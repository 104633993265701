import React, { useContext, useEffect, useRef, useState } from "react";

import { Text, View, Image, Platform, TouchableOpacity, FlatList, Modal, Animated, Alert } from "react-native";
import AppTheme from "../../utils/Theme";
import ComponentProps from "../../../specific/utils/Interfaces/ComponentProps";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { pop, push, showModal } from "../../../specific/utils/Navigation/Navigation";
import { useNavigate, useParams } from "react-router-dom";
import { getLocalActivities, getLocalActivitiesByCategories, getLocalCategories } from "../../../specific/utils/LocalStorage";
import i18n, { getLanguage } from "../../utils/Localization/Localization";
import Style from "../../utils/Style";
import SVGView from "../../utils/SvgView";
import { default as IcBack } from "../../assets/svg/lili/ic_back_arrow.svg";
import Images from "../../../specific/utils/Images";
import CategoryHomeStyle from "../../styles/categories/CategoryHomeStyle";
import MainStyle from "../../styles/game/MainStyle";
import User from "../../data/user/User";
import { EventFiltersByLevelChanged, ScreenActivityMediaList, ScreenActivityPager, ScreenActivitySheet, ScreenSubscription, ScreenSuperRoom } from "../../utils/Constants";
import Activity, { getActivityAvalability } from "../../models/Activity";
import ActivityMediaList from "../Activities/ActivityMediaList/ActivityMediaList";
import ActivityIcon from "../Activities/ActivityViews/ActivityIcon";
import { replaceCustomVariablesInString } from "../../utils/StringUtils";
import { build } from "../../../../package.json";
import { analyticsEventCategoryViewed, propertiesEventCategory, trackEvent } from "../../services/Tracking/Analytics";
import { superPowerDataKeys } from "../Superpowers/SuperPowersPlaylist";
import { default as IcFilter } from "../../assets/svg/lili/ic_filter.svg";
import { default as IcClose } from "../../assets/svg/icons/solid/times.svg";

import * as Navigation from "../../../specific/utils/Navigation/Navigation";

import superPowersData from "../../data/jsons/superPrograms.json";
import SVGIconButton from "../../designSystem/Buttons/SVGIconButton";
import ActivityFilterByLevel from "../Filters/ActivityFilterByLevel";
import { EventRegister } from "../../utils/EventListeners";
import ActivitySheet from "../Activities/ActivitySheet";
import InViewModal from "../../../specific/components/InViewModal/InViewModal";
import { LocalDBContext } from "../../data/LocalDB";
import DeepLink from "../../services/DeepLink/DeepLink";
import { SvgProps } from "react-native-svg";

interface CategoryHomeProps extends ComponentProps {
    categoryKey?: string,
    sectionKey?: string,
    activities?: Activity[],
    header?: string,
    title?: string,
    section?: string,
    origin?: string,
    isModal?: boolean,
    webCloseRequest?: () => void,
    starting_point?: string
}

const CategoryHome = (props: CategoryHomeProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const categoryHomeStyle: CategoryHomeStyle = new CategoryHomeStyle();
    const mainStyle: MainStyle = new MainStyle();
    const user: User = User.getInstance();
    const localDBData = useContext(LocalDBContext);

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [category, setCategory] = useState<any>(undefined);
    const [localActivities, setLocalActivities] = useState(undefined);
    const [localActivitiesByCategory, setLocalActivitiesByCategory] = useState(undefined);
    const [localSections, setLocalSections] = useState(undefined);
    const [activitiesForCategory, setActivitiesForCategory] = useState<any[] | undefined>(undefined);
    const [selectedActivity, setSelectedActivity] = useState<Activity | undefined>(undefined);

    const [filterByLevel, setFilterByLevel] = useState<{ filters: string[], last_update: number } | undefined>(undefined);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const contentViewOpactiy: Animated.Value = useRef(new Animated.Value(1)).current;

    const inViewModal = useRef<InViewModal>(null);

    let propsData = props;
    if ((Platform.OS === "web") && (props.isModal === undefined)) {
        propsData = useParams();
    }
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const { categoryKey, sectionKey, componentId, activities, header, title, section, origin, isModal = false, webCloseRequest, starting_point = "superpower" } = propsData;

    // 0
    useEffect(() => {
        EventRegister.addEventListener(EventFiltersByLevelChanged, () => {
            getActiveFiltersByLevel();
        });
        return () => {
            EventRegister.removeEventListener(EventFiltersByLevelChanged);
        };
    }, []);

    const onViewDidAppear = () => {
        if ((categoryKey !== undefined) && (categoryKey.length > 0)) {
            // On va récupérer les données de la catégorie
            getCategoryDetails();
            getSectionDetails();
        } else {
            // Erreur de lien on revient en arrière
            goBack();
        }
    }

    const getSectionDetails = async () => {
        const sections = localDBData.sections.data;
        setLocalSections(sections);
    }

    const getCategoryDetails = async () => {
        const localCategoriesFromStorage = await getLocalCategories();
        if (localCategoriesFromStorage.data[categoryKey] !== undefined) {
            setCategory(localCategoriesFromStorage.data[categoryKey]);
        } else {
            goBack();
        }
    }

    useEffect(() => {
        if (category !== undefined) {
            // On va récupérer les activités pour la catégorie
            trackEvent(analyticsEventCategoryViewed, {
                [propertiesEventCategory]: category.analytics_key,
                starting_point
            })
            if (activities !== undefined) {
                setLocalActivitiesByCategory({ [categoryKey]: activities });
            } else {
                getLocalActivitiesByCategoryFromStorage();
            }
        }
    }, [category]);

    useEffect(() => {
        if (localActivitiesByCategory !== undefined) {
            if ((localActivitiesByCategory[categoryKey] !== undefined) && (localActivitiesByCategory[categoryKey].length > 0)) {
                getActivities();
            } else {
                goBack();
            }
        }
    }, [localActivitiesByCategory]);

    const getActivities = async () => {
        const localActivitiesFromStorage = await getLocalActivities();
        setLocalActivities(localActivitiesFromStorage.data);
    }

    useEffect(() => {
        if (localActivities !== undefined) {
            getActiveFiltersByLevel();
        }
    }, [localActivities]);

    // 8
    const getActiveFiltersByLevel = async () => {
        const user: User = User.getInstance();
        setFilterByLevel({ filters: user.filter_by_level, last_update: new Date().getTime() });
    }

    // 9
    useEffect(() => {
        if (filterByLevel !== undefined) {
            getActivitiesListForCategory();
        }
    }, [filterByLevel]);

    useEffect(() => {
        if (activitiesForCategory !== undefined) {
            setViewDidAppear(true);
        }
    }, [activitiesForCategory]);

    useEffect(() => {
        if (viewDidAppear === true) {
            // On va regarder si on doit montrer une activité
            const deeplink: DeepLink = DeepLink.getInstance();
            if (deeplink.activity_key.length > 0) {
                // On doit montrer une activité. Donc on a forcément la clé de la catégorie
                let activityIndex: number = 0;
                let activityToShow: Activity | undefined = undefined;
                for (const anActivityIndex in activitiesForCategory) {
                    if (Object.prototype.hasOwnProperty.call(activitiesForCategory, anActivityIndex)) {
                        const anActivity: Activity = activitiesForCategory[anActivityIndex];
                        if (anActivity.key === deeplink.activity_key) {
                            activityIndex = parseInt(anActivityIndex);
                            activityToShow = anActivity;
                            break;
                        }
                    }
                }
                showActivityMedias(activityToShow, activityIndex);
                deeplink.activity_key = "";
            }
        }
    }, [viewDidAppear]);

    const getCategoryColors = () => {
        let categoryColors = {
            btn: appTheme.daySkyColor,
            header_bg: appTheme.daySkyColor,
            nav: appTheme.daySkyColor,
            txt: appTheme.white
        };
        if (category !== undefined) {
            const { clr } = category;
            console.log({clr});
            if (clr !== undefined) {
                categoryColors = clr;
            } else {
                let catSectionKey: string = category.sectionId ?? sectionKey;
                console.log({catSectionKey});
                if (catSectionKey === "still") {
                    categoryColors = {
                        btn: appTheme.still,
                        header_bg: appTheme.still,
                        nav: "#A2F2F8",
                        txt: appTheme.darkBlue
                    }
                } else if (catSectionKey === "talk") {
                    categoryColors = {
                        btn: appTheme.talk,
                        header_bg: "#FFAA8C",
                        nav: appTheme.talk,
                        txt: appTheme.white
                    }
                } else if (catSectionKey === "grow") {
                    categoryColors = {
                        btn: appTheme.grow,
                        header_bg: "#51D2A8",
                        nav: appTheme.grow,
                        txt: appTheme.white
                    }
                } else if (catSectionKey === "super_program") {
                    categoryColors = {
                        btn: appTheme.darkBlue,
                        header_bg: appTheme.darkBlue,
                        nav: appTheme.darkBlue,
                        txt: appTheme.white
                    }
                } else if (catSectionKey === "inspire") {
                    categoryColors = {
                        btn: "#7A80D4",
                        header_bg: "#A2A8FF",
                        nav: "#7A80D4",
                        txt:"#25364f"
                    }
                }
            }
        }
        return categoryColors;
    }

    const getActivitiesListForCategory = () => {
        // On doit ajouter l'affichage en fonction du filtre et potentiellement du super pouvoir
        let activitiesToDisplay = [];
        if (localActivities !== undefined) {
            let freeActivitiesToDisplay = [];
            let premiumActivitiesToDisplay = [];
            for (const anActivityKeyIndex in localActivitiesByCategory[categoryKey]) {
                if (Object.prototype.hasOwnProperty.call(localActivitiesByCategory[categoryKey], anActivityKeyIndex)) {
                    const anActivityKey = localActivitiesByCategory[categoryKey][anActivityKeyIndex];
                    if (localActivities[anActivityKey] !== undefined) {
                        const anActivity = localActivities[anActivityKey];
                        const sectionMinBuildNumber = anActivity.min_build_number ?? "0";
                        let displayActivity: boolean = false;
                        if (user.admin === true) {
                            displayActivity = true;
                        } else {
                            if ((anActivity.status !== "draft") && (parseInt(sectionMinBuildNumber) < parseInt(build))) {
                                displayActivity = true;
                            } else if ((anActivity.status === "draft") && (anActivity.clearance !== "public")) {
                                if ((anActivity.clearance === "admin") && (user.admin === true)) {
                                    displayActivity = true;
                                }
                                if ((anActivity.clearance === "bizdev") && ((user.admin === true) || (user.bizdev === true))) {
                                    displayActivity = true;
                                }
                            }
                        }
                        
                        if (displayActivity === true) {
                            // On regarde si l'activité correspond aux filtres
                            let addActivity: boolean = true;
                            if ((filterByLevel !== undefined) && (filterByLevel.filters !== undefined) && (anActivity.cycles !== undefined)) {
                                const filtersArrayIntersection = filterByLevel.filters.filter(x => anActivity.cycles.includes(x));
                                if (filtersArrayIntersection.length === 0) {
                                    addActivity = false;
                                }
                            }
                            if ((section === "superPowers") && (origin !== undefined)) {
                                if (anActivity.tags !== undefined) {
                                    if (anActivity.tags.indexOf(origin) === -1) {
                                        addActivity = false;
                                    }
                                }
                            }
                            if (addActivity === true) {
                                let activityIncluded = false;
                                if ((anActivity.access.is_essential === true) || (anActivity.access.is_premium === true)) {
                                    for (const aPremiumActivityIndex in premiumActivitiesToDisplay) {
                                        if (Object.prototype.hasOwnProperty.call(premiumActivitiesToDisplay, aPremiumActivityIndex)) {
                                            const aPremiumActivity = premiumActivitiesToDisplay[aPremiumActivityIndex];
                                            if (aPremiumActivity.order > anActivity.order) {
                                                premiumActivitiesToDisplay.splice(aPremiumActivityIndex, 0, anActivity);
                                                activityIncluded = true;
                                                break;
                                            }
                                        }
                                    }
                                    if (activityIncluded === false) {
                                        premiumActivitiesToDisplay.push(anActivity);
                                    }
                                } else {
                                    for (const aFreeActivityIndex in freeActivitiesToDisplay) {
                                        if (Object.prototype.hasOwnProperty.call(freeActivitiesToDisplay, aFreeActivityIndex)) {
                                            const aFreeActivity = freeActivitiesToDisplay[aFreeActivityIndex];
                                            if (aFreeActivity.order > anActivity.order) {
                                                freeActivitiesToDisplay.splice(aFreeActivityIndex, 0, anActivity);
                                                activityIncluded = true;
                                                break;
                                            }
                                        }
                                    }
                                    if (activityIncluded === false) {
                                        freeActivitiesToDisplay.push(anActivity);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            activitiesToDisplay = freeActivitiesToDisplay.concat(premiumActivitiesToDisplay);
        }
        setActivitiesForCategory(activitiesToDisplay);
    }

    const getLocalActivitiesByCategoryFromStorage = async () => {
        const localActivitiesByCategoryFromStorage = await getLocalActivitiesByCategories();
        setLocalActivitiesByCategory(localActivitiesByCategoryFromStorage.data);
    }

    const goBack = () => {
        if (isModal === true) {
            if (webCloseRequest !== undefined) {
                webCloseRequest();
            } else if (Platform.OS !== "web") {
                Navigation.dismissModal({ componentId, navigate })
            }
        } else {
            pop({ componentId, navigate });
        }
    }

    const buttonSize = Platform.OS === "web" ? appTheme.pixelPerfect(50) : appTheme.pixelPerfect(30);
    const getLeftButton = () => {
        // Si on est en mobile, on a un bouton retour
        // En web un bouton nous ramenant au menu
        const clr = getCategoryColors();
        let navBarColor = clr.nav;
        let leftButtonIcon: React.FC<SvgProps> = isModal === false ? IcBack : IcClose;
        let leftButtonContent = <SVGView Component={leftButtonIcon} color={navBarColor} size={appTheme.pixelPerfect(12)} />;
        if (Platform.OS === "web") {
            // On va regarder si l'utilisateur est un prof ou un élève
            let imageForLeftButton = images.icLiliAtSchoolCircle;
            if (user.profile === "family") {
                imageForLeftButton = images.icLiliAtHomeCircle;
            } else if (user.profile === "animator") {
                imageForLeftButton = images.icLiliAtExtracurricularCircle;
            }
            leftButtonContent = <Image source={imageForLeftButton} style={{ width: buttonSize, height: buttonSize }} />
        }
        return <TouchableOpacity onPress={goBack} style={[Style.shadowed, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: appTheme.white, justifyContent: "center", alignItems: "center" }]}>
            {leftButtonContent}
        </TouchableOpacity>
    }

    const goToSuperRoom = () => {
        inViewModal.current?.showAlert({
            name: ScreenSuperRoom
        });
    }

    const getRightButton = () => {
        if ((categoryKey === "wellBeing") || (categoryKey === "phi_questions") || (categoryKey === "quotes")) {
            return <View />
        }
        // Si on est en mobile, on a un bouton retour
        // En web un bouton nous ramenant au menu
        const clr = getCategoryColors();
        let navBarColor = clr.nav;
        let txtColor = clr.txt;
        let rightButtonContent = <SVGView Component={IcFilter} color={txtColor} size={appTheme.pixelPerfect(14)} />;
        if (Platform.OS === "web") {
            navBarColor = appTheme.white;
            // On va regarder si l'utilisateur est un prof ou un élève
            let imageForLeftButton = images.icLiliAtSchoolCircle;
            if (user.profile === "family") {
                imageForLeftButton = images.icLiliAtHomeCircle;
            } else if (user.profile === "animator") {
                imageForLeftButton = images.icLiliAtExtracurricularCircle;
            }
            rightButtonContent = <Image source={imageForLeftButton} style={{ width: buttonSize, height: buttonSize }} />;

            const imageSize = appTheme.pixelPerfect(40);
            const rightButtonWidth = appTheme.pixelPerfect(120);
            const buttonTitle = user.profile === "teacher" ? i18n.t("appMenu.teachers.button.title") : user.profile === "animator" ? i18n.t("appMenu.animators.button.title") : i18n.t("appMenu.parents.button.title");
            const buttonImage = user.profile === "teacher" ? images.icSuperTeachersRoom : images.icSuperParentsRoom;
            const paddingBottom = appTheme.bottomInsets > 0 ? appTheme.bottomInsets : appTheme.pixelPerfect(20);
            return <TouchableOpacity onPress={goToSuperRoom} style={{ width: rightButtonWidth, justifyContent: "space-between", alignItems: "center", height: appTheme.pixelPerfect(50) }}>
                <View style={[mainStyle.shadowed, { borderRadius: imageSize / 2 }]}>
                    <Image source={buttonImage} style={{ width: imageSize, height: imageSize }} resizeMode="cover" />
                </View>
                <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(3), textAlign: "center" }}>{buttonTitle}</Text>
            </TouchableOpacity>

        }
        return <TouchableOpacity onPress={toggleFilters} style={[{ width: buttonSize, height: buttonSize, backgroundColor: navBarColor, justifyContent: "center", alignItems: "center" }]}>
            {rightButtonContent}
        </TouchableOpacity>
    }

    const getNavBar = () => {
        const { title } = category;
        const clr = getCategoryColors();
        let navBarColor = clr.nav;
        let navBarTextColor = clr.txt;

        let titleToDisplay = replaceCustomVariablesInString(title[getLanguage()]);
        if ((Platform.OS === "web") && (section === "superPowers")) {
            titleToDisplay = i18n.t("tags." + origin);
        }
        return <View style={[mainStyle.shadowed, categoryHomeStyle.navigationBar(navBarColor)]}>
            <View style={categoryHomeStyle.navigationBarContent}>
                {getLeftButton()}
                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={2} style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.superHerosFontBold, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", color: Platform.OS === "web" ? appTheme.darkBlue : navBarTextColor }}>{titleToDisplay.replace("\\n", "\n")}</Text>
                </View>
                {getRightButton()}
            </View>
        </View>
    }

    const getBodyIntro = () => {
        const { body, img } = category;
        const clr = getCategoryColors();
        const navBarColor = clr.nav;
        const navBarTextColor = clr.txt;
        let intro = "";
        let seeMoreButton = <View />;
        if ((body !== undefined) && (body[getLanguage()] !== undefined) && (body[getLanguage()][0] !== undefined)) {
            intro = body[getLanguage()][0];
            if (body[getLanguage()] > 1) {
                seeMoreButton = <TouchableOpacity style={{ marginTop: appTheme.pixelPerfect(15) }}>
                    <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(6), color: navBarTextColor, textDecorationColor: navBarTextColor, textDecorationLine: "underline" }}>
                        {i18n.t("category.see_more")}
                    </Text>
                </TouchableOpacity>;
            }
        }
        if (intro.length === 0) {
            return <View />
        }
        return <View style={{ alignItems: "center" }}>
            <View style={categoryHomeStyle.bodyIntro(appTheme.white)}>
                <Text style={{ fontFamily: appTheme.primaryMediumFont, fontSize: appTheme.pixelPerfectForFont(8), color: appTheme.darkBlue, textAlign: "center" }}>
                    {intro}
                </Text>
                {seeMoreButton}
            </View>
        </View>
    }

    useEffect(() => {
        let contentViewOpactiyFinalValue = selectedActivity === undefined ? 1 : 0;
        Animated.timing(contentViewOpactiy, {
            toValue: contentViewOpactiyFinalValue,
            duration: 500,
            useNativeDriver: false
        }).start();
    }, [selectedActivity]);

    const showActivitySheet = (data: { index: number, activityKey: string }) => {
        const { activityKey, index } = data;
        const name = Platform.OS === 'web' ? ScreenActivitySheet : ScreenActivityPager;
        const passProps = Platform.OS === 'web' ? {
            activityKey,
            categoryKey,
            sectionKey,
            context: user.profile,
            starting_point
        } : {
            activityKey,
            categoryKey,
            sectionKey,
            context: user.profile,
            activities: activitiesForCategory,
            index,
            starting_point
        };
        push({
            componentId,
            navigate,
            passProps,
            name
        })
    }

    const showActivityMedias = (item, index) => {
        if (Platform.OS === "web") {
            window.scrollTo(0, 0);
            setSelectedActivity(item);
        } else {
            // On va faire une différence entre WellBeing & Philo qui doivent montrer des listes de médias et les autres qui doivent montrer des activités.
            if ((categoryKey === "wellBeing") || (categoryKey === "phi_questions") || (categoryKey === "quotes")) {
                push({
                    componentId,
                    navigate,
                    name: ScreenActivityMediaList,
                    passProps: {
                        activity: item,
                        category
                    }
                });
            } else {
                showActivitySheet({ index: parseInt(index), activityKey: item.key });
            }
        }
    }

    const activitiesGridCell = ({ item, index }) => {
        const refWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
        const cellSize = (refWidth - appTheme.pixelPerfect(60)) / 3;
        const marginHorizontal = appTheme.pixelPerfect(10);
        let imageSize = appTheme.pixelPerfect(80);
        if (imageSize > (cellSize - appTheme.pixelPerfect(10))) {
            imageSize = cellSize - appTheme.pixelPerfect(10);
        }
        const marginTop = Platform.OS === "web" ? 0 : appTheme.pixelPerfect(10);
        const textFontSize = Platform.OS === "web" ? appTheme.pixelPerfectForFont(10) : appTheme.pixelPerfectForFont(6);
        let localPath: string = item.app_image;
        let remotePath: string = item.img_url;
        let title: string = item.title[getLanguage()];
        // On va regarder si on a une iimage spécifique pour le type d'utilisateur
        if (item[user.profile] !== undefined) {
            const userProfileItem = item[user.profile];
            if (userProfileItem.app_image !== undefined) {
                localPath = userProfileItem.app_image;
            }
            if (userProfileItem.img_url !== undefined) {
                remotePath = userProfileItem.img_url;
            }
            if ((userProfileItem.title !== undefined) && (userProfileItem.title[getLanguage()] !== undefined)) {
                title = userProfileItem.title[getLanguage()];
            }
        }
        return <TouchableOpacity key={item.key} onPress={() => showActivityMedias(item, index)} style={{ marginTop, marginHorizontal, alignItems: "center", width: cellSize, backgroundColor: appTheme.white }}>
            <ActivityIcon isPremium={item.access.is_premium} status={item.status} isActivityAvailable={getActivityAvalability(item)} remotePath={remotePath} localPath={localPath} isSquare={true} withShadow={true} size={appTheme.pixelPerfect(80)} />
            <View style={{ marginTop: appTheme.pixelPerfect(5), width: "80%", justifyContent: "flex-start", flex: 1 }}>
                <Text numberOfLines={3} style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, textAlign: "center", fontSize:appTheme.pixelPerfectForFont(7) }}>{title}</Text>
            </View>
        </TouchableOpacity>
    }

    const getBackButton = () => {
        return <SVGIconButton buttonSize={appTheme.pixelPerfect(30)} icon={IcBack} onPress={goBack} color={"#354F84"} />
    }

    // Affichage des filtes
    const toggleFilters = () => {
        setShowFilters(!showFilters);
    }

    const getFilterbutton = () => {
        if ((categoryKey === "wellBeing") || (categoryKey === "phi_questions") || (categoryKey === "quotes")) {
            return <View />
        }
        return <TouchableOpacity onPress={toggleFilters} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), justifyContent: "center", alignItems: "center" }}>
            <SVGView Component={IcFilter} size={appTheme.pixelPerfect(20)} color={"#354F84"} />
        </TouchableOpacity>
    }

    const getActivitiesGrid = () => {
        const lineWidth = appTheme.getBlockWidth() - appTheme.pixelPerfect(20);
        let activitesGrid = [];
        let activitiesLine = [];
        const { title } = category;
        const clr = getCategoryColors();
        const navBarColor = clr.nav;
        const navBarTextColor = clr.txt;
        const titleToDisplay = replaceCustomVariablesInString(title[getLanguage()]);
        activitesGrid.push(<View style={{ width: "100%", height: appTheme.pixelPerfect(50), backgroundColor: navBarColor, justifyContent: "center", alignItems: "center", flexDirection: "row", paddingHorizontal: appTheme.pixelPerfect(10), marginBottom:appTheme.pixelPerfect(10) }}>
            {getBackButton()}
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <Text numberOfLines={2} style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "center", textTransform: "uppercase", color: Platform.OS === "web" ? appTheme.darkBlue : navBarTextColor }}>{titleToDisplay.replace("\\n", "\n")}</Text>
            </View>
            {getFilterbutton()}
        </View>)
        activitesGrid.push(getBodyIntro());
        const activitiesByLine = 3;
        const marginBottom = appTheme.pixelPerfect(40);
        for (const anActivityIndex in activitiesForCategory) {
            if (Object.prototype.hasOwnProperty.call(activitiesForCategory, anActivityIndex)) {
                const anActivity = activitiesForCategory[anActivityIndex];
                let displayActivity: boolean = true;
                if ((anActivity[user.profile] !== undefined) && (anActivity[user.profile].display !== undefined)) {
                    displayActivity = anActivity[user.profile].display;
                }
                if (displayActivity === true) {
                    activitiesLine.push(activitiesGridCell({ item: anActivity, index: anActivityIndex }));
                    if (activitiesLine.length === activitiesByLine) {
                        activitesGrid.push(<View style={{ flexDirection: "row", alignItems: "flex-start", width: lineWidth, marginBottom }}>
                            {activitiesLine}
                        </View>);
                        activitiesLine = [];
                    }
                }
            }
        }
        if (activitiesLine.length > 0) {
            activitesGrid.push(<View style={{ flexDirection: "row", alignItems: "flex-start", width: lineWidth }}>
                {activitiesLine}
            </View>);
        }
        return activitesGrid;
    }

    const  getListHeader = () => {
        const { img } = category;
        let headerBgColor = section === "superPowers" ? appTheme.darkBlue : undefined;
        if (headerBgColor === undefined) {
            const clr = getCategoryColors();
            headerBgColor = clr.header_bg;
        }
        let headerImageView = <View style={categoryHomeStyle.headerImage(headerBgColor)} />
        let source: any = undefined;
        if (header !== undefined) {
            source = images[header];
        } else if (superPowerDataKeys.indexOf(origin) !== -1) {
            if (superPowersData[origin] !== undefined) {
                source = images[superPowersData[origin].bg];
            }
        } else {
            if ((img !== undefined) && (img.header !== undefined)) {
                if ((img.header.local !== undefined) && (images[img.header.local] !== undefined)) {
                    source = images[img.header.local];
                } else if ((Platform.OS === "web") && (img.header.web !== undefined)) {
                    source = { url: img.header.web };
                } else if ((Platform.OS !== "web") && (img.header.mobile !== undefined)) {
                    source = { url: img.header.mobile };
                }
            } else {
                if ((activitiesForCategory !== undefined) && (activitiesForCategory[0] !== undefined)) {
                    const firstActivty: Activity = activitiesForCategory[0];
                    if (firstActivty.app_image !== undefined) {
                        source = images[firstActivty.app_image];
                    } else if (firstActivty.img_url !== undefined) {
                        source = { url: firstActivty.img_url };
                    }
                }
            }
        }
        // On va regarder si on a une iimage spécifique pour le type d'utilisateur
        if (category[user.profile] !== undefined) {
            if ((category[user.profile].img !== undefined) && (category[user.profile].img.header !== undefined)) {
                if ((category[user.profile].img.header.local !== undefined) && (images[category[user.profile].img.header.local] !== undefined)) {
                    source = images[category[user.profile].img.header.local];
                } else if ((Platform.OS === "web") && (category[user.profile].img.header.web !== undefined)) {
                    source = { url: category[user.profile].img.header.web };
                } else if ((Platform.OS !== "web") && (category[user.profile].img.header.mobile !== undefined)) {
                    source = { url: category[user.profile].img.header.mobile };
                }
            }
        }


        const imageWidth: number = appTheme.getFullScreenWidth();
        const imageHeight: number = Platform.OS === "web" ? appTheme.pixelPerfect(210) : imageWidth * 27 / 36;
        if (source !== undefined) {
            headerImageView = <Image source={source} style={[categoryHomeStyle.headerImage(headerBgColor), { width: imageWidth, height: imageHeight }]} resizeMode="cover" />;
        }

        const activitiesGrid = (Platform.OS === "web") ? getActivitiesGrid() : <View />;
        const bodyIntro = Platform.OS === "web" ? <View /> : getBodyIntro();
        return <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center" }}>
            {headerImageView}
            <Animated.View style={[categoryHomeStyle.contentView, { opacity: contentViewOpactiy, overflow: "hidden" }]}>
                {bodyIntro}
                {activitiesGrid}
            </Animated.View>
        </View>
    }

    const getActiviesList = () => {
        if (Platform.OS === "web") {
            return <View />
        } else {
            let listOfActivities: Activity[] = [];
            for (const anActivityIndex in activitiesForCategory) {
                if (Object.prototype.hasOwnProperty.call(activitiesForCategory, anActivityIndex)) {
                    const anActivity: Activity = activitiesForCategory[anActivityIndex];
                    let displayActivity: boolean = true;
                    if ((anActivity[user.profile] !== undefined) && (anActivity[user.profile].display !== undefined)) {
                        displayActivity = anActivity[user.profile].display;
                    }
                    if (displayActivity === true) {
                        listOfActivities.push(anActivity);
                    }
                }
            }
            return <FlatList
                numColumns={3}
                ListHeaderComponent={getListHeader}
                data={listOfActivities}
                style={{ width: appTheme.getFullAppWidth(), backgroundColor: appTheme.white }}
                renderItem={activitiesGridCell}
                keyExtractor={(item, index) => {
                    return item.key
                }} />
        }
    }

    const getModalContent = () => {
        if (Platform.OS === "web") {
            if ((viewDidAppear === true) && (selectedActivity !== undefined)) {
                if ((categoryKey === "phi_questions") || (categoryKey === "wellBeing")) {
                    return <View style={categoryHomeStyle.modalStyle}>
                        <ActivityMediaList
                            category={category}
                            activity={selectedActivity}
                            webCloseRequest={() => setSelectedActivity(undefined)} />
                    </View>
                }
                return <View style={categoryHomeStyle.modalStyle}>
                    <ActivitySheet
                        inPager={true}
                        activityKey={selectedActivity.key}
                        sectionKey={selectedActivity.section_key}
                        categoryKey={categoryKey}
                        activity={selectedActivity}
                        webCloseRequest={() => setSelectedActivity(undefined)} />
                </View>
            }
        }
        return <View />;
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator loading={true} color={appTheme.darkBlue} />
            </View>
        }

        let headerBgColor = section === "superPowers" ? appTheme.darkBlue : undefined;
        if (headerBgColor === undefined) {
            const clr = getCategoryColors();
            headerBgColor = clr.header_bg;
        }

        const listHeader = Platform.OS === "web" ? getListHeader() : <View />;
        return <View style={[categoryHomeStyle.mainView(headerBgColor)]}>
            <View style={categoryHomeStyle.mainBackground(headerBgColor)} />
            {listHeader}
            {getActiviesList()}
            {getModalContent()}
            {getNavBar()}
            <Modal
                transparent={true}
                animationType="fade"
                visible={showFilters}
                style={{ flex: 1, justifyContent: "flex-end" }}>
                <ActivityFilterByLevel backgroundColor={appTheme.darkBlue} toggleFilters={toggleFilters} selected={filterByLevel?.filters} />
            </Modal>
            <InViewModal ref={inViewModal} />
        </View>
    }

    return getContent();
}

export default CategoryHome;