import React from "react";
import { useState } from "react";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    Image as RNImage
} from 'react-native';

import AppTheme from "../../../utils/Theme";
import Image from "../../../designSystem/OTBImage";

// Assets
import Images from "../../../../specific/utils/Images";

// SVG
import i18n from "../../../utils/Localization/Localization";

interface Props {
    name: string;
}

const OnboardingParent = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const { page } = props;

    const [viewDidAppear, setViewDidAppear] = useState(false);

    const onViewDidAppear = () => {
        setViewDidAppear(true);
    }

    let paddingTop = Platform.OS === "web" ? appTheme.pixelPerfect(60) : 0;
    paddingTop = paddingTop + appTheme.topInsets + appTheme.pixelPerfect(10);
    let viewHeight = appTheme.getFullScreenHeight() - paddingTop;

    let backgroundColor = appTheme.nightSkyColor;
    if (page === "parentPageOne") {
        backgroundColor = appTheme.daySkyColor;
    }

    const textStyle = {
        textAlign: "center",
        paddingTop: appTheme.pixelPerfect(4)
    }

    const getRessourcesViewForParentPageFour = () => {
        
        return <View style={{ backgroundColor: appTheme.white, width: appTheme.getBlockWidth(), paddingHorizontal: appTheme.pixelPerfect(10), marginTop: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), alignItems: "center", paddingTop: appTheme.pixelPerfect(45), paddingBottom: appTheme.pixelPerfect(20) }}>
            <Image source={images.icCoachManual} style={{ position: "absolute", top: appTheme.pixelPerfect(-30), width: appTheme.pixelPerfect(80), height: appTheme.pixelPerfect(74) }} resizeMode="contain" />
            <Text style={[textStyle, { fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, paddingHorizontal: appTheme.pixelPerfect(2) }]}>{i18n.t("onboarding.parent.pageFour.coachManual.part_one")}</Text>
            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Text style={[textStyle, { fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, paddingHorizontal: appTheme.pixelPerfect(2) }]}>{i18n.t("onboarding.parent.pageFour.coachManual.part_two")}</Text>
                <Image source={images.icLiliAtHomeCircle} style={{ width: appTheme.pixelPerfect(20), height: appTheme.pixelPerfect(20) }} resizeMode="contain" />
                <Text style={[textStyle, { fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, paddingHorizontal: appTheme.pixelPerfect(2) }]}>{i18n.t("onboarding.parent.pageFour.coachManual.part_three")}</Text>
            </View>
            <View style={{ width: appTheme.getBlockWidth(), flexDirection: "row", justifyContent: "space-evenly", alignItems: "flex-start", marginTop: appTheme.pixelPerfect(15) }}>
                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Image source={images.illSchoolTools} style={{ width: appTheme.pixelPerfect(88), height: appTheme.pixelPerfect(80), marginBottom: appTheme.pixelPerfect(5) }} resizeMode="contain" />
                    <Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, paddingHorizontal: appTheme.pixelPerfect(2) }]}>{i18n.t("onboarding.parent.pageFour.tools.school.title")}</Text>
                    <Text style={[textStyle, { fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, paddingHorizontal: appTheme.pixelPerfect(2) }]}>{i18n.t("onboarding.parent.pageFour.tools.school.body")}</Text>
                </View>
                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Image source={images.illExpertsTools} style={{ width: appTheme.pixelPerfect(83), height: appTheme.pixelPerfect(80), marginBottom: appTheme.pixelPerfect(5) }} resizeMode="contain" />
                    <Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, paddingHorizontal: appTheme.pixelPerfect(2) }]}>{i18n.t("onboarding.parent.pageFour.tools.experts.title")}</Text>
                    <Text style={[textStyle, { fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, paddingHorizontal: appTheme.pixelPerfect(2) }]}>{i18n.t("onboarding.parent.pageFour.tools.experts.body")}</Text>
                </View>
            </View>
        </View>
    }

    const getRessourcesViewForParentPageThree = () => {
        return <View style={{ backgroundColor: appTheme.white, width: appTheme.getBlockWidth(), padding: appTheme.pixelPerfect(10), marginTop: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(20), alignItems: "center" }}>
            <Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, marginTop: appTheme.pixelPerfect(10), color: appTheme.nightSkyColor }]}>{i18n.t("onboarding.parent.pageThree.ressources.title").toLocaleUpperCase()}</Text>
            <View style={{ backgroundColor: appTheme.nightSkyColor, width: appTheme.pixelPerfect(30), height: 1, marginVertical: appTheme.pixelPerfect(10) }} />
            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Image source={images.icMusicNoteOne} style={{ width: appTheme.pixelPerfect(22), height: appTheme.pixelPerfect(22) }} resizeMode="contain" />
                <Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(13), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, paddingHorizontal: appTheme.pixelPerfect(10) }]}>{i18n.t("onboarding.parent.pageThree.ressources.activities")}</Text>
                <Image source={images.icMusicNoteTwo} style={{ width: appTheme.pixelPerfect(22), height: appTheme.pixelPerfect(22) }} resizeMode="contain" />
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-evenly", alignItems: "flex-start", marginTop: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth() }}>
                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Image source={images.illStill} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(60) }} resizeMode="contain" />
                    <Text style={[textStyle, { fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.primaryFont, color: appTheme.still, paddingHorizontal: appTheme.pixelPerfect(2) }]}>{i18n.t("onboarding.parent.pageThree.ressources.still")}</Text>
                </View>
                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Image source={images.illTalk} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(60) }} resizeMode="contain" />
                    <Text style={[textStyle, {fontSize: appTheme.pixelPerfectForFont(9),  fontFamily: appTheme.primaryFont, color: appTheme.talk, paddingHorizontal: appTheme.pixelPerfect(2) }]}>{i18n.t("onboarding.parent.pageThree.ressources.talk")}</Text>
                </View>
                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Image source={images.illGrow} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(60) }} resizeMode="contain" />
                    <Text style={[textStyle, { fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.primaryFont, color: appTheme.grow, paddingHorizontal: appTheme.pixelPerfect(2) }]}>{i18n.t("onboarding.parent.pageThree.ressources.grow")}</Text>
                </View>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: appTheme.pixelPerfect(10) }}>
                <Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor, paddingHorizontal: appTheme.pixelPerfect(10) }]}>{i18n.t("onboarding.parent.pageThree.ressources.tools")}</Text>
            </View>
            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginTop: appTheme.pixelPerfect(5) }}>
                <Image source={images.icPencils} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />
                <Image source={images.illOnboardingParentPageThree} style={{ width: appTheme.pixelPerfect(130), height: appTheme.pixelPerfect(80), marginHorizontal: appTheme.pixelPerfect(20) }} resizeMode="contain" />
                <Image source={images.icCisors} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />
            </View>
        </View>
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <ActivityIndicator color={appTheme.darkBlue} />;
        }
        let onboardingPageView = [];
        if (page === "parentPageOne") {
            // ONBOARDING PARENT PAGE ONE
            const grassHeight = appTheme.pixelPerfect(140);
            const catHeight = Platform.OS === "web" ? viewHeight / 2 : viewHeight / 2;
            const owlWidth = Platform.OS === "web" ? appTheme.getBlockWidth()/2 : appTheme.getBlockWidth();
            const owlHeight = owlWidth*105/270;
            // Images de fond
            onboardingPageView.push(<View style={{ width: appTheme.getFullAppWidth(), height: viewHeight, justifyContent: "flex-start", alignItems:"center" }}>
                <RNImage source={images.illOnboardingParentPageOneCloud} style={{ marginBottom: grassHeight, width: appTheme.getFullAppWidth(), height: viewHeight, position:"absolute", bottom:0 }} resizeMode="repeat" />
                <View style={{ backgroundColor: appTheme.grassColor, width: appTheme.getFullAppWidth(), height: grassHeight, bottom: 0, position: "absolute" }} />
                <RNImage source={images.illOnboardingParentPageOneGrass} style={{ width: appTheme.getFullAppWidth(), height: grassHeight, position: "absolute", bottom: 0 }} resizeMode="repeat" />
                <Image source={images.illOnboardingParentPageOneCat} style={{ width: appTheme.getFullAppWidth(), height: catHeight, bottom: 0, position: "absolute" }} resizeMode="contain" />
                <Image source={images.illFlyingOwl} style={{ width: owlWidth, height: owlHeight }} resizeMode="contain" />
                <View style={{ flex: 1, height:viewHeight, justifyContent: "flex-start", alignItems: "center" }}>
                <View style={{ padding: appTheme.pixelPerfect(4), borderRadius: appTheme.pixelPerfect(10) }}>
                    <Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, marginTop: appTheme.pixelPerfect(20), color: appTheme.schoolColor }]}>{i18n.t("onboarding.parent.pageOne.title").toLocaleUpperCase()}</Text>
                    <Text style={[styles.subtitle, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, marginTop: appTheme.pixelPerfect(10), color: appTheme.schoolColor }]}>{i18n.t("onboarding.parent.pageOne.subtitle")}</Text>
                    <Text style={[styles.legend, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.secondaryFont, marginTop: appTheme.pixelPerfect(10), color: appTheme.schoolColor }]}>{i18n.t("onboarding.parent.pageOne.legend")}</Text>
                </View>
            </View>
            </View>);
        } else if (page === "parentPageTwo") {
            // ONBOARDING PARENT PAGE TWO
            const textHeight = Platform.OS === "web" ? appTheme.pixelPerfect(80) : appTheme.pixelPerfect(57);
            const textWidth = textHeight * 250 / 80;
            const superPowerMarginTop = appTheme.pixelPerfect(30);
            let superPowerWidth = appTheme.getBlockWidth();
            let superPowerHeight = (viewHeight - textHeight) / 2;
            const superCatWidth = superPowerWidth;
            const superCatHeight = superPowerHeight - appTheme.pixelPerfect(30);
            onboardingPageView.push(<RNImage source={images.bgOnboardingParent} style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: "absolute", top: 0, left: 0 }} resizeMode="repeat" />);
            onboardingPageView.push(<Image source={images.txtSuperPowers} style={{ width: textWidth, height: textHeight }} resizeMode="contain" />);
            onboardingPageView.push(<Image source={images.illOnboardingParentPageTwo} style={{ width: superPowerWidth, height: superPowerHeight, marginTop: superPowerMarginTop }} resizeMode="contain" />);
            onboardingPageView.push(<Image source={images.illSupercat} style={{ width: superCatWidth, height: superCatHeight, marginRight: appTheme.pixelPerfect(50) }} resizeMode="contain" />);
        } else if ((page === "parentPageThree") || (page === "parentPageFour")) {
            const superCatPosition = (appTheme.getFullAppWidth() - appTheme.getBlockWidth()) / 2;
            let routinesWidth = (appTheme.getBlockWidth() -appTheme.pixelPerfect(20)) / 2;
            onboardingPageView.push(<View style={{ zIndex: -1, position: "absolute", top: 0, width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight() }}>
                <Image source={images.bgOnboardingParentTwo} style={{ position: "absolute", top: 0, width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight() }} resizeMode="cover" />
                <RNImage source={images.bgOnboardingParent} style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: "absolute", top: 0, left: 0 }} resizeMode="repeat" />
            </View>);
            if (page === "parentPageFour") {
                let ceoQuoteWidth = appTheme.getBlockWidth();
                onboardingPageView.push(<Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), zIndex: 10, fontFamily: appTheme.primaryFont, color: appTheme.homeColor }]}>{i18n.t("onboarding.parent.pageFour.title").toLocaleUpperCase()}</Text>);
                onboardingPageView.push(getRessourcesViewForParentPageFour());
                onboardingPageView.push(<View style={{ flex: 1, width: appTheme.getFullAppWidth(), alignItems: "center", paddingTop:appTheme.pixelPerfect(40)}}>
                    <View style={{ alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(5), paddingBottom: appTheme.pixelPerfect(5), borderRadius: appTheme.pixelPerfect(10), width: ceoQuoteWidth, backgroundColor: "#354F84", paddingTop: appTheme.pixelPerfect(35) }}>
                        <Image source={images.icOwlYoda} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(55), position: "absolute", top: appTheme.pixelPerfect(-30) }} resizeMode="contain" />
                        <Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: appTheme.homeColor }]}>{i18n.t("onboarding.parent.pageFour.citation.text")}</Text>
                        <Text style={[textStyle, { fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.secondaryFont, color: appTheme.white, paddingVertical: appTheme.pixelPerfect(10) }]}>{i18n.t("onboarding.parent.pageFour.citation.legend")}</Text>
                        <Image source={images.icLeftQuote} style={{ position: "absolute", top: appTheme.pixelPerfect(-6), left: appTheme.pixelPerfect(-4), width: appTheme.pixelPerfect(22), height: appTheme.pixelPerfect(15) }} resizeMode="contain" />
                        <Image source={images.icRightQuote} style={{ position: "absolute", bottom: appTheme.pixelPerfect(-6), right: appTheme.pixelPerfect(-4), width: appTheme.pixelPerfect(22), height: appTheme.pixelPerfect(15) }} resizeMode="contain" />
                    </View>

                </View>);
            } else {
                onboardingPageView.push(<View style={{ position: "absolute", right: superCatPosition, top: paddingTop }}>
                    <Image source={images.illSupercatStanding} style={{ width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(77) }} resizeMode="contain" />
                </View>);
                onboardingPageView.push(<Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: appTheme.homeColor }]}>{i18n.t("onboarding.parent.pageThree.title").toLocaleUpperCase()}</Text>);
                onboardingPageView.push(getRessourcesViewForParentPageThree());
                onboardingPageView.push(<View style={{ flex: 1, alignItems: "center", paddingTop:appTheme.pixelPerfect(20) }}>
                    <Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: appTheme.white }]}>{i18n.t("onboarding.parent.pageThree.routines.title").toLocaleUpperCase()}</Text>
                    <View style={{ backgroundColor: appTheme.white, width: appTheme.pixelPerfect(30), height: 1, marginVertical: appTheme.pixelPerfect(5) }} />
                    <View style={{ flexDirection: "row", width: appTheme.getFullAppWidth(), justifyContent: "center", paddingTop: appTheme.pixelPerfect(6) }}>
                        <View style={{ alignItems: "center", marginEnd: appTheme.pixelPerfect(5), paddingBottom: appTheme.pixelPerfect(5), borderRadius: appTheme.pixelPerfect(10), width: routinesWidth, backgroundColor: appTheme.daySkyColor }}>
                            <Image source={images.illTrainingPath} style={{ width: appTheme.pixelPerfect(90), height: appTheme.pixelPerfect(50), marginTop: appTheme.pixelPerfect(10) }} resizeMode="contain" />
                            <Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor }]}>{i18n.t("onboarding.parent.pageThree.routines.day.title").toLocaleUpperCase()}</Text>
                            <Text style={[textStyle, { fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.secondaryFont, color: appTheme.nightSkyColor }]}>{i18n.t("onboarding.parent.pageThree.routines.day.subtitle")}</Text>
                            <Image source={images.icDay} style={{ position: "absolute", top: appTheme.pixelPerfect(-10), left: appTheme.pixelPerfect(-10), width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(25) }} resizeMode="contain" />
                        </View>
                        <View style={{ alignItems: "center", marginStart: appTheme.pixelPerfect(5), paddingBottom: appTheme.pixelPerfect(5), borderRadius: appTheme.pixelPerfect(10), width: routinesWidth, backgroundColor: appTheme.daySkyColor }}>
                            <Image source={images.illSleepingMeditations} style={{ width: appTheme.pixelPerfect(90), height: appTheme.pixelPerfect(50), marginTop: appTheme.pixelPerfect(10) }} resizeMode="contain" />
                            <Text style={[styles.title, { fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: appTheme.nightSkyColor }]}>{i18n.t("onboarding.parent.pageThree.routines.night.title").toLocaleUpperCase()}</Text>
                            <Text style={[textStyle, { fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.secondaryFont, color: appTheme.nightSkyColor }]}>{i18n.t("onboarding.parent.pageThree.routines.night.subtitle")}</Text>
                            <Image source={images.icNight} style={{ position: "absolute", top: appTheme.pixelPerfect(-10), right: appTheme.pixelPerfect(-10), width: appTheme.pixelPerfect(38), height: appTheme.pixelPerfect(25) }} resizeMode="contain" />
                        </View>
                    </View>
                </View>);
            }

        }
        return onboardingPageView;
    }

    return (
        <View onLayout={onViewDidAppear} style={[styles.main, { width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), paddingHorizontal: appTheme.pixelPerfect(10), paddingTop, backgroundColor, marginBottom:appTheme.pixelPerfect(80) }]}>
            {getContent()}
        </View>
    )
}

export default OnboardingParent;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        textAlign: "center",
        fontWeight: "600",
        letterSpacing: 0.8
    },
    subtitle: {
        textAlign: "center",
        fontWeight: "600",
        letterSpacing: 0.8
    },
    legend: {
        textAlign: "center",
        letterSpacing: 0.8
    }
});