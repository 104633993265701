import React from "react";
import { useState, useEffect, useRef } from "react";
import Main from "../../../specific/components/Main/Main";
import i18n, { updateContext } from "../../utils/Localization/Localization";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Animated,
    FlatList,
    Modal
} from 'react-native';

import * as Navigation from "../../../specific/utils/Navigation/Navigation";

import AppTheme, { Theme } from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// UI
import Style from "../../utils/Style";

// Assets
import Images from "../../../specific/utils/Images";

// CardFlip
import { useParams, useNavigate } from "react-router-dom";
import User from "../../data/user/User";
import ComponentProps from "../../../specific/utils/Interfaces/ComponentProps";


import SVGView from "../../utils/SvgView";

import { default as IcQuoteLeft } from "../../assets/svg/icons/solid/quote-left.svg";
import { default as IcQuoteRight } from "../../assets/svg/icons/solid/quote-right.svg";
import { getLocalQuotes } from "../../../specific/utils/LocalStorage";
import Button from "../../designSystem/Button";

interface QuotesManagementProps extends ComponentProps {

}

const QuotesManagement = (props: any) => {

    let propsData = props;
    let navigate = null;
    if (Platform.OS === "web") {
        propsData = useParams();
        navigate = useNavigate();
    }

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const { componentId } = propsData;
    const user: User = User.getInstance();
    const [quotes, setQuotes] = useState<{ data: any, ts: number }[] | undefined>(undefined);
    const [showForm, setShowForm] = useState<boolean>(false);

    const mainOptions: MainOptions = new MainOptions({
        key: "admin_quotes_management",
        componentId,
        showNavBar: true,
        showMenu: false,
        safeArea: false,
        canGoBack: true,
        title: "Gestion des Citations",
        navigationColor: appTheme.talk
    });


    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

    const onViewDidAppear = () => {
        if (viewDidAppear === false) {
            getQuotes();
        }
    }

    const getQuotes = async () => {
        const localQuotes = await getLocalQuotes();
        setQuotes(localQuotes);
    }

    useEffect(() => {
        if (quotes !== undefined) {
            setViewDidAppear(true);
        }
    }, [quotes]);

    const quoteCell = ({ item, index }) => {
        const aQuote = quotes.data[item];
        const quoteWidth = appTheme.getBlockWidth() - appTheme.pixelPerfect(60);
        return <View style={[{ width: appTheme.getFullAppWidth(), alignItems: "center", marginTop: appTheme.pixelPerfect(10) }]}>
            <View style={[Style.shadowed, { width: appTheme.getBlockWidth(), backgroundColor: appTheme.white, padding: appTheme.pixelPerfect(10), borderRadius: appTheme.pixelPerfect(10) }]}>
                <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(8) }}>
                    {aQuote.title}
                </Text>
                <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop:appTheme.pixelPerfect(10) }}>
                    <View style={{ justifyContent: "flex-start" }}>
                        <SVGView Component={IcQuoteLeft} size={12} color={appTheme.talk} />
                    </View>
                    <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.primaryBoldFont, width: quoteWidth, fontSize: appTheme.pixelPerfectForFont(6) }}>
                        {aQuote.quote}
                    </Text>
                    <View style={{ justifyContent: "flex-end" }}>
                        <SVGView Component={IcQuoteRight} size={12} color={appTheme.talk} />
                    </View>
                </View>
                <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(6), textAlign:"right" }}>
                    {aQuote.author}
                </Text>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(6) }}>
                        {aQuote.theme}
                    </Text>

                </View>
            </View>
        </View>
    }

    const getQuoteForm = () => {
        if (showForm === false) {
            return <View />
        }
        const paddingBottom = appTheme.pixelPerfect(20) + appTheme.bottomInsets;
        return <View style={{backgroundColor:appTheme.talk+"90", justifyContent:"flex-end", flex:1}}>
            <View style={{backgroundColor:appTheme.white, width:appTheme.getFullAppWidth(), borderTopEndRadius:appTheme.pixelPerfect(20), borderTopStartRadius:appTheme.pixelPerfect(20), paddingBottom, paddingTop:appTheme.pixelPerfect(10)}}>
            <Text>Ajouter une citation</Text>
            </View>
        </View>
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ width: appTheme.getFullAppWidth(), flex: 1, justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator color={appTheme.darkBlue} />
            </View>
        }
        return (<View style={{ width: appTheme.getFullAppWidth(), flex: 1, alignItems:"center" }}>
            <Button onPress={() => setShowForm(true)} title="Ajouter une citation" style={{ width:appTheme.getBlockWidth(), backgroundColor:appTheme.talk, marginTop:appTheme.pixelPerfect(10) }}/> 
            <FlatList style={{ width: appTheme.getFullAppWidth(), flex: 1 }} data={Object.keys(quotes.data)} keyExtractor={(item, index) => index} renderItem={quoteCell} />
        </View>)
    }

    return (
        <Main mainOptions={mainOptions}>
            {getContent()}
            <Modal transparent={true} animationType="slide" visible={showForm}>
                {getQuoteForm()}
            </Modal>
        </Main>
    )
}

export default QuotesManagement;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    switchMenu: {
        position: "absolute",
        left: 0,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between"
    }
});