import React, { useEffect, useRef, useState } from "react";

import {
    Platform,
    Text,
    View
} from "react-native";
import AppTheme from "../../../utils/Theme";
import Images from "../../../../specific/utils/Images";
import TextInput from "../../../designSystem/TextInput/RoundCornerTextInput";
import Divider from "../../../designSystem/Divider/Divider";
import RoundButton from "../../../designSystem/Buttons/RoundButton";
import LinkButton from "../../../designSystem/Buttons/LinkButton";
import i18n from "../../../utils/Localization/Localization";
import { validateEmail } from "../../../utils/StringUtils";
import { revokePassword, signInWithEmailAndPassword } from "../../../services/Auth";
import { relaunchApp } from "../../../../specific/utils/Navigation/Navigation";
import ComponentProps from "../../../../specific/utils/Interfaces/ComponentProps";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Alert from "../../Notification/Alert";

interface ForgotPasswordProps extends ComponentProps {
    onGoBackToSignInRequest: (email:string) => void
}

const emptyErrors = {
    email: ""
};

const ForgotPassword = (props: ForgotPasswordProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState(emptyErrors);

    const alertRef = useRef<Alert>(null);

    const { componentId = "", onGoBackToSignInRequest } = props;
    let navigate: NavigateFunction | string = "";
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const askToRevokePassword = async () => {
        try {
            const forgotPasswordResponse = await revokePassword({ email });
            setIsLoading(false);
            let title = "Modification de mot de passe";
            let body = "Un e-mail vient de vous être envoyé pour réinitialiser votre mot de passe";
            alertRef.current?.showAlert({
                title,
                body,
                onPressPrimaryButton: () => onGoBackToSignInRequest(email)
            });
        } catch (error) {
            setIsLoading(false);
            let title = "Une erreur est survenue";
            let body = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
            if (error.toString().indexOf("auth/user-disabled") !== -1) {
                title = "Compte désactivé";
                body = "Votre compte a été désactivé.\nN'hésitez pas à nous contacter : support@lili.cool";
            } else if ((error.toString().indexOf("auth/invalid-credential") !== -1) || (error.toString().indexOf("auth/wrong-password") !== -1) || (error.toString().indexOf("auth/user-not-found") !== -1)) {
                title = "Adresse e-mail ou mot de passe erronée";
                body = "Veuillez vérifier votre adresse e-mail et votre mot de passe s'il vous plaît.\n\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\"";
            } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                title = "Erreur de connexion 📡";
                body = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît."
            }
            alertRef.current?.showAlert({
                title,
                body
            });
        }
    }

    const onRevokePasswordButtonPressed = () => {
        setIsLoading(true);
        let signInErrors = { email: "", password: "" };
        if (email.length === 0) {
            signInErrors.email = "Votre e-mail ne peut être vide";
        } else {
            const isEmailValid = validateEmail(email);
            if (isEmailValid === false) {
                signInErrors.email = "La saisie de votre e-mail est incorrect";
            }
        }
        if ((signInErrors.email.length > 0) || (signInErrors.password.length > 0)) {
            setErrors(signInErrors);
            setIsLoading(false);
        } else {
            //Sign In
            askToRevokePassword();
        }
    }

    return (<View style={{ justifyContent: "center", alignItems: "center", width: appTheme.getBlockWidth() }}>
        <Text style={{ textAlign: "center", fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.primaryMediumFont, color: appTheme.darkBlue, paddingTop: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.pixelPerfect(20) }}>
            {i18n.t("authentication.forgotPassword.title").toLocaleUpperCase()}
        </Text>
        <Text style={{ textAlign: "center", fontSize: appTheme.pixelPerfectForFont(9), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, marginBottom: appTheme.pixelPerfect(10), paddingTop: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.pixelPerfect(20) }}>
            {i18n.t("authentication.forgotPassword.body")}
        </Text>
        <Divider />
        <TextInput onFocus={() => setErrors(emptyErrors)} error={errors.email} onChangeText={(text) => setEmail(text)} type="email" placeholder={i18n.t("authentication.signIn.form.emailPlaceholder")} />
        <Divider size={20} />
        <RoundButton isLoading={isLoading} onPress={onRevokePasswordButtonPressed} title={i18n.t("authentication.forgotPassword.buttons.send")} />
        <Divider size={20} />
        <LinkButton onPress={onGoBackToSignInRequest} title={i18n.t("authentication.forgotPassword.buttons.cancel")} />
        <Alert ref={alertRef} />
    </View>)

}

export default ForgotPassword;