import React, { useRef, useState } from "react";

import {
    Image,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    SafeAreaView,
    ScrollView,
    Text,
    TextStyle,
    TouchableOpacity,
    View,
    ViewStyle
} from "react-native";
import Main from "../../../specific/components/Main/Main";
import Images from "../../../specific/utils/Images";
import ComponentProps from "../../../specific/utils/Interfaces/ComponentProps";
import User from "../../data/user/User";
import MainOptions from "../../models/MainOptions";
import AppTheme from "../../utils/Theme";
import DynamicBackground from "../DynamicBackground/DynamicBackground";


import SVGView from "../../utils/SvgView";
import { default as IcUpArraw } from "../../assets/svg/icons/solid/angle-up.svg";
import { default as IcDownArrow } from "../../assets/svg/icons/solid/angle-down.svg";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";

interface LegalMentionsPros extends ComponentProps {
    reference: string
}

const LegalMentions = (props: LegalMentionsPros) => {

    let propsData = props;
    
    const user: User = User.getInstance();
    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [pageOffset, setPageOffset] = useState<number>(0);
    const [listSize, setListSize] = useState<number>(0);

    const scrollViewRef = useRef<ScrollView>(null);
    const onViewDidAppear = () => {

    }

    const navigationColor = user.isTeacher() === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor;
    const mainOptions: MainOptions = new MainOptions({
        key:"leg_mention",
        componentId: props.componentId,
        navigationColor,
        showNavBar: false,
        showMenu: false,
        canBeHidden: true,
        safeArea: false,
        canGoBack: false,
        backgroundColor: user.isTeacher() === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        canBeDismissed: false
    });

    const textColor = user.isTeacher() === true ? appTheme.darkBlue : appTheme.getParentTextColor();

    const titleView: ViewStyle = {
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: appTheme.pixelPerfect(10)
    }

    const paragrapheView: ViewStyle = {
        paddingVertical: appTheme.pixelPerfect(10)
    }

    const paragrapheText: TextStyle = {
        fontFamily: appTheme.secondaryFont,
        fontSize: appTheme.pixelPerfectForFont(8)
    }

    const titleNumberViewStyle: ViewStyle = {
        backgroundColor: appTheme.talk,
        width: appTheme.pixelPerfect(20),
        height: appTheme.pixelPerfect(20),
        borderRadius: appTheme.pixelPerfect(10),
        justifyContent: "center",
        alignItems: "center",
        marginEnd: appTheme.pixelPerfect(10)
    }

    const titleNumberStyle: TextStyle = {
        color: appTheme.white,
        fontFamily: appTheme.primaryBoldFont,
        fontSize: appTheme.pixelPerfectForFont(9)
    }

    const sectionTitleStyle: TextStyle = {
        color: appTheme.textColor,
        fontFamily: appTheme.primaryBoldFont,
        fontSize: appTheme.pixelPerfectForFont(9)
    }

    const arrowViewHeight = appTheme.getFullAppHeight() - appTheme.pixelPerfect(150);
    const scrollArrowView = Platform.OS === "web" ? <View style={{ position: "absolute", right: appTheme.pixelPerfect(10), flex: 1, bottom: appTheme.pixelPerfect(30), justifyContent: "space-between", height: arrowViewHeight }}>
        <TouchableOpacity onPress={() => moveScrollView(true)} style={{ backgroundColor: appTheme.talk, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), justifyContent: "center", alignItems: "center", borderRadius: appTheme.pixelPerfect(15) }}>
            <SVGView Component={IcUpArraw} size={appTheme.pixelPerfect(20)} color={appTheme.white} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => moveScrollView(false)} style={{ backgroundColor: appTheme.talk, width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), justifyContent: "center", alignItems: "center", borderRadius: appTheme.pixelPerfect(15) }}>
            <SVGView Component={IcDownArrow} size={appTheme.pixelPerfect(20)} color={appTheme.white} />
        </TouchableOpacity>
    </View> : <View />;

    const moveScrollView = (moveUp: boolean) => {
        if (moveUp === true) {
            if (pageOffset > 0) {
                const newOffset = pageOffset - appTheme.getFullAppHeight();
                scrollViewRef.current?.scrollTo({ x: 0, y: newOffset });
                setPageOffset(newOffset);
            }
        } else {
            if ((pageOffset < listSize) || (listSize === 0)) {
                const newOffset = pageOffset + appTheme.getFullAppHeight();
                scrollViewRef.current?.scrollTo({ x: 0, y: newOffset });
                setPageOffset(newOffset);
            }
        }
    }

    const onScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        if (listSize === 0) {
            setListSize(e.nativeEvent.contentSize.height);
        }
    }

    const getCookiesView = () => {
        const appTarget = getAppTarget();
        if (appTarget === "public") {
            return [<View>
                <Text style={paragrapheText}>
                    Il s’agit des cookies/SDK suivants :
                </Text>
                <Text style={paragrapheText}>
                    ✔️ Firebase Auth afin de protéger l’identité de l’utilisateur et lui permettre de conserver une session authentifiée. Les cookies sont supprimés à la déconnexion de l'utilisateur ou à la suppression de son compte.
                </Text>
                <Text style={paragrapheText}>
                    ✔️ AsyncStorage et localStorage à des fins légitimes pour recueillir le consentement de l’utilisateur en matière de cookies et conserver des données de navigation nécessaires au bon fonctionnement de l’Application. Ces cookies sont conservés pendant une durée maximum de 13 (treize) mois.
                </Text>
                <Text style={paragrapheText}>
                    ✔️ Firebase Crashlytics afin de receuillir les données de performances et de dysfonctionnement de l'application LILI.
                </Text>
            </View>,
            <View>
                <Text style={paragrapheText}>
                    Nous utilisons également des cookies non essentiels. Ces cookies ne sont déposés que si vous donnez votre accord dès lors qu’ils n’ont pas pour finalité exclusive de permettre ou de faciliter la navigation sur l’Application ou qu’ils ne sont pas strictement nécessaires à la fourniture des fonctionnalités et services de l’Application.
                </Text>
                <Text style={paragrapheText}>
                    ✔️ Amplitude à des fins de performances.
                </Text>
                <Text style={paragrapheText}>
                    ✔️ Adjust à des fins marketing pour l’attribution des campagnes marketing.
                </Text>
            </View>]
        } else {
            return [<View>
                <Text style={paragrapheText}>
                    Il s’agit des cookies/SDK suivants :
                </Text>
                <Text style={paragrapheText}>
                    ✔️ AsyncStorage et localStorage à des fins légitimes pour recueillir le consentement de l’utilisateur en matière de cookies et conserver des données de navigation nécessaires au bon fonctionnement de l’Application. Ces cookies sont conservés pendant une durée maximum de 13 (treize) mois.
                </Text>
            </View>,
            <View>
                <Text style={paragrapheText}>
                    Nous utilisons également des cookies non essentiels. Ces cookies ne sont déposés que si vous donnez votre accord dès lors qu’ils n’ont pas pour finalité exclusive de permettre ou de faciliter la navigation sur l’Application ou qu’ils ne sont pas strictement nécessaires à la fourniture des fonctionnalités et services de l’Application.
                </Text>
                <Text style={paragrapheText}>
                    ✔️ Amplitude à des fins de performances.
                </Text>
            </View>]
        }
        
    }

    const getContent = () => {
        if (props.reference === "legal") {
            return <View style={{ flex: 1, paddingBottom: appTheme.pixelPerfect(30) }}>
                <View style={titleView}>
                    <View style={titleNumberViewStyle}>
                        <Text style={titleNumberStyle}>1</Text>
                    </View>
                    <Text style={sectionTitleStyle}>Propriété intellectuelle</Text>
                </View>

                <View style={paragrapheView}>
                    <Text style={paragrapheText}>
                        L’application Lili et le site Internet lili.cool sont édités par la société LILI SAS au capital de 10 000 euros.
                    </Text>
                </View>

                <View style={paragrapheView}>
                    <Text style={paragrapheText}>
                        LILI SAS
                    </Text>
                    <Text style={paragrapheText}>
                        RCS Créteil B 883 431 058
                    </Text>
                    <Text style={paragrapheText}>
                        Code APE 6201Z
                    </Text>
                    <Text style={paragrapheText}>
                        N° TVA : FR 16 883 431 058
                    </Text>
                    <Text style={paragrapheText}>
                        Siège social : 38 rue de la Liberté, 94300 Vincennes, France
                    </Text>
                    <Text style={paragrapheText}>
                        Président : Arnaud Massonnie
                    </Text>
                </View>

                <View>
                    <Text style={paragrapheText}>
                        Le site est hébergé par OVH, 2 rue Kellerman, 59100 Roubaix. Tout le contenu et les services de l’application et du site Internet sont protégés par la législation française et les traités internationaux (Convention de Berne pour la protection des œuvres littéraires et artistiques, Code de la propriété intellectuelle, Digital Millennium Copyright Act). Ils ne peuvent être recopiés sans l’autorisation expresse de LILI SAS. La réutilisation du contenu, illustrations, animations, podcasts et ateliers papiers présents dans l’application ou le site Internet est strictement interdite. La simple diffusion de ces contenus est néanmoins envisageable pour des usages non commerciaux, à condition qu’ils ne soient pas modifiés et sous réserve d’un accord écrit préalable de LILI SAS.
                    </Text>
                </View>

                <View style={titleView}>
                    <View style={titleNumberViewStyle}>
                        <Text style={titleNumberStyle}>2</Text>
                    </View>
                    <Text style={sectionTitleStyle}>Conditions générales</Text>
                </View>

                <View>
                    <Text style={paragrapheText}>
                        Le présent document fixe les conditions générales d’utilisation du contenu et des services de Lili. En fonction de l’évolution de notre offre, nous nous réservons la possibilité de modifier celles-ci et vous demandons par conséquent de vous y reporter régulièrement. Le contenu et les services sont accessibles en partie gratuitement. Après souscription d’un abonnement Premium, vous pouvez accéder à l’intégralité du contenu et des services tant que votre abonnement est effectif ; vous pouvez également y avoir accès gratuitement pendant une période déterminée, conformément à certaines offres promotionnelles. Un abonnement Premium est limité à trois appareils d’un même foyer (pour l’offre grand public) ou d’un même enseignant (pour l’offre Lili à l’école) et une seule connexion simultanée via l’application Lili (sur smartphone, iPod Touch ou tablette pour le grand public ; augmenté de l’accès possible par ordinateur pour l’enseignant) sauf souscription à une offre spécifique.
                    </Text>
                </View>

                <View style={titleView}>
                    <View style={titleNumberViewStyle}>
                        <Text style={titleNumberStyle}>3</Text>
                    </View>
                    <Text style={sectionTitleStyle}>Souscription d’un abonnement</Text>
                </View>

                <View>
                    <Text style={paragrapheText}>
                        La conclusion d’un abonnement Premium intervient au moment de l’envoi d’un e-mail détaillant les caractéristiques de votre demande d’abonnement, à la date spécifiée, à l’adresse de messagerie que vous nous avez communiquée. Il vaut acceptation de notre part de votre demande. Si vous effectuez votre achat par le biais d’un de nos partenaires ou de l’App Store d’une société tierce, cet achat pourra être soumis aux conditions générales de ces partenaires qui pourront prendre en charge la gestion du paiement et de l’abonnement. Lili peut proposer des abonnements Premium mensuels, trimestriels, semestriels et annuels. Les abonnements mensuels sont payables en versements mensuels. Pour chaque mois au cours duquel votre abonnement mensuel est actif, vous reconnaissez et acceptez que Lili est autorisée à prélever le montant des frais d’abonnement mensuels convenus sur la même carte de crédit ou sur tout autre moyen de paiement fourni, et ce de manière tacitement reconductible jusqu’à la résiliation de votre abonnement. Vous devez résilier votre abonnement avant son renouvellement mensuel afin que les frais d’abonnement du mois suivant ne soient pas prélevés sur le moyen de paiement que vous avez fourni. Aucun remboursement ne pourra être demandé pour toute période d’abonnement mensuel commencée. De la même façon, les abonnements trimestriels, semestriels et annuels sont tacitement reconductibles, prélevés en début de période, et doivent être résiliés avant le renouvellement de leur période, aucun remboursement ne pouvant être demandé pour toute période d’abonnement commencée.
                    </Text>
                </View>

                <View style={titleView}>
                    <View style={titleNumberViewStyle}>
                        <Text style={titleNumberStyle}>4</Text>
                    </View>
                    <Text style={sectionTitleStyle}>Offre d’un abonnement cadeau</Text>
                </View>

                <View>
                    <Text style={paragrapheText}>
                        Les abonnements cadeaux sont prépayés en un versement initial unique, au moment de l’achat. Une fois l’abonnement acheté, celui qui l’a offert reçoit un e-mail valant confirmation de commande, et le parent de l’enfant bénéficiaire recevra un e-mail de confirmation d’abonnement à la date indiquée, à l’adresse e-mail du parent de l’enfant bénéficiaire indiquée. Les abonnements cadeaux ne peuvent donner lieu à la remise de leur contre-valeur en argent si le parent de l’enfant bénéficiaire a déjà acheté un abonnement.
                    </Text>
                </View>

                <View style={titleView}>
                    <View style={titleNumberViewStyle}>
                        <Text style={titleNumberStyle}>5</Text>
                    </View>
                    <Text style={sectionTitleStyle}>Clôture d’un abonnement</Text>
                </View>

                <View>
                    <Text style={paragrapheText}>
                        Si vous achetez un abonnement par le biais de l’Apple iTunes Store ou de notre application Lili pour iPhone, iPod Touch et iPad (et possiblement pour l’ordinateur pour l’enseignant), vous pouvez annuler votre abonnement en annulant le renouvellement automatique des abonnements in-App payés en sélectionnant « gestion des abonnements des applications » dans les paramètres de votre compte iTunes et en choisissant l’abonnement que vous souhaitez modifier. Si vous achetez un abonnement par le biais du Google Play Store sur un appareil Android, vous pouvez annuler les renouvellements automatiques en accédant aux abonnements dans les paramètres de votre compte sur l’application Google Play de votre appareil, ou en vous conformant à la procédure en vigueur indiquée par Google Play. Si vous achetez un abonnement par le biais de notre site Internet, vous pouvez annuler votre abonnement directement depuis votre appareil, de la même façon qu’expliqué précédemment. Nous pouvons être amenés, à titre exceptionnel, à suspendre ou mettre un terme à votre utilisation du contenu et des services Lili pour un motif valable, et ce avec possible effet immédiat, en particulier en cas de fraude de votre part comme la copie, le téléchargement ou la distribution de notre contenu sans autorisation, ou en cas d’usage sur plus de deux appareils d’un même foyer. Lili exercera en cas de fraude ses droits de propriété intellectuelle dans toute la mesure permise par la réglementation en vigueur, y compris en engageant des poursuites pénales.
                    </Text>
                </View>

                <View style={titleView}>
                    <View style={titleNumberViewStyle}>
                        <Text style={titleNumberStyle}>6</Text>
                    </View>
                    <Text style={sectionTitleStyle}>Protection de la vie privée</Text>
                </View>

                <View>
                    <Text style={paragrapheText}>
                        Lili utilise des cookies, afin d’analyser le trafic, améliorer l’expérience de ses utilisateurs, et optimiser sa communication digitale. Dans un souci de respect de la vie privée de ses utilisateurs, LILI SAS s’engage à ce que la collecte et le traitement d’informations personnelles, effectués au sein de l’application et du site Internet, soient effectués conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, dite Loi « Informatique et Libertés ». Les informations que nous recueillons proviennent de l’inscription volontaire à nos services. Les renseignements que vous nous communiquez ne sont jamais diffusés à des tiers. Conformément à l’article 34 de la loi Informatique et Libertés du 6 janvier 1978, vous disposez d’un droit d’accès, de rectification, de modification et de suppression concernant les données qui vous concernent. Vous pouvez exercer ce droit depuis les e-mails qui sont vous adressés ou en nous écrivant à l’adresse suivante : hello@lili.cool. Merci pour votre confiance.
                    </Text>
                </View>
            </View>
        } else if (props.reference === "privacy") {
            return <View style={{ flex: 1, paddingBottom: appTheme.pixelPerfect(30) }}>
                <View style={paragrapheView}>
                    <Text style={paragrapheText}>
                        Nous vous informons que des cookies/SDK sont utilisés tout au long de votre navigation sur l’application LILI.
                    </Text>
                </View>

                <View style={paragrapheView}>
                    <Text style={paragrapheText}>
                        Les cookies sont témoins de connexion sous forme de fichiers textes, souvent cryptés, stockés dans votre navigateur. Ils sont créés lorsque le navigateur d’un utilisateur charge un site internet ou une application mobile : le site ou l’application mobile envoie des informations au navigateur, qui crée alors un fichier texte. Chaque fois que l’utilisateur revient sur le même site ou l’application mobile, le navigateur récupère ce fichier et l’envoie au serveur. Un cookie peut par exemple être utilisé pour mémoriser les réponses renseignées dans un formulaire ou encore sauvegarder les données de connexion.
                    </Text>
                </View>

                <View>
                    <Text style={paragrapheText}>
                        Nous utilisons des cookies/SDK qui ont pour finalité exclusive de permettre et de faciliter la navigation ou qui sont strictement nécessaires à la fourniture des fonctionnalités de l’application Lili.
                    </Text>
                </View>

                {getCookiesView()}

                <View>
                    <Text style={paragrapheText}>
                        Ces cookies sont conservés pendant une durée maximum de 13 (treize) mois. Lors de votre navigation sur l’Application, vous pouvez les accepter ou les refuser lors de votre premier session puis à tout moment via la rubrique “Paramètres de confidentialité” de l’Application. Nous vous informons qu’en cas de refus de ces cookies, l’usage des fonctionnalités et services de l’Application est susceptible d’être altéré.
                    </Text>
                </View>

                <View>
                    <Text style={paragrapheText}>
                        Les cookies sont des fichiers textes, souvent cryptés, stockés dans votre navigateur. Ils sont créés lorsque le navigateur d’un Utilisateur charge un site internet donné : le site envoie des informations au navigateur, qui crée alors un fichier texte. Chaque fois que l’Utilisateur revient sur le même site, le navigateur récupère ce fichier et l’envoie au serveur du site internet.
                    </Text>
                </View>

                <View>
                    <Text style={paragrapheText}>
                        Les cookies techniques sont utilisés tout au long de votre navigation, afin de la faciliter et d’exécuter certaines fonctions. Un cookie technique peut par exemple être utilisé pour mémoriser les réponses renseignées dans un formulaire ou encore sauvegarder les données de connexion. Nous utilisons des cookies techniques. Ces cookies ne sont déposés que si vous donnez votre accord. Vous pouvez vous informer sur leur nature, les accepter ou les refuser. Les cookies des réseaux sociaux peuvent être créés par les plateformes sociales pour permettre aux concepteurs de sites internet de partager le contenu de leur site sur lesdites plateformes. Ces cookies peuvent notamment être utilisés par les plateformes sociales pour tracer la navigation des internautes sur le site internet concerné, qu’ils utilisent ou non ces cookies. Nous utilisons des cookies des réseaux sociaux. Ces cookies ne sont déposés que si vous donnez votre accord. Vous pouvez vous informer sur leur nature, les accepter ou les refuser. Nous vous invitons par ailleurs à consulter les politiques de protection de la vie privée des plateformes sociales à l’origine de ces cookies, pour prendre connaissance des finalités d’utilisation des informations de navigation qu’elles peuvent recueillir grâce à ces cookies et des modalités d’exercice de vos droits auprès de ces plateformes.</Text>
                </View>

                <View>
                    <Text style={paragrapheText}>
                        Nous vous rappelons à toutes fins utiles qu’il vous est possible de vous opposer au dépôt de cookies en configurant votre navigateur. Un tel refus pourrait toutefois empêcher le bon fonctionnement de l’Application.
                    </Text>
                </View>
            </View>
        }
        return <View />
    }

    const getPageTitle = () => {
        if (props.reference === "legal") {
            return "Mentions légales"
        } else if (props.reference === "privacy") {
            return "Politique de cookies/SDK"
        }
        return "";
    }

    return <Main mainOptions={mainOptions}>
        <DynamicBackground isTeacher={user.isTeacher()} />
        <SafeAreaView onLayout={onViewDidAppear} style={{ flex: 1, alignItems: "center", width: appTheme.getFullAppWidth() }}>
            <Image source={images.icAbout} style={{ width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(70) }} resizeMode="contain" />
            <Text
                accessible={true}
                accessibilityLabel={"Contact"}
                accessibilityRole="text"
                style={[{ textAlign: 'center', fontSize: appTheme.pixelPerfectForFont(14), color: textColor }]}>{getPageTitle().toLocaleUpperCase()}</Text>
            <ScrollView onScroll={onScroll} ref={scrollViewRef} style={{ backgroundColor: appTheme.backgroundColor, padding: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth(), borderRadius: appTheme.pixelPerfect(20), marginTop: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(20) }}>
                {getContent()}
            </ScrollView>
            {scrollArrowView}
        </SafeAreaView>
    </Main>
}

export default LegalMentions;