import { ViewStyle, TextStyle } from "react-native";
import AppTheme from "../../utils/Theme";

export default class AuthHeaderStyle {

    appTheme: AppTheme = new AppTheme();

    titleContainer: ViewStyle = {
        paddingVertical: this.appTheme.pixelPerfect(10)
    }

    title: TextStyle = {
        fontFamily: this.appTheme.primaryBoldFont, 
        textAlign:"center",
        textTransform: "uppercase",
        color: this.appTheme.darkBlue,
        fontSize: this.appTheme.pixelPerfectForFont(6),
        paddingHorizontal: this.appTheme.pixelPerfect(10)
    }

    superHerostitle: TextStyle = {
        ...(this.title as object),
        fontFamily: this.appTheme.liliFont,
        fontSize: this.appTheme.pixelPerfectForFont(9)
    }

    leftDetailsView: ViewStyle = { 
        borderTopRightRadius: this.appTheme.pixelPerfect(10), 
        borderBottomRightRadius: this.appTheme.pixelPerfect(10), 
        minHeight: this.appTheme.pixelPerfect(90),
        backgroundColor: this.appTheme.white, 
        justifyContent: "center", 
        alignItems: "center" 
    }

    rightDetailsView: ViewStyle = { 
        ...(this.leftDetailsView as object),
        borderTopRightRadius: 0, 
        borderBottomRightRadius: 0, 
        borderTopLeftRadius: this.appTheme.pixelPerfect(10), 
        borderBottomLeftRadius: this.appTheme.pixelPerfect(10), 
        backgroundColor: this.appTheme.white, 
        justifyContent: "center", 
        alignItems: "flex-end",
        paddingRight: this.appTheme.pixelPerfect(5) 
    }

    constructor() {

    }
}