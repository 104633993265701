/* REACT */
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Share } from 'react-native';

import i18n, { getLanguage } from '../../utils/Localization/Localization';

import AppTheme from '../../utils/Theme';

import SVGView from '../../utils/SvgView';
import { default as IcFilm } from "../../assets/svg/icons/solid/film.svg";
import { default as IcHeadphones } from "../../assets/svg/lili/ic_audio_book.svg";
import { default as IcSearch } from "../../assets/svg/lili/ic_search.svg";
import { default as IcStar } from "../../assets/svg/lili/ic_star.svg";
import { default as IcFilePdf } from "../../assets/svg/lili/ic_pdf.svg";


const ActivityMediaCell = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const { item, color, displayActivities, index, screenWidth=appTheme.getFullAppWidth() } = props;
    
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setRefresh(!refresh);
    }, [screenWidth]);

    const onPress = () => {
        if (displayActivities === true) {
            if (props.onPress !== undefined) {
                props.onPress(item, color, index);
            }
        } else {
            if (props.showPremiumAlert !== undefined) {
                props.showPremiumAlert();
            }
        }
    }

    if (item === undefined) {
        return <View />
    }
    let title = ((item.title !== undefined) && (item.title[getLanguage()] !== undefined)) ? item.title[getLanguage()] : "";
    let subtitle = item.owner !== undefined ? item.owner : item.type === 'pdf' ? i18n.t('media.toDownload') : '';
    let mediaType = item.type === 'pdf' ? 'PDF' : item.type === 'preview' ? 'Aperçu' : item.type === 'video' ? 'Vidéo' : 'Livre audio';
    let separatorView = subtitle.length > 0 ? <View style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: color, marginHorizontal: appTheme.pixelPerfect(5) }} /> : <View />;
    const duration = item.duration !== undefined ? item.duration : mediaType;
    const iconComponent = displayActivities === false ? IcStar : item.type !== undefined ? item.type === 'pdf' ? IcFilePdf : item.type === 'preview' ? IcSearch : IcHeadphones : IcHeadphones;
    const iconView = ((item.type === 'video') && (displayActivities === true)) ? <SVGView Component={IcFilm} size={appTheme.pixelPerfect(18)} color={appTheme.white} />  : <SVGView Component={iconComponent} size={appTheme.pixelPerfect(18)} color={appTheme.white} />;
    return (
        <TouchableOpacity onPress={onPress} style={{ width:screenWidth, paddingHorizontal: appTheme.pixelPerfect(10), paddingVertical: appTheme.pixelPerfect(10), backgroundColor: appTheme.white, flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
                <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue }}>{title.toLocaleUpperCase()}</Text>
                <View style={{ flexDirection: 'row', marginTop: appTheme.pixelPerfect(5), alignItems:"center" }}>
                    <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: color }}>{subtitle.toLocaleUpperCase()}</Text>
                    {separatorView}
                    <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: color }}>{duration.toLocaleUpperCase()}</Text>
                </View>
                <View style={{ height: 1, backgroundColor: appTheme.schoolBackgroundColor, marginTop: appTheme.pixelPerfect(20) }} />
            </View>
            <View style={{ width: appTheme.pixelPerfect(40), marginStart:appTheme.pixelPerfect(10) }}>
                <View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), backgroundColor: color, borderRadius: appTheme.pixelPerfect(20), justifyContent: 'center', alignItems: 'center' }}>
                    {iconView}
                </View>
            </View>
        </TouchableOpacity>
    )

}

export default ActivityMediaCell;