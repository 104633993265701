import React, { useEffect, useState } from "react";

import {
    Dimensions,
    StyleSheet,
    Pressable,
    Text,
    ActivityIndicator,
    View
} from 'react-native';

import AppTheme, { Theme } from "../utils/Theme";

import SVGView from "../utils/SvgView";
import { default as Check } from "../assets/svg/icons/solid/check.svg";


const RadioButton = (props: any) => {

    const {
        disabled,
        onLongPress,
        onPress,
        style,
        radioButtonStyle,
        value = false,
        centerButton = false
    } = props;

    const [isSelected, setIsSelected] = useState(value);

    useEffect(() => {
        setIsSelected(props.value);
    }, [props]);

    const appTheme: AppTheme = new AppTheme();

    const buttonStyle = {
        width: appTheme.pixelPerfect(16),
        height: appTheme.pixelPerfect(16),
        borderRadius: appTheme.pixelPerfect(8),
        justifyContent: "center",
        alignItems: "center",
        borderWidth: appTheme.pixelPerfect(0.5),
        marginEnd: appTheme.pixelPerfect(14)
    };
    let selectedBackgroundColor = ((style !== undefined) && (style.backgroundColor !== undefined)) ? style.backgroundColor : appTheme.buttonBackgroundColor;
    let unSelectedBackgroundColor = appTheme.white;
    const isDisabled = disabled !== undefined ? disabled : false;

    const onRadioButtonPress = () => {
        if (onPress !== undefined) {
            onPress({ value: !isSelected });
        }
        setIsSelected(!isSelected);
    }

    const alignItems = centerButton === true ? "center" : "flex-start";

    return (
        <Pressable
            disabled={isDisabled}
            onLongPress={onLongPress}
            onPress={onRadioButtonPress}
            style={({ pressed }) => [
                {
                    opacity: pressed === true ? 0.5 : 1,
                    flexDirection: "row",
                    alignItems,
                    justifyContent:"center"
                },
                style]}>
            <View style={[buttonStyle, radioButtonStyle, { backgroundColor: isSelected === true ? selectedBackgroundColor : unSelectedBackgroundColor, borderColor: selectedBackgroundColor, justifyContent: "center" }]}>
                <SVGView Component={Check} size={appTheme.pixelPerfect(10)} color={appTheme.white} />
            </View>
            <View style={{ flex: 1 }}>
                {props.children}
            </View>
        </Pressable>
    )

}

export default RadioButton;