import React, { useState } from 'react';
import { TextInput as RNTextInput, TextInputProps, TextStyle, View, Text } from 'react-native';
import TextInputStyle from './TextInputStyle';

interface LiliTextInputProps extends TextInputProps {
    legend?: string
}

const TextInput = (props: LiliTextInputProps) => {

    const { style, multiline=false, legend = "", ...restProps } = props;
    const textInputStyle: TextInputStyle = new TextInputStyle();

    let inputStyle: TextStyle = multiline === true ? textInputStyle.inputMultiline : textInputStyle.input;

    const legendView = legend.length > 0 ? <Text style={textInputStyle.legend}>{legend}</Text> : <View />;
    return (
        <View>
            <RNTextInput
                style={[inputStyle, style]}
                {...restProps}
            />
            {legendView}
        </View>
    );
};

export default TextInput;