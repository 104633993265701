import React from "react";

import {
    View,
} from 'react-native';

export async function relaunchLostDownloads() {

}

export const downloadUrl = (data: { shortname: string, urlToDownload: string, destination: string }) => {

}

export const doesFileExist = async (path:string) => {
    return false;
}

export const getShortNameForUrl = (url: string) => {

}

export const getPathForOriginal = (shortname: string, extension: string, activityKey: string) => {

}

export const getDirPath = (activityKey: string) => {

}

export const toggleOfflineData = async (offline: any | undefined, activity_key: string) => {
}

export const deleteFileForActivityKey = async (activity_key:string) => {
}

const DownloadButton = () => {
    return <View />;
};

export default DownloadButton;