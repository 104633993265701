import React, { useState } from "react";

import {
    View,
    Text,
    Image,
    TouchableOpacity,
    Platform
} from "react-native";
import AppTheme from "../../utils/Theme";
import Images from "../../../specific/utils/Images";
import GameStyle from "../../styles/game/GameStyle";
import Activity from "../../models/Activity";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";
import { checkLocalUrl } from "../../utils/LocalStorage";
import { getAudioUrlForMediaId } from "../../../specific/services/AppWrite/AppWriteDatabase";
import AudioPlayer from "../../../specific/utils/AudioPlayer/AudioPlayer";
import { getLanguage } from "../../utils/Localization/Localization";

interface GameIntroductionProps {
    activity: Activity,
    color: string,
    onPlayerEnd: () => void,
    titleView: JSX.Element
}

const GameIntroduction = (props: GameIntroductionProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const gameStyle: GameStyle = new GameStyle();

    const { activity, color, onPlayerEnd, titleView } = props;

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

    const onViewDidAppear = () => {
        setViewDidAppear(true);
    }

    const skipRules = () => {
        onPlayerEnd();
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear}  style={{ width:1, height:1 }} />
        }
        let actionButton = <View />;
        const actionAudio = ((activity.offline !== undefined) && (activity.offline.action !== undefined) && (activity.offline.action.audio !== undefined) && (Platform.OS !== "web")) ? activity.offline.action.audio : activity.action.audio;
        const appTarget = getAppTarget();
        if (actionAudio !== undefined) {
            let audioList = [require("../../assets/audio/lili_je_m_exprime.mp3")];
            for (const aAudioIndex in actionAudio[getLanguage()]) {
                if (Object.prototype.hasOwnProperty.call(actionAudio[getLanguage()], aAudioIndex)) {
                    if (appTarget === "public") {
                        const audio = checkLocalUrl(actionAudio[getLanguage()][aAudioIndex]);
                        audioList.push(audio);
                    } else {
                        const media_id = activity.key + "_" + aAudioIndex;
                        audioList.push(getAudioUrlForMediaId({ media_id }))
                    }
                }
            }
            actionButton = <AudioPlayer
                onPlayerEnd={onPlayerEnd}
                autoLaunch={false}
                color={color}
                urls={audioList} />;
        }

        return (
            <View style={gameStyle.backgroundImageStyle}>
                {titleView}
                <View style={[{ justifyContent: 'center', alignItems: 'center', marginVertical: appTheme.pixelPerfect(20), width: "100%" }]}>
                    {actionButton}
                </View>
                <TouchableOpacity onPress={skipRules} style={{ backgroundColor: appTheme.white, borderRadius: 10 }}>
                    <Text style={{ padding: 10, color, fontFamily: appTheme.secondaryFont, fontSize: 16 }}>Ne pas écouter les consignes du jeu</Text>
                </TouchableOpacity>
            </View>
        )
    }

    return getContent();

}

export default GameIntroduction;