import React from "react";
import { useState, useEffect } from "react";

import {
    View
} from 'react-native';

import AppTheme from "../../utils/Theme";
import Image from "../../designSystem/OTBImage";

// Assets
import Images from "../../../specific/utils/Images";
import User from "../../data/user/User";

const AvatarView = (props: any) => {

    const [refresh, setRefresh] = useState(0);

    const appTheme: AppTheme = new AppTheme();

    const { width = appTheme.pixelPerfect(100), height = appTheme.pixelPerfect(100) } = props;
    
    useEffect(() => {
        setRefresh(props.refresh);
    }, [props])

    const images: Images = new Images();
    const user: User = User.getInstance();

    const avatarTable = {
        asian: [
            images.liliBadgeAsian,
            images.liliBadgeAsianLevel1,
            images.liliBadgeAsianLevel2,
            images.liliBadgeAsianLevel3,
            images.liliBadgeAsianLevel4,
            images.liliBadgeAsianLevel5
        ],
        base: [
            images.liliBadgeBase,
            images.liliBadgeBaseLevel1,
            images.liliBadgeBaseLevel2,
            images.liliBadgeBaseLevel3,
            images.liliBadgeBaseLevel4,
            images.liliBadgeBaseLevel5
        ], 
        catwoman: [
            images.liliBadgeCatwoman,
            images.liliBadgeCatwomanLevel1,
            images.liliBadgeCatwomanLevel2,
            images.liliBadgeCatwomanLevel3,
            images.liliBadgeCatwomanLevel4,
            images.liliBadgeCatwomanLevel5
        ],
        chevalier: [
            images.liliBadgeChevalier,
            images.liliBadgeChevalierLevel1,
            images.liliBadgeChevalierLevel2,
            images.liliBadgeChevalierLevel3,
            images.liliBadgeChevalierLevel4,
            images.liliBadgeChevalierLevel5
        ],
        cowboy: [
            images.liliBadgeCowboy,
            images.liliBadgeCowboyLevel1,
            images.liliBadgeCowboyLevel2,
            images.liliBadgeCowboyLevel3,
            images.liliBadgeCowboyLevel4,
            images.liliBadgeCowboyLevel5
        ],
        fleur: [
            images.liliBadgeFleur,
            images.liliBadgeFleurLevel1,
            images.liliBadgeFleurLevel2,
            images.liliBadgeFleurLevel3,
            images.liliBadgeFleurLevel4,
            images.liliBadgeFleurLevel5
        ]
    }

    let imageSource = avatarTable.base[user.level];
    // On va voir si l'utilisateur est abonné
    let isUserSubscriber = false;
    const now = new Date().getTime();
    if (user.sub !== undefined) {
        if ((user.sub.start_date < now) && (user.sub.expires_date > now)) {
            isUserSubscriber = true;
        }
    }
    if (isUserSubscriber === true) {
        if (user.avatar !== undefined) {
            if (avatarTable[user.avatar] !== undefined) {
                if (avatarTable[user.avatar][user.level] !== undefined) {
                    imageSource = avatarTable[user.avatar][user.level];
                }
            }
        }
    }

    return (
        <View>
            <Image source={imageSource} style={{width, height}} />
        </View>
    )
}

export default AvatarView;