import React from "react";

import {
    View,
    Text,
    SafeAreaView,
    Image,
    ScrollView
} from "react-native";
import Images from "../../../specific/utils/Images";
import AppTheme, { white } from "../../utils/Theme";
import Style from "../../utils/Style";
import Button from "../../designSystem/Button";
import User from "../../data/user/User";

const SubscriptionDetails = (props: { onCloseRequest: () => void }) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();

    const { onCloseRequest } = props;

    const parentsArguments = [
        "l’intégralité des 300 activités audio et papier proposées dans les 7 super-programmes pour développer ses super-pouvoirs \"sans écran\"",
        "des temps calmes et des relaxations pour s’endormir, de l’art et des jeux d’impro, et des podcasts philo",
        "diffusables sur mobile, tablette et ordinateur",
        "l’enregistrement en local des activités pour un usage sans Internet (idéal pour le train !)",
        "des conseils d’experts en pédagogie et en neuroscience",
        "un programme Bien-être pour les parents"
    ];
    const teacherArguments = [
        "300 activités collectives (temps calmes, art et impro, ateliers philo) conformes au programme de maternelle, élémentaire et collège",
        "7 séquences pédagogiques clé en main pour développer les CPS avec des fiches de préparation et de liaison",
        "diffusable sur TNI, ordinateur, mobile et tablette",
        "enregistrement en local des activités pour un usage sans Internet en classe",
        "l’accompagnement conçu avec des enseignants et chercheurs dont Edwige Chirouter et Jean-Charles Pettier (conseils, module de formation aux ateliers philo)",
        "un programme Bien-être des super-profs"
    ]
    const animatorsArguments = [
        "une ressource choisie par l’Education Nationale \"Les compétences psychosociales au service du bien-être et du vivre-ensemble\"",
        "des courtes activités adaptées au périscolaire : 300 activités collectives (temps calmes, art et impro, ateliers philo) ludiques faciles à prendre en main",
        "des semaines thématiques en extrascolaire : 7 séquences pédagogiques clé en main",
        "peu d’équipement : diffusable sur mobile, tablette et ordinateur",
        "utilisable sans Internet : enregistrement en local des activités sur mobile et tablette",
        "un espace dédié au développement professionnel des animateurs (formation aux ateliers philo conçue par Edwige Chirouter et Jean-Charles Pettier & programme Bien-être)"
    ]
    const knowMoreTitle = user.profile === "teacher" ? "Inclus dans Lili à l’école Premium" : user.profile === "family" ? "Inclus dans Lili à la maison Premium" : "Inclus dans Lili en périscolaire Premium";
    const subArguments = user.profile === "teacher" ? teacherArguments : user.profile === "family" ? parentsArguments : animatorsArguments;
    let knowMoreView = [];
    for (const anArgumentIndex in subArguments) {
        if (Object.prototype.hasOwnProperty.call(subArguments, anArgumentIndex)) {
            const element = subArguments[anArgumentIndex];
            knowMoreView.push(<Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), paddingBottom: appTheme.pixelPerfect(4) }} key={anArgumentIndex}>{"• " + element}</Text>)
        }
    }

    const imageSize = appTheme.pixelPerfect(80);
    const imageChoice = user.profile === "teacher" ? images.icLiliAtSchoolCircle : user.profile === "family" ? images.icLiliAtHomeCircle : images.icLiliAtExtracurricularCircle;
    const overlayColor = user.profile === "teacher" ? appTheme.schoolColor : user.profile === "family" ? appTheme.homeColor : appTheme.animatorColor;

    return (
        <SafeAreaView style={{ width: appTheme.getFullScreenWidth(), alignItems: "center", justifyContent: "center", backgroundColor: overlayColor + "80", flex: 1 }}>
            <ScrollView>
                <View style={{ width: appTheme.getBlockWidth(), justifyContent: "center", alignItems: "center", paddingTop: imageSize / 2, marginTop: appTheme.pixelPerfect(10) }}>
                    <View style={[Style.shadowed, { width: appTheme.getBlockWidth(), justifyContent: "center", alignItems: "center", backgroundColor: overlayColor, overflow: "hidden", borderRadius: appTheme.pixelPerfect(20), paddingTop: (appTheme.pixelPerfect(10) + imageSize / 2) }]}>
                        <Text style={{ color: appTheme.white, fontFamily: appTheme.primaryBoldFont, textTransform: "uppercase", fontSize: appTheme.pixelPerfectForFont(13), textAlign: "center", paddingBottom: appTheme.pixelPerfect(10) }}>{knowMoreTitle}</Text>
                        <View style={{ width: appTheme.getBlockWidth(), alignItems: "flex-start", backgroundColor: appTheme.white, padding: appTheme.pixelPerfect(10), paddingBottom: appTheme.pixelPerfect(80) }}>
                            {knowMoreView}
                        </View>
                    </View>
                    <View style={{ position: "absolute", top: 0, width: appTheme.getBlockWidth(), height: imageSize, justifyContent: "center", alignItems: "center" }}>
                        <Image source={imageChoice} style={{ width: imageSize, height: imageSize, borderRadius: imageSize / 2 }} />
                    </View>
                </View>
            </ScrollView>
            <View style={[Style.shadowed, { position: "absolute", bottom: appTheme.bottomInsets+appTheme.pixelPerfect(20), alignItems: "center" }]}>
                <Button style={{ backgroundColor: overlayColor, width: appTheme.pixelPerfect(100) }} textStyle={{ color: white }} title={"Fermer"} onPress={onCloseRequest} />
            </View>
        </SafeAreaView>
    )

}

export default SubscriptionDetails;