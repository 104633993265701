import React, { useEffect, useRef, useState } from "react";

import {
    Image,
    Platform,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import AppTheme from "../../../utils/Theme";
import Images, { bundleImage } from "../../../../specific/utils/Images";
import ComponentProps from "../../../../specific/utils/Interfaces/ComponentProps";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { UserType } from "../../../utils/Constants";
import { trackEvent } from "../../../services/Tracking/Analytics";

interface SignUpProps extends ComponentProps {
    onButtonsPressed?: (userType: UserType) => void
}


export type SignUpProfile = {
    image: bundleImage | number,
    bg_color: string,
    bg_image: bundleImage | number,
    title: string,
    color: string,
    id: UserType
}

const emptyErrors = {
    email: "",
    password: ""
};

const SignUp = (props: SignUpProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const { componentId = "", onButtonsPressed } = props;
    let navigate: NavigateFunction | string = "";
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const signUpProfiles: SignUpProfile[] = [
        {
            title: "Super-profs",
            image: images.icLiliAtSchoolCircle,
            bg_color: appTheme.schoolBackgroundColor,
            bg_image: images.bgBtnLiliAtSchool,
            color: appTheme.darkBlue,
            id: "teacher"
        },
        {
            title: "Super-animateurs",
            image: images.icLiliAtExtracurricularCircle,
            bg_color: appTheme.schoolBackgroundColor,
            bg_image: images.bgBtnLiliAtExtracurricular,
            color: appTheme.darkBlue,
            id: "animator"
        },
        {
            title: "Super-parents",
            image: images.icLiliAtHomeCircle,
            bg_color: appTheme.homeBackgroundColor,
            bg_image: images.bgBtnLiliAtHome,
            color: appTheme.white,
            id: "family"
        }
    ]

    const selectUserType = (userType: UserType) => {
        if (onButtonsPressed !== undefined) {
            trackEvent("signin_started", {profile: userType === "family" ? "parent" : userType});
            onButtonsPressed(userType);
        }
    }

    const getContent = () => {
        let signUpView = [];
        const imageSize = appTheme.pixelPerfect(70);
        const cellHeight = imageSize + appTheme.pixelPerfect(20);
        const titleHeight = imageSize - appTheme.pixelPerfect(8);
        const cellWidth = Platform.OS === "web" ? "50%" : "90%";
        for (const aUserProfile in signUpProfiles) {
            if (Object.prototype.hasOwnProperty.call(signUpProfiles, aUserProfile)) {
                const aProfile = signUpProfiles[aUserProfile];
                signUpView.push(<TouchableOpacity onPress={() => selectUserType(aProfile.id)} style={{ flexDirection: "row", width: cellWidth, alignItems: "center", height: cellHeight }}>
                    <View style={{ overflow: "hidden", flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: aProfile.bg_color, height: titleHeight, marginLeft: imageSize / 2, paddingLeft: imageSize / 2, borderRadius: appTheme.pixelPerfect(10) }}>
                        <Image source={aProfile.bg_image} style={{ left: imageSize / 2, width: "100%", height: "100%", position: "absolute" }} resizeMode="cover" />
                        <View>
                            <Text style={{ fontFamily: appTheme.secondaryFont, color: aProfile.color, fontSize: appTheme.pixelPerfectForFont(6), textAlign: "center" }}>Pour les</Text>
                            <Text style={{ fontFamily: appTheme.primaryBoldFont, textTransform: "uppercase", color: aProfile.color, fontSize: appTheme.pixelPerfectForFont(7), textAlign: "center" }}>{aProfile.title}</Text>
                        </View>
                    </View>
                    <Image source={aProfile.image} style={{ width: imageSize, height: imageSize, position: "absolute" }} />
                </TouchableOpacity>)
            }
        }
        return signUpView;
    }

    return <View style={{ justifyContent: "space-evenly", alignItems: "center", width: appTheme.getBlockWidth() }}>
        {getContent()}
    </View>

}

export default SignUp;