import i18n from "i18n-js";

import fr_kids from "./locales/fr_kids.json";
import fr_teachers from "./locales/fr_teachers.json";

export const contextKeys = {
    TEACHERS: "teachers",
    PARENTS: "kids"
}

let appContext = "fr_"+contextKeys.TEACHERS;

i18n.defaultLocale = appContext;
i18n.locale = appContext;
i18n.fallbacks = true;
i18n.translations = { fr_kids, fr_teachers };

export function updateContext(context:string) {
    appContext = getLanguage()+"_"+context;
    i18n.locale = appContext;
}

export function getLanguage() {
    const availableLanguages = ["fr"];
    let deviceLanguage = "fr";

    return deviceLanguage;
}

export default i18n;