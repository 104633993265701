import React, { useRef } from "react";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import {
    Text,
    View,
    Dimensions,
    Modal,
    Animated,
    TouchableOpacity
} from 'react-native';
import SVGView from "../../utils/SvgView";
import { default as IcClose } from "../../assets/svg/icons/solid/times.svg";
import AppTheme from "../../utils/Theme";
import ActionSheetSchoolSearch from "../../components/Authentication/Landing/ActionSheetSchoolSearch";

interface ActionSheetProps {
    onChange?: (item:any) => void
}

export type ActionSheetType = "SCHOOLSEARCH" | "";

interface ActionSheetContent {
    isVisible?: boolean,
    actionSheetType: ActionSheetType
}

const ActionSheet = forwardRef((props: ActionSheetProps, ref: any) => {

    const appTheme: AppTheme = new AppTheme();

    const { onChange } = props;

    const bottomSheetMarginBottom = useRef(new Animated.Value(-appTheme.getFullAppHeight())).current;

    const hideTheAlert = () => {
        Animated.timing(bottomSheetMarginBottom, {
            toValue: -appTheme.getFullAppHeight(),
            duration: 500,
            useNativeDriver: false
        }).start(() => {
            setTimeout(() => {
                setAlertContent({
                    isVisible: false,
                    actionSheetType: ""
                });
            }, 100);
        });
    }

    const [alertContent, setAlertContent] = useState<ActionSheetContent>({
        isVisible: false,
        actionSheetType: ""
    });

    // ComponentDidMount
    useEffect(() => {
        if (alertContent.isVisible === true) {
            Animated.timing(bottomSheetMarginBottom, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false
            }).start();
        }
    }, [alertContent.isVisible]);

    useImperativeHandle(ref, () => ({

        showAlert(data: { actionSheetType: ActionSheetType }) {
            const { actionSheetType } = data;
            setAlertContent({
                actionSheetType,
                isVisible: true
            });
        },

        hideAlert() {
            hideTheAlert();
        }
    }));

    const getActionSheetComponent = () => {
        if (alertContent.actionSheetType === "SCHOOLSEARCH") {
            return <ActionSheetSchoolSearch onChange={onChange} />
        }
        return <View />
    }

    return (
        <Modal
            transparent={true}
            animationType="fade"
            visible={alertContent.isVisible}
            style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height }}>
            <View style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height, justifyContent: "flex-end", alignItems: "center", backgroundColor: appTheme.alertOverlayColor }}>
                <Animated.View style={{ marginBottom: bottomSheetMarginBottom, minHeight: appTheme.pixelPerfect(60), width: Dimensions.get("window").width, paddingVertical: appTheme.pixelPerfect(40), paddingHorizontal: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(10), backgroundColor: appTheme.alertBackgroundColor }}>
                    {getActionSheetComponent()}
                    <TouchableOpacity onPress={hideTheAlert} style={{ position: "absolute", top: appTheme.pixelPerfect(10), right: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), justifyContent: "center", alignItems: "center" }}>
                        <SVGView Component={IcClose} size={appTheme.pixelPerfect(24)} color={appTheme.darkBlue} />
                    </TouchableOpacity>
                </Animated.View>
            </View>
        </Modal>
    )
});

export default ActionSheet;