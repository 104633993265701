import React, { useEffect, useState } from "react";

import {
    View,
    Text,
    Image,
    TouchableOpacity,
    Platform
} from "react-native";
import AppTheme from "../../utils/Theme";
import Images from "../../../specific/utils/Images";
import GameStyle from "../../styles/game/GameStyle";
import Activity from "../../models/Activity";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";
import { checkLocalUrl } from "../../utils/LocalStorage";
import { getAudioUrlForMediaId } from "../../../specific/services/AppWrite/AppWriteDatabase";
import AudioPlayer from "../../../specific/utils/AudioPlayer/AudioPlayer";
import { getLanguage } from "../../utils/Localization/Localization";

interface GameLaunchCountdownProps {
    color: string,
    onCountdownEnd: () => void,
    playASound: (sound: string) => void
}


const numberColors = ["#75DFEA", "#75DFEA", '#F6DC89', '#4EC6A9', '#C8F5FA', '#FF9972'];

const GameLaunchCountdown = (props: GameLaunchCountdownProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const gameStyle: GameStyle = new GameStyle();

    const { color, onCountdownEnd, playASound } = props;

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [countdownValue, setCountdownValue] = useState<number>(5);

    const onViewDidAppear = () => {
        setViewDidAppear(true);
    }

    useEffect(() => {
        if (viewDidAppear === true) {
            playASound('countdown.mp3');
            launchCountDownValues();
        }
    }, [viewDidAppear]);

    const launchCountDownValues = () => {
        setTimeout(() => {
            setCountdownValue(countdownValue - 1);
        }, 1000);
    }

    useEffect(() => {
        if (countdownValue !== 5) {
            setTimeout(() => {
                if (countdownValue > 1) {
                    setCountdownValue(countdownValue - 1);
                } else {
                    onCountdownEnd();
                }
            }, 1000);
        }
    }, [countdownValue]);

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ width: 1, height: 1 }} />
        }
        const digitColor = numberColors[countdownValue] !== undefined ? numberColors[countdownValue] : "#654321";
        return (
            <View style={gameStyle.backgroundImageStyle}>
                <View style={gameStyle.countdownNumberContainer}>
                    <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(92), color: digitColor }}>{countdownValue}</Text>
                </View>
            </View>
        )
    }

    return getContent();

}

export default GameLaunchCountdown;