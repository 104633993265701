import React from "react";
import { useState, useEffect } from "react";
import i18n, { getLanguage } from "../../utils/Localization/Localization";


import {
    View,
    Text,
    Platform,
    TouchableOpacity,
    SafeAreaView,
    Animated
} from 'react-native';

import AppTheme from "../../utils/Theme";

// UI
import Style from "../../utils/Style";

// Assets
import Images from "../../../specific/utils/Images";

// Data
import SVGView from "../../utils/SvgView";
import { default as IcClose } from "../../assets/svg/icons/solid/times.svg";

import shorthash from 'shorthash';


const BookPreview = (props: any) => {

    const [isFav, setIsFav] = useState(false);

    const yOffset = new Animated.Value(0);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();


    const favHeight = Platform.OS === 'ios' ? appTheme.pixelPerfect(105) : appTheme.pixelPerfect(145);

    const cardWidth = appTheme.getFlexDirectionForSplitScreen() === "column" ? appTheme.getFullAppWidth() : appTheme.getFullAppWidth() / 2;

    // ComponentDidMount
    useEffect(() => {
    }, []);

    // MainOptions
    const { componentId = "", activity, preview } = props;
    const { color = appTheme.darkBlue } = preview;

    const backgroundColor = appTheme.backgroundColor;

    // Animation du header
    const getheaderHeight = () => {
        return yOffset.interpolate({
            inputRange: [0, appTheme.pixelPerfect(160)],
            outputRange: [appTheme.pixelPerfect(230), appTheme.pixelPerfect(70)],
            extrapolate: 'clamp'
        })
    }

    const addAudioBookTofav = () => {
        // TO DO : Add Fav
        /*
        Services.addBookAsFav(preview.id, activity.key, activity.sectionKey).then((userData) => {
            Animated.timing(this.state.favNotificationHeight, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: false
            }).start(() => {
                setTimeout(() => {
                    Animated.timing(this.state.favNotificationHeight, {
                        toValue: -favHeight,
                        duration: 500,
                        useNativeDriver: false
                    }).start(() => {
                        if (this.state.isFav === true) {
                            this.setState({ isFav: false });
                        } else {
                            this.setState({ isFav: true });
                        }
                    });
                }, 2000);
            });

        }).catch((error) => {
            Alert.alert(
                i18n.t('book.favErrorTitle'),
                i18n.t('book.favErrorBody'),
                [
                    { text: i18n.t('book.favErrorOK') }
                ]
            )
        });
        */
    }

    const getPathForOriginal = (url: String) => {
        // TO DO : Share
        /*
        const name = shorthash.unique(url);
        const extension = Platform.OS === 'ios' ? '' : 'file://';
        return `${extension}${stockageDir}/${name}.png`;
        */
    }

    const shareTheAudioBook = () => {
        const appType = Platform.OS === 'ios' ? 'iPhone' : 'Android';
        try {
            const bookAuthor = preview.author !== undefined ? ' - ' + preview.author + ' -' : '';
            let messageToShare = i18n.t('preview.shareMessage', { bookTitle: preview.title[getLanguage()], bookAuthor: bookAuthor, appType: appType, sectionName: activity.subSection.section.title, subSectionName: activity.subSection.title });
            let subjectToShare = preview.title[getLanguage()];
            let shareOptions = {
                message: messageToShare,
                title: subjectToShare,
                url: ''
            };
            if (preview.img !== undefined) {
                const pathOfTheImage = getPathForOriginal(preview.img);
                // TO DO
                /*
                RNFS.exists(pathOfTheImage).then((response) => {
                    if (response === true) {
                        if (Platform.OS === 'ios') {
                            this.shareWithBase64Image(pathOfTheImage, shareOptions)
                        } else {
                            shareOptions.url = pathOfTheImage;
                            Share.open(shareOptions);
                        }
                    } else {
                        this.downloadAndShare(audioBook.img, shareOptions);
                    }
                });
                */
            } else {
                Share.open(shareOptions);
            }
        } catch (error) {
        }
    }

    const shareWithBase64Image = (pathOfTheImage: string, shareOptions: any) => {
        //TO DO
        /*
        RNFS.readFile(`file://${pathOfTheImage}`, 'base64').then((res) => {
            let shareOptionsUrl = { ...shareOptions };
            shareOptionsUrl.url = `data:image/jpeg;base64,${res}`;
            Share.open(shareOptionsUrl);
        })
        */
    }

    const downloadAndShare = (url: string, shareOptions: any) => {
        const id = shorthash.unique(url);
        const destination = getPathForOriginal(url);
        //TODO
        /*
        RNBackgroundDownloader.download({
            id: id,
            url: url,
            destination: destination
        }).begin((expectedBytes) => {
        }).progress((percent) => {
        }).done(() => {
            fileType(destination).then((type) => {
                if ((type.mime !== 'image/png') && (type.mime !== 'image/jpeg')) {
                    Share.open(shareOptions);
                    RNFS.unlink(destination);
                } else {
                    if (Platform.OS === 'ios') {
                        this.shareWithBase64Image(destination, shareOptions)
                    } else {
                        let options = { ...shareOptions };
                        options.url = destination;
                        Share.open(options);
                    }
                }
            }).catch((error) => {

            })
        }).error((error) => {
            Share.open(shareOptions);
        });
        */
    }

    const closeTheView = () => {
        if (props.closeTheDocument !== undefined) {
            props.closeTheDocument();
        }
    }

    const audioBookImageView = (preview.img !== undefined) ? <Animated.View style={[{ width: cardWidth, height: getheaderHeight() }]}>
        <Animated.Image
            resizeMode='contain'
            style={[Style.shadowed, { width: cardWidth, height: getheaderHeight() }]}
            source={{ uri: preview.img }} /></Animated.View> : <View />;
    //Gestion du coeur des favoris
    // TODO : FAV
    const favIcon = <View />;
    /*
    const favButtonColor = isFav === true ? actionSelectedColor : actionsUnselectedColor;
    const favIcon = isFav === true ? <IconFont name='heart' color={favButtonColor} size={appTheme.pixelPerfect(18)} solid /> : <IconFont name='heart' color={favButtonColor} size={appTheme.pixelPerfect(18)} regular />;
    const addFavTitleText = this.state.isFav === true ? i18n.t('activity.removeAsFavTitle') : i18n.t('activity.addAsFavTitle');
    const addFavBodyText = this.state.isFav === true ? i18n.t('activity.removeAsFavBody') : i18n.t('activity.addAsFavBody');
    */
    const authorNameView = preview.author !== undefined ? <Text style={{ textAlign: 'center', paddingTop: appTheme.pixelPerfect(5), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(13), color: appTheme.darkBlue }}>
        {i18n.t('preview.author') + ' : ' + preview.author}
    </Text> : <View />;
    const publisherNameView = preview.owner !== undefined ? <Text style={{ textAlign: 'center', paddingTop: appTheme.pixelPerfect(5), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(13), color: appTheme.darkBlue }}>
        {i18n.t('preview.editor') + ' : ' + preview.owner}
    </Text> : <View />;
    if (Platform.OS === "web") {
        return (
            <SafeAreaView style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center', backgroundColor: color }}>
                <View style={[{ width: appTheme.getFullAppWidth(), flex: 1, borderRadius: appTheme.pixelPerfect(20), alignItems: 'center', flexDirection: appTheme.getFlexDirectionForSplitScreen() }]}>
                    <View style={{ marginTop: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(230), marginBottom: appTheme.pixelPerfect(20) }}>
                        {audioBookImageView}
                    </View>
                    <View>
                        <View style={[{ width: cardWidth, height:appTheme.getFullAppHeight(), backgroundColor: appTheme.white, marginTop: appTheme.pixelPerfect(-1), borderTopLeftRadius: appTheme.pixelPerfect(20), borderTopRightRadius: appTheme.pixelPerfect(20) }]}>
                            <View style={[{ width: cardWidth, flex: 1, alignItems: 'flex-start', backgroundColor: appTheme.white }]}>
                                <Text style={{ textAlign: 'center', paddingTop: appTheme.pixelPerfect(16), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(16), color: appTheme.darkBlue }}>
                                    {preview.title}
                                </Text>
                                {authorNameView}
                                {publisherNameView}
                                <Text style={{ textAlign: 'center', paddingBottom: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(13), color: appTheme.darkBlue }}>
                                    {i18n.t('preview.age') + ' : ' + preview.age}
                                </Text>
                                <Text style={{ textAlign: 'center', paddingBottom: appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(16), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(13), color: appTheme.darkBlue }}>
                                    {preview.body[getLanguage()]}
                                </Text>
                            </View>
                        </View>
                        <TouchableOpacity onPress={closeTheView} style={{ position: 'absolute', right: appTheme.pixelPerfect(2), top: appTheme.pixelPerfect(2), justifyContent: 'center', alignItems: 'center', width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.white + "70" }}>
                            <SVGView Component={IcClose} size={appTheme.pixelPerfect(20)} color={color} />
                        </TouchableOpacity>
                    </View>
                </View>
            </SafeAreaView>
        )
    }

    return (
        <SafeAreaView style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center', backgroundColor: color }}>
            <View style={[{ width: appTheme.getFullAppWidth(), flex: 1, borderRadius: appTheme.pixelPerfect(20), alignItems: 'center', flexDirection: appTheme.getFlexDirectionForSplitScreen() }]}>
                <Animated.View style={{ marginTop: appTheme.pixelPerfect(40), height: getheaderHeight(), marginBottom: appTheme.pixelPerfect(20) }}>
                    {audioBookImageView}
                </Animated.View>
                <Animated.ScrollView
                    bounces={false}
                    onScroll={
                        Animated.event(
                            [
                                {
                                    nativeEvent: {
                                        contentOffset: { y: yOffset }
                                    }
                                }
                            ]
                        )
                    }
                    showsVerticalScrollIndicator={false}
                    style={[{ width: cardWidth, flex: 1, backgroundColor: appTheme.white, marginTop: appTheme.pixelPerfect(-1), borderTopLeftRadius: appTheme.pixelPerfect(20), borderTopRightRadius: appTheme.pixelPerfect(20) }]}>
                    <View style={[{ width: cardWidth, flex: 1, alignItems: 'center', backgroundColor: appTheme.white }]}>
                        <Text style={{ textAlign: 'center', paddingTop: appTheme.pixelPerfect(16), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(16), color: appTheme.darkBlue }}>
                            {preview.title}
                        </Text>
                        {authorNameView}
                        {publisherNameView}
                        <Text style={{ textAlign: 'center', paddingBottom: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(13), color: appTheme.darkBlue }}>
                            {i18n.t('preview.age') + ' : ' + preview.age}
                        </Text>
                        <Text style={{ textAlign: 'center', paddingBottom: appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(16), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(16), color: appTheme.darkBlue }}>
                            {preview.body[getLanguage()]}
                        </Text>

                    </View>
                </Animated.ScrollView>
                <TouchableOpacity onPress={closeTheView} style={{ position: 'absolute', right: appTheme.pixelPerfect(2), top: appTheme.pixelPerfect(2), justifyContent: 'center', alignItems: 'center', width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.white + "70" }}>
                    <SVGView Component={IcClose} size={appTheme.pixelPerfect(20)} color={color} />
                </TouchableOpacity>
            </View>
        </SafeAreaView>
    )
}

export default BookPreview;
