import { TextStyle } from "react-native";
import AppTheme from "../../utils/Theme";

export default class AnimatorSignUpStyle {

    appTheme: AppTheme = new AppTheme();

    titleStyle: TextStyle = {
        textAlign: "center",
        fontSize: this.appTheme.pixelPerfectForFont(9),
        fontFamily: this.appTheme.primarySemiBoldFont
    };

    bodyStyle: TextStyle = {
        textAlign: "center",
        fontSize: this.appTheme.pixelPerfectForFont(9),
        fontFamily: this.appTheme.secondaryFont
    };

    constructor() {

    }
}