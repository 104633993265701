/* REACT */
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Share, Animated, Platform } from 'react-native';

/* UTILS */
import * as Constants from "../../utils/Constants";

/* EXTERNAL */

import Svg, { Path, Defs, ClipPath, G, Circle } from 'react-native-svg';

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Game from './Game';
import { EventRegister } from '../../utils/EventListeners';
import User from '../../data/user/User';
import AppTheme from '../../utils/Theme';

const AnimatedCircle = Animated.createAnimatedComponent(Circle);

// Définition de l'arc en ciel en SVG-
const duration = 1000;
const delay = 300;

//Gamification
const sandy = "#f3d676";
const paleViolet = '#bba9fc';
const greeny_blue = "#42bc99";
const melon = "#ff855f";
const pale_sky_blue = "#bdf3f9";
const levelOne = pale_sky_blue;
const levelTwo = paleViolet;
const levelThree = greeny_blue;
const levelFour = sandy;
const levelFive = melon;
const off_white = "#fff1e1";
const white = "#FFFFFF";

const RainbowPoints = (props: any) => {

    const appTheme: AppTheme = new AppTheme();

    const [fifthLevelRadius, setFifthLevelRadius] = useState(appTheme.pixelPerfect(20));
    const [fourthLevelRadius, setFourthLevelRadius] = useState(appTheme.pixelPerfect(16));
    const [thirdLevelRadius, setThirdLevelRadius] = useState(appTheme.pixelPerfect(12));
    const [secondLevelRadius, setSecondLevelRadius] = useState(appTheme.pixelPerfect(8));
    const [firstLevelRadius, setFirstLevelRadius] = useState(appTheme.pixelPerfect(4));
    const [halfCircle, setHalfCircle] = useState(appTheme.pixelPerfect(24));
    const [wholeViewHeight, setWholeViewHeight] = useState(appTheme.pixelPerfect(24));
    const [strokeWidth, setStrokeWidth] = useState(3);
    const [cardMarginVerticalForPoints, setCardMarginVerticalForPoints] = useState(appTheme.pixelPerfect(4));
    const [cardMinWidth, setCardMinWidth] = useState(appTheme.pixelPerfect(80));
    const [cardFontSize, setCardFontSize] = useState(appTheme.pixelPerfect(16));
    const [cardHeight, setCardHeight] = useState(appTheme.pixelPerfect(28));
    const [isSizeDefined, setIsSizeDefined] = useState(false);
    const [gamePoints, setGamePoints] = useState(0);
    const [level, setLevel] = useState(1);


    const getCircumferenceForLevel = (levelRadius: number) => {
        return 2 * Math.PI * levelRadius;
    }

    const [fifthLevelValue] = useState(new Animated.Value(getCircumferenceForLevel(20)));
    const [fourthLevelValue] = useState(new Animated.Value(getCircumferenceForLevel(16)));
    const [thirdLevelValue] = useState(new Animated.Value(getCircumferenceForLevel(12)));
    const [secondLevelValue] = useState(new Animated.Value(getCircumferenceForLevel(8)));
    const [firstLevelValue] = useState(new Animated.Value(getCircumferenceForLevel(4)));

    const { isNavbar = false, userProfile = false, showTotalPoints = true, arcColors, showPoints = false, pts = 0 } = props;

    const onViewDidAppear = () => {
        checkGameScore();
        // On défini l'arc-en-ciel très grand
        // Pour la barre de navigation on va redéfinir la taille de l'arc-en-ciel
        // Level 5
        let aFifthLevelRadius = appTheme.pixelPerfect(70);
        // Level 4
        let aFourthLevelRadius = appTheme.pixelPerfect(59);
        // Level 3
        let aThirdLevelRadius = appTheme.pixelPerfect(48);
        // Level 2
        let aSecondLevelRadius = appTheme.pixelPerfect(37);
        // Level 1
        let aFirstLevelRadius = appTheme.pixelPerfect(26);
        let aStrokeWidth = appTheme.pixelPerfect(8);
        // Cartouche contenant les points
        let aCardMarginVerticalForPoints = appTheme.pixelPerfect(30);
        let aCardMinWidth = appTheme.pixelPerfect(140);
        let aCardHeight = appTheme.pixelPerfect(28);
        let aCardFontSize = appTheme.pixelPerfectForFont(16);
        if ((props.isNavbar !== undefined) && (props.isNavbar === true)) {
            // Pour la barre de navigation on va redéfinir la taille de l'arc-en-ciel
            // Level 5
            aFifthLevelRadius = appTheme.pixelPerfect(22);
            // Level 4
            aFourthLevelRadius = appTheme.pixelPerfect(17);
            // Level 3
            aThirdLevelRadius = appTheme.pixelPerfect(12);
            // Level 2
            aSecondLevelRadius = appTheme.pixelPerfect(7);
            // Level 1
            aFirstLevelRadius = appTheme.pixelPerfect(2);
            aStrokeWidth = appTheme.pixelPerfect(3);
            // Cartouche contenant les points
            aCardMarginVerticalForPoints = appTheme.pixelPerfect(12);
            aCardMinWidth = appTheme.pixelPerfect(40);
            aCardHeight = appTheme.pixelPerfect(16);
            aCardFontSize = appTheme.pixelPerfectForFont(10);
        } else if ((props.size !== undefined) && (props.size === 'pointsRewards')) {
            // Pour la barre de navigation on va redéfinir la taille de l'arc-en-ciel
            // Level 5
            aFifthLevelRadius = appTheme.pixelPerfect(60);
            // Level 4
            aFourthLevelRadius = appTheme.pixelPerfect(48);
            // Level 3
            aThirdLevelRadius = appTheme.pixelPerfect(36);
            // Level 2
            aSecondLevelRadius = appTheme.pixelPerfect(24);
            // Level 1
            aFirstLevelRadius = appTheme.pixelPerfect(12);
            aStrokeWidth = appTheme.pixelPerfect(8);
            // Cartouche contenant les points
            aCardMarginVerticalForPoints = appTheme.pixelPerfect(12);
            aCardMinWidth = appTheme.pixelPerfect(40);
            aCardHeight = appTheme.pixelPerfect(16);
            aCardFontSize = appTheme.pixelPerfectForFont(10);
        } else if ((props.userProfile !== undefined) && (props.userProfile === true)) {
            // Pour la barre de navigation on va redéfinir la taille de l'arc-en-ciel
            // Level 5
            aFifthLevelRadius = appTheme.pixelPerfect(30);
            // Level 4
            aFourthLevelRadius = appTheme.pixelPerfect(24);
            // Level 3
            aThirdLevelRadius = appTheme.pixelPerfect(18);
            // Level 2
            aSecondLevelRadius = appTheme.pixelPerfect(12);
            // Level 1
            aFirstLevelRadius = appTheme.pixelPerfect(6);
            aStrokeWidth = appTheme.pixelPerfect(5);
            // Cartouche contenant les points
            aCardMarginVerticalForPoints = appTheme.pixelPerfect(12);
            aCardMinWidth = appTheme.pixelPerfect(70);
            aCardHeight = appTheme.pixelPerfect(20);
            aCardFontSize = appTheme.pixelPerfectForFont(10);
        }
        setFifthLevelRadius(aFifthLevelRadius);
        setSecondLevelRadius(aSecondLevelRadius);
        setThirdLevelRadius(aThirdLevelRadius);
        setFourthLevelRadius(aFourthLevelRadius);
        setFirstLevelRadius(aFirstLevelRadius);
        setStrokeWidth(aStrokeWidth);
        setCardFontSize(aCardFontSize);
        setCardHeight(aCardHeight);
        setCardMinWidth(aCardMinWidth);
        setCardMarginVerticalForPoints(aCardMarginVerticalForPoints);
        setHalfCircle(aFifthLevelRadius+strokeWidth);
        let aWholeViewHeight = aFifthLevelRadius+strokeWidth;
        if (showTotalPoints === true) {
            aWholeViewHeight = aFifthLevelRadius+strokeWidth + aCardHeight;
        }
        if (isNavbar === true) {
            aWholeViewHeight = 2*(aFifthLevelRadius+strokeWidth);
        }
        setWholeViewHeight(aWholeViewHeight);
        setIsSizeDefined(true);
    }

    useEffect(() => {
        if (isSizeDefined === true) {
            onPress();
        }
    }, [isSizeDefined])

    // Gestion individuelle des points
    const checkGameScore = () => {
        const user: User = props.user;
        if (user !== undefined) {
            setGamePoints(user.pts);
            setLevel(user.level);
        }
    }

    const launchAnimation = () => {
        Animated.parallel([
            Animated.timing(fifthLevelValue, {
                toValue: getCircumferenceForLevel(fifthLevelRadius) / 2,
                duration,
                delay,
                useNativeDriver: true
            }),
            Animated.timing(fourthLevelValue, {
                toValue: getCircumferenceForLevel(fourthLevelRadius) / 2,
                duration,
                delay: delay + 200,
                useNativeDriver: true
            }),
            Animated.timing(thirdLevelValue, {
                toValue: getCircumferenceForLevel(thirdLevelRadius) / 2,
                duration,
                delay: delay + 400,
                useNativeDriver: true
            }),
            Animated.timing(secondLevelValue, {
                toValue: getCircumferenceForLevel(secondLevelRadius) / 2,
                duration,
                delay: delay + 600,
                useNativeDriver: true
            }),
            Animated.timing(firstLevelValue, {
                toValue: getCircumferenceForLevel(firstLevelRadius) / 2,
                duration,
                delay: delay + 800,
                useNativeDriver: true
            })
        ]).start();
    }

    const onUserPress = () => {
        if (props.onPress !== undefined) {
            props.onPress();
        } else {
            onPress();
        }
    }

    const onPress = () => {
        Animated.parallel([
            Animated.timing(fifthLevelValue, {
                toValue: getCircumferenceForLevel(fifthLevelRadius),
                duration: 400,
                useNativeDriver: true
            }),
            Animated.timing(fourthLevelValue, {
                toValue: getCircumferenceForLevel(fourthLevelRadius),
                duration: 400,
                useNativeDriver: true
            }),
            Animated.timing(thirdLevelValue, {
                toValue: getCircumferenceForLevel(thirdLevelRadius),
                duration: 400,
                useNativeDriver: true
            }),
            Animated.timing(secondLevelValue, {
                toValue: getCircumferenceForLevel(secondLevelRadius),
                duration: 400,
                useNativeDriver: true
            }),
            Animated.timing(firstLevelValue, {
                toValue: getCircumferenceForLevel(firstLevelRadius),
                duration: 400,
                useNativeDriver: true
            })
        ]).start(() => {
            launchAnimation();
        });
    }

    const getContent = () => {
        if (isSizeDefined === false) {
            return <View />
        }

        const defaultColor = arcColors !== undefined ? arcColors : ((isNavbar === true) || (userProfile === true)) ? off_white : white;
        const fifthLevelColor = level > 4 ? levelFive : defaultColor;
        const fourthLevelColor = level > 3 ? levelFour : defaultColor;
        const thirdLevelColor = level > 2 ? levelThree : defaultColor;
        const secondLevelColor = level > 1 ? levelTwo : defaultColor;
        const firstLevelColor = level > 0 ? levelOne : defaultColor;
        let totalPointsView = <View />;

        const pointsBgColor = Game.getColorForLevel(level);
        if (showTotalPoints === true) {
            const gamePointString = gamePoints !== undefined ? gamePoints.toString() : "-";
            totalPointsView = <View style={{ justifyContent: 'center', alignItems: 'center', position: 'absolute', top: halfCircle + appTheme.pixelPerfect(5) }}>
                <View style={{ minWidth: cardMinWidth, borderWidth: appTheme.pixelPerfect(2), borderColor: white, justifyContent: 'center', alignItems: 'center', borderRadius: appTheme.pixelPerfect(30), backgroundColor: pointsBgColor }}>
                    <Text style={{ fontFamily: "Dosis-Medium", fontSize: cardFontSize, color: white, paddingHorizontal: appTheme.pixelPerfect(4), paddingVertical: appTheme.pixelPerfect(2) }}>{gamePointString}</Text>
                </View>
            </View>;
        }
        let pointsView = <View />;
        if (showPoints === true) {
            pointsView = <View style={{ position: 'absolute', right: 0, bottom: halfCircle, borderWidth: appTheme.pixelPerfect(4), borderColor: white, backgroundColor: pointsBgColor, width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(25), justifyContent: 'center', alignItems: 'center' }}>
                <Text style={{ paddingTop: appTheme.pixelPerfect(4), fontSize: appTheme.pixelPerfectForFont(20), color: white, fontFamily: "Dosis-Medium", lineHeight: appTheme.pixelPerfect(30) }}>
                    {'+' + pts}
                </Text>
            </View>;
        }

        return (
            <View>
                <View style={{ height: halfCircle * 2, width: halfCircle * 2, justifyContent: 'flex-start', alignItems: 'flex-start', transform: [{ rotate: '180deg' }] }}>
                    <Svg width={halfCircle * 2} height={halfCircle * 2} viewbox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}>
                        <G origin={`${halfCircle}, ${halfCircle}`}>
                            <AnimatedCircle
                                cx={halfCircle}
                                cy={halfCircle}
                                stroke={fifthLevelColor}
                                strokeWidth={strokeWidth}
                                r={fifthLevelRadius}
                                fill="transparent"
                                strokeDasharray={getCircumferenceForLevel(fifthLevelRadius)}
                                strokeDashoffset={fifthLevelValue}
                                strokeLinecap='round' />
                            <AnimatedCircle
                                cx={halfCircle}
                                cy={halfCircle}
                                stroke={fourthLevelColor}
                                strokeWidth={strokeWidth}
                                r={fourthLevelRadius}
                                fill="transparent"
                                strokeDasharray={getCircumferenceForLevel(fourthLevelRadius)}
                                strokeDashoffset={fourthLevelValue}
                                strokeLinecap='round' />
                            <AnimatedCircle
                                cx={halfCircle}
                                cy={halfCircle}
                                stroke={thirdLevelColor}
                                strokeWidth={strokeWidth}
                                r={thirdLevelRadius}
                                fill="transparent"
                                strokeDasharray={getCircumferenceForLevel(thirdLevelRadius)}
                                strokeDashoffset={thirdLevelValue}
                                strokeLinecap='round' />
                            <AnimatedCircle
                                cx={halfCircle}
                                cy={halfCircle}
                                stroke={secondLevelColor}
                                strokeWidth={strokeWidth}
                                r={secondLevelRadius}
                                fill="transparent"
                                strokeDasharray={getCircumferenceForLevel(secondLevelRadius)}
                                strokeDashoffset={secondLevelValue}
                                strokeLinecap='round' />
                            <AnimatedCircle
                                cx={halfCircle}
                                cy={halfCircle}
                                stroke={firstLevelColor}
                                strokeWidth={strokeWidth}
                                r={firstLevelRadius}
                                fill="transparent"
                                strokeDasharray={getCircumferenceForLevel(firstLevelRadius)}
                                strokeDashoffset={firstLevelValue}
                                strokeLinecap='round' />
                        </G>
                    </Svg>
                </View>
                {totalPointsView}
                {pointsView}
            </View>
        )
    }


    return (
        <TouchableOpacity onLayout={onViewDidAppear} style={{ height: wholeViewHeight, width: (halfCircle*2) + 30, alignItems: 'center' }} onPress={onUserPress} >
            {getContent()}
        </TouchableOpacity>
    )
}

export default RainbowPoints;
