import React, { useState } from "react";

import {
    View,
    Text,
    TouchableOpacity,
    TextStyle,
    FlatList,
    Linking
} from "react-native";
import AppTheme from "../../utils/Theme";
import TextInput from "../../designSystem/TextInput/RoundCornerTextInput";
import Button from "../../designSystem/Button";
import SVGIconButton from "../../designSystem/Buttons/SVGIconButton";
import { default as IcSearch } from "../../assets/svg/icons/solid/search.svg";
import { getTeachersForAnalyse, getUserWithEmail } from "../../../specific/services/Specific_Database";
import User from "../../data/user/User";

import { getFullDateForTimestamp } from "../../utils/DateUtils";
import Divider from "../../designSystem/Divider/Divider";
import { addDataToUser } from "../../services/Database";

interface LHQTeachersExportProps {
    goBack?: () => void
}

const LHQTeachersExport = (props: LHQTeachersExportProps) => {

    const appTheme: AppTheme = new AppTheme();
    const connectedUser: User = User.getInstance();

    const [userEmail, setUserEmail] = useState<string>("");
    const [usersList, setUsersList] = useState<User[] | undefined>(undefined);

    const { goBack } = props;

    const h1Style: TextStyle = {
        fontFamily: appTheme.primaryBoldFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(18)
    };

    const h2Style: TextStyle = {
        fontFamily: appTheme.primaryMediumFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(16)
    };

    const h3Style: TextStyle = {
        fontFamily: appTheme.primaryMediumFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(14)
    };

    const labelStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(10)
    };


    const inputStyle: TextStyle = {
        height: appTheme.pixelPerfect(40),
        borderRadius: appTheme.pixelPerfect(10),
        borderWidth: 1,
        borderColor: appTheme.grow,
        padding: appTheme.pixelPerfect(4),
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(6)
    };

    const pStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.textColor,
        fontSize: appTheme.pixelPerfectForFont(14)
    };

    const aStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.textColor,
        fontSize: appTheme.pixelPerfectForFont(14),
        textDecorationLine: "underline"
    };

    const searchForTheUser = async () => {
        try {
            console.log("on passe par là ?");
            //setUsersList([]);
            const userAccount = await getTeachersForAnalyse();
            console.log({ userAccount });
            if (userAccount !== undefined) {
                //setUsersList(userAccount);
            }
        } catch (error) {

        }
    }

    const usersCell = ({ item, index }) => {
        const userData: User = item.data;
        const userId: string = item.key;
        console.log({ userData });
        let userName: string = "-";
        if ((userData.firstname !== undefined) && (userData.lastname !== undefined)) {
            if (userData.firstname.length > 0) {
                userName = userData.firstname;
            }
            if (userData.lastname.length > 0) {
                if (userName.length > 1) {
                    userName = userName + " ";
                }
                userName = userName + userData.lastname;
            }
        }
        let creationDate: string = "-";
        if (userData.creation_date !== undefined) {
            creationDate = getFullDateForTimestamp(userData.creation_date);
        }
        let isPremium: boolean = false;
        let wasPremium: boolean = false;
        let premiumFrom: number = 0;
        let premiumTo: number = 0;
        let subOrigin: string = "";
        let isRenewable: string = "Non";
        if (userData.sub !== undefined) {
            wasPremium = true;
            const todayTimestamp: number = new Date().getTime();
            premiumFrom = userData.sub.start_date;
            premiumTo = userData.sub.expires_date;
            subOrigin = userData.sub.origin;
            isRenewable = userData.sub.auto_renewing === true ? "Oui" : "Non";
            if ((premiumFrom < todayTimestamp) && (premiumTo > todayTimestamp)) {
                isPremium = true;
            }
        }
        let userPremiumStatus: string = "Utilisateur freemium";
        let subDetailsViews: React.JSX.Element[] = [];
        if ((isPremium === true) || (wasPremium === true)) {
            userPremiumStatus = isPremium === true ? "Utilisateur premium" : "Utilisateur freemium - anciennement premium";
            subDetailsViews.push(<Text style={labelStyle}>{"Du : " + getFullDateForTimestamp(premiumFrom) + " au " + getFullDateForTimestamp(premiumTo)}</Text>);
            subDetailsViews.push(<Text style={labelStyle}>{"Orgine de l'abonnement : " + subOrigin}</Text>);
            subDetailsViews.push(<Text style={labelStyle}>{"Renouvellement automatique : " + isRenewable}</Text>);
        }

        let userProfile: string = "Inconnu";
        const teacherButton: React.JSX.Element = <Button onPress={() => changeUserTo("profile", "teacher", userId)} style={{ paddingHorizontal: appTheme.pixelPerfect(10), marginEnd: appTheme.pixelPerfect(10) }} title={"👩‍🏫 Enseignant"} />;
        const animatorButton: React.JSX.Element = <Button onPress={() => changeUserTo("profile", "animator", userId)} style={{ paddingHorizontal: appTheme.pixelPerfect(10), marginEnd: appTheme.pixelPerfect(10) }} title={"🤡 Animateur"} />;
        const parentButton: React.JSX.Element = <Button onPress={() => changeUserTo("profile", "family", userId)} style={{ paddingHorizontal: appTheme.pixelPerfect(10), marginEnd: appTheme.pixelPerfect(10) }} title={"🏠 Parent"} />;
        let changeProfileButton: React.JSX.Element[] = [];
        if (userData.profile !== undefined) {
            if (userData.profile === "animator") {
                userProfile = "Animateur";
                changeProfileButton.push(teacherButton);
                changeProfileButton.push(parentButton);
            }
            if (userData.profile === "teacher") {
                userProfile = "Enseignant";
                changeProfileButton.push(animatorButton);
                changeProfileButton.push(parentButton);
            }
            if (userData.profile === "family") {
                userProfile = "Parent";
                changeProfileButton.push(teacherButton);
                changeProfileButton.push(animatorButton);
            }
        }
        if (changeProfileButton.length === 0) {
            changeProfileButton.push(teacherButton);
            changeProfileButton.push(animatorButton);
            changeProfileButton.push(parentButton);
        }

        let userRole: string = "";
        let isBizDev: boolean = false;
        let isManager: boolean = false;
        let isAdmin: boolean = false;
        let isGod: boolean = false;
        if ((userData.root !== undefined) && (userData.root === true)) {
            userRole = "Dieu 😇";
            isGod = true;
        }
        if ((userData.admin !== undefined) && (userData.admin === true)) {
            if (userRole.length > 0) {
                userRole = userRole + " / ";
            }
            userRole = userRole + "Admin 🔑";
            isAdmin = true;
        }
        if ((userData.bizdev !== undefined) && (userData.bizdev === true)) {
            if (userRole.length > 0) {
                userRole = userRole + " / ";
            }
            userRole = userRole + "Biz Dev 🤑";
            isBizDev = true;
        }
        if ((userData.manager !== undefined) && (userData.manager === true)) {
            if (userRole.length > 0) {
                userRole = userRole + " / ";
            }
            userRole = userRole + "Manager 🤓";
            isManager = true;
        }
        if (userRole.length === 0) {
            userRole = "Utilisateur 😸";
        }

        let buttonToToggleAdmin: React.JSX.Element = <View />;
        let linkToUserDb: React.JSX.Element = <View />;
        if ((connectedUser.root !== undefined) && (connectedUser.root === true)) {
            buttonToToggleAdmin = <Button onPress={() => changeUserTo("admin", !isAdmin, userId)} style={{ paddingHorizontal: appTheme.pixelPerfect(10), marginEnd: appTheme.pixelPerfect(10) }} title={isAdmin === true ? "🚫 Admin" : "✅ Admin"} />;
            linkToUserDb = <Button onPress={() => showUserInFB(userId)} style={{ paddingHorizontal: appTheme.pixelPerfect(10), marginEnd: appTheme.pixelPerfect(10) }} title={"Voir l'utilisateur dans FB"} />;
        }

        return <View>
            <Text style={h1Style}>{userData.email}</Text>
            <Text style={labelStyle}>{userId}</Text>
            <Text style={labelStyle}>{"Nom : " + userName}</Text>
            <Text style={labelStyle}>{"Créé le : " + creationDate}</Text>
            <Divider />
            <Text style={labelStyle}>{"Profil : " + userProfile}</Text>
            <Divider />
            <View style={{ flexDirection: "row" }}>
                {changeProfileButton}
            </View>
            <Divider />
            <Text style={h2Style}>{userPremiumStatus}</Text>
            {subDetailsViews}
            <Divider />
            <Text style={labelStyle}>{"Rôle : " + userRole}</Text>
            <Divider />
            <View style={{ flexDirection: "row" }}>
                {buttonToToggleAdmin}
                <Button onPress={() => changeUserTo("bizdev", !isBizDev, userId)} style={{ paddingHorizontal: appTheme.pixelPerfect(10), marginEnd: appTheme.pixelPerfect(10) }} title={isBizDev === true ? "🚫 Bizdev" : "✅ Bizdev"} />
                <Button onPress={() => changeUserTo("manager", !isManager, userId)} style={{ paddingHorizontal: appTheme.pixelPerfect(10), marginEnd: appTheme.pixelPerfect(10) }} title={isManager === true ? "🚫 Manager" : "✅ Manager"} />
            </View>
            <Divider />
            <View style={{ flexDirection: "row" }}>
                {linkToUserDb}
            </View>
        </View>
    }

    const showUserInFB = (userId:string) => {
        Linking.openURL("https://console.firebase.google.com/u/0/project/lili-c7853/firestore/databases/-default-/data/~2Fusers~2F"+userId);
    }

    const changeUserTo = async (key: string, value: boolean | string, userId: string) => {
        await addDataToUser({ key, value, user_id: userId });
        searchForTheUser();
    }

    const emptyUserListView = () => {
        if (usersList === undefined) {
            return <View><Text>{"Appuie sur le bouton pour afficher les enseignants !"}</Text></View>
        }
        return <View><Text>{"Aucun enseignant à exporter."}</Text></View>
    }

    const getContent = () => {
        return <View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <SVGIconButton onPress={searchForTheUser} icon={IcSearch} buttonSize={appTheme.pixelPerfect(40)} backgroundColor={appTheme.grow} color={appTheme.white} />
            </View>
            <FlatList
                ListEmptyComponent={() => {
                    return emptyUserListView();
                }}
                data={usersList !== undefined ? usersList : []}
                style={{ flex: 1 }}
                renderItem={usersCell}
                keyExtractor={(item, index) => item.uid} />
        </View>
    }

    return <View style={{ padding: appTheme.pixelPerfect(20) }}>
        <View style={{ flexDirection: "row", marginBottom: appTheme.pixelPerfect(10) }}>
            <TouchableOpacity onPress={() => goBack()}>
                <Text style={[aStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                    Dashboard
                </Text>
            </TouchableOpacity>
            <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                {">"}
            </Text>
            <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                Export des enseignants
            </Text>
        </View>
        {getContent()}
    </View>

}

export default LHQTeachersExport;