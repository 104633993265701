import {
    useColorScheme,
    useWindowDimensions,
    Platform,
    Dimensions
} from 'react-native';

import { ScreenSize } from '../../specific/utils/ScreenSize';

export const templateWidth = 320;
export const templateHeight = 620;


// LILI - Color

export const melon = "#FF855F";
export const nightSky = "#1C3656";
export const daySky = "#72EAFE";
export const greeny_blue = "#42BB98";
export const purple = "#A2A8FF";
export const light_blue = "#75DFEA";
export const dark_blue = "#354F84";
export const dark_yellow = "#FFC94E";


export const black70 = "#00000030";
export const dark = "#1d2835";
export const dark_grey_blue = "#1C3656";
export const dark_grey_blue_90 = "#1C3656CC";
export const dark_grey_blue_70 = "#1C365670";
export const dark_grey_blue_30 = "#1C365630";
export const dark_green = "#22A18E";
export const off_white = "#fff1e1";
export const pale = "#faefdf";
export const pale_sky_blue = "#bdf3f9";
export const pale_sky_blue_70 = "#bdf3f970";
export const paleViolet = '#bba9fc';
export const paleViolet50 = '#bba9fc50';
export const violet = '#AFA0F7';
export const ultraPaleViolet = '#D0CAF3';
export const purplish_brown = "#3d3337";
export const sandy = "#f3d676";
export const white = "#FFFFFF";
export const white30 = "#FFFFFF30";
export const white70 = "#FFFFFF70";
export const white90 = "#FFFFFF90";
export const clear = "#FFFFFFFF";
export const light_grey = "#EFEFEF";
export const orange_yellow = "#FFB000";
export const gold = "#F4D37F";

export const dark_purple = "#5B5778";
export const light_yellow = "#F8D987";
export const grassGreen = "#00BC98";
export const superPower = "#364A7B";

const lightMainColor: string = pale_sky_blue;
const darkMainColor: string = "#1C3656";

const webWidth = 1024;
const mobileBreakpoint = 768;

export class Theme {
    public still: string;
    public talk: string;
    public grow: string;
    public inspire: string;
    public planet: string;

    public superHerosFontRegular: string;
    public superHerosFontBold: string;

    public primaryColor: string;
    public secondaryColor: string;
    public secondaryBackgroundColor: string;
    public navigationColor: string;
    public navigationItemColor: string;
    public titleColor: string;
    public textColor: string;
    public inputBackgroundColor: string;
    public buttonBackgroundColor: string;
    public buttonTextColor: string;
    public primaryFont: string;
    public primaryMediumFont: string;
    public primarySemiBoldFont: string;
    public primaryBoldFont: string;
    public secondaryFont: string;
    public liliFont: string;
    public alertOverlayColor: string;
    public alertBackgroundColor: string;
    public errorColor: string;
    public schoolBackgroundColor: string;
    public schoolTextColor: string;
    public schoolColor: string;
    public animatorColor: string;
    public homeBackgroundColor: string;
    public homeTextColor: string;
    public homeColor: string;
    public typeChoicColor: string;
    public backgroundColor: string;
    public neutralBackgroundColor: string;
    public parentBackgroundColor: string;
    public teacherBackgroundColor: string;
    public animatorBackgroundColor: string;

    public nightSkyColor: string;
    public daySkyColor: string;
    public grassColor: string;

    

    public white: string;
    public darkBlue: string;

    public premium: string;
    public lasEssential: string;
    public lasPremium: string;

    constructor() {
        this.still = light_blue;
        this.talk = melon;
        this.grow = greeny_blue;
        this.inspire = dark_yellow;
        this.planet = dark_blue;

        this.superHerosFontBold = "CantedFX-Bold";
        this.superHerosFontRegular = "CantedFX-Regular";

        this.primaryColor = "#ffffff";
        this.secondaryColor = lightMainColor;
        this.secondaryBackgroundColor = lightMainColor + "66";
        this.navigationColor = lightMainColor;
        this.navigationItemColor = lightMainColor;
        this.titleColor = "#303030";
        this.textColor = nightSky;
        this.inputBackgroundColor = "#ffffff";
        this.buttonBackgroundColor = darkMainColor;
        this.buttonTextColor = "#ffffff";
        this.primaryFont = "Dosis-Regular";
        this.primaryMediumFont = "Dosis-Medium";
        this.primarySemiBoldFont = "Dosis-SemiBold";
        this.primaryBoldFont = "Dosis-Bold";
        this.secondaryFont = "Abel-Regular";
        this.liliFont = "Lili";
        this.alertOverlayColor = "#00000080";
        this.alertBackgroundColor = "#FFFFFF";
        this.errorColor = "#ff6057";
        this.white = "#fdfdfd";
        this.darkBlue = dark_grey_blue;
        this.schoolBackgroundColor = off_white;
        this.schoolTextColor = "#1C3656";
        this.schoolColor = dark_purple;
        this.animatorColor = "#FFD4BD";
        this.homeBackgroundColor = "#1C3656";
        this.homeTextColor = white;
        this.homeColor = light_yellow;
        this.typeChoicColor = melon;
        this.nightSkyColor = nightSky;
        this.daySkyColor = daySky;
        this.grassColor = grassGreen;
        
        this.backgroundColor = white;
        this.premium = orange_yellow;
        this.lasEssential = orange_yellow;
        this.lasPremium = dark_green;
        this.neutralBackgroundColor = daySky;
        this.parentBackgroundColor = nightSky;
        this.teacherBackgroundColor = off_white;
        this.animatorBackgroundColor = off_white;
        return this;
    }
}

class AppTheme extends Theme {
    //isDarkMode: boolean = useColorScheme() === 'light';

    isDarkMode: boolean = false;
    windowWidth: number = useWindowDimensions().width;
    windowHeight: number = useWindowDimensions().height;

    screenSize: ScreenSize = new ScreenSize();

    bottomInsets: number = this.screenSize.safeAreaBottomHeight;
    topInsets: number = this.screenSize.safeAreaTopHeight;

    constructor() {
        super();
        this.primaryColor = this.isDarkMode === true ? darkMainColor : lightMainColor;
        this.secondaryColor = this.isDarkMode === true ? lightMainColor : darkMainColor;
        this.navigationItemColor = this.isDarkMode === true ? "#040404" : "#ffffff";
        this.titleColor = this.isDarkMode === true ? "#ffffff" : "#303030";
        this.textColor = this.isDarkMode === true ? daySky : nightSky;
        this.buttonBackgroundColor = this.isDarkMode === true ? darkMainColor : darkMainColor;
        this.buttonTextColor = this.isDarkMode === true ? lightMainColor : darkMainColor;


        return this;
    }

    getLiliAtHomeTitleColor = () => {
        return this.white;
    }

    private getImageScale = () => {
        let pageWidth = this.getBlockWidth();
        let pageHeight = pageWidth * 380 / 285;
        const ratioForMenuHeightInScreen = 3 / 5;
        const ratioForMenuHeight = pageHeight / this.getFullScreenHeight();
        if (ratioForMenuHeight > ratioForMenuHeightInScreen) {
            pageHeight = ratioForMenuHeightInScreen * this.getFullScreenHeight();
            pageWidth = pageHeight * 285 / 380;
        }

        const refWidth = this.pixelPerfectRef(285);
        const refHeight = this.pixelPerfectRef(380);
        const refResolution = Math.sqrt(refWidth * refWidth + refHeight * refHeight);
        const currentResolution = Math.sqrt(pageWidth * pageWidth + pageHeight * pageHeight);
        let imageScale = currentResolution / refResolution;
        if (imageScale > 1.5) {
            imageScale = 1.5
        } else if (imageScale < 1) {
            imageScale = 1;
        }

        return imageScale;
    }

    public pixelPerfect = (size: number) => {
        return Math.ceil(this.pixelPerfectRef(size)*this.getImageScale());
    }

    public pixelPerfectForFont = (size: number) => {
        return Math.ceil(this.pixelPerfectForFontRef(size)*this.getImageScale());
    }

    public pixelPerfectRef = (size: number) => {
        if (Platform.OS === "web") {
            return size;
        }
        let refWidth = templateWidth;
        let refHeight = templateHeight;
        const webWidth = Dimensions.get('window').width > 768 ? 768 : Dimensions.get('window').width;
        const webHeight = Dimensions.get('window').height > 768 ? 768 : Dimensions.get('window').height;
        const windowWidth = Platform.OS === "web" ? webWidth : Dimensions.get('window').width;
        const windowHeight = Platform.OS === "web" ? webHeight : Dimensions.get('window').height;
        const refResolution = Math.sqrt(refWidth * refWidth + refHeight * refHeight);
        const currentResolution = Math.sqrt(windowWidth * windowWidth + windowHeight * windowHeight);
        const resolutionRatio = currentResolution / refResolution;
        //const resolutionRatio = windowWidth / refWidth;
        return (resolutionRatio * size);
    }

    public pixelPerfectForFontRef = (size: number) => {
        const pixelPerfectSize = size + 6;
        if (Platform.OS !== "web") {
            return (this.pixelPerfectRef(size) + 6);
        }
        return (pixelPerfectSize + 1);
    }

    public getBlockWidth = () => {
        let blockWidth = this.getFullAppWidth() - this.pixelPerfectRef(20);
        if (blockWidth > mobileBreakpoint) {
            blockWidth = mobileBreakpoint;
        }
        return Math.ceil(blockWidth);
    }

    public getFullAppWidth() {
        let webFullWidthScreen: number = Dimensions.get("window").width > webWidth ? webWidth : Dimensions.get("window").width;
        // TEST V3 : Web full screen
        webFullWidthScreen = Dimensions.get("window").width;
        return Platform.OS === "web" ? webFullWidthScreen : Dimensions.get("window").width;
    }

    public getFullAppHeight() {
        return Dimensions.get("window").height - this.screenSize.safeAreaBottomHeight - this.screenSize.safeAreaTopHeight;
    }

    public getFullScreenWidth() {
        return Dimensions.get("window").width;
    }

    public getFullScreenHeight() {
        return Dimensions.get("window").height;
    }

    public getFlexDirectionForSplitScreen() {
        let flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse' | undefined = "column";
        const { width, height } = Dimensions.get("window");
        if (width > height) {
            // Mode portrait
            flexDirection = "row";
        }
        return flexDirection;
    }

    public getParentTextColor = () => {
        const hourOfTheDay = new Date().getHours();
        if ((hourOfTheDay > 7) && (hourOfTheDay < 19)) {
            return this.nightSkyColor;
        } else {
            return white;
        }
    }
}

export default AppTheme;