import React, { useContext, useEffect, useRef, useState } from "react";
import { View, Text, Platform, ActivityIndicator } from "react-native";
import { PagerView } from 'react-native-pager-view';
import { useNavigate, useParams } from "react-router-dom";
import Main from "../../../specific/components/Main/Main";
import MainOptions from "../../models/MainOptions";
import { getLanguage } from "../../utils/Localization/Localization";
import AppTheme from "../../utils/Theme";

// Data
import { LocalDBContext } from "../../data/LocalDB";

import ActivitySheet from "./ActivitySheet";
import ViewPager from "../../../specific/components/ViewPager/ViewPager";
import { getLocalCategories } from "../../../specific/utils/LocalStorage";
import { propertiesEventCategory, trackEvent } from "../../services/Tracking/Analytics";
import Activity from "../../models/Activity";

const ActivityPager = (props: any) => {

    let propsData = props;
    if (Platform.OS === "web") {
        propsData = useParams();
    }
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const { activityKey, sectionKey, categoryKey, activities, componentId, menu, context, index, starting_point } = propsData;
    const [viewDidAppear, setViewDidAppear] = useState(false);
    const [section, setSection] = useState(undefined);
    const [category, setCategory] = useState(undefined);

    const [localCategories, setLocalCategories] = useState({});

    const localDBData = useContext(LocalDBContext);
    const appTheme: AppTheme = new AppTheme();

    const navigationColor = section !== undefined ? section.clr.main : appTheme.daySkyColor;
    const navigationItemColor = section !== undefined ? section.clr.txt : appTheme.darkBlue;
    const backgroundColor = appTheme.backgroundColor;
    const categoryTitle = ((category !== undefined) && (category.title !== undefined) && (category.title[getLanguage()] !== undefined)) ? category.title[getLanguage()] : "";

    useEffect(() => {
        if (category !== undefined) {
            setViewDidAppear(true);
        }
    }, [category]);

    useEffect(() => {
        const sections = localDBData.sections.data;
        if (sections[sectionKey] !== undefined) {
            setSection(sections[sectionKey]);
        }
        if (localCategories[categoryKey] !== undefined) {
            setCategory(localCategories[categoryKey]);
        }
    }, [localCategories]);

    const onViewDidAppear = () => {
        getLocalCategoriesFromStorage();
    }

    const getLocalCategoriesFromStorage = async () => {
        const localCategoriesFromStorage = await getLocalCategories();
        setLocalCategories(localCategoriesFromStorage.data);
    }

    const onActivitySelected = (position:number) => {
        // On va tracker l'affichage de l'activité
        const selectedActivity: Activity = activities[position];
        const isActivityPremium: boolean = ((selectedActivity.access.is_essential === true) || (selectedActivity.access.is_premium === true));
        if (category !== undefined) {
            trackEvent("activity_viewed", {
                [propertiesEventCategory]: category?.analytics_key,
                content: selectedActivity?.action.type,
                access: isActivityPremium === true ? "premium" : "free",
                activity_name: selectedActivity?.analytics_key,
                starting_point
            });
        }
    }

    const getActivitySheets = () => {
        if (viewDidAppear === false) {
            return <ActivityIndicator color={appTheme.darkBlue} />
        }
        let activityViews = [];
        for (const anActivityIndex in activities) {
            if (Object.prototype.hasOwnProperty.call(activities, anActivityIndex)) {
                const anActivity = activities[anActivityIndex];
                activityViews.push({
                    key: anActivity.key,
                    view: <ActivitySheet
                        starting_point={starting_point}
                        inPager={true}
                        menu={menu}
                        activityKey={anActivity.key}
                        categoryKey={categoryKey}
                        sectionKey={sectionKey}
                        context={context}
                        activities={activities} />
                });
            }
        }
        return <ViewPager
            onPageSelected={onActivitySelected}
            views={activityViews}
            pageToShow={parseInt(index)}
            style={{ width: appTheme.getFullAppWidth(), flex: 1 }} />
    }

    const mainOptions: MainOptions = new MainOptions({
        key: "activity_pager",
        componentId,
        backgroundColor,
        navigationColor,
        navigationItemColor,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        canGoBack: true,
        title: categoryTitle
    });

    return <Main mainOptions={mainOptions}>
        <View onLayout={onViewDidAppear} style={{ flex: 1, height: appTheme.getFullScreenHeight() }}>
            {getActivitySheets()}
        </View>
    </Main>
}

export default ActivityPager;