import React, { useState } from "react";

import {
    TextInput,
    SafeAreaView,
    Dimensions,
    TextInputProps,
    View,
    Modal,
    Text,
    Platform
} from "react-native";
import DynamicBackground from "../components/DynamicBackground/DynamicBackground";
import User from "../data/user/User";
import AppTheme from "../utils/Theme";

import Button from "./Button";



const OTBTextInput = (props: TextInputProps & { inputWidth: number, onTextInput:(text:string) => void }) => {

    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();
    const primaryColor = appTheme.darkBlue;
    const secondaryColor = appTheme.textColor;
    const [showModalInput, setShowModalInput] = useState(false);
    const [inputData, setInputData] = useState("");
    const { width, height } = Dimensions.get("window");
    const padding = appTheme.pixelPerfect(20);
    const { inputWidth = width - padding * 2 } = props;

    const onFocus = () => {
        if (Platform.OS !== "web") {
            //setShowModalInput(true)
        }
    }

    const onBlur = () => {
        if (Platform.OS !== "web") {
            //setShowModalInput(false)
        }
    }

    const onTextChange = (text: string) => {
        setInputData(text);
        if (props.onTextInput !== undefined) {
            props.onTextInput(text);
        }
    }

    return (
        <View>
            <TextInput
                value={inputData}
                onFocus={onFocus}
                clearButtonMode="while-editing"
                style={{
                    fontSize:appTheme.pixelPerfectForFont(10),
                    fontFamily:appTheme.secondaryFont,
                    color: secondaryColor,
                    borderRadius: appTheme.pixelPerfect(18),
                    marginVertical: appTheme.pixelPerfect(5),
                    padding: appTheme.pixelPerfect(10),
                    width: inputWidth,
                    height: props.multiline === true ? appTheme.pixelPerfect(132) : appTheme.pixelPerfect(36),
                    backgroundColor: "#FFFFFF"
                }}
                {...props} />
            <Modal animationType="fade" visible={showModalInput}>
                <DynamicBackground isTeacher={user.isTeacher()} />
                <SafeAreaView style={{ height, width, alignItems: "center" }}>
                    <TextInput
                        autoFocus={showModalInput}
                        onChangeText={onTextChange}
                        value={inputData}
                        onBlur={onBlur}
                        clearButtonMode="while-editing"
                        style={{
                            marginTop:appTheme.pixelPerfect(20),
                            fontSize:appTheme.pixelPerfectForFont(10),
                            fontFamily:appTheme.secondaryFont,
                            color: secondaryColor,
                            borderRadius: appTheme.pixelPerfect(18),
                            marginVertical: appTheme.pixelPerfect(5),
                            padding: appTheme.pixelPerfect(10),
                            width: inputWidth,
                            height: props.multiline === true ? appTheme.pixelPerfect(132) : appTheme.pixelPerfect(36),
                            backgroundColor: "#FFFFFF"
                        }}
                        {...props} />
                    <Button style={{width:appTheme.pixelPerfect(100), backgroundColor:appTheme.talk}} onPress={() => setShowModalInput(false)}>
                        <Text>Valider</Text>
                    </Button>
                </SafeAreaView>
            </Modal>
        </View>
    )
}

export default OTBTextInput;
