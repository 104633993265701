import React, { FC, useEffect, useState } from "react";

import {
    Dimensions,
    StyleSheet,
    Pressable,
    Text,
    ActivityIndicator
} from 'react-native';

import AppTheme from "../utils/Theme";

// SVG
import SVGView from "../utils/SvgView";
import { default as IcPlay } from "../assets/svg/icons/solid/play.svg";
import { default as IcPause } from "../assets/svg/icons/solid/pause.svg";
import { default as IcList } from "../assets/svg/icons/solid/list.svg";
import Style from "../utils/Style";


const PlayButton = (props: {
    disabled?: boolean,
    onPress?: ((event: any) => void),
    inversed?: boolean,
    isLoading?: boolean,
    icon?: string,
    iconColor?: string,
    backgroundColor?: string,
    size?: number,
    iconSize?: number
}) => {

    const appTheme: AppTheme = new AppTheme();
    const [buttonIcon, setButtonIcon] = useState("play");

    const {
        disabled,
        onPress,
        inversed = false,
        isLoading,
        iconColor = appTheme.white,
        backgroundColor = appTheme.nightSkyColor,
        size = appTheme.pixelPerfect(80),
        iconSize = appTheme.pixelPerfect(28),
        icon = "play"
    } = props;

    useEffect(() => {
        setButtonIcon(icon);
    }, []);

    useEffect(() => {
        if (icon !== buttonIcon) {
            setButtonIcon(icon);
        }
    }, [props]);

    const getButtonIcon = () => {
        if (buttonIcon === "pause") {
            return <SVGView Component={IcPause} width={iconSize} height={iconSize} color={appTheme.white} />
        } else if (buttonIcon === "list") {
            return <SVGView Component={IcList} width={iconSize} height={iconSize} color={appTheme.white} />
        } else {
            return <SVGView Component={IcPlay} width={iconSize} height={iconSize} color={appTheme.white} />
        }
    }

    const buttonContent = ((isLoading !== undefined) && (isLoading === true)) ?
        <ActivityIndicator color={iconColor} /> : getButtonIcon();

    const isDisabled = disabled !== undefined ? disabled : false;

    return (
        <Pressable
            disabled={isDisabled}
            onPress={onPress}
            style={({ pressed }) => [
                Style.shadowed,
                {
                    opacity: pressed === true ? 0.5 : 1
                },
                {
                    backgroundColor,
                    width: size,
                    height: size,
                    borderRadius: size / 2,
                    justifyContent: "center",
                    alignItems: "center"
                }
            ]}>
            {buttonContent}
        </Pressable>
    )

}

export default PlayButton;