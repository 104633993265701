import React, { useContext, useEffect, useRef, useState } from 'react';
import {  Text, View, TouchableOpacity, Modal, ScrollView, SectionList, Animated, Platform } from 'react-native';


// Data
import { LocalDBContext } from "../../data/LocalDB";
import { useParams, useNavigate } from 'react-router-dom';
import AppTheme from '../../utils/Theme';
import Images from '../../../specific/utils/Images';
import User from '../../data/user/User';
import i18n, { getLanguage } from '../../utils/Localization/Localization';
import Button from '../../designSystem/Button';
import Style from '../../utils/Style';
import * as DateUtils from "../../utils/DateUtils";

// SVG
import SVGView from "../../utils/SvgView";
import { default as IcChevronRight } from "../../assets/svg/icons/solid/chevron-right.svg";
import { default as IcChevronLeft } from "../../assets/svg/icons/solid/chevron-left.svg";
import Main from '../../../specific/components/Main/Main';
import MainOptions from '../../models/MainOptions';
import DynamicBackground from '../DynamicBackground/DynamicBackground';
import RainbowPoints from './RainbowPoints';
import { getImageKeyForBadgeId } from './Game';
import PDFViewer from '../../../specific/components/PDF/PDFViewer';
import ViewPager from '../../../specific/components/ViewPager/ViewPager';
import { unlockedTheBadge } from '../../services/Database';
import { analyctisEventPageViewed, analyticsEventBadgeActivated, analyticsEventBadgeDownloaded, propertiesEventCategory, propertiesEventScreenLabel, trackEvent } from '../../services/Tracking/Analytics';

import Image from "../../designSystem/OTBImage";

const UserRewards = (props: any) => {

    let propsData = props;
    let navigate = null;
    if (Platform.OS === "web") {
        propsData = useParams();
        navigate = useNavigate();
    }

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();
    const isTeacher = user.isTeacher();
    const defaultTextColor = isTeacher === true ? appTheme.darkBlue : appTheme.white;
    const localDBData = useContext(LocalDBContext);
    const rewardsScrollView = useRef<ScrollView>(null);


    const badgeImageSize = appTheme.pixelPerfect(80);

    const localBadges = localDBData.badges.data;
    const discoveryBadges = localBadges.discoveryBadges;
    const fidelityBadges = localBadges.fidelityBadges;
    const eventsBadges = localBadges.eventsBadges;

    const [refreshed, setRefreshed] = useState(0);
    const [refreshList, setRefreshList] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [badges, setBadges] = useState({});
    const [gainedBadges, setGainedBadges] = useState({});
    const [unlockedBadges, setUnlockedBadges] = useState({});
    const [badgesBySection, setBadgesBySection] = useState([]);
    const [badgesViewPosition, setBadgesViewPosition] = useState(appTheme.pixelPerfect(590));
    const [daysInARow, setDaysInARow] = useState(1);
    const [badgePage, setBadgePage] = useState(0);
    const [showFullSection, setShowFullSection] = useState("none");
    const [sectionToShow, setSectionToShow] = useState(0);
    const [showPdf, setShowPdf] = useState(false);
    const [pdfToShow, setPdfToShow] = useState({ url: "", name: "" });
    const [viewDidAppear, setViewDidAppear] = useState(false);

    const [firstSectionCellHeight] = useState(new Animated.Value(0));
    const [secondSectionCellHeight] = useState(new Animated.Value(0));
    const [thirdSectionCellHeight] = useState(new Animated.Value(0));
    const [firstSectionCellOpacity] = useState(new Animated.Value(0));
    const [secondSectionCellOpacity] = useState(new Animated.Value(0));
    const [thirdSectionCellOpacity] = useState(new Animated.Value(0));
    const [howToGainBadgeWidth] = useState(new Animated.Value(appTheme.getFullAppWidth()));
    const [howToGainBadgeOpacity] = useState(new Animated.Value(0));

    const titleStyle = {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: appTheme.pixelPerfect(40),
        marginBottom: appTheme.pixelPerfect(5)
    };

    const textStyle = {
        fontSize: appTheme.pixelPerfectForFont(12)
    };

    const [currentBadge, setCurrentBadge] = useState({
        item: {
            imgUrl: '',
            messageBadgeActive: {
                fr: ''
            },
            messageBadgeInactive: {
                fr: ''
            },
        },
        imgCustomStyle: null,
        isActive: false
    });

    // badgeToGet est envoyé depuis les notifications de gamificiation pour récupérer un badge. Voir si on le remet plus tard
    const { badgeToGet = "" } = propsData;
    const { sectionId, componentId, backgroundColor, dbSectionsData, dbBadgesData, dbUserData, embded, overlay } = propsData;

    const onViewDidAppear = () => {
        if (viewDidAppear === false) {
            trackEvent(analyctisEventPageViewed, { [propertiesEventScreenLabel]: "badges" });
        }
        setViewDidAppear(true);
    }

    useEffect(() => {
        if (pdfToShow.url.length === 0) {
            setShowPdf(false);
        } else {
            setShowPdf(true);
        }
    }, [pdfToShow]);

    useEffect(() => {
        checkBadges();
    }, []);

    // Affichage de l'explication de la gamificatin
    const toogleHowToGainBadgeView = () => {
        let howToGainBadgeOpacityNewValue = 1;
        let howToGainBadgeWidthNewValue = appTheme.pixelPerfect(20);
        if (howToGainBadgeOpacity._value === 1) {
            howToGainBadgeOpacityNewValue = 0
            howToGainBadgeWidthNewValue = appTheme.getFullAppWidth();
        }
        Animated.parallel([
            Animated.timing(howToGainBadgeOpacity, {
                toValue: howToGainBadgeOpacityNewValue,
                duration: 500,
                useNativeDriver: false
            }),
            Animated.timing(howToGainBadgeWidth, {
                toValue: howToGainBadgeWidthNewValue,
                duration: 500,
                useNativeDriver: false
            }),
        ]).start(() => {

        });
    }

    // Construction des badges de date
    const getDatePicto = (badgeKey: string, isOwned: boolean, size: number) => {
        const badgeDate = DateUtils.getDayAndMonthForBadge(badgeKey);
        let bgColor = "#AFA0F7";
        for (const aBadgeIndex in eventsBadges) {
            if (Object.hasOwnProperty.call(eventsBadges, aBadgeIndex)) {
                const anEventBadge = eventsBadges[aBadgeIndex];
                if (anEventBadge.id === badgeKey) {
                    bgColor = anEventBadge.bgColor;
                    break;
                }
            }
        }
        const calendarWidth = size / 2;
        const calendarHeight = (size + 10) / 2;
        const fontSize = size > appTheme.pixelPerfect(60) ? appTheme.pixelPerfectForFont(22) : appTheme.pixelPerfectForFont(14);
        const fontSizeForMonth = size > appTheme.pixelPerfect(60) ? appTheme.pixelPerfectForFont(8) : appTheme.pixelPerfectForFont(2);
        const marginTop = size > appTheme.pixelPerfect(60) ? appTheme.pixelPerfect(4) : appTheme.pixelPerfect(2);
        const headerHeight = size > appTheme.pixelPerfect(60) ? appTheme.pixelPerfect(14) : appTheme.pixelPerfect(10);
        let backgroundColor = appTheme.white;
        let borderWidth = appTheme.pixelPerfect(1);
        const borderColor = '#00000050';
        let textColor = borderColor;
        let monthTextColor = borderColor;
        let dateBackgroundColor = appTheme.white;
        let dateText = <Text style={[{ fontSize, fontFamily: appTheme.primaryBoldFont, color: textColor }]}>{badgeDate.day}</Text>;
        if (isOwned === true) {
            backgroundColor = bgColor;
            borderWidth = 0;
            dateBackgroundColor = '#DD0000';
            textColor = '#000000';
            monthTextColor = "#fff";
            dateText = <Text style={[{ fontSize, fontFamily: appTheme.primaryBoldFont, color: textColor }]}>{badgeDate.day}</Text>;
        }

        const picto = <View style={{ borderColor, borderWidth, justifyContent: 'center', alignItems: 'center', width: size, height: size, borderRadius: size / 2, backgroundColor }}>
            <View style={{ borderWidth, borderColor, backgroundColor: appTheme.white, width: calendarWidth, height: calendarHeight, alignItems: 'center' }}>
                <View style={{ borderBottomWidth: borderWidth, borderLeftWidth: borderWidth, borderRightWidth: borderWidth, borderColor, backgroundColor: dateBackgroundColor, height: headerHeight, width: calendarWidth, justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ fontSize: fontSizeForMonth, fontFamily: appTheme.primaryBoldFont, color: monthTextColor }}>{badgeDate.month.toLocaleUpperCase()}</Text>
                </View>
                {dateText}
            </View>
        </View>
        return (picto);
    }

    // Affichage du coloriage correspondant au badge
    const showDrawing = (badgeKey: string) => {
        if (badgeKey.includes('badge_') === false) {
            const notificationData = [{
                action: "eventBadge",
                uid: badgeKey
            }];
            // TO DO : VOir ce que c'est que ça ?? :)
            /*
            Navigation.showOverlay({
                component: {
                    name: Constants.Notification,
                    passProps: {
                        notificationData
                    },
                    options: {
                        layout: {
                            componentBackgroundColor: 'transparent'
                        },
                    }
                }
            });
            */
        } else {
            let pdfUrl = '';
            let badgeType = "fidelity";
            for (const aDiscoveryBadgeIndex in discoveryBadges) {
                if (Object.hasOwnProperty.call(discoveryBadges, aDiscoveryBadgeIndex)) {
                    const aDiscovryBadge = discoveryBadges[aDiscoveryBadgeIndex];
                    if (aDiscovryBadge.id === badgeKey) {
                        pdfUrl = aDiscovryBadge.pdf[getLanguage()];
                        badgeType = "discovery";
                        break;
                    }
                }
            }
            if (pdfUrl.length === 0) {
                for (const aFidelityBadgeIndex in fidelityBadges) {
                    if (Object.hasOwnProperty.call(fidelityBadges, aFidelityBadgeIndex)) {
                        const aFidelityBadge = fidelityBadges[aFidelityBadgeIndex];
                        if (aFidelityBadge.id === badgeKey) {
                            pdfUrl = aFidelityBadge.pdf[getLanguage()];
                            break;
                        }
                    }
                }
            }

            if (pdfUrl.length > 0) {
                // TODO : Afficher le PDF 
                trackEvent(analyticsEventBadgeDownloaded, { [propertiesEventCategory]: badgeType });
                setPdfToShow({ url: pdfUrl, name: i18n.t('userRewards.' + badgeKey) });
            }
        }
    }

    // Gestion du pager des badges
    const movePagger = (goForward: boolean) => {
        let currentBadgePage = badgePage;
        if (goForward === true) {
            currentBadgePage = badgePage + 1;
        } else {
            currentBadgePage = badgePage - 1;
        }
        setBadgePage(currentBadgePage);
    }

    // On va récupérer les infos de la vue des badges, pour scroller sur cette section si un badge est à débloquer
    const onBadgesViewLayout = (event: any) => {
        setBadgesViewPosition(event.nativeEvent.layout.y);
    }

    // Récupération des titres des boutons
    const getButtonTitle = (sectionIndex: number) => {
        if (sectionIndex === 0) {
            if (firstSectionCellOpacity._value === 0) {
                return i18n.t('userRewards.seeAllDiscoveryBadges');
            } else {
                return i18n.t('userRewards.hideAllDiscoveryBadges');
            }
        } else if (sectionIndex === 1) {
            if (secondSectionCellOpacity._value === 0) {
                return i18n.t('userRewards.seeAllFidelityBadges');
            } else {
                return i18n.t('userRewards.hideAllFidelityBadges');
            }
        } else if (sectionIndex === 2) {
            if (thirdSectionCellOpacity._value === 0) {
                return i18n.t('userRewards.seeAllEventsBadges');
            } else {
                return i18n.t('userRewards.hideAllEventsBadges');
            }
        }
    }

    //Affichage de la section des badges

    const onShowFullSectionRequest = (sectionIndex: number) => {
        let firstSectionCellHeightValue = firstSectionCellHeight._value;
        let secondSectionCellHeightValue = secondSectionCellHeight._value;
        let thirdSectionCellHeightValue = thirdSectionCellHeight._value;
        let firstSectionCellOpacityValue = firstSectionCellOpacity._value;
        let secondSectionCellOpacityValue = secondSectionCellOpacity._value;
        let thirdSectionCellOpacityValue = thirdSectionCellOpacity._value;
        if (sectionIndex === 0) {
            firstSectionCellHeightValue = firstSectionCellHeight._value === 0 ? appTheme.pixelPerfect(160) : 0;
            firstSectionCellOpacityValue = firstSectionCellOpacity._value === 0 ? 1 : 0;
        } else if (sectionIndex === 1) {
            secondSectionCellHeightValue = secondSectionCellHeight._value === 0 ? appTheme.pixelPerfect(160) : 0;
            secondSectionCellOpacityValue = secondSectionCellOpacity._value === 0 ? 1 : 0;
        } else if (sectionIndex === 2) {
            thirdSectionCellHeightValue = thirdSectionCellHeight._value === 0 ? appTheme.pixelPerfect(160) : 0;
            thirdSectionCellOpacityValue = thirdSectionCellOpacity._value === 0 ? 1 : 0;
        }
        const duration = 100;
        Animated.parallel([
            Animated.timing(firstSectionCellHeight, {
                toValue: firstSectionCellHeightValue,
                duration,
                useNativeDriver: false
            }),
            Animated.timing(secondSectionCellHeight, {
                toValue: secondSectionCellHeightValue,
                duration,
                useNativeDriver: false
            }),
            Animated.timing(thirdSectionCellHeight, {
                toValue: thirdSectionCellHeightValue,
                duration,
                useNativeDriver: false
            }),
            Animated.timing(firstSectionCellOpacity, {
                toValue: firstSectionCellOpacityValue,
                duration,
                useNativeDriver: false
            }),
            Animated.timing(secondSectionCellOpacity, {
                toValue: secondSectionCellOpacityValue,
                duration,
                useNativeDriver: false
            }),
            Animated.timing(thirdSectionCellOpacity, {
                toValue: thirdSectionCellOpacityValue,
                duration,
                useNativeDriver: false
            })
        ]).start(() => {
            setRefreshList(refreshList + 1);
        });
    }

    // Section List items
    const keyExtractor = (item: any, index: number) => {
        return (index.toString());
    }

    const victoryCell = (data: { item: any, index: number }) => {
        const { item, index } = data;
        const gainedBadges = badges;
        let cellHeight = appTheme.pixelPerfect(140);
        let cellOpacity = 1;
        if (index > 2) {
            cellHeight = firstSectionCellHeight._value;
            cellOpacity = firstSectionCellOpacity._value;
            if (item.type === 'fidelity') {
                cellHeight = secondSectionCellHeight._value;
                cellOpacity = secondSectionCellOpacity._value;
            } else if (item.type === 'event') {
                cellHeight = thirdSectionCellHeight._value;
                cellOpacity = thirdSectionCellOpacity._value;
            }
        }
        const userLevel = ((gainedBadges[item.id] !== undefined) && (gainedBadges[item.id].userLevel !== undefined)) ? gainedBadges[item.id].userLevel : 0;
        const flexValueForLevel = parseInt(userLevel) / parseInt(item.goal);
        const flexValueForMissingLevel = 1 - flexValueForLevel;
        const isOwned = userLevel === item.goal;
        const isBadgeUnlocked = isOwned === false ? true : ((gainedBadges[item.id] !== undefined) && (gainedBadges[item.id].status !== undefined) && (gainedBadges[item.id].status === 'unlocked')) ? true : false;
        let picto = <View />;
        let badgeTitle = i18n.t('userRewards.' + item.id);
        let progessView = isBadgeUnlocked === true ? <View style={{ marginTop: appTheme.pixelPerfect(5), width: appTheme.pixelPerfect(160), flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ width: appTheme.pixelPerfect(130), flexDirection: 'row', height: appTheme.pixelPerfect(10), borderRadius: appTheme.pixelPerfect(10), borderWidth: 1, borderColor: "#EFEFEF", backgroundColor: appTheme.backgroundColor, overflow: 'hidden' }}>
                <View style={{ flex: flexValueForLevel, backgroundColor: appTheme.darkBlue }} />
                <View style={{ flex: flexValueForMissingLevel, backgroundColor: appTheme.backgroundColor }} />
            </View>
            <Text style={{ fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue, width: appTheme.pixelPerfect(30), marginLeft: appTheme.pixelPerfect(10) }}>{userLevel + '/' + item.goal}</Text>
        </View> : <TouchableOpacity onPress={() => getTheBadge(item)} style={{ paddingHorizontal: appTheme.pixelPerfect(5), marginTop: appTheme.pixelPerfect(5), borderRadius: appTheme.pixelPerfect(10), backgroundColor: '#AFA0F7', justifyContent: 'center', alignItems: 'center', height: appTheme.pixelPerfect(20) }}>
            <Text style={{ fontFamily: appTheme.primaryBoldFont, paddingTop: appTheme.pixelPerfect(2), color: appTheme.darkBlue }}>Obtenir mon badge</Text>
        </TouchableOpacity>;
        if ((item.isDate !== undefined) && (item.isDate === true)) {
            picto = getDatePicto(item.id, isOwned, appTheme.pixelPerfect(60));
            const badgeDate = DateUtils.getDayAndMonthForBadge(item.id);
            badgeTitle = badgeDate.day + ' ' + badgeDate.month;
        } else {
            picto = <Image source={images[getImageKeyForBadgeId(item.id, isOwned)]} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(60), borderRadius: appTheme.pixelPerfect(30) }} resizeMode='cover' />;
        }
        return (
            <Animated.View style={{ opacity: cellOpacity, width: appTheme.getFullAppWidth(), justifyContent: 'center', alignItems: 'center', height: cellHeight }}>
                <TouchableOpacity onPress={() => onVictoryCellPressed(item, isOwned, isBadgeUnlocked)} style={[Style.shadowed, { flexDirection: 'row', width: appTheme.pixelPerfect(272), height: cellHeight - appTheme.pixelPerfect(20), margin: appTheme.pixelPerfect(10), padding: appTheme.pixelPerfect(10), backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(10), alignItems: 'center' }]}>
                    {picto}
                    <View style={{ marginLeft: appTheme.pixelPerfect(20), justifyContent: 'center', flex: 1 }}>
                        <Text style={{ fontSize: appTheme.pixelPerfectForFont(13), fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue }}>{badgeTitle.toLocaleUpperCase()}</Text>
                        <Text style={{ fontSize: appTheme.pixelPerfectForFont(10), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }}>{item.description[getLanguage()]}</Text>
                        {progessView}
                    </View>
                </TouchableOpacity>
            </Animated.View>
        )
    }

    const closeBadgeAlert = () => {
        setModalVisible(false);
    }


    const getTheBadge = async (item: any) => {
        // TODO : Unlock the babdge
        try {
            const result = await unlockedTheBadge({ badgeId: item.id, user_id: user.uid });
            trackEvent(analyticsEventBadgeActivated, { [propertiesEventCategory]: item.type });
            checkBadges();
            setPdfToShow({ url: item.pdf[getLanguage()], name: i18n.t('userRewards.' + item.id) });
        } catch (error) {
        }
    }

    const showUnlockedBadgeNotificaiton = (badgeId: string) => {
        // TODO : Show adge unlocked
        /*
        const notificationData = [{
            action: "badgeUnlocked",
            uid: badgeId,
            onButtonPress: this.showDrawing,
            buttonTitle: i18n.t('notification.printTheBadge')
        }];
        Navigation.showOverlay({
            component: {
                name: Constants.Notification,
                passProps: {
                    notificationData
                },
                options: {
                    layout: {
                        componentBackgroundColor: 'transparent'
                    },
                }
            }
        });
        */
    }

    const onVictoryCellPressed = (item: any, isOwned: boolean, isBadgeUnlocked: boolean) => {
        if (isOwned === true) {
            if (isBadgeUnlocked == true) {
                showDrawing(item.id);
            } else {
                trackEvent(analyticsEventBadgeActivated, { [propertiesEventCategory]: item.type });
                getTheBadge(item);
            }
        }
    }

    // Gestion individuelle des points
    const checkBadges = () => {
        let userBadgesBySection = [{
            id: "discovery",
            title: 'Badges découverte',
            data: discoveryBadges
        }, {
            id: "fidelity",
            title: 'Badges fidélité',
            data: fidelityBadges
        }, {
            id: "event",
            title: 'Badges évènements',
            data: eventsBadges
        }];

        // Quels sont les bages de l'utilisateur, ceux gagnés et ceux à débloquer
        let userBadges = {};
        let userGainedBadges = {};
        let userUnlockedBadges = {};
        if ((user.badges !== undefined) && (user.badges !== null)) {
            userBadges = user.badges;
            for (const aBadgeKey in userBadges) {
                if (Object.hasOwnProperty.call(userBadges, aBadgeKey)) {
                    const aBadge = userBadges[aBadgeKey];
                    if (aBadge.gainedAt !== undefined) {
                        userGainedBadges[aBadgeKey] = aBadge;
                        if (aBadge.status === 'unlocked') {
                            userUnlockedBadges[aBadgeKey] = aBadge;
                        }
                    }
                }
            }
        }

        // Combien de jour de suite l'utilisateur a-t-il utiliser l'app
        let userDaysInARow = 1;
        if ((user.days_in_a_row !== undefined) && (user.days_in_a_row !== null)) {
            userDaysInARow = user.days_in_a_row;
        }

        // On va regarder si un ou plusieurs badges sont à débloquer, et on va les mettre en premières positions
        let userSectionToShow = 0;
        for (const aBadgesBySectionIndex in userBadgesBySection) {
            if (Object.hasOwnProperty.call(userBadgesBySection, aBadgesBySectionIndex)) {
                let aBadgesSection = userBadgesBySection[aBadgesBySectionIndex];
                let ownedUnlocked = [];
                let ownedToBeUnlocked = [];
                let notOwned = [];
                for (const aBadgeIndex in aBadgesSection.data) {
                    if (Object.hasOwnProperty.call(aBadgesSection.data, aBadgeIndex)) {
                        const aBadgeToReview = aBadgesSection.data[aBadgeIndex];
                        if (aBadgeToReview.id === badgeToGet) {
                            userSectionToShow = aBadgesBySectionIndex;
                        }
                        if (userGainedBadges[aBadgeToReview.id] !== undefined) {
                            const aGaindedBadge = userGainedBadges[aBadgeToReview.id];
                            const userLevel = ((aGaindedBadge !== undefined) && (aGaindedBadge.userLevel !== undefined)) ? aGaindedBadge.userLevel : 0;
                            const isOwned = userLevel === aBadgeToReview.goal;
                            const isBadgeUnlocked = isOwned === false ? true : ((aGaindedBadge !== undefined) && (aGaindedBadge.status !== undefined) && (aGaindedBadge.status === 'unlocked')) ? true : false;
                            if (isOwned === false) {
                                notOwned.push(aBadgeToReview)
                            } else if (isBadgeUnlocked === true) {
                                ownedUnlocked.push(aBadgeToReview);
                            } else {
                                ownedToBeUnlocked.push(aBadgeToReview);
                            }
                        } else {
                            notOwned.push(aBadgeToReview);
                        }
                    }
                }
                aBadgesSection.data = ownedToBeUnlocked.concat(ownedUnlocked.concat(notOwned));
                userBadgesBySection[aBadgesBySectionIndex] = aBadgesSection;
            }
        }
        setBadges(userBadges);
        setGainedBadges(userGainedBadges);
        setUnlockedBadges(userUnlockedBadges);
        setBadgesBySection(userBadgesBySection);
        setDaysInARow(userDaysInARow);
        setRefreshList(refreshList + 1);
        setSectionToShow(userSectionToShow);
        setTimeout(() => {
            if (badgeToGet.length > 0) {
                if ((rewardsScrollView !== undefined) && (rewardsScrollView.current !== undefined)) {
                    let positionToShow = badgesViewPosition + appTheme.pixelPerfect(30); // On prend en compte la marge supérieure
                    positionToShow = positionToShow + sectionToShow * (appTheme.pixelPerfect(400));
                    rewardsScrollView.current?.scrollTo({ x: 0, y: positionToShow, animated: true });
                }
            }
        }, 200);
    }

    let userBadges = {};
    if (user.badges !== undefined) {
        userBadges = user.badges;
    }

    // On va vérifier s'il y a des badges qui ont été gagné
    const noBadgesBGwidth = appTheme.getFullAppWidth() - appTheme.pixelPerfect(40);
    const noBadgesBGHeiht = 478 * noBadgesBGwidth / 847;
    let gainedBadgesView = <View style={{ width: appTheme.getFullAppWidth(), height: noBadgesBGHeiht + appTheme.pixelPerfect(40), justifyContent: 'center', alignItems: 'center', marginTop: appTheme.pixelPerfect(20) }}>
        <Text style={{ textAlign: 'center', fontFamily: appTheme.primaryBoldFont, color: defaultTextColor, fontSize: appTheme.pixelPerfect(14), marginBottom: appTheme.pixelPerfect(10) }}>
            {i18n.t('userRewards.noBadgesBody')}
        </Text>
        <Button
            onPress={toogleHowToGainBadgeView}
            title={i18n.t('userRewards.howToGainBadges')}
            style={{ backgroundColor }} />
        <Animated.View style={[Style.shadowed, { left: howToGainBadgeWidth, opacity: howToGainBadgeOpacity, width: noBadgesBGwidth, minHeight: noBadgesBGHeiht, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(20), position: 'absolute', alignItems: 'center' }]}>
            <Text style={{ fontSize: appTheme.pixelPerfectForFont(8), textAlign: 'center', fontFamily: appTheme.primaryBoldFont, padding: appTheme.pixelPerfect(10), color: appTheme.darkBlue }}>{i18n.t('userRewards.howToGainBadgesBody')}</Text>
            <Button
                onPress={toogleHowToGainBadgeView}
                title={i18n.t('userRewards.letsGo')}
                textStyle={{ color: appTheme.white }}
                style={{ backgroundColor, marginBottom: appTheme.pixelPerfect(10) }} />
        </Animated.View>
    </View>;
    if (Object.keys(unlockedBadges).length > 0) {
        let gainedBadgesDetailsView = [];
        for (const aGainedBadgeKey in unlockedBadges) {
            if (Object.hasOwnProperty.call(unlockedBadges, aGainedBadgeKey)) {
                const aBadge = unlockedBadges[aGainedBadgeKey];
                let picto = <View />;
                let badgeTitle = '';
                if (aGainedBadgeKey.includes('badge_') === false) {
                    const badgeDate = DateUtils.getDayAndMonthForBadge(aGainedBadgeKey);
                    badgeTitle = badgeDate.day + ' ' + badgeDate.month;
                    picto = getDatePicto(aGainedBadgeKey, true, appTheme.pixelPerfect(94));
                } else {
                    picto = <Image source={images[getImageKeyForBadgeId(aGainedBadgeKey, true)]} style={[{ width: appTheme.pixelPerfect(94), height: appTheme.pixelPerfect(94) }]} />;
                    badgeTitle = i18n.t('userRewards.' + aGainedBadgeKey);
                }
                gainedBadgesDetailsView.push({
                    key: aGainedBadgeKey, view: <View style={{ height: appTheme.pixelPerfect(140), width: appTheme.getFullAppWidth(), justifyContent: "center", alignItems: "center" }}>
                        <TouchableOpacity onPress={() => showDrawing(aGainedBadgeKey)} style={{ width: appTheme.pixelPerfect(140), alignItems: 'center', justifyContent: 'center' }}>
                            <View style={[Style.shadowed, { backgroundColor: "#fff1e1", width: appTheme.pixelPerfect(110), height: appTheme.pixelPerfect(110), borderRadius: appTheme.pixelPerfect(55), borderWidth: appTheme.pixelPerfect(8), borderColor: "#fff", justifyContent: 'center', alignItems: 'center' }]}>
                                {picto}
                            </View>
                            <View style={[{ width: appTheme.getFullAppWidth(), alignItems: 'center', marginTop: appTheme.pixelPerfect(10) }]}>
                                <Text style={[textStyle, { color: defaultTextColor }]}>{badgeTitle}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                }
                )
            }
        }
        const backwardButton = ((gainedBadgesDetailsView.length < 2) || (badgePage === 0)) ? <View style={{ position: 'absolute', left: appTheme.pixelPerfect(40), width: appTheme.pixelPerfect(36), height: appTheme.pixelPerfect(36) }} /> : <TouchableOpacity onPress={() => movePagger(false)} style={{ position: 'absolute', left: appTheme.pixelPerfect(40), backgroundColor: appTheme.white, width: appTheme.pixelPerfect(36), height: appTheme.pixelPerfect(36), borderRadius: appTheme.pixelPerfect(18), justifyContent: 'center', alignItems: 'center' }}>
            <SVGView Component={IcChevronLeft} size={appTheme.pixelPerfect(18)} color={appTheme.darkBlue} />
        </TouchableOpacity>;
        const forwardButton = ((gainedBadgesDetailsView.length < 2) || (badgePage === Object.keys(unlockedBadges).length - 1)) ? <View style={{ position: 'absolute', right: appTheme.pixelPerfect(40), width: appTheme.pixelPerfect(36), height: appTheme.pixelPerfect(36) }} /> : <TouchableOpacity onPress={() => movePagger(true)} style={{ position: 'absolute', right: appTheme.pixelPerfect(40), backgroundColor: appTheme.white, width: appTheme.pixelPerfect(36), height: appTheme.pixelPerfect(36), borderRadius: appTheme.pixelPerfect(18), justifyContent: 'center', alignItems: 'center' }}>
            <SVGView Component={IcChevronRight} size={appTheme.pixelPerfect(18)} color={appTheme.darkBlue} />
        </TouchableOpacity>;
        // TO DO : Trouver le pager
        const pagerView = <ViewPager
            pageToShow={badgePage}
            style={{ height: appTheme.pixelPerfect(140), width: appTheme.getFullAppWidth() }}
            views={gainedBadgesDetailsView} />
        gainedBadgesView = <View style={{}}>
            <View style={[titleStyle]}>
                <Text style={[textStyle, { color: defaultTextColor }]}> {i18n.t('userRewards.myBadges').toLocaleUpperCase()}</Text>
            </View>
            <View style={[{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}>
                {pagerView}
                {backwardButton}
                {forwardButton}
            </View>
        </View>
    }
    // On va construire la vue sur le nombre de jours gagnés
    let firstDay = daysInARow - 2 > 0 ? daysInARow - 2 : 1;
    const daysToShow = [firstDay, firstDay + 1, firstDay + 2, firstDay + 3, firstDay + 4];
    let daysInARowView = [];
    const dayCircleSize = appTheme.pixelPerfect(28);
    const presentDayCircleSize = appTheme.pixelPerfect(42);
    for (const aDayIndex in daysToShow) {
        if (Object.hasOwnProperty.call(daysToShow, aDayIndex)) {
            const aDay = daysToShow[aDayIndex];
            let circleSize = dayCircleSize;
            let circleBackgroundColor = "#D0CAF3";
            let top = appTheme.pixelPerfect(0);
            let right = appTheme.pixelPerfect(0);
            let fontSize = 12;
            if (aDay < daysInARow) {
                circleBackgroundColor = appTheme.white;
            } else if (aDay === daysInARow) {
                circleSize = presentDayCircleSize;
                circleBackgroundColor = "#AFA0F7";
                top = appTheme.pixelPerfect(7);
                right = appTheme.pixelPerfect(5);
                fontSize = 18;
            }
            let ptsView = <View />;
            const ptsViewSize = appTheme.pixelPerfect(24);
            if ((aDay > 1) && (aDay >= daysInARow)) {
                const pts = aDay > 2 ? '+30' : '+10';
                ptsView = <View style={{ justifyContent: 'center', alignItems: 'center', position: 'absolute', top, right, height: ptsViewSize, width: ptsViewSize, backgroundColor: circleBackgroundColor, borderWidth: appTheme.pixelPerfect(2), borderColor: appTheme.white, borderRadius: ptsViewSize / 2 }}>
                    <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(6), color: appTheme.white }}>{pts}</Text>
                </View>;
            }
            const pointsAndDaysViewSize = circleSize + (ptsViewSize / 2);
            daysInARowView.push(<View style={{ width: pointsAndDaysViewSize, height: pointsAndDaysViewSize, alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                <View style={{ backgroundColor: circleBackgroundColor, width: circleSize, height: circleSize, borderRadius: circleSize / 2, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: appTheme.pixelPerfectForFont(fontSize), fontFamily: appTheme.primaryFont, color: appTheme.darkBlue }}>{aDay.toString()}</Text>
                </View>
                {ptsView}
            </View>);
        }
    }
    const isModal = ((embded !== undefined) && (embded === true));
    const isOverlay = ((overlay !== undefined) && (overlay === true));
    //TO DO : Remettre la raindbow view
    const rainbowView = <RainbowPoints user={user} />;
    const badgesView = <ScrollView
        ref={rewardsScrollView}
        style={{flex:1}}>
        <View style={[{height: appTheme.pixelPerfect(40), justifyContent: 'center', alignItems: 'center'}]}>
            <Text style={[textStyle, { color: defaultTextColor, fontFamily: appTheme.primaryBoldFont }]}> {i18n.t('profile.myVictories').replace("\n", " ").toLocaleUpperCase()}</Text>
        </View>
        <View style={{ flex: 1 }}>
            <View style={[titleStyle, { marginTop: appTheme.pixelPerfect(10) }]}>
                <Text style={[textStyle, { color: defaultTextColor, fontFamily: appTheme.primaryMediumFont }]}> {i18n.t('userRewards.myRainbows').toLocaleUpperCase()}</Text>
            </View>
            <View style={{ width: appTheme.getFullAppWidth(), alignItems: 'center' }}>
                {rainbowView}
            </View>
            <View style={[titleStyle, {}]}>
                <Text style={[textStyle, { color: defaultTextColor, fontFamily: appTheme.primaryMediumFont }]}> {i18n.t('userRewards.myDays').toLocaleUpperCase()}</Text>
            </View>
            <View style={[{ width: appTheme.getFullAppWidth(), justifyContent: 'center', alignItems: 'center' }]}>
                <View style={[{ flexDirection: 'row', width: appTheme.pixelPerfect(230), justifyContent: 'space-evenly', alignItems: 'center' }]}>
                    {daysInARowView}
                </View>
            </View>
            {gainedBadgesView}
            <View onLayout={onBadgesViewLayout}>
                <SectionList
                    extraData={refreshList}
                    style={{ width: appTheme.getFullAppWidth(), marginBottom: appTheme.pixelPerfect(30) }}
                    sections={badgesBySection}
                    renderSectionHeader={({ section: { title } }) => (
                        <View style={[titleStyle, {}]}>
                            <Text style={[textStyle, { color: defaultTextColor }]}> {title}</Text>
                        </View>
                    )}
                    renderSectionFooter={({ section }) => {
                        let sectionId = 0;
                        if (section.title === 'Badges fidélité') {
                            sectionId = 1;
                        } else if (section.title === 'Badges évènements') {
                            sectionId = 2;
                        }
                        const buttonTitle = getButtonTitle(sectionId);
                        return <View style={{ width: appTheme.getFullAppWidth(), justifyContent: 'center', alignItems: 'center', marginTop: appTheme.pixelPerfect(10) }}>
                            <View style={{ width: appTheme.pixelPerfect(272) }}>
                                <Button
                                    onPress={() => onShowFullSectionRequest(sectionId)}
                                    title={buttonTitle}
                                    style={{ backgroundColor: "#AFA0F7" }} />
                            </View>
                        </View>;
                    }}
                    keyExtractor={keyExtractor}
                    renderItem={victoryCell}
                    getItemLayout={(data, index) => ({ length: appTheme.pixelPerfect(80), offset: appTheme.pixelPerfect(80) * index, index })} />
            </View>
        </View>
    </ScrollView>;

    const userBackgroundColor = isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor;

    const mainOptions: MainOptions = new MainOptions({
        key:"user_rewards",
        componentId,
        showNavBar: false,
        showMenu: false,
        canBeHidden: false,
        safeArea: false,
        canGoBack: true,
        backgroundColor: userBackgroundColor,
        navigationColor: userBackgroundColor,
        canBeDismissed: false
    });

    return (
        <Main mainOptions={mainOptions}>
            <DynamicBackground isTeacher={isTeacher} />
            <View onLayout={onViewDidAppear} style={{ width: appTheme.getFullAppWidth(), flex: 1 }}>
                {badgesView}
            </View>
            <Modal animationType="slide" visible={showPdf} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, alignItems: "center", backgroundColor: userBackgroundColor + "80", width: appTheme.getFullScreenWidth() }}>
                    <View style={{ flex: 1, justifyContent: "flex-end", backgroundColor: userBackgroundColor + "80", width: appTheme.getFullAppWidth() }}>
                        <PDFViewer
                            title={pdfToShow.name}
                            pdfUrl={pdfToShow.url}
                            color={userBackgroundColor}
                            sectionId={"rewards"}
                            closeTheDocument={() => setPdfToShow({ url: "", name: "" })} />
                    </View>
                </View>
            </Modal>
        </Main>
    )
}

export default UserRewards;
