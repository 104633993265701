import i18n from "./Localization";

class Label {

    about = {
        title : i18n.t("about.title")
    }

}

export default Label;