import { StyleSheet } from 'react-native';

const Style = StyleSheet.create({
    shadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4
    },
    fullViewShadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 4,
            height: 4,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 2
    },
    avatarShadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowOpacity: 0.50,
        shadowRadius: 5.84,
        elevation: 2
    },
    buttonShadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.50,
        shadowRadius: 3.84,
        elevation: 5
    },
    textShadowed: {
        shadowColor: "#00000050",
        shadowOffset: {
            width: 0,
            height: 0.5,
        },
        shadowOpacity: 0.75,
        shadowRadius: 1,
        elevation: 2
    },
    navBarShadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 6,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    bottomBarShadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    webNavBarShadowed: {
        shadowColor: "#F00",
        shadowOffset: {
            width: 6,
            height: 12,
        },
        shadowOpacity: 0.25,
        shadowRadius: 8,
        elevation: 5
    },
    switchShadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    }
});

export default Style;