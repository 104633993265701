module.exports = {
    "all": {
        "Default": {
            "serverDescription": "All purchases available in the app",
            "metadata": {},
            "monthly": {
                "offeringIdentifier": "Default",
                "identifier": "$rc_monthly",
                "product": {
                    "price": 4.99,
                    "productCategory": "SUBSCRIPTION",
                    "subscriptionPeriod": "P1M",
                    "discounts": [],
                    "description": "7 jours gratuits puis 4,99€/mois",
                    "productType": "NON_CONSUMABLE",
                    "priceString": "4,99 €",
                    "currencyCode": "EUR",
                    "introPrice": {
                        "cycles": 1,
                        "periodUnit": "WEEK",
                        "periodNumberOfUnits": 1,
                        "priceString": "0,00 €",
                        "period": "P1W",
                        "price": 0
                    },
                    "title": "Mensuel",
                    "identifier": "cool.lili.sub.sevendaysonemonth"
                },
                "packageType": "MONTHLY"
            },
            "availablePackages": [
                {
                    "packageType": "MONTHLY",
                    "offeringIdentifier": "Default",
                    "product": {
                        "currencyCode": "EUR",
                        "identifier": "cool.lili.sub.sevendaysonemonth",
                        "price": 4.99,
                        "subscriptionPeriod": "P1M",
                        "productCategory": "SUBSCRIPTION",
                        "introPrice": {
                            "periodUnit": "WEEK",
                            "periodNumberOfUnits": 1,
                            "period": "P1W",
                            "priceString": "0,00 €",
                            "price": 0,
                            "cycles": 1
                        },
                        "title": "Mensuel",
                        "productType": "NON_CONSUMABLE",
                        "description": "7 jours gratuits puis 4,99€/mois",
                        "discounts": [],
                        "priceString": "4,99 €"
                    },
                    "identifier": "$rc_monthly"
                },
                {
                    "offeringIdentifier": "Default",
                    "packageType": "ANNUAL",
                    "identifier": "$rc_annual",
                    "product": {
                        "title": "Annuel",
                        "productType": "NON_CONSUMABLE",
                        "description": "7 jours gratuits puis 35,99€/an",
                        "discounts": [],
                        "introPrice": {
                            "price": 0,
                            "priceString": "0,00 €",
                            "period": "P1W",
                            "periodNumberOfUnits": 1,
                            "cycles": 1,
                            "periodUnit": "WEEK"
                        },
                        "priceString": "35,99 €",
                        "identifier": "cool.lili.sub.sevendaysoneyear",
                        "price": 35.99,
                        "productCategory": "SUBSCRIPTION",
                        "subscriptionPeriod": "P1Y",
                        "currencyCode": "EUR"
                    }
                },
                {
                    "identifier": "GiftOneMonth",
                    "packageType": "CUSTOM",
                    "offeringIdentifier": "Default",
                    "product": {
                        "title": "CADEAU 1 MOIS",
                        "currencyCode": "EUR",
                        "priceString": "3,99 €",
                        "identifier": "cool.lili.gift.onemonth",
                        "introPrice": null,
                        "productCategory": "NON_SUBSCRIPTION",
                        "price": 3.9899999999999998,
                        "subscriptionPeriod": null,
                        "description": "LILI en cadeau 1 mois à l'un de vos proches",
                        "discounts": [],
                        "productType": "NON_CONSUMABLE"
                    }
                },
                {
                    "identifier": "GiftThreeMonths",
                    "product": {
                        "description": "LILI en cadeau 3 mois à l'un de vos proches",
                        "productType": "NON_CONSUMABLE",
                        "discounts": [],
                        "title": "CADEAU 3 MOIS",
                        "introPrice": null,
                        "subscriptionPeriod": null,
                        "price": 12.99,
                        "productCategory": "NON_SUBSCRIPTION",
                        "currencyCode": "EUR",
                        "identifier": "cool.lili.gift.threemonths",
                        "priceString": "12,99 €"
                    },
                    "offeringIdentifier": "Default",
                    "packageType": "CUSTOM"
                },
                {
                    "packageType": "CUSTOM",
                    "offeringIdentifier": "Default",
                    "identifier": "GiftOneYear",
                    "product": {
                        "productType": "NON_CONSUMABLE",
                        "description": "LILI en cadeau 1 mois à l'un de vos proches",
                        "discounts": [],
                        "identifier": "cool.lili.gift.oneyear",
                        "introPrice": null,
                        "price": 34.989999999999995,
                        "productCategory": "NON_SUBSCRIPTION",
                        "subscriptionPeriod": null,
                        "currencyCode": "EUR",
                        "title": "Cadeau 1 An",
                        "priceString": "34,99 €"
                    }
                }
            ],
            "identifier": "Default",
            "annual": {
                "identifier": "$rc_annual",
                "packageType": "ANNUAL",
                "product": {
                    "productCategory": "SUBSCRIPTION",
                    "subscriptionPeriod": "P1Y",
                    "price": 35.989999999999995,
                    "priceString": "35,99 €",
                    "identifier": "cool.lili.sub.sevendaysoneyear",
                    "title": "Annuel",
                    "description": "7 jours gratuits puis 35,99€/an",
                    "discounts": [],
                    "productType": "NON_CONSUMABLE",
                    "currencyCode": "EUR",
                    "introPrice": {
                        "price": 0,
                        "periodNumberOfUnits": 1,
                        "period": "P1W",
                        "priceString": "0,00 €",
                        "cycles": 1,
                        "periodUnit": "WEEK"
                    }
                },
                "offeringIdentifier": "Default"
            }
        },
        "LAHV2": {
            "serverDescription": "New offers for parents",
            "metadata": {},
            "monthly": {
                "packageType": "MONTHLY",
                "identifier": "$rc_monthly",
                "product": {
                    "stripe_product_id": "prod_OAHZLesTbtpW2P",
                    "stripe_price_id": "price_1NNx0FL6k4WG2qIj1YzOpjob",
                    "stripe_product_id_test": "prod_NFIawOAgITW4B0",
                    "stripe_price_id_test": "price_1MUnz9L6k4WG2qIjQaoAR71C",
                    "subscriptionPeriod": "P1M",
                    "price": 4.99,
                    "productCategory": "SUBSCRIPTION",
                    "description": "7 jours gratuits puis 4,99€/mois",
                    "discounts": [],
                    "productType": "NON_CONSUMABLE",
                    "title": "Mensuel",
                    "introPrice": {
                        "periodUnit": "WEEK",
                        "cycles": 1,
                        "priceString": "0,00 €",
                        "periodNumberOfUnits": 1,
                        "period": "P1W",
                        "price": 0
                    },
                    "currencyCode": "EUR",
                    "priceString": "4,99 €",
                    "identifier": "cool.lili.sub.sevendaysonemonth"
                },
                "offeringIdentifier": "LAHV2"
            },
            "availablePackages": [
                {
                    "packageType": "ANNUAL",
                    "identifier": "$rc_annual",
                    "offeringIdentifier": "LAHV2",
                    "product": {
                        "currencyCode": "EUR",
                        "introPrice": {
                            "price": 0,
                            "priceString": "0,00 €",
                            "periodNumberOfUnits": 1,
                            "period": "P1W",
                            "cycles": 1,
                            "periodUnit": "WEEK"
                        },
                        "discounts": [],
                        "description": "7 jours gratuits puis 35,99€/an",
                        "productType": "NON_CONSUMABLE",
                        "productCategory": "SUBSCRIPTION",
                        "stripe_product_id": "prod_OAHZUZ7WJYnAfl",
                        "stripe_price_id": "price_1NNx0QL6k4WG2qIj7t9RLSkU",
                        "stripe_product_id_test": "prod_NFIawOAgITW4B0",
                    "stripe_price_id_test": "price_1MUnz9L6k4WG2qIjQaoAR71C",
                        "price": 35.989999999999995,
                        "subscriptionPeriod": "P1Y",
                        "priceString": "35,99 €",
                        "identifier": "cool.lili.sub.sevendaysoneyear",
                        "title": "Annuel"
                    }
                },
                {
                    "product": {
                        "subscriptionPeriod": "P1M",
                        "productCategory": "SUBSCRIPTION",
                        "stripe_product_id": "prod_OAHZLesTbtpW2P",
                        "stripe_price_id": "price_1NNx0FL6k4WG2qIj1YzOpjob",
                        "stripe_product_id_test": "prod_NFIawOAgITW4B0",
                        "stripe_price_id_test": "price_1MUnz9L6k4WG2qIjQaoAR71C",
                        "price": 4.99,
                        "discounts": [],
                        "description": "7 jours gratuits puis 4,99€/mois",
                        "productType": "NON_CONSUMABLE",
                        "priceString": "4,99 €",
                        "introPrice": {
                            "price": 0,
                            "periodUnit": "WEEK",
                            "cycles": 1,
                            "priceString": "0,00 €",
                            "periodNumberOfUnits": 1,
                            "period": "P1W"
                        },
                        "title": "Mensuel",
                        "identifier": "cool.lili.sub.sevendaysonemonth",
                        "currencyCode": "EUR"
                    },
                    "offeringIdentifier": "LAHV2",
                    "packageType": "MONTHLY",
                    "identifier": "$rc_monthly"
                }
            ],
            "annual": {
                "product": {
                    "currencyCode": "EUR",
                    "identifier": "cool.lili.sub.sevendaysoneyear",
                    "stripe_product_id": "prod_OAHZUZ7WJYnAfl",
                    "stripe_price_id": "price_1NNx0QL6k4WG2qIj7t9RLSkU",
                    "stripe_product_id_test": "prod_NFIawOAgITW4B0",
                    "stripe_price_id_test": "price_1MUnz9L6k4WG2qIjQaoAR71C",
                    "productCategory": "SUBSCRIPTION",
                    "subscriptionPeriod": "P1Y",
                    "price": 35.989999999999995,
                    "discounts": [],
                    "productType": "NON_CONSUMABLE",
                    "description": "7 jours gratuits puis 35,99€/an",
                    "priceString": "35,99 €",
                    "introPrice": {
                        "periodUnit": "WEEK",
                        "price": 0,
                        "priceString": "0,00 €",
                        "period": "P1W",
                        "periodNumberOfUnits": 1,
                        "cycles": 1
                    },
                    "title": "Annuel"
                },
                "packageType": "ANNUAL",
                "identifier": "$rc_annual",
                "offeringIdentifier": "LAHV2"
            },
            "identifier": "LAHV2"
        },
        "LASV2": {
            "annual": {
                "identifier": "$rc_annual",
                "product": {
                    "priceString": "120 €",
                    "price": 120,
                    "productCategory": "NON_SUBSCRIPTION",
                    "stripe_product_id_test": "prod_Nh1sXF2ARlY1yW",
                    "stripe_price_id_test": "price_1MvdojL6k4WG2qIjWktVLcn5",
                    "subscriptionPeriod": null,
                    "currencyCode": "EUR",
                    "description": "par classe pour 12 mois (100 € HT)",
                    "stripe_product_id": "prod_OAHZNzq7WkXvhw",
                    "stripe_price_id": "price_1P1k0tL6k4WG2qIjt7i2IqK6",
                    "productType": "NON_CONSUMABLE",
                    "discounts": [],
                    "identifier": "cool.lili.sub.laspremiumtwelve",
                    "title": "PREMIUM 1 AN",
                    "introPrice": null
                },
                "offeringIdentifier": "LASV2",
                "packageType": "ANNUAL"
            },
            "availablePackages": [
                {
                    "identifier": "$rc_annual",
                    "offeringIdentifier": "LASV2",
                    "product": {
                        "title": "PREMIUM 1 AN",
                        "discounts": [],
                        "productType": "NON_CONSUMABLE",
                        "description": "par classe pour 12 mois (100 € HT)",
                    "stripe_product_id_test": "prod_Nh1sXF2ARlY1yW",
                    "stripe_price_id_test": "price_1MvdojL6k4WG2qIjWktVLcn5",
                        "stripe_product_id": "prod_OAHZNzq7WkXvhw",
                        "stripe_price_id": "price_1P1k0tL6k4WG2qIjt7i2IqK6",
                        "subscriptionPeriod": null,
                        "price": 120,
                        "productCategory": "NON_SUBSCRIPTION",
                        "identifier": "cool.lili.sub.laspremiumtwelve",
                        "introPrice": null,
                        "currencyCode": "EUR",
                        "priceString": "120 €"
                    },
                    "packageType": "ANNUAL"
                },
                {
                    "identifier": "$rc_three_month",
                    "product": {
                        "description": "par classe pour 3 mois (45 € HT)",
                        "stripe_product_id": "prod_OAHY2BqfbvIiFI",
                        "stripe_price_id": "price_1P1k8oL6k4WG2qIjII7zvaxt",
                    "stripe_product_id_test": "prod_Nh1sXF2ARlY1yW",
                    "stripe_price_id_test": "price_1MvdojL6k4WG2qIjWktVLcn5",
                        "discounts": [],
                        "productType": "NON_CONSUMABLE",
                        "currencyCode": "EUR",
                        "identifier": "cool.lili.sub.laspremiumthree",
                        "introPrice": null,
                        "priceString": "54 €",
                        "productCategory": "NON_SUBSCRIPTION",
                        "subscriptionPeriod": null,
                        "price": 54,
                        "title": "PREMIUM 3 MOIS"
                    },
                    "packageType": "THREE_MONTH",
                    "offeringIdentifier": "LASV2"
                }
            ],
            "identifier": "LASV2",
            "serverDescription": "New offers for teachers",
            "threeMonth": {
                "packageType": "THREE_MONTH",
                "identifier": "$rc_three_month",
                "product": {
                    "priceString": "54 €",
                    "currencyCode": "EUR",
                    "title": "PREMIUM 3 MOIS",
                    "productCategory": "NON_SUBSCRIPTION",
                    "stripe_product_id": "prod_OAHY2BqfbvIiFI",
                    "stripe_price_id": "price_1P1k8oL6k4WG2qIjII7zvaxt",
                    "stripe_product_id_test": "prod_Nh1sXF2ARlY1yW",
                    "stripe_price_id_test": "price_1MvdojL6k4WG2qIjWktVLcn5",
                    "subscriptionPeriod": null,
                    "price": 54,
                    "introPrice": null,
                    "identifier": "cool.lili.sub.laspremiumthree",
                    "description": "par classe pour 3 mois (45€ HT)",
                    "discounts": [],
                    "productType": "NON_CONSUMABLE"
                },
                "offeringIdentifier": "LASV2"
            },
            "metadata": {}
        },
        "LASTest": {
            "identifier": "LASTest",
            "availablePackages": [
                {
                    "packageType": "ANNUAL",
                    "offeringIdentifier": "LASTest",
                    "identifier": "$rc_annual",
                    "product": {
                        "productCategory": "NON_SUBSCRIPTION",
                        "subscriptionPeriod": null,
                        "price": 49.989999999999995,
                        "description": "par classe pour 12 mois (45,82€ HT)",
                        "discounts": [],
                        "productType": "NON_CONSUMABLE",
                        "title": "PREMIUM 1 AN",
                        "identifier": "cool.lili.sub.laspremiumtwelve",
                        "priceString": "49,99 €",
                        "currencyCode": "EUR",
                        "introPrice": null
                    }
                }
            ],
            "annual": {
                "identifier": "$rc_annual",
                "offeringIdentifier": "LASTest",
                "packageType": "ANNUAL",
                "product": {
                    "priceString": "49,99 €",
                    "productCategory": "NON_SUBSCRIPTION",
                    "price": 49.989999999999995,
                    "subscriptionPeriod": null,
                    "currencyCode": "EUR",
                    "productType": "NON_CONSUMABLE",
                    "description": "par classe pour 12 mois (45,82€ HT)",
                    "discounts": [],
                    "title": "PREMIUM 1 AN",
                    "identifier": "cool.lili.sub.laspremiumtwelve",
                    "introPrice": null
                }
            },
            "metadata": {},
            "serverDescription": "Test pour récupérer les produits qui ne sont plus visible sur Android"
        },
        "LAS": {
            "serverDescription": "Lili at school subscription",
            "metadata": {},
            "availablePackages": [
                {
                    "identifier": "$rc_annual",
                    "product": {
                        "identifier": "cool.lili.sub.lasessential",
                        "price": 9.99,
                        "subscriptionPeriod": "P1Y",
                        "productCategory": "SUBSCRIPTION",
                        "priceString": "9,99 €",
                        "productType": "NON_CONSUMABLE",
                        "description": "1 an / classe (Au lieu de 19,99€)",
                        "discounts": [],
                        "title": "Tranquillité",
                        "introPrice": null,
                        "currencyCode": "EUR"
                    },
                    "packageType": "ANNUAL",
                    "offeringIdentifier": "LAS"
                },
                {
                    "offeringIdentifier": "LAS",
                    "identifier": "AnnualPremium",
                    "product": {
                        "currencyCode": "EUR",
                        "description": "1 an / classe (Au lieu de 39,99€)",
                        "discounts": [],
                        "productType": "NON_CONSUMABLE",
                        "introPrice": null,
                        "title": "Sagesse",
                        "price": 19.990000000000002,
                        "productCategory": "SUBSCRIPTION",
                        "subscriptionPeriod": "P1Y",
                        "priceString": "19,99 €",
                        "identifier": "cool.lili.sub.laspremium"
                    },
                    "packageType": "CUSTOM"
                }
            ],
            "identifier": "LAS",
            "annual": {
                "product": {
                    "price": 9.99,
                    "productCategory": "SUBSCRIPTION",
                    "subscriptionPeriod": "P1Y",
                    "discounts": [],
                    "productType": "NON_CONSUMABLE",
                    "description": "1 an / classe (Au lieu de 19,99€)",
                    "priceString": "9,99 €",
                    "currencyCode": "EUR",
                    "introPrice": null,
                    "identifier": "cool.lili.sub.lasessential",
                    "title": "Tranquillité"
                },
                "offeringIdentifier": "LAS",
                "packageType": "ANNUAL",
                "identifier": "$rc_annual"
            }
        }
    },
    "current": {
        "annual": {
            "product": {
                "identifier": "cool.lili.sub.sevendaysoneyear",
                "priceString": "35,99 €",
                "introPrice": {
                    "price": 0,
                    "periodUnit": "WEEK",
                    "cycles": 1,
                    "periodNumberOfUnits": 1,
                    "period": "P1W",
                    "priceString": "0,00 €"
                },
                "subscriptionPeriod": "P1Y",
                "productCategory": "SUBSCRIPTION",
                "price": 35.989999999999995,
                "productType": "NON_CONSUMABLE",
                "discounts": [],
                "description": "7 jours gratuits puis 35,99€/an",
                "currencyCode": "EUR",
                "title": "Annuel"
            },
            "offeringIdentifier": "Default",
            "identifier": "$rc_annual",
            "packageType": "ANNUAL"
        },
        "metadata": {},
        "monthly": {
            "identifier": "$rc_monthly",
            "product": {
                "introPrice": {
                    "price": 0,
                    "periodUnit": "WEEK",
                    "periodNumberOfUnits": 1,
                    "priceString": "0,00 €",
                    "period": "P1W",
                    "cycles": 1
                },
                "identifier": "cool.lili.sub.sevendaysonemonth",
                "price": 4.99,
                "productCategory": "SUBSCRIPTION",
                "subscriptionPeriod": "P1M",
                "priceString": "4,99 €",
                "productType": "NON_CONSUMABLE",
                "discounts": [],
                "description": "7 jours gratuits puis 4,99€/mois",
                "currencyCode": "EUR",
                "title": "Mensuel"
            },
            "offeringIdentifier": "Default",
            "packageType": "MONTHLY"
        },
        "availablePackages": [
            {
                "product": {
                    "currencyCode": "EUR",
                    "title": "Mensuel",
                    "identifier": "cool.lili.sub.sevendaysonemonth",
                    "description": "7 jours gratuits puis 4,99€/mois",
                    "discounts": [],
                    "productType": "NON_CONSUMABLE",
                    "subscriptionPeriod": "P1M",
                    "price": 4.99,
                    "productCategory": "SUBSCRIPTION",
                    "introPrice": {
                        "price": 0,
                        "periodUnit": "WEEK",
                        "periodNumberOfUnits": 1,
                        "priceString": "0,00 €",
                        "period": "P1W",
                        "cycles": 1
                    },
                    "priceString": "4,99 €"
                },
                "offeringIdentifier": "Default",
                "packageType": "MONTHLY",
                "identifier": "$rc_monthly"
            },
            {
                "identifier": "$rc_annual",
                "packageType": "ANNUAL",
                "product": {
                    "currencyCode": "EUR",
                    "priceString": "35,99 €",
                    "identifier": "cool.lili.sub.sevendaysoneyear",
                    "introPrice": {
                        "price": 0,
                        "periodUnit": "WEEK",
                        "periodNumberOfUnits": 1,
                        "period": "P1W",
                        "priceString": "0,00 €",
                        "cycles": 1
                    },
                    "title": "Annuel",
                    "description": "7 jours gratuits puis 35,99€/an",
                    "discounts": [],
                    "productType": "NON_CONSUMABLE",
                    "productCategory": "SUBSCRIPTION",
                    "subscriptionPeriod": "P1Y",
                    "price": 35.989999999999995
                },
                "offeringIdentifier": "Default"
            },
            {
                "product": {
                    "identifier": "cool.lili.gift.onemonth",
                    "priceString": "3,99 €",
                    "subscriptionPeriod": null,
                    "price": 3.9899999999999998,
                    "productCategory": "NON_SUBSCRIPTION",
                    "introPrice": null,
                    "title": "CADEAU 1 MOIS",
                    "currencyCode": "EUR",
                    "productType": "NON_CONSUMABLE",
                    "description": "LILI en cadeau 1 mois à l'un de vos proches",
                    "discounts": []
                },
                "offeringIdentifier": "Default",
                "packageType": "CUSTOM",
                "identifier": "GiftOneMonth"
            },
            {
                "packageType": "CUSTOM",
                "product": {
                    "identifier": "cool.lili.gift.threemonths",
                    "currencyCode": "EUR",
                    "title": "CADEAU 3 MOIS",
                    "priceString": "12,99 €",
                    "description": "LILI en cadeau 3 mois à l'un de vos proches",
                    "discounts": [],
                    "productType": "NON_CONSUMABLE",
                    "price": 12.99,
                    "subscriptionPeriod": null,
                    "productCategory": "NON_SUBSCRIPTION",
                    "introPrice": null
                },
                "offeringIdentifier": "Default",
                "identifier": "GiftThreeMonths"
            },
            {
                "offeringIdentifier": "Default",
                "identifier": "GiftOneYear",
                "product": {
                    "priceString": "34,99 €",
                    "introPrice": null,
                    "currencyCode": "EUR",
                    "identifier": "cool.lili.gift.oneyear",
                    "subscriptionPeriod": null,
                    "price": 34.989999999999995,
                    "productCategory": "NON_SUBSCRIPTION",
                    "description": "LILI en cadeau 1 mois à l'un de vos proches",
                    "discounts": [],
                    "productType": "NON_CONSUMABLE",
                    "title": "Cadeau 1 An"
                },
                "packageType": "CUSTOM"
            }
        ],
        "identifier": "Default",
        "serverDescription": "All purchases available in the app"
    }
};