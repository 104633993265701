import React, { useState, CSSProperties } from 'react';
import { View, TextInput } from 'react-native';

import AppTheme from '../../../shared/utils/Theme';

import AdminGiftTreatment from "../../../shared/components/Admin/AdminGiftTreatment";
import Main from '../Main/Main';
import MainOptions from '../../../shared/models/MainOptions';
import DynamicBackground from '../../../shared/components/DynamicBackground/DynamicBackground';
import Button from '../../../shared/designSystem/Button';

const AdminGifts = (props: any) => {

    const [jsonData, setJsonData] = useState("");

    const appTheme: AppTheme = new AppTheme();

    const onCreationCompleted = (data) => {
        //setReportingData(data);
    }

    const mainOptions: MainOptions = new MainOptions({
        key:"super_admin",
        componentId:props.componentId,
        showNavBar: false,
        showMenu: false,
        canBeHidden: false,
        safeArea: false,
        canGoBack: true,
        backgroundColor: appTheme.homeBackgroundColor,
        navigationColor: appTheme.homeBackgroundColor,
        canBeDismissed: false
    });

    return (
        <Main mainOptions={mainOptions}>
            <DynamicBackground isTeacher={false} />
            <View style={{ width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight(), alignItems: "center", marginTop:appTheme.pixelPerfect(40) }}>
            <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), alignItems: "center" }}>
                <TextInput value={jsonData} onChangeText={(text) => setJsonData(text)} placeholder="Copier le JSON ici..." multiline={true} style={{width:appTheme.getBlockWidth(), height:appTheme.pixelPerfect(200), backgroundColor:"#fff", borderRadius:appTheme.pixelPerfect(10), padding:appTheme.pixelPerfect(10)}} />
                <AdminGiftTreatment data={jsonData} onCreationCompleted={onCreationCompleted} />
            </View>
        </View>
        </Main>
    )
}

export default AdminGifts;