import React, { SVGProps } from "react";

import {
    Platform,
    TouchableOpacity, 
    TouchableOpacityProps,
    ViewStyle
} from "react-native";

import AppTheme from "../../utils/Theme";
import SVGView from "../../utils/SvgView";
import Style from "../../utils/Style";

interface SVGIconButtonProps extends TouchableOpacityProps {
    icon: any,
    color?: string,
    style?: ViewStyle,
    backgroundColor?: string,
    buttonSize?: number
}

const SVGIconButton = (props:SVGIconButtonProps) => {

    const appTheme: AppTheme = new AppTheme();
    const { icon, color = appTheme.navigationColor, backgroundColor = appTheme.white, buttonSize = Platform.OS === "web" ? appTheme.pixelPerfect(50) : appTheme.pixelPerfect(30), style, ...restProps } = props;
    
    const buttonStyle: ViewStyle = {
        backgroundColor, 
        width: buttonSize, 
        height: buttonSize, 
        borderRadius: buttonSize/2, 
        justifyContent: "center", 
        alignItems: "center"
    }

    return <TouchableOpacity style={[Style.shadowed, buttonStyle, style]} {...restProps}>
        <SVGView Component={icon} size={appTheme.pixelPerfect(12)} color={color} />
    </TouchableOpacity>
}

export default SVGIconButton;