import React, { useEffect, useRef, useState } from "react";

import {
    Image,
    Linking,
    Platform,
    ScrollView,
    Text,
    Touchable,
    TouchableOpacity,
    View
} from "react-native";
import AppTheme from "../../../utils/Theme";
import Images from "../../../../specific/utils/Images";
import TextInput from "../../../designSystem/TextInput/RoundCornerTextInput";
import Divider from "../../../designSystem/Divider/Divider";
import RoundButton from "../../../designSystem/Buttons/RoundButton";
import LinkButton from "../../../designSystem/Buttons/LinkButton";
import i18n from "../../../utils/Localization/Localization";
import { validateEmail } from "../../../utils/StringUtils";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "../../../services/Auth";
import { relaunchApp } from "../../../../specific/utils/Navigation/Navigation";
import ComponentProps from "../../../../specific/utils/Interfaces/ComponentProps";
import { NavigateFunction, useNavigate } from "react-router-dom";
import RadioButton from "../../../designSystem/RadioButton";
import LandingStyle from "../../../styles/auth/LandingStyle";
import TeacherSignUpStyle from "../../../styles/components/TeacherSignUpStyle";
import * as Navigation from "../../../../specific/utils/Navigation/Navigation";
import { ScreenCookiesPolicy } from "../../../utils/Constants";
import Alert from "../../Notification/Alert";
import { setUserProperty } from "../../../services/Tracking/Analytics";

interface ParentSignUpProps extends ComponentProps {
    onSignUpConfirmed: () => void,
    onCancelRequest: () => void,
    userEmail?: string
}

const ParentSignUp = (props: ParentSignUpProps) => {

    const appTheme: AppTheme = new AppTheme();
    const landingStyle: LandingStyle = new LandingStyle();
    const teacherSignUpStyle: TeacherSignUpStyle = new TeacherSignUpStyle();
    const images: Images = new Images();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState(undefined);

    const alertRef = useRef<Alert>(null);
    const signUpPagerRef = useRef<ScrollView>(null);

    const { componentId = "", userEmail = "", onSignUpConfirmed, onCancelRequest } = props;
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [newsletter, setNewsletter] = useState<boolean>(false);
    const [optInCoachNewsLetter, setOptInCoachNewsLetter] = useState<boolean>(false);
    const [step, setStep] = useState(0);

    const scrollViewWidth = appTheme.getBlockWidth();

    let navigate: NavigateFunction | string = "";
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    useEffect(() => {
        if (props.userEmail !== undefined) {
            setEmail(props.userEmail);
        }
    }, [props]);

    useEffect(() => {
        signUpPagerRef.current?.scrollTo({ x: scrollViewWidth * step, y: 0, animated: true });
    }, [step]);

    const registerUser = async () => {
        try {
            const signUpResponse = await createUserWithEmailAndPassword({ context: "family", email, password, options: { optInNewsletter: newsletter, optInCoachNewsLetter: optInCoachNewsLetter } });
            setUserProperty({key:"rare_emails_optin", value:newsletter.toString(), once:true});
            setUserProperty({key:"optin_brochure", value:optInCoachNewsLetter.toString(), once:true});
            setIsLoading(false);
            signUpPagerRef.current?.scrollTo({ x: scrollViewWidth, y: 0, animated: true });
            onSignUpConfirmed();
        } catch (error) {
            setIsLoading(false);
            let title: string = "Une erreur est survenue";
            let body: string = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
            if (error.toString().indexOf("auth/email-already-in-use") !== -1) {
                title = "Utilisateur existant";
                body = "Votre adresse e-mail est déjà reliée à un compte de LILI.\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\" dans la page \"J'AI DÉJÀ UN COMPTE\"";
            } else if (error.toString().indexOf("auth/invalid-email") !== -1) {
                title = "Adresse e-mail erronée";
                body = "Veuillez vérifier votre adresse e-mail s'il vous plaît.";
            } else if (error.toString().indexOf("auth/weak-password") !== -1) {
                title = "Mot de passe trop faible";
                body = "Veuillez saisir au moins 6 caractères pour votre mot de passe.";
            } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                title = "Erreur de connexion 📡";
                body = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît."
            }
            alertRef.current?.showAlert({
                title,
                body
            });
        }
    }

    useEffect(() => {
    }, [errors]);

    const onFirstStepValidation = () => {
        setIsLoading(true);
        let signInErrors = {
            email: "",
            password: ""
        };
        if (email.length === 0) {
            signInErrors.email = "Votre e-mail ne peut être vide";
        } else {
            const isEmailValid = validateEmail(email);
            if (isEmailValid === false) {
                signInErrors.email = "La saisie de votre e-mail est incorrect";
            } else {
                signInErrors.email = "";
            }
        }
        if (password.length === 0) {
            signInErrors.password = "Votre mot de passe ne peut être vide";
        } else if (password.length < 6) {
            signInErrors.password = "Votre mot de passe doit contenir 6 caractères minimum";
        } else {
            signInErrors.password = "";
        }
        if ((signInErrors.email.length > 0) || (signInErrors.password.length > 0)) {
            setErrors(signInErrors);
            setIsLoading(false);
        } else {
            //Sign Up
            registerUser();
        }
    }

    const onSecondStepValidation = () => {
        Navigation.relaunchApp({
            componentId,
            navigate
        });
    }

    const openGeneralConditions = () => {
        if (Platform.OS === "web") {
            let uri = "https://www.lili.cool/fr/mentions-legales/";
            Linking.openURL(uri);
        } else {
            Navigation.showModalInView({
                componentId,
                name: ScreenCookiesPolicy,
                passProps: {
                    uri: "https://www.lili.cool/fr/mentions-legales/"
                },
                options: {
                    modalPresentationStyle: 'overCurrentContext',
                    layout: {
                        componentBackgroundColor: 'transparent'
                    }
                }
            });
        }
    }

    const parentSecondStep = () => {
        return (<View style={{ marginBottom: appTheme.pixelPerfect(10), width: scrollViewWidth, alignItems: "center" }}>
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont, color: appTheme.homeColor, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", textTransform: "uppercase" }}>
                {"Bienvenue parmi les\nsuper-parents !"}
            </Text>
            <Divider />
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center" }}>
                {"Découvrez"}
            </Text>
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", textTransform: "uppercase" }}>
                {"nos conseils pour vous lancer"}
            </Text>
            <View style={{ height: appTheme.pixelPerfect(180) }}>
                <Image source={images.illParentSignUpFinal} style={{ height: appTheme.pixelPerfect(180), width: appTheme.pixelPerfect(230) }} resizeMode="contain" />
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ flex: 1 }}>
                    <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center" }}>
                        {"Rendez-vous dans"}
                    </Text>
                    <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", textTransform: "uppercase" }}>
                        {"Le coin des\nSuper-parents"}
                    </Text>
                </View>
                <View style={{ width: appTheme.pixelPerfect(120), height: appTheme.pixelPerfect(145) }}>
                    <Image source={images.illParentSignUpFinalBis} style={{ height: appTheme.pixelPerfect(145), width: appTheme.pixelPerfect(100) }} resizeMode="contain" />
                </View>
            </View>
            <Divider size={20} />
            <RoundButton textColor={appTheme.darkBlue} style={{ backgroundColor: appTheme.homeColor }} isLoading={isLoading} onPress={onSecondStepValidation} title={"Découvrir Lili"} />
        </View>)
    }

    const parentFirstStep = () => {
        return (<View style={{ marginBottom: appTheme.pixelPerfect(10), paddingTop: appTheme.pixelPerfect(10), width: scrollViewWidth, alignItems: "center" }}>
            <TextInput
                key="email"
                value={email}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.email !== undefined)) ? errors.email : ""}
                onChangeText={(text) => setEmail(text)}
                type="email"
                placeholder={i18n.t("authentication.signUp.form.emailPlaceholder")} />
            <TextInput
                key="password"
                value={password}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.password !== undefined)) ? errors.password : ""}
                onChangeText={(text) => setPassword(text)}
                type="password"
                placeholder={i18n.t("authentication.signUp.form.passwordPlaceholder")} />
            <Divider />
            <RadioButton value={optInCoachNewsLetter} style={{ width: appTheme.getBlockWidth() * 0.9 }} radioButtonStyle={{ marginTop: appTheme.pixelPerfect(10) }} onPress={() => setOptInCoachNewsLetter(!optInCoachNewsLetter)}>
                <View style={{ flex: 1 }}>
                    <View style={{ alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "row", marginStart: appTheme.pixelPerfect(-8) }}>
                        <Image source={images.icCoachManual} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(56) }} resizeMode="contain" />
                        <Text style={{ flex: 1, fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10) }}>
                            {i18n.t("authentication.signUp.form.radioButtons.coachManual.firstPart")}
                        </Text>
                    </View>
                    <Text style={{ flex: 1, fontFamily: appTheme.secondaryFont, color: appTheme.textColor, fontSize: appTheme.pixelPerfectForFont(10) }}>
                        {i18n.t("authentication.signUp.form.radioButtons.coachManual.secondPart")}
                    </Text>
                </View>
            </RadioButton>
            <Divider />
            <RadioButton
                centerButton={true}
                value={newsletter}
                onPress={() => setNewsletter(!newsletter)}
                style={{ width: appTheme.getBlockWidth() * 0.9 }}>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue }}>
                    {i18n.t("authentication.signUp.form.radioButtons.newsletter")}
                </Text>
            </RadioButton>
            <Divider size={30} />
            <RoundButton textColor={appTheme.darkBlue} style={{ backgroundColor: appTheme.homeColor }} isLoading={isLoading} onPress={onFirstStepValidation} title={i18n.t("authentication.signIn.buttons.signIn")} />
            <Divider />
            <TouchableOpacity onPress={openGeneralConditions} style={{ marginTop: appTheme.pixelPerfect(20) }}>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "center", color: appTheme.textColor }}>
                    {i18n.t("authentication.signUp.buttons.cgu.firstPart")}
                </Text>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "center", textDecorationLine: "underline", color: appTheme.textColor }}>
                    {i18n.t("authentication.signUp.buttons.cgu.secondPart")}
                </Text>
            </TouchableOpacity>
            <Divider size={20} />
            <LinkButton onPress={onCancelRequest} title="Annuler" />
        </View>)
    }

    return (<View style={{ justifyContent: "center", alignItems: "center", width: appTheme.getBlockWidth() }}>
        <Divider />
        <ScrollView scrollEnabled={false} ref={signUpPagerRef} horizontal={true} style={landingStyle.contentContainer}>
            {parentFirstStep()}
            {parentSecondStep()}
        </ScrollView>
        <Alert ref={alertRef} />
    </View>)

}

export default ParentSignUp;