import React from "react";
import { useState } from "react";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet
} from 'react-native';

import AppTheme from "../../../utils/Theme";
import Image from "../../../designSystem/OTBImage";

// Assets
import Images from "../../../../specific/utils/Images";

// SVG
import i18n from "../../../utils/Localization/Localization";

interface Props {
    name: string;
}

const OnboardingTeacher = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const { page } = props;

    const [viewDidAppear, setViewDidAppear] = useState(false);

    const onViewDidAppear = () => {
        setViewDidAppear(true);
    }

    let paddingTop = Platform.OS === "web" ? appTheme.pixelPerfect(60) : 0;
    paddingTop = paddingTop + appTheme.topInsets + appTheme.pixelPerfect(10);

    let backgroundColor = "transparent";

    let blockWidth = appTheme.getBlockWidth();

    let fontSizefactor = (appTheme.getFullAppWidth() - appTheme.pixelPerfect(40)) / blockWidth;
    if (fontSizefactor < 1) {
        fontSizefactor = 1;
    } else if (fontSizefactor > 1.5) {
        fontSizefactor = 1.5;
    }

    const getTeacherTestimonyView = () => {
        const teacherTestimony = [
            {
                title: i18n.t("onboarding.teacher.pageFour.sylvieDEsclaibes.title"),
                subtitle: i18n.t("onboarding.teacher.pageFour.sylvieDEsclaibes.subtitle")
            },
            {
                title: i18n.t("onboarding.teacher.pageFour.nadine.title"),
                subtitle: i18n.t("onboarding.teacher.pageFour.nadine.subtitle")
            },
            {
                title: i18n.t("onboarding.teacher.pageFour.claire.title"),
                subtitle: i18n.t("onboarding.teacher.pageFour.claire.subtitle")
            },
            {
                title: i18n.t("onboarding.teacher.pageFour.severine.title"),
                subtitle: i18n.t("onboarding.teacher.pageFour.severine.subtitle")
            },
            {
                title: i18n.t("onboarding.teacher.pageFour.armelle.title"),
                subtitle: i18n.t("onboarding.teacher.pageFour.armelle.subtitle")
            }
        ]
        let teacherTestimonyListView = [];
        let maxTesttimony = Platform.OS === "web" ? 3 : 3;
        for (const aTeacherTestimonyIndex in teacherTestimony) {
            if (Object.prototype.hasOwnProperty.call(teacherTestimony, aTeacherTestimonyIndex)) {
                const aTeacherTestimony = teacherTestimony[aTeacherTestimonyIndex];
                let subtitle = <View />;
                if (aTeacherTestimony.subtitle !== undefined) {
                    subtitle = <Text style={[styles.subtitle, { fontSize:appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: "#bba9fc" }]}>{aTeacherTestimony.subtitle}</Text>
                }
                teacherTestimonyListView.push(
                    <View style={{ width: blockWidth, justifyContent: "center", alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(10), paddingBottom:appTheme.pixelPerfect(5) }}>
                        <Text style={[styles.title, { fontSize:appTheme.pixelPerfectForFont(11), fontWeight:"700", fontFamily: appTheme.primaryFont, marginTop: appTheme.pixelPerfect(10), color: appTheme.titleColor }]}>{aTeacherTestimony.title}</Text>
                        {subtitle}
                    </View>
                )
                if (teacherTestimonyListView.length === maxTesttimony) {
                    break;
                }
            }
        }
        const yellowGreenLeftPistion = (blockWidth-appTheme.pixelPerfect(50))/2;
        return <View style={{ backgroundColor: appTheme.white, paddingVertical: appTheme.pixelPerfect(10), marginTop: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(20), paddingBottom:appTheme.pixelPerfect(40) }}>
            {teacherTestimonyListView}
            <Image source={images.icBlablaGreenYellow} style={{ position:"absolute", bottom:appTheme.pixelPerfect(-25), left:yellowGreenLeftPistion, width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(50) }} resizeMode="contain" />
            <Image source={images.icBlablaPurple} style={{ position:"absolute", bottom:appTheme.pixelPerfect(-10), right:appTheme.pixelPerfect(-5), width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30) }} resizeMode="contain" />
        </View>
    }

    const getRessourcesViewForPageThree = (mainViewHeight:number) => {
        const ressourcesList = [
            {
                image: images.icClassMaterial,
                title: i18n.t("onboarding.teacher.pageThree.classMaterial")
            },
            {
                image: images.icSchoolMaterial,
                title: i18n.t("onboarding.teacher.pageThree.schoolMaterial")
            },
            {
                image: images.icLinkSheets,
                title: i18n.t("onboarding.teacher.pageThree.linkSheets")
            },
            {
                image: images.icFormationModules,
                title: i18n.t("onboarding.teacher.pageThree.formationModules")
            },
            {
                image: images.icTeacherFacebook,
                title: i18n.t("onboarding.teacher.pageThree.teacherFacebook.title"),
                subtitle: i18n.t("onboarding.teacher.pageThree.teacherFacebook.subtitle")
            }
        ]
        let ressourcesListView = [];
        const verticaleSpaceToRemove = Platform.OS === "web" ? appTheme.pixelPerfect(200) : appTheme.pixelPerfect(160);
        const cellSize = (mainViewHeight - verticaleSpaceToRemove)/ressourcesList.length;
        for (const aRessourcesListIndex in ressourcesList) {
            if (Object.prototype.hasOwnProperty.call(ressourcesList, aRessourcesListIndex)) {
                const aRessource = ressourcesList[aRessourcesListIndex];
                let subtitle = <View />;
                if (aRessource.subtitle !== undefined) {
                    subtitle = <Text style={[styles.subtitle, { fontSize:appTheme.pixelPerfectForFont(11), textAlign: "left", width: appTheme.pixelPerfect(180), paddingStart: appTheme.pixelPerfect(20), fontFamily: appTheme.primaryFont, color: appTheme.white }]}>{aRessource.subtitle}</Text>
                }
                ressourcesListView.push(
                    <View style={{ flexDirection: "row", width: blockWidth, height:cellSize, justifyContent: "flex-start", alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(10) }}>
                        <Image source={aRessource.image} style={{ width: cellSize*0.8, height: cellSize*0.8 }} resizeMode="contain" />
                        <View style={{ alignItems: "flex-start", flex: 1 }}>
                            <Text style={[styles.title, { fontSize:appTheme.pixelPerfectForFont(11), paddingStart: appTheme.pixelPerfect(20), fontFamily: appTheme.primaryFont, marginTop: appTheme.pixelPerfect(10), color: appTheme.white, textAlign: "left", paddingEnd: appTheme.pixelPerfect(10) }]}>{aRessource.title.toLocaleUpperCase()}</Text>
                            {subtitle}
                        </View>
                    </View>
                )
            }
        }
        return <View style={{ backgroundColor: appTheme.schoolColor, width: blockWidth, paddingVertical: appTheme.pixelPerfect(10), marginTop: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(20) }}>
            {ressourcesListView}
        </View>
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <ActivityIndicator color={appTheme.darkBlue} />;
        }
        let onboardingPageView = [];
        if (page === "teacherPageOne") {
            const mainImageHeight = appTheme.getFullScreenHeight() / 3;
            onboardingPageView.push(<Image source={images.icLiliAtSchoolCircle} style={{ width: appTheme.pixelPerfect(90), height: appTheme.pixelPerfect(90) }} resizeMode="contain" />);
            onboardingPageView.push(<View style={{ justifyContent: "center", alignItems: "center", paddingBottom: appTheme.pixelPerfect(125) }}>
                <View style={{ padding: appTheme.pixelPerfect(4), borderRadius: appTheme.pixelPerfect(10) }}>
                    <Text style={[styles.title, { fontSize:appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, marginTop: appTheme.pixelPerfect(20), color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageOne.title").toLocaleUpperCase()}</Text>
                    <Text style={[styles.subtitle, { fontSize:appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, marginTop: appTheme.pixelPerfect(10), color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageOne.subtitle")}</Text>
                </View>
                <View style={{ marginTop: appTheme.pixelPerfect(10) }}>
                    <Image source={images.illOnboardingTeacherPageOne} style={{ width: appTheme.getFullAppWidth(), height: mainImageHeight }} resizeMode="contain" />
                </View>
                <View style={{ paddingHorizontal: appTheme.pixelPerfect(10), paddingVertical: appTheme.pixelPerfect(4), borderRadius: appTheme.pixelPerfect(10) }}>
                    <Text style={[styles.legend, { fontSize:appTheme.pixelPerfectForFont(11), fontFamily: appTheme.secondaryFont, marginTop: appTheme.pixelPerfect(10), color: appTheme.schoolColor,  }]}>{i18n.t("onboarding.teacher.pageOne.legend")}</Text>
                </View>
            </View>);
        } else if (page === "teacherPageTwo") {
            const headerSize = Platform.OS === "web" ? appTheme.pixelPerfect(80) : appTheme.pixelPerfect(50);
            const mainImageWidth = blockWidth;
            const mainImageHeight = appTheme.getFullAppHeight() - headerSize - appTheme.pixelPerfect(80);
            onboardingPageView.push(<Image source={images.icSchoolNotebook} style={{ width: headerSize, height: headerSize }} resizeMode="contain" />);
            onboardingPageView.push(<View style={{ height: appTheme.pixelPerfect(80), justifyContent: "center", alignItems: "center" }}>
                <View style={{ padding: appTheme.pixelPerfect(4),  borderRadius: appTheme.pixelPerfect(10) }}>
                    <Text style={[styles.title, { fontSize:appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageTwo.title").toLocaleUpperCase()}</Text>
                </View>
            </View>);
            onboardingPageView.push(<View style={{ position: "absolute", bottom: 0, justifyContent: "flex-end", marginTop: appTheme.pixelPerfect(20), alignItems: "center", width: mainImageWidth }}>
                <Image source={images.illOnboardingTeacherPageTwo} style={{ width: mainImageWidth, height: mainImageHeight }} resizeMode="contain" />
            </View>);
        } else if (page === "teacherPageThree") {
            const headerHeight = Platform.OS === "web" ? appTheme.pixelPerfect(80) : appTheme.pixelPerfect(50);
            const headerWidth = headerHeight * 6 / 5;
            const textMarginTop = appTheme.pixelPerfect(20);
            const mainViewHeight = appTheme.getFullAppHeight() - headerHeight - textMarginTop;
            onboardingPageView.push(<Image source={images.icSchoolBag} style={{ width: headerWidth, height: headerHeight }} resizeMode="contain" />);
            onboardingPageView.push(<View style={{ padding: appTheme.pixelPerfect(4), marginTop: textMarginTop,  borderRadius: appTheme.pixelPerfect(10) }}>
                <Text style={[styles.title, { fontSize:appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageThree.title").toLocaleUpperCase()}</Text>
            </View>);
            onboardingPageView.push(<View style={{ width: appTheme.getFullAppWidth(), justifyContent: "center", alignItems: "center", paddingBottom: appTheme.pixelPerfect(100) }}>
                {getRessourcesViewForPageThree(mainViewHeight)}
            </View>);
        } else if (page === "teacherPageFour") {
            onboardingPageView.push(<Image source={images.icBlablaPurpleYellow} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(50) }} resizeMode="contain" />);
            onboardingPageView.push(<Text style={[styles.title, { fontSize:appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, marginTop: appTheme.pixelPerfect(20), color: appTheme.schoolColor }]}>{i18n.t("onboarding.teacher.pageFour.title").toLocaleUpperCase()}</Text>);
            onboardingPageView.push(getTeacherTestimonyView());
            onboardingPageView.push(<View style={{ flex: 1, width: blockWidth }}>
                <Image source={images.illTeacherCat} style={{ position: "absolute", bottom: 0, left: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(120), height: appTheme.pixelPerfect(140) }} resizeMode="contain" />
            </View>);
        }
        return onboardingPageView;
    }

    return (
        <View onLayout={onViewDidAppear} style={[styles.main, { width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), paddingHorizontal: appTheme.pixelPerfect(10), paddingTop, backgroundColor }]}>
            {getContent()}
        </View>
    )
}

export default OnboardingTeacher;

const styles = StyleSheet.create({
    main: {
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        textAlign: "center",
        fontWeight: "600",
        letterSpacing: 0.8
    },
    subtitle: {
        textAlign: "center",
        fontWeight: "600",
        letterSpacing: 0.8
    },
    legend: {
        textAlign: "center",
        letterSpacing: 0.8
    },
    text: {
        textAlign: "center",
    }
});