import React, { useEffect, useRef, useState } from "react";

import {
    Text,
    View,
    TouchableOpacity,
    TextStyle,
    Modal,
    FlatList,
    SafeAreaView
} from "react-native";
import Images from "../../../../specific/utils/Images";
import Button from "../../../designSystem/Button";
import TextInput from "../../../designSystem/TextInput/TextInput";
import i18n from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";
import SignUpStyle from "../../../../specific/style/Authentication/SignUpStyle";
import Alert from "../../Notification/Alert";
import SVGView from "../../../utils/SvgView";
import { default as IcSearch } from "../../../assets/svg/icons/solid/search.svg";
import { default as IcAngleRight } from "../../../assets/svg/icons/solid/angle-right.svg";
import { addDataToUser, getSchoolId } from "../../../services/Database";

interface SignUpFormProps {
    type: "lah" | "las",
    width?: number,
    onSuccess: (schooldata) => void,
    openGeneralConditions: () => void
}

const TeacherSchoolForm = (props: SignUpFormProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const style: SignUpStyle = new SignUpStyle();

    const { type, width = appTheme.getBlockWidth() } = props;
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showSchoolsList, setShowSchoolsList] = useState<boolean>(false);
    const [schoolsList, setSchoolsList] = useState([]);
    const [schoolName, setSchoolName] = useState("");
    const [schoolAddress, setSchoolAddress] = useState("");
    const [schoolZipCode, setSchoolZipCode] = useState("");
    const [schoolCity, setSchoolCity] = useState("");
    const [schoolCountry, setSchoolCountry] = useState("");
    const [step, setStep] = useState<number>(0);

    const alertRef = useRef<Alert>(null);

    const onSchoolSearchRequest = async () => {
        setIsLoading(true);
        if (schoolsList.length > 0) {
            // On a déjà récupéré la liste
            setShowSchoolsList(true);
            setIsLoading(false);
        } else {
            const url = "https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-annuaire-education&q=" + searchTerm + "&rows=50&facet=identifiant_de_l_etablissement&facet=nom_etablissement&facet=type_etablissement&facet=statut_public_prive&facet=code_postal&facet=code_commune&facet=nom_commune&facet=code_departement&facet=code_academie&facet=code_region&facet=ecole_maternelle&facet=ecole_elementaire&facet=voie_generale&facet=voie_technologique&facet=voie_professionnelle&facet=restauration&facet=hebergement&facet=ulis&facet=apprentissage&facet=segpa&facet=section_arts&facet=section_cinema&facet=section_theatre&facet=section_sport&facet=section_internationale&facet=section_europeenne&facet=lycee_agricole&facet=lycee_militaire&facet=lycee_des_metiers&facet=post_bac&facet=appartenance_education_prioritaire&facet=greta&facet=type_contrat_prive&facet=libelle_departement&facet=libelle_academie&facet=libelle_region&facet=nom_circonscription&facet=precision_localisation&facet=etat&facet=ministere_tutelle&facet=multi_uai&facet=rpi_concentre&facet=rpi_disperse&facet=code_nature&facet=libelle_nature&facet=code_type_contrat_prive&facet=pial";
            try {
                const listResult = await fetch(url, {
                    method: "GET",
                    headers: {
                        "Accept": "application/json"
                    }
                });
                let jsonResults = await listResult.json();
                if (jsonResults.records !== undefined) {
                    jsonResults = jsonResults.records;
                } else {
                    jsonResults = [];
                }
                setSchoolsList(jsonResults);
            } catch (error) {
                //throw error;
            }
        }
    }

    useEffect(() => {
        if (schoolsList.length > 0) {
            setShowSchoolsList(true);
            setIsLoading(false);
        }
    }, [schoolsList]);

    const onNextStepRequest = () => {
        setStep((step) => step + 1);
    }

    const onSchoolRecordRequest = async () => {
        let errrorTitle = "";
        let errorBody = "";
        if ((schoolAddress.length > 0) && (schoolCity.length > 0) && (schoolCountry.length > 0) && (schoolName.length > 0) && (schoolZipCode.length > 0)) {
            const schoolId = await getSchoolId({
                name: schoolName,
                address: schoolAddress,
                zipcode: schoolZipCode,
                city: schoolCity,
                country: schoolCountry
            });
            setIsLoading(false);
            props.onSuccess(schoolId);
        } else {
            errrorTitle = "Veuillez vérifier les informations suivantes sur votre établissement :";
            if (schoolName.length === 0) {
                errorBody = "‣ son nom"
            }
            if (schoolAddress.length === 0) {
                if (errorBody.length > 0) {
                    errorBody = errorBody + '\n';
                }
                errorBody = errorBody + "‣ son adresse"
            }
            if (schoolZipCode.length === 0) {
                if (errorBody.length > 0) {
                    errorBody = errorBody + '\n';
                }
                errorBody = errorBody + "‣ son code postal"
            }
            if (schoolCity.length === 0) {
                if (errorBody.length > 0) {
                    errorBody = errorBody + '\n';
                }
                errorBody = errorBody + "‣ sa ville"
            }
            if (schoolCountry.length === 0) {
                if (errorBody.length > 0) {
                    errorBody = errorBody + '\n';
                }
                errorBody = errorBody + "‣ son pays"
            }
            setIsLoading(false);
            alertRef.current?.showAlert({
                title: errrorTitle,
                body: errorBody
            });
        }
    }

    const selectSchool = (item) => {
        setSchoolName(item.fields.nom_etablissement);
        setSchoolAddress(item.fields.adresse_1);
        setSchoolZipCode(item.fields.code_postal);
        setSchoolCity(item.fields.nom_commune);
        setSchoolCountry("France");
        setStep((step) => step + 1);
        setShowSchoolsList(false);
    }

    const title: TextStyle = {
        fontFamily: appTheme.primaryBoldFont,
        textTransform: "uppercase",
        fontSize: appTheme.pixelPerfectForFont(8),
        paddingBottom: appTheme.pixelPerfect(10)
    }

    if (step === 1) {
        return (<View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1, alignItems: "center" }}>
            <Text style={[title, { textAlign: "left", color: appTheme.schoolColor, paddingTop: appTheme.pixelPerfect(10) }]}>
                {i18n.t("authentication.signUp.form.schoolFormTitle").toLocaleUpperCase()}
            </Text>
            <TextInput
                key="schoolName"
                placeholder={i18n.t("authentication.signUp.form.schoolNamePlaceholder")}
                style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                onChangeText={(text) => setSchoolName(text)}
                value={schoolName}
                keyboardType="default"
                autoCapitalize="words" />
            <TextInput
                placeholder={i18n.t("authentication.signUp.form.schoolAddressPlaceholder")}
                style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                onChangeText={(text) => setSchoolAddress(text)}
                value={schoolAddress}
                keyboardType="default"
                autoCapitalize="words" />
            <TextInput
                placeholder={i18n.t("authentication.signUp.form.schoolZipCodePlaceholder")}
                style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                onChangeText={(text) => setSchoolZipCode(text)}
                value={schoolZipCode}
                keyboardType="default"
                autoCapitalize="words" />
            <TextInput
                placeholder={i18n.t("authentication.signUp.form.schoolCityPlaceholder")}
                style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                onChangeText={(text) => setSchoolCity(text)}
                value={schoolCity}
                keyboardType="default"
                autoCapitalize="words" />
            <TextInput
                placeholder={i18n.t("authentication.signUp.form.schoolCountryPlaceholder")}
                style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                onChangeText={(text) => setSchoolCountry(text)}
                value={schoolCountry}
                keyboardType="default"
                autoCapitalize="words" />
            <Button
                isLoading={isLoading}
                disabled={isLoading}
                onPress={onSchoolRecordRequest}
                style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.schoolColor }}
                title={i18n.t("authentication.signUp.buttons.steps")} />
            <Alert ref={alertRef} />
        </View>);
    }
    return (<View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1, paddingTop: appTheme.pixelPerfect(10), alignItems: "center" }}>
        <Text style={[title, { textAlign: "center", color: appTheme.schoolColor, paddingTop: appTheme.pixelPerfect(10) }]}>
            {i18n.t("authentication.signUp.form.schoolSearchEngineTitle").toLocaleUpperCase()}
        </Text>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <TextInput
                key="schoolNameSearch"
                placeholder={i18n.t("authentication.signUp.form.schoolByZipCodePlaceholder")}
                style={{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(180), color: appTheme.darkBlue }}
                onChangeText={(text) => setSearchTerm(text)}
                value={searchTerm}
                keyboardType="default"
                autoCapitalize="words" />
            <TouchableOpacity onPress={onSchoolSearchRequest} style={{ marginLeft: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), justifyContent: "center", alignItems: "center", backgroundColor: appTheme.schoolColor, borderRadius: appTheme.pixelPerfect(20) }}>
                <SVGView Component={IcSearch} width={appTheme.pixelPerfect(16)} height={appTheme.pixelPerfect(16)} color={appTheme.white} />
            </TouchableOpacity>
        </View>
        <View style={{ alignItems: "center" }}>
            <View style={{ alignItems: "center" }}>
                <Text style={[title, { textAlign: "center", color: appTheme.schoolColor, paddingTop: appTheme.pixelPerfect(10) }]}>
                    {i18n.t("authentication.signUp.form.schoolInOtherTerritoryTitle").toLocaleUpperCase()}
                </Text>
                <Button
                    isLoading={isLoading}
                    disabled={isLoading}
                    onPress={onNextStepRequest}
                    style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.schoolColor }}
                    title={i18n.t("authentication.signUp.buttons.thisWay")} />
            </View>
        </View>
        <Modal
            transparent={true}
            animationType="slide"
            visible={showSchoolsList}>
            <SafeAreaView style={{ width: appTheme.getFullScreenWidth(), alignItems: "center" }}>
                <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), backgroundColor: appTheme.white }}>
                    <View style={{ width: appTheme.getFullAppWidth(), alignItems: "flex-end", padding: appTheme.pixelPerfect(10) }}>
                        <TouchableOpacity onPress={() => setShowSchoolsList(false)}>
                            <Text style={{ color: appTheme.darkBlue }}>Fermer</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                        <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center" }}>
                            <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <TextInput
                                    key="schoolNameSearch"
                                    placeholder={i18n.t("authentication.signUp.form.schoolByZipCodePlaceholder")}
                                    style={{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }}
                                    onChangeText={(text) => setSearchTerm(text)}
                                    value={searchTerm}
                                    keyboardType="default"
                                    autoCapitalize="words" />
                                <TouchableOpacity onPress={onSchoolSearchRequest} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), justifyContent: "center", alignItems: "center", backgroundColor: appTheme.schoolColor, borderRadius: appTheme.pixelPerfect(20) }}>
                                    <SVGView Component={IcSearch} width={appTheme.pixelPerfect(16)} height={appTheme.pixelPerfect(16)} color={appTheme.white} />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                    <View style={{ width: appTheme.getFullAppWidth(), flex: 1, backgroundColor: appTheme.white }}>
                        <Text style={{ textAlign: "center", color: appTheme.secondaryColor, fontFamily: appTheme.primaryFont, fontWeight: "700", fontSize: appTheme.pixelPerfectForFont(12), padding: appTheme.pixelPerfect(10) }}>{schoolsList.length + " résultat(s)"}</Text>

                        <FlatList
                            style={{ width: appTheme.getFullAppWidth(), flex: 1, backgroundColor: appTheme.white }}
                            data={schoolsList}
                            keyExtractor={(item, index) => index.toString()}
                            renderItem={({ item, index }) => {
                                return (
                                    <TouchableOpacity onPress={() => selectSchool(item)} style={{ width: appTheme.getFullAppWidth() - appTheme.pixelPerfect(20), paddingVertical: appTheme.pixelPerfect(10), borderBottomColor: appTheme.schoolColor, borderBottomWidth: 1, marginHorizontal: appTheme.pixelPerfect(10), flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                        <View style={{ width: appTheme.getFullAppWidth() - appTheme.pixelPerfect(40) }}>
                                            <Text style={{ color: appTheme.secondaryColor, fontFamily: appTheme.primaryFont, fontWeight: "700", fontSize: appTheme.pixelPerfectForFont(12) }}>{item.fields.nom_etablissement}</Text>
                                            <Text style={{ color: appTheme.secondaryColor }}>{item.fields.type_etablissement + " " + item.fields.statut_public_prive}</Text>
                                            <Text style={{ color: appTheme.secondaryColor }}>{item.fields.adresse_1}</Text>
                                            <Text style={{ color: appTheme.secondaryColor }}>{item.fields.code_postal + " " + item.fields.nom_commune}</Text>
                                        </View>
                                        <SVGView Component={IcAngleRight} width={appTheme.pixelPerfect(16)} height={appTheme.pixelPerfect(16)} color={appTheme.schoolColor} />
                                    </TouchableOpacity>
                                )
                            }} />
                    </View>
                    <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center", justifyContent: "center", marginBottom: appTheme.pixelPerfect(10) }}>
                        <Text style={{ textAlign: "center", color: appTheme.secondaryColor, fontFamily: appTheme.primaryFont, fontWeight: "700", fontSize: appTheme.pixelPerfectForFont(12), padding: appTheme.pixelPerfect(10) }}>{"Si vous ne trouvez pas votre école cliquez sur le bouton ci-dessous."}</Text>
                        <Button
                            onPress={onNextStepRequest}
                            title="Mon école n'est pas listée" />
                    </View>
                </View>
            </SafeAreaView>
        </Modal>
        <Alert ref={alertRef} />
    </View>)

}

export default TeacherSchoolForm;