import React from "react";

import {
    Text,
    View
} from "react-native";
import { getLanguage } from "../../../utils/Localization/Localization";
import SVGView from "../../../utils/SvgView";
import AppTheme from "../../../utils/Theme";
import { default as IcQuoteLeft } from "../../../assets/svg/icons/solid/quote-left.svg";
import { default as IcQuoteRight } from "../../../assets/svg/icons/solid/quote-right.svg";

type localizedString = {
    fr?: string
}

interface ActivityCellQuoteProps {
    quote: localizedString;
    color: string;
    width: number;
    isEnable?: boolean;
}

const ActivityCellQuote = (props: ActivityCellQuoteProps) => {
    const { quote, color, width, isEnable = false } = props;

    const appTheme: AppTheme = new AppTheme();

    let quoteView = isEnable === true ? <View style={{ marginHorizontal: appTheme.pixelPerfect(20), marginVertical: appTheme.pixelPerfect(10), borderRadius: appTheme.pixelPerfect(20), marginTop: appTheme.pixelPerfect(10), flexDirection: 'row', width: width - appTheme.pixelPerfect(40) }}>
        <View style={{ justifyContent: 'flex-start' }}>
            <SVGView Component={IcQuoteLeft} width={appTheme.pixelPerfect(24)} height={appTheme.pixelPerfect(24)} color={color} />
        </View>
        <View style={{ flex: 1 }}>
            <Text style={{
                paddingHorizontal: appTheme.pixelPerfect(40),
                paddingVertical: appTheme.pixelPerfect(10),
                textAlign: 'center',
                fontFamily: appTheme.primaryFont,
                fontSize: appTheme.pixelPerfectForFont(12),
                color: appTheme.darkBlue
            }}>
                {quote[getLanguage()]}
            </Text>
        </View>
        <View style={{ justifyContent: 'flex-end' }}>
            <SVGView Component={IcQuoteRight} width={appTheme.pixelPerfect(24)} height={appTheme.pixelPerfect(24)} color={color} />
        </View>
    </View> : <View />;

    return <View style={{ width, alignItems: "center", marginTop: appTheme.pixelPerfect(10) }}>
        {quoteView}
    </View>
}

export default ActivityCellQuote;