import React from "react";

import {
    View, ViewProps
} from "react-native";
import AppTheme from "../../utils/Theme";

interface DividerProps extends ViewProps {
    size?: number
}

const Divider = (props:DividerProps) => {

    const appTheme: AppTheme = new AppTheme();

    const { size = 10 } = props;

    return (
        <View style={{height:appTheme.pixelPerfect(size)}} />
    );
}

export default Divider;