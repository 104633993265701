import React from "react";

import {
    Dimensions,
    StyleSheet,
    Pressable,
    Text,
    ActivityIndicator,
    View
} from 'react-native';

import AppTheme, { Theme } from "../utils/Theme";
import SVGView from "../utils/SvgView";

const Button = (props: {
    disabled?: boolean,
    onLongPress?: ((event: any) => void),
    onPress?: ((event: any) => void),
    inversed?: boolean,
    isLoading?: boolean,
    link?: boolean,
    style?: any,
    title?: string,
    textStyle?: any,
    icon?: any
}) => {

    const {
        disabled = false,
        onLongPress,
        onPress,
        inversed = false,
        isLoading,
        link,
        style,
        title,
        textStyle,
        icon
    } = props;

    const appTheme: AppTheme = new AppTheme();

    const isLinkButton = ((link !== undefined) && (link === true));
    const buttonStyle = isLinkButton === false ? {
        paddingVertical: appTheme.pixelPerfect(10),
        borderRadius: appTheme.pixelPerfect(60),
        justifyContent: "center",
        alignItems: "center"
    } : {
        borderRadius: appTheme.pixelPerfect(20),
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'transparent'
    };
    let backgroundColor = isLinkButton === true ? "transparent" : ((style !== undefined) && (style.backgroundColor !== undefined)) ? style.backgroundColor : appTheme.buttonBackgroundColor;
    let pressedBackgroundColor = ((style !== undefined) && (style.pressedBackgroundColor !== undefined)) ? style.pressedBackgroundColor : backgroundColor + "33";
    let buttonTitle = title !== undefined ? title : 'OK';
    let titleColor = ((textStyle !== undefined) && (textStyle.color !== undefined)) ? textStyle.color : isLinkButton === true ? appTheme.textColor : appTheme.white;
    let titleFontSize = ((textStyle !== undefined) && (textStyle.fontSize !== undefined)) ? textStyle.fontSize : appTheme.pixelPerfectForFont(10);
    let textDecorationLine = isLinkButton === true ? "underline" : "none";
    let contextStyle = {
        backgroundColor,
        color: titleColor
    };

    const iconView = icon !== undefined ? <View style={{width:appTheme.pixelPerfect(40), alignItems:"center"}}><SVGView Component={icon} color={titleColor} size={appTheme.pixelPerfect(20)} /></View> : <View />;
    const buttonContent = ((isLoading !== undefined) && (isLoading === true)) ?
        <ActivityIndicator color={titleColor} /> :
        <View style={{flexDirection:"row", justifyContent:"flex-start", alignItems:"center"}}>
            {iconView}
            <Text style={[styles.title, { flex:1, color: titleColor, textDecorationLine, fontFamily: appTheme.primaryFont, fontSize: titleFontSize, fontWeight: "500", paddingEnd: appTheme.pixelPerfect(10), textAlign: "center", textTransform:"uppercase" }, textStyle]}>{buttonTitle}</Text>
        </View>;

    const buttonOpacity = disabled === true ? 0.5 : 1;

    return (
        <Pressable
            disabled={disabled}
            onLongPress={onLongPress}
            onPress={onPress}
            style={({ pressed }) => [
                {
                    opacity: pressed === true ? 0.5 : buttonOpacity
                },
                style,
                buttonStyle,
                contextStyle
            ]}>
            {buttonContent}
        </Pressable>
    )

}

export default Button;

const styles = StyleSheet.create({
    shadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.30,
        shadowRadius: 4.65,

        elevation: 8,
    },
    pressed_shadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.23,
        shadowRadius: 2.62,

        elevation: 4,
    }
});