import React from 'react';
import { StyleSheet, View, Image, Platform, ActivityIndicator, Dimensions, TouchableOpacity, Text } from 'react-native';

import * as Constants from '../utils/Constants';
import Style from './Style';

import _ from 'lodash';

//const stockageDir = Platform.OS === 'android' ? RNFS.CachesDirectoryPath : RNFS.DocumentDirectoryPath + '/OtbCaches';
//const stockageDir = RNFS.CachesDirectoryPath;

export default class OtbImage extends React.Component {


    constructor() {
        super();
        this.state = {
            source: null,
            imageLoaded: false,
            loadedWithError: false,
            refreshed: 0,
            origin: '',
            showSvg: false
        };
        this.onError = this.onError.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onLoadEnd = this.onLoadEnd.bind(this);
        this.treateImageData = this.treateImageData.bind(this);
    }

    componentDidMount() {
        this.treateImageData();
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (_.isEqual(prevProps, this.props) === false) {
            if (_.isEqual(prevProps.source, this.props.source) === false) {
                this.treateImageData();
            }
        }
    }


    render() {
        const { imageLoaded, source, showSvg } = this.state;
        const { svg, resizeMode = "contain", key } = this.props;
        let style = [];
        if (this.props.style !== undefined) {
            style.push(this.props.style)
        }
        if (this.props.shadow !== undefined) {
            style.push(this.props.shadow);
        }
        if (imageLoaded === false) {
            return (
                <View style={[style, { justifyContent: 'center', alignItems: 'center' }]}>
                    <ActivityIndicator />
                </View>
            )
        }
        return (
            <Image
                nativeID={key}
                onError={this.onError}
                resizeMode={resizeMode}
                source={source}
                style={[style]} />
        );
    }

    onLoadEnd() {
        //Log.display('onLoadEnd', this.props.source);
    }

    onLoad() {
        //Log.display('onLoad', this.props.source);
    }

    onError() {
        const { origin, backup } = this.props;
        if ((origin === Constants.ImageOrigin.BUNDLE) && ((backup !== undefined) && (backup.length > 0))) {
            this.setState({ source: { uri: backup }, imageLoaded: true, origin: Constants.ImageOrigin.REMOTE });
        }
    }

    treateImageData() {
        const { origin, source, svg } = this.props;
        if (origin !== undefined) {
            //On va transformer l'image en data BASE64
            if (origin === Constants.ImageOrigin.BUNDLE) {
                this.setState({ source: source, imageLoaded: true, origin: Constants.ImageOrigin.BUNDLE });
            } else if (origin === Constants.ImageOrigin.LOCAL) {
                this.setState({ source: source, imageLoaded: true, origin: Constants.ImageOrigin.LOCAL });
                /*
                if (Platform.OS === 'android') {
                    
                } else {
                    const pathOfTheImage = Platform.OS === 'android' ? source.uri : source.uri;
                    ReactNativeBlobUtil.fs.readFile()
                    fileType(pathOfTheImage).then((type) => {
                        RNFS.readFile(pathOfTheImage, 'base64').then((res) => {
                            this.setState({ source: { uri: `data:${type};base64,${res}` }, imageLoaded: true, origin: Constants.ImageOrigin.LOCAL });
                        }).catch((error) => {

                        })
                    });
                }
                */
            } else {
                this.setState({ source: source, imageLoaded: true, origin: Constants.ImageOrigin.REMOTE });
            }
        } else {
            this.setState({ source: source, imageLoaded: true, origin: Constants.ImageOrigin.REMOTE });
        }
    }
}