import React, { useEffect, useState } from "react";

import {
    Image,
    Modal,
    TouchableOpacity,
    View,
    Text
} from "react-native";
import User from "../../../shared/data/user/User";

import AppTheme from "../../../shared/utils/Theme";
import { getCurrentSession } from "../../services/AppWrite/AppWriteDatabase";
import AppMenu from "../../../shared/components/Homepage/AppMenu";
import Style from "../../../shared/utils/Style";
import Images from "../../utils/Images";
import i18n from "../../../shared/utils/Localization/Localization";
import AvatarView from "../../../shared/components/Gamification/AvatarView";
import { useNavigate } from "react-router-dom";
import { env } from "../../services/Specific_Database";
//import { launchUserListener } from "../../services/Specific_Auth";

const KEYS = {
    info: "info",
    profile: "profile",
    path: "path",
    still: "still",
    grow: "grow",
    talk: "talk",
    liliAtSchool: "liliAtSchool",
    superPower: "superPower",
    liliAtHome: "liliAtHome",
    trainingPath: "trainingPath",
    sleepingMeditation: "sleepingMeditation",
    coachingManual: "coachingManual"
}

export let user_uid = "";

const InvisibleHeader = () => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();

    const [viewDidAppear, setViewDidAppear] = useState(false);

    const [showMenu, setShowMenu] = useState<boolean>(false);

    const onViewDidAppear = () => {
        setViewDidAppear(true);
    }

    const launchSessionListener = async () => {
        const currentSessiion = await getCurrentSession();
        setTimeout(() => {
            if (currentSessiion === null) {
                user.deleteUser();
            } else {
                launchSessionListener();
            }
        }, 30000);
    }

    useEffect(() => {
        if (viewDidAppear === true) {
            // Si on est sur l'app TNE, on va lancer un "listener" de session. Sinon des données utilisateurs
            const host = window.location.hostname;
            if (host === "app.lili.cool") {
                //launchUserListener()
            } else {
                launchSessionListener();
            }
        }
    }, [viewDidAppear]);

    const launchTheApp = (data: {key:string}) => {
        const { key } = data;
        const location = window.location;
        const hideNavBarHashes = [];
        const menuHashes = ["/appMenu/teacher"];
        if (menuHashes.indexOf(location.hash) !== -1) {
            // On est sur une page de menu, donc on doit envoyer la demande de changer de menu
            
        } else {
            // On affiche la page de menu en modal ou la page demandée en push

        }
    }

    const showUserProfile = () => {

    }

    const getHeader = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} />;
        }
        if (env === "DEV") {
            return <View style={{width:"100%", height:appTheme.pixelPerfect(20), backgroundColor:"#800000", justifyContent:"center", alignItems:"center"}}>
                <Text style={{color:"#FFFFFF", fontWeight:"bold"}}>ENVIRONNEMENT DE DÉVELOPPEMENT</Text>
            </View>
        }
        return <View />;
        // Style
        let switchMenuButtonStyle = {
            width: appTheme.pixelPerfect(50),
            height: appTheme.pixelPerfect(50),
            marginBottom: appTheme.pixelPerfect(10)
        };
        const lilitAtHomeIcon = user.isTeacher() === true ? images.icSuperProgramsCircle : images.icLiliAtHomeCircle;
        const profileButton = <TouchableOpacity
            accessible={true}
            accessibilityLabel={i18n.t("accessibility.openUserProfile")}
            accessibilityRole="button"
            onPress={showUserProfile} style={{ width: appTheme.pixelPerfect(60), justifyContent: "center", alignItems: "center", marginHorizontal: appTheme.pixelPerfect(10) }}>
            <AvatarView width={appTheme.pixelPerfect(40)} height={appTheme.pixelPerfect(40)} />
            <Text style={[{ textAlign: "center", fontSize: appTheme.pixelPerfectForFont(3), paddingTop: appTheme.pixelPerfect(4) }, { fontSize: appTheme.pixelPerfectForFont(3), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }]}>{i18n.t("appMenu.switchMenu.profile").toLocaleUpperCase()}</Text>
        </TouchableOpacity>;
        return <View style={[{ width: appTheme.getFullScreenWidth(), backgroundColor: "transparent" }]}>
            <View style={[Style.bottomBarShadowed, { width: appTheme.getFullScreenWidth(), backgroundColor: appTheme.schoolBackgroundColor, height: appTheme.pixelPerfect(80), flexDirection:"row", justifyContent:"space-between" }]}>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", height: appTheme.pixelPerfect(80) }}>
                    <TouchableOpacity style={{ marginHorizontal: appTheme.pixelPerfect(10) }} onPress={() => launchTheApp({ key: KEYS.liliAtSchool })}>
                        <Image source={images.icLiliAtSchoolCircle} style={switchMenuButtonStyle} resizeMode="contain" />
                    </TouchableOpacity>
                    <TouchableOpacity style={{ marginHorizontal: appTheme.pixelPerfect(10) }} onPress={() => launchTheApp({ key: KEYS.liliAtHome })}>
                        <Image source={lilitAtHomeIcon} style={switchMenuButtonStyle} resizeMode="contain" />
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => launchTheApp({ key: KEYS.coachingManual })} style={{ width: appTheme.pixelPerfect(80), justifyContent: "center", alignItems: "center" }}>
                        <Image source={images.icCoachManual} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />
                        <Text style={[{ textAlign: "center", fontSize: appTheme.pixelPerfectForFont(3), paddingTop: appTheme.pixelPerfect(4) }, { fontSize: appTheme.pixelPerfectForFont(3), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }]}>{i18n.t("appMenu.switchMenu.coachManual").toLocaleUpperCase()}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    {profileButton}
                    <TouchableOpacity onPress={() => launchTheApp({ key: KEYS.info })} style={{ width: appTheme.pixelPerfect(60), justifyContent: "center", alignItems: "center", marginHorizontal: appTheme.pixelPerfect(10) }}>
                        <View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), justifyContent: "center", alignItems: "center" }}>
                            <Image source={images.icAbout} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />
                        </View>
                        <Text style={{ textAlign: "center", fontSize: appTheme.pixelPerfectForFont(3), paddingTop: appTheme.pixelPerfect(4), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }}>{i18n.t("appMenu.switchMenu.about").toLocaleUpperCase()}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    }

    return (
        <View style={{ zIndex: 100 }}>
            {getHeader()}
            <Modal
                transparent={true}
                visible={showMenu}
                animationType="slide">
                <AppMenu />
            </Modal>
        </View>
    )
};

export default InvisibleHeader;