import { TextStyle } from "react-native";

import AppTheme from "../../utils/Theme";

export default class RoundCornerTextInputStyle {

    appTheme: AppTheme = new AppTheme();

    input: TextStyle = {
        outline: "none",
        width: this.appTheme.getBlockWidth()*0.8,
        height: this.appTheme.pixelPerfect(40),
        padding: 10,
        borderWidth:1,
        borderColor: this.appTheme.darkBlue+'3D',
        fontSize: this.appTheme.pixelPerfectForFont(8),
        marginBottom: this.appTheme.pixelPerfect(10),
        fontFamily: this.appTheme.secondaryFont,
        color: this.appTheme.darkBlue,
        borderRadius: this.appTheme.pixelPerfect(10)
    };

    inputMultiline: TextStyle = {
        ...(this.input as object),
        height: 120
    }

    error: TextStyle = {
        width: this.appTheme.getBlockWidth()*0.8,
        fontFamily: this.appTheme.secondaryFont, 
        fontSize: this.appTheme.pixelPerfectForFont(5), 
        color: this.appTheme.errorColor, 
        paddingLeft: this.appTheme.pixelPerfect(5), 
        marginTop: this.appTheme.pixelPerfect(-8)
    };

    constructor() {

    }
}