import React from "react";

import {
    View,
    Platform
} from 'react-native';

const LiliAirPlayButton = (props: {
    activeTintColor: string | undefined,
    tintColor: string | undefined
}) => {

    const {
        activeTintColor,
        tintColor,
    } = props;

    return <View />;

}

export default LiliAirPlayButton;