import React, { useState } from "react";

import {
    View,
    Text,
    Image
} from "react-native";

import AppTheme from "../../utils/Theme";
import Divider from "../../designSystem/Divider/Divider";
import TextInput from "../../designSystem/TextInput/RoundCornerTextInput";
import Images from "../../../specific/utils/Images";
import Button from "../../designSystem/Button";
import KeyboardAwareScrollView from "../../../specific/views/KeyboardAwareScrollView";
import { checkUserGiftCode } from "../../../specific/services/Specific_Database";
import { Card } from "../../models/Models";

interface CodeProps {
    code?: string
}

const CodeSheet = (props: CodeProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const { code = "" } = props;

    const [userCode, setUserCode] = useState<string>(code);
    const [userEmail, setUserEmail] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [userCard, setUserCard] = useState<Card | undefined>(undefined);

    const checkCode = async () => {
        setErrorMsg("");
        try {
            const result = await checkUserGiftCode({ code: userCode });
            if (result === undefined) {
                setErrorMsg("Il semble que ce code ne soit pas valide.");
            } else {
                setUserCard(result.data);
            }
        } catch (error) {

        }
    }

    const showErrorMessage = () => {
        if (errorMsg.length === 0) {
            return <View />
        }
        return <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(7), color: appTheme.errorColor, textAlign: "center" }}>
            {errorMsg}
        </Text>
    }

    const checkEmail = () => {

    }

    const getContent = () => {
        if (userCard === undefined) {
            return <View style={{ width: "100%", backgroundColor: "#FFF", alignItems: "center" }}>
                <Image source={images.icPremium} style={{ width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(50) }} />
                <Divider />
                <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(7), textTransform: "uppercase", color: appTheme.darkBlue, textAlign: "center" }}>Saisissez votre code ci-dessous</Text>
                <Divider />
                <TextInput value={userCode} onChangeText={(text) => setUserCode(text)} />
                {showErrorMessage()}
                <Divider />
                <Button disabled={userCode.length === 0} onPress={checkCode} title="Valider" />
            </View>
        }
        const userTypeIcon = userCard.type === "animator" ? images.icLiliAtExtracurricularCircle : userCard.type === "family" ? images.icLiliAtHomeCircle : images.icLiliAtSchoolCircle;
        return <View style={{ width: "100%", backgroundColor: "#FFF", alignItems: "center" }}>
            <Image source={userTypeIcon} style={{ width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(50) }} />
            <Divider />
            <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(7), textTransform: "uppercase", color: appTheme.darkBlue, textAlign: "center" }}>{"Code " + userCard.code}</Text>
            <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(7), color: appTheme.darkBlue, textAlign: "center" }}>{"Saisissez ci-dessous votre adresse e-mail pour l'associer à ce code."}</Text>
            <Divider />
            <TextInput type="email" value={userEmail} onChangeText={(text) => setUserEmail(text)} />
            {showErrorMessage()}
            <Divider />
            <Button disabled={userEmail.length < 5} onPress={checkEmail} title="Associer" />
        </View>
    }

    return <KeyboardAwareScrollView>
        {getContent()}
    </KeyboardAwareScrollView>
}

export default CodeSheet;