import React from "react";

import {
    StyleSheet,
    Text,
    View
} from "react-native";
import i18n from "../../utils/Localization/Localization";
import AppTheme from "../../utils/Theme";
import { version, build } from "../../../../package.json";

const Footer = () => {

    const appTheme: AppTheme = new AppTheme();

    return (
        <View style={styles.footer}>
            <Text style={{color: appTheme.secondaryColor }}>
                {i18n.t("app.title", { version, build })}
            </Text>
        </View>
    );
}

export default Footer;

const styles = StyleSheet.create({
    footer: {
        position: "absolute",
        bottom: 40
    }
}); 