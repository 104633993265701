import FileResizer from "react-image-file-resizer";
import { storage } from "./Specific_Database";
import { UploadResult, getDownloadURL, ref as storageRef, uploadBytes, uploadString } from "firebase/storage"

export const resizeFile = (file, width, height) =>
    new Promise((resolve) => {
        FileResizer.imageFileResizer(
            file,
            width,
            height,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        );
    });

// on part du principe que toutes les images des activités doivent être au format 1x = 320 x 270
export const updloadActivityImage = async (data: { imageToUpload: any, categoryKey: string, activityKey: string }) => {
    try {
        // On va retailler l'image à plusieurs taille, pour correspondre aux demandes iOS / Web / Android
        const { imageToUpload, categoryKey, activityKey } = data;
        // Pour le web on se base sur l'image en 4x
        const imageFactorFour = await resizeFile(imageToUpload, 1440, 1080);
        const imageFactorThree = await resizeFile(imageToUpload, 1080, 810);
        const imageFactorTwo = await resizeFile(imageToUpload, 720, 540);
        const imageFactorOne = await resizeFile(imageToUpload, 360, 270);
        const imageFactorOneAndHalf = await resizeFile(imageToUpload, 540, 405);
        const imageFactorOneRef = storageRef(storage, `lili_hq/${categoryKey}/${activityKey}/${activityKey}_1x.jpg`);
        await uploadString(imageFactorOneRef, imageFactorOne, 'data_url');
        const imageFactorOneAndAHalfRef = storageRef(storage, `lili_hq/${categoryKey}/${activityKey}/${activityKey}_1_5x.jpg`);
        await uploadString(imageFactorOneAndAHalfRef, imageFactorOneAndHalf, 'data_url');
        const imageFactorTwoRef = storageRef(storage, `lili_hq/${categoryKey}/${activityKey}/${activityKey}_2x.jpg`);
        await uploadString(imageFactorTwoRef, imageFactorTwo, 'data_url');
        const imageFactorThreeRef = storageRef(storage, `lili_hq/${categoryKey}/${activityKey}/${activityKey}_3x.jpg`);
        await uploadString(imageFactorThreeRef, imageFactorThree, 'data_url');
        const imageRef = storageRef(storage, `lili_hq/${categoryKey}/${activityKey}/${activityKey}_4x.jpg`);
        const snapshot: UploadResult = await uploadString(imageRef, imageFactorFour, 'data_url');
        if ((snapshot.ref !== undefined) && (snapshot.ref !== null)) {
            const imageUrl: string = await getDownloadURL(snapshot.ref);
            return imageUrl;
        } else {
            throw (new Error("-1"));
        };
    } catch (error) {
        throw (error);
    }
}

export const updloadMediaAudio = async (data: { audioToUpload: any, categoryKey: string, activityKey: string, mediaKey: string, index: number, language:string }) => {
    try {
        // On va retailler l'image à plusieurs taille, pour correspondre aux demandes iOS / Web / Android
        const { audioToUpload, categoryKey, activityKey, mediaKey, index, language } = data;
        const audioRef = storageRef(storage, `lili_hq/${categoryKey}/${activityKey}/media/${language}_${mediaKey}_${index}.mp3`);
        //const snapshot: UploadResult = await uploadString(audioRef, audioToUpload, "data_url");
        const snapshot: UploadResult = await uploadBytes(audioRef, audioToUpload);
        
        if ((snapshot.ref !== undefined) && (snapshot.ref !== null)) {
            const imageUrl: string = await getDownloadURL(snapshot.ref);
            return imageUrl;
        } else {
            throw (new Error("-1"));
        };
    } catch (error) {
        throw (error);
    }
}

export const updloadActivityAudio = async (data: { audioToUpload: any, categoryKey: string, activityKey: string, index: number, language:string }) => {
    try {
        // On va retailler l'image à plusieurs taille, pour correspondre aux demandes iOS / Web / Android
        const { audioToUpload, categoryKey, activityKey, index, language } = data;
        const audioRef = storageRef(storage, `lili_hq/${categoryKey}/${activityKey}/audio/${activityKey}_${language}_${index}.mp3`);
        //const snapshot: UploadResult = await uploadString(audioRef, audioToUpload, "data_url");
        const snapshot: UploadResult = await uploadBytes(audioRef, audioToUpload);
        
        if ((snapshot.ref !== undefined) && (snapshot.ref !== null)) {
            const imageUrl: string = await getDownloadURL(snapshot.ref);
            return imageUrl;
        } else {
            throw (new Error("-1"));
        };
    } catch (error) {
        throw (error);
    }
}