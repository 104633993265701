import React, { useRef, useEffect, useState } from "react";

import {
    Dimensions,
    StyleSheet,
    Pressable,
    Text,
    ActivityIndicator,
    View,
    Touchable,
    TouchableOpacity,
    Platform
} from 'react-native';


import AppTheme from "../utils/Theme";
import Alert from "../components/Notification/Alert";

// SVG
import SVGView from "../utils/SvgView";
import { default as IcHeart } from "../assets/svg/icons/solid/heart.svg";
import { default as IcHeartOutline } from "../assets/svg/icons/regular/heart.svg";
import Style from "../utils/Style";

import { addDataToUser } from "../services/Database";

// Analytics
import { trackEvent, analyticsEventActivityBookmarked, propertiesEventCategory } from "../services/Tracking/Analytics";
import User from "../data/user/User";
import { useLocation } from "react-router-dom";

const FavoriteButton = (props: {
    activity: any,
    onFavoriteStatusChanged?: void,
    color: string,
    categoryAnalyticsKey:string,
}) => {

    let location = null;
    let host = "app.lili.cool";
    if (Platform.OS === "web") {
        location = useLocation();
        host = window.location.hostname;
    }
    
    if ((host === "app-tne.lili.cool") || (host === "app-tne-demo.lili.cool")) {
        return <View />;
    }

    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();
    const [togglingFavorite, setTogglingFavorites] = useState(false);

    const alertRef = useRef<Alert>(null);

    const {
        activity,
        onFavoriteStatusChanged,
        color,
        categoryAnalyticsKey
    } = props;

    useEffect(() => {
    }, []);

    const toggleActivityToFavorites = async () => {
        try {
            setTogglingFavorites(true);
            let activityAddedToFav = true;
            let userFavorites = user.favorite_activities;
            if (userFavorites.indexOf(activity.key) !== -1) {
                // On retire des favories
                userFavorites.splice(userFavorites.indexOf(activity.key), 1);
                activityAddedToFav = false;
            } else {
                // On ajoute au favories
                trackEvent(analyticsEventActivityBookmarked, {[propertiesEventCategory]:categoryAnalyticsKey});
                userFavorites.push(activity.key);
                activityAddedToFav = true;
            }
            await addDataToUser({
                key: "favorite_activities",
                value: userFavorites,
                user_id: user.uid
            });
            if (onFavoriteStatusChanged !== undefined) {
                onFavoriteStatusChanged(activityAddedToFav);
            }
            setTogglingFavorites(false);
        } catch (error) {
            setTogglingFavorites(false);
        }
    }


    const heartIcon = user.isActivityFavorite(activity.key) === true ? IcHeart : IcHeartOutline;
    const favoriteContent = togglingFavorite === true ? <ActivityIndicator color={color} /> : <SVGView Component={heartIcon} size={appTheme.pixelPerfect(20)} color={color} />;

    return (
        <TouchableOpacity onPress={toggleActivityToFavorites} style={[Style.shadowed, { backgroundColor: appTheme.white, width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20) }]}>
            <View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), justifyContent: "center", alignItems: "center", borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.white }}>
                {favoriteContent}
            </View>
        </TouchableOpacity>
    )

}

export default FavoriteButton;