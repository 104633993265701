import { useEffect, useState } from "react";
import User from "../../shared/data/user/User";
import { auth } from "./Specific_Database";
import { browserLocalPersistence, createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signOut } from "firebase/auth";
import { setPersistence, browserSessionPersistence, signInWithEmailAndPassword } from "firebase/auth";

export function useAuth() {
    //
    const [currentUser, setCurrentUser] = useState<any>();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, user => {
            setCurrentUser(user);
        });
        return unsubscribe;
    }, []);
    return currentUser;
}

// Get current user
export function dbCurrentUser() {
    return new Promise((resolve, reject) => {
        if ((auth.currentUser !== undefined) && (auth.currentUser !== null)) {
            resolve(auth.currentUser);
        }
        const unsubscribe = onAuthStateChanged(auth, user => {
            unsubscribe();
            resolve(user);
        }, reject);
    });
}

// User SignUp
export async function dbCreateUserWithEmailAndPassword(data: { email: string, password: string }) {
    const { email, password } = data;
    try {
        const authResult = await createUserWithEmailAndPassword(auth, email, password);
        return authResult;
    } catch (error) {
        throw error.code;
    }
}

// User SignIn
export async function dbSignInWithEmailAndPassword({ email, password }) {
    try {
        await setPersistence(auth, browserLocalPersistence);
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        return userCredential;
    } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        throw (error.code);
    }
}

// User reset password
export function revokePassword(credential: { email: string }) {
    return new Promise(function (resolve, reject) {
        sendPasswordResetEmail(auth, credential.email).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error.code);
        });
    });
}

// User Sign out
export function dbSignOut() {
    signOut(auth);
}