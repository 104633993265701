import React, { useContext, useEffect, useRef, useState } from "react";

import { Text, View, Image, Platform, TouchableOpacity, FlatList, Modal, Animated, Alert, ImageResizeMode, LayoutAnimation } from "react-native";
import AppTheme from "../../utils/Theme";
import ComponentProps from "../../../specific/utils/Interfaces/ComponentProps";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { pop, push, showModal } from "../../../specific/utils/Navigation/Navigation";
import { useNavigate, useParams } from "react-router-dom";
import { getLocalActivities, getLocalActivitiesByCategories, getLocalActivitiesBySections, getLocalCategories } from "../../../specific/utils/LocalStorage";
import i18n, { getLanguage } from "../../utils/Localization/Localization";
import Style from "../../utils/Style";
import SVGView from "../../utils/SvgView";
import { default as IcBack } from "../../assets/svg/lili/ic_back_arrow.svg";
import { default as IcClose } from "../../assets/svg/icons/solid/times.svg";
import Images from "../../../specific/utils/Images";
import CategoryHomeStyle from "../../styles/categories/CategoryHomeStyle";
import MainStyle from "../../styles/game/MainStyle";
import User from "../../data/user/User";
import { EventFiltersByLevelChanged, ScreenActivityMediaList, ScreenActivityPager, ScreenActivitySheet, ScreenCategoryHome, ScreenSubscription, ScreenSuperRoom } from "../../utils/Constants";
import Activity, { getActivityAvalability } from "../../models/Activity";
import CategoryIcon from "../Categories/CategoryIcon";
import { replaceCustomVariablesInString } from "../../utils/StringUtils";
import { default as IcFilter } from "../../assets/svg/lili/ic_filter.svg";
import { build } from "../../../../package.json";

import SVGIconButton from "../../designSystem/Buttons/SVGIconButton";

import * as Navigation from "../../../specific/utils/Navigation/Navigation";

import { LocalDBContext } from "../../data/LocalDB";
import Divider from "../../designSystem/Divider/Divider";
import CategoryHome from "../Categories/CategoryHome";
import InViewModal from "../../../specific/components/InViewModal/InViewModal";
import DeepLink from "../../services/DeepLink/DeepLink";

import Sections from "../../data/jsons/bundleSections.json";
import LinkButton from "../../designSystem/Buttons/LinkButton";

interface SectionCategoriesProps extends ComponentProps {
    sectionKey?: string,
    isModal?: boolean
}

const headerImages = {
    "still": "headerSectionStill",
    "talk": "headerSectionTalk",
    "grow": "headerSectionGrow",
    "inspire": "headerSectionInspire"
}

const SectionCategories = (props: SectionCategoriesProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const categoryHomeStyle: CategoryHomeStyle = new CategoryHomeStyle();
    const mainStyle: MainStyle = new MainStyle();
    const user: User = User.getInstance();

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [categories, setCategories] = useState<any>(undefined);
    const [localCategories, setLocalCategories] = useState<any>(undefined);
    const [section, setSection] = useState<any>(undefined);

    const [localActivities, setLocalActivities] = useState(undefined);
    const [localActivitiesByCategory, setLocalActivitiesByCategory] = useState(undefined);
    const [activitiesForCategory, setActivitiesForCategory] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string | undefined>(undefined);

    const [bodyNumberOfLines, setBodyNumberOfLines] = useState<number>(2);

    const contentViewOpactiy: Animated.Value = useRef(new Animated.Value(1)).current;

    const localDBData = useContext(LocalDBContext);

    const inViewModal = useRef<InViewModal>(null);

    let propsData = props;
    if (Platform.OS === "web") {
        propsData = useParams();
    }
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const { sectionKey, componentId, isModal = false } = propsData;

    useEffect(() => {
        if (section !== undefined) {
            getSectionCategories();
        }
    }, [section]);

    const onViewDidAppear = () => {
        if ((sectionKey !== undefined) && (sectionKey.length > 0)) {
            // On va récupérer les données de la catégorie
            const sections = localDBData.sections.data;
            if (sections[sectionKey] !== undefined) {
                setSection(sections[sectionKey]);
            } else {
                goBack();
            }
        } else {
            // Erreur de lien on revient en arrière
            goBack();
        }
    }

    const getSectionCategories = async () => {
        const localActivitiesBySectionsFromStorage = await getLocalActivitiesBySections();
        if (localActivitiesBySectionsFromStorage.data[sectionKey] !== undefined) {
            console.log(localActivitiesBySectionsFromStorage.data[sectionKey]);
            setCategories(localActivitiesBySectionsFromStorage.data[sectionKey]);
        } else {
            goBack();
        }
    }

    useEffect(() => {
        if (categories !== undefined) {
            getCategoryDetails()
        }
    }, [categories]);

    const getCategoryDetails = async () => {
        const localCategoriesFromStorage = await getLocalCategories();
        let localCategoriesOrdered = [];
        for (const aCategoryKey in categories) {
            if (Object.prototype.hasOwnProperty.call(categories, aCategoryKey)) {
                if (localCategoriesFromStorage.data[aCategoryKey] !== undefined) {
                    const aCategory = localCategoriesFromStorage.data[aCategoryKey];
                    const categoryMinBuildNumber = aCategory.minBuildNumber ?? "0";
                    if ((aCategory.status === "published") && (parseInt(categoryMinBuildNumber) < parseInt(build))) {
                        let isCategoryInserted: boolean = false;
                        for (const anOrderedCategoryIndex in localCategoriesOrdered) {
                            if (Object.prototype.hasOwnProperty.call(localCategoriesOrdered, anOrderedCategoryIndex)) {
                                const anOrderedCategory = localCategoriesOrdered[anOrderedCategoryIndex];
                                if (anOrderedCategory.ord > aCategory.ord) {
                                    localCategoriesOrdered.splice(parseInt(anOrderedCategoryIndex), 0, aCategory);
                                    isCategoryInserted = true;
                                    break;
                                }
                            }
                        }
                        if (isCategoryInserted === false) {
                            localCategoriesOrdered.push(aCategory);
                        }
                    }
                }
            }
        }
        setLocalCategories(localCategoriesOrdered);
    }

    useEffect(() => {
        if (localCategories !== undefined) {
            getActivities();
        }
    }, [localCategories])

    const getActivities = async () => {
        const localActivitiesFromStorage = await getLocalActivities();
        setLocalActivities(localActivitiesFromStorage.data);
    }

    useEffect(() => {
        if (localActivities !== undefined) {
            // On va regarder si on a des critères de deeplink
            const deeplink: DeepLink = DeepLink.getInstance();
            if (deeplink.category_key.length > 0) {
                showCategorySheet({ categoryKey: deeplink.category_key });
                deeplink.category_key = "";
            }
            setViewDidAppear(true);
        }
    }, [localActivities]);

    const getCategoryColors = () => {
        let defaultColors = {
            btn: appTheme.daySkyColor,
            header_bg: appTheme.daySkyColor,
            nav: appTheme.daySkyColor,
            txt: appTheme.white
        };
        let categoryColors = defaultColors;
        if (sectionKey === "still") {
            categoryColors = {
                btn: appTheme.still,
                header_bg: appTheme.still,
                nav: "#A2F2F8",
                txt: appTheme.darkBlue
            }
        } else if (sectionKey === "talk") {
            categoryColors = {
                btn: appTheme.talk,
                header_bg: "#FFAA8C",
                nav: appTheme.talk,
                txt: appTheme.white
            }
        } else if (sectionKey === "grow") {
            categoryColors = {
                btn: appTheme.grow,
                header_bg: "#51D2A8",
                nav: appTheme.grow,
                txt: appTheme.white
            }
        } else if (sectionKey === "super_program") {
            categoryColors = {
                btn: appTheme.darkBlue,
                header_bg: appTheme.darkBlue,
                nav: appTheme.darkBlue,
                txt: appTheme.white
            }
        } else if (sectionKey === "inspire") {
            categoryColors = {
                btn: section.clr.main,
                header_bg: section.clr.action,
                nav: section.clr.main,
                txt:"#25364f"
            }
        }
        return categoryColors;
    }

    const goBack = () => {
        if ((Platform.OS !== "web") && (isModal === true)) {
            Navigation.dismissModal({ componentId, navigate });
        } else {
            pop({ componentId, navigate });
        }  
    }

    const buttonSize = Platform.OS === "web" ? appTheme.pixelPerfect(50) : appTheme.pixelPerfect(30);
    const getLeftButton = () => {
        // Si on est en mobile, on a un bouton retour
        // En web un bouton nous ramenant au menu
        if ((Platform.OS !== "web") && (isModal === true)) {
            return <View />;
        }
        const clr = getCategoryColors();
        let navBarColor = clr.nav;
        let leftButtonContent = <SVGView Component={IcBack} color={navBarColor} size={appTheme.pixelPerfect(12)} />;
        if (Platform.OS === "web") {
            // On va regarder si l'utilisateur est un prof ou un élève
            let imageForLeftButton = images.icLiliAtHomeCircle;
            if (user.profile === "teacher") {
                imageForLeftButton = images.icLiliAtSchoolCircle;
            } else if (user.profile === "animator") {
                imageForLeftButton = images.icLiliAtExtracurricularCircle;
            }
            leftButtonContent = <Image source={imageForLeftButton} style={{ width: buttonSize, height: buttonSize }} />
        }
        return <TouchableOpacity onPress={goBack} style={[Style.shadowed, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: appTheme.white, justifyContent: "center", alignItems: "center" }]}>
            {leftButtonContent}
        </TouchableOpacity>
    }

    const goToSuperRoom = () => {
        inViewModal.current?.showAlert({
            name: ScreenSuperRoom
        });
    }

    const getRightButton = () => {
        // Si on est en mobile, on a un bouton retour
        // En web un bouton nous ramenant au menu
        const clr = getCategoryColors();
        let navBarColor = clr.nav;
        let txtColor = clr.txt;
        let rightButtonContent = <SVGView Component={IcFilter} color={txtColor} size={appTheme.pixelPerfect(14)} />;
        if ((Platform.OS !== "web") && (isModal === true)) {
            return <TouchableOpacity onPress={goBack} style={[Style.shadowed, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, backgroundColor: appTheme.white, justifyContent: "center", alignItems: "center" }]}>
                <SVGView Component={IcClose} color={navBarColor} size={appTheme.pixelPerfect(12)} />
            </TouchableOpacity>
        }
        if (Platform.OS === "web") {
            navBarColor = appTheme.white;
            // On va regarder si l'utilisateur est un prof ou un élève
            let imageForLeftButton = images.icLiliAtSchoolCircle;
            if (user.profile === "family") {
                imageForLeftButton = images.icLiliAtHomeCircle;
            } else if (user.profile === "animator") {
                imageForLeftButton = images.icLiliAtExtracurricularCircle;
            }
            rightButtonContent = <Image source={imageForLeftButton} style={{ width: buttonSize, height: buttonSize }} />;

            const imageSize = appTheme.pixelPerfect(40);
            const rightButtonWidth = appTheme.pixelPerfect(120);
            const buttonTitle = user.profile === "teacher" ? i18n.t("appMenu.teachers.button.title") : user.profile === "animator" ? i18n.t("appMenu.animators.button.title") : i18n.t("appMenu.parents.button.title");
            const buttonImage = user.profile === "teacher" ? images.icSuperTeachersRoom : images.icSuperParentsRoom;
            return <TouchableOpacity onPress={goToSuperRoom} style={{ width: rightButtonWidth, justifyContent: "space-between", alignItems: "center", height: appTheme.pixelPerfect(50) }}>
                <View style={[mainStyle.shadowed, { borderRadius: imageSize / 2 }]}>
                    <Image source={buttonImage} style={{ width: imageSize, height: imageSize }} resizeMode="cover" />
                </View>
                <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(3), textAlign: "center" }}>{buttonTitle}</Text>
            </TouchableOpacity>

        }
        return <View />
    }

    const getNavBar = () => {
        const { title } = section;
        const clr = getCategoryColors();
        let navBarColor = clr.nav;
        let navBarTextColor = clr.txt;

        let titleToDisplay = replaceCustomVariablesInString(title[getLanguage()]);
        return <View style={[categoryHomeStyle.navigationBar(navBarColor)]}>
            <View style={categoryHomeStyle.navigationBarContent}>
                {getLeftButton()}
                <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                    <Text numberOfLines={2} style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.superHerosFontBold, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", color: Platform.OS === "web" ? appTheme.darkBlue : navBarTextColor }}>{titleToDisplay.replace("\\n", "\n")}</Text>
                </View>
                {getRightButton()}
            </View>
        </View>
    }

    const toggleBodyIntro = () => {
        LayoutAnimation.configureNext({
            duration:200,
            create: { duration:100, type: 'linear', property: 'opacity' },
            update: { duration:600, type: 'spring', springDamping: 0.9 },
            delete: { duration:100, type: 'linear', property: 'opacity' },
        });
        if (bodyNumberOfLines === 0) {
            setBodyNumberOfLines(2);
        } else {
            setBodyNumberOfLines(0);
        }
    }

    const getBodyIntro = () => {
        const { body, img } = section;
        const clr = getCategoryColors();
        let intro = "";
        if ((body !== undefined) && (body[getLanguage()] !== undefined)) {
            intro = body[getLanguage()];
        }
        if (intro.length === 0) {
            return <View />
        }
        return <View style={{ alignItems: "center" }}>
            <View style={[categoryHomeStyle.bodyIntro(clr.nav)]}>
                <Text numberOfLines={bodyNumberOfLines} style={{ fontFamily: appTheme.primaryMediumFont, fontSize: appTheme.pixelPerfectForFont(8), color: appTheme.darkBlue, textAlign: "center" }}>
                    {intro}
                </Text>
                <LinkButton title={bodyNumberOfLines === 0 ? "Fermer" : "Lire la suite"} onPress={toggleBodyIntro} />
            </View>
        </View>
    }

    useEffect(() => {
        let contentViewOpactiyFinalValue = selectedCategory === undefined ? 1 : 0;
        Animated.timing(contentViewOpactiy, {
            toValue: contentViewOpactiyFinalValue,
            duration: 500,
            useNativeDriver: false
        }).start();
    }, [selectedCategory]);

    const showCategorySheet = (data: { categoryKey: string }) => {
        const { categoryKey } = data;
        if (Platform.OS === "web") {
            window.scrollTo(0, 0);
            setSelectedCategory(categoryKey);
        } else {
            let passProps = {
                categoryKey: categoryKey,
                sectionKey,
                starting_point: "section"
            }
            push({
                componentId,
                navigate,
                name: ScreenCategoryHome,
                passProps
            });
        }

    }

    const categoriesGridCell = ({ item, index }) => {
        const refWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
        let cellSize = (refWidth - appTheme.pixelPerfect(60)) / 3;
        const marginHorizontal = appTheme.pixelPerfect(10);
        let imageSize = appTheme.pixelPerfect(80);
        if (imageSize > (cellSize - appTheme.pixelPerfect(10))) {
            imageSize = cellSize - appTheme.pixelPerfect(10);
        }
        const marginTop = Platform.OS === "web" ? 0 : appTheme.pixelPerfect(10);
        const textFontSize = Platform.OS === "web" ? appTheme.pixelPerfectForFont(10) : appTheme.pixelPerfectForFont(6);
        // On va chercher l'image de la première activité de la catégorie
        const firstActivityKey = categories[item.key][0] !== undefined ? categories[item.key][0] : undefined;
        let activityImage: string | undefined = undefined;
        let remotePath: string | undefined = undefined;
        if (localActivities[firstActivityKey] !== undefined) {
            const anActivity: Activity = localActivities[firstActivityKey];
            if ((anActivity.img_url !== undefined) && (anActivity.img_url.length > 0)) {
                remotePath = anActivity.img_url;
            }
            if ((anActivity.app_image !== undefined) && (anActivity.app_image.length > 0) && (images[anActivity.app_image] !== undefined)) {
                activityImage = anActivity.app_image;
            }
        }
        const categoryColor = getCategoryColors();
        let flexDirection = "column";
        let textAlign = "center";
        let paddingHorizontal = 0;
        return <TouchableOpacity key={item.key} onPress={() => showCategorySheet({ categoryKey: item.key })} style={{ flexDirection, marginTop, marginHorizontal, alignItems: "center", width: cellSize }}>
            <CategoryIcon color={categoryColor.nav} localPath={activityImage} remotePath={remotePath} size={appTheme.pixelPerfect(80)} />
            <View style={{ marginTop: appTheme.pixelPerfect(5), width: "80%", justifyContent: "flex-start", flex: 1, paddingHorizontal }}>
                <Text numberOfLines={3} style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, textAlign, fontSize:appTheme.pixelPerfectForFont(7) }}>{item.title[getLanguage()]}</Text>
            </View>
        </TouchableOpacity>
    }

    const getBackButton = () => {
        return <SVGIconButton buttonSize={appTheme.pixelPerfect(30)} icon={IcBack} onPress={goBack} color={"#354F84"} />
    }


    const getActivitiesGrid = () => {
        const lineWidth = appTheme.getFlexDirectionForSplitScreen() === "column" ? appTheme.getBlockWidth() : appTheme.getBlockWidth() - appTheme.pixelPerfect(20);
        let categoriesGrid = [];
        let categoriesLine = [];
        const clr = getCategoryColors();
        const navBarColor = clr.nav;
        const navBarTextColor = clr.txt;
        const titleToDisplay = replaceCustomVariablesInString(section.title[getLanguage()]);
        let intro = titleToDisplay;
        if ((section.body !== undefined) && (section.body[getLanguage()] !== undefined)) {
            intro = section.body[getLanguage()];
        }
        categoriesGrid.push(<View style={{ width: "100%", backgroundColor: navBarColor, justifyContent: "center", alignItems: "flex-start", flexDirection: "row", padding: appTheme.pixelPerfect(10) }}>
            {getBackButton()}
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center", paddingTop: appTheme.pixelPerfect(20) }}>
                <Text style={{ paddingHorizontal: appTheme.pixelPerfect(8), fontFamily: appTheme.primaryMediumFont, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "center", color: Platform.OS === "web" ? appTheme.darkBlue : navBarTextColor }}>{intro}</Text>
            </View>
        </View>);
        const activitiesByLine = appTheme.getFlexDirectionForSplitScreen() === "column" ? 3 : 3;
        const marginBottom = appTheme.getFlexDirectionForSplitScreen() === "column" ? appTheme.pixelPerfect(40) : appTheme.pixelPerfect(40);
        categoriesGrid.push(<Divider />);
        for (const aCategoryIndex in localCategories) {
            if (Object.prototype.hasOwnProperty.call(localCategories, aCategoryIndex)) {
                const aCategory = localCategories[aCategoryIndex];
                categoriesLine.push(categoriesGridCell({ item: aCategory, index: aCategoryIndex }));
                if (categoriesLine.length === activitiesByLine) {
                    categoriesGrid.push(<View style={{ flexDirection: "row", alignItems: "flex-start", width: lineWidth, marginBottom }}>
                        {categoriesLine}
                    </View>);
                    categoriesLine = [];
                }
            }
        }
        if (categoriesLine.length > 0) {
            categoriesGrid.push(<View style={{ flexDirection: "row", alignItems: "flex-start", width: lineWidth }}>
                {categoriesLine}
            </View>);
        }
        return categoriesGrid;
    }

    const getListHeader = () => {
        let headerBgColor = section.key === "superPowers" ? appTheme.darkBlue : undefined;
        const clr = getCategoryColors();
        if (headerBgColor === undefined) {
            headerBgColor = clr.header_bg;
        }
        let headerImageView = <View style={categoryHomeStyle.headerImage(appTheme.white)} />;
        let source: any = images[headerImages[sectionKey]];
        let imageWidth: number = appTheme.getFullScreenWidth();
        let imageHeight: number = imageWidth * 27 / 36;
        let resizeMode: ImageResizeMode = "cover";
        if  (Platform.OS === "web") {
            imageHeight = appTheme.pixelPerfect(210);
        }
        if (source !== undefined) {
            headerImageView = <Image source={source} style={[categoryHomeStyle.headerImage(headerBgColor), { width: imageWidth, height: imageHeight}]} resizeMode={resizeMode} />;
        }

        const activitiesGrid = (Platform.OS === "web") ? getActivitiesGrid() : <View />;
        const bodyIntro = Platform.OS === "web" ? <View /> : getBodyIntro();
        return <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center", backgroundColor:clr.nav }}>
            {headerImageView}
            <Animated.View style={[categoryHomeStyle.contentView, { opacity: contentViewOpactiy, overflow: "hidden" }]}>
                {bodyIntro}
                {activitiesGrid}
            </Animated.View>
        </View>
    }

    const getCategoriesList = () => {
        if (Platform.OS === "web") {
            return <View />
        } else {
            return <FlatList
                numColumns={3}
                ListHeaderComponent={getListHeader}
                data={localCategories}
                style={{ width: appTheme.getFullAppWidth(), backgroundColor: appTheme.white }}
                renderItem={categoriesGridCell}
                keyExtractor={(index, item) => item.key} />
        }
    }

    const getModalContent = () => {
        if (Platform.OS === "web") {
            if ((viewDidAppear === true) && (selectedCategory !== undefined)) {
                return <View style={[categoryHomeStyle.modalStyle, { top: 0 }]}>
                    <CategoryHome starting_point="section" sectionKey={sectionKey} header={headerImages[sectionKey]} isModal={true} categoryKey={selectedCategory} webCloseRequest={() => setSelectedCategory(undefined)} />
                </View>
            }
        }
        return <View />;
    }

    const getTabBar = () => {
        return <View />
        return <View style={[Style.bottomBarShadowed, {position:"absolute", bottom:0, backgroundColor:appTheme.white, width:appTheme.getFullAppWidth(), height:appTheme.pixelPerfect(45)+appTheme.bottomInsets, paddingTop:appTheme.pixelPerfect(5), paddingBottom:appTheme.bottomInsets, alignItems:"center"}]}>
            <View style={{flexDirection:"row", width:appTheme.getFullAppWidth() - appTheme.pixelPerfect(40), height:appTheme.pixelPerfect(40), borderRadius:appTheme.pixelPerfect(20), justifyContent:"space-evenly", alignItems:"center"}}>
                <View style={{ flexDirection:"row", justifyContent:"flex-start", alignItems:"center", width:appTheme.pixelPerfect(120), height:appTheme.pixelPerfect(40), borderRadius:appTheme.pixelPerfect(20), backgroundColor:"#EFEFEF"}}>
                    <Image source={images.icInspiration} style={{width:appTheme.pixelPerfect(40), height:appTheme.pixelPerfect(40), borderRadius:appTheme.pixelPerfect(20)}} />
                    <Text style={{fontWeight:"bold", fontFamily:appTheme.superHerosFontBold}}>Je m'inspire</Text>
                </View>
                <Image source={images.illStill} style={{width:appTheme.pixelPerfect(40), height:appTheme.pixelPerfect(40), borderRadius:appTheme.pixelPerfect(20)}} />
                <Image source={images.illTalk} style={{width:appTheme.pixelPerfect(40), height:appTheme.pixelPerfect(40), borderRadius:appTheme.pixelPerfect(20)}} />
                <Image source={images.illGrow} style={{width:appTheme.pixelPerfect(40), height:appTheme.pixelPerfect(40), borderRadius:appTheme.pixelPerfect(20)}} />
            </View>
        </View>
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator loading={true} color={appTheme.darkBlue} />
            </View>
        }

        let headerBgColor = section === "superPowers" ? appTheme.darkBlue : undefined;
        if (headerBgColor === undefined) {
            const clr = getCategoryColors();
            headerBgColor = clr.header_bg;
        }

        const listHeader = Platform.OS === "web" ? getListHeader() : <View />;
        return <View style={categoryHomeStyle.mainView(headerBgColor)}>
            <View style={categoryHomeStyle.mainBackground(headerBgColor)} />
            {listHeader}
            {getCategoriesList()}
            {getModalContent()}
            {getNavBar()}
            {getTabBar()}
            <InViewModal ref={inViewModal} />
        </View>
    }

    return getContent();
}

export default SectionCategories;