import React, { SVGProps } from "react";

import {
    Text,
    TouchableOpacity,
    TouchableOpacityProps
} from "react-native";

import AppTheme from "../../utils/Theme";

interface LinkButtonProps extends TouchableOpacityProps {
    color?: string,
    title?: string
}

const LinkButton = (props: LinkButtonProps) => {

    const appTheme: AppTheme = new AppTheme();
    const { color = appTheme.darkBlue, title="OK", ...restProps } = props;

    return <TouchableOpacity {...restProps}>
        <Text style={{color, fontFamily:appTheme.secondaryFont, fontSize:appTheme.pixelPerfectForFont(7), textDecorationLine:"underline"}}>{title}</Text>
    </TouchableOpacity>
}

export default LinkButton;