import React, { useEffect, useRef, useState } from "react";

import {
    Text,
    View,
    SafeAreaView,
    TouchableOpacity,
    Modal,
    Platform,
    ScrollView
} from "react-native";

import Main from "../../../specific/components/Main/Main";
import DynamicBackground from "../DynamicBackground/DynamicBackground";
import MainOptions from "../../models/MainOptions";
import Images from "../../../specific/utils/Images";
import Image from "../../designSystem/OTBImage";

import AppTheme from "../../utils/Theme";
import Style from "../../utils/Style";
import Label from "../../utils/Localization/Label";

import i18n from "../../utils/Localization/Localization";

import { addSubToUser, getUserGiftCards, redeemedCardInDb, transfertCardToNewUser } from "../../services/Database";
import User from "../../data/user/User";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../designSystem/Button";
import OTBTextInput from "../../designSystem/OTBTextInput";
import Alert from "../Notification/Alert";

const gridCellNumber = Platform.OS === "web" ? 2 : 1;

const UserGiftCards = (props: any) => {

    let propsData = props;
    let navigate = null;
    if (Platform.OS === "web") {
        propsData = useParams();
        navigate = useNavigate();
    }

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const label: Label = new Label();
    const user: User = User.getInstance();
    const alertRef = useRef<Alert>(null);

    const [giftCardsList, setGiftCardsList] = useState([]);
    const [giftCardSelected, setGiftCardSelected] = useState(undefined);
    const [transferCard, setTransferCard] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userEmailAddress, setUserEmailAddress] = useState("");

    const giftCardsDescription = {
        "cool.lili.subnr.laspremiumtwelve": {
            title: "Lili à l'école Premium",
            duration: "1 an",
            durationInMonth: 12,
            image: images.giftcardOne
        },
        "cool.lili.subnr.laspremiumthree": {
            title: "Lili à l'école Premium",
            duration: "3 mois",
            durationInMonth: 3,
            image: images.giftcardTwo
        },
        "cool.lili.sub.laspremiumtwelve": {
            title: "Lili à l'école Premium",
            duration: "1 an",
            durationInMonth: 12,
            image: images.giftcardOne
        },
        "cool.lili.sub.laspremiumthree": {
            title: "Lili à l'école Premium",
            duration: "3 mois",
            durationInMonth: 3,
            image: images.giftcardTwo
        },
        "cool.lili.sub.laspremium": {
            title: "Lili à l'école Premium",
            duration: "1 an",
            durationInMonth: 12,
            image: images.giftcardThree
        },
        "cool.lili.sub.lasessential": {
            title: "Lili à l'école Premium",
            duration: "1 an",
            durationInMonth: 12,
            image: images.giftcardFour
        },
        "cool.lili.gift.oneyear": {
            title: "Accès PREMIUM à LILI à la maison",
            duration: "1 an",
            durationInMonth: 12,
            image: images.giftcardOne
        },
        "cool.lili.gift.onemonth": {
            title: "Accès PREMIUM à LILI à la maison",
            duration: "1 mois",
            durationInMonth: 1,
            image: images.giftcardTwo
        },
        "cool.lili.gift.threemonths": {
            title: "Accès PREMIUM à LILI à la maison",
            duration: "3 mois",
            durationInMonth: 3,
            image: images.giftcardThree
        }
    }

    // MainOptions
    const { componentId = "", code = "" } = propsData;
    const navigationColor = user.profile === "family" ?  appTheme.homeBackgroundColor  :appTheme.schoolBackgroundColor;
    const mainOptions: MainOptions = new MainOptions({
        key: "user_gift_card",
        componentId,
        navigationColor,
        showNavBar: false,
        showMenu: false,
        canBeHidden: false,
        safeArea: false,
        canGoBack: true,
        backgroundColor: user.profile === "family" ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        canBeDismissed: false
    });

    const onViewDidAppear = () => {
        getUserCards();
    }

    const getUserCards = async () => {
        if ((user !== undefined) && (user.email !== undefined)) {
            const userGiftCardsList = await getUserGiftCards({ user_email: user.email });
            let listWithImage = [];
            for (const aCardIndex in userGiftCardsList) {
                if (Object.prototype.hasOwnProperty.call(userGiftCardsList, aCardIndex)) {
                    let aCard = userGiftCardsList[aCardIndex];
                    const imageIndex = Math.floor(Math.random() * 4);
                    const imagesArrray = [images.giftcardOne, images.giftcardTwo, images.giftcardThree, images.giftcardFour];
                    aCard.data.image = imagesArrray[imageIndex];
                    listWithImage.push(aCard);
                }
            }
            setGiftCardsList(listWithImage);
        }
    }

    useEffect(() => {
        if (giftCardsList.length > 0) {
            if (code.length > 0) {
                // On va vérifier que la carte cadeau est bien listée dans les cartes de l'utilisateur
                for (const aCardIndex in giftCardsList) {
                    if (Object.prototype.hasOwnProperty.call(giftCardsList, aCardIndex)) {
                        const aCard = giftCardsList[aCardIndex];
                        if (aCard.data.code === code) {
                            setGiftCardSelected(aCard);
                            break;
                        }
                    }
                }
            }
        }
    }, [giftCardsList])

    const getCardDetailsModalContent = () => {
        if (giftCardSelected === undefined) {
            return <View />
        }
        const { data, key } = giftCardSelected;
        const isCardValid = data.status === "toBeRedeemed" ? true : false;
        let cardTitle = isCardValid === true ? i18n.t("subCards.modal.valid.title") : i18n.t("subCards.modal.redeemed.title");
        let cardSubtitle = isCardValid === true ? i18n.t("subCards.modal.valid.body") : i18n.t("subCards.modal.redeemed.body");
        let activeButton = user.getPremiumStatus() !== "free" ? <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.talk, marginBottom: appTheme.pixelPerfect(20), textAlign: "center" }}>{i18n.t("subCards.modal.already")}</Text> : <Button
            isLoading={isLoading}
            onPress={() => redeemedCard({ isForUser: true, item: giftCardSelected })}
            title={i18n.t("subCards.modal.valid.redeemed")}
            style={{ width: appTheme.getBlockWidth(), marginBottom: appTheme.pixelPerfect(20) }} />;
        let closeButtonTitle = isCardValid === true ? i18n.t("subCards.modal.valid.nothingToDo") : i18n.t("subCards.modal.redeemed.close");
        let transferButtonTitle = i18n.t("subCards.modal.valid.transfer");
        let userEmailAddressView = <View />;
        if (transferCard === true) {
            cardTitle = i18n.t("subCards.modal.transfer.title");
            cardSubtitle = i18n.t("subCards.modal.transfer.body");
            activeButton = <View />;
            closeButtonTitle = i18n.t("subCards.modal.transfer.cancel");
            transferButtonTitle = i18n.t("subCards.modal.transfer.send");
            userEmailAddressView = <OTBTextInput placeholder={i18n.t("subCards.modal.transfer.email")} value={userEmailAddress} onChangeText={(text) => setUserEmailAddress(text)} inputWidth={appTheme.getBlockWidth()} />;
        }
        let actionsView = isCardValid === true ? <View style={{ marginTop: appTheme.pixelPerfect(20), width: appTheme.getFullAppWidth(), alignItems: "center" }}>
            {activeButton}
            {userEmailAddressView}
            <Button
                isLoading={isLoading}
                onPress={() => redeemedCard({ isForUser: false, item: giftCardSelected })}
                title={transferButtonTitle}
                style={{ width: appTheme.getBlockWidth(), marginBottom: appTheme.pixelPerfect(20) }} />
        </View> : <View />;
        return <View style={{ flex: 1, width: appTheme.getFullAppWidth(), backgroundColor: "#00000090", alignItems: "center", justifyContent: "flex-end" }}>
            <View style={[Style.shadowed, { paddingVertical: appTheme.pixelPerfect(30), paddingHorizontal: appTheme.pixelPerfect(20), width: appTheme.getFullAppWidth(), backgroundColor: appTheme.white, alignItems: "center", justifyContent: "flex-end", borderTopEndRadius: appTheme.pixelPerfect(10), borderTopStartRadius: appTheme.pixelPerfect(10) }]}>
                {getCardView(giftCardSelected)}
                <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue, paddingVertical: appTheme.pixelPerfect(10), textAlign: "center" }}>{cardTitle}</Text>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue, paddingBottom: appTheme.pixelPerfect(10), textAlign: "center" }}>{cardSubtitle}</Text>
                {actionsView}
                <Button
                    link={true}
                    isLoading={isLoading}
                    onPress={() => setGiftCardSelected(undefined)}
                    title={closeButtonTitle}
                    style={{ width: appTheme.getBlockWidth(), marginBottom: appTheme.pixelPerfect(20) }} />
            </View>
        </View>;
    }

    const redeemedCard = async (data: { isForUser: boolean, item: any }) => {
        const { isForUser, item } = data;
        setIsLoading(true);
        if (transferCard === true) {
            transferCardToNewAddress({ card: item });
        } else {
            try {
                if (isForUser === true) {
                    const giftDetails = giftCardsDescription[item.data.product_id];
                    await addSubToUser({
                        user_id: user.uid,
                        sub_id: item.data.product_id,
                        duration_in_month: giftDetails.durationInMonth
                    });
                    await redeemedCardInDb({ card: item, user: user });
                    alertRef.current?.showAlert({
                        title: "Abonnement PREMIUM activé !",
                        body: "Votre compte est maintenant PREMIUM! Bonne utilisation de LILI !",
                        onPressPrimaryButton: refreshCardsAfterAction
                    });
                } else {
                    setTransferCard(true);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
            }
        }
    }

    const refreshCardsAfterAction = () => {
        getUserCards();
        setIsLoading(false);
        setGiftCardSelected(undefined);
    }

    const transferCardToNewAddress = async (data: { card: any }) => {
        const { card } = data;
        try {
            await transfertCardToNewUser({ card, user_email: userEmailAddress.toLowerCase().trim() });
            alertRef.current?.showAlert({
                title: "",
                body: "Votre carte d'abonnement PREMIUM a été tranférée à l'adresse : " + userEmailAddress.toLowerCase().trim() + ".\nNous lui avons envoyé un e-mail pour le prevenir.",
                onPressPrimaryButton: refreshCardsAfterAction
            });
        } catch (error) {
            setIsLoading(false);
        }
    }

    const getCardView = (item) => {
        const { data, key } = item;
        const giftDetails = giftCardsDescription[data.product_id];
        let title = "Carte Premium";
        let duration = "-";
        let image = data.image !== undefined ? data.image : images.illPremiumCard;
        if (giftDetails !== undefined) {
            title = giftDetails.title;
            duration = giftDetails.duration;
        }
        const isCardValid = data.status === "toBeRedeemed" ? true : false;
        const overlayViewOpacity = isCardValid === true ? 0 : 0.9;
        const overlayView = <View style={{ alignItems: "flex-end", position: "absolute", width: appTheme.pixelPerfect(230), height: appTheme.pixelPerfect(160), backgroundColor: "#000000", opacity: overlayViewOpacity, borderRadius: appTheme.pixelPerfect(10) }} />;
        const badgeViewTitle = isCardValid === true ? data.code.toLocaleUpperCase() : i18n.t("subCards.card.used");
        const badgeView = <View style={{ alignItems: "center", justifyContent: "center", width: appTheme.pixelPerfect(100), height: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(10), backgroundColor: appTheme.white }}>
            <Text>{badgeViewTitle}</Text>
        </View>;
        return <View style={[Style.shadowed, { flexDirection: 'row', width: appTheme.pixelPerfect(230), height: appTheme.pixelPerfect(160), backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(10), alignItems: 'flex-end' }]}>
            <Image source={image} style={{ width: appTheme.pixelPerfect(230), height: appTheme.pixelPerfect(160), position: "absolute", borderRadius: appTheme.pixelPerfect(10) }} resizeMode="cover" />
            {overlayView}
            <View style={{ flexDirection: "row", position: "absolute", top: appTheme.pixelPerfect(10), left: 0, width: appTheme.pixelPerfect(230), justifyContent: "space-between", paddingHorizontal: appTheme.pixelPerfect(10) }}>
                <Text style={[{ color: appTheme.white, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(9), textTransform: "uppercase" }]}>
                    {duration}
                </Text>
                {badgeView}
            </View>
            <View style={{ width: appTheme.pixelPerfect(230), paddingHorizontal: appTheme.pixelPerfect(10), marginBottom: appTheme.pixelPerfect(10) }}>
                <Text style={[{ color: appTheme.white, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(8) }]}>
                    {title.toLocaleUpperCase()}
                </Text>
            </View>
        </View>
    }

    const aboutCell = ({ item, index }) => {
        const cellWidth = appTheme.getBlockWidth() / gridCellNumber;
        return (
            <TouchableOpacity
                accessibilityRole="button"
                accessible={true}
                accessibilityLabel={item.data.code}
                onPress={() => setGiftCardSelected(item)}
                style={{ width: cellWidth, paddingBottom: appTheme.pixelPerfect(10), justifyContent: 'center', alignItems: 'center' }}>
                {getCardView(item)}
            </TouchableOpacity>
        )
    }

    const getCardsList = () => {
        const color: string = user.profile === "family" ? appTheme.white : appTheme.darkBlue;
        if (giftCardsList.length === 0) {
            return <Text
                accessible={true}
                accessibilityLabel={i18n.t("subCards.no_cards")}
                accessibilityRole="text"
                style={[{ textAlign: 'center', fontSize: appTheme.pixelPerfectForFont(14) }, { color, paddingTop: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont }]}>{i18n.t("subCards.no_cards").toLocaleUpperCase()}</Text>
        }
        let cardListView = [];
        let cardListLine = [];
        for (const aCardIndex in giftCardsList) {
            if (Object.prototype.hasOwnProperty.call(giftCardsList, aCardIndex)) {
                let aCard = giftCardsList[aCardIndex];
                cardListLine.push(aboutCell({ item: aCard, index: aCardIndex }));
                if (cardListLine.length === gridCellNumber) {
                    cardListView.push(<View style={{ flexDirection: "row", width: appTheme.getBlockWidth() }}>
                        {cardListLine}
                    </View>);
                    cardListLine = [];
                }
            }
        }
        if (cardListLine.length > 0) {
            cardListView.push(<View style={{ flexDirection: "row", width: appTheme.getBlockWidth() }}>
                {cardListLine}
            </View>);
        }
        return cardListView;
    }

    
    const textColor: string = user.profile === "family" ? appTheme.white : appTheme.darkBlue;

    return (
        <Main mainOptions={mainOptions}>
            <DynamicBackground />
            <ScrollView style={{ flex: 1, width: appTheme.getFullScreenWidth() }}>
                <SafeAreaView onLayout={onViewDidAppear} style={{ flex: 1, alignItems: "center", marginTop: appTheme.pixelPerfect(10) }}>
                    <Image source={images.icPremium} style={{ width: appTheme.pixelPerfect(100), height: appTheme.pixelPerfect(100), borderRadius: appTheme.pixelPerfect(50) }} resizeMode="cover" />
                    <Text
                        accessible={true}
                        accessibilityLabel={i18n.t("subCards.title")}
                        accessibilityRole="text"
                        style={[{ textAlign: 'center', fontSize: appTheme.pixelPerfectForFont(14) }, { color: textColor, paddingTop: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont }]}>{i18n.t("subCards.title").toLocaleUpperCase()}</Text>
                    <View style={{ flex: 1, marginTop: appTheme.pixelPerfect(20) }}>
                        {getCardsList()}
                    </View>
                    <Modal
                        style={{ flex: 1 }}
                        transparent={true}
                        presentationStyle="overFullScreen"
                        animationType="slide"
                        visible={giftCardSelected !== undefined}>
                        <View style={{ flex: 1, width: appTheme.getFullScreenWidth(), alignItems: "center", justifyContent: "center" }}>
                            {getCardDetailsModalContent()}
                        </View>
                    </Modal>
                </SafeAreaView>
            </ScrollView>
            <Alert ref={alertRef} />
        </Main>

    )
}

export default UserGiftCards;