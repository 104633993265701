import React from 'react';
import { ScrollView, ScrollViewProps } from 'react-native';

const KeyboardAwareScrollView = (props: ScrollViewProps) => {

    return (
        <ScrollView {...props}>
            {props.children}
        </ScrollView>
    );
};

export default KeyboardAwareScrollView;
