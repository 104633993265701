import React, { useState, useEffect, useRef } from "react";

import {
    Dimensions,
    StyleSheet,
    Pressable,
    Text,
    ActivityIndicator,
    View
} from 'react-native';
import { useAudioPosition, useAudioPlayer } from "react-use-audio-player";

import PlayButton from "../../../shared/designSystem/PlayButton";
import AppTheme from "../../../shared/utils/Theme";

import H5AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import "./AudioPlayer.css"

import SVGView from "../../../shared/utils/SvgView";
import { default as IcPlay } from "../../../shared/assets/svg/icons/solid/play.svg";
import { default as IcPause } from "../../../shared/assets/svg/icons/solid/pause.svg";
import { handleUrl } from "../Deeplink/Deeplink";

let gamificationPointEarned = false;

const AudioPlayer = (props: any) => {

    const [offset, setOffset] = useState(0);

    const [songIndex, setSongIndex] = useState(0);
    const { urls, image, percentageForCompletion = 75, gamification = true, displayView = true, onPlayerEnd } = props;
    const [position, setPosition] = useState(-1);

    const onAudioPlayerEnd = (event) => {
        if (songIndex + 1 < urls.length) {
            setSongIndex(songIndex + 1);
        } else {
            if (onPlayerEnd !== undefined) {
                onPlayerEnd();
            }
        }
    };

    let { togglePlayPause, ready, loading, playing, stop, player, load } = useAudioPlayer({
        src: urls[songIndex],
        format: "mp3",
        autoplay: false,
        html5: true,
        onend: onAudioPlayerEnd
    });

    useEffect(() => {
        if (position > -1) {
            setTimeout(() => {
                if (audioPlayerRef.current?.isPlaying() === true) {
                    setPosition(audioPlayerRef.current?.audio.current?.currentTime);
                }
            }, 1000);
        }
    }, [position]);

    const { percentComplete, duration, seek } = useAudioPosition({ highRefreshRate: true });

    const appTheme: AppTheme = new AppTheme();
    let audioPlayerSize: number = 100;
    if (props.size !== undefined) {
        audioPlayerSize = props.size;
    };

    const audioPlayerRef = useRef<H5AudioPlayer>(null);

    useEffect(() => {
        gamificationPointEarned = false;
    }, []);

    useEffect(() => {
        if (props.stopRequest === true) {
            stop();
        }
    }, [props]);

    const checkIfPlayerIsReady = async () => {
        if (ready === true) {
            if (props.autoLaunch === true) {
                togglePlayPause();
            }
        } else {
            checkIfPlayerIsReady();
        }
    };

    const onAudioPlay = () => {
        if (audioPlayerRef !== undefined) {
            setPosition(audioPlayerRef.current?.audio.current?.currentTime);
        } else {
            setTimeout(() => {
                onAudioPlay();
            }, 1000);
        }
    };

    const getCircularProgressValue = () => {
        let duration = 0;
        let percent = 0;
        if (audioPlayerRef !== undefined) {
            duration = audioPlayerRef.current?.audio.current?.duration;
            if (duration > 0) {
                percent = position / duration;
            }
        };
        if (duration < 20) {
            return 0;
        };
        if (gamification === true) {
            if ((percent > percentageForCompletion) && (gamificationPointEarned === false)) {
                gamificationPointEarned = true;
                if (props.onActivityCompleted !== undefined) {
                    props.onActivityCompleted();
                }
            }
        };
        return percent * 360;
    };

    if (displayView === false) {
        return <View />;
    }

    const showProgress = duration > 20 ? true : false;
    const viewSize = audioPlayerSize;
    const playerIconSize = audioPlayerSize * 0.4;
    const playerIconContainerSize = audioPlayerSize * 0.7;


    // Svg Progress bar
    const progressBarSize = viewSize;
    const strokeWidth = audioPlayerSize * 0.1;
    let progress = getCircularProgressValue();
    const center = progressBarSize / 2;
    const radius = progressBarSize / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;

    useEffect(() => {
        const progressOffset = ((360 - progress) / 360) * circumference;
        setOffset(progressOffset);
    }, [setOffset, progress, circumference, offset])


    return (
        <div style={{ width: viewSize, height: viewSize, display: "flex", justifyContent: "center", alignItems: "center" }} >
            <svg
                className="circular-chart"
                width={progressBarSize}
                height={progressBarSize}
            >
                <circle
                    className="circular-bg"
                    stroke={showProgress === true ? "#eeeeee" : "#ffffff"}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                ></circle>
                <circle
                    className="circle"
                    stroke={showProgress === true ? props.color : "#ffffff"}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                ></circle>
            </svg>
            <H5AudioPlayer
                ref={audioPlayerRef}
                style={{ width: audioPlayerSize, height: audioPlayerSize, display: "flex", justifyContent: "center", alignItems: "center" }}
                layout="stacked"
                autoPlay={props.autoLaunch}
                loop={false}
                src={urls[songIndex]}
                onPlay={onAudioPlay}
                onEnded={onAudioPlayerEnd}
                customAdditionalControls={[]}
                customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
                customVolumeControls={[]}
                customProgressBarSection={[]}
                showJumpControls={false}
                showFilledProgress={false}
                customIcons={{
                    play: <div style={{ borderRadius: playerIconContainerSize / 2, width: playerIconContainerSize, height: playerIconContainerSize, backgroundColor: props.color, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <SVGView Component={IcPlay} width={playerIconSize} height={playerIconSize} color={appTheme.white} />
                    </div>,
                    pause: <div style={{ borderRadius: (playerIconContainerSize / 2), width: playerIconContainerSize, height: playerIconContainerSize, backgroundColor: props.color, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <SVGView Component={IcPause} width={playerIconSize} height={playerIconSize} color={appTheme.white} />
                    </div>
                }}
            />
        </div>
    )
}

export default AudioPlayer;