import React, { useEffect, useState } from "react";

import {
    Text,
    TouchableOpacity,
    View
} from "react-native";
import ComponentProps from "../../../../specific/utils/Interfaces/ComponentProps";
import AppTheme from "../../../utils/Theme";
import AuthButton from "../../../designSystem/Buttons/RoundButton";
import DynamicBackground from "../../DynamicBackground/DynamicBackground";
import { UserType } from "./Landing";
import { Image } from "react-native";
import Images from "../../../../specific/utils/Images";
import AuthHeaderStyle from "../../../styles/auth/AuthHeaderStyle";
import Superfont from "../../../utils/Superfont/Superfont";

interface AuthHeaderProps {
    type?: UserType,
    showTitle?: boolean
}

const AuthHeader = (props: AuthHeaderProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const authHeaderStyle: AuthHeaderStyle = new AuthHeaderStyle();

    const [titleElements, setTitleElements] = useState<string[]>(["Faire", "grandir la confiance", "des 3-12 ans"]);
    const [titleColor, setTitleColor] = useState<string>(appTheme.darkBlue);
    const [userTypeIcon, setUserTypeIcon] = useState(images.icLiliCircle);

    const { type = "neutral", showTitle = true } = props;

    const whiteBlockWidth = appTheme.pixelPerfect(100)

    useEffect(() => {
        if (props.type !== undefined) {
            if (props.type === "neutral") {
                setTitleElements(["Faire", "grandir la confiance", "des 3-12 ans"]);
                setTitleColor(appTheme.darkBlue);
                setUserTypeIcon(images.icLiliCircle);
            } else if (props.type === "family") {
                setTitleElements(["Libérer les", "super-pouvoirs", "de votre enfant"]);
                setTitleColor(appTheme.white);
                setUserTypeIcon(images.icLiliAtHomeCircle);
            } else if (props.type === "animator") {
                setTitleElements(["Apprendre à", "vivre ensemble", "en s'amusant"]);
                setTitleColor(appTheme.darkBlue);
                setUserTypeIcon(images.icLiliAtExtracurricularCircle);
            } else if (props.type === "teacher") {
                setTitleElements(["Améliorer le", "bien-être", "à l'école"]);
                setTitleColor(appTheme.darkBlue);
                setUserTypeIcon(images.icLiliAtSchoolCircle);
            }
        }
    }, [props]);

    const getLeftDetails = () => {
        if (props.type === "teacher") {
            return <View style={[authHeaderStyle.leftDetailsView, { width: whiteBlockWidth }]}>
                <Image source={images.icMinistere} style={{ width: appTheme.pixelPerfect(80), height: appTheme.pixelPerfect(80) }} resizeMode="contain" />
            </View>
        }
        return <View />
    }

    const getRightDetails = () => {
        if (props.type === "teacher") {
            return <View style={[authHeaderStyle.rightDetailsView, { width: whiteBlockWidth }]}>
                <Text style={{ color: appTheme.darkBlue, textTransform: "uppercase", fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFontRef(8), textAlign: "right" }}>Ressource TNE</Text>
                <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFontRef(6), textAlign: "right" }}>Les compétences psychosociales au service du climat scolaire</Text>
                <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.primaryMediumFont, fontSize: appTheme.pixelPerfectForFontRef(2), textAlign: "right" }}>Maternelle - Élémentaire - Collège</Text>
            </View>
        }
        return <View />
    }

    return (<View style={{ justifyContent: "center", alignItems: "center", marginBottom: appTheme.pixelPerfect(10), marginTop: appTheme.pixelPerfect(10) }}>
        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: appTheme.getFullScreenWidth() }}>
            {getLeftDetails()}
            <View style={{ flex: 1, alignItems: "center" }}>
                <Image source={userTypeIcon} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(60) }} />
                <View style={authHeaderStyle.titleContainer}>
                    <Text style={[authHeaderStyle.title, { color: titleColor }]}>{titleElements[0]}</Text>
                    <Text style={[authHeaderStyle.superHerostitle, { color: titleColor }]}>{titleElements[1]}</Text>
                    <Text style={[authHeaderStyle.title, { color: titleColor }]}>{titleElements[2]}</Text>
                </View>
            </View>
            {getRightDetails()}
        </View>
    </View>)

}

export default AuthHeader;