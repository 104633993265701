import { EventRegister } from "../../../shared/utils/EventListeners"
import * as Constants from "../../../shared/utils/Constants";
import { generatePath } from "react-router-dom";
import * as ScreenPath from "./ScreenPath";
import { getAppTarget } from "./Host";
import { getCurrentSession } from "../../services/AppWrite/AppWriteDatabase";
import DeepLink from "../../../shared/services/DeepLink/DeepLink";

const appTarget = getAppTarget();
const deepLink: DeepLink = DeepLink.getInstance();

export const relaunchPage = async (data: { componentId?: string, navigate?: any, passProps?: any, name: string, options?: any }) => {
    const { componentId = "", navigate, passProps = {}, name, options = {} } = data;
    const pathname = generatePath(ScreenPath.screenWebPath[name], passProps);
    navigate(pathname, { replace: true, state: { back: false} });
}


export const push = async (data: { componentId?: string, navigate?: any, passProps?: any, name: string, options?: any }) => {
    const { componentId = "", navigate, passProps = {}, name, options = {} } = data;
    if ((appTarget === "gar-prod") || (appTarget === "gar-preprod")) {
        // On va vérifier que l'utilisateur est connecté
        const currentSessiion = await getCurrentSession();
    }
    const pathname = generatePath(ScreenPath.screenWebPath[name], passProps);
    deepLink.last_urls.push(pathname);
    navigate(pathname, { replace: false, state: { back: false} });
}

export function pop(data: { componentId?: string, navigate?: any }) {
    const { componentId = "", navigate } = data;
    deepLink.last_urls.pop();
    navigate(-1, { state: { back: true } });
}

export function showModal(data: { componentId?: string, navigate?: any, passProps?: any, name: string, options?: any }) {
    const { componentId = "", navigate, passProps = {}, name, options = {} } = data;
    const pathname = generatePath(ScreenPath.screenWebPath[name], passProps);
    deepLink.last_urls.push(pathname);
    navigate(pathname, { replace: false });
}

export function dismissModal(data: { componentId?: string, navigate?: any }) {
    const { componentId = "", navigate = {} } = data;
    deepLink.last_urls.pop();
    navigate(-1, { state: { back: true } });
}

export function showModalInView(data: { componentId?: string, passProps?: any, name: string, options?: any }) {
    const { componentId = "", passProps = {}, name, options = {} } = data;
    let newProps = { ...passProps, componentId };
    EventRegister.emit(Constants.EventShowModal, { name, passProps: newProps });
}

export function dismissModalInView(data: { componentId?: string }) {
    EventRegister.emit(Constants.EventDismissmodal, {});
}

export function dismissAllModals(data: { componentId?: string, navigate?: any }) {
    const { navigate } = data;
    deepLink.last_urls = [];
    navigate(-1);
}

export function relaunchApp(data: { componentId?: string, navigate?: any }) {
    const { navigate } = data;
    deepLink.last_urls = [];
    navigate(ScreenPath.screenWebPath[Constants.ScreenSplashscreen], {replace:true});
}