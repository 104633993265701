import React, { useEffect, useState, useContext, useRef } from "react";

import {
    Text,
    View,
    Image,
    SafeAreaView,
    FlatList,
    TouchableOpacity,
    Platform,
    StyleSheet,
    ScrollView,
    ActivityIndicator,
    Linking,
    Animated,
    Modal,
    TextStyle
} from "react-native";

import Main from "../../../specific/components/Main/Main";
import DynamicBackground from "../DynamicBackground/DynamicBackground";
import MainOptions from "../../models/MainOptions";
import Images, { BundleImage } from "../../../specific/utils/Images";

import AppTheme from "../../utils/Theme";

import i18n from "../../utils/Localization/Localization";

import * as DateUtils from "../../utils/DateUtils";
import Alert from "../Notification/Alert";

import { formatCurrency } from "../../utils/Currency";

// DATA
import * as Constants from "../../utils/Constants";

// Services
import * as Navigation from "../../../specific/utils/Navigation/Navigation";

import User from "../../data/user/User";
import { getOfferings, purchaseProduct, treatPurchaserInfo, restorePurchase, createCheckoutSession, SubscriptionPackage } from "../../../specific/services/InAppPurchase/InAppPurchaseService";

import RadioButton from "../../designSystem/RadioButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../designSystem/Button";
import OtbImage from "../../../specific/utils/OtbImage/OtbImage";
import { analytcisEventPaywallOfferClicked, propertiesEventAmount, propertiesEventDuration, propertiesEventProfile, trackEvent } from "../../services/Tracking/Analytics";
import { cancelSubscriptionFromWeb } from "../../../specific/services/Subscriptions/Subscription";
import { getUserWithId } from "../../services/Database";
import { addPreSubToUser, addSubToUser, app, markSucessToSubToUser, removePreSubToUser } from "../../../specific/services/Specific_Database";

import SubscriptionDetails from "./SubscriptionDetails";
import { currentUser } from "../../services/Auth";
import SVGIconButton from "../../designSystem/Buttons/SVGIconButton";
import { default as IcBack } from "../../assets/svg/lili/ic_back_arrow.svg";
import { default as IcDismiss } from "../../assets/svg/icons/solid/times.svg";

const Subscription = (props: any) => {

    let propsData = props;
    if (Platform.OS === "web") {
        propsData = useParams();
    }


    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const userInstance: User = User.getInstance();
    const isTeacher = userInstance.isTeacher();

    const [showSubscriptionDetails, setShowSubscriptionDetails] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    let boxWidth = appTheme.getFullAppWidth() - appTheme.pixelPerfect(40);
    if (boxWidth > appTheme.pixelPerfect(400)) {
        boxWidth = appTheme.pixelPerfect(400);
    }

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

    const [user, setUser] = useState(userInstance.getUserData());
    const [products, setProducts] = useState<SubscriptionPackage[] | undefined>(undefined);
    const [packageReductions, setPackageReductions] = useState<any>({});
    const [selectedProduct, setSelectedProduct] = useState<SubscriptionPackage | undefined>(undefined);

    const [successError] = useState(false);
    const alertRef = useRef<Alert>(null);
    const boxStyle = {
        borderRadius: appTheme.pixelPerfect(10)
    };
    const boxTitleStyle: TextStyle = {
        textAlign: "center",
        fontSize: appTheme.pixelPerfectForFont(12),
        padding: appTheme.pixelPerfect(6),
        overflow: "hidden"
    };
    const boxTextStyle: TextStyle = {
        textAlign: "center",
        fontSize: appTheme.pixelPerfectForFont(10),
        paddingHorizontal: appTheme.pixelPerfect(10),
        paddingTop: appTheme.pixelPerfect(10)
    };

    // MainOptions
    const { componentId = "", isModal = false } = props;
    let navigate: any = null;
    let location: any = null;
    if (Platform.OS === "web") {
        location = useLocation();
        navigate = useNavigate();
    }
    let canUserGoBack = !isModal;
    const { status } = propsData;
    if ((status === "success") || (status === "cancel")) {
        canUserGoBack = false;
    }
    if (Platform.OS === "web") {
        if ((location !== null) && (location.pathname !== undefined)) {
            if (location.pathname.indexOf("selectSubscription") !== -1) {
                canUserGoBack = false;
            }
        }
    }
    const mainOptions: MainOptions = new MainOptions({
        key: "subscription",
        componentId,
        showNavBar: false,
        showMenu: false,
        canBeHidden: isModal,
        safeArea: false,
        canGoBack: canUserGoBack,
        backgroundColor: isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        navigationColor: isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        canBeDismissed: false
    });
    if ((Platform.OS === "web") && (props.onCloseRequest !== undefined)) {
        mainOptions.canBeHidden = false;
        mainOptions.rightButtonIcon = IcDismiss;
        mainOptions.rightButtonAction = () => props.onCloseRequest();
    }

    const onViewDidAppear = async () => {
        if (Platform.OS === "web") {
            const { status } = propsData;
            if (status === "success") {
                userInstance.successPreSub();
                checkUserSubStatus(1);
            } else if (status === "cancel") {
                await removePreSubToUser({ user_id: userInstance.uid });
                userInstance.removePreSub();
                getProducts();
            } else {
                getProducts();
            }
        } else {
            // On va récupérer les abonnements disponibles pour l'utilisateur
            getProducts();
        }
    }

    useEffect(() => {
        if (products !== undefined) {
            if (products.length > 0) {
                // On va regarder si on a un type et une durée d'abonnement présélectionnés
                const { type, duration } = propsData;
                let productSelected = false;
                if (userInstance.getPremiumStatus() === "free") {
                    if ((type !== undefined) && (duration !== undefined)) {
                        for (const aProductIndex in products) {
                            if (Object.prototype.hasOwnProperty.call(products, aProductIndex)) {
                                const aProduct = products[aProductIndex];
                                if ((aProduct.product.duration === 12) && (duration === "twelve")) {
                                    setSelectedProduct(aProduct);
                                    productSelected = true;
                                    onPurchaseRequest(aProduct);
                                    break;
                                } else if ((aProduct.product.duration === 1) && (duration === "one")) {
                                    setSelectedProduct(aProduct);
                                    productSelected = true;
                                    onPurchaseRequest(aProduct);
                                    break;
                                } else if ((aProduct.product.duration === 3) && (duration === "three")) {
                                    setSelectedProduct(aProduct);
                                    productSelected = true;
                                    onPurchaseRequest(aProduct);
                                    break;
                                }
                            }
                        }
                    }
                }
                if (productSelected === false) {
                    setViewDidAppear(true);
                }
            } else {
                setViewDidAppear(true);
            }
        }

    }, [products]);

    const getProducts = async () => {
        if (user.profile === "animator") {
            setProducts([]);
            setPackageReductions([]);
        } else {
            const inAppProducts: { package: SubscriptionPackage[], reductions: any } | undefined = await getOfferings();
            // On va classer les abonnements à montrer en fonction du type d'utilisateur
            setProducts(inAppProducts?.package);
            setPackageReductions(inAppProducts?.reductions);
        }
    }

    const goHome = () => {
        if (isTeacher === true) {
            navigate(Constants.ScreenAppMenu + "/" + Constants.CONTEXT.teacher);
        } else {
            navigate(Constants.ScreenAppMenu + "/" + Constants.CONTEXT.family);
        }
    }

    const checkUserSubStatus = async (count: number) => {
        // On va d'abord récupérer l'utilisateur connecté
        try {
            const { subId } = propsData;
            const loggerUser = await currentUser();
            if ((loggerUser !== null) && (loggerUser.uid !== undefined)) {
                const db_user = await getUserWithId({ user_id: loggerUser.uid, source: "server" });
                userInstance.setDBUserData(db_user);
                if (userInstance.getPremiumStatus() === "free") {
                    if (db_user.sub !== undefined) {
                        if (db_user.sub.pre_sub_id === subId) {
                            updatePreSub();
                            setIsLoading(false);
                        }
                    } else {
                        if (count < 10) {
                            setTimeout(() => {
                                checkUserSubStatus(count + 1);
                            }, 2000);
                        } else {
                            updatePreSub();
                            setIsLoading(false);
                        }
                    }
                } else {
                    getProducts();
                }
            } else {
                setIsLoading(false);
                Navigation.relaunchPage({ componentId, navigate, name: Constants.ScreenSubscription });
            }
        } catch (error) {
            setIsLoading(false);
            Navigation.relaunchPage({ componentId, navigate, name: Constants.ScreenSubscription });
        }
    }

    const updatePreSub = async () => {
        try {
            if ((userInstance.sub !== undefined) && (userInstance.sub.pre_expires_date !== undefined)) {
                try {
                    await addSubToUser({ user_id: userInstance.uid, sub_id: userInstance.sub.product_id, expiration_date: userInstance.sub.pre_expires_date });
                    Navigation.relaunchPage({ componentId, navigate, name: Constants.ScreenSubscription });
                } catch (error) {
                    Navigation.relaunchPage({ componentId, navigate, name: Constants.ScreenSubscription });
                }

            } else {
                Navigation.relaunchPage({ componentId, navigate, name: Constants.ScreenSubscription });
            }
        } catch (error) {
            Navigation.relaunchPage({ componentId, navigate, name: Constants.ScreenSubscription });
        }
    }

    const onQuoteRequest = async () => {
        try {
            const animatorQuoteForm = "https://periscolaire.lili.cool";
            const canOpenUrl = await Linking.canOpenURL(animatorQuoteForm);
            if (canOpenUrl === true) {
                Linking.openURL(animatorQuoteForm);
            } else {
                alertRef.current?.showAlert({
                    title: "Une erreur est survenue lors de l'ouverture du lien",
                    body: "Vous pouvez accéder à cette page sur : \n https://periscolaire.lili.cool"
                });
            }
        } catch (error) {
            alertRef.current?.showAlert({
                title: "Une erreur est survenue lors de l'ouverture du lien",
                body: "Vous pouvez accéder à cette page sur : \n https://periscolaire.lili.cool"
            });
        }
    }

    // Méthode d'appel quand un utilisateur demande un abonnement
    const onPurchaseRequest = async (aProduct?: SubscriptionPackage) => {
        if (aProduct !== undefined) {
            setIsLoading(true);
            trackEvent(analytcisEventPaywallOfferClicked, { [propertiesEventAmount]: aProduct.product.price, [propertiesEventDuration]: aProduct.packageType, [propertiesEventProfile]: userInstance.userProfile() });
            if (Platform.OS === "web") {
                // Création de la session et redirection vers le checkout
                preRecordSubScription(aProduct.product);
            } else {
                try {
                    //const purchaseAnswer = await purchasePackage(aProduct);
                    let purchaseAnswer = await purchaseProduct(aProduct);
                    const activeSub = treatPurchaserInfo(purchaseAnswer.customerInfo);
                    if (activeSub !== null) {
                        userInstance.addSubToUser(activeSub);
                    }
                    setUser(userInstance.getUserData());
                    setIsLoading(false);
                } catch (error) {
                    console.log({error, aProduct:JSON.stringify(aProduct)});
                    alertRef.current?.showAlert({
                        title: "Une erreur s'est produite",
                        body: "Si le problème persiste, n'hésitez pas à nous contacter : support@lili.cool"
                    });
                    setIsLoading(false);
                }
            }
        } else if (user.profile === "animator") {
            onQuoteRequest();
        }

    }

    const preRecordSubScription = async (product) => {
        const presubId: string = new Date().getTime().toString();
        const duration_in_month: number = ((product.identifier === "cool.lili.sub.laspremiumthree") || (product.identifier === "cool.lili.subnr.laspremiumthree")) ? 3 : 12;
        await addPreSubToUser({ user_id: userInstance.uid, sub_id: product.identifier, duration_in_month, presubId });
        await createCheckoutSession({ product, presubId });
        setIsLoading(false);
    }

    // V3 : Récupération des IAP
    const onRestorePurchaseRequest = async () => {
        if (Platform.OS === "web") {
            alertRef.current?.showAlert({
                title: "Retrouvez votre abonenement",
                body: "Vous avez un abonnement que vous avez souscris sur le web, et vous ne le voyez pas ici ? Contactez-nous apr e-mail : support@lili.cool"
            });
        } else {
            setIsLoading(true);
            try {
                const activeSub = await restorePurchase(userInstance.email);
                if (activeSub === null) {
                    // Alerte nous n'avons pas trouver d'abonnement en cours.
                    alertRef.current?.showAlert({
                        title: "Nous n'avons pas trouvé d'abonnement pour votre compte",
                        body: "Si vous pensez qu'il s'agit d'une erreur, n'hésitez pas à nous contacter : support@lili.cool"
                    });
                } else {
                    userInstance.addSubToUser(activeSub);
                    setUser(userInstance.getUserData());
                }
                setIsLoading(false);
            } catch (error) {
                alertRef.current?.showAlert({
                    title: "Une erreur s'est produite",
                    body: "Une erreur s'est produite lors de la vérification de votre compte. Si le problème persiste, n'hésitez pas à nous contacter : support@lili.cool."
                });
                setIsLoading(false);
            }
        }
    }

    const onResilisationRequest = () => {
        const userSub = userInstance.sub;
        const androidOrigins: string[] = ["android", "play_store"];
        const iOSOrigins: string[] = ["ios", "app_store"];
        const webOrigins: string[] = ["stripe", "web"];
        let canManageSubscription: boolean = false;
        const alertTitle: string = i18n.t('profile.unsubscribeTitle'); // Cas par défaut - cadeau de LILI
        let alertBody: string = i18n.t('profile.unsubscribeFromLili');
        let primaryButtonTitle: string = i18n.t('profile.showSubscriptionManagement');
        let secondaryButtonTitle: string = i18n.t('profile.unsubscribeCancel');
        if (iOSOrigins.indexOf(userSub.origin.toString().toLowerCase()) !== -1) {
            // On a un abonnement iOS
            if (Platform.OS === 'android') {
                alertBody = i18n.t('profile.unsubscribeAndroidFromiOSBody');
            } else {
                canManageSubscription = true;
                alertBody = i18n.t('profile.unsubscribeiOSBody');
            }
        } else if (androidOrigins.indexOf(userSub.origin.toString().toLowerCase()) !== -1) {
            // On a un abonnement Android
            if (Platform.OS === 'ios') {
                alertBody = i18n.t('profile.unsubscribeAndroidFromiOSBody');
            } else {
                canManageSubscription = true;
                alertBody = i18n.t('profile.unsubscribeAndroidBody');
            }
        } else if (webOrigins.indexOf(userSub.origin.toString().toLowerCase()) !== -1) {
            // On a un abonnement Stripe
            canManageSubscription = true;
            alertBody = i18n.t('profile.unsubscribeFromWeb');
            primaryButtonTitle = i18n.t('profile.unsubscribeConfirmation');
        }
        if (canManageSubscription === true) {
            alertRef.current?.showAlert({
                title: alertTitle,
                body: alertBody,
                primaryButtonTitle,
                onPressPrimaryButton: onManageSubscription,
                secondaryButtonTitle: secondaryButtonTitle
            });
        } else {
            alertRef.current?.showAlert({
                title: alertTitle,
                body: i18n.t('profile.unsubscribeFromLili')
            });
        }
    }

    const onManageSubscription = async () => {
        if (Platform.OS === "ios") {
            Linking.openURL('itms-apps://apps.apple.com/account/subscriptions');
        } else if (Platform.OS === "android") {
            Linking.openURL('https://play.google.com/store/account/subscriptions');
        } else if (Platform.OS === "web") {
            setIsLoading(true);
            try {
                const cancelRequestResponse = await cancelSubscriptionFromWeb({ userSub: userInstance.sub, user_id: userInstance.uid });
                if (cancelRequestResponse.date !== undefined) {
                    user.revokeRenwability();
                    alertRef.current?.showAlert({
                        title: i18n.t('profile.unsubscribeSuccessTitle'),
                        body: i18n.t('profile.unsubscribeSuccessBody', { date: DateUtils.getFullDateForTimestamp(cancelRequestResponse.date) })
                    });
                } else {
                    alertRef.current?.showAlert({
                        title: i18n.t('profile.unsubscribeErrorTitle'),
                        body: i18n.t('profile.unsubscribeErrorBody')
                    });
                }
                setIsLoading(false);
            } catch (error) {
                alertRef.current?.showAlert({
                    title: i18n.t('profile.unsubscribeErrorTitle'),
                    body: i18n.t('profile.unsubscribeErrorBody')
                });
                setIsLoading(false);
            }
        }
    }

    const toggleSubDescription = () => {
        setShowSubscriptionDetails(true);
    }

    const getPageHeader = () => {
        // On définit les éléments pour une famille. On les changera si c'est un prof ou un animateur
        let headerImage: number | BundleImage = images.icLiliAtHomeCircle;
        let headerTitle: string = userInstance.getPremiumStatus() === "free" ? i18n.t("subscription.parent.title") : i18n.t("subscription.parent.subscribed.title");
        let titleColor: string = appTheme.white;
        if (user.profile === "teacher") {
            headerImage = images.icLiliAtSchoolCircle;
            headerTitle = userInstance.getPremiumStatus() === "free" ? i18n.t("subscription.teacher.title") : "Votre offre";;
            titleColor = appTheme.darkBlue;
        } else if (user.profile === "animator") {
            headerImage = images.icLiliAtExtracurricularCircle;
            headerTitle = userInstance.getPremiumStatus() === "free" ? i18n.t("subscription.animator.title") : "Votre offre";;
            titleColor = appTheme.darkBlue;
        }
        return <View style={{ flex: 1, alignItems: "center", paddingTop: appTheme.pixelPerfect(10) }}>
            <OtbImage source={headerImage} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(60), borderRadius: appTheme.pixelPerfect(30) }} />
            <Text
                accessible={true}
                accessibilityLabel={headerTitle}
                accessibilityRole="text"
                style={[{ textAlign: 'center' }, { color: titleColor, padding: appTheme.pixelPerfect(10), fontSize: appTheme.pixelPerfectForFont(9) }]}>
                {headerTitle.toLocaleUpperCase()}
            </Text>
        </View>
    }

    const getMainContent = () => {
        let mainBackgroundColor: string = appTheme.homeColor;
        let offerTitle = user.getPremiumStatus() === "free" ? i18n.t("subscription.parent.offers.title") : i18n.t("subscription.parent.offers.title");
        let offerBody = user.getPremiumStatus() === "free" ? i18n.t("subscription.parent.offers.body") : i18n.t("subscription.parent.subscribed.body");
        let getMoreDetailsView = <TouchableOpacity onPress={toggleSubDescription} style={{ padding: appTheme.pixelPerfect(10) }}>
            <Text style={[boxTextStyle, { paddingTop: 0, color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, textDecorationLine: "underline", marginBottom: appTheme.pixelPerfect(10) }]}>
                {i18n.t("subscription.buttons.more")}
            </Text>
        </TouchableOpacity>;
        if (user.profile === "teacher") {
            mainBackgroundColor = appTheme.schoolColor;
            offerTitle = user.getPremiumStatus() === "free" ? i18n.t("subscription.teacher.offers.title") : i18n.t("subscription.teacher.offers.title");
            offerBody = user.getPremiumStatus() === "free" ? i18n.t("subscription.teacher.offers.body") : i18n.t("subscription.teacher.subscribed.body");
        } else if (user.profile === "animator") {
            //getMoreDetailsView = <View />;
            mainBackgroundColor = appTheme.schoolColor;
            offerTitle = user.getPremiumStatus() === "free" ? i18n.t("subscription.animator.offers.title") : i18n.t("subscription.animator.offers.title");
            offerBody = user.getPremiumStatus() === "free" ? i18n.t("subscription.animator.offers.body") : i18n.t("subscription.animator.subscribed.body");
        }

        const detailsContent = user.getPremiumStatus() === "free" ? getFreeUserContent() : getPremiumUserContent();
        return <View style={{ alignItems: "center" }}>
            <View style={[boxStyle, { width: boxWidth, backgroundColor: mainBackgroundColor }]}>
                <Text style={[boxTitleStyle, { color: appTheme.white, fontFamily: appTheme.primaryBoldFont, textTransform: "uppercase" }]}>{offerTitle}</Text>
                <View style={{ backgroundColor: appTheme.white, borderBottomEndRadius: appTheme.pixelPerfect(10), borderBottomStartRadius: appTheme.pixelPerfect(10), paddingBottom: appTheme.pixelPerfect(10) }}>
                    <Text style={[boxTextStyle, { color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont }]}>
                        {offerBody}
                    </Text>
                    {getMoreDetailsView}
                    <View style={{ alignItems: "center" }}>
                        {detailsContent}
                    </View>
                </View>
            </View>
        </View>
    }

    const getPremiumUserContent = () => {
        let renewSubs = [
            "cool.lili.sub.laspremium",
            "cool.lili.sub.lasessential",
            "cool.lili.sub.sevendaysoneyear",
            "cool.lili.sub.sevendaysonemonth",
            "cool.lili.sub.threemonths",
            "cool.lili.sub.onemonth",
            "cool.lili.sub.oneyear",
            "cool.lili.sub.laspremiumtwelve",
            "cool.lili.sub.laspremiumthree"
        ];
        let teacherOfferProductIds = [
            "cool.lili.sub.laspremium",
            "cool.lili.sub.lasessential",
            "cool.lili.sub.laspremiumtwelve",
            "cool.lili.sub.laspremiumthree",
            "cool.lili.subnr.laspremiumtwelve",
            "cool.lili.subnr.laspremiumthree"
        ];
        let parentsOfferProductIds = [
            "cool.lili.sub.sevendaysoneyear",
            "cool.lili.sub.sevendaysonemonth",
            "cool.lili.sub.threemonths",
            "cool.lili.sub.onemonth",
            "cool.lili.sub.oneyear",
            "cool.lili.gift.oneyear",
            "cool.lili.gift.onemonth",
            "cool.lili.gift.threemonths"
        ];
        let durationByOffers = {
            "cool.lili.sub.laspremium": "12 mois",
            "cool.lili.sub.lasessential": "12 mois",
            "cool.lili.sub.sevendaysoneyear": "Annuel",
            "cool.lili.sub.sevendaysonemonth": "Mensuel",
            "cool.lili.sub.threemonths": "3 mois",
            "cool.lili.sub.onemonth": "Mensuel",
            "cool.lili.sub.oneyear": "Annuel",
            "cool.lili.sub.laspremiumtwelve": "12 mois",
            "cool.lili.sub.laspremiumthree": "3 mois",
            "cool.lili.subnr.laspremiumtwelve": "12 mois",
            "cool.lili.subnr.laspremiumthree": "3 mois",
            "cool.lili.gift.oneyear": "12 mois",
            "cool.lili.gift.onemonth": "1 mois",
            "cool.lili.gift.threemonths": "3 mois"
        };
        // Nouvelle version
        let blockTitle: string = i18n.t("subscription.teacher.subscribed.title");
        let subscriptionTitle: string = i18n.t("subscription.teacher.subscribed.duration");
        let dateTitle: string = "Date de fin";
        let actionsView = <View />; // Boutons d'actions pour annuler son abonnement
        if (user.profile === "family") {
            blockTitle = i18n.t("subscription.parent.subscribed.title");
            subscriptionTitle = i18n.t("subscription.parent.subscribed.duration");
        } else if (user.profile === "animator") {
            blockTitle = i18n.t("subscription.animator.subscribed.title");
            subscriptionTitle = i18n.t("subscription.animator.subscribed.duration");
        }
        // Ancienne version
        let mainBackgroundColor: string = appTheme.homeColor;
        let offersBackgroundColor: string = mainBackgroundColor + "30";
        let durationTitle = "Durée de votre version Premium";
        let showTeacherPremiumBody = false;
        let legalMention = "Votre abonnement ne sera pas reconduit automatiquement à cette date.";
        if (teacherOfferProductIds.indexOf(userInstance.sub.product_id) !== -1) {
            legalMention = "Votre licence ne sera pas automatiquement reconduite à cette date.";
            showTeacherPremiumBody = true;
        } else if (parentsOfferProductIds.indexOf(userInstance.sub.product_id) !== -1) {
            durationTitle = i18n.t("subscription.parent.subscribed.duration");
        }

        if (renewSubs.indexOf(userInstance.sub.product_id) !== -1) {
            if (userInstance.sub.period_type === "TRIAL") {
                dateTitle = "Date de fin de votre période d'essai";
                legalMention = "Vous bénéficiez d'une période d'essai gratuite. Votre abonnement commencera à la date indiquée ci-dessus.";
                if (userInstance.sub.auto_renewing === true) {
                    legalMention = "Vous bénéficiez d'une période d'essai gratuite. Votre abonnement commencera à la date indiquée ci-dessus.";
                    actionsView = <View style={[{ width: boxWidth, backgroundColor: appTheme.white, alignItems: "center", marginVertical: appTheme.pixelPerfect(10) }]}>
                        <Button link={true} onPress={onResilisationRequest} title={i18n.t("subscription.buttons.unsubscribe")} textStyle={{ color: appTheme.darkBlue }} style={{ width: boxWidth, backgroundColor: appTheme.white }} />
                    </View>;
                } else {
                    legalMention = "Vous bénéficiez d'une période d'essai gratuite, que vous avez annulé. À la date indiquée ci-dessus, vous n'aurez plus accès aux contenus Premium.";
                }
            } else if (userInstance.sub.auto_renewing === true) {
                legalMention = "Vous pouvez résilier votre abonnement à tout moment d’ici sa date de fin. Si non, il sera automatiquement reconduit à l’issue de sa date de fin.";
                actionsView = <View style={[{ width: boxWidth, backgroundColor: appTheme.white, alignItems: "center", marginVertical: appTheme.pixelPerfect(10) }]}>
                    <Button link={true} onPress={onResilisationRequest} title={i18n.t("subscription.buttons.unsubscribe")} textStyle={{ color: mainBackgroundColor }} style={{ width: boxWidth, backgroundColor: appTheme.white }} />
                </View>;
            }
        }

        let showActionViewSuccessAndRedirect = false;
        let buttonTitle = "";
        if (status === "success") {
            showActionViewSuccessAndRedirect = true;
            buttonTitle = i18n.t("subscription.buttons.startWithPremium");
        }
        if (Platform.OS === "web") {
            if ((location !== null) && (location.pathname !== undefined)) {
                if (location.pathname.indexOf("selectSubscription") !== -1) {
                    showActionViewSuccessAndRedirect = true;
                    buttonTitle = i18n.t("subscription.buttons.goBackHome");
                }
            }
        }

        let legalMentionView = <Text style={{ fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "center", paddingTop: appTheme.pixelPerfect(10) }}>{legalMention}</Text>;
        let endOfSubView = <View style={{ width: appTheme.pixelPerfect(130), alignItems: "flex-end" }}>
            <Text style={{ fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10) }}>{DateUtils.getFullDateForTimestamp(userInstance.sub.expires_date)}</Text>
        </View>;
        if (user.getPremiumStatus() === "pre-sub") {
            endOfSubView = <View />;
            legalMentionView = <View />;
        }
        return <View style={{ alignItems: "center" }}>
            <View style={{ width: boxWidth - appTheme.pixelPerfect(20), paddingHorizontal: appTheme.pixelPerfect(10), paddingVertical: appTheme.pixelPerfect(5), backgroundColor: offersBackgroundColor, marginBottom: appTheme.pixelPerfect(5), borderRadius: appTheme.pixelPerfect(10) }}>
                <Text style={{ flex: 1, fontFamily: appTheme.primaryMediumFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10) }}>
                    {blockTitle}
                </Text>
                <View style={{ flexDirection: "row", marginTop: appTheme.pixelPerfect(10) }}>
                    <Text style={{ flex: 1, fontFamily: appTheme.primaryMediumFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(9) }}>
                        {subscriptionTitle}
                    </Text>
                    <Text style={{ textAlign: "right", fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(9) }}>
                        {durationByOffers[userInstance.sub.product_id]}
                    </Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: appTheme.pixelPerfect(5) }}>
                    <Text style={{ flex: 1, fontFamily: appTheme.primaryMediumFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10) }}>{dateTitle}</Text>
                    {endOfSubView}
                </View>
                {legalMentionView}
            </View>
            {actionsView}
        </View>;
    }

    const getFreeUserContent = () => {
        let mainBackgroundColor: string = appTheme.homeColor;
        let legalMentions = i18n.t("subscription.parent.legalMentions");
        let subscriptionButtonTitle = i18n.t("subscription.parent.subscribe");
        let restoreButtonTitle = i18n.t("subscription.parent.restore");
        if (user.profile === "teacher") {
            mainBackgroundColor = appTheme.schoolColor;
            legalMentions = i18n.t("subscription.teacher.legalMentions");
            subscriptionButtonTitle = i18n.t("subscription.teacher.subscribe");
            restoreButtonTitle = i18n.t("subscription.teacher.restore");
        } else if (user.profile === "animator") {
            mainBackgroundColor = appTheme.schoolColor;
            legalMentions = i18n.t("subscription.teacher.legalMentions");
            subscriptionButtonTitle = i18n.t("authentication.animator.introduction.buttons.main")
            restoreButtonTitle = i18n.t("subscription.teacher.restore");
        }
        let offersBackgroundColor: string = mainBackgroundColor + "30";
        let subOptionsView = [];
        for (const aSubOptionIndex in products) {
            if (Object.prototype.hasOwnProperty.call(products, aSubOptionIndex)) {
                const aPackage: SubscriptionPackage = products[parseInt(aSubOptionIndex)];
                let isSelected: boolean = false;
                let borderColor: string = mainBackgroundColor + "40";
                let borderWidth: number = 0;
                if ((selectedProduct !== undefined) && (selectedProduct.identifier !== undefined)) {
                    if (selectedProduct.identifier === aPackage.identifier) {
                        borderWidth = 2;
                        isSelected = true;
                        borderColor = appTheme.darkBlue;
                    }
                }
                let reductionView = <View />;
                if (packageReductions[aPackage.packageType] !== undefined) {
                    borderWidth = 2;
                    reductionView = <View style={{ position: "absolute", top: appTheme.pixelPerfect(-6), borderRadius: appTheme.pixelPerfect(4), right: appTheme.pixelPerfect(10), backgroundColor: mainBackgroundColor, paddingHorizontal: appTheme.pixelPerfect(10) }}>
                        <Text style={{ textAlign: "center", color: appTheme.white, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(4) }}>
                            {"Économisez " + packageReductions[aPackage.packageType].toString() + " %"}
                        </Text>
                    </View>
                }
                let productDescriptionComplement = <View />;
                if ((aPackage.identifier === "$rc_annual") && (user.profile === "family")) {
                    productDescriptionComplement = <Text style={{ textAlign: "center", color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(7), marginTop: appTheme.pixelPerfect(5) }}>
                        {"prélèvement annuel"}
                    </Text>
                }
                if (user.profile === "teacher") {
                    productDescriptionComplement = <Text style={{ textAlign: "center", color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(7), marginTop: appTheme.pixelPerfect(5) }}>
                        {aPackage.product.description}
                    </Text>
                }
                const [valueFormattedWithSymbol] = formatCurrency({ amount: aPackage.product.price.toFixed(2), code: aPackage.product.currencyCode })
                subOptionsView.push(<View style={{ borderWidth, borderColor, width: boxWidth - appTheme.pixelPerfect(20), paddingHorizontal: appTheme.pixelPerfect(10), paddingVertical: appTheme.pixelPerfect(5), backgroundColor: offersBackgroundColor, marginBottom: appTheme.pixelPerfect(5), borderRadius: appTheme.pixelPerfect(10) }}>
                    <RadioButton value={isSelected} style={{ width: boxWidth - appTheme.pixelPerfect(20) }} centerButton={true} onPress={() => setSelectedProduct(aPackage)}>
                        <View style={{ flex: 1, alignItems: "center", paddingEnd: appTheme.pixelPerfect(20) }}>
                            <Text style={{ textAlign: "center", color: appTheme.darkBlue, fontFamily: appTheme.primaryMediumFont, fontSize: appTheme.pixelPerfectForFont(10) }}>
                                {aPackage.product.title}
                            </Text>
                            <Text style={{ textAlign: "center", color: appTheme.darkBlue, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(10), marginTop: appTheme.pixelPerfect(5) }}>
                                {valueFormattedWithSymbol + " TTC"}
                            </Text>
                            {productDescriptionComplement}
                        </View>
                    </RadioButton>
                    {reductionView}
                </View>)
            }
        }
        if (successError === true) {
            return <View style={{ flex: 1, alignItems: "center", paddingVertical: appTheme.pixelPerfect(10) }}>
                <View style={[boxStyle, { width: boxWidth, backgroundColor: mainBackgroundColor, overflow: "hidden" }]}>
                    <View style={{ alignItems: "center", backgroundColor: appTheme.white, paddingBottom: appTheme.pixelPerfect(20) }}>
                        <Text style={[boxTextStyle, { color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont }]}>
                            Votre paiement a bien été enregistré. Votre compte va être mise à jour avec votre abonnement.
                        </Text>
                        <Text style={[boxTextStyle, { color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont }]}>
                            En cas de problème, n'hésitez pas à nous contacter : support@lili.cool
                        </Text>
                    </View>
                </View>
            </View>;
        }
        return <View style={{ flex: 1, alignItems: "center" }}>
            <View style={[boxStyle, { width: boxWidth, backgroundColor: mainBackgroundColor }]}>
                <View style={{ backgroundColor: appTheme.white, borderBottomEndRadius: appTheme.pixelPerfect(10), borderBottomStartRadius: appTheme.pixelPerfect(10), paddingBottom: appTheme.pixelPerfect(10) }}>
                    <View style={{ alignItems: "center" }}>
                        {subOptionsView}
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={[{ width: boxWidth, backgroundColor: appTheme.white, alignItems: "center", marginTop: appTheme.pixelPerfect(10) }]}>
                            <Button disabled={((selectedProduct === undefined) && (user.profile !== "animator"))} onPress={() => onPurchaseRequest(selectedProduct)} title={subscriptionButtonTitle} style={{ width: appTheme.pixelPerfect(200), backgroundColor: mainBackgroundColor }} />
                        </View>
                        <View style={[{ width: boxWidth, backgroundColor: appTheme.white, alignItems: "center", marginTop: appTheme.pixelPerfect(10) }]}>
                            <Button onPress={onRestorePurchaseRequest} title={restoreButtonTitle} textStyle={{ color: mainBackgroundColor }} style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.white, borderWidth: 1, borderColor: mainBackgroundColor }} />
                        </View>
                        <Text style={{ textAlign: "center", color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(7), marginTop: appTheme.pixelPerfect(5), marginBottom: appTheme.pixelPerfect(10) }}>
                            {legalMentions}
                        </Text>
                    </View>
                </View>
            </View>
        </View >
    }

    const getLoadingView = () => {
        if (isLoading === false) {
            return <View />
        }
        return <View style={{ position: "absolute", flex: 1, alignItems: "center" }}>
            <View style={{ width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight(), backgroundColor: "#FFFFFFBB", justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator color={appTheme.darkBlue} />
            </View>
        </View>
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ flex: 1, alignItems: "center" }}>
                <View style={{ width: appTheme.getFullScreenWidth(), height: appTheme.getFullScreenHeight(), backgroundColor: "#FFFFFFBB", justifyContent: "center", alignItems: "center" }}>
                    <ActivityIndicator color={appTheme.darkBlue} />
                </View>
            </View>
        }
        let cancelBackButton = <View />;
        if (status === "cancel") {
            let mainBackgroundColor: string = appTheme.homeColor;
            if (user.profile === "teacher") {
                mainBackgroundColor = appTheme.schoolColor;
            } else if (user.profile === "animator") {
                mainBackgroundColor = appTheme.schoolColor;
            }
            cancelBackButton = <View style={{ position: "absolute", top: appTheme.pixelPerfect(10), left: appTheme.pixelPerfect(10) }}>
                <SVGIconButton icon={IcBack} onPress={goHome} color={mainBackgroundColor} />
            </View>
        }
        return <SafeAreaView onLayout={onViewDidAppear} style={{ flex: 1, alignItems: "center" }}>
            <ScrollView
                showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
                {getPageHeader()}
                {getMainContent()}
            </ScrollView>
            {cancelBackButton}
        </SafeAreaView>
    }

    return (
        <Main mainOptions={mainOptions}>
            <DynamicBackground />
            {getContent()}
            <Alert ref={alertRef} />
            <Modal
                style={{ flex: 1, width: appTheme.getFullScreenWidth(), justifyContent: 'center', alignItems: "center" }}
                transparent={true}
                animationType={Platform.OS === "web" ? "fade" : "slide"}
                visible={showSubscriptionDetails}>
                <SubscriptionDetails onCloseRequest={() => setShowSubscriptionDetails(false)} />
            </Modal>
            {getLoadingView()}
        </Main>
    )
}

export default Subscription;