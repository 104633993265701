import { Platform } from 'react-native';
import { Adjust, AdjustEvent, AdjustConfig } from "../../../specific/services/Tracking/Adjust";
import Amplitude from "../../../specific/services/Tracking/Amplitude";
import { getLocalStorage } from '../../../specific/utils/LocalStorage';
import * as Constants from "../../utils/Constants";
import { Purchases } from "../../../specific/services/InAppPurchase/InAppPurchaseService";

// EVENT NAME
export const analyticsEventHpViewed = "hp_viewed";
export const analyticsEventHpLoungeClicked = "hp_lounge_clicked";
export const analyticsEventSectionWelnessClicked = "section_welness_clicked";
export const analyticsEventSectionTrainingPhiloClicked = "section_training_philo_clicked";
export const analyticsEventCategoryViewed = "category_viewed";

export const analyticsEventActivityLaunch = "activity_launched";
export const analyticsEventActivityBookmarked = "activity_bookmarked";
export const analyticsEventLogIn = "log_in";
export const analyticsEventMediaLaunch = "media_launch";
export const analyticsEventParentProfile = "parent_profile";
export const analyticsEventShareActivity = "activity_shared";
export const analyticsEventTeacherProfile = "teacher_profile";
export const analyticsEventSignInCompleted = "signin_completed";
export const analyticsEventHpSuperPowerClicked = "hp_super_power_clicked";
export const analyticsEventHpSuperpowersPosterClicked = "hp_super_power_poster_clicked";
export const analyticsEventHpSectionClicked = "hp_section_clicked";
export const analyticsEventHpTrainingPathClicked = "hp_training_path_clicked";
export const analyticsEventHpSleepMeditationsClicked = "hp_sleep_meditations_clicked";
export const analyticsEventHpSuperpowersClicked = "hp_poster_superpowers_clicked";
export const analyticsEventHpParentsResourcesClicked = "hp_parents_resources_clicked";
export const analyticsEventHpWellBeingResourcesClicked = "hp_wellbeing_resources_clicked";
export const analyticsEventActivityClicked = "activity_clicked";
export const analyticsEventActivityCompleted = "activity_completed";
export const analyticsEventActivityDownloaded = "activity_downloaded";
export const analyticsEventBadgeActivated = "badge_activated";
export const analyticsEventBadgeDownloaded = "badge_downloaded";
export const analyticsEventWorkshopLaunched = "workshop_launched";
export const analyticsEventWorkshopCompleted = "workshop_completed";
export const analyticsEventPaywallDisplayed = "paywall_displayed";
export const analytcisEventPaywallOfferClicked = "paywall_offer_clicked";
export const analyctisEventPageViewed = "page_viewed";

// EVENT PROPERTIES
export const propertiesEventHpLoungeStartingPoint = "starting_point";
export const propertiesValueHpLoungeStartingPointSuperpower = "superpower";
export const propertiesValueHpLoungeStartingPointSection = "section";

export const propertiesEventTeacherGradeLevel = "teacher_grade_level";
export const propertiesEventTeacherDepartment = "teacher_department";
export const propertiesEventSuperPower = "super_power";
export const propertiesEventProfile = "profile";
export const propertiesEventContext = "context";
export const propertiesEventSource = "source";
export const propertiesEventName = "name";
export const propertiesEventCategory = "category";
export const propertiesEventSection = "section";
export const propertiesEventContent = "content";
export const propertiesEventContentName = "content_name";
export const propertiesEventAccess = "access";
export const propertiesEventCompleted = "completed";
export const propertiesEventAmount = "amount";
export const propertiesEventDuration = "duration";
export const propertiesEventScreenLabel = "screen_label";
export const propertiesMediaKey = "media";

// ADJUST
const adjustAppToken = "oegkd79o21vk"
const adjustEventKeys = {
    [analyticsEventActivityLaunch]: "dvc7bk",
    [analyticsEventActivityBookmarked]: "tfzf9j",
    [analyticsEventLogIn]: "8ou34y",
    [analyticsEventMediaLaunch]: "fgber5",
    [analyticsEventParentProfile]: "n59jgb",
    [analyticsEventPaywallDisplayed]: "xqff0r",
    [analyticsEventShareActivity]: "676bat",
    [analyticsEventTeacherProfile]: "1i0cjs"
}

const amplitudeEventKeys = [
    analyticsEventHpViewed,
    analyticsEventHpLoungeClicked,
    analyticsEventCategoryViewed,
    analyticsEventSectionTrainingPhiloClicked,
    analyticsEventSectionWelnessClicked,
    analyticsEventSignInCompleted,
    analyticsEventHpSuperPowerClicked,
    analyticsEventHpTrainingPathClicked,
    analyticsEventHpSleepMeditationsClicked,
    analyticsEventHpParentsResourcesClicked,
    analyticsEventHpWellBeingResourcesClicked,
    analyticsEventHpSuperpowersPosterClicked,
    analyticsEventActivityLaunch,
    analyticsEventActivityCompleted,
    analyticsEventActivityDownloaded,
    analyticsEventActivityBookmarked,
    analyticsEventBadgeActivated,
    analyticsEventBadgeDownloaded,
    analyticsEventHpSectionClicked,
    analyticsEventWorkshopLaunched,
    analyticsEventWorkshopCompleted,
    analyticsEventPaywallDisplayed,
    analytcisEventPaywallOfferClicked,
    analyctisEventPageViewed
]

// AMPLITUDE
const isProd = true;
const amplitudeAPIKey = isProd === true ? "9605d8b096477137a6d9bdc4b67cb375" : "32ce861dbe3424f284ea1823986ae1c5";

export const deleteAnalyticsUserData = async () => {
    // await Batch.optOutAndWipeData();
}

export const consentActivation = async (doesConsent: boolean) => {
    if (doesConsent === true) {
        //await Batch.optIn();
        //Amplitude.setOptOut(false);
    } else {
        //await Batch.optOut();
        //Amplitude.setOptOut(true);
    }
}

export const initAnalyticsServices = async () => {
    // Amplitude est activé quelque soit le consentement, car nous l'avons défini ainsi dans les 
    // règles de confidentialités
    Amplitude.init(amplitudeAPIKey, undefined, {
        serverZone: 'EU',
        logLevel: Amplitude.Types.LogLevel.Debug,
        trackingOptions: {
            adid: false
        }
    });
    const userConsent = await getLocalStorage({ key: Constants.keyGdprConsent });
    // Adjust n'est activé que si l'utilisateur a accepté la demande de consentement
    if (userConsent !== null) {
        const userConsentData = JSON.parse(userConsent);
        if (userConsentData.consent === true) {
            // Adjust
            if (Platform.OS === "web") {
                Adjust.initSdk({
                    appToken:adjustAppToken,
                    environment:"production"
                });
            } else {
                const adjustEnvironment = isProd === true ? AdjustConfig.EnvironmentProduction : AdjustConfig.EnvironmentSandbox;
                const adjustConfig = new AdjustConfig(adjustAppToken, adjustEnvironment);
                Adjust.create(adjustConfig);
                Adjust.getAdid((adjustId: string) => {
                    if (adjustId !== undefined) {
                        Purchases.setAdjustID(adjustId);
                    }
                });
                if (Platform.OS === "ios") {
                    Adjust.getAppTrackingAuthorizationStatus((status) => {
                        if (status === 3) {
                            Purchases.collectDeviceIdentifiers();
                        }
                    });
                } else {
                    Purchases.collectDeviceIdentifiers();
                }
            }
        }
    }
}

export const setUserProperty = async (data: { key: string, value: string, once?: boolean }) => {
    const { key, value, once = false } = data;
    const identifyObject = Platform.OS === "web" ? new Amplitude.Identify() : new Amplitude.Identify();
    if (once === true) {
        identifyObject.setOnce(key, value);
    } else {
        identifyObject.set(key, value);
    }
    Amplitude.identify(identifyObject);
}

export const initUserIdForAnalytics = async (user_id: string) => {
    // Amplitude
    Amplitude.setUserId(user_id);
}

export const revokeAnalyticsServices = () => {
    Adjust.componentWillUnmount();
}

export const trackEvent = async (eventName: string, metadata?: any) => {
    if (metadata !== undefined) {
        Amplitude.track(eventName, metadata);
    } else {
        Amplitude.track(eventName);
    }

    // Adjust
    const adjustKey: string = adjustEventKeys[eventName];
    if (adjustKey !== undefined) {
        if (Platform.OS !== "web") {
            const adjustEvent = new AdjustEvent(adjustKey);
            Adjust.trackEvent(adjustEvent);
        }
    }
}