import React, { useState } from 'react';
import { StyleSheet, View, Platform, ScrollView, Dimensions, Text, Linking, ActivityIndicator, Image, Animated, TouchableOpacity } from 'react-native';
import OtbImage from '../../../specific/utils/OtbImage/OtbImage';
import Button from '../../designSystem/Button';

import i18n, { getLanguage } from '../../utils/Localization/Localization';
import { checkLocalUrl } from '../../utils/LocalStorage';

import AppTheme from '../../utils/Theme';
import Style from '../../utils/Style';
import SVGView from '../../utils/SvgView';
import { default as IcGoForward } from "../../assets/svg/icons/solid/angle-right.svg";
import { default as IcGoBackward } from "../../assets/svg/icons/solid/angle-left.svg";

const TutorialPage = (props: any) => {

    const [showLargeImage, setShowLargeImage] = useState(false);
    const { isRules, step, rules, width, color, stepNumber = 0, isLastStep, isFirstStep, totalNbumberOfStep = 0 } = props;
    const appTheme: AppTheme = new AppTheme();


    const cardWidth = width;
    const cardheight = (appTheme.getFullAppHeight() - appTheme.pixelPerfect(100));

    const imageHeight = Platform.OS === "web" ? cardheight - appTheme.pixelPerfect(200) : cardWidth * 3543 / 4726;

    const launchLargeImageViewer = () => {
        if (props.showGalleryMode !== undefined) {
            props.showGalleryMode(stepNumber);
        }
    }

    const showNextStep = (stepNumber: number) => {
        if (props.changePage !== undefined) {
            props.changePage(stepNumber);
        }
    }

    const getContent = () => {
        let contentView = [];
        if (isRules === false) {
            const marginLeft = isFirstStep === false ? appTheme.pixelPerfect(10) : 0;
            const buttonWidth = (cardWidth - appTheme.pixelPerfect(30)) / 2;
            const imageWidth: number = cardWidth - appTheme.pixelPerfect(10);
            const nextRoundButton = isLastStep === false ? <TouchableOpacity onPress={() => showNextStep(stepNumber + 1)} style={[Style.shadowed, { width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center", backgroundColor: color }]}>
                <SVGView Component={IcGoForward} color={appTheme.white} size={appTheme.pixelPerfect(24)} />
            </TouchableOpacity> : <View style={{ width: appTheme.pixelPerfect(40) }} />;
            const lastRoundButton = isFirstStep === false ? <TouchableOpacity onPress={() => showNextStep(stepNumber - 1)} style={[Style.shadowed, { width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), justifyContent: "center", alignItems: "center", backgroundColor: appTheme.white }]}>
                <SVGView Component={IcGoBackward} color={color} size={appTheme.pixelPerfect(24)} />
            </TouchableOpacity> : <View style={{ width: appTheme.pixelPerfect(40) }} />;
            const nextButton = isLastStep === false ? <Button onPress={() => showNextStep(stepNumber + 1)}
                title={i18n.t('activities.tuto.actionButtonNext')}
                style={{ width: buttonWidth, marginLeft: marginLeft, backgroundColor: color }} /> : <View />;
            const lastButton = isLastStep === false ? <Button onPress={() => showNextStep(stepNumber - 1)}
                title={i18n.t('activities.tuto.actionButtonLast')}
                style={{ width: buttonWidth, marginLeft: marginLeft, backgroundColor: color }} /> : <View />;
            contentView.push(
                <View style={{ flexDirection: "row", padding: appTheme.pixelPerfect(5), alignItems: "center" }}>
                    {lastRoundButton}
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ textAlign: 'center', paddingTop: appTheme.pixelPerfect(14), paddingBottom: appTheme.pixelPerfect(18), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue }}>
                            {i18n.t('activities.tuto.step').toLocaleUpperCase() + ' ' + stepNumber.toString().toLocaleUpperCase() + ' / ' + totalNbumberOfStep.toString().toLocaleUpperCase()}
                        </Text>
                    </View>
                    {nextRoundButton}
                </View>);
            /*
            contentView.push(<View style={{ height: appTheme.pixelPerfect(80), justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: 0, flexDirection: 'row' }}>
                {lastButton}
                {nextButton}
            </View>);
            */
            contentView.push(<TouchableOpacity
                onPress={launchLargeImageViewer}>
                <OtbImage style={[{ width: imageWidth, height: imageHeight }]} resizeMode="contain" source={{ uri: step.imgLoc !== undefined ? step.imgLoc : checkLocalUrl(step.media.url) }} />
            </TouchableOpacity>);
            contentView.push(<Text style={{ textAlign: 'center', paddingTop: appTheme.pixelPerfect(16), paddingBottom: appTheme.pixelPerfect(16), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(13), color: appTheme.darkBlue }}>
                {step.body[getLanguage()]}
            </Text>);

        } else {
            let rulesView = [];
            let nbOfRules = 0;
            rules.forEach(rule => {
                nbOfRules++;
                const pointView = nbOfRules === rules.length ? <View /> : <View style={{ width: 6, height: 6, borderRadius: 3, backgroundColor: color }} />;
                const ruleView = <View key={'rules_' + nbOfRules} style={{ width: width, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ textAlign: 'center', paddingVertical: appTheme.pixelPerfect(6), paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(13), color: appTheme.darkBlue }}>{rule}</Text>
                    {pointView}
                </View>;
                rulesView.push(ruleView);
            });
            contentView.push(<Text style={{ textAlign: 'center', paddingTop: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.pixelPerfect(20), fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue }}>
                {i18n.t('activities.tuto.goldenRules').toLocaleUpperCase()}
            </Text>);
            contentView.push(<ScrollView
                showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
                <View style={{ paddingBottom: appTheme.pixelPerfect(80) }}>
                    {rulesView}
                </View>
            </ScrollView>);
            contentView.push(<View style={{ justifyContent: 'center', alignItems: 'center', position: 'absolute', bottom: 0 }}>
                <Button onPress={() => showNextStep(1)}
                    title={i18n.t('activities.tuto.rulesActionButtonTitle')}
                    style={{ width: appTheme.pixelPerfect(200), backgroundColor: color }} />
            </View>)
        }
        return contentView;
    }

    return (
        <View style={[styles.container, { height: cardheight, width: width, borderBottomLeftRadius: appTheme.pixelPerfect(20), borderBottomRightRadius: appTheme.pixelPerfect(20) }]}>
            {getContent()}
        </View>
    )
}

export default TutorialPage;


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'flex-start'
    }
});
