import { stripeApiKey } from "../InAppPurchase/StripeInitialisation";

export const getSubscriptionForEmail = async (data: { user_email: string }) => {
    try {
        const { user_email } = data;
        const url = "https://api.stripe.com/v1/customers/search?email:"+user_email;
        const stripeKey: string = stripeApiKey;
        const listResult = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + stripeKey
            },
            body: JSON.stringify({"expand[]":"data.subscriptions"})
        });
        if (listResult.status === 200) {
            const listData = await listResult.json();
            
        }
    } catch (error) {
        throw error;
    }
}

export const cancelSubscriptionFromWeb = async (data: { userSub: any, user_id: string }) => {
    //Mettre en place l'appel de l'API Stripe : https://stripe.com/docs/billing/subscriptions/cancel
    //curl https://api.stripe.com/v1/subscriptions/sub_49ty4767H20z6a \
    //-u sk_test_4eC39HqLyjWDarjtT1zdp7dc: \
    //-X POST \
    //-d cancel_at_period_end=true
    try {
        const { userSub, user_id } = data;
        if (userSub.stripe_sub_id !== undefined) {
            const subId = userSub.stripe_sub_id;
            const url = 'https://api.stripe.com/v1/subscriptions/' + subId;
            const stripeKey: string = stripeApiKey;
            const listResult = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer ' + stripeKey
                },
                body: 'cancel_at_period_end=true'
            });
            if (listResult.status === 200) {
                const listData = await listResult.json();
                if (listData.cancel_at_period_end === true) {
                    return ({ success: true, date: parseInt(listData.current_period_end) * 1000 });
                } else {
                    return ({ success: false });
                }
            }
        } else {
            return ({ success: false });
        }
    } catch (error) {
        throw error;
    }
}