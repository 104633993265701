import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAppTarget } from "../../utils/Navigation/Host";

const isProd = true;
const CAS_STATUS_IN_PROCESS = 'in_process';

// GAR
const GAR_ENDPOINT = "idp-auth.gar.education.fr";
const GAR_PREPROD_ENDPOINT = "idp-auth.partenaire.test-gar.education.fr";

// CANOPE
const CANOPE_ENDPOINT = isProd === true ? "www.reseau-canope.fr/cas-usagers" : "val.reseau-canope.fr/cas-usagers";

const getTicket = () => {
    return getParamWithName("ticket");
}

const getStatus = () => {
    return getParamWithName("status");
}

const getParamWithName = (name: string) => {
    return new URL(window.location.href).searchParams.get(name);
}

const redirectToLoginUrl = async (appTarget: "canope" | "public" | "gar-prod" | "gar-preprod") => {
    const host = window.location.hostname;
    if (appTarget === "canope") {
        window.location.href = "https://" + CANOPE_ENDPOINT + "/login?service=" + encodeURI("https://" + host);
    } else if (appTarget === "gar-prod") {
        window.location.href = "https://" + GAR_ENDPOINT + "/login?service=" + encodeURI("https://" + host);
    } else if (appTarget === "gar-preprod") {
        window.location.href = "https://" + GAR_PREPROD_ENDPOINT + "/login?service=" + encodeURI("https://" + host);
    }
}

const getValidateUrl = (ticket: string, appTarget: "canope" | "public" | "gar-prod" | "gar-preprod") => {
    const hostToUse = appTarget === "gar-prod" ? "https://app-tne.lili.cool" : appTarget === "gar-preprod" ? "https://app-tne-pp.lili.cool" :  "https://app-tne-demo.lili.cool";
    const baseUrl = appTarget === "canope" ? hostToUse+"/cas_tne_proxy" : hostToUse+"/cas_proxy"; 
    const service = window.location.href;
    const queryParams = {
        service,
        ticket,
        format: "json"
    };

    const path = 'p3/serviceValidate';

    let validatingUrl = baseUrl + "/" + path + "?";
    let params = "";
    for (const aParamKey in queryParams) {
        if (Object.hasOwnProperty.call(queryParams, aParamKey)) {
            if (params.length > 0) {
                params = params + "&"
            }
            const aParam = queryParams[aParamKey];
            if (aParamKey === "service") {
                params = params + "service=" + encodeURI(hostToUse);
            } else {
                params = params + aParamKey + "=" + aParam;
            }
        }
    }
    validatingUrl = validatingUrl + params;

    return validatingUrl;
};

const validateTicket = async (ticket: string, appTarget: "canope" | "public" | "gar-prod" | 'gar-preprod') => {
    let validateURL = getValidateUrl(ticket, appTarget);
    const content_type = 'application/json';
    try {
        const mode = appTarget === "canope" ? "cors" : "same-origin";
        const validationRequestResponse = await fetch(validateURL, {
            mode,
            credentials: "include",
            headers: {
                'Content-Type': content_type
            }
        });
        const validationResponseAsText = await validationRequestResponse.text();
        //On va supprimer la valeur du dernier referrer
        await AsyncStorage.removeItem("lastReferrer");
        let validationResponseAsJson = JSON.parse(validationResponseAsText);
        if (validationResponseAsJson.serviceResponse) {
            if (validationResponseAsJson.serviceResponse.authenticationSuccess) {
                let user = validationResponseAsJson.serviceResponse.authenticationSuccess.attributes;
                return ({ user, ticket });
            } else {
                throw ("unable to validate ticket");
            }
        }
    } catch (error) {
        throw (error);
    }
}

export const checkCASAuthentication = async () => {
    try {
        //On va d'abord vérifier la présence d'un ticker dans l'URL
        const ticket = getTicket();
        const appTarget = getAppTarget();
        if (ticket === null) {
            // On va vérifier si l'URL contient un paramètre status. Si c'est la cas, cela peut signifier qu'il y a une erreur
            const status = getStatus();
            if (status === CAS_STATUS_IN_PROCESS) {
                throw new Error("No ticket found");
            } else {
                redirectToLoginUrl(appTarget);
            }
        } else {
            const userData = await validateTicket(ticket, appTarget);
            return userData;
        }
        return null;
    } catch (error) {
        throw error;
    };
}
