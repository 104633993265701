import React, { useEffect, useRef, useState } from "react";

import {
    Text,
    View,
    TextStyle,
    TouchableOpacity
} from "react-native";
import Images from "../../../../specific/utils/Images";
import Button from "../../../designSystem/Button";
import RadioButton from "../../../designSystem/RadioButton";
import i18n from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";
import SignUpStyle from "../../../../specific/style/Authentication/SignUpStyle";
import Alert from "../../Notification/Alert";

interface AnimatorLevelsSelectionProps {
    width?: number,
    onSuccess: (levels) => void,
    isLoading?: boolean
}

const AnimatorLevelsSelection = (props: AnimatorLevelsSelectionProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const style: SignUpStyle = new SignUpStyle();

    const { width = appTheme.getBlockWidth() } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [level, setLevel] = useState<string>("animator");

    const alertRef = useRef<Alert>(null);

    const toggleLevel = (value: string) => {
        setLevel(value);
    }

    const onLevelSelectionDone = () => {
        props.onSuccess(level);
    }

    useEffect(() => {
        if (props.isLoading === true) {
            setIsLoading(true);
        }
    }, [props]);

    const getForm = () => {
        let choicesForm = [];
        let choices = [
            {
                key: "animator",
                value: i18n.t("authentication.signUp.form.radioButtons.animator.animator")
            },
            {
                key: "centerDirection",
                value: i18n.t("authentication.signUp.form.radioButtons.animator.centerDirection")
            },
            {
                key: "collectivityDirection",
                value: i18n.t("authentication.signUp.form.radioButtons.animator.collectivityDirection")
            }
        ];
        for (const aChoiceIndex in choices) {
            if (Object.prototype.hasOwnProperty.call(choices, aChoiceIndex)) {
                const aChoice = choices[aChoiceIndex];
                choicesForm.push(<RadioButton
                    value={aChoice.key === level}
                    onPress={() => toggleLevel(aChoice.key)}
                    style={{ marginBottom: appTheme.pixelPerfect(10) }}>
                    <Text style={{ flex: 1, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue }}>
                        {aChoice.value}
                    </Text>
                </RadioButton>);
            }
        }
        const title: TextStyle = {
            fontFamily: appTheme.primaryBoldFont,
            textTransform: "uppercase",
            fontSize: appTheme.pixelPerfectForFont(8),
            paddingBottom: appTheme.pixelPerfect(10)
        }
        return (<View style={{ marginBottom: appTheme.pixelPerfect(10), alignItems: "center", flex: 1, paddingHorizontal: appTheme.pixelPerfect(10) }}>
            <Text style={[title, { textAlign: "center", color: appTheme.schoolColor, paddingVertical: appTheme.pixelPerfect(10) }]}>
                {i18n.t("authentication.signUp.form.animatorFunctionTitle").toLocaleUpperCase()}
            </Text>
            {choicesForm}
            <Button
                isLoading={isLoading}
                disabled={isLoading}
                onPress={onLevelSelectionDone}
                style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.schoolColor, marginTop: appTheme.pixelPerfect(10) }}
                title={i18n.t("authentication.signUp.buttons.signUp")} />
        </View>);
    }

    return (<View style={{ flex: 1, width }}>
        {getForm()}
        <Alert ref={alertRef} />
    </View>)

}

export default AnimatorLevelsSelection;