import React, { useState } from 'react';
import { TextInput as RNTextInput, TextInputProps, TextStyle, View, Text, KeyboardTypeOptions, TouchableOpacity } from 'react-native';
import TextInputStyle from './TextInputStyle';
import RoundCornerTextInputStyle from './RoundCornerTextInputStyle';
import AppTheme from '../../utils/Theme';
import SVGView from '../../utils/SvgView';
import { default as IcEye } from "../../assets/svg/icons/solid/eye.svg";
import { default as IcEyeSlashed } from "../../assets/svg/icons/solid/eye-slash.svg";

type TextInputType = "default" | "email" | "password";

interface LiliTextInputProps extends TextInputProps {
    type?: TextInputType,
    legend?: string,
    error?: string
}

const TextInput = (props: LiliTextInputProps) => {

    const { legend, type = "default", error, style, multiline = false, ...restProps } = props;
    const appTheme: AppTheme = new AppTheme();
    const textInputStyle: RoundCornerTextInputStyle = new RoundCornerTextInputStyle();

    const [showSecureTextEntry, setShowSecureTextEntry] = useState<boolean>(false);

    let inputStyle: TextStyle = multiline === true ? textInputStyle.inputMultiline : textInputStyle.input;
    let autoCapitalize: 'none' | 'sentences' | 'words' | 'characters' | undefined = "sentences";
    let autoCorrect: boolean = true;
    let keyboardType: KeyboardTypeOptions = "default";
    let secureTextEntry: boolean = false;
    let svgComponentForSecureTextEntry = showSecureTextEntry === false ? IcEye : IcEyeSlashed;
    let showSecureTextEntryView = type === "password" ? <TouchableOpacity onPress={() => setShowSecureTextEntry(!showSecureTextEntry)} style={{ position: "absolute", right: appTheme.pixelPerfect(0), top: appTheme.pixelPerfect(0), width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), justifyContent: "center", alignItems: "center" }}>
        <SVGView Component={svgComponentForSecureTextEntry} size={appTheme.pixelPerfect(16)} color={appTheme.darkBlue + "3D"} />
    </TouchableOpacity> : <View />;
    let clearButtonMode: 'never' | 'while-editing' | 'unless-editing' | 'always' | undefined = "while-editing";
    let legendView = <View />;
    if ((legend !== undefined) || (type === "password")) {
        const textOfTheLegend = legend !== undefined ? legend : "6 caractères minimum";
        legendView = <Text style={{fontFamily:appTheme.secondaryFont, fontSize:appTheme.pixelPerfectForFont(5), color: appTheme.darkBlue+"6D", paddingLeft:appTheme.pixelPerfect(5), marginTop:appTheme.pixelPerfect(-8)}}>{textOfTheLegend}</Text>;
    }
    let errorView = <View />;
    if ((props.error !== undefined) && (props.error.length > 0)) {
        errorView = <Text style={textInputStyle.error}>{props.error}</Text>;
        inputStyle.borderColor = appTheme.errorColor;
    } else {
        inputStyle.borderColor = appTheme.darkBlue+"6D";
    }
    if ((type === "email") || (type === "password")) {
        autoCapitalize = "none";
        autoCorrect = false;
    }
    if (type === "email") {
        keyboardType = "email-address";
    }
    if (type === "password") {
        secureTextEntry = !showSecureTextEntry;
        clearButtonMode = "never";
    }
    return (
        <View>
            {errorView}
            <View style={{ flexDirection: "row" }}>
                <RNTextInput
                    clearButtonMode={clearButtonMode}
                    autoCapitalize={autoCapitalize}
                    autoCorrect={autoCorrect}
                    placeholderTextColor={appTheme.darkBlue + 'AB'}
                    secureTextEntry={secureTextEntry}
                    style={[inputStyle, style]}
                    {...restProps} />
                {showSecureTextEntryView}
            </View>
            {legendView}
        </View>

    );
};

export default TextInput;