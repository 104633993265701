module.exports = {
    "emotionsTaming": {
      "bg": "illHeaderApprivoiserMesEmotions",
      "ill": "pictoEmotionsTaming",
      "next": "txtMusclerMaConcentration",
      "description": "superPowers.emotionsTaming.body",
      "documents": [
        {
          "cell_key": "doc_emotion_01",
          "title": {
            "teacher": {
              "fr": "Affiches pour la classe"
            },
            "family": {
              "fr": "Affiches pour la chambre"
            },
            "animator": {
              "fr": "Affiches pour la classe"
            }
          },
          "subtitle": {
            "fr": "Apprivoiser mes émotions"
          },
          "icon": "pac06PosterEmotions",
          "type": "activity",
          "category_id": "super_posters",
          "section_id": "superPowers",
          "activity_id": "POS_01"
        },
        {
          "cell_key": "doc_emotion_02",
          "title": {
            "teacher": {
              "fr": "Production de l'élève"
            },
            "family": {
              "fr": "Activité pour les super-héros"
            },
            "animator": {
              "fr": "Production de l'élève"
            }
          },
          "subtitle": {
            "fr": "La palette de ma météo intérieure"
          },
          "icon": "she01ApprivoiserMesEmotions",
          "type": "activity",
          "category_id": "super_activities",
          "section_id": "superPowers",
          "activity_id": "SHE_01"
        },
        {
          "cell_key": "doc_emotion_03",
          "title": {
            "teacher": {
              "fr": "Fiches de préparation et de liaison"
            },
            "family": {
              "fr": "Manuel des super-parents"
            },
            "animator": {
              "fr": "Fiches d'activités"
            }
          },
          "subtitle": {
            "fr": "Apprivoiser mes émotions"
          },
          "icon": "illSheetEmotions",
          "type": "activity",
          "category_id": "super_documents",
          "section_id": "superPowers",
          "activity_id": "WOK_06",
          "family": {
            "type": "PDF",
            "url": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F06%20-%20Super%20pouvoirs%2Fv3%2Ffamily%2Flili-apprivoiser-mes-emotions-manuel-parents.pdf?alt=media&token=b3dc55fc-1676-4520-a4c3-9a2130c80009"
          }
        }
      ],
      "discovery": [
        "BIO_01",
        "MED_02",
        "JEM_01",
        "EMO_04"
      ],
      "start": [
        "inspireArts",
        "meditation",
        "breath",
        "inspireMovieAndMusic",
        "emotion_game",
        "my_emotion",
        "inspireNatureAndSpace",
        "sleepMeditation",
        "sleepMusic",
        "others",
        "inpireSportAndAdventure"
      ],
      "continue": [
        "yoga",
        "musical_yoga",
        "inspireBookAndEducation",
        "dedicaces",
        "game_to_build",
        "my_entourage",
        "world",
        "impro_game",
        "inspirePolitic"
      ]
    },
    "focus": {
      "bg": "illHeaderMusclerSaConcentration",
      "ill": "pictoFocus",
      "next": "txtMusclerMaConcentration",
      "description": "superPowers.focus.body",
      "documents": [
        {
          "cell_key": "doc_focus_01",
          "title": {
            "teacher": {
              "fr": "Affiches pour la classe"
            },
            "family": {
              "fr": "Affiches pour la chambre"
            },
            "animator": {
              "fr": "Affiches pour la classe"
            }
          },
          "subtitle": {
            "fr": "Muscler ma concentration"
          },
          "icon": "illPosterFocus",
          "type": "activity",
          "category_id": "super_posters",
          "section_id": "superPowers",
          "activity_id": "POS_02"
        },
        {
          "cell_key": "doc_focus_02",
          "title": {
            "teacher": {
              "fr": "Production de l'élève"
            },
            "family": {
              "fr": "Activité pour les super-héros"
            },
            "animator": {
              "fr": "Production de l'élève"
            }
          },
          "subtitle": {
            "fr": "Ma planète calme"
          },
          "icon": "she02MusclerMaConcentration",
          "type": "activity",
          "category_id": "super_activities",
          "section_id": "superPowers",
          "activity_id": "SHE_02"
        },
        {
          "cell_key": "doc_focus_03",
          "title": {
            "teacher": {
              "fr": "Fiches de préparation et de liaison"
            },
            "family": {
              "fr": "Manuel des super-parents"
            },
            "animator": {
              "fr": "Fiches de préparation et de liaison"
            }
          },
          "subtitle": {
            "fr": "Muscler ma concentration"
          },
          "icon": "illSheetFocus",
          "type": "activity",
          "category_id": "super_documents",
          "section_id": "superPowers",
          "activity_id": "WOK_07",
          "family": {
            "type": "PDF",
            "url": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F06%20-%20Super%20pouvoirs%2Fv3%2Ffamily%2Flili-muscler-ma-concentration-manuel-parents.pdf?alt=media&token=c5e35775-531f-4d85-b5a7-45a2be5f1ef0"
          }
        }
      ],
      "discovery": [
        "BIO_03",
        "JAT_01",
        "VIR_03",
        "MON_02"
      ],
      "start": [
        "inpireSportAndAdventure",
        "concentration_game",
        "virelangues",
        "inspireBookAndEducation",
        "yoga",
        "breath",
        "dedicaces",
        "inspireMovieAndMusic",
        "my_entourage"
      ],
      "continue": [
        "inspireNatureAndSpace",
        "finger_yoga",
        "musical_yoga",
        "inspireScience",
        "meditation",
        "my_emotion",
        "game_to_build",
        "inspirePolitic"
      ]
    },
    "errorTaming": {
      "bg": "illHeaderDompterErreur",
      "ill": "pictoErrorTaming",
      "next": "txtMusclerMaConcentration",
      "description": "superPowers.errorTaming.body",
      "documents": [
        {
          "cell_key": "doc_error_01",
          "title": {
            "teacher": {
              "fr": "Affiches pour la classe"
            },
            "family": {
              "fr": "Affiches pour la chambre"
            },
            "animator": {
              "fr": "Affiches pour la classe"
            }
          },
          "subtitle": {
            "fr": "Dompter l'erreur"
          },
          "icon": "illPosterError",
          "type": "activity",
          "category_id": "super_posters",
          "section_id": "superPowers",
          "activity_id": "POS_03"
        },
        {
          "cell_key": "doc_error_02",
          "title": {
            "teacher": {
              "fr": "Production de l'élève"
            },
            "family": {
              "fr": "Activité pour les super-héros"
            },
            "animator": {
              "fr": "Production de l'élève"
            }
          },
          "subtitle": {
            "fr": "Oh la belle tache"
          },
          "icon": "she03DompterLErreur",
          "type": "activity",
          "category_id": "super_activities",
          "section_id": "superPowers",
          "activity_id": "SHE_03"
        },
        {
          "cell_key": "doc_error_03",
          "title": {
            "teacher": {
              "fr": "Fiches de préparation et de liaison"
            },
            "family": {
              "fr": "Manuel des super-parents"
            },
            "animator": {
              "fr": "Fiches de préparation et de liaison"
            }
          },
          "subtitle": {
            "fr": "Dompter l'erreur"
          },
          "icon": "illSheetError",
          "type": "activity",
          "category_id": "super_documents",
          "section_id": "superPowers",
          "activity_id": "WOK_08",
          "family": {
            "type": "PDF",
            "url": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F06%20-%20Super%20pouvoirs%2Fv3%2Ffamily%2Flili-dompter-lerreur-manuel-parents.pdf?alt=media&token=aff65ab2-51ba-4214-9f4d-d40cdd62d66b"
          }
        }
      ],
      "discovery": [
        "BIO_06",
        "JAT_03",
        "JIM_07",
        "REG_04"
      ],
      "start": [
        "inspireMovieAndMusic",
        "impro_game",
        "introspection",
        "inspireBookAndEducation",
        "virelangues",
        "concentration_game",
        "my_emotion",
        "inspireScience",
        "breath"
      ],
      "continue": [
        "inspireArts",
        "emotion_game",
        "meditation",
        "inpireSportAndAdventure",
        "dedicaces",
        "musical_yoga",
        "inspireNatureAndSpace"
      ]
    },
    "speak": {
      "bg": "illHeaderOserALOral",
      "ill": "pictoSpeak",
      "next": "txtMusclerMaConcentration",
      "description": "superPowers.speak.body",
      "documents": [
        {
          "cell_key": "doc_speak_01",
          "title": {
            "teacher": {
              "fr": "Affiches pour la classe"
            },
            "family": {
              "fr": "Affiches pour la chambre"
            },
            "animator": {
              "fr": "Affiches pour la classe"
            }
          },
          "subtitle": {
            "fr": "Oser à l'oral"
          },
          "icon": "illPosterSpeak",
          "type": "activity",
          "category_id": "super_posters",
          "section_id": "superPowers",
          "activity_id": "POS_04"
        },
        {
          "cell_key": "doc_speak_02",
          "title": {
            "teacher": {
              "fr": "Production de l'élève"
            },
            "family": {
              "fr": "Activité pour les super-héros"
            },
            "animator": {
              "fr": "Production de l'élève"
            }
          },
          "subtitle": {
            "fr": "7 conseils pour oser à l’oral"
          },
          "icon": "she04OserALOral",
          "type": "activity",
          "category_id": "super_activities",
          "section_id": "superPowers",
          "activity_id": "SHE_04"
        },
        {
          "cell_key": "doc_speak_03",
          "title": {
            "teacher": {
              "fr": "Fiches de préparation et de liaison"
            },
            "family": {
              "fr": "Manuel des super-parents"
            },
            "animator": {
              "fr": "Fiches de préparation et de liaison"
            }
          },
          "subtitle": {
            "fr": "Oser à l'oral"
          },
          "icon": "illSheetSpeak",
          "type": "activity",
          "category_id": "super_documents",
          "section_id": "superPowers",
          "activity_id": "WOK_09",
          "family": {
            "type": "PDF",
            "url": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F06%20-%20Super%20pouvoirs%2Fv3%2Ffamily%2Flili-oser-a-loral-manuel-parents.pdf?alt=media&token=5958b2f3-0156-42ed-8f2b-1843b7d670d5"
          }
        },
        {
          "cell_key": "doc_speak_04",
          "title": {
            "teacher": {
              "fr": "Conseils vidéo avec Les Petits Champions"
            },
            "family": {
              "fr": "Conseils vidéo avec Les Petits Champions"
            },
            "animator": {
              "fr": "Conseils vidéo avec Les Petits Champions"
            }
          },
          "subtitle": {
            "fr": "Bien lire à voix haute"
          },
          "icon": "lpc01LectureAVoixHaute",
          "type": "activity",
          "category_id": "little_champions",
          "section_id": "talk",
          "activity_id": "LPC_02",
          "media_id": ""
        }
      ],
      "discovery": [
        "BIO_02",
        "YOG_05",
        "JIM_01",
        "EMO_06"
      ],
      "start": [
        "inspirePolitic",
        "emotion_game",
        "impro_game",
        "breath",
        "inspireMovieAndMusic",
        "my_emotion",
        "virelangues"
      ],
      "continue": [
        "mime_game",
        "inspireBookAndEducation",
        "meditation",
        "game_to_build",
        "inspireNatureAndSpace"
      ]
    },
    "collective": {
      "bg": "illHeaderLaJouerCollectif",
      "ill": "pictoCollectives",
      "next": "txtMusclerMaConcentration",
      "description": "superPowers.collective.body",
      "documents": [
        {
          "cell_key": "doc_collective_01",
          "title": {
            "teacher": {
              "fr": "Affiches pour la classe"
            },
            "family": {
              "fr": "Affiches pour la chambre"
            },
            "animator": {
              "fr": "Affiches pour la classe"
            }
          },
          "subtitle": {
            "fr": "La jouer collectif"
          },
          "icon": "illPosterCollective",
          "type": "activity",
          "category_id": "super_posters",
          "section_id": "superPowers",
          "activity_id": "POS_05"
        },
        {
          "cell_key": "doc_collective_02",
          "title": {
            "teacher": {
              "fr": "Production de l'élève"
            },
            "family": {
              "fr": "Activité pour les super-héros"
            },
            "animator": {
              "fr": "Production de l'élève"
            }
          },
          "subtitle": {
            "fr": "Les cartes merci"
          },
          "icon": "she06LaJouerCollectif",
          "type": "activity",
          "category_id": "super_activities",
          "section_id": "superPowers",
          "activity_id": "SHE_06"
        },
        {
          "cell_key": "doc_collective_03",
          "title": {
            "teacher": {
              "fr": "Fiches de préparation et de liaison"
            },
            "family": {
              "fr": "Manuel des super-parents"
            },
            "animator": {
              "fr": "Fiches de préparation et de liaison"
            }
          },
          "subtitle": {
            "fr": "La jouer collectif"
          },
          "icon": "illSheetCollective",
          "type": "activity",
          "category_id": "super_documents",
          "section_id": "superPowers",
          "activity_id": "WOK_10",
          "family": {
            "type": "PDF",
            "url": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F06%20-%20Super%20pouvoirs%2Fv3%2Ffamily%2Flili-la-jouer-collectif-manuel-parents.pdf?alt=media&token=7f42cb46-0e59-4ab9-b313-4fdbdf8079c2"
          }
        }
      ],
      "discovery": [
        "BIO_04",
        "MED_04",
        "JIM_06",
        "AUT_01"
      ],
      "start": [
        "inspirePolitic",
        "impro_game",
        "musical_yoga",
        "inpireSportAndAdventure",
        "mime_game",
        "game_to_build",
        "inspireBookAndEducation",
        "meditation",
        "others",
        "inspireScience"
      ],
      "continue": [
        "emotion_game",
        "inspireArts",
        "dedicaces",
        "my_entourage",
        "inspireNatureAndSpace",
        "finger_yoga",
        "world",
        "inspireMovieAndMusic"
      ]
    },
    "criticalThinking": {
      "bg": "illHeaderReflechirSurLaVie",
      "ill": "pictoCriticalThinking",
      "next": "txtMusclerMaConcentration",
      "description": "superPowers.criticalThinking.body",
      "documents": [
        {
          "cell_key": "doc_criticalThinking_01",
          "title": {
            "teacher": {
              "fr": "Affiches pour la classe"
            },
            "family": {
              "fr": "Affiches pour la chambre"
            },
            "animator": {
              "fr": "Affiches pour la classe"
            }
          },
          "subtitle": {
            "fr": "Réfléchir sur la vie"
          },
          "icon": "illPosterCritical",
          "type": "activity",
          "category_id": "super_posters",
          "section_id": "superPowers",
          "activity_id": "POS_06"
        },
        {
          "cell_key": "doc_criticalThinking_02",
          "title": {
            "teacher": {
              "fr": "Production de l'élève"
            },
            "family": {
              "fr": "Activité pour les super-héros"
            },
            "animator": {
              "fr": "Production de l'élève"
            }
          },
          "subtitle": {
            "fr": "Le paysage des idées reçues"
          },
          "icon": "she05ReflechirSurLaVie",
          "type": "activity",
          "category_id": "super_activities",
          "section_id": "superPowers",
          "activity_id": "SHE_05"
        },
        {
          "cell_key": "doc_criticalThinking_03",
          "title": {
            "teacher": {
              "fr": "Fiches de préparation et de liaison"
            },
            "family": {
              "fr": "Manuel des super-parents"
            },
            "animator": {
              "fr": "Fiches de préparation et de liaison"
            }
          },
          "subtitle": {
            "fr": "Réfléchir sur la vie"
          },
          "icon": "illSheetCritic",
          "type": "activity",
          "category_id": "super_documents",
          "section_id": "superPowers",
          "activity_id": "WOK_11",
          "family": {
            "type": "PDF",
            "url": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F06%20-%20Super%20pouvoirs%2Fv3%2Ffamily%2Flili-reflechir-sur-la-vie-manuel-parents.pdf?alt=media&token=d5e3194a-7f0b-4864-a47d-a001f20b28f7"
          }
        }
      ],
      "discovery": [
        "BIO_05",
        "MED_08",
        "JIM_08",
        "AUT_02"
      ],
      "start": [
        "inspireArts",
        "others",
        "inspirePolitic",
        "introspection",
        "inspireBookAndEducation",
        "others",
        "inspireMovieAndMusic"
      ],
      "continue": [
        "inspireScience",
        "my_entourage",
        "inspireNatureAndSpace",
        "world",
        "inpireSportAndAdventure"
      ]
    },
    "selfEsteem": {
      "bg": "illHeaderCroireEnMoi",
      "ill": "pictoSelfEsteem",
      "next": "txtMusclerMaConcentration",
      "description": "superPowers.selfEsteem.body",
      "documents": [
        {
          "cell_key": "doc_selfEsteem_01",
          "title": {
            "teacher": {
              "fr": "Affiches pour la classe"
            },
            "family": {
              "fr": "Affiches pour la chambre"
            },
            "animator": {
              "fr": "Affiches pour la classe"
            }
          },
          "subtitle": {
            "fr": "Croire en moi"
          },
          "icon": "illPosterSelfEstemm",
          "type": "activity",
          "category_id": "super_posters",
          "section_id": "superPowers",
          "activity_id": "POS_07"
        },
        {
          "cell_key": "doc_selfEsteem_02",
          "title": {
            "teacher": {
              "fr": "Production de l'élève"
            },
            "family": {
              "fr": "Activité pour les super-héros"
            },
            "animator": {
              "fr": "Production de l'élève"
            }
          },
          "subtitle": {
            "fr": "La liste de mes qualités"
          },
          "icon": "she07CroireEnSoi",
          "type": "activity",
          "category_id": "super_activities",
          "section_id": "superPowers",
          "activity_id": "SHE_07"
        },
        {
          "cell_key": "doc_selfEsteem_03",
          "title": {
            "teacher": {
              "fr": "Fiches de préparation et de liaison"
            },
            "family": {
              "fr": "Manuel des super-parents"
            },
            "animator": {
              "fr": "Fiches de préparation et de liaison"
            }
          },
          "subtitle": {
            "fr": "Croire en moi"
          },
          "icon": "illSheetSelfEsteem",
          "type": "activity",
          "category_id": "super_documents",
          "section_id": "superPowers",
          "activity_id": "WOK_12",
          "family": {
            "type": "PDF",
            "url": "https://firebasestorage.googleapis.com/v0/b/lili-c7853.appspot.com/o/03%20-%20Documents%2F06%20-%20Super%20pouvoirs%2Fv3%2Ffamily%2Flili-croire-en-moi-manuel-parents.pdf?alt=media&token=2b9e265b-ed65-4edc-8105-13205cec8177"
          }
        }
      ],
      "discovery": [
        "BIO_07",
        "MED_01",
        "DED_03",
        "REG_03"
      ],
      "start": [
        "inspireBookAndEducation",
        "meditation",
        "sleepMeditation",
        "inspireMovieAndMusic",
        "impro_game",
        "yoga",
        "inspirePolitic",
        "musical_yoga",
        "mime_game",
        "my_emotion",
        "inspireScience"
      ],
      "continue": [
        "breath",
        "inspireArts",
        "introspection",
        "emotion_game",
        "finger_yoga",
        "inspireNatureAndSpace",
        "others",
        "game_to_build",
        "inpireSportAndAdventure"
      ]
    }
  };