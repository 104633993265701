import React, { useState } from "react";

import {
    TextInput,
    Dimensions,
    TextInputProps,
    View,
    Modal,
    Text,
    SafeAreaView
} from "react-native";
import AppTheme from "../utils/Theme";

const OTBRountTextInput = (props: TextInputProps) => {

    const appTheme: AppTheme = new AppTheme();

    const [showModalInput, setShowModalInput] = useState(false);
    const [inputData, setInputData] = useState("");
    const width = appTheme.getFullAppWidth();
    const height = appTheme.getFullAppHeight();
    const padding = 20;
    const inputWidth = width - padding * 2;

    const onFocus = () => {
        setShowModalInput(true)
    }

    const onBlur = () => {
        setShowModalInput(false)
    }

    const onTextChange = (text: string) => {
        if (props.onChangeText !== undefined) {
            props.onChangeText(text);
        }
        setInputData(text);
    }

    return (
        <View>
            <TextInput
                value={inputData}
                onFocus={onFocus}
                clearButtonMode="while-editing"
                style={{
                    borderRadius: 10,
                    marginVertical: 5,
                    padding: 10,
                    borderColor: "#CCCCCC",
                    borderWidth: 1,
                    width: inputWidth,
                    height: 44,
                    backgroundColor: "#FFFFFF"
                }}
                {...props} />
            <Modal animationType="fade" visible={showModalInput}>
                <SafeAreaView style={{ height, width, backgroundColor: "#FFFFFF", alignItems: "center" }}>
                    <Text style={{color:"#404040", width:inputWidth, marginVertical:5}}>{props.placeholder}</Text>
                    <TextInput
                        autoFocus={showModalInput}
                        onChangeText={onTextChange}
                        value={inputData}
                        onBlur={onBlur}
                        clearButtonMode="while-editing"
                        style={{
                            borderRadius: 10,
                            marginVertical: 5,
                            padding: 10,
                            borderColor: "#CCCCCC",
                            borderWidth: 1,
                            width: inputWidth,
                            height: 44,
                            backgroundColor: "#FFFFFF"
                        }}
                        {...props} />
                </SafeAreaView>
            </Modal>
        </View>
    )
}

export default OTBRountTextInput;
