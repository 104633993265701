import { ViewStyle, Platform } from "react-native";
import AppTheme from "../../utils/Theme";
import MainStyle from "./MainStyle";

export default class GameStyle {

  appTheme: AppTheme = new AppTheme();
  mainStyle: MainStyle = new MainStyle();

  mainContainer: ViewStyle = {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    transform: [
      {
        rotate: Platform.OS === "web" ? "Odeg" : "90deg"
      }
    ]
  }

  mainView: ViewStyle = {
    width: Platform.OS === "web" ? this.appTheme.getFullAppWidth() : this.appTheme.getFullAppHeight(),
    height: Platform.OS === "web" ? this.appTheme.getFullAppHeight() : this.appTheme.getFullAppWidth(),
    justifyContent: "center",
    alignItems: "center"
  }

  backgroundImageStyle: ViewStyle = {
    ...(this.mainView as object),
    position: "absolute"
  }

  closeButtonView: ViewStyle = {
    position: "absolute",
    top: this.appTheme.pixelPerfect(10),
    right: this.appTheme.pixelPerfect(10)
  }

  muteButtonView: ViewStyle = {
    position: "absolute",
    top: this.appTheme.pixelPerfect(10),
    left: this.appTheme.pixelPerfect(10)
  }

  slashButtonView: ViewStyle = {
    position: "absolute",
    top: 0,
    left: 0
  }

  countdownNumberContainer: ViewStyle = {
    backgroundColor:this.appTheme.white,
    height: this.appTheme.pixelPerfect(120),
    width: this.appTheme.pixelPerfect(120),
    borderRadius: this.appTheme.pixelPerfect(60),
    justifyContent:"center",
    alignItems:"center"
  }

  gameBoardContainer: ViewStyle = {
    width: Platform.OS === "web" ? this.appTheme.getFullAppWidth() : this.appTheme.getFullAppHeight(),
    height: "100%",
    justifyContent: "center",
    alignItems: "center"
  }

  pauseModal: ViewStyle = {
    position:"absolute",
    backgroundColor: this.appTheme.talk+"80",
    width: Platform.OS === "web" ? this.appTheme.getFullScreenWidth() : this.appTheme.getFullScreenHeight(),
    height: Platform.OS === "web" ? this.appTheme.getFullScreenHeight() : this.appTheme.getFullScreenWidth(),
    justifyContent: "center",
    alignItems: "center"
  }

  pauseModalOptions: ViewStyle = {
    backgroundColor: this.appTheme.white, 
    width: this.appTheme.pixelPerfect(350), 
    minHeight: this.appTheme.pixelPerfect(150), 
    borderRadius: this.appTheme.pixelPerfect(10),
  }

  pauseViewButtons: ViewStyle = { 
    marginVertical: this.appTheme.pixelPerfect(5), 
    backgroundColor: this.appTheme.white, 
    borderWidth: this.appTheme.pixelPerfect(1), 
    borderColor: this.appTheme.talk, 
    width: this.appTheme.pixelPerfect(250) 
  }

  constructor() {

  }
}