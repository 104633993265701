import React from "react";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import {
    Text,
    View,
    Dimensions,
    Modal
} from 'react-native';

import AppTheme from "../../utils/Theme";

import Button from "../../designSystem/Button";


const Alert = forwardRef((props: any, ref: any) => {

    const [isVisible, setIsVisible] = useState(false);

    const appTheme: AppTheme = new AppTheme();

    const hideTheAlert = () => {
        setAlertContent({
            title: "",
            body: "",
            primaryButtonTitle: "OK",
            onPressPrimaryButton: hideTheAlert,
            secondaryButtonTitle: "OK",
            onPressSecondaryButton: hideTheAlert,
            isVisible: false,
            hideTheAlertOnOptionPressed:true
        });
    }

    const [alertContent, setAlertContent] = useState({
        title: "",
        body: "",
        primaryButtonTitle: "OK",
        onPressPrimaryButton: hideTheAlert,
        secondaryButtonTitle: "OK",
        onPressSecondaryButton: hideTheAlert,
        isVisible: false,
        hideTheAlertOnOptionPressed: true
    });

    // ComponentDidMount
    useEffect(() => {
    }, []);

    useImperativeHandle(ref, () => ({

        showAlert(data: {
            title: string,
            body?: string,
            primaryButtonTitle?: string,
            onPressPrimaryButton?: (() => void) | undefined,
            secondaryButtonTitle?: string,
            onPressSecondaryButton?: (() => void) | undefined,
            hideTheAlertOnOptionPressed?: boolean
        }) {
            const {
                title = "",
                body = "",
                primaryButtonTitle = "OK",
                onPressPrimaryButton = hideTheAlert,
                secondaryButtonTitle = "",
                onPressSecondaryButton = hideTheAlert,
                hideTheAlertOnOptionPressed = true
            } = data;
            setAlertContent({
                title,
                body,
                primaryButtonTitle,
                onPressPrimaryButton,
                secondaryButtonTitle,
                onPressSecondaryButton,
                isVisible: true,
                hideTheAlertOnOptionPressed
            });
        },

        hideAlert() {
            hideTheAlert();
        }
    }));

    const onPressSecondaryButton = async () => {
        hideTheAlert();
        if (alertContent.onPressSecondaryButton !== undefined) {
            await alertContent.onPressSecondaryButton();
        }
    }

    const onPressPrimaryButton = async () => {
        const hideTheAlertOnOptionPressed = alertContent.hideTheAlertOnOptionPressed ?? true;
        if (hideTheAlertOnOptionPressed === true) {
            hideTheAlert();
        }
        if (alertContent.onPressPrimaryButton !== undefined) {
            await alertContent.onPressPrimaryButton();
        }
    }

    const getSecondaryButton = () => {
        if (alertContent.secondaryButtonTitle.length > 0) {
            return <Button style={{marginTop:appTheme.pixelPerfect(10)}} onPress={onPressSecondaryButton} title={alertContent.secondaryButtonTitle} />
        }
        return <View />;
    }

    return (
        <Modal
            transparent={true}
            visible={alertContent.isVisible}
            style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height }}>
            <View style={{ width: Dimensions.get("window").width, height: Dimensions.get("window").height, justifyContent: "center", alignItems: "center", backgroundColor: appTheme.alertOverlayColor }}>
                <View style={{ width: appTheme.getBlockWidth(), padding: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(10), backgroundColor: appTheme.alertBackgroundColor }}>
                    <View style={{ marginBottom: appTheme.pixelPerfect(10) }}>
                        <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), fontWeight: "600", color:appTheme.darkBlue }}>{alertContent.title}</Text>
                        <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color:appTheme.darkBlue }}>{alertContent.body}</Text>
                    </View>
                    <Button onPress={onPressPrimaryButton} title={alertContent.primaryButtonTitle} />
                    {getSecondaryButton()}
                </View>
            </View>
        </Modal>
    )
});

export default Alert;