import React, { useState } from "react";

import {
    ActivityIndicator,
    Dimensions,
    Platform,
    StyleSheet,
    Text,
    View
} from "react-native";

interface TicketFormProps {

}

const TicketForm = (props:TicketFormProps) => {

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

    const onViewWillAppear = () => {
        
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewWillAppear} style={styles.container}>
                <ActivityIndicator />
            </View>
        }
        return <View />
    }

    return <View style={styles.container}>
        {getContent()}
    </View>

}

export default TicketForm;

const styles = StyleSheet.create({
    container: {
        width: Platform.OS === "web" ? "100%" : Dimensions.get("screen").width,
        height: Platform.OS === "web" ? "100%" : Dimensions.get("screen").height,
        justifyContent:"center",
        alignItems:"center"
    }
})