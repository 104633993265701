import React from "react";

import {
    View,
    Text,
    SafeAreaView,
    Image,
    ScrollView
} from "react-native";
import Images from "../../../specific/utils/Images";
import AppTheme, { white } from "../../utils/Theme";
import Style from "../../utils/Style";
import Button from "../../designSystem/Button";
import User from "../../data/user/User";
import i18n from "../../utils/Localization/Localization";
import { default as IcStar } from "../../assets/svg/icons/solid/star-of-life-solid.svg";
import SVGView from "../../utils/SvgView";

const AboutDescription = (props: { onCloseRequest: () => void }) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();

    const { onCloseRequest } = props;

    const description = i18n.t("about.description");
    let knowMoreView = [];
    for (const anArgumentIndex in description) {
        if (Object.prototype.hasOwnProperty.call(description, anArgumentIndex)) {
            if (knowMoreView.length > 0) {
                knowMoreView.push(<View style={{ opacity: 0.5, width: "100%", height: appTheme.pixelPerfect(20), alignItems: "center", justifyContent: "center" }}>
                    <SVGView Component={IcStar} color={appTheme.darkBlue} size={appTheme.pixelPerfect(10)} />
                </View>);
            }
            const element = description[anArgumentIndex];
            knowMoreView.push(<Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), paddingBottom: appTheme.pixelPerfect(4) }} key={anArgumentIndex}>{element}</Text>)
        }
    }

    const imageSize = appTheme.pixelPerfect(80);
    const imageChoice = images.icAbout
    const overlayColor = user.profile === "teacher" ? appTheme.schoolColor : user.profile === "family" ? appTheme.homeColor : appTheme.animatorColor;

    return (
        <SafeAreaView style={{ width: appTheme.getFullScreenWidth(), alignItems: "center", justifyContent: "center", backgroundColor: overlayColor + "80", flex: 1 }}>
            <ScrollView>
                <View style={{ width: appTheme.getBlockWidth(), justifyContent: "center", alignItems: "center", marginTop: appTheme.pixelPerfect(10) }}>
                    <View style={[Style.shadowed, { width: appTheme.getBlockWidth(), justifyContent: "center", alignItems: "center", backgroundColor: "#FFF", overflow: "hidden", borderRadius: appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(10) }]}>
                        <View style={{ width: appTheme.getBlockWidth(), height: imageSize, justifyContent: "center", alignItems: "center" }}>
                            <Image source={imageChoice} style={{ width: imageSize, height: imageSize, borderRadius: imageSize / 2 }} />
                        </View>
                        <View style={{ width: appTheme.getBlockWidth(), alignItems: "flex-start", backgroundColor: appTheme.white, padding: appTheme.pixelPerfect(10), paddingBottom: appTheme.pixelPerfect(80) }}>
                            {knowMoreView}
                        </View>
                    </View>
                </View>
            </ScrollView>
            <View style={[Style.shadowed, { position: "absolute", bottom: appTheme.bottomInsets + appTheme.pixelPerfect(20), alignItems: "center" }]}>
                <Button style={{ backgroundColor: overlayColor, width: appTheme.pixelPerfect(100) }} textStyle={{ color: white }} title={"Fermer"} onPress={onCloseRequest} />
            </View>
        </SafeAreaView>
    )

}

export default AboutDescription;