import React from "react";

import {
    Image, ImageErrorEventData, NativeSyntheticEvent, Platform, View
} from "react-native";
import Images, { bundleImage } from "../../../../specific/utils/Images";
import AppTheme from "../../../utils/Theme";
import Activity, { getActivityAvalability } from "../../../models/Activity";
import User from "../../../data/user/User";
import Style from "../../../utils/Style";
import SVGView from "../../../utils/SvgView";
import { default as IcLiliStar } from "../../../assets/svg/lili/ic_star.svg";
import { default as IcDev } from "../../../assets/svg/icons/solid/dev.svg";

interface ActivityIconProps {
    isActivityAvailable?: boolean,
    localPath?: string,
    remotePath?: string,
    withShadow?: boolean,
    isSquare?: boolean,
    size?: number,
    status?: string,
    isPremium?: boolean
}

const ActivityIcon = (props: ActivityIconProps) => {

    const images: Images = new Images();
    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();

    const { isPremium, isActivityAvailable = false, localPath = "", remotePath, isSquare = false, withShadow = false, size = appTheme.pixelPerfect(60), status = "published" } = props;

    const onError = (error: NativeSyntheticEvent<ImageErrorEventData>) => {}

    const activityIconSize = size;
    let imageOpacity = 1;
    let premiumMark = <View />;
    let imageSource: bundleImage | number | undefined = undefined;
    if ((localPath !== undefined) && (localPath.length > 0)) {
        if (images[localPath] !== undefined) {
            imageSource = images[localPath];
        }
    }
    if (imageSource === undefined) {
        // On n'a pas d'imaage locale, donc on charge l'image distante
        if ((remotePath !== undefined) && (remotePath.length > 0)) {
            imageSource = { uri: remotePath };
        }
    }
    console.log({isPremium, admin:user.admin})
    if ((isActivityAvailable === false) || ((user.admin === true) && (isPremium === true))) {
        imageOpacity = Platform.OS === "ios" ? 0.5 : 0.3;
        if (user.admin === true) {
            imageOpacity = 1;
        }
        const premiumMarkSize = activityIconSize / 3;
        let premiumMarkColor = appTheme.premium;
        let icon = <SVGView Component={IcLiliStar} size={premiumMarkSize * 0.5} color={appTheme.white} />;
        premiumMark = <View style={[Style.shadowed, { width: premiumMarkSize, height: premiumMarkSize, borderRadius: premiumMarkSize / 2, backgroundColor: premiumMarkColor, position: "absolute", top: appTheme.pixelPerfect(2), right: appTheme.pixelPerfect(2), justifyContent: 'center', alignItems: 'center' }]}>
            {icon}
        </View>;
    }
    let devMark = <View />
    if (status === "draft") {
        imageOpacity = Platform.OS === "ios" ? 0.5 : 0.3;
        const premiumMarkSize = activityIconSize / 3;
        let icon = <SVGView Component={IcDev} size={premiumMarkSize * 0.5} color={appTheme.white} />;
        devMark = <View style={[Style.shadowed, { width: premiumMarkSize, height: premiumMarkSize, borderRadius: premiumMarkSize / 2, backgroundColor: "#CC0000", position: "absolute", bottom: appTheme.pixelPerfect(2), right: appTheme.pixelPerfect(2), justifyContent: 'center', alignItems: 'center' }]}>
            {icon}
        </View>;
    }

    const borderRadius = isSquare === true ? appTheme.pixelPerfect(10) : activityIconSize / 2;
    let imageView = <View style={[{ width: activityIconSize, height: activityIconSize, borderRadius, backgroundColor:"#DBDBDB" }]} />
    if (imageSource !== undefined) {
        // Définir une image par défaut
        imageView = <Image onError={onError} source={imageSource} style={[{ width: activityIconSize, height: activityIconSize, borderRadius }]} resizeMode="cover" />;
    }

    const containerStyle = withShadow === true ? [Style.shadowed, { borderRadius }] : undefined;

    return (
        <View>
            <View style={[containerStyle, { opacity: imageOpacity }]}>
                {imageView}
            </View>
            {premiumMark}
            {devMark}
        </View>

    )
}

export default ActivityIcon;