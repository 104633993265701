import { Linking } from "react-native";
import { getAppTarget } from "../../specific/utils/Navigation/Host";
import User from "../data/user/User";

// Méthode pour ouvrir les liens en fonction de l'instance GAR ou PUBLIC
export const openUrl = (url: string) => {
    const appTarget = getAppTarget();
    if (appTarget !== "public") {
        const user: User = User.getInstance();
        const contactUrlForGar = "https://sortie-confiance.gar.education.fr?idRessource=ark%3A%2F19153%2Frcqbq94w21%2Fs3&IDO=" + user.tne_ido + "&urlDest=" + encodeURI(url);
        Linking.openURL(contactUrlForGar);
    } else {
        Linking.openURL(url);
    }
}