import React, { useRef, useState } from "react";

import {
    Modal,
    SafeAreaView,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import SVGView from "../../../utils/SvgView";
import AppTheme from "../../../utils/Theme";
import { default as IcPaperclip } from "../../../assets/svg/icons/solid/paperclip-solid.svg";
import PDFViewer from "../../../../specific/components/PDF/PDFViewer";
import { analyticsEventActivityLaunch, analyticsEventMediaLaunch, propertiesEventAccess, propertiesEventCategory, propertiesEventContent, propertiesEventContentName, propertiesEventName, trackEvent } from "../../../services/Tracking/Analytics";
import Activity from "../../../models/Activity";

type localizedString = {
    fr?: string
}

interface ActivityCellContextProps {
    pdf?: string;
    color: string;
    width: number;
    isEnable?: boolean;
    title?: string;
    sectionKey?: string;
    activity_title?: string;
    activity: Activity;
    categoryAnalyticsKey?: string;
}

const ActivityCellContext = (props: ActivityCellContextProps) => {
    const { pdf, color, width, isEnable = false, title, sectionKey = "", activity_title="", activity, categoryAnalyticsKey = "" } = props;
    const [launchDocument, setLaunchDocument] = useState<boolean>(false);
    const summaryViewRef = useRef<ScrollView>(null);

    const appTheme: AppTheme = new AppTheme();

    const toggleSummary = () => {
        if (launchDocument === false) {
            // On ouvre le doc
            trackEvent(analyticsEventActivityLaunch, {
                [propertiesEventCategory]: categoryAnalyticsKey,
                [propertiesEventContent]: "doc",
                [propertiesEventContentName]: "context",
                [propertiesEventAccess]: activity.isPremium() === true ? "premium" : "free",
                [propertiesEventName]: activity.analytics_key
            });
        }
        setLaunchDocument(!launchDocument);
    }

    return <View style={{ width, alignItems: "center", paddingHorizontal: appTheme.pixelPerfect(10), marginVertical: appTheme.pixelPerfect(10) }}>
        <TouchableOpacity onPress={toggleSummary} style={{ paddingHorizontal: appTheme.pixelPerfect(10), width: width - appTheme.pixelPerfect(10), height: appTheme.pixelPerfect(40), flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: appTheme.pixelPerfect(20), borderWidth: 1, borderColor: color, marginBottom: appTheme.pixelPerfect(10) }}>
            <Text style={{ flex: 1, fontFamily: appTheme.primaryBoldFont, textTransform: "uppercase", color, fontSize: appTheme.pixelPerfectForFont(10) }}>{title}</Text>
            <View style={{ marginStart: appTheme.pixelPerfect(10) }}>
                <SVGView Component={IcPaperclip} size={appTheme.pixelPerfect(24)} color={color} />
            </View>
        </TouchableOpacity>
        <Modal animationType="slide" visible={launchDocument} transparent={true} style={{ width: appTheme.getFullScreenWidth() }}>
            <View style={{ flex: 1, alignItems: "center", backgroundColor: color + "80", width: appTheme.getFullScreenWidth() }}>
                <View style={{ flex: 1, justifyContent: "flex-end", backgroundColor: color + "80", width: appTheme.getFullAppWidth() }}>
                    <PDFViewer
                        title={activity_title}
                        pdfUrl={pdf}
                        color={color}
                        sectionId={sectionKey}
                        closeTheDocument={toggleSummary} />
                </View>
            </View>
        </Modal>
    </View>
}

export default ActivityCellContext;