import React from "react";
import Progress from "@ramonak/react-progress-bar";

import {
    ActivityIndicator,
    View
} from "react-native";
import AppTheme from "../../../shared/utils/Theme";

const ProgressBar = (props: {
    color: string,
    indeterminate: boolean,
    progress: number,
    width: number,
    borderWidth: number
}) => {
    const appTheme: AppTheme = new AppTheme();
    const { color, indeterminate, progress, width, borderWidth } = props;
    const loadingView = indeterminate === true ? <View style={{marginEnd:appTheme.pixelPerfect(10)}}><ActivityIndicator color={color} /></View> : <View />;
    return <View style={{ flexDirection:"row" }}>
        {loadingView}
        <Progress
            isLabelVisible={false}
            bgColor={color}
            completed={progress}
            animateOnRender={true}
            width={width.toString()} />
    </View>
}

export default ProgressBar;