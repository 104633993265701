import React from "react";
import { useState, useEffect, useRef } from "react";
import Main from "../../../specific/components/Main/Main";
import i18n, { updateContext } from "../../utils/Localization/Localization";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Animated
} from 'react-native';

import * as Navigation from "../../../specific/utils/Navigation/Navigation";

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// CardFlip
import SchoolMenu from "./SchoolMenu";

import { useParams, useNavigate } from "react-router-dom";
import { analyticsEventHpViewed, trackEvent } from "../../services/Tracking/Analytics";

let todaysMoodDone = true;

const AppMenu = (props: any) => {

    let propsData = props;
    let navigate = null;
    if (Platform.OS === "web") {
        propsData = useParams();
        navigate = useNavigate();
    }

    const appTheme: AppTheme = new AppTheme();
    const { context, componentId } = propsData;

    const mainOptions: MainOptions = new MainOptions({
        key: "app_menu",
        componentId,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        canGoBack: false
    });

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

    const onViewDidAppear = () => {
        if (viewDidAppear === false) {
            trackEvent(analyticsEventHpViewed);
            checkTodaysMood();
        }
    }

    const checkTodaysMood = () => {
        if (todaysMoodDone === false) {
            // Launch mood
            todaysMoodDone = true;
            Navigation.showModalInView({
                componentId,
                name: Constants.ScreenMood,
                options: {
                    modalPresentationStyle: 'overCurrentContext',
                    layout: {
                        componentBackgroundColor: 'transparent'
                    }
                }
            });
        }
        setViewDidAppear(true);
    }

    const showUserProfile = () => {
        Navigation.showModalInView({
            componentId,
            name: Constants.ScreenUserProfile,
            passProps: {
                isTeacher: true
            }
        });
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ width: appTheme.getFullAppWidth(), justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator color={appTheme.darkBlue} />
            </View>
        }
        return (<View style={{ flex: 1, width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight() }}>
            <SchoolMenu isViewOnScreen={true} componentId={componentId} showUserProfile={showUserProfile} />
        </View>)
    }

    return (
        <Main mainOptions={mainOptions}>
            {getContent()}
        </Main>
    )
}

export default AppMenu;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    switchMenu: {
        position: "absolute",
        left: 0,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between"
    }
});