import React from "react";

import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity
} from 'react-native';

import VideoPlayer from 'react-simple-video-player';

import AppTheme from "../../../shared/utils/Theme";

import SVGView from "../../../shared/utils/SvgView";
import { default as icDismiss } from "../../../shared/assets/svg/icons/solid/times.svg";


const LiliWebView = (props: any) => {

    const appTheme: AppTheme = new AppTheme();

    // MainOptions
    const { url, } = props;

    const closeTheDocument = () => {
        if (props.closeTheDocument !== undefined) {
            props.closeTheDocument();
        }
    }

    const onBuffer = () => {

    }

    const onError = () => {

    }

    const onEnd = () => {
    }

    return (
        <View />
    )
}

export default LiliWebView;

const styles = StyleSheet.create({
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
});