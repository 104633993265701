import React, { useRef, useState } from "react";

import {
    ScrollView,
    Text,
    View
} from "react-native";
import Button from "../../../designSystem/Button";
import i18n from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";

import TextInput from "../../../designSystem/TextInput/TextInput";
import SignInStyle from "../../../../specific/style/Authentication/SignInStyle";
import { signInWithEmailAndPassword } from "../../../services/Auth";
import { relaunchApp } from "../../../../specific/utils/Navigation/Navigation";
import Alert from "../../Notification/Alert";
import * as Constants from "../../../utils/Constants";

interface SignInFormProps {
    type: "lah" | "las",
    width?: number,
    onSuccess: () => void,
    forgotPasswordRequest: () => void
}

const SignInForm = (props: SignInFormProps) => {

    const appTheme: AppTheme = new AppTheme();
    const style: SignInStyle = new SignInStyle();

    const alertRef = useRef<Alert>(null);

    const { type, width = appTheme.getBlockWidth(), onSuccess, forgotPasswordRequest } = props;

    const [emailAddress, setEmailAddress] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onChangeEmailAddress = (text: string) => {
        setEmailAddress(text);
    }

    const onChangePassword = (text: string) => {
        setPassword(text);
    }

    const showForgotPasswordScreen = () => {
        forgotPasswordRequest();
    }

    const onSignInRequest = () => {
        setIsLoading(true);
        if ((emailAddress.length > 0) && (password.length > 0)) {
            signInWithEmailAndPassword({
                email: emailAddress,
                password: password
            }).then(() => {
                setIsLoading(false);
                onSuccess();
            }).catch((error) => {
                setIsLoading(false);
                let title = "Une erreur est survenue";
                let body = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
                if (error.toString().indexOf("auth/user-disabled") !== -1) {
                    title = "Compte désactivé";
                    body = "Votre compte a été désactivé.\nN'hésitez pas à nous contacter : support@lili.cool";
                } else if ((error.toString().indexOf("auth/invalid-credential") !== -1) || (error.toString().indexOf("auth/wrong-password") !== -1) || (error.toString().indexOf("auth/user-not-found") !== -1)) {
                    title = "Adresse e-mail ou mot de passe erronée";
                    body = "Veuillez vérifier votre adresse e-mail et votre mot de passe s'il vous plaît.\n\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\"";
                } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                    title = "Erreur de connexion 📡";
                    body = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît."
                }
                alertRef.current?.showAlert({
                    title,
                    body
                });
            });
        } else {
            setIsLoading(false);
            alertRef.current?.showAlert({
                title: "Erreur sur votre saisie",
                body: "L'e-mail et le mot de passe ne peuvent pas être vides."
            });
        }
    }

    const buttonColor = type === "las" ? appTheme.schoolColor : appTheme.darkBlue;

    const textInputWidth: number = Math.ceil(width * 0.6);
    const buttonWidth: number = Math.ceil(width * 0.4);

    return (<View style={[style.formContainer]}>
        <TextInput
            placeholder={i18n.t("authentication.signIn.form.emailPlaceholder")}
            style={[{ width: textInputWidth }]}
            onChangeText={onChangeEmailAddress}
            value={emailAddress}
            keyboardType="email-address"
            autoCapitalize="none"
        />
        <TextInput
            placeholder={i18n.t("authentication.signIn.form.passwordPlaceholder")}
            style={[{ width: textInputWidth }]}
            onChangeText={onChangePassword}
            value={password}
            secureTextEntry={true}
            autoCapitalize="none"
            legend={i18n.t("authentication.signIn.form.passwordLegend")}
        />
        <Button
            isLoading={isLoading}
            onPress={onSignInRequest}
            style={{ width: buttonWidth, backgroundColor: buttonColor, marginTop: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(20) }}
            title={i18n.t("authentication.signIn.buttons.signIn")} />
        <Button
            onPress={showForgotPasswordScreen}
            textStyle={{ color: buttonColor, fontSize: appTheme.pixelPerfectForFont(6) }}
            link={true}
            title={i18n.t("authentication.signIn.buttons.forgotPassword")} />
        <Alert ref={alertRef} />
    </View>);

}

export default SignInForm;