import React from "react";
import { useState, useEffect, useRef } from "react";
import * as Navigation from "../../../../specific/utils/Navigation/Navigation";
import Main from "../../../../specific/components/Main/Main";

import {
    View,
    Text,
    Platform,
    StyleSheet,
    ScrollView,
    SafeAreaView
} from 'react-native';

import AppTheme from "../../../utils/Theme";
import * as Constants from "../../../utils/Constants";
import MainOptions from "../../../models/MainOptions";

// Assets
import Images from "../../../../specific/utils/Images";

// Services
import { signInWithEmailAndPassword } from "../../../services/Auth";


import i18n from "../../../utils/Localization/Localization";

// UI
import Button from "../../../designSystem/Button";
import Image from "../../../designSystem/OTBImage";
import TextInput from "../../../designSystem/TextInput";

import Alert from "../../Notification/Alert";

import { useNavigate, useParams } from "react-router-dom";

const TeacherSignIn = (props: any) => {

    let propsData = props;

    const [isLoading, setIsLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertAttributes, setAlertAttributes] = useState({ title: "", body: "" });

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    let blockWidthMax = appTheme.pixelPerfect(480);
    let blockWidth = appTheme.getFullAppWidth() - appTheme.pixelPerfect(40);
    if (blockWidth > blockWidthMax) {
        blockWidth = blockWidthMax;
    }

    let fontSizefactor = (appTheme.getFullAppWidth() - appTheme.pixelPerfect(40)) / blockWidth;
    if (fontSizefactor < 1) {
        fontSizefactor = 1;
    } else if (fontSizefactor > 1.5) {
        fontSizefactor = 1.5;
    }

    const pixelPerfectForOnBoarding = (size: number) => {
        return appTheme.pixelPerfect(size) * fontSizefactor;
    }

    // UI
    const { isTeacher, isCas = false } = propsData;
    const buttonBackgroundColor = appTheme.schoolColor;
    const viewBackgroundColor = appTheme.schoolBackgroundColor;
    const mainImageHeight = appTheme.getFullScreenHeight() / 3;

    const alertRef = useRef<Alert>(null);

    // MainOptions
    const { componentId = "" } = props;
    const mainOptions: MainOptions = new MainOptions({
        key:"teacher_signin",
        componentId,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        backgroundColor: viewBackgroundColor,
        canGoBack: true
    });


    const onChangeEmailAddress = (emailAddress: string) => {
        setEmailAddress(emailAddress);
    }

    const onChangePassword = (password: string) => {
        setPassword(password);
    }

    const onSignInRequest = async () => {
        setIsLoading(true);
        if ((emailAddress.length > 0) && (password.length > 0)) {
            if (isCas === true) {
                fetch("https://val.reseau-canope.fr/cas-usagers/v1/users?Username="+emailAddress+"&Password="+password, {
                    mode:"cors",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Autorization": "Basic ZXNuLXRuZTpjODg4YW4wcGVUbmUhIQ==",
                        "Connection": "keep-alive",
                        "Access-Control-Allow-Origin": "*"
                    }
                }).then(response => response.json())
                .then(result => {
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                });
            } else {
                signInWithEmailAndPassword({
                    email: emailAddress,
                    password: password
                }).then(() => {
                    setIsLoading(false);
                    const { componentId } = props;
                    Navigation.relaunchApp({
                        componentId,
                        navigate
                    });
                }).catch((error) => {
                    setIsLoading(false);
                    let title = "Une erreur est survenue";
                    let body = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
                    if (error.toString().indexOf("auth/user-disabled") !== -1) {
                        title = "Compte désactivé";
                        body = "Votre compte a été désactivé.\nN'hésitez pas à nous contacter : support@lili.cool";
                    } else if ((error.toString().indexOf("auth/invalid-credential") !== -1) || (error.toString().indexOf("auth/wrong-password") !== -1) || (error.toString().indexOf("auth/user-not-found") !== -1)) {
                        title = "Adresse e-mail ou mot de passe erronée";
                        body = "Veuillez vérifier votre adresse e-mail et votre mot de passe s'il vous plaît.\n\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\"";
                    } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                        title = "Erreur de connexion 📡";
                        body = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît."
                    }
                    alertRef.current?.showAlert({
                        title,
                        body
                    });
                });
            }
        } else {
            setIsLoading(false);
            alertRef.current?.showAlert({
                title: "Erreur sur votre saisie",
                body: "L'e-mail et le mot de passe ne peuvent pas être vides."
            });
        }
    }

    const openGeneralConditions = () => {

    }

    const showSignUpScreen = () => {
        if (props.onFlipRequest !== undefined) {
            props.onFlipRequest();
        }
    }

    const showForgotPasswordScreen = () => {
        Navigation.push({
            componentId,
            navigate,
            name: Constants.ScreenForgotPassword,
            passProps: {
                context: Constants.CONTEXT.teacher,
                emailAddress
            }
        })
    }

    const signUpButton = isCas === true ? <View /> : <Button
        onPress={showSignUpScreen}
        textStyle={{ color: appTheme.darkBlue }}
        link={true}
        title={i18n.t("authentication.signIn.buttons.signUp")} />;
    const signUpUpButton = <View style={{ width: appTheme.getFullAppWidth(), alignItems: "flex-end", height: appTheme.pixelPerfect(40), justifyContent: "center", paddingEnd: appTheme.pixelPerfect(10) }}>{signUpButton}</View>;
    const birdPosition = (appTheme.getFullAppWidth() - blockWidth) / 2;
    const forgotPassword = isCas === true ? <View /> : <View style={{ marginTop: appTheme.pixelPerfect(30) }}>
        <Button
            onPress={showForgotPasswordScreen}
            textStyle={{ color: appTheme.darkBlue }}
            link={true}
            title={i18n.t("authentication.signIn.buttons.forgotPassword")} />
    </View>;
    return (
        <View style={{ flex: 1 }}>
            <Image source={images.bgOnboardingTeacher} style={{ position: "absolute", top: 0, width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight() }} resizeMode="cover" />
            {signUpUpButton}
            <SafeAreaView style={[styles.main, { width: appTheme.getFullAppWidth() }]}>
                <View style={[styles.main, { width: appTheme.getFullAppWidth(), padding: appTheme.pixelPerfect(10) }]}>
                    <Image source={images.icLiliAtSchoolCircle} style={{ width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(70) }} resizeMode="contain" />
                    <View style={{ justifyContent: "space-evenly", alignItems: "center" }}>
                        <Text style={[{ textAlign: "center", fontSize: appTheme.pixelPerfectForFont(10), fontWeight: "500", letterSpacing: 0.8 }, { fontFamily: appTheme.primaryFont, marginTop: appTheme.pixelPerfect(20), color: appTheme.schoolColor }]}>{i18n.t("authentication.signIn.title").toLocaleUpperCase()}</Text>
                    </View>
                    <View style={{ flex: 1 }}>
                        <ScrollView style={{ flex: 1 }}>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", marginTop: appTheme.pixelPerfect(10) }}>
                                <View style={{ flex: 1, width: appTheme.getFullAppWidth(), alignItems: "center", justifyContent: "center" }}>
                                    <View style={{
                                        flex: 1,
                                        borderRadius: appTheme.pixelPerfect(20),
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: appTheme.pixelPerfect(20),
                                        width: blockWidth,
                                        backgroundColor: appTheme.white,
                                        paddingVertical: appTheme.pixelPerfect(20),
                                        paddingHorizontal: appTheme.pixelPerfect(10)
                                    }}>
                                        <View style={{ marginBottom: appTheme.pixelPerfect(10), height: appTheme.pixelPerfect(200) }}>
                                            <TextInput
                                                label={i18n.t("authentication.signIn.form.emailPlaceholder")}
                                                style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                                                onChangeText={onChangeEmailAddress}
                                                value={emailAddress}
                                                keyboardType="email-address"
                                                autoCapitalize="none"
                                            />
                                            <TextInput
                                                label={i18n.t("authentication.signIn.form.passwordPlaceholder")}
                                                password={true}
                                                style={[{ borderColor: appTheme.secondaryColor, color: appTheme.darkBlue }]}
                                                onChangeText={onChangePassword}
                                                value={password}
                                                secureTextEntry={true}
                                                autoCapitalize="none"
                                                legend={i18n.t("authentication.signIn.form.passwordLegend")}
                                            />
                                        </View>
                                        <View style={{ flex: 1, alignItems: "center" }}>
                                            <View style={{ marginTop: appTheme.pixelPerfect(20) }}>
                                                <Button
                                                    isLoading={isLoading}
                                                    onPress={onSignInRequest}
                                                    style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.schoolColor }}
                                                    title={i18n.t("authentication.signIn.buttons.signIn")} />
                                            </View>
                                            {forgotPassword}
                                        </View>
                                    </View>
                                    <Image source={images.icBird} style={{ position: "absolute", top: -appTheme.pixelPerfect(8), right: birdPosition, width: appTheme.pixelPerfect(75), height: appTheme.pixelPerfect(72) }} resizeMode="contain" />
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </SafeAreaView>
            <Alert ref={alertRef} />
        </View>
    )
}

export default TeacherSignIn;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});