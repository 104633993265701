import React from "react";

import {
    Image, ImageProps
} from 'react-native';

interface bundleImage {
    uri: string;
}

const OtbImage = (props: ImageProps & { remoteSource?: string })  => {

    return (
        <Image {...props} />
    )

}

export default OtbImage;