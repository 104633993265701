import { initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, setDoc, onSnapshot, runTransaction } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import User from "../../shared/data/user/User";
import { DBCollectionUsers, DBCollectionCodes, DBCollectionActions, DBCollectionAdvices, DBCollectionRCCustomers, DBCollectionSchools, DBCollectionGifts, DBCollectionStripeCheckout, DBCollectionStats, DBCollectionCategories, DBCollectionActivities, DBCollectionNews } from '../../shared/utils/Constants';
import { dbCurrentUser } from "./Specific_Auth";
import { build } from "../../../package.json";


import Categories from "../../shared/data/jsons/bundleCategories.json";
import Activities from "../../shared/data/jsons/bundleActivities.json";
import PremiumCards from "../../shared/data/jsons/premiumCards.json";
import { getLocalNews, recordLocalNews, recordSessionsActivities, recordSessionsCategories, recordSessionsPremiumCards } from "../utils/LocalStorage";
import { PremiumCard } from "../../shared/components/LiliHQ/LHQCreateCode";

let dbListeners = [];
export let app = undefined;
export let db = undefined;
export let auth: Auth = undefined;
export let storage = undefined;

export let env: "PROD" | "DEV" = "DEV";
let host = window.location.hostname;

if ((host !== "app-tne.lili.cool") && (host !== "app-tne-demo.lili.cool")) {
    let firebaseConfig = {
        apiKey: "AIzaSyAo3HkaSmxlMO9YlMg7qIBOOLU70ENJWcQ",
        authDomain: "lili-dev-608f0.firebaseapp.com",
        databaseURL: "https://lili-dev-608f0.firebaseio.com",
        projectId: "lili-dev-608f0",
        storageBucket: "lili-dev-608f0.appspot.com",
        messagingSenderId: "766371018901",
        appId: "1:766371018901:web:dcdff94cdbace262f3039c"
    };
    if ((host === "app.lili.cool") || (host === "lili-dev-608f0.web.app") || (host === "centralperk.web.app")) {
        env = "PROD";
        firebaseConfig = {
            apiKey: "AIzaSyD5fQrShi1qQCgbvQubqA4r6yQzcmd_qlI",
            authDomain: "lili-c7853.firebaseapp.com",
            databaseURL: "https://lili-c7853.firebaseio.com",
            projectId: "lili-c7853",
            storageBucket: "lili-c7853.appspot.com",
            messagingSenderId: "908054617461",
            appId: "1:908054617461:web:473c3eaf0a96c3af85073c",
            measurementId: "G-P17JN801Y5"
        };
    }

    app = initializeApp(firebaseConfig);
    auth = getAuth(app);
    db = getFirestore(app);
    storage = getStorage(app);
} else {
    env = "PROD";
}

export const initFirebase = () => {

}

/**
 * 
 * ADMIN
 */
export const getUsersStats = async () => {
    try {
        const userStatRef = doc(db, DBCollectionStats, "users");
        const querySnapshot = await getDoc(userStatRef);
        if (querySnapshot.exists() === true) {
            const result = querySnapshot.data();
            return result;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

/**
 * SCHOOL
 */
export const getSchoolId = async (data: { name: string, address: string, zipcode: string, city: string, country: string }) => {
    const { name, address, zipcode, city, country } = data;
    try {
        const schoolRef = collection(db, DBCollectionSchools);
        const schoolQuery = query(schoolRef, where("name", "==", name), where("zipcode", "==", zipcode), where("city", "==", city));
        const querySnapshot = await getDocs(schoolQuery);
        if (querySnapshot.empty === false) {
            const result = querySnapshot.docs;
            return result[0].id;
        } else {
            const newSchoolId = await createSchool({ name, address, zipcode, city, country });
            return newSchoolId;
        }
    } catch (error) {
        throw error;
    }
};

export const createSchool = async (data: { name: string, address: string, zipcode: string, city: string, country: string }) => {
    try {
        const newSchoolData = await addDocument({ aCollection: DBCollectionSchools, content: data });
        return newSchoolData.id;
    } catch (error) {
        throw error;
    }
}

/**
 * GIFT
 */

export const createGiftForUser = async (data: { gift: any }) => {
    const { gift } = data;
    try {
        const newGift = await addDocument({ aCollection: DBCollectionGifts, content: gift });
        return {
            isCreated: true,
            id: newGift.id
        };
    } catch (error) {
        return {
            isCreated: false,
            id: ""
        };
    }
}

/**
 * 
 * USER
 */
export const getUserWithId = async (data: { user_id: string, source?: "server" | "cache" | "default" }) => {
    const { user_id, source = "server" } = data;
    try {
        const getOptions = {
            source
        };
        const documentRef = doc(db, DBCollectionUsers, user_id);
        const querySnapshot = await getDoc(documentRef);
        if (querySnapshot.exists() === true) {
            const result = querySnapshot.data();
            return result;
        } else {
            return undefined;
        }
    } catch (error) {
        throw error;
    }
}

export const getUserWithEmail = async (data: { user_email: string, like?: boolean }) => {
    const { user_email, like = false } = data;
    try {
        const documentRef = collection(db, DBCollectionUsers);
        let documentQuery = query(documentRef, where("email", "==", user_email));
        if (like === true) {
            documentQuery = query(documentRef, where("email", "==", user_email));
        }
        const querySnapshot = await getDocs(documentQuery);
        if (querySnapshot.empty === false) {
            const userDocs = querySnapshot.docs;
            let userData = [];
            for (const aUserDocsIndex in userDocs) {
                if (Object.prototype.hasOwnProperty.call(userDocs, aUserDocsIndex)) {
                    const aUserDoc = userDocs[aUserDocsIndex];
                    userData.push({
                        data: aUserDoc.data(),
                        key: aUserDoc.id
                    })
                }
            }
            return userData;
        } else {
            return undefined;
        }
    } catch (error) {
        throw error;
    }
}

export const setDBActivity = async (data: { key: string, value: any }) => {
    const { key, value } = data;
    try {
        console.log({ value });
        const activityRef = doc(db, DBCollectionActivities, key);
        const setResult = await setDoc(activityRef, value);
        return setResult;
    } catch (error) {
        throw error;
    }
}

/******************** START - JMN TRIES TO ADD A NEW DOCUMENT IN DB CATEGORIES  *******************/

export const setDBCategory = async (data: { key: string, value: any }) => {
    const { key, value } = data;
    try {
        console.log({ value });
        const categoryRef = doc(db, DBCollectionCategories, key);
        const setResult = await setDoc(categoryRef, value);
        return setResult;
    } catch (error) {
        throw error
    }
}
/******************** END - JMN TRIES TO ADD A NEW DOCUMENT IN DB CATEGORIES  *******************/

export const addDataToUser = async (data: { key: string, value: any, user_id: string }) => {
    const { user_id, key, value } = data;
    const last_update = new Date().getTime();
    try {
        const userRef = doc(db, DBCollectionUsers, user_id);
        const updateResult = await updateDoc(userRef, {
            [key]: value,
            last_update
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

export const createPremiumCardForCustomers = async (premiumCard: PremiumCard) => {
    try {
        const creationResult = await setDocument({ aCollection: 'db_premium_cards', content: premiumCard, key: premiumCard.code });
        return creationResult;
    } catch (error) {
        throw error;
    }
}


export const addUserWithData = async (data: { user_id: string, user_email: string, school_name: string, school_zipcode: string, opt_in: boolean, sub_id?: string, duration_in_month?: number, addCode?: boolean }) => {
    try {
        const { user_id, user_email, school_name, school_zipcode, opt_in, sub_id, duration_in_month = 1, addCode = false } = data;
        const today = new Date();
        let userData = {
            creation_date: today.getTime(),
            email: user_email,
            optIn: opt_in,
            sub: undefined
        }
        if (sub_id !== undefined) {
            let expires_date = new Date();
            expires_date.setMonth(today.getMonth() + duration_in_month);
            userData.sub = {
                creationDate: today.getTime(),
                start_date: today.getTime(),
                expires_date: expires_date.getTime(),
                auto_renewing: false,
                origin: "lili",
                product_id: sub_id,
            }
        }
        // On va créer l'utilisateur
        await setDocument({ aCollection: DBCollectionUsers, key: user_id, content: userData });

        // On va lui affecter un code
        const addCodeResult = await createTeacherCode({ user_id, user_email, school_name, school_zipcode });
        return addCodeResult;
    } catch (error) {
        throw error;
    }
}

export const addPreSubToUser = async (data: { user_id: string, sub_id: string, duration_in_month: number, presubId: string }) => {
    const { user_id, sub_id, duration_in_month, presubId } = data;
    const start_date = new Date();
    let expires_date = new Date();
    expires_date.setMonth(start_date.getMonth() + duration_in_month);
    try {
        const updateResult = await addDataToUser({
            key: "sub", value: {
                creationDate: start_date.getTime(),
                start_date: start_date.getTime(),
                expires_date: start_date.getTime(),
                pre_expires_date: expires_date.getTime(),
                pre_sub_id: presubId,
                auto_renewing: false,
                origin: "lili",
                product_id: sub_id
            }, user_id
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

export const markSucessToSubToUser = async (data: { user_id: string }) => {
    const { user_id } = data;
    try {
        const updateResult = await addDataToUser({
            key: "sub",
            value: {
                product_id: "stripe_success"
            },
            user_id
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

export const removePreSubToUser = async (data: { user_id: string }) => {
    const { user_id } = data;
    const start_date = new Date();
    try {
        const updateResult = await addDataToUser({
            key: "sub",
            value: {
                creationDate: start_date.getTime(),
                start_date: start_date.getTime(),
                expires_date: start_date.getTime(),
                pre_expires_date: start_date.getTime(),
                auto_renewing: false,
                origin: "lili",
                product_id: "stripe_cancel"
            },
            user_id
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

export const addFilterToUser = async (data: { user_id: string, filters: string[] }) => {
    const { user_id, filters } = data;
    try {
        const updateResult = await addDataToUser({
            key: "filter_by_level",
            value: filters,
            user_id
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

export const addSubToUser = async (data: { user_id: string, sub_id: string, duration_in_month?: number, expiration_date?: number, origin?: string, card_id?: string }) => {
    const { user_id, sub_id, duration_in_month, expiration_date, origin = "lili", card_id = "" } = data;
    const start_date = new Date();
    let expires_date = new Date();
    if (expiration_date !== undefined) {
        expires_date = new Date(expiration_date);
    } else {
        expires_date.setMonth(start_date.getMonth() + duration_in_month);
    }
    try {
        const updateResult = await addDataToUser({
            key: "sub",
            value: {
                creationDate: start_date.getTime(),
                start_date: start_date.getTime(),
                expires_date: expires_date.getTime(),
                auto_renewing: false,
                origin,
                product_id: sub_id,
                card_id
            },
            user_id
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

// User deletion
export const deleteUser = async (data: { user_id: string }) => {
    const { user_id } = data;
    try {
        await deleteDoc(doc(db, DBCollectionAdvices, user_id));
        await deleteDoc(doc(db, DBCollectionActions, user_id));
        await deleteDoc(doc(db, DBCollectionRCCustomers, user_id));
        await deleteDoc(doc(db, DBCollectionUsers, user_id));
        // Suppression de l'utilisateur dans l'auth
        const authUser = await dbCurrentUser();
        if ((authUser !== null) && (authUser !== undefined)) {
            // TODO : Suppression utilisateur
            //await authUser.delete();
        }
        return true
    } catch (error) {
        throw error;
    }
}

export const createTeacherCode = async (data: { user_id: string, user_email: string, school_name: string, school_zipcode: string }) => {
    try {
        const { user_id, user_email, school_name, school_zipcode } = data;
        const randomCode = Math.floor(100000 + Math.random() * 900000);
        const codeCreationResult = await addDocument({
            aCollection: DBCollectionCodes, content: {
                classroom: "lili",
                code: randomCode,
                count: 1,
                creation_date: new Date().getTime(),
                email: user_email,
                expirationDate: -1,
                firstname: "",
                lastname: "",
                grade: [],
                school: school_name + ", " + school_zipcode,
                status: "activated",
                type: "teacher",
                validity: -1
            }
        });

        const linkCodeToUserResult = await addDataToUser({
            key: "code", value: {
                code: randomCode,
                expirationDate: -1,
                key: codeCreationResult.id
            }, user_id
        });
        return linkCodeToUserResult;
    } catch (error) {
        throw error;
    }
}

export const getCollection = async ({ aCollection, query }) => {
    try {
        const collectionRef = collection(db, aCollection);
        const collectionQuery = query(collectionRef);
        const querySnapshot = await getDocs(collectionQuery);
        if (querySnapshot.empty === false) {
            let result = [];
            for (const aDocIndex in querySnapshot.docs) {
                if (Object.hasOwnProperty.call(querySnapshot.docs, aDocIndex)) {
                    const anElement = querySnapshot.docs[aDocIndex];
                    let aDoc = {
                        key: anElement.id,
                        data: anElement.data()
                    }
                    result.push(aDoc);
                }
            }
            return result;
        } else {
            return [];
        }
    } catch (error) {
        throw error;
    }
}

export const addDocument = async (data: { aCollection: string, content: any }) => {
    try {
        const { aCollection, content } = data;
        let dataReference = await addDoc(collection(db, aCollection), content);
        return dataReference;
    } catch (error) {
        throw error;
    }
}

export const setDocument = async (data: { aCollection: string, key: string, content: any }) => {
    try {
        const { aCollection, key, content } = data;
        let result = await setDoc(doc(db, aCollection, key), content);
        return result;
    } catch (error) {
        throw error;
    }
}

// GAMIFICATION
export const getUserActions = async (data: { user_id: string }) => {
    const { user_id } = data;
    try {
        const querySnapshot = await getDoc(doc(db, DBCollectionActions, user_id));
        if (querySnapshot.exists() === true) {
            const result = querySnapshot.data();
            return result;
        } else {
            return undefined;
        }
    } catch (error) {
        throw error;
    }
}

// LISTENERS
export const revokeDBListeners = () => {

}
export const launchUserListener = async (data: { user_id: string }) => {
    const { user_id } = data;
    onSnapshot(doc(db, "users", user_id), (doc) => {
        updateUserData({ user_data: doc.data() });
    });
}

export const updateUserData = async (data: { user_data: User }) => {
    const { user_data } = data;
    const user: User = User.getInstance();
    await user.setDBUserData(user_data);
    await user.saveUserData();
}

// Déblocage de badges
export const unlockedTheBadge = async (data: { badgeId: string, user_id: string }) => {
    const { badgeId, user_id } = data;
    const last_update = new Date().getTime();
    try {
        const actionsRef = doc(db, 'actions', user_id);
        let actionDoc = await getDoc(actionsRef);
        if (actionDoc.exists() === true) {
            let userActionData = actionDoc.data();
            userActionData.badges[badgeId].status = "unlocked";
            setDocument({ aCollection: "actions", key: user_id, content: userActionData });
        } else {
            setDocument({
                aCollection: "actions", key: user_id, content: {
                    badges: {
                        [badgeId]: {
                            status: "unlocked"
                        }
                    }
                }
            });
        }
        return true;
    } catch (error) {
        throw error;
    }
}

//Nouvelle Gamification
export const onActivityCompleted = async (data: { activityKey: string, iteration: number, categoryKey: string, sectionKey: string, detail: string, menu: string }) => {
    try {
        const user: User = User.getInstance();
        const { activityKey, iteration = 1, categoryKey, sectionKey, detail, menu } = data;
        const numberOfPointsForActivity = 10;
        if ((activityKey !== undefined) && (user !== undefined)) {
            const pointsGained = iteration * numberOfPointsForActivity;
            const userActionRef = doc(db, "actions", user.uid);
            // On crée l'historique des points de l'enfant
            const date = new Date();
            const historyData = {
                ts: date.getTime(),
                action: "activity",
                type: "modal",
                pts: pointsGained,
                uid: activityKey,
                sId: sectionKey,
                menu,
                sbId: categoryKey,
                nbOfIteration: iteration,
                detail: detail !== undefined ? detail : "",
                build
            };
            await runTransaction(db, async transaction => {
                let userActions = await transaction.get(userActionRef);
                if (userActions.data() !== undefined) {
                    let userActionData = userActions.data();
                    let pts = 0;
                    if (userActionData.pts !== undefined) {
                        pts = userActionData.pts;
                    }
                    pts = pts + pointsGained;
                    let history = [];
                    if (userActionData.history !== undefined) {
                        history = userActionData.history;
                    }
                    history.push(historyData);
                    const latest = [historyData];
                    transaction.update(userActionRef, { pts, history, latest });
                    return true;
                } else {
                    const newData = {
                        pts: pointsGained,
                        history: [historyData],
                        badges: {},
                        level: 1,
                    };
                    transaction.set(userActionRef, newData);
                    return true;
                }
            });
        }
    } catch (error) {
        throw error;
    }

}

export const updateActions = (data: { pts: number, last_connexion_date: number, days_in_a_row: number }) => {
    const { pts, last_connexion_date, days_in_a_row } = data;
    const user: User = User.getInstance();
    const userActionRef = doc(db, "actions", user.uid);
    updateDoc(userActionRef, { pts, last_connexion_date, days_in_a_row, last_update: new Date().getTime() });
}

export const userActionsListener = async (data: { user_id: string }) => {
    const { user_id = "" } = data;
    const user: User = User.getInstance();
    const dbUserActionListener = onSnapshot(doc(db, DBCollectionActions, user_id), async (documentSnapshot) => {
        if (documentSnapshot.exists() === true) {
            await user.setActionsUserData(documentSnapshot.data());
        }
    });
    if (dbListeners !== undefined) {
        dbListeners.push(dbUserActionListener);
    } else {
        dbListeners = [dbUserActionListener];
    }
}

export const categoriesListener = async () => {
    let localCategoriesData = Categories.data;
    await recordSessionsCategories(localCategoriesData);
    const dbCategoryQuery = query(collection(db, DBCollectionCategories), where("last_update", ">=", Categories.ts));
    const dbCategoriesListener = onSnapshot(dbCategoryQuery, async (querySnapshot) => {
        if (querySnapshot.empty === false) {
            for (const aCategoryDoc in querySnapshot.docs) {
                if (Object.prototype.hasOwnProperty.call(querySnapshot.docs, aCategoryDoc)) {
                    const element = querySnapshot.docs[aCategoryDoc];
                    let aCategory = element.data();
                    localCategoriesData[aCategory.key] = aCategory;
                }
            }
        }
        await recordSessionsCategories(localCategoriesData);
    });
    if (dbListeners !== undefined) {
        dbListeners.push(dbCategoriesListener);
    } else {
        dbListeners = [dbCategoriesListener];
    }
}

export const activitiesListener = async () => {
    let localActivitiesData = Activities.data;
    await recordSessionsCategories(localActivitiesData);
    const dbUserQuery = query(collection(db, DBCollectionActivities), where("last_update", ">=", Activities.ts));
    const dbActivitiesListener = onSnapshot(dbUserQuery, async (querySnapshot) => {
        if (querySnapshot.empty === false) {
            for (const anActivityDoc in querySnapshot.docs) {
                if (Object.prototype.hasOwnProperty.call(querySnapshot.docs, anActivityDoc)) {
                    const element = querySnapshot.docs[anActivityDoc];
                    let anActivity = element.data();
                    localActivitiesData[anActivity.key] = anActivity;
                }
            }
        }
        await recordSessionsActivities(localActivitiesData);
    });
    if (dbListeners !== undefined) {
        dbListeners.push(dbActivitiesListener);
    } else {
        dbListeners = [dbActivitiesListener];
    }
}

export const premiumCardListener = async () => {
    let localPremiumCards = PremiumCards.data;
    await recordSessionsPremiumCards(localPremiumCards);
    const dbUserQuery = query(collection(db, "db_premium_cards"), where("last_update", ">=", PremiumCards.ts));
    const dbPremiumCardsListener = onSnapshot(dbUserQuery, async (querySnapshot) => {
        if (querySnapshot.empty === false) {
            for (const anActivityDoc in querySnapshot.docs) {
                if (Object.prototype.hasOwnProperty.call(querySnapshot.docs, anActivityDoc)) {
                    const element = querySnapshot.docs[anActivityDoc];
                    let aPremiumCard = element.data();
                    localPremiumCards[aPremiumCard.code] = aPremiumCard;
                }
            }
        }
        await recordSessionsPremiumCards(localPremiumCards);
    });
    if (dbListeners !== undefined) {
        dbListeners.push(dbPremiumCardsListener);
    } else {
        dbListeners = [dbPremiumCardsListener];
    }
}

export const getUserGiftCards = async (data: { user_email: string }) => {
    try {
        const { user_email } = data;
        const dbCardQuery = query(collection(db, "cards"), where("customer_email", "==", user_email));
        const querySnapshot = await getDocs(dbCardQuery);
        if (querySnapshot.empty === false) {
            let result = [];
            for (const aDocIndex in querySnapshot.docs) {
                if (Object.hasOwnProperty.call(querySnapshot.docs, aDocIndex)) {
                    const anElement = querySnapshot.docs[aDocIndex];
                    let aDoc = {
                        key: anElement.id,
                        data: anElement.data()
                    }
                    result.push(aDoc);
                }
            }
            return result;
        } else {
            return [];
        }

    } catch (error) {
        throw error
    }
}

export const redeemedCardInDb = async (data: { card: any, user: User }) => {
    const { card, user } = data;
    try {
        const cardRef = doc(db, "cards", card.key);
        await updateDoc(cardRef, { status: "redeemed", user_email: user.email, user_key: user.uid, redeemed_date: new Date().getTime() });
        return true;
    } catch (error) {
        throw error;
    }
}

export const transfertCardToNewUser = async (data: { card: any, user_email: string }) => {
    const { card, user_email } = data;
    try {
        const cardRef = doc(db, "cards", card.key);
        await updateDoc(cardRef, { customer_email: user_email });
        return true;
    } catch (error) {
        throw error;
    }
}

export const getCardWithCode = async (data: { code: string }) => {
    const { code } = data;
    try {
        const querySnapshot = await getDoc(doc(db, "db_premium_cards", code));
        if (querySnapshot.exists() === true) {
            const premiumCard = querySnapshot.data();
            return premiumCard;
        } else {
            return undefined;
        }
    } catch (error) {
        throw error;
    }
}

export async function redeemedPremiumCardInDb(data: { card: PremiumCard, user: User }) {
    const { card, user } = data;
    try {
        const cardRef = doc(db, "db_premium_cards", card.code);
        await updateDoc(cardRef, { status: "redeemed", user_email: user.email, user_key: user.uid, redeemed_date: new Date().getTime() });
        return true;
    } catch (error) {
        throw error;
    }
}

export const launchNewsListener = async () => {
    try {
        console.log("LaunchNewListener")
        let localNews = await getLocalNews();
        let localNewsData = localNews.data;
        console.log({ db });
        const dbCardQuery = query(collection(db, DBCollectionNews), where("last_update", ">=", localNews.ts));
        const dbNewsListener = onSnapshot(dbCardQuery, async (collectionSnapshot) => {
            if (collectionSnapshot.empty === false) {
                for (const aNewsDoc in collectionSnapshot.docs) {
                    if (Object.prototype.hasOwnProperty.call(collectionSnapshot.docs, aNewsDoc)) {
                        const element = collectionSnapshot.docs[aNewsDoc];
                        console.log({ element });
                        let aNews = element.data();
                        localNewsData[element.id] = aNews;
                    }
                }
            }
            await recordLocalNews({ data: localNewsData, ts: new Date().getTime() });
        });

    } catch (error) {
        throw error
    }
    if (dbListeners !== undefined) {
        dbListeners.push(dbNewsListener);
    } else {
        dbListeners = [dbNewsListener];
    }
}

// Récupération des utilisateurs
export const getTeachersForAnalyse = async () => {
    /*
    const schoolQuerySnapshot = await collection()
    let schoolRef = db.collection("db_schools");
    console.log("YO ?");
    schoolRef.get().then((querySnapshot) => {
        let schoolList = {};
        for (const aDocIndex in querySnapshot.docs) {
            let aSchool = querySnapshot.docs[aDocIndex];
            const aSchoolData = aSchool.data();
            const aSchoolKey = aSchool.id;
            schoolList[aSchoolKey] = aSchoolData;
        }
        let dbRef = db.collection('users').where("creation_build", ">", 347);
        //1623297600
        return dbRef.get().then((querySnapshot) => {
            let teachers = [];
            for (const aDocIndex in querySnapshot.docs) {
                if (querySnapshot.docs.hasOwnProperty(aDocIndex)) {
                    let aDoc = querySnapshot.docs[aDocIndex];
                    let aDocData = aDoc.data();
                    if (aDocData.code !== undefined) {
                        let creation_date = "inconnue";
                        if (aDocData.creation_date !== undefined) {
                            creation_date = new Date(aDocData.creation_date).toLocaleDateString();
                        }
                        const schoolId = aDocData.school_id;
                        let schoolName = "";
                        let schoolAddress = "";
                        let schoolZipCode = "";
                        let schoolCity = "";
                        let schoolCountry = "";
                        if (schoolList[schoolId] !== undefined) {
                            schoolName = schoolList[schoolId].name;
                            schoolAddress = schoolList[schoolId].address;
                            schoolZipCode = schoolList[schoolId].zipcode;
                            schoolCity = schoolList[schoolId].city;
                            schoolCountry = schoolList[schoolId].country;
                        }
                        teachers.push({
                            user_key: aDoc.id,
                            creation_date,
                            email: aDocData.email,
                            firstname: aDocData.firstname,
                            lastname: aDocData.lastname,
                            optIn: aDocData.optInNewsletter,
                            schoolName,
                            schoolAddress,
                            schoolCountry,
                            schoolZipCode,
                            schoolCity,
                            school_key: schoolId,
                            classes: JSON.stringify(aDocData.classes)
                        })
                    }
                }
            }
            return teachers;
        });
    }).catch((error) => {
        console.log({error});
        throw(error);
    });
    */
}