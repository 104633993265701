import React, { useEffect, useState } from "react";

import {
    View,
    Text,
    ViewStyle,
    TextStyle
} from "react-native";
import AppTheme from "../../utils/Theme";
import Images from "../../../specific/utils/Images";
import GameStyle from "../../styles/game/GameStyle";

interface GameTimerProps {
    color: string,
    gameTime?: number,
    isMute?: boolean,
    playASound: (sound: string, source?: string) => void,
    pauseTimer: boolean,
    gameId: number,
    onGameTimeEnd:() => void
}

let easterEggSoundNumberOne = true;

const GameTimer = (props: GameTimerProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const gameStyle: GameStyle = new GameStyle();

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

    const { color, gameTime = 0, playASound, gameId, onGameTimeEnd } = props;

    const [onScreenGameId, setOnScreenGameId] = useState<number>(gameId);
    const [time, setTime] = useState<number>(gameTime);
    const [countdownTime, setCountdownTime] = useState<number>(-1);

    const onViewDidAppear = () => {
        setViewDidAppear(true);
        launchCountdown(time);
    }

    const launchCountdown = (newTime: number) => {
        setCountdownTime(newTime);
    }

    const playTicTacSound = (currentTime:number) => {
        const muteSound = props.isMute !== undefined ? props.isMute : true;
        if (muteSound === false) {
            let nameOfTheSound = easterEggSoundNumberOne === true ? 'tic.mp3' : 'tac.mp3';
            easterEggSoundNumberOne = !easterEggSoundNumberOne;
            if (currentTime < 11) {
                nameOfTheSound = "tictac.mp3";
            }
            playASound(nameOfTheSound, "app");
        }
    }

    useEffect(() => {
        if ((countdownTime > -1) && (props.pauseTimer === false)) {
            onCountdownAction();
        }
    }, [countdownTime]);

    useEffect(() => {
        if ((countdownTime > -1) && (props.pauseTimer === false)) {
            onCountdownAction();
        }
    }, [props]);

    const onCountdownAction = () => {
        setTimeout(() => {
            if (countdownTime > 0) {
                playTicTacSound(countdownTime - 1);
                launchCountdown(countdownTime - 1);
            } else {
                onGameTimeEnd();
            }
        }, 1000);
    }

    const getMinAndSecForCountdown = (timeInSec: number) => {
        if (timeInSec === -1) {
            return { minDigit: 0, minUnit: 0, secDigit: 0, secUnit: 0 };
        }
        const sec = timeInSec;
        let minutes = Math.floor(sec / 60);
        let seconds = sec - (minutes * 60);
        let minDigit = 0;
        let minUnit = 0;
        let secDigit = 0;
        let secUnit = 0;
        if (minutes < 10) {
            minDigit = 0;
            minUnit = minutes;
        } else {
            minDigit = Math.floor(minutes / 10);
            minUnit = minutes - (minDigit * 10);
        }
        if (seconds < 10) {
            secDigit = 0;
            secUnit = seconds;
        } else {
            secDigit = Math.floor(seconds / 10);
            secUnit = seconds - (secDigit * 10);
        }
        return { minDigit, minUnit, secDigit, secUnit };
    }

    const countdownCardStyle: ViewStyle = {
        height: appTheme.pixelPerfect(40),
        width: appTheme.pixelPerfect(30),
        borderRadius: appTheme.pixelPerfect(6),
        justifyContent: 'center',
        alignItems: 'center',
        marginEnd: appTheme.pixelPerfect(6),
        backgroundColor: appTheme.white
    };

    const hoursAndMintuesSeperationView: ViewStyle = {
        ...countdownCardStyle,
        backgroundColor: "transparent"
    }

    const countdownCardTextStyle: TextStyle = {
        fontSize: appTheme.pixelPerfectForFont(30),
        paddingLeft: appTheme.pixelPerfect(2)
    };

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ width: 1, height: 1 }} />
        }

        const { minDigit, minUnit, secDigit, secUnit } = getMinAndSecForCountdown(countdownTime);
        return <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: "center", marginVertical: appTheme.pixelPerfect(10) }}>
            <View style={[countdownCardStyle]}>
                <Text style={[countdownCardTextStyle, { color }]}>{minDigit}</Text>
            </View>
            <View style={[countdownCardStyle]}>
                <Text style={[countdownCardTextStyle, { color }]}>{minUnit}</Text>
            </View>
            <View style={[hoursAndMintuesSeperationView]}>
                <Text style={{ color: color, fontSize: appTheme.pixelPerfectForFont(30) }}>
                    :
                </Text>
            </View>
            <View style={[countdownCardStyle]}>
                <Text style={[countdownCardTextStyle, { color }]}>{secDigit}</Text>
            </View>
            <View style={[countdownCardStyle]}>
                <Text style={[countdownCardTextStyle, { color }]}>{secUnit}</Text>
            </View>
        </View>;
    }

    return getContent();

}

export default GameTimer;