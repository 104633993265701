import React, { useContext, useEffect, useRef, useState } from 'react';
import { Text, View, ScrollView, ActivityIndicator, Platform, Animated, TouchableOpacity } from 'react-native';

import AppTheme from '../../utils/Theme';
import { useNavigate, useParams } from 'react-router-dom';

import Style from '../../utils/Style';
import * as Constants from "../../utils/Constants";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";

// Data
import { LocalDBContext } from "../../data/LocalDB";
import ActivitiesCell from './ActivitiesCell';
import Main from '../../../specific/components/Main/Main';
import MainOptions from '../../models/MainOptions';
import User from '../../data/user/User';
import Images from '../../../specific/utils/Images';
import Image from "../../designSystem/OTBImage";
import i18n, { getLanguage } from '../../utils/Localization/Localization';
import { getLocalActivities, getLocalActivitiesByCategories, getLocalCategories } from '../../../specific/utils/LocalStorage';
import InViewModal from '../../../specific/components/InViewModal/InViewModal';

const sleepCategories = ["sleepMeditation", "sleepMusic"];

const FullScreenCategory = (props: any) => {

    let propsData = props;

    let navigate: any = null;
    if (Platform.OS === "web") {
        propsData = useParams();
        navigate = useNavigate();
    }

    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();
    const images: Images = new Images();

    const [refresh, setRefresh] = useState(0);
    const [viewDidAppear, setViewDidAppear] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(appTheme.daySkyColor);
    const [textColor, setTextColor] = useState(appTheme.darkBlue);

    const [localActivities, setLocalActivities] = useState({});
    const [localCategories, setLocalCategories] = useState({});
    const [localActivitiesByCategory, setLocalActivitiesByCategory] = useState({});

    const [activitiesToDisplay, setActivitiesToDisplay] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState(propsData.category);

    let switchMenuLeftPosition = useRef(new Animated.Value(0)).current;
    const inViewModal = useRef<InViewModal>(null);

    const localDBData = useContext(LocalDBContext);
    const localSections = localDBData.sections.data;

    const { context = "app", isModal = true, componentId, category, menu = "superpowers" } = propsData;


    const onViewDidAppear = async () => {
        // On va récupérer les données des activités locales
        if (viewDidAppear === false) {
            const localActivitiesFromStorage = await getLocalActivities();
            setLocalActivities(localActivitiesFromStorage.data);
        }
    }

    useEffect(() => {
        if (Object.keys(localActivities).length > 0) {
            getLocalActivitiesByCategoryFromStorage();
        }
    }, [localActivities]);

    useEffect(() => {
        if (Object.keys(localActivitiesByCategory).length > 0) {
            getLocalCategoriesFromStorage();
        }
    }, [localActivitiesByCategory]);

    useEffect(() => {
        if (activitiesToDisplay.length > 0) {
            setViewDidAppear(true);
        }
    }, [activitiesToDisplay]);

    useEffect(() => {
        //On récupère alors la section
        getActivitiesToDisplay();
    }, [localCategories]);

    const getActivitiesToDisplay = () => {
        let activityIds: string[] = localActivitiesByCategory[selectedCategory] !== undefined ? localActivitiesByCategory[selectedCategory] : [];
        let freeActivitiesToDisplay = [];
        let premiumActivitiesToDisplay = [];
        for (const anActivityIndex in activityIds) {
            if (Object.prototype.hasOwnProperty.call(activityIds, anActivityIndex)) {
                const anActivityId = activityIds[anActivityIndex];
                if (localActivities[anActivityId] !== undefined) {
                    const anActivity = localActivities[anActivityId];
                    if (anActivity.status === "published") {
                        let activityIncluded = false;
                        if ((anActivity.access.is_essential === true) || (anActivity.access.is_premium === true)) {
                            for (const aPremiumActivityIndex in premiumActivitiesToDisplay) {
                                if (Object.prototype.hasOwnProperty.call(premiumActivitiesToDisplay, aPremiumActivityIndex)) {
                                    const aPremiumActivity = premiumActivitiesToDisplay[aPremiumActivityIndex];
                                    if (aPremiumActivity.order > anActivity.order) {
                                        premiumActivitiesToDisplay.splice(aPremiumActivityIndex, 0, anActivity);
                                        activityIncluded = true;
                                        break;
                                    }
                                }
                            }
                            if (activityIncluded === false) {
                                premiumActivitiesToDisplay.push(anActivity);
                            }
                        } else {
                            for (const aFreeActivityIndex in freeActivitiesToDisplay) {
                                if (Object.prototype.hasOwnProperty.call(freeActivitiesToDisplay, aFreeActivityIndex)) {
                                    const aFreeActivity = freeActivitiesToDisplay[aFreeActivityIndex];
                                    if (aFreeActivity.order > anActivity.order) {
                                        freeActivitiesToDisplay.splice(aFreeActivityIndex, 0, anActivity);
                                        activityIncluded = true;
                                        break;
                                    }
                                }
                            }
                            if (activityIncluded === false) {
                                freeActivitiesToDisplay.push(anActivity);
                            }
                        }
                    }
                }
            }
        }
        const activitiesToDisplayForCategory = freeActivitiesToDisplay.concat(premiumActivitiesToDisplay);
        let aBackgroundColor = category === "sleepMeditation" ? appTheme.nightSkyColor : appTheme.daySkyColor;
        let aTextColor = category === "sleepMeditation" ? appTheme.white : appTheme.darkBlue;
        if ((category !== "sleepMeditation") && (category !== "routineSetUp")) {
            const sectionKey = activitiesToDisplay[0].section_key;
            if (localSections[sectionKey] !== undefined) {
                const sectionColors = localSections[sectionKey].clr;
                aTextColor = sectionColors.txt;
                aBackgroundColor = sectionColors.main;
            }
        }
        setTextColor(aTextColor);
        setBackgroundColor(aBackgroundColor);
        setActivitiesToDisplay(activitiesToDisplayForCategory);
    }

    const getLocalCategoriesFromStorage = async () => {
        const localCategoriesFromStorage = await getLocalCategories();
        setLocalCategories(localCategoriesFromStorage.data);
    }

    const getLocalActivitiesByCategoryFromStorage = async () => {
        const localActivitiesByCategoryFromStorage = await getLocalActivitiesByCategories();
        setLocalActivitiesByCategory(localActivitiesByCategoryFromStorage.data);
    }

    const showActivitySheet = (data: { index: number, activityKey: string, categoryKey: string, sectionKey: string, analytics_key: string }) => {
        const { activityKey, categoryKey, sectionKey, index, analytics_key } = data;
        const name = Platform.OS === 'web' ? Constants.ScreenActivitySheet : Constants.ScreenActivityPager;
        const passProps = Platform.OS === 'web' ? {
            activityKey,
            categoryKey,
            sectionKey,
            context
        } : {
            menu,
            activityKey,
            categoryKey,
            sectionKey,
            context,
            activities: activitiesToDisplay,
            index
        };
        //trackEvent(analyticsEventActivityClicked, { [propertiesEventName]: analytics_key, [propertiesEventCategory]: category.analytics_key });
        if (Platform.OS === "web") {
            inViewModal.current?.showAlert({
                name:Constants.ScreenActivitySheet,
                passProps
            });
        } else {
            Navigation.push({
                componentId,
                navigate,
                passProps,
                name
            });
        }
    }

    const selectCategory = (categoryIndex: number) => {
        setSelectedCategory(sleepCategories[categoryIndex]);
    }

    useEffect(() => {
        if (sleepCategories.indexOf(selectedCategory) !== -1) {
            const categoryIndex = sleepCategories.indexOf(selectedCategory);
            const newSwitchLeftPosition = categoryIndex === 0 ? 0 : appTheme.getBlockWidth() / 2;
            Animated.timing(switchMenuLeftPosition, {
                toValue: newSwitchLeftPosition,
                duration: 500,
                useNativeDriver: false
            }).start();
        }
        getActivitiesToDisplay();
    }, [selectedCategory]);

    const getCategorySwitch = () => {
        if (category !== "sleepMeditation") {
            return <View />;
        }

        const categoryWidth = appTheme.getBlockWidth() / 2;
        const relaxationTitleColor = selectedCategory === "sleepMeditation" ? appTheme.darkBlue : appTheme.white;
        return <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center", marginTop: appTheme.pixelPerfect(40) }}>
            <View style={{ flexDirection: "row", width: appTheme.getBlockWidth(), backgroundColor: appTheme.white + "20", height: appTheme.pixelPerfect(60), borderRadius: appTheme.pixelPerfect(30), justifyContent: "space-between", alignItems: "center" }}>
                <Animated.View style={{ position: "absolute", left: switchMenuLeftPosition, width: categoryWidth, backgroundColor: "#FFD77A", height: appTheme.pixelPerfect(60), borderRadius: appTheme.pixelPerfect(30) }} />
                <TouchableOpacity onPress={() => selectCategory(0)} style={{ flexDirection: "row", width: categoryWidth, paddingHorizontal: appTheme.pixelPerfect(5), alignItems: "center" }}>
                    <View style={{ backgroundColor: appTheme.darkBlue, width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(25) }}>
                        <Image source={images.icMeditationsSleep} style={{ width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50) }} resizeMode="contain" />
                    </View>
                    <View style={{ flex: 1, paddingStart: appTheme.pixelPerfect(5), alignItems: "center" }}>
                        <Text style={{ fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(7), textTransform: "uppercase", color: relaxationTitleColor }}>Relaxations</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => selectCategory(1)} style={{ flexDirection: "row", width: categoryWidth, paddingHorizontal: appTheme.pixelPerfect(5), alignItems: "center" }}>
                    <View style={{ flex: 1, paddingStart: appTheme.pixelPerfect(5), alignItems: "center" }}>
                        <Text style={{ fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(7), textTransform: "uppercase", color: appTheme.white }}>Musiques</Text>
                    </View>
                    <View style={{ backgroundColor: appTheme.darkBlue, width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(25) }}>
                        <Image source={images.icMeditationsMusic} style={{ width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50) }} resizeMode="contain" />
                    </View>
                </TouchableOpacity>
            </View>
        </View>;
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator color={appTheme.darkBlue} />
            </View>
        }

        const viewWidth = appTheme.getFlexDirectionForSplitScreen() === "row" ? appTheme.getFullAppWidth() / 2 : appTheme.getFullAppWidth();

        let activitiesView = [];
        let activityLine = [];
        for (const anActivityWithDataIndex in activitiesToDisplay) {
            if (Object.hasOwnProperty.call(activitiesToDisplay, anActivityWithDataIndex)) {
                const anActivity = activitiesToDisplay[anActivityWithDataIndex];
                activityLine.push(<View style={{ paddingHorizontal: appTheme.pixelPerfect(4) }}>
                    <ActivitiesCell
                        onPress={() => showActivitySheet({ index: parseInt(anActivityWithDataIndex), activityKey: anActivity.key, categoryKey: selectedCategory, sectionKey: anActivity.section_key, analytics_key: anActivity.analytics_key })}
                        width={viewWidth - appTheme.pixelPerfect(20)}
                        localCategories={localCategories}
                        type="bloc"
                        menu='superpowers'
                        context={context}
                        activityTitleFirst={true}
                        showCategoryTitle={false}
                        item={anActivity}
                        titleColor={textColor}
                        bodyColor={textColor}
                        activities={activitiesToDisplay}
                        index={anActivityWithDataIndex}
                        sectionId={"SuperPowers"}
                        subSection={category}
                        componentId={propsData.componentId} /></View>);
                if (activityLine.length === 3) {
                    activitiesView.push(<View style={{ flexDirection: "row", width: viewWidth, justifyContent: "center" }}>{activityLine}</View>);
                    activityLine = [];
                }
            }
        }
        if (activityLine.length > 0) {
            activitiesView.push(<View style={{ flexDirection: "row" }}>{activityLine}</View>);
            activityLine = [];
        }

        let headerView = <View />;
        const categoryData = localCategories[selectedCategory];
        if (categoryData.header !== undefined) {
            if (categoryData.header.img !== undefined) {
                const headerImageHeight = viewWidth * 206 / 360;
                headerView = <Image resizeMode="contain" source={images[categoryData.header.img]} style={{ width: viewWidth, height: headerImageHeight }} />
            }
        }
        let pageTitle = category === "sleepMeditation" ? i18n.t("appMenu.superPowers.menu.routines.sleepingMeditation") : i18n.t("appMenu.superPowers.menu.routines.trainingPath");
        let pageBody = category === "sleepMeditation" ? "Zzzzz… Les relaxations pour t'endormir te préparent au sommeil en te transportant dans un univers propice à un endormissement profond et serein. Elles sont à écouter avant de dormir le soir ou avant une sieste, au lit, la lumière éteinte !" : "Pour libérer tes super-pouvoirs, le secret c’est de t'entraîner un peu tous les jours. Avec cet équipement, tu vas pouvoir noter quotidiennement une action qui te fait du bien. Grâce à cette routine, apprends à reconnaître ce qui augmente ton bien-être.";

        if ((categoryData !== undefined) && (categoryData.body !== undefined)) {
            if (context === Constants.CONTEXT.family) {
                if ((categoryData.body.children !== undefined) && (categoryData.body.children[getLanguage()] !== undefined)) {
                    pageBody = categoryData.body.children[getLanguage()];
                }
            } else if (context === Constants.CONTEXT.teacher) {
                if ((categoryData.body.children !== undefined) && (categoryData.body.children[getLanguage()] !== undefined)) {
                    pageBody = categoryData.body.children[getLanguage()];
                }
            }
        }

        pageTitle = localCategories[selectedCategory].title[getLanguage()];
        if ((category !== "sleepMeditation") && (category !== "routineSetUp")) {
            headerImage = images[activitiesToDisplay[0].app_image];
            pageBody = "";
        }


        return <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ flex: 1 }}>
            {getCategorySwitch()}
            <View style={{ flex: 1, flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                <View style={{ width: viewWidth }}>
                    {headerView}
                    <View style={{ padding: appTheme.pixelPerfect(10), justifyContent: "flex-start", alignItems: "center" }}>
                        <Text style={[{ textAlign: "center", fontSize: appTheme.pixelPerfectForFont(11) }, { color: textColor, fontFamily: appTheme.primaryBoldFont }]}>{pageTitle.toLocaleUpperCase()}</Text>
                        <Text style={[{ textAlign: "center", fontSize: appTheme.pixelPerfectForFont(12) }, { color: textColor, paddingTop: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont }]}>{pageBody}</Text>
                    </View>
                </View>
                <View style={{ width: viewWidth, alignItems: "center" }}>
                    {activitiesView}
                </View>
            </View>
        </ScrollView>
    }


    const mainOptions: MainOptions = new MainOptions({
        componentId,
        navigationColor: backgroundColor,
        navigationItemColor: appTheme.white,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        canGoBack: !isModal,
        canBeDismissed: isModal
    });

    return (
        <Main mainOptions={mainOptions}>
            <View style={{ flex: 1, backgroundColor }}>
                {getContent()}
                <InViewModal ref={inViewModal} />
            </View>
        </Main>
    )
}

export default FullScreenCategory;