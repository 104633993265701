import React from "react";

import {
    Platform,
    Text,
    View
} from "react-native";
import DownloadButton from "../../../../specific/components/Download/DownloadButton";
import ShareButton from "../../../../specific/components/Share/ShareButton";
import User from "../../../data/user/User";
import FavoriteButton from "../../../designSystem/FavoriteButton";
import { getLanguage } from "../../../utils/Localization/Localization";
import { trackEvent, propertiesEventCategory, analyticsEventActivityDownloaded } from "../../../services/Tracking/Analytics";

import AppTheme from "../../../utils/Theme";
import Activity from "../../../models/Activity";
import PremiumButton from "../../../designSystem/PremiumButton";

type localizedString = {
    fr?: string
}

interface ActivityCellBodyProps {
    body?: localizedString;
    color: string;
    width: number;
    categoryAnalyticsKey: string;
    activity: Activity;
    onFavoriteStatusChanged:void;
    onDownloadStatusChanged:(download:boolean) => {};
    componentId?:string;
    showDownloadOption?:boolean;
}

const ActivityCellUserActions = (props: ActivityCellBodyProps) => {
    const { body, color, width, categoryAnalyticsKey, activity, onFavoriteStatusChanged, onDownloadStatusChanged, componentId = "", showDownloadOption=false } = props;
    const user: User = User.getInstance();

    const appTheme: AppTheme = new AppTheme();

    const onDownloadStart = () => {
        trackEvent(analyticsEventActivityDownloaded, {
            [propertiesEventCategory]: categoryAnalyticsKey
        });
    }

    let numberOfButtons = 1;
    let downloadButton = <View />;
    if ((Platform.OS !== "web") && (showDownloadOption === true)) {
        numberOfButtons++;
        downloadButton = user.getPremiumStatus() !== "free" ? <DownloadButton onDownloadStart={onDownloadStart} onDownloadStatusChanged={onDownloadStatusChanged} activity={activity} color={color} /> : <PremiumButton isDownloadButton={true} backgroundColor={appTheme.white} iconColor={color} componentId={componentId} size={appTheme.pixelPerfect(40)} iconSize={appTheme.pixelPerfect(20)} />;
    } 
    const shareButton = <View />;
    //const shareButton = <ShareButton categoryAnalyticsKey={categoryAnalyticsKey} activity={activity} color={color} />;
    const justifyContent = ((Platform.OS === "web") || (numberOfButtons === 1)) ? "center" : "space-evenly";

    return <View style={{ width, alignItems: "center", marginTop: appTheme.pixelPerfect(20), flexDirection: "row", justifyContent, marginBottom:appTheme.pixelPerfect(20) }}>
        <FavoriteButton categoryAnalyticsKey={categoryAnalyticsKey} activity={activity} onFavoriteStatusChanged={onFavoriteStatusChanged} color={color} />
        {shareButton}
        {downloadButton}
    </View>
}

export default ActivityCellUserActions;