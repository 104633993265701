import React, { useEffect, useRef, useState } from "react";

import { Text, View, StyleSheet, Image, ScrollView, Platform, Touchable, TouchableOpacity } from "react-native";

import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import AppTheme, { white } from "../../utils/Theme";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { ScreenActivitySheet, ScreenCategoryHome, ScreenPDFViewer, superPowersKeys } from "../../utils/Constants";
import Images from "../../../specific/utils/Images";
import LinkButton from "../../designSystem/Buttons/LinkButton";
import Divider from "../../designSystem/Divider/Divider";
import i18n, { getLanguage } from "../../utils/Localization/Localization";
import Style from "../../utils/Style";
import Activity from "../../models/Activity";
import { useNavigate } from "react-router-dom";
import User from "../../data/user/User";
import ActionSheet from "../ActionSheets/ActionSheet";
import SVGIconButton from "../../designSystem/Buttons/SVGIconButton";
import { default as IcBack } from "../../assets/svg/lili/ic_back_arrow.svg";
import ActivityFilterByLevel from "../Filters/ActivityFilterByLevel";
import InViewModal from "../../../specific/components/InViewModal/InViewModal";

interface SuperPowers {
    bg: string,
    ill: string,
    next: string,
    documents: any[],
    description: string,
    discovery: string[],
    start: string[],
    continue: string[],
    data: any
}

interface SuperPowerSheetProps {
    activities: any,
    localActivities: any,
    localCategories: any,
    componentId: string,
    superpowerKey: string,
    isPageOnScreen: boolean,
    superPowerData: SuperPowers
}

const SuperPowerSheet = (props: SuperPowerSheetProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();
    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

    const actionSheetRef = useRef<ActionSheet>(null);
    const inViewModal = useRef<InViewModal>(null);

    const { superpowerKey, superPowerData, localCategories, activities, localActivities, componentId } = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    useEffect(() => {
        if (props.isPageOnScreen === true) {
            setViewDidAppear(true);
        }
    }, [props]);

    const showActivitySheet = (data: { activityKey: string, categoryKey: string, sectionKey: string, analytics_key: string }) => {
        const { activityKey, categoryKey, sectionKey } = data;
        if (Platform.OS === "web") {
            inViewModal.current?.showAlert({
                name: ScreenActivitySheet,
                passProps: {
                    activityKey,
                    categoryKey,
                    sectionKey,
                    context: user.profile
                }
            });
        } else {
            Navigation.push({
                componentId,
                navigate,
                passProps: {
                    activityKey,
                    categoryKey,
                    sectionKey,
                    context: user.profile
                },
                name: ScreenActivitySheet
            })
        }
    }

    const showPDF = (data: { pdfData: any, sectionKey: string, title: string }) => {
        const { pdfData, sectionKey, title } = data;
        inViewModal.current?.showAlert({
            name: ScreenPDFViewer,
            passProps: {
                sectionId: sectionKey,
                title: title,
                color: appTheme.darkBlue,
                pdfUrl: pdfData.url
            }
        });
    }

    const showCategory = (categoryKey: string, cellData?: any) => {
        let passProps = {
            categoryKey: categoryKey,
            section: "superPowers",
            origin: superpowerKey
        }
        /*
        if (Platform.OS === "web") {
            passProps = {
                categoryKey,
                sectionKey: cellData.sectionKey,
                activities: cellData.activities,
                header: cellData.icon,
                title: cellData.title
            }
        }
        */
        Navigation.push({
            componentId,
            navigate,
            name: ScreenCategoryHome,
            passProps
        });
    }

    const categoryCell = (data: { cellKey: string, index: string, type: number, display_type: string, cellData: any }) => {
        const { cellKey, index, type, display_type, cellData } = data;
        const cellWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
        const cellTitle = ((cellData.title !== undefined) && (cellData.title[user.profile] !== undefined) && (cellData.title[user.profile][getLanguage()] !== undefined)) ? cellData.title[user.profile][getLanguage()] : ((cellData.title !== undefined) && (cellData.title[getLanguage()] !== undefined)) ? cellData.title[getLanguage()] : "";
        if (display_type === "header") {
            const borderTopEndRadius = Platform.OS === "web" ? appTheme.pixelPerfect(20) : 0;
            const borderTopStartRadius = Platform.OS === "web" ? appTheme.pixelPerfect(20) : 0;
            const iconSize = Platform.OS === "web" ? appTheme.pixelPerfect(50) : appTheme.pixelPerfect(30);
            const marginTop = Platform.OS === "web" ? appTheme.pixelPerfect(20) : 0;
            return <View style={{ width: cellWidth, justifyContent: "center", alignItems: "center", marginTop }}>
                <View style={{ borderTopEndRadius, borderTopStartRadius, flexDirection: "row", width: cellWidth, backgroundColor: "#2B4572", paddingVertical: appTheme.pixelPerfect(10), justifyContent: "center", alignItems: "center" }}>
                    <Image source={images[cellData.icon]} style={{ width: iconSize, height: iconSize, marginEnd: appTheme.pixelPerfect(10) }} resizeMode="contain" />
                    <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.white, textTransform: "uppercase", fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center" }} >{cellTitle}</Text>
                </View>
            </View>
        } else if (display_type === "closure") {
            if (Platform.OS === "web") {
                return <View style={{ height: appTheme.pixelPerfect(20), backgroundColor: appTheme.white, width: cellWidth, borderBottomStartRadius: appTheme.pixelPerfect(20), borderBottomEndRadius: appTheme.pixelPerfect(20) }} />
            }
            return <View />
        } else if (display_type === "activity") {
            const activityKey: string = cellData;
            let activityToDisplay: Activity | undefined = undefined;
            if (localActivities[activityKey] !== undefined) {
                activityToDisplay = localActivities[activityKey];
            }
            if (activityToDisplay !== undefined) {
                let categoryTitle = "";
                const categoryKey = activityToDisplay?.category_keys[0];
                let category_prefix: string = "";
                if (activityToDisplay.section_key === "inspire") {
                    category_prefix = "Histoires audio - ";
                    if (user.profile === "teacher") {
                        category_prefix = "Récits - "
                    }
                }
                if (localCategories[categoryKey] !== undefined) {
                    const documentCategory = localCategories[categoryKey];
                    categoryTitle = documentCategory.title[getLanguage()];
                    if ((documentCategory[user.profile] !== undefined) && (documentCategory[user.profile].title !== undefined) && (documentCategory[user.profile].title[getLanguage()] !== undefined)) {
                        categoryTitle = documentCategory[user.profile].title[getLanguage()];
                    }
                }
                const textColor: string = activityToDisplay.section_key === "grow" ? appTheme.grow : activityToDisplay.section_key === "talk" ? appTheme.talk : activityToDisplay.section_key === "still" ? appTheme.still : activityToDisplay.section_key === "inspire" ? appTheme.inspire : appTheme.darkBlue;
                const localImage = activityToDisplay.app_image !== undefined ? activityToDisplay.app_image : "";
                const remoteImage = activityToDisplay.img_url;
                const imageToDisplay = ((localImage.length > 0) && (images[localImage] !== undefined)) ? images[localImage] : { uri: remoteImage };
                return <View style={{ backgroundColor: appTheme.white, paddingVertical: appTheme.pixelPerfect(10), width: cellWidth, justifyContent: "center", alignItems: "center" }}>
                    <TouchableOpacity onPress={() => showActivitySheet({ activityKey: cellData, sectionKey: activityToDisplay.section_key, categoryKey: categoryKey, analytics_key: "" })} style={{ paddingHorizontal: appTheme.pixelPerfect(20), flexDirection: "row", width: cellWidth, paddingVertical: appTheme.pixelPerfect(10), justifyContent: "center", alignItems: "center" }}>
                        <View style={[Style.shadowed, { borderRadius: appTheme.pixelPerfect(10) }]}>
                            <Image nativeID={'image_' + cellData.activity_id} source={imageToDisplay} style={[{ width: appTheme.pixelPerfect(80), height: appTheme.pixelPerfect(80), borderRadius: appTheme.pixelPerfect(10) }]} resizeMode="cover" />
                        </View>
                        <View style={{ flex: 1, marginStart: appTheme.pixelPerfect(10) }}>
                            <Text style={{ fontFamily: appTheme.primaryBoldFont, color: textColor, textTransform: "uppercase", fontSize: appTheme.pixelPerfectForFont(9) }} >{category_prefix + categoryTitle}</Text>
                            <Text style={{ fontFamily: appTheme.primaryMediumFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(9) }} >{activityToDisplay.title[getLanguage()]}</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            }
            return <View />
        } else if (display_type === "document") {
            let categoryTitle = cellTitle;
            if (localCategories[cellData.category_id] !== undefined) {
                const documentCategory = localCategories[cellData.category_id];
                categoryTitle = documentCategory.title[getLanguage()];
                if ((documentCategory[user.profile] !== undefined) && (documentCategory[user.profile].title !== undefined) && (documentCategory[user.profile].title[getLanguage()] !== undefined)) {
                    categoryTitle = documentCategory[user.profile].title[getLanguage()];
                }
            }
            let cellAction = () => showActivitySheet({ activityKey: cellData.activity_id, sectionKey: cellData.section_id, categoryKey: cellData.category_id, analytics_key: "" });
            if (cellData[user.profile] !== undefined) {
                if (cellData[user.profile].type === "PDF") {
                    cellAction = () => showPDF({ pdfData: cellData[user.profile], sectionKey: cellData.section_id, title: cellData.subtitle[getLanguage()] });
                }
            }
            return <View style={{ backgroundColor: appTheme.white, paddingVertical: appTheme.pixelPerfect(10), width: cellWidth, justifyContent: "center", alignItems: "center" }}>
                <TouchableOpacity onPress={cellAction} style={{ paddingHorizontal: appTheme.pixelPerfect(20), flexDirection: "row", width: cellWidth, paddingVertical: appTheme.pixelPerfect(10), justifyContent: "center", alignItems: "center" }}>
                    <View style={[Style.shadowed, { borderRadius: appTheme.pixelPerfect(10) }]}>
                        <Image nativeID={'image_' + cellData.activity_id} source={images[cellData.icon]} style={[{ width: appTheme.pixelPerfect(80), height: appTheme.pixelPerfect(80), borderRadius: appTheme.pixelPerfect(10) }]} resizeMode="cover" />
                    </View>
                    <View style={{ flex: 1, marginStart: appTheme.pixelPerfect(10) }}>
                        <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, textTransform: "uppercase", fontSize: appTheme.pixelPerfectForFont(9) }} >{categoryTitle}</Text>
                        <Text style={{ fontFamily: appTheme.primaryMediumFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(9) }} >{cellData.subtitle[getLanguage()]}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        } else if (display_type === "path") {
            // La cellule de type 3 est celle qui réceptionnera le chat de la victoire
            // Nous allons donc calculler sa position, et les autres seront positionnées en fonction
            const positionForTypeThree = (cellWidth - (appTheme.pixelPerfect(65) * 308 / 408)) / 2;
            let marginStartForFeet = appTheme.pixelPerfect(50);
            let marginStartForImage = appTheme.pixelPerfect(10);
            let marginTopForImage = appTheme.pixelPerfect(5);
            let marginBottomForImage = appTheme.pixelPerfect(0);
            let angleForFeet = "0deg";
            if (type === 1) {
                marginStartForFeet = appTheme.pixelPerfect(110);
                marginStartForImage = appTheme.pixelPerfect(70);
                marginTopForImage = appTheme.pixelPerfect(0);
                marginBottomForImage = appTheme.pixelPerfect(0);
                angleForFeet = "0deg";
            } else if (type === 2) {
                marginStartForFeet = appTheme.pixelPerfect(150);
                marginStartForImage = appTheme.pixelPerfect(120);
                marginTopForImage = appTheme.pixelPerfect(10);
                marginBottomForImage = appTheme.pixelPerfect(5);
                angleForFeet = "25deg";
            } else if (type === 3) {
                marginStartForFeet = appTheme.pixelPerfect(145);
                marginStartForImage = appTheme.pixelPerfect(145);
                marginTopForImage = appTheme.pixelPerfect(10);
                marginBottomForImage = appTheme.pixelPerfect(5);
                angleForFeet = "45deg";
            } else if (type === 4) {
                marginStartForFeet = appTheme.pixelPerfect(110);
                marginStartForImage = appTheme.pixelPerfect(120);
                marginTopForImage = appTheme.pixelPerfect(10);
                marginBottomForImage = appTheme.pixelPerfect(5);
                angleForFeet = "60deg";
            } else if (type === 5) {
                marginStartForFeet = appTheme.pixelPerfect(60);
                marginStartForImage = appTheme.pixelPerfect(80);
                marginTopForImage = appTheme.pixelPerfect(5);
                marginBottomForImage = appTheme.pixelPerfect(5);
                angleForFeet = "60deg";
            } else if (type === 6) {
                marginStartForFeet = appTheme.pixelPerfect(25);
                marginStartForImage = appTheme.pixelPerfect(25);
                marginTopForImage = appTheme.pixelPerfect(10);
                marginBottomForImage = appTheme.pixelPerfect(10);
                angleForFeet = "50deg";
            }
            const feetImageSize = Platform.OS === "web" ? appTheme.pixelPerfect(50) : appTheme.pixelPerfect(30);
            if (Platform.OS === "web") {
                marginStartForFeet = marginStartForFeet + appTheme.pixelPerfect(20);
            }
            if ((cellKey === "start") || (cellKey === "continue") || (cellKey === "end")) {
                const catImage = cellKey === "start" ? require("../../assets/img/ic_super_cat_start.png") : cellKey === "continue" ? require("../../assets/img/ic_super_cat_continue.png") : require("../../assets/img/ic_super_cat_winner.png");
                const catImageHeight = Platform.OS === "web" ? appTheme.pixelPerfect(100) : appTheme.pixelPerfect(65);
                const catImageWidth = cellKey === "start" ? catImageHeight : catImageHeight * 308 / 408;
                const cartoucheHeight = Platform.OS === "web" ? appTheme.pixelPerfect(30) : appTheme.pixelPerfect(20);
                const textMargin = cellKey === "start" ? Platform.OS === "web" ? appTheme.pixelPerfect(70) : appTheme.pixelPerfect(40) : Platform.OS === "web" ? appTheme.pixelPerfect(40) : appTheme.pixelPerfect(20);
                const title = cellKey === "start" ? "Démarrer" : "Aller plus loin";
                const textWidth = cellKey === "start" ? appTheme.pixelPerfect(120) : appTheme.pixelPerfect(150);
                const feetImages = cellKey === "end" ? <View style={{ borderBottomStartRadius: appTheme.pixelPerfect(20), borderBottomEndRadius: appTheme.pixelPerfect(20), width: cellWidth, height: appTheme.pixelPerfect(40) + appTheme.topInsets, backgroundColor: "#354F84" }} /> : <Image source={require("../../assets/img/ic_foot.png")} style={{ marginTop: marginTopForImage, marginBottom: marginBottomForImage, marginStart: marginStartForFeet, width: feetImageSize, height: feetImageSize }} resizeMode="contain" />;
                const textCartouche = cellKey === "end" ? <View style={{ height: catImageHeight }} /> : <View style={{ height: catImageHeight, justifyContent: "flex-end" }}>
                    <View style={{ marginStart: textMargin + marginStartForImage, backgroundColor: "#FFD77A", width: textWidth, alignItems: "center", height: cartoucheHeight, justifyContent: "center", borderRadius: cartoucheHeight / 2 }}>
                        <Text style={{ paddingStart: appTheme.pixelPerfect(10), fontFamily: appTheme.superHerosFontBold, color: appTheme.darkBlue }}>{title}</Text>
                    </View>
                </View>;
                const paddingBottom = cellKey === "end" ? appTheme.pixelPerfect(80) : 0;
                const feetBackgroundImage = cellKey === "end" ? appTheme.darkBlue : appTheme.white;
                const feetBackgroundColor = cellKey === "end" ? <View style={{ position: "absolute", bottom: 0, width: cellWidth, height: appTheme.pixelPerfect(10), backgroundColor: "#354F84" }} /> : <View />;
                return <View style={{ backgroundColor: appTheme.white, paddingTop: appTheme.pixelPerfect(10) }}>
                    <View style={{ flexDirection: "row", width: cellWidth }}>
                        {feetBackgroundColor}
                        {textCartouche}
                        <Image source={catImage} style={{ position: "absolute", bottom: 0, left: marginStartForImage, width: catImageWidth, height: catImageHeight }} resizeMode="contain" />
                    </View>
                    <View style={{ backgroundColor: feetBackgroundImage, paddingBottom }}>
                        {feetImages}
                    </View>
                </View>
            }
            const imageSize = Platform.OS === "web" ? appTheme.pixelPerfect(90) : appTheme.pixelPerfect(50);
            let imageSource = images[cellData.icon] !== undefined ? images[cellData.icon] : { url: cellData.remoteIcon };
            return <View style={{ backgroundColor: appTheme.white }}>
                <TouchableOpacity onPress={() => showCategory(cellKey, cellData)} style={{ flexDirection: "row", width: cellWidth, height: imageSize, alignItems: "center" }}>
                    <Image source={imageSource} style={{ borderWidth: appTheme.pixelPerfect(1), borderColor: cellData.clr, marginStart: marginStartForImage, width: imageSize, height: imageSize, borderRadius: imageSize / 2 }} resizeMode="cover" />
                    <Text style={{ color: cellData.clr, fontSize: appTheme.pixelPerfectForFont(8), fontFamily: appTheme.primaryBoldFont, paddingHorizontal: appTheme.pixelPerfect(5), textTransform: "uppercase", flex: 1 }}>{cellTitle}</Text>
                </TouchableOpacity>
                <Image source={require("../../assets/img/ic_foot.png")} style={{ marginTop: marginTopForImage, marginBottom: marginBottomForImage, transform: [{ rotate: angleForFeet }], marginStart: marginStartForFeet, width: feetImageSize, height: feetImageSize }} resizeMode="contain" />
            </View>
        }
    }

    const getCategoriesList = () => {
        let superPowerCells = [categoryCell({
            cellKey: "educational_supports",
            display_type: "header",
            type: 0,
            index: 0,
            cellData: {
                icon: "icTraining",
                title: {
                    fr: "Supports pédagogiques"
                }
            }
        })];
        if (superPowerData.documents !== undefined) {
            for (const aDocumentIndex in superPowerData.documents) {
                if (Object.prototype.hasOwnProperty.call(superPowerData.documents, aDocumentIndex)) {
                    const aDocument = superPowerData.documents[aDocumentIndex];
                    superPowerCells.push(categoryCell({
                        cellKey: aDocument.cell_key,
                        display_type: "document",
                        type: 0,
                        index: parseInt(aDocumentIndex),
                        cellData: aDocument
                    }));
                }
            }
        }
        superPowerCells.push(categoryCell({
            cellKey: "educational_supports_closure",
            display_type: "closure",
            type: 0,
            index: 0,
            cellData: {
            }
        }));
        superPowerCells.push(categoryCell({
            cellKey: "discovery_list",
            display_type: "header",
            type: 0,
            index: 0,
            cellData: {
                icon: "icTraining",
                title: {
                    fr: "4 activités pour démarrer"
                }
            }
        }));
        if (superPowerData.discovery !== undefined) {
            for (const aDiscoveryIndex in superPowerData.discovery) {
                if (Object.prototype.hasOwnProperty.call(superPowerData.discovery, aDiscoveryIndex)) {
                    const aDiscovery = superPowerData.discovery[aDiscoveryIndex];
                    superPowerCells.push(categoryCell({
                        cellKey: aDiscovery,
                        display_type: "activity",
                        type: 0,
                        index: parseInt(aDiscoveryIndex),
                        cellData: aDiscovery
                    }));
                }
            }
        }
        superPowerCells.push(categoryCell({
            cellKey: "educational_supports_closure",
            display_type: "closure",
            type: 0,
            index: 0,
            cellData: {
            }
        }));
        superPowerCells.push(categoryCell({
            cellKey: "sequence_guide",
            display_type: "header",
            type: 0,
            index: 1,
            cellData: {
                icon: "icWarmUp",
                title: {
                    fr: "La sélection pour continuer"
                }
            }
        }));
        let categories = ["start"];
        categories = categories.concat(superPowerData.start);
        categories.push("continue");
        categories = categories.concat(superPowerData.continue);
        categories.push("end");
        let categoriesView = [];
        let typeOfTheCell = 0;
        for (const aCategoryIndex in categories) {
            if (Object.prototype.hasOwnProperty.call(categories, aCategoryIndex)) {
                const aCategoryKey = categories[aCategoryIndex];
                if ((aCategoryKey === "start") || (aCategoryKey === "continue") || (aCategoryKey === "end")) {
                    categoriesView.push(categoryCell({
                        cellKey: aCategoryKey, index: aCategoryIndex, type: typeOfTheCell, display_type: "path", cellData: {
                            icon: "",
                            title: { fr: "" }
                        }
                    }));
                    if (typeOfTheCell === 6) {
                        typeOfTheCell = 0;
                    } else {
                        typeOfTheCell++;
                    }
                } else if (aCategoryKey === "discovery") {
                    categoriesView.push(categoryCell({
                        cellKey: aCategoryKey, index: aCategoryIndex, type: typeOfTheCell, display_type: "path", cellData: {
                            activities: superPowerData.discovery,
                            sectionKey: "super_program",
                            clr: "#354F84",
                            icon: "dscv00ActivitiesDiscovery",
                            title: { fr: "4 activités pour démarrer" }
                        }
                    }));
                    if (typeOfTheCell === 6) {
                        typeOfTheCell = 0;
                    } else {
                        typeOfTheCell++;
                    }
                } else {
                    let activitiesForCategory = undefined;
                    let sectionKey = "";
                    for (const aSectionKey in activities) {
                        if (Object.prototype.hasOwnProperty.call(activities, aSectionKey)) {
                            const aSection = activities[aSectionKey];
                            if (aSection[aCategoryKey] !== undefined) {
                                activitiesForCategory = aSection[aCategoryKey];
                                sectionKey = aSectionKey;
                                break;
                            }
                        }
                    }
                    if ((localCategories[aCategoryKey] !== undefined) && (activitiesForCategory !== undefined) && (activitiesForCategory[0] !== undefined)) {
                        // On va regarder s'il y a des activités de la catéogrie sélectionnée qui correspondent également au tag
                        let areThereActivitiesForTag: boolean = false;
                        let icon = images.adv01JeMePose;
                        let remoteIcon = undefined;
                        for (const anActivityIndex in activitiesForCategory) {
                            if (Object.prototype.hasOwnProperty.call(activitiesForCategory, anActivityIndex)) {
                                const anActivityKey:string = activitiesForCategory[anActivityIndex];
                                const anActivity = localActivities[anActivityKey];
                                if ((anActivity !== undefined) && (anActivity.tags !== undefined) && (anActivity.tags.indexOf(superpowerKey) !== -1)) {
                                    areThereActivitiesForTag = true;
                                    icon = anActivity.app_image;
                                    remoteIcon = anActivity.img_url;
                                    break;
                                }
                            }
                        }
                        if (areThereActivitiesForTag === true) {
                            const aCategory = localCategories[aCategoryKey];
                            categoriesView.push(categoryCell({
                                cellKey: aCategoryKey, index: aCategoryIndex, type: typeOfTheCell, display_type: "path", cellData: {
                                    sectionKey,
                                    clr: sectionKey === "grow" ? appTheme.grow : sectionKey === "talk" ? appTheme.talk : sectionKey === "still" ? appTheme.still : sectionKey === "inspire" ? appTheme.inspire : appTheme.darkBlue,
                                    icon,
                                    remoteIcon,
                                    title: aCategory.title
                                }
                            }));
                            if (typeOfTheCell === 6) {
                                typeOfTheCell = 0;
                            } else {
                                typeOfTheCell++;
                            }
                        }

                    }
                }
            }
        }
        superPowerCells = superPowerCells.concat(categoriesView);
        return superPowerCells;
    }

    const goBack = () => {
        Navigation.pop({
            componentId,
            navigate
        });
    }

    const getBodyView = () => {
        const bodyViewWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
        const bodyViewBorderRadius = Platform.OS === "web" ? appTheme.pixelPerfect(20) : 0;
        const bodyArray: string[] = i18n.t(superPowerData.description)[getLanguage()];
        if ((bodyArray.length === 0) || ((bodyArray[0] !== undefined) && (bodyArray[0].length === 0))) {
            return <View />
        }
        let textToDiplay = bodyArray[0];
        const backButton = Platform.OS === "web" ? <View style={{ position: "absolute", top: appTheme.pixelPerfect(5), left: appTheme.pixelPerfect(5), marginBottom: appTheme.pixelPerfect(5) }}>
            <SVGIconButton buttonSize={appTheme.pixelPerfect(30)} icon={IcBack} onPress={goBack} color={"#354F84"} />
        </View> : <View />;
        const paddingTop = Platform.OS === "web" ? appTheme.pixelPerfect(50) : appTheme.pixelPerfect(20);
        return <View style={{ paddingTop: appTheme.pixelPerfect(25), alignItems: "center", marginTop: -appTheme.pixelPerfect(25) }}>
            <View style={{ position: "absolute", top: 0, width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(25), backgroundColor: "#354F84", paddingTop: appTheme.pixelPerfect(2) }}>
                <Image source={images[superPowerData.ill]} style={{ width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(23) }} resizeMode="contain" />
            </View>
            <View style={{ backgroundColor: "#354F84", width: bodyViewWidth, paddingTop, paddingHorizontal: appTheme.pixelPerfect(10), paddingBottom: appTheme.pixelPerfect(20), alignItems: "center", borderRadius: bodyViewBorderRadius }}>
                <Text numberOfLines={5} style={{ color: appTheme.white, fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "center" }}>{textToDiplay}</Text>
                <Divider />
                <LinkButton onPress={() => showMore(bodyArray)} title="Voir plus" color={appTheme.white} />
                {backButton}
            </View>
        </View>
    }

    const getContent = () => {
        if (props.isPageOnScreen === false) {
            return <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator loading={true} color={appTheme.white} />
            </View>
        }
        if (viewDidAppear === false) {
            return <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), justifyContent: "center", alignItems: "center" }}>
                <Text style={{ color: "#FFF" }}>{superpowerKey}</Text>
                <ActivityIndicator loading={true} color={appTheme.white} />
            </View>
        }
        let imageWidth = appTheme.getFullAppWidth();
        if (imageWidth > 1920) {
            imageWidth = 1920;
        }
        const imageHeight = Platform.OS === "web" ? imageWidth * 384 / 1920 : imageWidth * 156 / 360;
        return <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), justifyContent: "flex-start", alignItems: "center" }}>
            <ScrollView style={{ flex: 1 }}>
                <View style={{ backgroundColor: appTheme.darkBlue, width: appTheme.getFullAppWidth(), alignItems: "center" }}>
                    <View style={{ alignItems: "center", width: appTheme.getFullAppWidth() }}>
                        <Image source={images[superPowerData.bg]} style={{ backgroundColor: appTheme.darkBlue, width: imageWidth, height: imageHeight }} resizeMode="cover" />
                    </View>
                    {getBodyView()}
                    {getCategoriesList()}
                </View>
            </ScrollView>
        </View>
    }

    const showMore = (body: string[]) => {
        actionSheetRef.current.showAlert({
            actionSheetType: "READ_MORE",
            data: {
                arrayOfStrings: body,
                title: i18n.t("tags." + superpowerKey)
            }
        });
    }

    return <View style={{ width: appTheme.getFullAppWidth(), flex: 1, backgroundColor: appTheme.darkBlue }}>
        {getContent()}
        <ActionSheet textColor={appTheme.white} backgroundColor={"#354F84"} componentId={componentId} ref={actionSheetRef} />
        <InViewModal ref={inViewModal} />
    </View>
}

export default SuperPowerSheet;