import { ViewStyle, Platform, ImageStyle } from "react-native";
import AppTheme from "../../utils/Theme";

export default class ActivityMediaListStyle {

    appTheme: AppTheme = new AppTheme();

    headerImageWidth: number = Platform.OS === "web" ? this.appTheme.getBlockWidth() - this.appTheme.pixelPerfect(20) : this.appTheme.getFullAppWidth();
    headerImageHeight: number = Platform.OS === "web" ? 340*this.headerImageWidth/1100 : this.appTheme.pixelPerfect(200) + this.appTheme.topInsets;

    headerImage: ImageStyle = {
        marginTop: Platform.OS === "web" ? this.appTheme.pixelPerfect(10) : 0,
        borderRadius: Platform.OS === "web" ? this.appTheme.pixelPerfect(8) : 0,
        width: this.headerImageWidth,
        height: this.headerImageHeight
    }

    horizontalScrollView: ViewStyle = { 
        width: Platform.OS === "web" ? this.appTheme.getBlockWidth() : this.appTheme.getFullAppWidth()
    }

    horizontalScrollViewMobile: ViewStyle = { 
        width: Platform.OS === "web" ? this.appTheme.getBlockWidth() : this.appTheme.getFullAppWidth(), 
        height: this.appTheme.getFullScreenHeight() - this.headerImageHeight
    }

    mediaContent : ViewStyle = {  
        width: Platform.OS === "web" ? this.appTheme.getBlockWidth() : this.appTheme.getFullAppWidth(), 
        paddingBottom: this.appTheme.bottomInsets, 
        backgroundColor: this.appTheme.white
    }

    innerContainerStyle: ViewStyle = { 
        width: Platform.OS === "web" ? this.appTheme.getBlockWidth() : this.appTheme.getFullAppWidth(), 
        borderRadius: Platform.OS === "web" ? this.appTheme.pixelPerfect(10) : 0,
        alignItems: "center", 
        backgroundColor: this.appTheme.white,
        paddingHorizontal: Platform.OS === "web" ? this.appTheme.pixelPerfect(40) : this.appTheme.pixelPerfect(10),
        paddingBottom: this.appTheme.pixelPerfect(20)
    }

    contentView: ViewStyle = {
        width: Platform.OS === "web" ? this.appTheme.getBlockWidth() : this.appTheme.getFullAppWidth(), 
    }

    sheetContentScrollView: ViewStyle = {
        width: Platform.OS === "web" ? this.appTheme.getBlockWidth() : this.appTheme.getFullAppWidth(), 
        height: this.appTheme.getFullScreenHeight() - this.headerImageHeight
    }

    constructor() {

    }
}