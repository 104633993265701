import User from "../data/user/User";
import i18n from "./Localization/Localization";
export function replaceAll(oldString, stringToChange, stringToReplace) {
    const stringArray = oldString.split(stringToChange);
    let stringToReturn = '';
    for (const aStringIndex in stringArray) {
        if (stringArray.hasOwnProperty(aStringIndex)) {
            const aString = stringArray[aStringIndex];
            if (aStringIndex !== stringArray.length - 1) {
                stringToReturn = stringToReturn + aString + stringToReplace;
            }
        }
    }
    return stringToReturn;
}

export function getFileNameFromUrl(url) {
    const stringArray = url.split('/');
    if (stringArray[stringArray.length - 1] !== undefined) {
        const finalPath = stringArray[stringArray.length - 1];
        const finalPathArray = finalPath.split('.pdf');
        if (finalPathArray[0] !== undefined) {
            let fileName = decodeURI(finalPathArray[0]);
            const fileNameBlankArray = fileName.split(' ');
            if (fileNameBlankArray[fileNameBlankArray.length - 1] !== undefined) {
                fileName = fileNameBlankArray[fileNameBlankArray.length - 1];
            }
            const fileName2FArray = fileName.split('%2F');
            if (fileName2FArray[fileName2FArray.length - 1] !== undefined) {
                fileName = fileName2FArray[fileName2FArray.length - 1];
            }
            return fileName;
        }
        return finalPath;
    }
    return url;
}

export function replaceCustomVariablesInString(text) {
    const user = User.getInstance();
    const userProfile = user.profile !== undefined ? user.profile : "unknown";
    if (text.indexOf("$$") !== -1) {
        const keyToBeReplaced = text.substring(text.indexOf("$$") + 2, text.lastIndexOf("$$"));
        let transformedText = text.replace("$$" + keyToBeReplaced + "$$", i18n.t("keys." + keyToBeReplaced + "." +userProfile));
        return transformedText;
    }
    return text;
}

export const validateEmail = (email) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
};