import { loadStripe } from '@stripe/stripe-js';
export const stripeVersionProd: boolean = true;
export const stripeApiKey:string = stripeVersionProd === true ? "pk_live_s5ZXgWU0YpbP8LCRnFgUv1AN00ZdHpcjwO" : "pk_test_WNVyz5hDRzMEzDUTxfGOjp2A00EX8ZPW7H";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripeApiKey);
  }
  return stripePromise;
};

export default getStripe;