import React from "react";
import { useState, useEffect, useRef } from "react";
import * as Navigation from "../../../../specific/utils/Navigation/Navigation";
import Main from "../../../../specific/components/Main/Main";
import i18n from "../../../utils/Localization/Localization";


import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    Dimensions,
    Image,
    ScrollView,
    Modal,
    SafeAreaView,
    Animated,
    LayoutChangeEvent
} from 'react-native';

import AppTheme, { Theme } from "../../../utils/Theme";
import * as Constants from "../../../utils/Constants";
import MainOptions from "../../../models/MainOptions";
import { isUserATeacher, setUserType } from "../../../utils/LocalStorage";

// UI
import Button from "../../../designSystem/Button";
import TextInput from "../../../designSystem/TextInput";

// Assets
import Images from "../../../../specific/utils/Images";

// CardFlip
import CardFlip from "../../../../specific/components/CardFlip/CardFlip";
import TeacherSignUp from "./TeacherSignUp";
import TeacherSignIn from "./TeacherSignIn";

import { useParams } from "react-router-dom";
import { analyctisEventPageViewed, propertiesEventScreenLabel, trackEvent } from "../../../services/Tracking/Analytics";

const TeacherAuthentication = (props: any) => {

    let propsData = props;
    if (Platform.OS === "web") {
        propsData = useParams();
    }

    const [viewDidAppear, setViewDidAppear] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [firstViewOpacity] = useState(new Animated.Value(1));
    const defaultValueForSecondOpacity = Platform.OS === "web" ? 1 : 0;
    const [secondViewOpacity] = useState(new Animated.Value(defaultValueForSecondOpacity));
    const [signUpStep, setSignUpStep] = useState(0);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const [screenHeight, setScreenHeight] = useState(appTheme.getFullScreenHeight);

    // ComponentDidMount
    const onViewDidAppear = () => {
        if( viewDidAppear === false) {
            trackEvent(analyctisEventPageViewed, {[propertiesEventScreenLabel]:"teacher_authentication"})
        }
        setViewDidAppear(true);
    }

    // MainOptions
    const { componentId="" } = props;
    const mainOptions: MainOptions = new MainOptions({
        key:"teacher_authentication",
        componentId,
        showNavBar: false,
        showMenu: false,
        safeArea: true,
        canGoBack: signUpStep === 0 ? true : false,
        navigationItemColor: appTheme.white,
        navigationColor: appTheme.schoolBackgroundColor
    });

    const cardFlipRef = useRef(null);

    const onFlipRequest = (targetFirstViewOpacity: number) => {
        cardFlipRef.current?.flipTheCard();
        if (Platform.OS !== "web") {
            Animated.parallel([
                Animated.timing(firstViewOpacity, {
                    toValue: targetFirstViewOpacity,
                    duration: 500,
                    useNativeDriver: false
                }),
                Animated.timing(secondViewOpacity, {
                    toValue: 1 - targetFirstViewOpacity,
                    duration: 500,
                    useNativeDriver: false
                })
            ]).start();
        }
    }

    const calibrateHeight = (layoutEvent: LayoutChangeEvent) => {
        setScreenHeight(layoutEvent.nativeEvent.layout.height);
    }

    const onStepChange = (step:number) => {
        // Le but est d'envoyer le numéro de l'étape de l'inscription prof, pour savoir si on affiche le bouton retour (uniquement sur l'étape 1)
        setSignUpStep(step);
    }

    const getFirstFlipView = () => {
        if (propsData.screen === "signUp") {
            return <TeacherSignUp onStepChange={onStepChange} componentId={componentId} onFlipRequest={() => onFlipRequest(0)} />
        }
        return <TeacherSignIn componentId={componentId} onFlipRequest={() => onFlipRequest(0)} />
    }

    const getSecondFlipView = () => {
        if (propsData.screen === "signUp") {
            return <TeacherSignIn componentId={componentId} onFlipRequest={() => onFlipRequest(1)} />
        }
        return <TeacherSignUp onStepChange={onStepChange} componentId={componentId} onFlipRequest={() => onFlipRequest(1)} />
    }

    return (
        <Main mainOptions={mainOptions}>
            <View onLayout={onViewDidAppear} style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: appTheme.windowHeight, top: 0, left: 0, backgroundColor:appTheme.schoolBackgroundColor }} />
            <ScrollView 
                    showsVerticalScrollIndicator={false} onLayout={calibrateHeight} style={{ flex: 1 }}>
                <View style={{ width: appTheme.getFullAppWidth(), height: screenHeight }}>
                    <CardFlip ref={cardFlipRef}>
                        <Animated.View style={{ flex: 1, opacity: firstViewOpacity }}>
                            {getFirstFlipView()}
                        </Animated.View>
                        <Animated.View style={{ flex: 1, opacity: secondViewOpacity }}>
                            {getSecondFlipView()}
                        </Animated.View>
                    </CardFlip>
                </View>
            </ScrollView>
        </Main>
    )
}

export default TeacherAuthentication;