import React, { SVGProps } from "react";

import {
    Text,
    TouchableOpacity,
    TouchableOpacityProps
} from "react-native";

import AppTheme from "../../utils/Theme";
import ActivityIndicator from "../ActivityIndicator/ActivityIndicator";

interface RoundButtonProps extends TouchableOpacityProps {
    color?: string,
    title?: string,
    isLoading?: boolean,
    textColor?: string
}

const RoundButton = (props: RoundButtonProps) => {

    const appTheme: AppTheme = new AppTheme();
    const { isLoading = false, disabled = false, color = appTheme.navigationColor, textColor = appTheme.white, title="OK", style, ...restProps } = props;

    const isButtonEnabled = ((disabled === true) || (isLoading === true));

    const getContent = () => {
        if (isLoading === true) {
            return <ActivityIndicator loading={true} color={textColor} />
        }
        return <Text style={{color:textColor, textTransform:"uppercase", fontFamily:appTheme.primarySemiBoldFont, fontSize:appTheme.pixelPerfectForFont(8)}}>{title}</Text>;
    }

    return <TouchableOpacity disabled={isButtonEnabled} style={[{paddingVertical:appTheme.pixelPerfect(8), paddingHorizontal:appTheme.pixelPerfect(30), minHeight:appTheme.pixelPerfect(30), backgroundColor:appTheme.darkBlue, borderRadius:appTheme.pixelPerfect(30)}, style]} {...restProps}>
        {getContent()}
    </TouchableOpacity>
}

export default RoundButton;