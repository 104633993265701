import { ViewStyle, Platform } from "react-native";
import AppTheme from "../../utils/Theme";

export default class CategoryHomeStyle {

    appTheme: AppTheme = new AppTheme();

    navBarHeight = Platform.OS === "web" ? this.appTheme.pixelPerfect(80) : this.appTheme.pixelPerfect(60);
    headerImageHeight = Platform.OS === "web" ? this.appTheme.pixelPerfect(210) : this.appTheme.pixelPerfect(115) + this.appTheme.topInsets;

    mainView = (backgroundColor: string) => {
        return {
            flex: 1,
            width:this.appTheme.getFullScreenWidth(),
            alignItems:"center",
            paddingTop: this.navBarHeight + this.appTheme.topInsets
        }
    }

    mainBackground = (backgroundColor: string) => {
        return {
            width:Platform.OS === "web" ? "100%" : this.appTheme.getFullScreenWidth(),
            height:Platform.OS === "web" ? "100%" : this.appTheme.getFullScreenHeight(),
            backgroundColor,
            position: Platform.OS === "web" ? "fixed" : "absolute",
            top: 0,
            left: 0
        }
    }

    navigationBar = (backgroundColor: string) => {
        return {
            position: Platform.OS === "web" ? "fixed" : "absolute",
            top: 0,
            left: 0,
            backgroundColor: Platform.OS === "web" ? this.appTheme.white : backgroundColor,
            width: this.appTheme.getFullScreenWidth(),
            height: this.navBarHeight + this.appTheme.topInsets,
            paddingTop: this.appTheme.topInsets,
            alignItems: "center"
        }
    }

    navigationBarContent: ViewStyle = {
        paddingHorizontal: this.appTheme.pixelPerfect(5),
        flexDirection: "row",
        width: Platform.OS === "web" ? this.appTheme.getBlockWidth() : this.appTheme.getFullAppWidth(),
        height: this.navBarHeight,
        justifyContent: "space-between",
        alignItems: "center"
    }

    headerImage = (backgroundColor: string) => { 
        return {
            position: Platform.OS === "web" ? "fixed" : "relative",
            width: this.appTheme.getFullScreenWidth(), 
            height: this.headerImageHeight, 
            backgroundColor
        }
    }

    bodyIntro = (backgroundColor:string) => { 
        return {
            width: Platform.OS === "web" ? this.appTheme.getBlockWidth() - this.appTheme.pixelPerfect(20) : this.appTheme.getFullScreenWidth(),
            borderRadius: Platform.OS === "web" ? this.appTheme.pixelPerfect(10) : 0, 
            paddingHorizontal: this.appTheme.pixelPerfect(20),
            paddingVertical: this.appTheme.pixelPerfect(10),
            backgroundColor, 
            justifyContent: "center", 
            alignItems: "center" 
        }
    }

    contentView: ViewStyle = { 
        marginTop: Platform.OS === "web" ? this.headerImageHeight : 0,
        backgroundColor: this.appTheme.white, 
        width: Platform.OS === "web" ? this.appTheme.getBlockWidth() : this.appTheme.getFullAppWidth(), 
        alignItems:"center", 
        borderRadius: Platform.OS === "web" ? this.appTheme.pixelPerfect(10) : 0,
        paddingBottom: Platform.OS === "web" ? this.appTheme.pixelPerfect(20) : 0
    }

    backgroundImageStyle: ViewStyle = {
        ...(this.mainView as object),
        position: "absolute"
    }

    introIllustrationBackground = (backgroundColor:string) => { 
        return {
            top: Platform.OS === "web" ? this.appTheme.pixelPerfect(-25) : this.appTheme.pixelPerfect(-35), 
            position: "absolute", 
            backgroundColor, 
            width: this.appTheme.pixelPerfect(70), 
            height: this.appTheme.pixelPerfect(70), 
            borderRadius: this.appTheme.pixelPerfect(35), 
            alignItems: "center"
        }
    }

    introIllustration: ViewStyle = { 
        top: Platform.OS === "web" ? this.appTheme.pixelPerfect(-15) : this.appTheme.pixelPerfect(-25), 
        position: "absolute", 
        width: this.appTheme.pixelPerfect(44), 
        height: this.appTheme.pixelPerfect(25) 
    }

    modalStyle: ViewStyle = {
        position:"absolute", 
        top: this.navBarHeight + this.appTheme.pixelPerfect(10),
        width:this.appTheme.getFullAppWidth(), 
        alignItems:"center"
    }

    constructor() {

    }
}