// Utils
import { isUserATeacher } from "../utils/LocalStorage";

import { userOptions } from "./Auth";
import { version, build } from "../../../package.json";

// Firebase
import * as Database from "../../specific/services/Specific_Database";
import * as Constants from "../../shared/utils/Constants";

import User from "../data/user/User";
import { EventRegister } from "../utils/EventListeners";
import { PremiumCard } from "../components/LiliHQ/LHQCreateCode";



export const getSchoolId = async (data: { name: string, address: string, zipcode: string, city: string, country: string }) => {
    const { name, address, zipcode, city, country } = data;
    const schoolId = await Database.getSchoolId({ name, address, zipcode, city, country });
    return schoolId;
}

export const createUserWithData = async (data: { context: string, email: string, user_id: string, options?: userOptions }) => {
    const { user_id, options = {}, email, context } = data;
    const { optInNewsletter = false, optInCoachNewsLetter = false, firstname = "", lastname = "", school_id = "", classes = [], tneEnable = false } = options;
    let newUserDocumentContent = {
        email,
        version,
        build: parseInt(build),
        creation_version: version,
        creation_build: parseInt(build),
        creation_date: new Date().getTime(),
        last_update: new Date().getTime(),
        profile: context
    }
    for (const anOptionKey in options) {
        if (Object.prototype.hasOwnProperty.call(options, anOptionKey)) {
            const anOptionValue = options[anOptionKey];
            newUserDocumentContent[anOptionKey] = anOptionValue;
        }
    }
    try {
        // Si nous créons un professeur nous allons d'abord lui créer un code
        const newUserData = await Database.setDocument({
            aCollection: Constants.DBCollectionUsers,
            content: {
                email,
                firstname,
                lastname,
                optInCoachNewsLetter,
                optInNewsletter,
                profile: context,
                school_id,
                classes,
                tneEnable,
                creation_date: new Date().getTime(),
                last_update: new Date().getTime(),
                version,
                build: parseInt(build),
                creation_version: version,
                creation_build: parseInt(build)
            },
            key: user_id
        });
        return true;
    } catch (error) {
        throw error;
    }
}

export const addDataToUser = async (data: { key: string, value: any, user_id: string }) => {
    const { key, value, user_id } = data;
    // To
    try {
        const newUserData = await Database.addDataToUser({ key, value, user_id });
        return newUserData;
    } catch (error) {
        console.log({error});
    }
}

export const checkUserDd = async (data: { user_id: string, dbUserData:any }) => {
    const { user_id, dbUserData } = data;
    // To
    try {
        const newUserData = await Database.checkUserDd({ user_id, dbUserData });
        return newUserData;
    } catch (error) {
    }
}

export async function getUserWithId(data: { user_id: string }) {
    const { user_id } = data;
    try {
        const userData = await Database.getUserWithId({ user_id });
        if (userData === undefined) {
            return undefined;
        }
        if (userData.build === undefined) {
            // Cela signifie que nous avons un utilisateur des versions 1. Il faut migrer ses favoris
            if (userData.favorites !== undefined) {
                let newFavorites: string[] = [];
                for (const aSectionKey in userData.favorites) {
                    if (Object.prototype.hasOwnProperty.call(userData.favorites, aSectionKey)) {
                        if (aSectionKey !== "version") {
                            const aFavArray: string[] = userData.favorites[aSectionKey];
                            newFavorites = newFavorites.concat(aFavArray);
                        }
                    }
                }
                await addDataToUser({
                    key: "favorite_activities",
                    value: newFavorites,
                    user_id
                });
            }
            // Migrer ses livres favories
            if (userData.fav_books !== undefined) {
                let newFavoriteBooks: string[] = [];
                for (const aSectionKey in userData.fav_books) {
                    if (Object.prototype.hasOwnProperty.call(userData.fav_books, aSectionKey)) {
                        if (aSectionKey !== "version") {
                            const aFavArray: string[] = userData.fav_books[aSectionKey];
                            newFavoriteBooks = newFavoriteBooks.concat(aFavArray);
                        }
                    }
                }
                await addDataToUser({
                    key: "favorite_books",
                    value: newFavoriteBooks,
                    user_id
                });
            }
            await addDataToUser({
                key: "creation_version",
                value: "1.X",
                user_id
            });
            await addDataToUser({
                key: "creation_build",
                value: 347,
                user_id
            });
            await addDataToUser({
                key: "version",
                value: version,
                user_id
            });
            await addDataToUser({
                key: "build",
                value: parseInt(build),
                user_id
            });
        }
        return userData;
    } catch (error) {
        throw error;
    }
}

export const addUserWithData = async (data: { user_id: string, user_email: string, school_name: string, school_zipcode: string, opt_in: boolean, sub_id?: string, duration_in_month?: number, addCode?: boolean }) => {
    const { user_id, user_email, school_name, school_zipcode, opt_in, sub_id, duration_in_month, addCode } = data;
    try {
        const userAdditionResult = await Database.addUserWithData({ user_id, user_email, school_name, school_zipcode, opt_in, sub_id, duration_in_month, addCode });
        return userAdditionResult;
    } catch (error) {
        throw error;
    }
}

export async function addSubToUser(data: { user_id: string, sub_id: string, duration_in_month: number, origin?: string, card_id?: string }) {
    const { user_id, sub_id, duration_in_month, origin, card_id } = data;
    try {
        const updateResult = await Database.addSubToUser({
            user_id,
            sub_id,
            duration_in_month,
            origin,
            card_id
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

export const getCardWithCode = async (data: { code: string }) => {
    const { code } = data;
    try {
        return await Database.getCardWithCode({ code });
    } catch (error) {
        throw error;
    }
}

export async function redeemedPremiumCardInDb(data: { card: PremiumCard, user: User }) {
    const { card, user } = data;
    try {
        return await Database.redeemedPremiumCardInDb({ card, user });
    } catch (error) {
        throw error;
    }
}

export async function redeemedCardInDb(data: { card: any, user: User }) {
    const { card, user } = data;
    try {
        return await Database.redeemedCardInDb({ card, user });
    } catch (error) {
        throw error;
    }
}

export async function transfertCardToNewUser(data: { card: any, user_email: string }) {
    const { card, user_email } = data;
    try {
        return await Database.transfertCardToNewUser({ card, user_email });
    } catch (error) {
        throw error;
    }
}

export const getUserGiftCards = async (data: { user_email: string }) => {
    //customer_email
    //user_email
    const { user_email } = data;
    try {
        const userGiftCards = await Database.getUserGiftCards({ user_email });
        return userGiftCards
    } catch (error) {
        throw error;
    }
}

export const createTeacherCode = async (data: { user_id: string, user_email: string, school_name: string, school_zipcode: string }) => {
    const { user_id, user_email, school_name, school_zipcode } = data;
    try {
        const updateResult = await Database.createTeacherCode({
            user_id,
            user_email,
            school_name,
            school_zipcode
        });
        return updateResult;
    } catch (error) {
        throw error;
    }
}

export function getActivities() {
    return new Promise(function (resolve, reject) {
        Database.getCollection({ aCollection: "acti", query: "" }).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}

export function saveActivities(data: { key: string, content: any }) {
    return new Promise(function (resolve, reject) {
        const { key, content } = data;
        Database.setDocument({ aCollection: "acti", key: key, content: content }).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}

// GAMIFICATION
export const getUserActions = async (data: { user_id: string }) => {
    const { user_id } = data;
    try {
        const userActionsData = await Database.getUserActions({
            user_id
        });
        return userActionsData;
    } catch (error) {
        throw error;
    }
}

// USER DELETION
export const deleteUser = async (data: { user_id: string }) => {
    const { user_id } = data;
    try {
        const deletionUserResult = await Database.deleteUser({ user_id });
        return deletionUserResult;
    } catch (error) {
        throw error;
    }
}

// LISTENERS
export const revokeDBListeners = () => {
    Database.revokeDBListeners();
}

export const launchUserListener = async (data: { user_id: string }) => {
    const { user_id } = data;
    Database.launchUserListener({ user_id });
}

// Déblocage de badges
export const unlockedTheBadge = async (data: { badgeId: string, user_id: string }) => {
    const { badgeId, user_id } = data;
    try {
        const userActions = await Database.unlockedTheBadge({ badgeId, user_id });
        const user: User = User.getInstance();
        if (userActions !== undefined) {
            user.setActionsUserData(userActions);
            EventRegister.emit(Constants.EventUserAuthDB, user.getUserData());
        }
        return userActions;
    } catch (error) {
        throw error;
    }
}