import React from "react";
import { useState, useEffect, useRef } from "react";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import Main from "../../../specific/components/Main/Main";

import {
    View,
    Text,
    ActivityIndicator,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    Linking
} from 'react-native';

import AppTheme from "../../utils/Theme";
import Images from "../../../specific/utils/Images";
import MainOptions from "../../models/MainOptions";

// Services 
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from "../../services/Auth";
import { getUserWithId, addSubToUser, createTeacherCode, addUserWithData } from "../../services/Database";

import Button from "../../designSystem/Button";
import Image from "../../designSystem/OTBImage";
import i18n from "../../utils/Localization/Localization";
import TextInput from "../../designSystem/RoundTextInput";

import Alert from "../Notification/Alert";
import RadioButton from "../../designSystem/RadioButton";

interface Props {
    name: string;
}

const TeacherForm = (props: any) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isSignUp, setIsSignUp] = useState(true);
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [optIn, setOptIn] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [user, setUser] = useState({ email: "", sub_expiration: "", teacher_code: "" });

    const alertRef = useRef(null);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const titleStyle = {
        color: "#25364f",
        fontWeight: "700",
        fontSize: appTheme.pixelPerfectForFont(16),
        textAlign: "center",
        paddingHorizontal: appTheme.pixelPerfect(5),
        paddingVertical: appTheme.pixelPerfect(10)
    };

    const introStyle = {
        color: "#25364f",
        fontWeight: "500",
        fontSize: appTheme.pixelPerfectForFont(15),
        textAlign: "left",
        paddingHorizontal: appTheme.pixelPerfect(20),
        paddingVertical: appTheme.pixelPerfect(10)
    };

    const buttonTitleStyle = {
        fontSize: appTheme.pixelPerfectForFont(8),
        textAlign: "center",
        fontWeight: "600"
    };

    const activitiesTitle = {
        color: "#25364f",
        fontWeight: "600",
        fontSize: appTheme.pixelPerfectForFont(10),
        textAlign: "center",
    };

    const activitiesBodyStyle =  {
        color: "#25364f",
        fontWeight: "400",
        fontSize: appTheme.pixelPerfectForFont(10),
        textAlign: "center",
    };

    // ComponentDidMount
    useEffect(() => {
        // A l'entrée sur la page je déconnecte l'utilisateur, pour être sur de ne pas être sur une session déjà connecté
        signOut();
    }, []);

    // UI
    const loadingView = isLoading === true ? <ActivityIndicator color={appTheme.secondaryColor} /> : <View />;

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        key:"teacher_form",
        componentId: "",
        showNavBar: false,
        showMenu: false
    });

    const submitForm = async () => {
        setIsLoading(true);
        let canGoThrough = false;
        if (isSignUp === true) {
            canGoThrough = ((emailAddress.length > 0) && (password.length > 0) && (zipCode.length > 0) && (schoolName.length > 0));
        } else {
            canGoThrough = ((emailAddress.length > 0) && (password.length > 0))
        }
        if (canGoThrough === true) {
            if (isSignUp === true) {
                if (password.length > 5) {
                    try {
                        const authResult = await createUserWithEmailAndPassword({
                            email: emailAddress,
                            password
                        });
                        const dbUserCreationResult = await addUserWithData({
                            user_id: authResult.uid,
                            user_email: emailAddress,
                            school_name: schoolName,
                            school_zipcode: zipCode,
                            opt_in: optIn,
                            sub_id: "cool.lili.sub.laspremium",
                            duration_in_month: 1,
                            addCode: true
                        });
                        alertRef.current?.showAlert({
                            title: i18n.t("teacherForm.success.signUp.title"),
                            body: i18n.t("teacherForm.success.signUp.body")
                        });
                        setIsLoading(false);
                        resetFormForConnectedUser();
                    } catch (error) {
                        setIsLoading(false);
                        if (error.code === "auth/email-already-in-use") {
                            alertRef.current.showAlert({
                                title: i18n.t("teacherForm.error.addressAlreadyInUse.title"),
                                body: i18n.t("teacherForm.error.addressAlreadyInUse.body")
                            });
                        } else {
                            alertRef.current.showAlert({
                                title: i18n.t("teacherForm.error.default")
                            });
                        }
                    }
                } else {
                    setIsLoading(false);
                    alertRef.current.showAlert({
                        title: i18n.t("teacherForm.error.impossibleSubscription"),
                        body: i18n.t("teacherForm.error.tooShortPassword")
                    });
                }
            } else {
                try {
                    const authResult = await signInWithEmailAndPassword({
                        email: emailAddress,
                        password
                    });
                    // Une fois connecté on va récupérer le détail du compte pour voir si la personne a déjà un abonnement en cours
                    if (authResult !== undefined) {
                        setIsConnected(true);
                        if (authResult.uid !== undefined) {
                            const userData = await getUserWithId({
                                user_id: authResult.uid
                            });
                            let hasASub = false;
                            if (userData.sub !== undefined) {
                                const now = new Date().getTime();
                                if (now < userData.sub.expires_date) {
                                    hasASub = true;
                                }
                            }
                            if (hasASub === true) {
                                alertRef.current.showAlert({
                                    title: i18n.t("teacherForm.error.alreaySub.title"),
                                    body: i18n.t("teacherForm.error.alreadySub.body")
                                });
                            } else {
                                // La personne n'a pas d'abonnement. On va voir si elle a un code prof. Si non, on lui en ajoute un et on active son mois d'abonnement
                                if ((userData.code !== undefined) && (userData.code.code !== undefined)) {
                                    // La personne a déjà un code on lui ajoute un abonnement d'un mois
                                    const addSubResult = await addSubToUser({
                                        user_id: authResult.uid,
                                        sub_id: "cool.lili.sub.laspremium",
                                        duration_in_month: 1
                                    });
                                    alertRef.current.showAlert({
                                        title: i18n.t("teacherForm.success.signIn.title"),
                                        body: i18n.t("teacherForm.success.signIn.body")
                                    });
                                    resetFormForConnectedUser();
                                } else {
                                    const createCodeForUser = await createTeacherCode({
                                        user_id: authResult.uid,
                                        user_email: emailAddress,
                                        school_name: "",
                                        school_zipcode: ""
                                    });
                                    const addSubResult = await addSubToUser({
                                        user_id: authResult.uid,
                                        sub_id: "cool.lili.sub.laspremium",
                                        duration_in_month: 1
                                    });
                                    alertRef.current.showAlert({
                                        title: i18n.t("teacherForm.success.signIn.title"),
                                        body: i18n.t("teacherForm.success.signIn.body")
                                    });
                                    resetFormForConnectedUser();
                                }
                            }
                            setIsLoading(false);
                        };
                    } else {
                        setIsLoading(false);
                        alertRef.current.showAlert({
                            title: i18n.t("teacherForm.error.default")
                        });
                    }
                } catch (error) {
                    alertRef.current.showAlert({
                        title: i18n.t("teacherForm.error.default")
                    });
                }
            }

        } else {
            setIsLoading(false);
            alertRef.current.showAlert({
                title: i18n.t("teacherForm.error.impossibleSubscription"),
                body: i18n.t("teacherForm.error.allFieldsAreMandatory")
            });
        }
    }

    const resetFormForConnectedUser = () => {
        setIsConnected(true);
    }

    const openLink = (data: { url: string }) => {
        Linking.openURL(data.url);
    }

    const getSignUpSpecificInputs = () => {
        if (isSignUp === true) {
            return (
                <View>
                    <Text style={{ width: appTheme.pixelPerfect(220), textAlign: "left", paddingHorizontal: appTheme.pixelPerfect(10), paddingTop: appTheme.pixelPerfect(2), fontFamily: appTheme.secondaryFont }}>Mininum 6 caractères</Text>
                    <TextInput
                        key="schoolName"
                        style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), marginTop: appTheme.pixelPerfect(10) }]}
                        onChangeText={(value) => setSchoolName(value)}
                        value={schoolName}
                        placeholder={i18n.t("teacherForm.form.schoolNamePlaceholder")}
                        placeholderColor={"#ABABAB"}
                        autoCapitalize="none"
                        editable={true}
                    />
                    <TextInput
                        key="schoolZipCode"
                        style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), marginTop: appTheme.pixelPerfect(10) }]}
                        onChangeText={(value) => setZipCode(value)}
                        value={zipCode}
                        placeholder={i18n.t("teacherForm.form.zipCodePlaceholder")}
                        placeholderColor={"#ABABAB"}
                        autoCapitalize="none"
                        editable={true}
                    />
                    <RadioButton value={optIn} onPress={(value) => setOptIn(value)} style={{ marginStart: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(220), marginTop: appTheme.pixelPerfect(10) }}>
                        <Text style={{ flex: 1, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10) }}>
                            {i18n.t("teacherForm.form.button.newsletter")}
                        </Text>
                    </RadioButton>
                </View>
            )
        }
        return <View />;
    }

    const getOtherFormButton = () => {
        if (isConnected === true) {
            return <View style={{ flex: 1, alignItems: "center", backgroundColor: appTheme.alertBackgroundColor, marginHorizontal: appTheme.pixelPerfect(10), borderRadius: appTheme.pixelPerfect(10) }}>
                <Text style={[introStyle, { fontFamily: appTheme.primaryFont }]}>Vous bénéficiez maintenant d'un abonnement d'un mois sur votre compte Lili.</Text>
                <Text style={[introStyle, { fontFamily: appTheme.primaryFont }]}>Si vous n'avez pas encore installé l'application, vous pouvez le faire via les liens ci-dessous.</Text>
                <View style={{ flexDirection: "row", alignItems: "flex-end", justifyContent: "center", width: appTheme.pixelPerfect(240) }}>
                    <TouchableOpacity style={{flex:1, alignItems:"center"}} onPress={() => openLink({ url: "https://play.google.com/store/apps/details?id=fr.aotb.lili" })}>
                        <Image source={{ uri: "https://lili.cool/wp-content/themes/lili/assets/img/logo-google-play-oiseau.png" }} style={{ width: appTheme.pixelPerfect(100), height: appTheme.pixelPerfect(56) }} />
                    </TouchableOpacity>
                    <TouchableOpacity style={{flex:1, alignItems:"center"}} onPress={() => openLink({ url: "https://apps.apple.com/app/id1515173016" })}>
                        <Image source={{ uri: "https://lili.cool/wp-content/themes/lili/assets/img/logo-app-store-bleu.png" }} style={{ width: appTheme.pixelPerfect(85), height: appTheme.pixelPerfect(29), marginBottom: appTheme.pixelPerfect(4) }} />
                    </TouchableOpacity>
                </View>
            </View>
        }
        if (isSignUp === true) {
            return (<View style={{ width: appTheme.pixelPerfect(260), alignItems: "center" }}>
                <Button
                    onPress={() => setIsSignUp(false)}
                    title={i18n.t("teacherForm.form.button.alreadyRegistered")}
                    textStyle={{ fontFamily: appTheme.primaryFont, color: appTheme.white, fontSize:appTheme.pixelPerfectForFont(9) }}
                    style={{ backgroundColor: appTheme.typeChoicColor, width: appTheme.pixelPerfect(260), marginVertical: appTheme.pixelPerfect(10) }} />
                <Text style={[introStyle, { fontFamily: appTheme.primaryFont }]}>
                    {i18n.t("teacherForm.form.signUp.createYourAccount")}
                </Text>
            </View>)
        }
        return (<View style={{ width: appTheme.pixelPerfect(260), alignItems: "center" }}>
            <Button
                onPress={() => setIsSignUp(true)}
                title={i18n.t("teacherForm.form.button.noAccount")}
                textStyle={[buttonTitleStyle, { fontFamily: appTheme.primaryFont, color: appTheme.white }]}
                style={{ backgroundColor: appTheme.typeChoicColor, width: appTheme.pixelPerfect(260), marginVertical: appTheme.pixelPerfect(10) }} />
            <Text style={[introStyle, { fontFamily: appTheme.primaryFont }]}>
                {i18n.t("teacherForm.form.signIn.connect")}
            </Text>
        </View>)
    }

    const getFormContent = () => {
        if (isConnected === true) {
            return <View />
        }
        if (isLoading === true) {
            return (
                <View style={{ padding: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(10), justifyContent: "center", alignItems: "center", backgroundColor: appTheme.white }}>
                    <ActivityIndicator color={appTheme.secondaryColor} />
                    <Text style={[introStyle, { fontFamily: appTheme.primaryFont }]}>Connexion avec le serveur en cours...</Text>
                </View>
            )
        }
        return (<View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <TextInput
                style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), marginBottom: appTheme.pixelPerfect(10) }]}
                key="email"
                onChangeText={(value) => setEmailAddress(value)}
                placeholderColor={"#ABABAB"}
                value={emailAddress}
                placeholder={i18n.t("teacherForm.form.emailPlaceholder")}
                keyboardType="email-address"
                autoCapitalize="none"
                editable={true}
            />
            <TextInput
                key="password"
                password={true}
                style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220) }]}
                onChangeText={(value) => setPassword(value)}
                value={password}
                placeholder={isSignUp === true ? i18n.t("teacherForm.form.signUpPasswordPlaceholder") : i18n.t("teacherForm.form.signInPasswordPlaceholder")}
                placeholderColor={"#ABABAB"}
                autoCapitalize="none"
                editable={true}
            />
            {getSignUpSpecificInputs()}
            <Button
                onPress={submitForm}
                title={"C'est parti !"}
                textStyle={[buttonTitleStyle, { fontFamily: appTheme.primaryFont }]}
                style={[{ marginTop: appTheme.pixelPerfect(10), backgroundColor: appTheme.schoolColor, width: appTheme.pixelPerfect(220) }]} />
        </View >);
    }

    return (
        <Main mainOptions={mainOptions} isTeacherForm={true}>
            <View style={[styles.main, { backgroundColor: "#FFF1E1", paddingVertical: appTheme.pixelPerfect(10) }]}>
                <ScrollView 
                    showsVerticalScrollIndicator={false} style={{ flex: 1, paddingBottom: appTheme.pixelPerfect(40) }}>
                    <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center" }}>
                        <Image source={images.icLiliLesPetitsChampionsLogo} style={{ width:appTheme.pixelPerfect(200), height:appTheme.pixelPerfect(100) }} />
                    </View>
                    <Text style={[titleStyle, { fontFamily: appTheme.primaryFont }]}>
                        {i18n.t("teacherForm.title")}
                    </Text>
                    <View style={{ flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                        <View style={{ flex: 1, marginEnd: appTheme.pixelPerfect(5) }}>
                            <Text style={[introStyle, { fontFamily: appTheme.primaryFont }]}>
                                {i18n.t("teacherForm.body")}
                            </Text>
                        </View>
                        <View style={{ flex: 1, marginStart: appTheme.pixelPerfect(5), justifyContent: "center", alignItems: "center" }}>
                            <Image source={{ uri: "https://lili.cool/wp-content/themes/lili/assets/img/compo-devices.png" }} style={{ width: appTheme.pixelPerfect(300), height: appTheme.pixelPerfect(200) }} resizeMode="contain" />
                        </View>
                    </View>
                    <View style={{ flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                        <View style={{ flex: 1, justifyContent: "center", alignItems: "center", marginBottom:appTheme.pixelPerfect(20) }}>
                            <Text style={[titleStyle, { fontFamily: appTheme.primaryFont }]}>
                                {i18n.t("teacherForm.form.title")}
                            </Text>
                            {getOtherFormButton()}
                            {getFormContent()}
                        </View>
                        <View style={{ flex: 1 }}>
                            <View style={{ flex: 1, marginStart: appTheme.pixelPerfect(5) }}>
                                <View style={{ alignItems: "center", flexDirection: appTheme.getFlexDirectionForSplitScreen(), marginBottom: appTheme.pixelPerfect(15) }}>
                                    <Image
                                        source={{ uri: "https://lili.cool/wp-content/themes/lili/assets/img/vignette-meditation.png" }}
                                        style={{ width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(70) }} resizeMode="contain" />
                                    <View style={{ flex: 1, paddingHorizontal: appTheme.pixelPerfect(10) }}>
                                        <Text style={[activitiesTitle, { fontFamily: appTheme.primaryFont }]}>DES TEMPS CALMES</Text>
                                        <Text style={[activitiesBodyStyle, { fontFamily: appTheme.secondaryFont }]}>pour aider les élèves à se poser et à mieux gérer leurs émotions.</Text>
                                    </View>
                                </View>
                                <View style={{ alignItems: "center", flexDirection: appTheme.getFlexDirectionForSplitScreen(), marginBottom: appTheme.pixelPerfect(15) }}>
                                    <Image
                                        source={{ uri: "https://lili.cool/wp-content/themes/lili/assets/img/vignette-improvisation.png" }}
                                        style={{ width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(70) }} resizeMode="contain" />
                                    <View style={{ flex: 1, paddingHorizontal: appTheme.pixelPerfect(10) }}>
                                        <Text style={[activitiesTitle, { fontFamily: appTheme.primaryFont }]}>DES ATELIERS D'ARTS ET D'IMPROVISATION</Text>
                                        <Text style={[activitiesBodyStyle, { fontFamily: appTheme.secondaryFont }]}>pour que les élèves prennent confiance en eux et soient plus à l’aise à l’oral.</Text>
                                    </View>
                                </View>
                                <View style={{ alignItems: "center", flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                                    <Image
                                        source={{ uri: "https://lili.cool/wp-content/themes/lili/assets/img/vignette-philosophie.png" }}
                                        style={{ width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(70) }} resizeMode="contain" />
                                    <View style={{ flex: 1, paddingHorizontal: appTheme.pixelPerfect(10) }}>
                                        <Text style={[activitiesTitle, { fontFamily: appTheme.primaryFont }]}>DES ATELIERS DE PHILOSOPHIE POUR ENFANTS</Text>
                                        <Text style={[activitiesBodyStyle, { fontFamily: appTheme.secondaryFont }]}>pour que les élèves débattent et développent leur esprit critique.</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>
            <Alert ref={alertRef} />
        </Main>
    )
}

export default TeacherForm;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    }
});