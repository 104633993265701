import React from "react"
import { Platform, Image, View } from "react-native";

const SVGView = (props: { Component: any, width?: number, height?: number, size?:number, color?: string }) => {
   const { Component, width, height, size, color } = props;
   let imageWidth = width !== undefined ? width : size !== undefined ? size : 0;
   let imageHeight = height !== undefined ? height : size !== undefined ? size : 0;
   if (Platform.OS === "web") {
      return (
         <Image source={props.Component} style={{ width:imageWidth, height:imageHeight, tintColor: color }} resizeMode="contain" />
      )
   } else {
      return (
         <Component style={{color}} width={imageWidth} height={imageHeight} fill={color} />
      )
   }
}

export default SVGView;