import React from "react";

import BundleSections from "./jsons/bundleSections.json";
import BundleActivities from "./jsons/bundleActivities.json";
import BundleActivitiesByCategory from "./jsons/bundleActivitiesByCategory.json";
import BundleActivitiesByTag from "./jsons/bundleActivitiesByTag.json";
import BundleCategories from "./jsons/bundleCategories.json";
import BundleActivitiesByTagAndCategory from "./jsons/bundleActivitiesByTagAndCategory.json";
import BundleBadges from "./jsons/bundleBadges.json";

export const LocalDBContext = React.createContext({
    sections: BundleSections,
    categories: BundleCategories,
    activities: BundleActivities,
    activitiesByCategory: BundleActivitiesByCategory,
    activitiesByTagAndCategory: BundleActivitiesByTagAndCategory,
    activitiesByTag: BundleActivitiesByTag,
    badges:BundleBadges
});

export const LocalDBConsumer = LocalDBContext.Consumer;

export class LocalDBProvider extends React.Component {

    state = {
        sections: BundleSections,
        categories: BundleCategories,
        activities: BundleActivities,
        activitiesByCategory: BundleActivitiesByCategory,
        activitiesByTag: BundleActivitiesByTag,
        activitiesByTagAndCategory: BundleActivitiesByTagAndCategory,
        badges:BundleBadges,
    }

    render() {
        const { sections, categories, activities, activitiesByCategory, activitiesByTag, activitiesByTagAndCategory, badges } = this.state;
        return (
            <LocalDBContext.Provider
                value={{
                    sections,
                    categories,
                    activities,
                    activitiesByCategory,
                    activitiesByTag,
                    activitiesByTagAndCategory,
                    badges
                }}>
                {this.props.children}
            </LocalDBContext.Provider>
        )
    }

}

