import React, { useRef, useState } from "react";

import {
    Platform,
    Text,
    View,
    ActivityIndicator,
    Image,
    TouchableOpacity,
    Linking,
    ViewStyle,
    TextStyle
} from "react-native";
import { useNavigate, useParams } from "react-router-dom";

import Main from "../../../../specific/components/Main/Main";
import Images from "../../../../specific/utils/Images";
import MainOptions from "../../../models/MainOptions";
import i18n from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";
import DynamicBackground from "../../DynamicBackground/DynamicBackground";

import SignInForm from "../../Authentication/Forms/SignInForm";
import SignUpForm from "../../Authentication/Forms/SignUpForm";
import TeacherSchoolForm from "../../Authentication/Forms/TeacherSchoolForm";
import TeacherLevelsSelection from "../../Authentication/Forms/TeacherLevelsSelection";
import WebSubStyle from "../../../../specific/style/Authentication/WebSubStyle";
import { push, relaunchApp, relaunchPage, showModalInView } from "../../../../specific/utils/Navigation/Navigation";

import * as Constants from "../../../utils/Constants";
import { createUserWithEmailAndPassword, isConnnected } from "../../../services/Auth";
import User from "../../../data/user/User";
import { addContactToGeneralAudience } from "../../../services/Mailing";
import Alert from "../../Notification/Alert";
import { EventRegister } from "../../../utils/EventListeners";
import { getUserWithId } from "../../../services/Database";
import CloseButton from "../../../designSystem/Buttons/CloseButton";
import ForgotPassword from "../../Authentication/ForgotPassword";
import ForgotPasswordForm from "../../Authentication/Forms/ForgotPasswordForm";

interface WebSubscriptionProps {
    componentId?: string,
    type?: "las" | "lah",
    duration?: string
}

interface UserData {
    email?: string,
    password?: string,
    firstname?: string,
    lastname?: string,
    school_id?: string,
    classes?: string[],
    optIn?: boolean
}

const WebSubscription = (props: WebSubscriptionProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const style: WebSubStyle = new WebSubStyle();

    const { componentId = "", ...restProps } = props;

    let propsData = restProps;
    let navigate = null;

    if (Platform.OS === "web") {
        propsData = useParams();
        navigate = useNavigate();
    }

    const { type = "lah", duration = "12" } = propsData;

    const mainOptions: MainOptions = new MainOptions({
        key: "web_subscription",
        componentId,
        canBeDismissed: false,
        canBeHidden: false,
        canGoBack: false,
        showNavBar: false,
        showMenu: false,
        backgroundColor: type === "las" ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        navigationColor: type === "las" ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        safeArea: false
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [isSignIn, setIsSignIn] = useState<boolean>(false);
    const [userData, setUserData] = useState<UserData>({});
    const [onForgotPasswordRequest, setOnForgotPasswordRequest] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0); // Utilisé pour les étapes de l'inscription des profs

    const alertRef = useRef<Alert>(null);

    let headerImage = images.icLiliAtSchoolCircle;
    let backgroundColor = appTheme.schoolColor;
    if (type === "lah") {
        backgroundColor = appTheme.darkBlue;
        headerImage = images.icLiliAtHomeCircle;
    }

    const onViewDidAppear = async () => {
        // On va vérifier si l'utilisateur est déjà connecté
        const userAuthData: { email: string | null | undefined, uid: string | undefined } | null = await isConnnected();
        if ((userAuthData !== null) && (userAuthData.uid !== undefined)) {
            // On va vérifier que l'utilisateur a un profil (teacher ou parent) qui corresponde au type de l'abo (lah ou las)
            const user: User = User.getInstance();
            checkUserData(userAuthData);
            //connect();
        } else {
            setViewDidAppear(true);
        }
    }

    const closeThePage = () => {
        relaunchApp({ componentId, navigate });
    }

    const relaunchSubscriptionPage = () => {
        relaunchPage({
            componentId,
            navigate,
            name: Constants.ScreenSubscription,
            passProps: {
                status: "cancel"
            }
        });
    }

    const checkUserData = async (userAuthData: { email: string | null | undefined, uid: string | undefined }) => {
        const user: User = User.getInstance();
        await user.setAuthUserData(userAuthData);
        EventRegister.emit(Constants.EventUserAuthDB, userAuthData);
        // On va transmettre les informations de l'utilisateur
        const offlineUserData = await user.initUserData(userAuthData);
        const userData = await getUserWithId({ user_id: userAuthData?.uid });
        await user.setDBUserData(userData);
        if (user.isTeacher() === true) {
            if (type === "lah") {
                alertRef.current?.showAlert({
                    title: "Vous vous êtes inscrit en tant que Super-Prof !",
                    body: "Vous allez être redirigé vers la page d'abonnement enseignant.",
                    onPressPrimaryButton: relaunchSubscriptionPage
                });
            } else {
                connect();
            }
        } else {
            if (type === "lah") {
                connect();
            } else {
                alertRef.current?.showAlert({
                    title: "Vous vous êtes inscrit en tant que Super-Parent.",
                    body: "Vous allez être redirigé vers la page d'abonnement parent.",
                    onPressPrimaryButton: relaunchSubscriptionPage
                });
            }
        }
    }

    const launchSubscriptionPage = (userId: { email: string, password: string, firstname: string, lastname: string, optIn?: boolean }) => {
        if ((type === "lah") || (isSignIn === true)) {
            connect();
        } else {
            setUserData(userId);
            goToNextLevel();
        }
    }

    const launchTeacherLevelsPage = (schoolId: string) => {
        // On va récupérer l'id de l'école
        let updatedUserData = { ...userData };
        updatedUserData.school_id = schoolId;
        setUserData(updatedUserData);
        goToNextLevel();
    }

    const subscribeUser = async (classes: string[]) => {
        setIsLoading(true);
        const { firstname, lastname, email, password, school_id, optIn = false } = userData;
        const userSession: User = await createUserWithEmailAndPassword({
            context: Constants.CONTEXT.teacher,
            email,
            password,
            options: {
                firstname,
                lastname,
                optInCoachNewsLetter: false,
                optInNewsletter: optIn,
                school_id,
                classes
            }
        });
        const isDirector = classes.indexOf("direction") !== -1 ? true : false;
        // Si l'utilisateur a accepté de recevoir des e-mails, on l'ajoute à la base
        addContactToGeneralAudience({
            email,
            context: Constants.CONTEXT.teacher,
            optIn,
            optInCoach: false,
            isDirector
        });
        if ((userSession !== undefined) && (userSession.uid !== undefined)) {
            setIsLoading(false);
            connect();
        } else {
            setIsLoading(false);
            alertRef.current?.showAlert({
                title: "Une erreur est survenue",
                body: "Veuillez réessayer plus tard s'il vous plaît."
            });
        }
    }

    const connect = () => {
        relaunchPage({
            componentId,
            navigate,
            name: Constants.ScreenSelectedSubscription,
            passProps: {
                status: "deeplink",
                type,
                duration
            }
        });
    }

    const openGeneralConditions = () => {
        if (Platform.OS === "web") {
            let uri = "https://www.lili.cool/fr/mentions-legales/";
            Linking.openURL(uri);
        } else {
            showModalInView({
                componentId,
                name: Constants.ScreenCookiesPolicy,
                passProps: {
                    uri: "https://www.lili.cool/fr/mentions-legales/"
                },
                options: {
                    modalPresentationStyle: 'overCurrentContext',
                    layout: {
                        componentBackgroundColor: 'transparent'
                    }
                }
            });
        }
    }

    const getPagerView = () => {
        if ((type === "las") && (isSignIn === false)) {
            if (step === 4) {
                return <View />;
            }
            // Condition pour afficher le bouton permettant de basculer vers la connexion
            const positionIndicatorCircleStyle: ViewStyle = {
                width: appTheme.pixelPerfect(20),
                height: appTheme.pixelPerfect(20),
                borderRadius: appTheme.pixelPerfect(10),
                borderWidth: 1,
                justifyContent: 'center',
                alignItems: 'center'
            };
            const positionIndicatorTextStyle: TextStyle = {
                fontSize: appTheme.pixelPerfectForFont(6),
                fontWeight: "500"
            };
            let stepsBackgroundColors = [appTheme.schoolColor, appTheme.schoolBackgroundColor, appTheme.schoolBackgroundColor];
            let stepsColors = [appTheme.schoolBackgroundColor, appTheme.schoolColor, appTheme.schoolColor];
            if (step === 1) {
                stepsBackgroundColors = [appTheme.schoolColor, appTheme.schoolColor, appTheme.schoolBackgroundColor];
                stepsColors = [appTheme.schoolBackgroundColor, appTheme.schoolBackgroundColor, appTheme.schoolColor];
            } else if (step === 2) {
                stepsBackgroundColors = [appTheme.schoolColor, appTheme.schoolColor, appTheme.schoolColor];
                stepsColors = [appTheme.schoolBackgroundColor, appTheme.schoolBackgroundColor, appTheme.schoolBackgroundColor];
            }
            return <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: appTheme.pixelPerfect(10) }}>
                <View style={[positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[0], borderColor: appTheme.schoolColor }]}>
                    <Text style={[positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[0] }]}>1</Text>
                </View>
                <View style={{ width: appTheme.pixelPerfect(20), height: 1, backgroundColor: appTheme.schoolColor }} />
                <View style={[positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[1], borderColor: appTheme.schoolColor }]}>
                    <Text style={[positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[1] }]}>2</Text>
                </View>
                <View style={{ width: appTheme.pixelPerfect(20), height: 1, backgroundColor: appTheme.schoolColor }} />
                <View style={[positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[2], borderColor: appTheme.schoolColor }]}>
                    <Text style={[positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[2] }]}>3</Text>
                </View>
            </View>
        }
        return <View />;
    }

    const getSwitch = () => {
        if (step === 0) {
            return <View style={[style.authSwitch, { borderColor: backgroundColor }]}>
                <TouchableOpacity style={[style.switchButton, { backgroundColor: isSignIn === false ? backgroundColor : appTheme.white }]} onPress={() => setIsSignIn(false)}>
                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8), textAlign: "center", textTransform: "uppercase", color: isSignIn === false ? appTheme.white : backgroundColor }} >{i18n.t("websub.signUpButtonTitle")}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={[style.switchButton, { backgroundColor: isSignIn === true ? backgroundColor : appTheme.white }]} onPress={() => setIsSignIn(true)}>
                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8), textAlign: "center", textTransform: "uppercase", color: isSignIn === true ? appTheme.white : backgroundColor }} >{i18n.t("websub.signInButtonTitle")}</Text>
                </TouchableOpacity>
            </View>
        }
        return <View />
    }

    const forgotPasswordRequest = () => {
        setOnForgotPasswordRequest((forgotPasswordRequest) => !forgotPasswordRequest);
    }

    const onForgotPasswordSuccess = () => {
        let title = "Modification de mot de passe";
        let body = "Un e-mail vient de vous être envoyé pour réinitialiser votre mot de passe";
        alertRef.current?.showAlert({
            title,
            body,
            onPressPrimaryButton: forgotPasswordRequest
        });
    }

    const goToNextLevel = () => {
        setStep((step) => step + 1);
    }

    const getPageContent = () => {
        const titleColor = type === "las" ? appTheme.darkBlue : appTheme.getLiliAtHomeTitleColor();
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear}>
                <ActivityIndicator color={titleColor} />
            </View>
        }
        let subPrice = "55";
        let subDuration = "1 an";
        let subDurationType = "annuel";
        let subTitle = i18n.t("websub.las.subTitle");
        let subTitleColor = appTheme.homeColor;
        let legalMentionView = <View />;
        if (type === "las") {
            if (duration === "three") {
                subPrice = "24";
                subDuration = "3 mois";
            }
        } else {
            if (duration === "one") {
                subDurationType = "mensuel";
                subPrice = "4,99";
                subDuration = "mois";
            } else {
                subPrice = "35,99";
                subDuration = "an";
            }
        }
        let subDetails = i18n.t("websub.las.details", { price: subPrice, duration: subDuration });
        if (type === "lah") {
            subDetails = i18n.t("websub.lah.details", { price: subPrice, duration: subDuration });
            subTitle = i18n.t("websub.lah.subTitle", { duration: subDurationType });
            subTitleColor = appTheme.homeColor;
            legalMentionView = <Text style={style.legalMentions}>{i18n.t("websub.lah.legalMention", { duration: subDurationType })}</Text>;
        }

        const formTitle = isSignIn === true ? onForgotPasswordRequest === false ? i18n.t("websub.signIn") : i18n.t("websub.forgotPassword") : i18n.t("websub.signUp");
        const formWidth = appTheme.getBlockWidth() - appTheme.pixelPerfect(60);
        let formView = onForgotPasswordRequest === false ? <SignInForm type={type} width={formWidth} onSuccess={launchSubscriptionPage} forgotPasswordRequest={forgotPasswordRequest} /> : <ForgotPasswordForm type={type} width={formWidth} onSuccess={onForgotPasswordSuccess} onSignInFormRequest={forgotPasswordRequest} />;
        if (isSignIn === false) {
            if (step === 0) {
                formView = <SignUpForm openGeneralConditions={openGeneralConditions} type={type} width={formWidth} onSuccess={launchSubscriptionPage} />;
            } else if (step === 1) {
                formView = <TeacherSchoolForm openGeneralConditions={openGeneralConditions} type={type} width={formWidth} onSuccess={launchTeacherLevelsPage} />;
            } else if (step === 2) {
                formView = isLoading === false ? <TeacherLevelsSelection openGeneralConditions={openGeneralConditions} type={type} width={formWidth} onSuccess={subscribeUser} /> : <ActivityIndicator color={titleColor} />;
            }
        }
        const formTitleView = step === 0 ? <Text style={[style.authFormTitle, { color: backgroundColor }]}>{formTitle}</Text> : <View />;
        return <View style={style.container}>
            <Image style={style.headerLogo} source={headerImage} />
            <Text style={[style.title, { color: titleColor }]}>{i18n.t("websub.title")}</Text>
            <View style={[style.subFormContainer, { backgroundColor }]}>
                <View style={style.subDetailsContainer}>
                    <Text style={[style.subTitle, { color: subTitleColor }]}>{subTitle}</Text>
                    <Text style={style.subDetails}>{subDetails}</Text>
                    {legalMentionView}
                </View>
                <View style={style.authFormContainer}>
                    {getSwitch()}
                    <View style={style.authForm}>
                        {formTitleView}
                        <View style={{ flex: 1 }}>
                            {formView}
                        </View>
                    </View>
                </View>
            </View>
            {getPagerView()}
        </View>
    }
    //<CloseButton onPress={closeThePage} />
    return <Main mainOptions={mainOptions}>
        <DynamicBackground isTeacher={type === "las"} />
        {getPageContent()}
        <Alert ref={alertRef} />
    </Main>

}

export default WebSubscription;