import { SafeAreaView } from "moti";
import React, { useState } from "react";

import {
    TextInput,
    Dimensions,
    View,
    Modal,
    Text,
    TouchableOpacityProps,
    TouchableOpacity
} from "react-native";
import SVGView from "../utils/SvgView";
import AppTheme from "../utils/Theme";

const RoundIconButton = (props: TouchableOpacityProps & {iconName:any, backgroundColor:string}) => {

    const appTheme: AppTheme = new AppTheme();

    const { iconName, backgroundColor=appTheme.darkBlue } = props;

    return (
        <TouchableOpacity
            style={{
                justifyContent:"center",
                alignItems:"center",
                borderRadius:appTheme.pixelPerfect(40),
                width: appTheme.pixelPerfect(80),
                height: appTheme.pixelPerfect(80),
                backgroundColor: backgroundColor
            }}
            {...props}>
                <SVGView Component={iconName} size={appTheme.pixelPerfect(42)} color={appTheme.white} />
        </TouchableOpacity>
    )
}

export default RoundIconButton;
