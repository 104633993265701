import React from "react";

import {
    Image,
    ActivityIndicator as RNActivityIndicator,
    View
} from "react-native";
import Images from "../../../specific/utils/Images";

interface ActivityIndicatorProps {
    loading?:boolean,
    color?:string
}

const ActivityIndicator = (props:ActivityIndicatorProps) => {

    const images: Images = new Images();
    const { loading = false, color="#fff"} = props;

    if (loading === false) {
        return <View />;
    }
    return <RNActivityIndicator color={color} />

}

export default ActivityIndicator;