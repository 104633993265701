import React from "react";

import {
    Text,
    View
} from "react-native";
import { getLanguage } from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";

type localizedString = {
    fr?: string
}

interface ActivityCellBodyProps {
    body?: localizedString;
    color: string;
    width: number;
}

const ActivityCellBody = (props: ActivityCellBodyProps) => {
    const { body, color, width } = props;

    const appTheme: AppTheme = new AppTheme();

    return <View style={{ width, alignItems: "center", marginTop: appTheme.pixelPerfect(10) }}>
        <Text style={[{ fontFamily: appTheme.secondaryFont, color: appTheme.textColor, fontSize: appTheme.pixelPerfectForFont(11), paddingHorizontal: appTheme.pixelPerfect(20), textAlign: "center" }]}>
            {body[getLanguage()]}
        </Text>
    </View>
}

export default ActivityCellBody;