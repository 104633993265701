import React, { useState } from "react";

import {
    View,
    Text,
    StyleSheet,
    TextStyle,
    ViewStyle,
    TouchableOpacity,
    Image
} from "react-native";

import LHQDocumentation from "./LHQDocumentation";
import LHQCreateCode from "./LHQCreateCode";
import LHQActivitiesManagement from "./LHQActivitiesManagement";

import AppTheme from "../../utils/Theme";
import Divider from "../../designSystem/Divider/Divider";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { getUsersStats } from "../../../specific/services/Specific_Database";

type Page = "DASHBOARD" | "DOCUMENTATION" | "CODES" | "ACTIVITIES";

interface LHQAdminDashboardProps {

}

const LHQAdminDashboard = (props: LHQAdminDashboardProps) => {

    const appTheme: AppTheme = new AppTheme;

    const [stats, setStats] = useState(undefined);
    const [pageToDisplay, setPageToDisplay] = useState<Page>("DASHBOARD");

    const getStatistics = async () => {
        try {
            const userStats = await getUsersStats();
            setStats(userStats);
        } catch (error) {
            setStats([]);
        }
    }

    let boxWidth = appTheme.getFullAppWidth() - appTheme.pixelPerfect(20);
    if (boxWidth > 400) {
        boxWidth = appTheme.pixelPerfect(400);
    }

    const h1Style: TextStyle = {
        fontFamily: appTheme.primaryBoldFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(18)
    }

    const h2Style: TextStyle = {
        fontFamily: appTheme.primaryMediumFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(16)
    }

    const pStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(12)
    }

    const boxStyle: ViewStyle = {
        borderRadius: appTheme.pixelPerfect(20),
        borderWidth: appTheme.pixelPerfect(1),
        borderColor: appTheme.talk,
        padding: appTheme.pixelPerfect(10),
        width: boxWidth,
        marginHorizontal: appTheme.pixelPerfect(10),
        marginBottom: appTheme.pixelPerfect(10)
    }

    const boxContainerStyle: ViewStyle = {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start"
    }

    const formatNumbers = (aNumber: number) => {
        if (aNumber < 1000) {
            return aNumber.toString();
        } else {
            const thousands: number = parseInt(aNumber / 1000);
            const units: number = aNumber - thousands * 1000;
            return thousands.toString() + " " + units.toString();
        }
    }

    const getStatisticsView = () => {
        const contentWidth: number = boxWidth - appTheme.pixelPerfect(20);
        if (stats === undefined) {
            return <View onLayout={getStatistics} style={{ width: contentWidth, height: appTheme.pixelPerfect(60), justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator color={appTheme.talk} loading={true} />
            </View>
        }
        const keyStyle: TextStyle = {
            fontFamily: appTheme.primaryFont,
            color: appTheme.darkBlue,
            fontSize: appTheme.pixelPerfectForFont(12)
        }
        const valueStyle: TextStyle = {
            fontFamily: appTheme.primaryBoldFont,
            color: appTheme.darkBlue,
            fontSize: appTheme.pixelPerfectForFont(12),
            textAlign: "right",
            letterSpacing: 1.5
        }
        let registeredUsersView = [];
        const decountByYear = stats.cumulated.date;
        let lastCount = 0;
        for (const decountByYearKey in decountByYear) {
            if (Object.prototype.hasOwnProperty.call(decountByYear, decountByYearKey)) {
                const dataByYear = decountByYear[decountByYearKey];
                let variation = "-";
                if (lastCount > 0) {
                    const diff = dataByYear.total - lastCount;
                    const percent = diff * 100 / lastCount;
                    const sign = percent > 0 ? "+" : "";
                    variation = sign + percent.toFixed(0).toString();
                }
                registeredUsersView.push(
                    <View style={{ flexDirection: "row", flex: 1 }}>
                        <View style={{ flex: 2, paddingStart: appTheme.pixelPerfect(40) }}>
                            <Text style={keyStyle}>{"● " + decountByYearKey + " (" + variation + "%)"}</Text>
                        </View>
                        <View style={{ justifyContent: "flex-end", flex: 1 }}>
                            <Text style={[valueStyle, { fontFamily: appTheme.primaryMediumFont }]}>{formatNumbers(dataByYear.total)}</Text>
                        </View>
                    </View>
                )
                lastCount = dataByYear.total;
            }
        }

        return <View style={{ width: contentWidth }}>
            <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={{ flex: 2 }}>
                    <Text style={keyStyle}>{"Nombre d'utilisateurs inscrits"}</Text>
                </View>
                <View style={{ justifyContent: "flex-end", flex: 1 }}>
                    <Text style={valueStyle}>{formatNumbers(stats.count)}</Text>
                </View>
            </View>
            {registeredUsersView}
            <Divider />
            <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 2 }}>
                    <Text style={keyStyle}>{"Nombre d'utilisateurs effacés"}</Text>
                </View>
                <View style={{ justifyContent: "flex-end", flex: 1 }}>
                    <Text style={valueStyle}>{formatNumbers(stats.deleted.total)}</Text>
                </View>
            </View>
        </View>
    }

    const getDashboardContent = () => {
        if (pageToDisplay === "DOCUMENTATION") {
            return <LHQDocumentation goBack={() => setPageToDisplay("DASHBOARD")} />
        } else if (pageToDisplay === "CODES") {
            return <LHQCreateCode goBack={() => setPageToDisplay("DASHBOARD")} />
        } else if (pageToDisplay === "ACTIVITIES") {
            return <LHQActivitiesManagement goBack={() => setPageToDisplay("DASHBOARD")} />
        }
        return <View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Image source={require("../../assets/img/gunther.png")} style={{ width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50) }} resizeMode="contain" />
                <Text style={h1Style}>
                    Bienvenue au Central Perk. Que peut-on vous servir aujourd'hui ?
                </Text>
            </View>
            <Divider />
            <View style={boxContainerStyle}>
                <View style={boxStyle}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Image source={require("../../assets/img/chandler.jpg")} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />
                        <Text style={h1Style}>
                            Les statistiques
                        </Text>
                    </View>
                    <Divider />
                    {getStatisticsView()}
                </View>
                <View style={boxStyle}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Image source={require("../../assets/img/rachel.jpg")} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />
                        <Text style={h1Style}>
                            La gestion des utilisateurs
                        </Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <Text style={pStyle}>
                            À venir...
                        </Text>
                    </View>
                </View>
            </View>
            <View style={boxContainerStyle}>
                <View style={boxStyle}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Image source={require("../../assets/img/monica.jpg")} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />
                        <Text style={h1Style}>
                            La gestion des catégories
                        </Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <Text style={pStyle}>
                            À venir...
                        </Text>
                    </View>
                </View>
                <TouchableOpacity onPress={() => setPageToDisplay("ACTIVITIES")} style={boxStyle}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Image source={require("../../assets/img/phoebe.jpg")} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />
                        <Text style={h1Style}>
                            La gestion des activités
                        </Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "flex-start" }}>
                        <Text style={pStyle}>
                            Gérer les activités de l'application
                        </Text>
                    </View>
                </TouchableOpacity>
            </View>
            <View style={boxContainerStyle}>
                <TouchableOpacity onPress={() => setPageToDisplay("CODES")} style={boxStyle}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Image source={require("../../assets/img/joey.jpg")} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />
                        <Text style={h1Style}>
                            La gestion des cartes PREMIUM
                        </Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "flex-start" }}>
                        <Text style={pStyle}>
                            Vous pouvez déjà créer des cartes PREMIUM !
                        </Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => setPageToDisplay("DOCUMENTATION")} style={boxStyle}>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                        <Image source={require("../../assets/img/ross.jpg")} style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }} resizeMode="contain" />
                        <Text style={h1Style}>
                            Un peu de documentation
                        </Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                        <Text style={pStyle}>
                            En chantier...
                        </Text>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    }

    return <View style={{ flex: 1, padding: appTheme.pixelPerfect(20) }}>
        {getDashboardContent()}
    </View>

}

export default LHQAdminDashboard;