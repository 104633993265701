export type menuSegmentOrigin = "superpowers" | "section" | "";

class MenuSegment {
    private static instance: MenuSegment;

    public origin: menuSegmentOrigin = "";

    public static getInstance(): MenuSegment {
        if (!MenuSegment.instance) {
            MenuSegment.instance = new MenuSegment();
        }

        return MenuSegment.instance;
    }

}

export default MenuSegment;