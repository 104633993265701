import React, { useEffect, useRef, useState } from "react";

import {
    Text,
    View,
    TouchableOpacity,
    Platform,
    ScrollView,
    ViewStyle,
    TextStyle,
    ImageStyle,
    StyleSheet,
} from "react-native";

import Images from "../../../specific/utils/Images";
import Image from "../../designSystem/OTBImage";

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";

import * as Navigation from "../../../specific/utils/Navigation/Navigation";

import { useNavigate } from "react-router-dom";
import Main from "../../../specific/components/Main/Main";
import MainOptions from "../../models/MainOptions";
import User from "../../data/user/User";
import SVGView from "../../utils/SvgView";

import { default as IcQuoteLeft } from "../../assets/svg/icons/solid/quote-left.svg";
import { default as IcQuoteRight } from "../../assets/svg/icons/solid/quote-right.svg";
import { default as IcInstagram } from "../../../shared/assets/svg/icons/regular/instagram.svg";
import { default as IcLinkedin } from "../../../shared/assets/svg/icons/regular/linkedin.svg";
import Divider from "../../designSystem/Divider/Divider";
import AvatarView from "../Gamification/AvatarView";
import Style from "../../utils/Style";
import { analyticsEventHpWellBeingResourcesClicked, analyticsEventPaywallDisplayed, analyticsEventSectionTrainingPhiloClicked, propertiesEventProfile, propertiesEventSource, trackEvent } from "../../services/Tracking/Analytics";
import { default as IcDismiss } from "../../../shared/assets/svg/icons/solid/times.svg";
import InViewModal from "../../../specific/components/InViewModal/InViewModal";
import { AppTarget, getAppTarget } from "../../../specific/utils/Navigation/Host";

import { openUrl } from "../../utils/WebLink";
import StartingSteps from "./StartingSteps";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import { News } from "../../models/Models";
import { getLocalNews } from "../../../specific/utils/LocalStorage";
import { getLanguage } from "../../utils/Localization/Localization";

export const KEYS = {
    info: "info",
    profile: "profile",
    path: "path",
    still: "still",
    grow: "grow",
    talk: "talk",
    liliAtSchool: "liliAtSchool",
    superPower: "superPower",
    liliAtHome: "liliAtHome",
    trainingPath: "trainingPath",
    sleepingMeditation: "sleepingMeditation",
    coachingManual: "coachingManual",
    wellBeing: "wellBeing",
    philo: "phi_questions"
}

type Inspiration = {
    text: string,
    author: string
}

const inspirations: Inspiration[] = [{
    text: "Rien ne rapproche plus deux personnes que de s'accorder sur la connerie d'un tiers.",
    author: "Fred Vargas"
}, {
    text: "Quand on mettra les cons sur orbite, t'as pas fini de tourner.",
    author: "Michel Audiard"
}, {
    text: "Quand un philosophe me répond, je ne comprends plus ma question.",
    author: "Pierre Desproges"
}, {
    text: "On a toujours tort d'essayer d'avoir raison devant des gens qui ont toutes les bonnes raisons de croire qu'ils n'ont pas tort !",
    author: "Raymond Devos"
},
{
    text: "Pour reconnaître que l'on n'est pas intelligent, il faudrait l'être.",
    author: "Georges Brassens"
}
]

type DesignElement = {
    pictoBgImage: any,
    headerImage: any,
    headerDecoImage: any,
    mainBackgroundColor: string,
    headerBackgroundColor: string,
    headerTextColor: string,
    itemsColor: string,
    itemsTextColor: string,
    socialButtonBackground: string,
    pageTitle: {
        firstPart: string,
        secondPart: string
    },
    wellBeingSectionTitle: string,
    wellBeingSectionBody: string,
    premiumTitle: string,
    premiumBody: string,
    premiumButtonTitle: string,
    premiumIcon: any,
    premiumIllustration: any
}

export type PDFDocument = {
    title: string,
    url: string,
    color: string
}

const SuperRoom = (props: any) => {
    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();
    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [newsToDisplay, setNewsToDisplay] = useState<News[]|undefined>(undefined);

    let navigate = null;
    if (Platform.OS === "web") {
        navigate = useNavigate()
    }

    const inViewModal = useRef<InViewModal>(null);

    const teacherDesignElements: DesignElement = {
        pictoBgImage: images.bgTeacherPictos,
        headerImage: images.illHeaderTeacherRoom,
        headerDecoImage: images.icHeaderTeacherBoard,
        mainBackgroundColor: "#FFEBDE",
        headerBackgroundColor: "#716899",
        headerTextColor: "#FFFFFF",
        itemsColor: appTheme.schoolColor,
        itemsTextColor: appTheme.white,
        socialButtonBackground: appTheme.schoolColor,
        pageTitle: {
            firstPart: "La salle des",
            secondPart: "super-profs"
        },
        wellBeingSectionTitle: "Bien-être des super-profs",
        wellBeingSectionBody: "Respirer, se détendre, réduire son stress, croire en soi… Parce que votre bien-être est aussi essentiel que celui de vos élèves, Lili a conçu un programme spécialement pour vous !",
        premiumTitle: "Profitez de l’intégralité des activités et des modules d’accompagnement",
        premiumBody: "avec Lili à l’école Premium\nà partir de 24€ TTC (pour 3 mois)",
        premiumButtonTitle: "Je souscris à \nLili à l’école Premium",
        premiumIcon: images.icLiliAtSchoolCircle,
        premiumIllustration: images.icParentsPremium
    }

    const familyDesignElements: DesignElement = {
        pictoBgImage: images.bgParentsPictos,
        headerImage: images.illHeaderParentsRoom,
        headerDecoImage: images.icHeaderParentsWindow,
        mainBackgroundColor: "#354F84",
        headerBackgroundColor: "#1C3656",
        headerTextColor: "#FFFFFF",
        itemsColor: appTheme.homeColor,
        itemsTextColor: appTheme.white,
        socialButtonBackground: appTheme.darkBlue,
        pageTitle: {
            firstPart: "Le coin des",
            secondPart: "super-parents"
        },
        wellBeingSectionTitle: "Bien-être des super-parents",
        wellBeingSectionBody: "Respirer, se détendre, réduire son stress, croire en soi… Parce que votre bien-être est aussi essentiel que celui de votre enfant, Lili a conçu un programme spécialement pour vous !",
        premiumTitle: "J’abonne mon super-héros pour l’aider à libérer pleinement ses super-pouvoirs",
        premiumBody: "7 jours gratuits\npuis 4,99€ / mois\nou 35,99€ / an",
        premiumButtonTitle: "J’abonne mon super-héros",
        premiumIcon: images.icLiliAtHomeCircle,
        premiumIllustration: images.illSupercatStanding
    };

    const animatorDesignElements: DesignElement = {
        pictoBgImage: images.bgAnimatorPictos,
        headerImage: images.illHeaderAnimatorsRoom,
        headerDecoImage: images.icHeaderAnimatorsWindow,
        mainBackgroundColor: "#FFEBDE",
        headerBackgroundColor: "#FFFFFF",
        headerTextColor: "#57537F",
        itemsColor: appTheme.schoolColor,
        itemsTextColor: appTheme.white,
        socialButtonBackground: appTheme.animatorColor,
        pageTitle: {
            firstPart: "Le coin des",
            secondPart: "super-animateurs"
        },
        wellBeingSectionTitle: "Bien-être des super-animateurs",
        wellBeingSectionBody: "Respirer, se détendre, réduire son stress, croire en soi… Parce que votre bien-être est aussi essentiel que celui des enfants, Lili a conçu un programme spécialement pour vous !",
        premiumTitle: "",
        premiumBody: "",
        premiumButtonTitle: "",
        premiumIcon: images.icLiliAtHomeCircle,
        premiumIllustration: images.illSupercatStanding
    }

    const designElementsByProfile = {
        "teacher": teacherDesignElements,
        "family": familyDesignElements,
        "animator": animatorDesignElements,
        "default": familyDesignElements
    }

    const designElements: DesignElement = designElementsByProfile[user.profile] !== undefined ? designElementsByProfile[user.profile] : designElementsByProfile["default"];

    // MainOptions
    const { componentId = "", onCloseRequest } = props;

    const blocksWidth = Platform.OS === "web" ? appTheme.pixelPerfect(300) : appTheme.pixelPerfect(240);
    const blocksHeight = Platform.OS === "web" ? appTheme.pixelPerfect(100) : appTheme.pixelPerfect(90);
    const newsHeight = Platform.OS === "web" ? blocksHeight : appTheme.pixelPerfect(70);
    const paddingTopForBlocks = Platform.OS === "web" ? 20 : 20;


    let mainOptions: MainOptions = new MainOptions({
        key: "super_teachers_room",
        componentId: props.componentId,
        navigationColor: user.profile === "animator" ? designElements.mainBackgroundColor : designElements.headerBackgroundColor,
        showNavBar: false,
        showMenu: false,
        canBeHidden: true,
        safeArea: false,
        canGoBack: false,
        canBeDismissed: false
    });
    if ((Platform.OS === "web") && (onCloseRequest !== undefined)) {
        mainOptions.canBeHidden = false;
        mainOptions.rightButtonIcon = IcDismiss;
        mainOptions.rightButtonAction = () => onCloseRequest();
    }

    const onViewDidAppear = () => {
        if ((props.path !== undefined) && (props.path.length > 0)) {
            if (props.path === "sub") {
                showUserProfile(props.path, props.stripe);
            } else if (props.path.includes("cardActivation")) {
                showUserProfile(props.path);
            } else if (props.path === "philo") {
                launchSection(KEYS.philo)
            } else if (props.path === "well_being") {
                launchSection(KEYS.wellBeing)
            } else if (props.path === "activity_types") {
                const activityKey: string = "ADV_05";
                const passProps = {
                    activityKey,
                    categoryKey: "onboarding_advices",
                    sectionKey: "onboardingSteps",
                    context: user.profile
                };
                //trackEvent(analyticsEventActivityClicked, { [propertiesEventName]: analytics_key, [propertiesEventCategory]: category.analytics_key });
                if (Platform.OS === "web") {
                    inViewModal.current?.showAlert({
                        name: Constants.ScreenActivitySheet,
                        passProps
                    });
                } else {
                    Navigation.push({
                        componentId,
                        navigate,
                        passProps,
                        name: Constants.ScreenActivitySheet
                    }) 
                }
            }
        }
        checkNewsToDisplay();
    }

    const checkNewsToDisplay = async () => {
        let localNewsToDisplay: News[] = [];
        try {
            const localNews = await getLocalNews();
            if (localNews.data !== undefined) {
                const localNewsData = localNews.data;
                for (const aNewsKey in localNewsData) {
                    if (Object.prototype.hasOwnProperty.call(localNewsData, aNewsKey)) {
                        const aNews: News = localNewsData[aNewsKey];
                        const now: number = new Date().getTime();
                        if (aNews.status === "published") {
                            if ((aNews.from < now) && (aNews.to > now)) {
                                if (aNews.target.indexOf(user.profile) !== -1) {
                                    localNewsToDisplay.push(aNews);
                                    break;
                                }
                            }
                        }
                    }
                }
                setNewsToDisplay(localNewsToDisplay);
            } else {
                setNewsToDisplay(localNewsToDisplay); 
            }
        } catch (error) {
            setNewsToDisplay(localNewsToDisplay); 
        }
    }

    useEffect(() => {
        if (newsToDisplay !== undefined) {
            setViewDidAppear(true);
        }
    }, [newsToDisplay]);

    const showUserProfile = (path?:string, stripe?:{subId:string, status:string}) => {
        Navigation.showModalInView({
            componentId,
            name: Constants.ScreenUserProfile,
            passProps: {
                isTeacher: true,
                path,
                stripe
            }
        });
    }

    const showAboutPage = () => {
        Navigation.showModalInView({
            componentId,
            name: Constants.ScreenAbout,
            passProps: {
                isTeacher: true
            }
        });
    }

    let headerWidth = Platform.OS === "web" ? appTheme.getFullAppWidth() : appTheme.getFullAppWidth();
    if (headerWidth > 1920) {
        headerWidth = 1920;
    }
    const headerHeight = Platform.OS === "web" ? headerWidth * 350 / 1920 : headerWidth * 193 / 320;

    const getHeader = () => {
        if (Platform.OS === "web") {
            return <View />;
        }
        const boardWidth = appTheme.getFullAppWidth() / 2;
        const boardHeight = boardWidth * 100 / 180;
        return <View style={{ width: headerWidth, alignItems: "center", paddingTop: appTheme.topInsets, position: "absolute" }}>
            <View style={{ width: boardWidth, height: boardHeight }}>
                <Image source={designElements.headerDecoImage} style={{ width: boardWidth, height: boardHeight }} resizeMode="contain" />
                <View style={{ position: "absolute", bottom: 0, width: boardWidth, height: boardHeight, justifyContent: "center", alignItems: "center" }}>
                    <Text style={{ fontFamily: appTheme.primaryBoldFont, color: designElements.headerTextColor, textAlign: "center", fontSize: appTheme.pixelPerfectForFont(10), textTransform: "uppercase" }}>{designElements.pageTitle.firstPart}</Text>
                    <Text style={{ fontFamily: appTheme.superHerosFontBold, color: designElements.headerTextColor, textAlign: "center", fontSize: appTheme.pixelPerfectForFont(12) }}>{designElements.pageTitle.secondPart}</Text>
                </View>
            </View>
        </View>
    }

    const getWebHeader = () => {
        if (Platform.OS !== "web") {
            return <View />;
        }
    }

    const sectionButtonStyle: ViewStyle = {
        marginTop: appTheme.pixelPerfect(10),
        paddingHorizontal: appTheme.pixelPerfect(20),
        paddingVertical: appTheme.pixelPerfect(5),
        backgroundColor: designElements.itemsColor,
        borderRadius: appTheme.pixelPerfect(30)
    };

    const getInspirationsSection = () => {
        return <View />
        const randomNumber = Math.floor(Math.random() * inspirations.length);
        const textColor = user.profile === "teacher" ? appTheme.darkBlue : appTheme.white;
        if (inspirations[randomNumber] !== undefined) {
            const anInspiration: Inspiration = inspirations[randomNumber];
            return <View style={{ alignItems: "center", justifyContent: "flex-start", width: blocksWidth }}>
                <Text style={{ color: designElements.itemsColor, fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(9) }}>L'inspiration de la semaine</Text>
                <View style={{ width: appTheme.pixelPerfect(24), borderRadius: appTheme.pixelPerfect(1), height: 1, backgroundColor: designElements.itemsColor, marginTop: appTheme.pixelPerfect(4) }} />
                <View style={{ paddingVertical: appTheme.pixelPerfect(10) }}>
                    <SVGView Component={IcQuoteLeft} size={18} color={designElements.itemsColor} />
                </View>
                <View style={{ paddingHorizontal: appTheme.pixelPerfect(30), alignItems: "center" }}>
                    <Text style={{ color: textColor, fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(8), textAlign: "center", textTransform: "uppercase" }}>{anInspiration.text}</Text>
                    <Text style={{ textAlign: "center", color: textColor, fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(6), paddingTop: appTheme.pixelPerfect(5) }}>{anInspiration.author}</Text>
                </View>
                <View style={{ paddingVertical: appTheme.pixelPerfect(10) }}>
                    <SVGView Component={IcQuoteRight} size={18} color={designElements.itemsColor} />
                </View>
                <Divider />
            </View>
        }
        return <View />
    }

    const getNewsSection = () => {
        if ((newsToDisplay !== undefined) && (newsToDisplay.length > 0)) {
            const macaroonSize = Platform.OS === "web" ? 60 : 40;
            const newsLink = "https://www.ouest-france.fr/festivals/hellfest/hellfest-2024-cest-bon-ca-demarre-fort-les-foo-fighters-mettent-tout-le-monde-daccord-881e2d78-7274-11ee-8ee4-6d23d2858b34";
            const imageLink = "https://media.ouest-france.fr/v1/pictures/MjAyMzEwNTdlY2UwNjZmMjk0ZmJlM2UyZWNiYzExMjIzZjBlM2M?width=630&height=354&focuspoint=50%2C25&cropresize=1&client_id=bpeditorial&sign=6a2f0faa968faa6dae64f6f252bcb9364852221e887c5058457e2f36dc78aa7a";
            const paddingHorizontal = Platform.OS === "web" ? appTheme.pixelPerfect(10) : appTheme.pixelPerfect(5);
            const imageSize = newsHeight - 2 * paddingHorizontal;
            return <TouchableOpacity onPress={() => openUrl(newsToDisplay[0].url[getLanguage()])} style={{ alignItems: "center", justifyContent: "flex-start", width: blocksWidth + appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(paddingTopForBlocks) }}>
                <View style={{ flexDirection: "row", width: blocksWidth, height: newsHeight, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(10), padding: paddingHorizontal, alignItems: "center" }}>
                    <Image source={{ uri: newsToDisplay[0].img }} style={{ width: imageSize, height: imageSize, borderRadius: appTheme.pixelPerfect(8) }} />
                    <View style={{ flex: 1, paddingStart: appTheme.pixelPerfect(5) }}>
                        <Text style={{ textTransform: "uppercase", fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(7), paddingEnd: appTheme.pixelPerfect(10) }}>
                            {newsToDisplay[0].title[getLanguage()]}
                        </Text>
                        <Text style={{ fontFamily: appTheme.primarySemiBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(6) }}>
                        {newsToDisplay[0].body[getLanguage()]}
                        </Text>
                    </View>
                </View>
                <View style={{ justifyContent: "center", alignItems: "center", position: "absolute", top: appTheme.pixelPerfect(0), right: appTheme.pixelPerfect(4), width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40) }}>
                    <Image source={images.icTeacherMacaroon} style={{ position: "absolute", width: appTheme.pixelPerfect(macaroonSize), height: appTheme.pixelPerfect(macaroonSize) }} />
                    <Text style={{ textAlign: "center", fontFamily: appTheme.primaryBoldFont, color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(3), textTransform: "uppercase", lineHeight: appTheme.pixelPerfect(10), transform: [{ rotate: '20deg' }] }}>{"Actu"}</Text>
                </View>
            </TouchableOpacity>
        }
        return <View />
    }

    const sectionBlockWidth = Platform.OS === "web" ? 2 * blocksWidth : blocksWidth;

    const sectionTitleStyle: TextStyle = {
        fontFamily: appTheme.superHerosFontBold,
        fontSize: Platform.OS === "web" ? appTheme.pixelPerfect(18) : appTheme.pixelPerfect(12),
        color: appTheme.darkBlue
    }

    const sectionBodyStyle: TextStyle = {
        fontFamily: appTheme.primarySemiBoldFont,
        fontSize: Platform.OS === "web" ? appTheme.pixelPerfect(16) : appTheme.pixelPerfect(10),
        color: appTheme.darkBlue,
        paddingHorizontal: appTheme.pixelPerfect(20),
        textAlign: "center"
    }

    const sectionImageStyle: ImageStyle = {
        width: sectionBlockWidth,
        height: Platform.OS === "web" ? sectionBlockWidth * 7 / 16 : sectionBlockWidth * 7 / 16
    }

    const sectionButtonTextStyle: TextStyle = {
        fontFamily: appTheme.primarySemiBoldFont,
        fontSize: Platform.OS === "web" ? appTheme.pixelPerfectForFont(8) : appTheme.pixelPerfectForFont(6),
        color: appTheme.white,
        textTransform: "none",
        paddingHorizontal: appTheme.pixelPerfect(10),
        textAlign: "center"
    }

    const launchSection = (key: string) => {
        if (key === KEYS.wellBeing) {
            trackEvent(analyticsEventHpWellBeingResourcesClicked);
        } else if (key === KEYS.philo) {
            trackEvent(analyticsEventSectionTrainingPhiloClicked)
        }
        Navigation.push({
            componentId,
            navigate,
            name: Constants.ScreenCategoryHome,
            passProps: {
                categoryKey: key,
                section: "room",
                origin: "menu"
            }
        });
    }

    const getRelaxationSection = () => {
        return <View style={{ alignItems: "center", justifyContent: "center", width: appTheme.getFullScreenWidth(), flexDirection: "row" }}>
            <View style={{ alignItems: "center", justifyContent: "flex-start", width: blocksWidth + appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(paddingTopForBlocks) }}>
                <View style={{ width: sectionBlockWidth, backgroundColor: "#A2F2F8", borderRadius: appTheme.pixelPerfect(10), alignItems: "center", overflow: "hidden" }}>
                    <Image source={images.catWellBeing} style={sectionImageStyle} />
                    <Text style={sectionTitleStyle}>{designElements.wellBeingSectionTitle}</Text>
                    <Divider />
                    <Text style={sectionBodyStyle}>{designElements.wellBeingSectionBody}</Text>
                    <Divider />
                    <TouchableOpacity onPress={() => launchSection(KEYS.wellBeing)} style={[sectionButtonStyle, { backgroundColor: appTheme.darkBlue, marginBottom: appTheme.pixelPerfect(20) }]}>
                        <Text style={sectionButtonTextStyle}>Découvrez le programme</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    }

    const getPhiloSection = () => {
        if ((user.profile === "teacher") || (user.profile === "animator")) {
            const headerPhiloImage = user.profile === "teacher" ? images.catPhiloFormationTeacherRoom : images.catPhiloFormationAnimatorRoom;
            return <View style={{ alignItems: "center", justifyContent: "center", width: appTheme.getFullScreenWidth(), flexDirection: "row" }}>
                <View style={{ alignItems: "center", justifyContent: "flex-start", width: blocksWidth + appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(paddingTopForBlocks) }}>
                    <View style={{ width: sectionBlockWidth, backgroundColor: appTheme.grow, borderRadius: appTheme.pixelPerfect(10), alignItems: "center", overflow: "hidden" }}>
                        <Image source={headerPhiloImage} style={sectionImageStyle} />
                        <Text style={sectionTitleStyle}>{"Formation aux ateliers philo"}</Text>
                        <Divider />
                        <Text style={sectionBodyStyle}>Des programmes d’accompagnement d’une qualité unique, conçus par Edwige Chirouter et Jean-Charles Pettier, professeurs universitaires, chercheurs, formateurs et auteurs de référence.</Text>
                        <Divider />
                        <TouchableOpacity onPress={() => launchSection(KEYS.philo)} style={[sectionButtonStyle, { marginBottom: appTheme.pixelPerfect(20) }]}>
                            <Text style={sectionButtonTextStyle}>Découvrez les programmes</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        }
        return <View />
    }

    const showSubscription = () => {
        trackEvent(analyticsEventPaywallDisplayed, { [propertiesEventSource]: "user_profile", [propertiesEventProfile]: user.userProfile() })
        Navigation.showModalInView({
            componentId: componentId,
            name: Constants.ScreenSubscription,
            passProps: {
                isModal: true
            }
        })
    }

    const sectionWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : blocksWidth;
    const getPremiumSection = () => {
        if (user.profile === "animator") {
            return <View />
        }
        const appTarget: AppTarget = getAppTarget();
        if ((user.isPremium() === true) || (appTarget !== "public")) {
            return <View />
        }
        return <View style={{ alignItems: "center", justifyContent: "center", width: appTheme.getFullScreenWidth(), flexDirection: "row" }}>
            <View style={{ alignItems: "center", justifyContent: "flex-start", width: sectionWidth + appTheme.pixelPerfect(20), paddingBottom: appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(10) }}>
                <View style={{ width: sectionWidth, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(10), justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
                    <View style={{ width: sectionWidth, height: appTheme.pixelPerfect(30), backgroundColor: designElements.itemsColor, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ textTransform: "uppercase", fontFamily: appTheme.primaryBoldFont, color: designElements.itemsTextColor, fontSize: appTheme.pixelPerfectForFont(7) }}>
                            Premium
                        </Text>
                    </View>
                    <View style={{ width: sectionWidth, justifyContent: "center", alignItems: "center", padding: appTheme.pixelPerfect(10) }}>
                        <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(7), textAlign: "center" }}>
                            {designElements.premiumTitle}
                        </Text>
                        <Text style={{ fontFamily: appTheme.primarySemiBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(6), paddingTop: appTheme.pixelPerfect(5), textAlign: "center" }}>
                            {designElements.premiumBody}
                        </Text>
                        <TouchableOpacity onPress={showSubscription} style={sectionButtonStyle}>
                            <Text style={{ fontFamily: appTheme.primarySemiBoldFont, color: designElements.itemsTextColor, fontSize: appTheme.pixelPerfectForFont(6), textAlign: "center" }}>{designElements.premiumButtonTitle}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={[Style.shadowed, { justifyContent: "center", alignItems: "center", position: "absolute", top: appTheme.pixelPerfect(0), left: appTheme.pixelPerfect(0), width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(25) }]}>
                    <Image source={designElements.premiumIcon} style={{ position: "absolute", width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50) }} resizeMode="contain" />
                </View>
                <View style={[{ justifyContent: "center", alignItems: "center", position: "absolute", bottom: appTheme.pixelPerfect(0), right: appTheme.pixelPerfect(0), width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(55) }]}>
                    <Image source={designElements.premiumIllustration} style={{ position: "absolute", width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(55) }} resizeMode="contain" />
                </View>
            </View>
        </View>;
    }

    const getTNEOffer = () => {
        if (user.profile !== "teacher") {
            return <View />
        }
        const appTarget: AppTarget = getAppTarget();
        if (appTarget !== "public") {
            return <View />
        }
        return <View style={{ alignItems: "center", justifyContent: "center", width: appTheme.getFullScreenWidth(), flexDirection: "row" }}>
            <View style={{ alignItems: "center", justifyContent: "flex-start", width: sectionWidth + appTheme.pixelPerfect(20), paddingBottom: appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(10) }}>
                <View style={{ width: sectionWidth, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(10), justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
                    <View style={{ width: sectionWidth, height: appTheme.pixelPerfect(30), backgroundColor: appTheme.schoolColor, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ textTransform: "uppercase", fontFamily: appTheme.primaryBoldFont, color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(7) }}>
                            OFFERTE DANS LES TNE
                        </Text>
                    </View>
                    <View style={{ width: sectionWidth, justifyContent: "center", alignItems: "center", padding: appTheme.pixelPerfect(10) }}>
                        <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(7), textAlign: "center" }}>
                            Vous enseignez dans l'un des 12 Territoires Numériques Éducatifs, vous pouvez bénéficier gratuitement de Lili avec Réseau Canopé.
                        </Text>
                        <Text style={{ fontFamily: appTheme.primarySemiBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(6), paddingTop: appTheme.pixelPerfect(5), textAlign: "center" }}>
                            {"Aisne, Bouches-du-Rhône, Cher, Corse-du-Sud, Doubs, Finistère, Guadeloupe, Hérault, Isère, Val-d'Oise, Vienne et Vosges."}
                        </Text>
                        <TouchableOpacity onPress={() => openUrl("https://www.lili.cool/fr/tne-lili/")} style={sectionButtonStyle}>
                            <Text style={{ fontFamily: appTheme.primarySemiBoldFont, color: designElements.itemsTextColor, fontSize: appTheme.pixelPerfectForFont(6), width: appTheme.pixelPerfect(100), textAlign: "center" }}>{"Comment obtenir\nvotre licence gratuite"}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={[Style.shadowed, { justifyContent: "center", alignItems: "center", position: "absolute", top: appTheme.pixelPerfect(0), left: appTheme.pixelPerfect(0), width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(25) }]}>
                    <Image source={images.icLiliAtSchoolCircle} style={{ position: "absolute", width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50) }} resizeMode="contain" />
                </View>
                <View style={[Style.shadowed, { justifyContent: "center", alignItems: "center", position: "absolute", bottom: appTheme.pixelPerfect(0), right: appTheme.pixelPerfect(0), width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(55) }]}>
                    <Image source={images.icTneMap} style={{ position: "absolute", width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(65) }} resizeMode="contain" />
                </View>
            </View>
        </View>;
    }

    const getNefleSection = () => {
        if (user.profile !== "teacher") {
            return <View />
        }
        const appTarget: AppTarget = getAppTarget();
        if (appTarget !== "public") {
            return <View />
        }
        return <View style={{ alignItems: "center", justifyContent: "center", width: appTheme.getFullScreenWidth(), flexDirection: "row" }}>
            <View style={{ alignItems: "center", justifyContent: "flex-start", width: sectionWidth + appTheme.pixelPerfect(20), paddingBottom: appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(10) }}>
                <View style={{ width: sectionWidth, backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(10), justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
                    <View style={{ width: sectionWidth, height: appTheme.pixelPerfect(30), backgroundColor: appTheme.schoolColor, justifyContent: "center", alignItems: "center" }}>
                        <Text style={{ textTransform: "uppercase", fontFamily: appTheme.primaryBoldFont, color: appTheme.white, fontSize: appTheme.pixelPerfectForFont(7) }}>
                            Prise en charge par le FIP
                        </Text>
                    </View>
                    <View style={{ width: sectionWidth, justifyContent: "center", alignItems: "center", padding: appTheme.pixelPerfect(10) }}>
                        <Image source={images.illNefle} style={{ width: appTheme.pixelPerfect(120), height: appTheme.pixelPerfect(70) }} resizeMode="contain" />
                        <Divider />
                        <Text style={{ fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(7), textAlign: "center" }}>
                            {"Lili peut être financée par le FIP dans le cadre de votre projet\n« Notre école faisons-la ensemble »"}
                        </Text>
                        <TouchableOpacity onPress={() => openUrl("https://www.lili.cool/fr/notre-ecole-faisons-la-ensemble-avec-lili/")} style={sectionButtonStyle}>
                            <Text style={{ fontFamily: appTheme.primarySemiBoldFont, color: designElements.itemsTextColor, fontSize: appTheme.pixelPerfectForFont(6), width: appTheme.pixelPerfect(100), textAlign: "center" }}>{"Notre école faisons-la ensemble avec Lili"}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={[Style.shadowed, { justifyContent: "center", alignItems: "center", position: "absolute", top: appTheme.pixelPerfect(0), left: appTheme.pixelPerfect(0), width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(25) }]}>
                    <Image source={images.icLiliAtSchoolCircle} style={{ position: "absolute", width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50) }} resizeMode="contain" />
                </View>
            </View>
        </View>;
    }

    const footerPaddingBottom = appTheme.bottomInsets + appTheme.pixelPerfect(10);
    const getFooter = () => {
        const footerIconContainerSize = Platform.OS === "web" ? appTheme.pixelPerfect(60) : appTheme.pixelPerfect(40);
        const footerIconSize = footerIconContainerSize - appTheme.pixelPerfect(10);
        const footerIconStyle: ViewStyle = {
            width: footerIconContainerSize,
            height: footerIconContainerSize,
            backgroundColor: designElements.mainBackgroundColor,
            borderRadius: footerIconContainerSize / 2,
            justifyContent: "center",
            alignItems: "center"
        }
        const footerPosition = Platform.OS === "web" ? "absolute" : "absolute";
        const facebookForTeacherButton = user.profile === "teacher" ? <View style={{ flex: 1, alignItems: "center" }}>
            <TouchableOpacity onPress={() => openUrl("https://salledesprofs.lili.cool")} style={footerIconStyle}>
                <Image source={images.icFacebook} style={{ width: footerIconContainerSize - appTheme.pixelPerfect(14), height: footerIconContainerSize - appTheme.pixelPerfect(14) }} />
            </TouchableOpacity>
            <View style={{ height: appTheme.pixelPerfect(20), justifyContent: "flex-start" }}>
                <Text style={{ fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(6), textAlign: "center" }} >{"Le groupe"}</Text>
            </View>
        </View> : <View />;
        const waveWidth = appTheme.getFullScreenWidth();
        const waveHeight = Platform.OS === "web" ? waveWidth * 11 / 360 : waveWidth * 38 / 360;
        const waveBottomHeight = Platform.OS === "web" ? appTheme.pixelPerfect(36) : appTheme.pixelPerfect(16);
        const buttonWidth = user.profile === "teacher" ? 5 * footerIconContainerSize : 4 * footerIconContainerSize;
        return <View style={[{ width: appTheme.getFullScreenWidth(), position: footerPosition, bottom: 0, alignItems: "center" }]}>
            <View style={{ width: appTheme.getFullScreenWidth(), alignItems: "center" }}>
                <View style={{ position: "absolute", bottom: 0 }}>
                    <Image source={images.illWhiteWave} style={[Style.bottomBarShadowed, { width: waveWidth, height: waveHeight }]} />
                    <View style={{ width: waveWidth, height: waveBottomHeight, backgroundColor: appTheme.white }} />
                </View>
                <View style={{ width: buttonWidth, flexDirection: "row", justifyContent: "space-evenly", alignItems: "flex-end" }}>
                    {facebookForTeacherButton}
                    <View style={{ flex: 1, alignItems: "center" }}>
                        <TouchableOpacity onPress={showUserProfile} style={footerIconStyle}>
                            <AvatarView width={footerIconContainerSize} height={footerIconContainerSize} />
                        </TouchableOpacity>
                        <View style={{ height: appTheme.pixelPerfect(20), justifyContent: "flex-start" }}>
                            <Text style={{ fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(6), textAlign: "center" }} >Mon profil</Text>
                        </View>
                    </View>
                    <View style={{ flex: 1, alignItems: "center" }}>
                        <TouchableOpacity onPress={showAboutPage} style={footerIconStyle}>
                            <Image source={images.icAbout} style={{ width: footerIconSize, height: footerIconSize }} />
                        </TouchableOpacity>
                        <View style={{ height: appTheme.pixelPerfect(20), justifyContent: "flex-start" }}>
                            <Text style={{ fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(6), textAlign: "center" }} >À propos</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={{ justifyContent: "center", flexDirection: "row", backgroundColor: appTheme.white, paddingBottom: footerPaddingBottom, width: appTheme.getFullScreenWidth() }}>
                <TouchableOpacity onPress={() => openUrl("https://linkedin.lili.cool")} style={{ marginEnd: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(15), backgroundColor: designElements.socialButtonBackground, justifyContent: "center", alignItems: "center" }}>
                    <SVGView Component={IcLinkedin} size={appTheme.pixelPerfect(20)} color={appTheme.white} />
                </TouchableOpacity>
                <TouchableOpacity onPress={() => openUrl("https://www.instagram.com/lili.cool.app/")} style={{ marginStart: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), borderRadius: appTheme.pixelPerfect(15), backgroundColor: designElements.socialButtonBackground, justifyContent: "center", alignItems: "center" }}>
                    <SVGView Component={IcInstagram} size={appTheme.pixelPerfect(20)} color={appTheme.white} />
                </TouchableOpacity>
            </View>
        </View >
    }

    const multiPlatformNewsSection = getNewsSection();
    const bottomNewsSection = Platform.OS === "web" ? <View /> : multiPlatformNewsSection;
    let backgroundColorForHeader = appTheme.darkBlue;
    if (user.profile === "animator") {
        backgroundColorForHeader = appTheme.white;
    }
    const backgroundColorHeight = headerHeight * 13 / 24;
    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ alignItems: "center", justifyContent: "flex-start", backgroundColor: designElements.headerBackgroundColor, flex: 1, width: appTheme.getFullScreenWidth() }}>
                <ActivityIndicator loading={true} color={appTheme.primaryColor} />
            </View>
        }
        return <View style={{ alignItems: "center", justifyContent: "flex-start", backgroundColor: designElements.headerBackgroundColor, flex: 1, width: appTheme.getFullScreenWidth() }}>
            <View style={{ position: "absolute", backgroundColor: designElements.mainBackgroundColor, height: appTheme.getFullScreenHeight(), width: appTheme.getFullAppWidth(), marginTop: appTheme.topInsets }}>
                <Image source={designElements.pictoBgImage} style={StyleSheet.absoluteFill} resizeMode="repeat" />
            </View>
            <ScrollView showsVerticalScrollIndicator={false} style={{ marginTop: appTheme.topInsets }}>
                <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center" }}>
                    <View style={{ position: "absolute", width: appTheme.getFullAppWidth(), height: backgroundColorHeight, backgroundColor: designElements.headerBackgroundColor }} />
                    <Image source={designElements.headerImage} style={{ width: headerWidth, height: headerHeight }} resizeMode="stretch" />
                </View>
                <View style={{ alignItems: "center", justifyContent: "flex-start", width: appTheme.getFullScreenWidth(), paddingBottom: footerPaddingBottom + appTheme.pixelPerfect(100) }}>
                    <StartingSteps
                        newsSection={Platform.OS === "web" ? multiPlatformNewsSection : <View />}
                        designElements={designElements}
                        showAtLaunch={((props.path !== undefined) && (props.path === "advices"))}
                        componentId={componentId}
                        width={blocksWidth}
                        height={blocksHeight} />
                    <Divider size={20} />
                    {getInspirationsSection()}
                    {bottomNewsSection}
                    {getPhiloSection()}
                    {getRelaxationSection()}
                    <Divider />
                    {getPremiumSection()}
                    <Divider />
                    {getTNEOffer()}
                    <Divider />
                    {getNefleSection()}
                </View>
            </ScrollView>
            {getFooter()}
        </View>
    }

    return (
        <Main mainOptions={mainOptions}>
            {getContent()}
            {getWebHeader()}
            {getHeader()}
            <InViewModal ref={inViewModal} />
        </Main>
    )
}

export default SuperRoom;