import React, { useRef, useEffect, useState } from "react";

import {
    Dimensions,
    StyleSheet,
    Pressable,
    Text,
    ActivityIndicator,
    View,
    Platform
} from 'react-native';


import AppTheme from "../utils/Theme";
import Alert from "../components/Notification/Alert";

// SVG
import SVGView from "../utils/SvgView";
import { default as IcPlay } from "../assets/svg/icons/solid/play.svg";
import { default as IcPause } from "../assets/svg/icons/solid/pause.svg";
import { default as IcLiliStar } from "../assets/svg/lili/ic_star.svg";
import Style from "../utils/Style";
import * as Navigation from "../../specific/utils/Navigation/Navigation";
import * as Constants from "../utils/Constants";

// Analytics
import { trackEvent, analyticsEventPaywallDisplayed, propertiesEventSource, propertiesEventProfile } from "../services/Tracking/Analytics";
import User from "../data/user/User";
import InViewModal from "../../specific/components/InViewModal/InViewModal";


const PremiumButton = (props: {
    componentId: string,
    disabled?: boolean,
    onPress?: ((event: any) => void),
    inversed?: boolean,
    isLoading?: boolean,
    icon?: string,
    iconColor?: string,
    backgroundColor?: string,
    size?: number,
    iconSize?: number,
    isDownloadButton?: boolean
}) => {

    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();

    const alertRef = useRef<Alert>(null);

    const {
        componentId,
        disabled,
        onPress,
        inversed = false,
        isLoading,
        iconColor = appTheme.white,
        backgroundColor = appTheme.premium,
        size = appTheme.pixelPerfect(80),
        iconSize = appTheme.pixelPerfect(42),
        isDownloadButton = false
    } = props;

    const inViewModal = useRef<InViewModal>(null);


    const onButtonPressed = () => {
        trackEvent(analyticsEventPaywallDisplayed, {[propertiesEventSource]:"user_profile", [propertiesEventProfile]:user.userProfile()})
        if (isDownloadButton === true) {
            let title = "Compte premium seulement";
            let body = "Le téléchargement des activités est réservé aux comptes Premium.";
            alertRef.current?.showAlert({
                title,
                body,
                onPressPrimaryButton: showSubscription
            });
        } else {
            showSubscription();
        }
    }

    const showSubscription = () => {
        if ((user.demo !== undefined) && (user.demo === true)) {
            let title = "Compte de démonstration";
            let body = "Pour accéder aux ateliers Premium, il vous faut un abonnement. Vous pouvez en faire la demande sur le site tne.reseau-canope.fr";
            alertRef.current?.showAlert({
                title,
                body
            });
        } else {
            if (Platform.OS === "web") {
                inViewModal.current?.showAlert({
                    name:Constants.ScreenSubscription,
                    passProps: {
                        isModal:true
                    }
                });
            } else {
                Navigation.showModalInView({
                    componentId:componentId,
                    name:Constants.ScreenSubscription,
                    passProps: {
                        isModal:true
                    }
                })
            }
           
        }
    }

    const buttonContent = ((isLoading !== undefined) && (isLoading === true)) ? <ActivityIndicator color={iconColor} /> : <SVGView Component={IcLiliStar} size={iconSize} color={iconColor} />;

    const isDisabled = disabled !== undefined ? disabled : false;

    return (
        <View>
            <Pressable
                disabled={isDisabled}
                onPress={onButtonPressed}
                style={({ pressed }) => [
                    Style.shadowed,
                    {
                        opacity: pressed === true ? 0.5 : 1
                    },
                    {
                        backgroundColor,
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }
                ]}>
                {buttonContent}
            </Pressable>
            <Alert ref={alertRef} />
                <InViewModal ref={inViewModal} />
        </View>
    )

}

export default PremiumButton;