//Gamification
const sandy = "#f3d676";
const paleViolet = '#bba9fc';
const greeny_blue = "#42bc99";
const melon = "#ff855f";
const pale_sky_blue = "#bdf3f9";
const levelOne = pale_sky_blue;
const levelTwo = paleViolet;
const levelThree = greeny_blue;
const levelFour = sandy;
const levelFive = melon;
const off_white = "#fff1e1";
const white = "#FFFFFF";
/*
export const avatarsArray = [
    {
        image:require('../assets/images/gamification/avatars/BASE/lili_badge_base_open_level_1.png'),
        level: [
            require('../assets/images/gamification/avatars/BASE/lili_badge_base_open_level_1.png'),
            require('../assets/images/gamification/avatars/BASE/lili_badge_base_open_level_2.png'),
            require('../assets/images/gamification/avatars/BASE/lili_badge_base_open_level_3.png'),
            require('../assets/images/gamification/avatars/BASE/lili_badge_base_open_level_4.png'),
            require('../assets/images/gamification/avatars/BASE/lili_badge_base_open_level_5.png')
        ],
        id: 'base'
    },
    {
        image:require('../assets/images/gamification/avatars/ASIAN/lili_badge_asian.png'),
        level: [
            require('../assets/images/gamification/avatars/ASIAN/lili_badge_asian_level_1.png'),
            require('../assets/images/gamification/avatars/ASIAN/lili_badge_asian_level_2.png'),
            require('../assets/images/gamification/avatars/ASIAN/lili_badge_asian_level_3.png'),
            require('../assets/images/gamification/avatars/ASIAN/lili_badge_asian_level_4.png'),
            require('../assets/images/gamification/avatars/ASIAN/lili_badge_asian_level_5.png')
        ],
        id: 'asian'
    },
    {
        image:require('../assets/images/gamification/avatars/CHEVALIER/lili_badge_chevalier.png'),
        level: [
            require('../assets/images/gamification/avatars/CHEVALIER/lili_badge_chevalier_level_1.png'),
            require('../assets/images/gamification/avatars/CHEVALIER/lili_badge_chevalier_level_2.png'),
            require('../assets/images/gamification/avatars/CHEVALIER/lili_badge_chevalier_level_3.png'),
            require('../assets/images/gamification/avatars/CHEVALIER/lili_badge_chevalier_level_4.png'),
            require('../assets/images/gamification/avatars/CHEVALIER/lili_badge_chevalier_level_5.png')
        ],
        id: 'chevalier'
    },
    {
        image:require('../assets/images/gamification/avatars/COWBOY/lili_badge_cowboy.png'),
        level: [
            require('../assets/images/gamification/avatars/COWBOY/lili_badge_cowboy_level_1.png'),
            require('../assets/images/gamification/avatars/COWBOY/lili_badge_cowboy_level_2.png'),
            require('../assets/images/gamification/avatars/COWBOY/lili_badge_cowboy_level_3.png'),
            require('../assets/images/gamification/avatars/COWBOY/lili_badge_cowboy_level_4.png'),
            require('../assets/images/gamification/avatars/COWBOY/lili_badge_cowboy_level_5.png')
        ],
        id: 'cowboy'
    },
    {
        image:require('../assets/images/gamification/avatars/FLEUR/lili_badge_fleur.png'),
        level: [
            require('../assets/images/gamification/avatars/FLEUR/lili_badge_fleur_level_1.png'),
            require('../assets/images/gamification/avatars/FLEUR/lili_badge_fleur_level_2.png'),
            require('../assets/images/gamification/avatars/FLEUR/lili_badge_fleur_level_3.png'),
            require('../assets/images/gamification/avatars/FLEUR/lili_badge_fleur_level_4.png'),
            require('../assets/images/gamification/avatars/FLEUR/lili_badge_fleur_level_5.png')
        ],
        id: 'fleur'
    },
    {
        image:require('../assets/images/gamification/avatars/GAULOIS/lili_badge_gaulois.png'),
        level: [
            require('../assets/images/gamification/avatars/GAULOIS/lili_badge_gaulois_level_1.png'),
            require('../assets/images/gamification/avatars/GAULOIS/lili_badge_gaulois_level_2.png'),
            require('../assets/images/gamification/avatars/GAULOIS/lili_badge_gaulois_level_3.png'),
            require('../assets/images/gamification/avatars/GAULOIS/lili_badge_gaulois_level_4.png'),
            require('../assets/images/gamification/avatars/GAULOIS/lili_badge_gaulois_level_5.png')
        ],
        id: 'gaulois'
    },
    {
        image:require('../assets/images/gamification/avatars/INDIEN/lili_badge_indien.png'),
        level: [
            require('../assets/images/gamification/avatars/INDIEN/lili_badge_indien_level_1.png'),
            require('../assets/images/gamification/avatars/INDIEN/lili_badge_indien_level_2.png'),
            require('../assets/images/gamification/avatars/INDIEN/lili_badge_indien_level_3.png'),
            require('../assets/images/gamification/avatars/INDIEN/lili_badge_indien_level_4.png'),
            require('../assets/images/gamification/avatars/INDIEN/lili_badge_indien_level_5.png')
        ],
        id: 'indien'
    },
    {
        image:require('../assets/images/gamification/avatars/LICORNE/lili_badge_licorne.png'),
        level: [
            require('../assets/images/gamification/avatars/LICORNE/lili_badge_licorne_level_1.png'),
            require('../assets/images/gamification/avatars/LICORNE/lili_badge_licorne_level_2.png'),
            require('../assets/images/gamification/avatars/LICORNE/lili_badge_licorne_level_3.png'),
            require('../assets/images/gamification/avatars/LICORNE/lili_badge_licorne_level_4.png'),
            require('../assets/images/gamification/avatars/LICORNE/lili_badge_licorne_level_5.png')
        ],
        id: 'licorne'
    },
    {
        image:require('../assets/images/gamification/avatars/PIRATE/lili_badge_pirate.png'),
        level: [
            require('../assets/images/gamification/avatars/PIRATE/lili_badge_pirate_level_1.png'),
            require('../assets/images/gamification/avatars/PIRATE/lili_badge_pirate_level_2.png'),
            require('../assets/images/gamification/avatars/PIRATE/lili_badge_pirate_level_3.png'),
            require('../assets/images/gamification/avatars/PIRATE/lili_badge_pirate_level_4.png'),
            require('../assets/images/gamification/avatars/PIRATE/lili_badge_pirate_level_5.png')
        ],
        id: 'pirate'
    },
    {
        image:require('../assets/images/gamification/avatars/PLONGEUR/lili_badge_plongeur.png'),
        level: [
            require('../assets/images/gamification/avatars/PLONGEUR/lili_badge_plongeur_level_1.png'),
            require('../assets/images/gamification/avatars/PLONGEUR/lili_badge_plongeur_level_2.png'),
            require('../assets/images/gamification/avatars/PLONGEUR/lili_badge_plongeur_level_3.png'),
            require('../assets/images/gamification/avatars/PLONGEUR/lili_badge_plongeur_level_4.png'),
            require('../assets/images/gamification/avatars/PLONGEUR/lili_badge_plongeur_level_5.png')
        ],
        id: 'plongeur'
    },
    {
        image:require('../assets/images/gamification/avatars/PRINCESSE/lili_badge_princesse.png'),
        level: [
            require('../assets/images/gamification/avatars/PRINCESSE/lili_badge_princesse_level_1.png'),
            require('../assets/images/gamification/avatars/PRINCESSE/lili_badge_princesse_level_2.png'),
            require('../assets/images/gamification/avatars/PRINCESSE/lili_badge_princesse_level_3.png'),
            require('../assets/images/gamification/avatars/PRINCESSE/lili_badge_princesse_level_4.png'),
            require('../assets/images/gamification/avatars/PRINCESSE/lili_badge_princesse_level_5.png')
        ],
        id: 'princesse'
    },
    {
        image:require('../assets/images/gamification/avatars/RASTA/lili_badge_rasta.png'),
        level: [
            require('../assets/images/gamification/avatars/RASTA/lili_badge_rasta_level_1.png'),
            require('../assets/images/gamification/avatars/RASTA/lili_badge_rasta_level_2.png'),
            require('../assets/images/gamification/avatars/RASTA/lili_badge_rasta_level_3.png'),
            require('../assets/images/gamification/avatars/RASTA/lili_badge_rasta_level_4.png'),
            require('../assets/images/gamification/avatars/RASTA/lili_badge_rasta_level_5.png')
        ],
        id: 'rasta'
    },
    {
        image:require('../assets/images/gamification/avatars/VAMPIRE/lili_badge_vampire.png'),
        level: [
            require('../assets/images/gamification/avatars/VAMPIRE/lili_badge_vampire_level_1.png'),
            require('../assets/images/gamification/avatars/VAMPIRE/lili_badge_vampire_level_2.png'),
            require('../assets/images/gamification/avatars/VAMPIRE/lili_badge_vampire_level_3.png'),
            require('../assets/images/gamification/avatars/VAMPIRE/lili_badge_vampire_level_4.png'),
            require('../assets/images/gamification/avatars/VAMPIRE/lili_badge_vampire_level_5.png')
        ],
        id: 'vampire'
    },
    {
        image:require('../assets/images/gamification/avatars/TEACHER/lili_badge_teacher.png'),
        level: [
            require('../assets/images/gamification/avatars/TEACHER/lili_badge_teacher_level_1.png'),
            require('../assets/images/gamification/avatars/TEACHER/lili_badge_teacher_level_2.png'),
            require('../assets/images/gamification/avatars/TEACHER/lili_badge_teacher_level_3.png'),
            require('../assets/images/gamification/avatars/TEACHER/lili_badge_teacher_level_4.png'),
            require('../assets/images/gamification/avatars/TEACHER/lili_badge_teacher_level_5.png')
        ],
        id: 'teacher'
    }
];
*/

const imageById = {
    "badge_agile": "badgeAgile",
    "badge_audacieux": "badgeAudacieux",
    "badge_aventurier": "badgeAventurier",
    "badge_collectionneur": "badgeCollectionneur",
    "badge_comedien": "badgeComedien",
    "badge_courageux": "badgeCourageux",
    "badge_curieux": "badgeCurieux",
    "badge_enqueteur": "badgeEnqueteur",
    "badge_explorateur": "badgeExplorateur",
    "badge_expressif": "badgeExpressif",
    "badge_grand_lecteur": "badgeGrandLecteur",
    "badge_grand_sage": "badgeGrandSage",
    "badge_intellectuel": "badgeIntellectuel",
    "badge_joueur": "badgeJoueur",
    "badge_maitre_zen": "badgeMaitreZen",
    "badge_melomane": "badgeMelomane",
    "badge_pose": "badgePose",
    "badge_repose": "badgeRepose",
    "badge_reveur": "badgeReveur",
    "badge_roi_de_la_scene": "badgeRoiDeLaScene",
    "badge_serein": "badgeSerein",
    "badge_star": "badgeStar",
    "badge_tranquille": "badgeTranquille"
}

export function getImageKeyForBadgeId(badgeId, isOwned) {
    const imageName = isOwned === true ? imageById[badgeId] : imageById[badgeId]+"Bw"
    return imageName;
}

export function getColorForPoints(points) {
    const numberOfPoints = parseInt(points);
    if (numberOfPoints < 500) {
        return levelOne;
    } else if (numberOfPoints < 1000) {
        return levelTwo;
    } else if (numberOfPoints < 2000) {
        return levelThree;
    } else if (numberOfPoints < 5000) {
        return levelFour;
    } else {
        return levelFive;
    }
}

export function getColorForLevel(level) {
    const levelAsInt = parseInt(level);
    if (levelAsInt === 1) {
        return levelOne;
    } else if (levelAsInt === 2) {
        return levelTwo;
    } else if (levelAsInt === 3) {
        return levelThree;
    } else if (levelAsInt === 4) {
        return levelFour;
    } else {
        return levelFive;
    }
}

export function getAvatarSpriteForLevel(level) {
    const levelAsInt = parseInt(level);
    if (levelAsInt === 1) {
        return 'BASE_1';
    } else if (levelAsInt === 2) {
        return 'BASE_2';
    } else if (levelAsInt === 3) {
        return 'BASE_3';
    } else if (levelAsInt === 4) {
        return 'BASE_4';
    } else {
        return 'BASE_5';
    }
}

/*
export function getAvatarImageForLevelAndId(level, avatarId, isCodeActive) {
    let avatarIdToCheck = avatarId;
    let avatarImage = require('../assets/images/gamification/avatars/BASE/lili_badge_base_open_level_1.png');
    if ((isCodeActive === true) && (laeActive === true)) {
        avatarIdToCheck = 'teacher';
    }
    for (const anAvatarIndex in avatarsArray) {
        if (Object.hasOwnProperty.call(avatarsArray, anAvatarIndex)) {
            const anAvatar = avatarsArray[anAvatarIndex];
            if (anAvatar.id === avatarIdToCheck) {
                avatarImage = anAvatar.level[level-1];
                break;
            }
        }
    }
    
    return avatarImage;
}
*/