import React from "react";
import { useState, useEffect } from "react";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import Main from "../../../specific/components/Main/Main";
import i18n, { updateContext, contextKeys } from "../../utils/Localization/Localization";


import {
    Platform,
    StyleSheet,
    SafeAreaView,
    View
} from 'react-native';

import Image from "../../designSystem/OTBImage";

import AppTheme, { Theme } from "../../utils/Theme";
import Images from "../../../specific/utils/Images";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// UI
import Button from "../../designSystem/Button";
import TextInput from "../../designSystem/TextInput";

// SVG
import SVGView from "../../utils/SvgView";
import { default as illSuperCat } from "../../assets/svg/illustrations/ill_supercat.svg";
import { default as illTeacherDocs } from "../../assets/svg/illustrations/ill_teacher_docs.svg";
import { default as txtSuperPowers } from "../../assets/svg/illustrations/txt_super_powers.svg";
import { default as txtWellBeing } from "../../assets/svg/illustrations/txt_well_being.svg";

import { useNavigate, useParams } from "react-router-dom";

const UserTypeChoice = (props: any) => {

    let propsData = props;
    if (Platform.OS === "web") {
        propsData = useParams();
    }

    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const [isLoading, setIsLoading] = useState(true);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const columnStyle = {
        flex: 1,
        alignItems: "center",
        justifyContent: "space-evenly",
        padding: appTheme.pixelPerfect(20)
    };

    // MainOptions
    const { componentId = "" } = props;
    const mainOptions: MainOptions = new MainOptions({
        key:"user_type_choice",
        componentId,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        canGoBack: false
    });

    const storeType = async (data: { isTeacher: boolean }) => {
        const { isTeacher } = data;
        const { onDismiss } = propsData;
        // On met à jour le context des langues
        let context = contextKeys.TEACHERS;
        if (isTeacher === true) {
            updateContext(contextKeys.TEACHERS)
        } else {
            updateContext(contextKeys.PARENTS);
            context = contextKeys.PARENTS;
        }
        Navigation.push({
            componentId,
            navigate,
            name: Constants.ScreenOnboarding,
            passProps: {
                context,
                isTeacher
            }
        })
    }

    const iconsSize = Platform.OS === "web" ? (appTheme.getFullAppWidth() / 6) : appTheme.pixelPerfect(100);
    const illustrationWidth = Platform.OS === "web" ? (appTheme.getFullAppWidth() / 2) - appTheme.pixelPerfect(20) : appTheme.pixelPerfect(120);
    const illustrationHeight = Platform.OS === "web" ? appTheme.pixelPerfect(240) : illustrationWidth;
    const textHeight = Platform.OS === "web" ? appTheme.pixelPerfect(100) : appTheme.pixelPerfect(80);
    const buttonWitdh = Platform.OS === "web" ? appTheme.pixelPerfect(240) : appTheme.pixelPerfect(120);

    return (
        <Main mainOptions={mainOptions} showTeachForm={true}>
            <View style={[styles.main, { backgroundColor: appTheme.primaryColor }]}>
                <View style={{ width: appTheme.getFullAppWidth(), flex: 1, flexDirection: "row" }}>
                    <View style={[columnStyle, { backgroundColor: appTheme.homeBackgroundColor, overflow: "hidden" }]}>
                        <View style={{ position: "absolute", top: 0, left: 0 }}>
                            <Image source={images.bgLiliAtHomeChoice} style={{ width: appTheme.getFullAppWidth() / 2, height: appTheme.getFullScreenHeight() }} />
                        </View>
                        <SafeAreaView style={columnStyle}>
                            <Image source={images.icLiliAtHomeCircle} style={{ width: iconsSize, height: iconsSize }} />
                            <SVGView Component={txtSuperPowers} width={illustrationWidth} height={textHeight} />
                            <SVGView Component={illSuperCat} width={illustrationWidth} height={illustrationHeight} />
                            <Button
                                onPress={() => storeType({ isTeacher: false })}
                                title={i18n.t("authentication.userTypeChoice.liliAtHome.button")}
                                style={{ width: buttonWitdh, marginTop: appTheme.pixelPerfect(20), backgroundColor: appTheme.homeColor }} />
                        </SafeAreaView>
                    </View>
                    <View style={[columnStyle, { backgroundColor: appTheme.schoolBackgroundColor, overflow: "hidden" }]}>
                        <View style={{ position: "absolute", top: 0, left: 0 }}>
                            <Image source={images.bgLiliAtSchoolChoice} style={{ width: appTheme.getFullAppWidth() / 2, height: appTheme.getFullScreenHeight() }} />
                        </View>
                        <SafeAreaView style={columnStyle}>
                            <Image source={images.icLiliAtSchoolCircle} style={{ width: iconsSize, height: iconsSize }} />
                            <SVGView Component={txtWellBeing} width={illustrationWidth} height={textHeight} />
                            <SVGView Component={illTeacherDocs} width={illustrationWidth} height={illustrationHeight} />
                            <Button
                                onPress={() => storeType({ isTeacher: true })}
                                title={i18n.t("authentication.userTypeChoice.liliAtSchool.button")}
                                style={{ width: buttonWitdh, marginTop: appTheme.pixelPerfect(20), backgroundColor: appTheme.schoolColor }} />
                        </SafeAreaView>
                    </View>
                </View>
            </View>
        </Main>
    )
}

export default UserTypeChoice;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});