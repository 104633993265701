import React, { useState } from "react";

import {
    View,
    Text,
    Image
} from "react-native";
import AppTheme from "../../utils/Theme";
import Images from "../../../specific/utils/Images";
import GameStyle from "../../styles/game/GameStyle";

interface GameBackgroundProps {

}

const GameBackground = (props: GameBackgroundProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const gameStyle: GameStyle = new GameStyle();

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

    const onViewDidAppear = () => {
        setViewDidAppear(true);
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View onLayout={onViewDidAppear} style={{ width:1, height:1 }} />
        }
        const bgImage = images.bgDayClouds;
        const aBgImageView = <Image source={bgImage} style={{width:"100%", height:"100%"}} />;
        return aBgImageView;
    }

    return <View style={gameStyle.backgroundImageStyle}>
        {getContent()}
    </View>

}

export default GameBackground;