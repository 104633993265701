import { Account, Client, Databases, ID, Query } from 'appwrite';
import { Platform } from 'react-native';
import { build, version } from "../../../package.json";
import { getHost } from "../../specific/utils/Navigation/Host";

const appWriteCRMApiUrl = "https://api.lili.cool/v1";
const appWriteCRMprojectId = "6392ed8493c6811ab73f";
const appWriteCRMDatabaseId = "641039f75a81b7f582e3";
const appWriteCRMMessagesCollectionId = "64103a6e538b6830b582";

let client = new Client();
client.setEndpoint(appWriteCRMApiUrl).setProject(appWriteCRMprojectId);
let account = new Account(client);
let databases = new Databases(client);

export const createMessage = async (messageData: {object:string, name:string, phone_number:string, body:string, email:string, uid:string}) => {
    try {
        const { object, name, phone_number, body, email, uid } = messageData;
        let promise = await databases.createDocument(appWriteCRMDatabaseId, appWriteCRMMessagesCollectionId, ID.unique(), {
            "object":object,
            "body":body,
            "app_build":build,
            "app_version":version,
            "creation_date": new Date(),
            "last_update": new Date(),
            "email":email,
            "name":name,
            "phone_number":phone_number,
            "device_os": Platform.OS,
            "user_id": uid,
            "app": getHost(),
            "device_version": Platform.Version
        });
        return true;
    } catch (error) {
        return null;
    }
}