class MainOptions {
    canBeDismissed: boolean;
    canBeHidden: boolean;
    canGoBack: boolean;
    componentId: string;
    showNavBar: boolean;
    showMenu: boolean;
    safeArea: boolean;
    backgroundColor: string | undefined;
    navigationColor: string | undefined;
    navigationItemColor: string | undefined;
    title: string;
    showVerticalButtons: boolean;
    rightButtonIcon?: any;
    rightButtonAction?: any;
    pageSize?: number;
    contentSize?: number;
    key:string;
    showAccountAccess?:boolean;

    constructor(options: {
        canBeDismissed?: boolean,
        canBeHidden?: boolean,
        canGoBack?: boolean,
        componentId?: string,
        showNavBar?: boolean,
        showMenu?: boolean,
        safeArea?: boolean,
        backgroundColor?: string | undefined,
        navigationColor?: string | undefined,
        navigationItemColor?: string | undefined,
        title?: string,
        showVerticalButtons?: boolean,
        rightButtonIcon?: any,
        rightButtonAction?: any,
        pageSize?: number,
        contentSize?: number,
        key:string,
        showAccountAccess?:boolean
    }) {
        this.canBeDismissed = options.canBeDismissed ?? false;
        this.canBeHidden = options.canBeHidden ?? false;
        this.canGoBack = options.canGoBack ?? true;
        this.componentId = options.componentId ?? "";
        this.showNavBar = options.showNavBar ?? false;
        this.showMenu = options.showMenu ?? false;
        this.safeArea = options.safeArea ?? true;
        this.backgroundColor = options.backgroundColor ?? undefined;
        this.navigationColor = options.navigationColor ?? undefined;
        this.navigationItemColor = options.navigationItemColor ?? undefined;
        this.title = options.title ?? "";
        this.showVerticalButtons = options.showVerticalButtons ?? false;
        this.rightButtonIcon = options.rightButtonIcon,
        this.rightButtonAction = options.rightButtonAction,
        this.pageSize = options.pageSize;
        this.contentSize = options.contentSize;
        this.key = options.key ?? new Date().getTime().toString();
        this.showAccountAccess = options.showAccountAccess ?? false;
        return this;
    }
}

export default MainOptions;