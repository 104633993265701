import React, { useEffect, useRef, useState } from 'react';
import { View, Animated, Easing, Platform, Image as RNImage } from 'react-native';


import AppTheme from "../../utils/Theme";
import Image from "../../designSystem/OTBImage";

// Assets
import Images from "../../../specific/utils/Images";
import ComponentProps from '../../../specific/utils/Interfaces/ComponentProps';
import { UserType } from '../Authentication/Landing/Landing';
import User from '../../data/user/User';

interface DynamicBackgroundProps {
    type?: UserType,
    isMenu?: boolean
}

const DynamicBackground = (props: DynamicBackgroundProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const user: User = User.getInstance();

    const getContent = () => {
        let userType: UserType | undefined = ((user !== undefined) && (user.profile !== undefined)) ? user.profile : undefined;
        if (userType === undefined) {
            if (props.type !== undefined) {
                userType = props.type;
            } else {
                userType = "neutral";
            }
        }

        let wallpaperImage = images.bgOnboarding;
        let backgroundColor = appTheme.neutralBackgroundColor;

        if (userType === "family") {
            wallpaperImage = images.bgNightStars;
            backgroundColor = appTheme.parentBackgroundColor;
        } else if (userType === "animator") {
            wallpaperImage = images.bgOnboardingAnimators;
            backgroundColor = appTheme.animatorBackgroundColor;
        } else if (userType === "teacher") {
            wallpaperImage = images.bgOnboardingTeacher;
            backgroundColor = appTheme.schoolBackgroundColor;
        }
        let contentView = <View />;
        const widthForBackground = appTheme.getFullAppWidth();
        const heightForBackground = appTheme.getFullScreenHeight() * 3 / 4;
        if (userType === "neutral") {
            contentView = <View>
                <Image
                    source={wallpaperImage}
                    style={{
                        width: widthForBackground,
                        height: heightForBackground
                    }}
                    resizeMode="cover" />
                <Image
                    source={images.bgDayGrass}
                    style={{
                        width: appTheme.getFullAppWidth(),
                        height: appTheme.getFullScreenHeight() * 1 / 4,
                        backgroundColor: "#00B590"
                    }}
                    resizeMode="repeat" />
            </View>
        } else if (userType === "family") {
            contentView = <View>
                <Image
                    source={wallpaperImage}
                    style={{
                        position:"absolute",
                        top:0,
                        left:0,
                        width: appTheme.getFullAppWidth(),
                        height: appTheme.getFullScreenHeight()
                    }}
                    resizeMode="cover" />
                <Image
                    source={images.bgOnboardingParent}
                    style={{
                        width: appTheme.getFullAppWidth(),
                        height: appTheme.getFullScreenHeight()
                    }}
                    resizeMode="cover" />
            </View>
        } else {
            contentView = <Image
                source={wallpaperImage}
                style={{
                    width: appTheme.getFullAppWidth(),
                    height: appTheme.getFullScreenHeight()
                }}
                resizeMode="cover" />;
        }


        const position = Platform.OS === "web" ? "fixed" : "absolute";

        return <View style={{
            width: appTheme.getFullAppWidth(),
            height: appTheme.getFullScreenHeight(),
            backgroundColor: backgroundColor,
            position,
            justifyContent: "flex-start",
            alignItems: "flex-start"
        }}>
            {contentView}
        </View>;
    }

    return getContent();
}

export default DynamicBackground;