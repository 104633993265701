import React, { useEffect, useRef, useState } from "react";
import * as Navigation from "../../../../specific/utils/Navigation/Navigation";

import {
    Image,
    Linking,
    Platform,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import AppTheme from "../../../utils/Theme";
import Images from "../../../../specific/utils/Images";
import TextInput from "../../../designSystem/TextInput/RoundCornerTextInput";
import Divider from "../../../designSystem/Divider/Divider";
import RoundButton from "../../../designSystem/Buttons/RoundButton";
import LinkButton from "../../../designSystem/Buttons/LinkButton";
import i18n from "../../../utils/Localization/Localization";
import { validateEmail } from "../../../utils/StringUtils";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "../../../services/Auth";
import { relaunchApp } from "../../../../specific/utils/Navigation/Navigation";
import ComponentProps from "../../../../specific/utils/Interfaces/ComponentProps";
import { NavigateFunction, useNavigate } from "react-router-dom";
import RadioButton from "../../../designSystem/RadioButton";
import LandingStyle from "../../../styles/auth/LandingStyle";
import TeacherSignUpStyle from "../../../styles/components/TeacherSignUpStyle";
import ActivityIndicator from "../../../designSystem/ActivityIndicator/ActivityIndicator";
import { CONTEXT, ScreenCookiesPolicy } from "../../../utils/Constants";
import Alert from "../../Notification/Alert";
import User from "../../../data/user/User";
import { addContactToGeneralAudience } from "../../../services/Mailing";
import { getSchoolId } from "../../../services/Database";
import ActionSheet from "../../../designSystem/ActionSheet/ActionSheet";
import SVGView from "../../../utils/SvgView";
import { default as IcSearch } from "../../../assets/svg/lili/ic_search.svg";
import { setUserProperty } from "../../../services/Tracking/Analytics";

interface TeacherSignUpProps extends ComponentProps {
    onCancelRequest: () => void,
    onSignUpConfirmed: () => void,
    userEmail?: string
}

const emptyErrors = {
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    schoolName: "",
    schoolAddress: "",
    schoolZipCode: "",
    schoolCity: "",
    schoolCountry: ""
};

const TeacherSignUp = (props: TeacherSignUpProps) => {

    const appTheme: AppTheme = new AppTheme();
    const landingStyle: LandingStyle = new LandingStyle();
    const teacherSignUpStyle: TeacherSignUpStyle = new TeacherSignUpStyle();
    const images: Images = new Images();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState(undefined);

    const alertRef = useRef<Alert>(null);
    const actionSheetRef = useRef<ActionSheet>(null);
    const signUpPagerRef = useRef<ScrollView>(null);

    const { componentId = "", userEmail = "", onCancelRequest, onSignUpConfirmed } = props;
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");
    const [newsletter, setNewsletter] = useState<boolean>(false);
    const [schoolName, setSchoolName] = useState<string>("");
    const [schoolAddress, setSchoolAddress] = useState<string>("");
    const [schoolZipCode, setSchoolZipCode] = useState<string>("");
    const [schoolCity, setSchoolCity] = useState<string>("");
    const [schoolCountry, setSchoolCountry] = useState<string>("");
    const [schoolType, setSchoolType] = useState<"public" | "private" | "unknown">("unknown");
    const [schoolId, setSchoolId] = useState<string>("");
    const [schoolSelected, setSchoolSelected] = useState<boolean>(false);
    const [step, setStep] = useState(0);
    const [classes, setClasses] = useState<string[]>([]);

    const scrollViewWidth = appTheme.getBlockWidth();

    let navigate: NavigateFunction | string = "";
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    useEffect(() => {
        signUpPagerRef.current?.scrollTo({ x: scrollViewWidth * step, y: 0, animated: true });
    }, [step]);

    useEffect(() => {
        if (schoolId.length > 0) {
            setStep(2);
        }
    }, [schoolId]);

    const onSecondStepValidation = async () => {
        setIsLoading(true);
        let signUpErrors = emptyErrors;
        if (schoolName.length === 0) {
            signUpErrors.schoolName = "Le nom de votre école ne peut pas être vide"
        } else {
            signUpErrors.schoolName = "";
        }
        if (schoolAddress.length === 0) {
            signUpErrors.schoolAddress = "L'adresse de votre école ne peut pas être vide"
        } else {
            signUpErrors.schoolAddress = "";
        }
        if (schoolZipCode.length === 0) {
            signUpErrors.schoolZipCode = "Le code postal de votre école ne peut pas être vide"
        } else {
            signUpErrors.schoolZipCode = "";
        }
        if (schoolCity.length === 0) {
            signUpErrors.schoolCity = "La ville de votre école ne peut pas être vide"
        } else {
            signUpErrors.schoolCity = "";
        }
        if (schoolCountry.length === 0) {
            signUpErrors.schoolCountry = "Le pays de votre école ne peut pas être vide"
        } else {
            signUpErrors.schoolCountry = "";
        }
        if ((signUpErrors.schoolName.length > 0) || (signUpErrors.schoolAddress.length > 0) || (signUpErrors.schoolZipCode.length > 0) || (signUpErrors.schoolCity.length > 0) || (signUpErrors.schoolCountry.length > 0)) {
            setErrors(signUpErrors);
        } else {
            // On va récupérer l'ID de l'école
            try {
                const userSchoolId = await getSchoolId({ name: schoolName, address: schoolAddress, zipcode: schoolZipCode, city: schoolCity, country: schoolCountry });
                setSchoolId(userSchoolId);
            } catch (error) {
                setSchoolId("unknown");
            }
        }
        setIsLoading(false);
    }

    const isUserTneEnable = () => {
        const TNEDiscrict = {
            "02": "dans l'Aisne",
            "2": "dans l'Aisne",
            "13": "dans les Bouches-du-Rhône",
            "18": "dans le Cher",
            "2A": "en Corse-du-Sud",
            "25": "dans le Doubs",
            "29": "dans le Finistère",
            "971": "en Guadeloupe",
            "34": "dans l'Hérault",
            "38": "dans l'Isère",
            "95": "dans le Val-d'Oise",
            "86": "dans la Vienne",
            "88": "dans les Vosges"
        }

        const districtNumbers = Object.keys(TNEDiscrict);
        // On va récupérer le numéro de département du zip code
        let zipNumber = "";
        const schoolZipCodeNoBlank: string = schoolZipCode.trim();
        if (schoolZipCodeNoBlank.length === 4) {
            zipNumber = schoolZipCodeNoBlank.substring(0, 1);
        } else if (schoolZipCodeNoBlank.length === 5) {
            zipNumber = schoolZipCodeNoBlank.substring(0, 2);
            if (zipNumber === "97") {
                zipNumber = schoolZipCodeNoBlank.substring(0, 3);
            }
        }
        if (districtNumbers.indexOf(zipNumber) !== -1) {
            return true;
        }
        return false;
    }

    const onFirstStepValidation = () => {
        setIsLoading(true);
        let signUpErrors = emptyErrors;
        if (firstname.length === 0) {
            signUpErrors.firstname = "Votre prénom ne peut pas être vide"
        } else {
            signUpErrors.firstname = "";
        }
        if (lastname.length === 0) {
            signUpErrors.lastname = "Votre nom de famille ne peut pas être vide"
        } else {
            signUpErrors.lastname = "";
        }
        if (email.length === 0) {
            signUpErrors.email = "Votre e-mail ne peut pas être vide";
        } else {
            const isEmailValid = validateEmail(email);
            if (isEmailValid === false) {
                signUpErrors.email = "La saisie de votre e-mail est incorrect";
            } else {
                signUpErrors.email = "";
            }
        }
        if (password.length === 0) {
            signUpErrors.password = "Votre mot de passe ne peut être vide";
        } else if (password.length < 6) {
            signUpErrors.password = "Votre mot de passe doit contenir 6 caractères minimum";
        } else {
            signUpErrors.password = "";
        }
        if ((signUpErrors.email.length > 0) || (signUpErrors.password.length > 0) || (signUpErrors.firstname.length > 0) || (signUpErrors.lastname.length > 0)) {
            setIsLoading(false);
            setErrors(signUpErrors);
        } else {
            //Sign In
            setStep(1);
            setIsLoading(false);
        }
    }

    // Condition pour afficher le bouton permettant de basculer vers la connexion
    const getStepIndicatorByStep = () => {
        if (step === 3) {
            return <View />;
        }
        let stepsBackgroundColors = [appTheme.schoolColor, appTheme.white, appTheme.white];
        let stepsColors = [appTheme.white, appTheme.schoolColor, appTheme.schoolColor];
        if ((step === 1) || (step === 2)) {
            stepsBackgroundColors = [appTheme.schoolColor, appTheme.schoolColor, appTheme.white];
            stepsColors = [appTheme.white, appTheme.white, appTheme.schoolColor];
        } else if (step === 3) {
            stepsBackgroundColors = [appTheme.schoolColor, appTheme.schoolColor, appTheme.schoolColor];
            stepsColors = [appTheme.white, appTheme.white, appTheme.white];
        }
        return <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <View style={[teacherSignUpStyle.positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[0], borderColor: appTheme.schoolColor }]}>
                <Text style={[teacherSignUpStyle.positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[0] }]}>1</Text>
            </View>
            <View style={{ width: appTheme.pixelPerfect(20), height: 1, backgroundColor: appTheme.schoolColor }} />
            <View style={[teacherSignUpStyle.positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[1], borderColor: appTheme.schoolColor }]}>
                <Text style={[teacherSignUpStyle.positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[1] }]}>2</Text>
            </View>
            <View style={{ width: appTheme.pixelPerfect(20), height: 1, backgroundColor: appTheme.schoolColor }} />
            <View style={[teacherSignUpStyle.positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[2], borderColor: appTheme.schoolColor }]}>
                <Text style={[teacherSignUpStyle.positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[2] }]}>3</Text>
            </View>
        </View>
    }

    const showSchoolSearch = () => {
        actionSheetRef.current.showAlert({
            actionSheetType: "SCHOOLSEARCH"
        });
    }

    const selectSchool = (item) => {
        setSchoolName(item.fields.nom_etablissement);
        setSchoolAddress(item.fields.adresse_1);
        setSchoolZipCode(item.fields.code_postal);
        setSchoolCity(item.fields.nom_commune);
        setSchoolCountry("France");
        let schoolStatus: "public" | "private" | "unknown" = item.fields.statut_public_prive === "Public" ? "public" : item.fields.statut_public_prive === "Privé" ? "private" : "unknown";
        setSchoolType(schoolStatus);
        setSchoolSelected(true);
        actionSheetRef.current.hideAlert();
    }

    const toggleClasses = (value: string) => {
        let teacherClasses = classes;
        const valueIndex = teacherClasses.indexOf(value);
        if (valueIndex !== -1) {
            teacherClasses.splice(valueIndex, 1);
        } else {
            teacherClasses.push(value);
        }
        setClasses(teacherClasses);
    }

    const openGeneralConditions = () => {
        if (Platform.OS === "web") {
            let uri = "https://www.lili.cool/fr/mentions-legales/";
            Linking.openURL(uri);
        } else {
            Navigation.showModalInView({
                componentId,
                name: ScreenCookiesPolicy,
                passProps: {
                    uri: "https://www.lili.cool/fr/mentions-legales/"
                },
                options: {
                    modalPresentationStyle: 'overCurrentContext',
                    layout: {
                        componentBackgroundColor: 'transparent'
                    }
                }
            });
        }
    }

    const onThirdStepValidation = async () => {
        try {
            setIsLoading(true);
            const emailToRecord = email.toLowerCase().trim();
            const tneEnable = isUserTneEnable();
            const userSession: User | undefined = await createUserWithEmailAndPassword({
                context: CONTEXT.teacher,
                email: emailToRecord,
                password,
                options: {
                    firstname,
                    lastname,
                    optInCoachNewsLetter: false,
                    optInNewsletter: newsletter,
                    school_id: schoolId,
                    classes,
                    tneEnable
                }
            });
            let isDirector: boolean = false;
            if (classes.indexOf("direction") !== -1) {
                setUserProperty({ key: "school_principal", value: "true" });
                isDirector = true;
            } else {
                setUserProperty({ key: "school_principal", value: "false" });
            }
            if (classes.length === 1) {
                if (isDirector === false) {
                    setUserProperty({ key: "school_level", value: classes[0] });
                }
            } else if (classes.length > 1) {
                if (isDirector === false) {
                    setUserProperty({ key: "school_level", value: "several" });
                } else if (classes.length === 2) {
                    let schoolLevel: string = classes[0];
                    if (classes[0] === "direction") {
                        schoolLevel = classes[1];
                    }
                    setUserProperty({ key: "school_level", value: schoolLevel });
                } else {
                    setUserProperty({ key: "school_level", value: "several" });
                }
            }
            setUserProperty({ key: "rare_emails_optin", value: newsletter.toString(), once: true });
            if (schoolId.length > 0) {
                setUserProperty({ key: "school_id", value: schoolId })
            }
            if (schoolName.length > 0) {
                setUserProperty({ key: "school_name", value: schoolName })
            }
            if (schoolAddress.length > 0) {
                setUserProperty({ key: "school_address", value: schoolAddress })
            }

            const schoolZipCodeNoBlank: string = schoolZipCode.trim();
            if (schoolZipCodeNoBlank.length > 0) {
                let zipNumber = "";
                if (schoolZipCodeNoBlank.length === 4) {
                    zipNumber = schoolZipCodeNoBlank.substring(0, 1);
                } else if (schoolZipCodeNoBlank.length === 5) {
                    zipNumber = schoolZipCodeNoBlank.substring(0, 2);
                    if (zipNumber === "97") {
                        zipNumber = schoolZipCodeNoBlank.substring(0, 3);
                    }
                }
                setUserProperty({ key: "school_department", value: zipNumber })
            }
            if (schoolCountry.length > 0) {
                setUserProperty({ key: "school_country", value: schoolCountry })
            }
            if (schoolType.length > 0) {
                setUserProperty({ key: "school_type", value: schoolType })
            }
            setUserProperty({ key: "school_added_manually", value: schoolSelected === true ? "false" : "true" })
            // Si l'utilisateur a accepté de recevoir des e-mails, on l'ajoute à la base
            if (Platform.OS !== "web") {
                addContactToGeneralAudience({
                    email: emailToRecord,
                    userType: CONTEXT.teacher,
                    optIn: newsletter,
                    optInCoach: false,
                    tneEnable
                });
            }
            if ((userSession !== undefined) && (userSession.uid !== undefined)) {
                setIsLoading(false);
                onSignUpConfirmed();
                setStep(3);
            } else {
                setIsLoading(false);
                alertRef.current?.showAlert({
                    title: "Une erreur est survenue",
                    body: "Veuillez réessayer plus tard s'il vous plaît."
                });
            }
        } catch (error) {
            setIsLoading(false);
            let title = "Une erreur est survenue";
            let body = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
            if (error.toString().indexOf("auth/email-already-in-use") !== -1) {
                title = "Utilisateur existant";
                body = "Votre adresse e-mail est déjà reliée à un compte de LILI.\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\" dans la page \"DÉJÀ INSCRIT(E)\"";
            } else if (error.toString().indexOf("auth/invalid-email") !== -1) {
                title = "Adresse e-mail erronée";
                body = "Veuillez vérifier votre adresse e-mail s'il vous plaît.";
            } else if (error.toString().indexOf("auth/weak-password") !== -1) {
                title = "Mot de passe trop faible";
                body = "Veuillez saisir au moins 6 caractères pour votre mot de passe.";
            } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                title = "Erreur de connexion 📡";
                body = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît."
            }
            alertRef.current?.showAlert({
                title,
                body,
                onPressPrimaryButton: () => setStep(0)
            });
        }
    }

    const onFinalStepValidation = () => {
        Navigation.relaunchApp({
            componentId,
            navigate
        });
    }

    const openTneLiliCool = async () => {
        try {
            const tneUrl = "https://tne.lili.cool";
            const canOpenUrl = await Linking.canOpenURL(tneUrl);
            if (canOpenUrl === true) {
                Linking.openURL(tneUrl);
            } else {
                alertRef.current?.showAlert({
                    title: "Une erreur est survenue lors de l'ouverture du lien",
                    body: "Vous pouvez accéder à cette page sur : \n https://tne.lili.cool"
                });
            }
        } catch (error) {
            alertRef.current?.showAlert({
                title: "Une erreur est survenue lors de l'ouverture du lien",
                body: "Vous pouvez accéder à cette page sur : \n https://tne.lili.cool"
            });
        }
        setTimeout(() => {
            onFinalStepValidation();
        }, 500);
    }

    const teacherFourthStep = () => {
        if (step !== 3) {
            return <View />;
        }
        const tneConclusion = isUserTneEnable();
        const firstText = tneConclusion === true ? "Bonne nouvelle !" : "N'oubliez pas de vous inscrire à";
        const secondText = tneConclusion === true ? "LA LICENCE LILI VOUS EST OFFERTE EN TANT QU’ENSEIGNANT.E DES TERRITOIRES NUMÉRIQUES EDUCATIFS" : "notre webinar de formation";
        const thirstTextView = tneConclusion === true ? <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center" }}>
            {"(Aisne, Bouches-du-Rhône, Cher, Corse-du-Sud, Doubs, Finistère, Guadeloupe, Hérault, Isère, Val-d’Oise, Vienne et Vosges)"}
        </Text> : <View />;
        const mainImage = tneConclusion === true ? images.illTeacherSignUpFinalTne : images.illTeacherSignUpFinal;
        const secondImageView = tneConclusion === true ? <View>
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center" }}>
                {"Pour obtenir votre licence gratuite"}
            </Text>
            <Divider />
            <RoundButton textColor={appTheme.white} style={{ backgroundColor: appTheme.schoolColor }} isLoading={isLoading} onPress={openTneLiliCool} title={"Rendez-vous sur TNE.LILI.COOL"} />
        </View> : <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View style={{ flex: 1 }}>
                <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center" }}>
                    {"En vous rendant dans"}
                </Text>
                <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", textTransform: "uppercase" }}>
                    {"La salle des\nSuper-profs"}
                </Text>
            </View>
            <View style={{ width: appTheme.pixelPerfect(120), height: appTheme.pixelPerfect(145) }}>
                <Image source={images.illTeacherSignUpFinalBis} style={{ height: appTheme.pixelPerfect(145), width: appTheme.pixelPerfect(100) }} resizeMode="contain" />
            </View>
        </View>;
        const discoverLiliButton = tneConclusion === true ? <LinkButton title="Découvrir Lili" onPress={onFinalStepValidation} /> : <RoundButton textColor={appTheme.white} style={{ backgroundColor: appTheme.schoolColor }} isLoading={isLoading} onPress={onFinalStepValidation} title={"Découvrir Lili"} />;
        return (<View style={{ marginBottom: appTheme.pixelPerfect(10), width: scrollViewWidth, alignItems: "center" }}>
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont, color: appTheme.schoolColor, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", textTransform: "uppercase" }}>
                {"Bienvenue parmi les\nsuper-profs !"}
            </Text>
            <Divider />
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center" }}>
                {firstText}
            </Text>
            <Divider />
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", textTransform: "uppercase" }}>
                {secondText}
            </Text>
            <Divider />
            {thirstTextView}
            <Divider />
            <View style={{ height: appTheme.pixelPerfect(180) }}>
                <Image source={mainImage} style={{ height: appTheme.pixelPerfect(180), width: appTheme.pixelPerfect(230) }} resizeMode="contain" />
            </View>
            <Divider />
            {secondImageView}
            <Divider size={20} />
            {discoverLiliButton}
        </View>)
    }

    const teacherThirdStep = () => {
        if (step !== 2) {
            return <View />;
        }
        let choicesForm = [];
        let choices = [
            {
                key: "kindergarten",
                value: i18n.t("authentication.signUp.form.radioButtons.classes.kindergarten")
            },
            {
                key: "elementary",
                value: i18n.t("authentication.signUp.form.radioButtons.classes.elementary")
            },
            {
                key: "middleschool",
                value: i18n.t("authentication.signUp.form.radioButtons.classes.middleschool")
            },
            {
                key: "highschool",
                value: i18n.t("authentication.signUp.form.radioButtons.classes.highschool")
            },
            {
                key: "direction",
                value: i18n.t("authentication.signUp.form.radioButtons.classes.direction")
            }
        ];
        for (const aChoiceIndex in choices) {
            if (Object.prototype.hasOwnProperty.call(choices, aChoiceIndex)) {
                const aChoice = choices[aChoiceIndex];
                choicesForm.push(<RadioButton
                    onPress={() => toggleClasses(aChoice.key)}
                    style={{ width: appTheme.pixelPerfect(220), marginBottom: appTheme.pixelPerfect(10) }}>
                    <Text style={{ flex: 1, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue }}>
                        {aChoice.value}
                    </Text>
                </RadioButton>);
            }
        }
        return (<View style={{ paddingTop: appTheme.pixelPerfect(10), width: scrollViewWidth, alignItems: "center" }}>
            <Text style={[teacherSignUpStyle.titleStyle, { textAlign: "center", color: appTheme.schoolColor, fontFamily: appTheme.primaryBoldFont, paddingTop: appTheme.pixelPerfect(10) }]}>
                {i18n.t("authentication.signUp.form.classesFormTitle").toLocaleUpperCase()}
            </Text>
            <Divider />
            {choicesForm}
            <Divider size={20} />
            <RoundButton isLoading={isLoading} onPress={onThirdStepValidation} title={i18n.t("authentication.signUp.buttons.signUp")} style={{ backgroundColor: appTheme.schoolColor }} />
            <Divider />
            <TouchableOpacity onPress={openGeneralConditions} style={{ marginTop: appTheme.pixelPerfect(20) }}>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "center", color: appTheme.textColor }}>
                    {i18n.t("authentication.signUp.buttons.cgu.firstPart")}
                </Text>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "center", textDecorationLine: "underline", color: appTheme.textColor }}>
                    {i18n.t("authentication.signUp.buttons.cgu.secondPart")}
                </Text>
            </TouchableOpacity>
        </View>);
    }

    const teacherSecondStep = () => {
        if (step !== 1) {
            return <View />;
        }
        return (<View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1, width: scrollViewWidth, alignItems: "center" }}>
            <Text style={[teacherSignUpStyle.titleStyle, { textAlign: "center", color: appTheme.schoolColor, fontFamily: appTheme.primaryBoldFont, paddingTop: appTheme.pixelPerfect(10) }]}>
                {i18n.t("authentication.signUp.form.schoolFormTitle").toLocaleUpperCase()}
            </Text>
            <Divider />
            <TouchableOpacity onPress={showSchoolSearch} style={[{ alignItems: "center", justifyContent: "space-between", width: appTheme.getBlockWidth() * 0.9, flexDirection: "row", borderWidth: 1, borderColor: appTheme.schoolColor, paddingVertical: appTheme.pixelPerfect(8), paddingHorizontal: appTheme.pixelPerfect(10), minHeight: appTheme.pixelPerfect(30), backgroundColor: appTheme.white, borderRadius: appTheme.pixelPerfect(30) }]}>
                <View style={{ width: appTheme.pixelPerfect(40) }}>
                    <SVGView Component={IcSearch} size={appTheme.pixelPerfect(24)} color={appTheme.schoolColor} />
                </View>
                <Text style={{ flex: 1, color: appTheme.schoolColor, textTransform: "uppercase", fontFamily: appTheme.primarySemiBoldFont, fontSize: appTheme.pixelPerfectForFont(7) }}>
                    Chercher votre établissement...
                </Text>
            </TouchableOpacity>
            <Text style={[teacherSignUpStyle.titleStyle, { textAlign: "center", color: appTheme.schoolColor, fontFamily: appTheme.primarySemiBoldFont, paddingHorizontal: appTheme.pixelPerfect(10), paddingTop: appTheme.pixelPerfect(10) }]}>
                {"ou\nsaisissez manuellement les coordonnées de votre établissement"}
            </Text>
            <Divider />
            <TextInput
                key="schoolName"
                value={schoolName}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.schoolName !== undefined)) ? errors.schoolName : ""}
                onChangeText={(text) => setSchoolName(text)}
                type="default"
                autoCapitalize="words"
                placeholder={i18n.t("authentication.signUp.form.schoolNamePlaceholder")} />
            <TextInput
                key="schoolAddress"
                value={schoolAddress}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.schoolAddress !== undefined)) ? errors.schoolAddress : ""}
                onChangeText={(text) => setSchoolAddress(text)}
                type="default"
                autoCapitalize="words"
                placeholder={i18n.t("authentication.signUp.form.schoolAddressPlaceholder")} />
            <TextInput
                key="schoolZipCode"
                value={schoolZipCode}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.schoolZipCode !== undefined)) ? errors.schoolZipCode : ""}
                onChangeText={(text) => setSchoolZipCode(text)}
                type="default"
                autoCapitalize="words"
                placeholder={i18n.t("authentication.signUp.form.schoolZipCodePlaceholder")} />
            <TextInput
                key="schoolCity"
                value={schoolCity}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.schoolCity !== undefined)) ? errors.schoolCity : ""}
                onChangeText={(text) => setSchoolCity(text)}
                type="default"
                autoCapitalize="words"
                placeholder={i18n.t("authentication.signUp.form.schoolCityPlaceholder")} />
            <TextInput
                key="schoolCountry"
                value={schoolCountry}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.schoolCountry !== undefined)) ? errors.schoolCountry : ""}
                onChangeText={(text) => setSchoolCountry(text)}
                type="default"
                autoCapitalize="words"
                placeholder={i18n.t("authentication.signUp.form.schoolCountryPlaceholder")} />
            <View style={{ flexDirection: "row", justifyContent: "space-evenly", width: appTheme.getBlockWidth() * 0.7 }}>
                <RadioButton
                    centerButton={true}
                    value={schoolType === "public"}
                    onPress={() => setSchoolType("public")}
                    style={{ flex: 1 }}>
                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(9), color: appTheme.darkBlue }}>
                        {"Public"}
                    </Text>
                </RadioButton>
                <RadioButton
                    centerButton={true}
                    value={schoolType === "private"}
                    onPress={() => setSchoolType("private")}
                    style={{ flex: 1 }}>
                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(9), color: appTheme.darkBlue }}>
                        {"Privé"}
                    </Text>
                </RadioButton>
            </View>

            <Divider size={30} />
            <RoundButton isLoading={isLoading} onPress={onSecondStepValidation} title={i18n.t("authentication.signUp.buttons.steps")} style={{ backgroundColor: appTheme.schoolColor }} />
        </View>);
    }

    const teacherFirstStep = () => {
        if (step !== 0) {
            return <View />;
        }
        return (<View style={{ paddingTop: appTheme.pixelPerfect(10), width: scrollViewWidth, alignItems: "center" }}>
            <TextInput
                key="firstname"
                value={firstname}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.firstname !== undefined)) ? errors.firstname : ""}
                onChangeText={(text) => setFirstname(text)}
                type="default"
                autoCapitalize="words"
                placeholder={i18n.t("authentication.signUp.form.firstnamePlaceholder")} />
            <TextInput
                key="lastname"
                value={lastname}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.lastname !== undefined)) ? errors.lastname : ""}
                onChangeText={(text) => setLastname(text)}
                type="default"
                autoCapitalize="words"
                placeholder={i18n.t("authentication.signUp.form.lastnamePlaceholder")} />
            <TextInput
                key="email"
                value={email}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.email !== undefined)) ? errors.email : ""}
                onChangeText={(text) => setEmail(text)}
                type="email"
                placeholder={i18n.t("authentication.signUp.form.emailPlaceholder")} />
            <TextInput
                key="password"
                value={password}
                onFocus={() => setErrors(undefined)}
                error={((errors !== undefined) && (errors.password !== undefined)) ? errors.password : ""}
                onChangeText={(text) => setPassword(text)}
                type="password"
                placeholder={i18n.t("authentication.signUp.form.passwordPlaceholder")} />
            <Divider />
            <RadioButton
                centerButton={true}
                value={newsletter}
                onPress={() => setNewsletter(!newsletter)}
                style={{ width: appTheme.getBlockWidth() * 0.7 }}>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(9), color: appTheme.darkBlue }}>
                    {i18n.t("authentication.signUp.form.radioButtons.newsletter")}
                </Text>
            </RadioButton>
            <Divider size={30} />
            <RoundButton style={{ backgroundColor: appTheme.schoolColor }} isLoading={isLoading} onPress={onFirstStepValidation} title={i18n.t("authentication.signUp.buttons.steps")} />
            <Divider size={20} />
            <LinkButton onPress={onCancelRequest} title="Annuler" />
        </View>)
    }

    return (<View style={{ justifyContent: "center", alignItems: "center", width: appTheme.getBlockWidth() }}>
        {getStepIndicatorByStep()}
        <Divider />
        <ScrollView scrollEnabled={false} ref={signUpPagerRef} horizontal={true} style={landingStyle.contentContainer}>
            {teacherFirstStep()}
            {teacherSecondStep()}
            {teacherThirdStep()}
            {teacherFourthStep()}
        </ScrollView>
        <Alert ref={alertRef} />
        <ActionSheet ref={actionSheetRef} onChange={selectSchool} />
    </View>)

}

export default TeacherSignUp;