import React, { useRef } from "react";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import {
    ActivityIndicator,
    Image,
    Platform,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    SafeAreaView,
    Dimensions,
    Keyboard,
    ScrollView
} from 'react-native';

import AppTheme from "../../../shared/utils/Theme";

const CardFlip = forwardRef((props: any, ref: any) => {

    const [isFlipped, setIsFlipped] = useState(false);

    const appTheme: AppTheme = new AppTheme();
    const scrollViewRef = useRef<ScrollView>(null);

    // ComponentDidMount
    useEffect(() => {
        if (isFlipped === false) {
            scrollViewRef.current.getScrollableNode().scrollTo({x:0, y:0, animated:true});
        } else {
            scrollViewRef.current.getScrollableNode().scrollTo({x:appTheme.getFullAppWidth(), y:0, animated:true});
        }
    }, [isFlipped]);

    useImperativeHandle(ref, () => ({
        flipTheCard() {
            setIsFlipped(!isFlipped);
        }
    }));

    const onScroll = (e) => {
    }

    return (
        <ScrollView
            onScroll={onScroll}
            scrollEnabled={false}
            ref={scrollViewRef}
            horizontal={true}
            style={{ width: appTheme.getFullAppWidth(), flex:1 }}>
            {props.children}
        </ScrollView>
    )

});

export default CardFlip;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "center"
    },
    navBar: {
        width: Dimensions.get("window").width,
        height: 60
    }
});