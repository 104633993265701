import { ViewStyle, TextStyle } from "react-native";
import AppTheme from "../../utils/Theme";

export default class LandingStyle {

    appTheme: AppTheme = new AppTheme();

    tabsView: ViewStyle = {
        flexDirection:"row",
        width: this.appTheme.getBlockWidth()
    }

    tabButton: ViewStyle = {
        backgroundColor:"transparent",
        width: "50%",
        height: this.appTheme.pixelPerfect(40),
        justifyContent: "center",
        alignItems: "center",
        borderTopEndRadius: this.appTheme.pixelPerfect(15),
        borderTopStartRadius: this.appTheme.pixelPerfect(15)
    }

    selectedTabButton: ViewStyle = {
        ...(this.tabButton as object)
    }

    contentContainer: ViewStyle = {
        width: this.appTheme.getBlockWidth(), 
        backgroundColor: this.appTheme.white, 
        borderBottomRightRadius: this.appTheme.pixelPerfect(15), 
        borderBottomLeftRadius: this.appTheme.pixelPerfect(15)
    }

    tabButtonTitle: TextStyle = {
        fontFamily: this.appTheme.primaryMediumFont,
        color: this.appTheme.darkBlue,
        textTransform: "uppercase",
        fontSize: this.appTheme.pixelPerfectForFont(7)
    }

    constructor() {

    }
}