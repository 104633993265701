/* REACT */
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Dimensions, Share } from 'react-native';

import i18n, { getLanguage } from '../../utils/Localization/Localization';

import AppTheme from '../../utils/Theme';

import SVGView from '../../utils/SvgView';
import { default as IcStar } from "../../assets/svg/lili/ic_star.svg";
import { default as IcPlay } from "../../assets/svg/icons/solid/play.svg";


const ActivityMultiFormatCell = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const { item, color, displayActivities, index, screenWidth = appTheme.getFullAppWidth() } = props;

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setRefresh(!refresh);
    }, [screenWidth]);

    const onPress = () => {
        if (displayActivities === true) {
            if (props.onPress !== undefined) {
                props.onPress(item, color, index);
            }
        } else {
            if (props.showPremiumAlert !== undefined) {
                props.showPremiumAlert();
            }
        }
    }

    let subtitle = item.owner !== undefined ? item.owner : item.type === 'pdf' ? i18n.t('media.toDownload') : '';
    if ((item.body !== undefined) && (item.body[getLanguage()] !== undefined)) {
        subtitle = item.body[getLanguage()];
    }
    let mediaType = item.type === 'pdf' ? 'PDF' : item.type === 'preview' ? 'Aperçu' : item.type === 'video' ? 'Vidéo' : 'Livre audio';
    if (item.type === "multi_format") {
        mediaType = "";
    }
    const duration = item.duration !== undefined ? item.duration : mediaType;
    const iconComponent = displayActivities === false ? IcStar : IcPlay;
    const iconView = <View style={{width:appTheme.pixelPerfect(30), height:appTheme.pixelPerfect(30), borderRadius:appTheme.pixelPerfect(15), backgroundColor:color, justifyContent:"center", alignItems:"center"}}>
        <SVGView Component={iconComponent} size={appTheme.pixelPerfect(14)} color={appTheme.white} />
    </View>;
    return (
        <TouchableOpacity onPress={onPress} style={{ width: screenWidth, paddingHorizontal: appTheme.pixelPerfect(10), paddingVertical: appTheme.pixelPerfect(10), backgroundColor: appTheme.white, flexDirection: 'row' }}>
            <View style={{ flex: 1 }}>
                <Text style={{ fontFamily: appTheme.primaryFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue }}>{item.title[getLanguage()].toLocaleUpperCase()}</Text>
                <View style={{ flexDirection: 'row', marginTop: appTheme.pixelPerfect(5), alignItems: "center" }}>
                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: color }}>{subtitle}</Text>
                </View>
                <View style={{ height: 1, backgroundColor: appTheme.schoolBackgroundColor, marginTop: appTheme.pixelPerfect(20) }} />
            </View>
            {iconView}
        </TouchableOpacity>
    )

}

export default ActivityMultiFormatCell;