import React, { useRef, useState } from "react";

import {
    Text,
    View,
    TextStyle
} from "react-native";
import Images from "../../../../specific/utils/Images";
import Button from "../../../designSystem/Button";
import RadioButton from "../../../designSystem/RadioButton";
import i18n from "../../../utils/Localization/Localization";
import AppTheme from "../../../utils/Theme";
import SignUpStyle from "../../../../specific/style/Authentication/SignUpStyle";
import Alert from "../../Notification/Alert";

interface SignUpFormProps {
    type: "lah" | "las",
    width?: number,
    onSuccess: (levels) => void,
    openGeneralConditions: () => void
}

const SignUpForm = (props: SignUpFormProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const style: SignUpStyle = new SignUpStyle();

    const { type, width = appTheme.getBlockWidth() } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [classes, setClasses] = useState<string[]>([]);

    const alertRef = useRef<Alert>(null);

    const toggleClasses = (value: string) => {
        let teacherClasses = classes;
        const valueIndex = teacherClasses.indexOf(value);
        if (valueIndex !== -1) {
            teacherClasses.splice(valueIndex, 1);
        } else {
            teacherClasses.push(value);
        }
        setClasses(teacherClasses);
    }

    const onLevelSelectionDone = () => {
        props.onSuccess(classes);
    }

    const getForm = () => {
        let choicesForm = [];
        let choices = [
            {
                key: "kindergarten",
                value: i18n.t("authentication.signUp.form.radioButtons.classes.kindergarten")
            },
            {
                key: "elementary",
                value: i18n.t("authentication.signUp.form.radioButtons.classes.elementary")
            },
            {
                key: "middleschool",
                value: i18n.t("authentication.signUp.form.radioButtons.classes.middleschool")
            },
            {
                key: "highschool",
                value: i18n.t("authentication.signUp.form.radioButtons.classes.highschool")
            },
            {
                key: "direction",
                value: i18n.t("authentication.signUp.form.radioButtons.classes.direction")
            }
        ];
        for (const aChoiceIndex in choices) {
            if (Object.prototype.hasOwnProperty.call(choices, aChoiceIndex)) {
                const aChoice = choices[aChoiceIndex];
                choicesForm.push(<RadioButton
                    onPress={() => toggleClasses(aChoice.key)}
                    style={{ width: appTheme.pixelPerfect(220), marginBottom: appTheme.pixelPerfect(10) }}>
                    <Text style={{ flex: 1, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue }}>
                        {aChoice.value}
                    </Text>
                </RadioButton>);
            }
        }
        const title: TextStyle = {
            fontFamily: appTheme.primaryBoldFont,
            textTransform: "uppercase",
            fontSize: appTheme.pixelPerfectForFont(8),
            paddingBottom: appTheme.pixelPerfect(10)
        }
        return (<View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1 }}>
            <Text style={[title, { textAlign: "left", color: appTheme.schoolColor, fontFamily: appTheme.primaryFont, paddingVertical: appTheme.pixelPerfect(10) }]}>
                {i18n.t("authentication.signUp.form.classesFormTitle").toLocaleUpperCase()}
            </Text>
            {choicesForm}
            <Button
                isLoading={isLoading}
                disabled={isLoading}
                onPress={onLevelSelectionDone}
                style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.schoolColor }}
                title={i18n.t("authentication.signUp.buttons.signUp")} />
        </View>);
    }

    return (<View style={style.formContainer}>
        {getForm()}
        <Alert ref={alertRef} />
    </View>)

}

export default SignUpForm;