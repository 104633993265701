import { ImageStyle, StyleProp, TextStyle, ViewStyle } from "react-native";
import AppTheme from "../../../shared/utils/Theme";


export default class SignUpStyle {

    appTheme: AppTheme = new AppTheme();

    formContainer: StyleProp<ViewStyle> = { 
        flex: 1, 
        alignItems: "center", 
        justifyContent: "center", 
        backgroundColor: this.appTheme.white,
        width: this.appTheme.getBlockWidth()
    }

    coachFirstContainer: StyleProp<ViewStyle> = { 
        flexDirection: "row"
    }

    coachFirstText: StyleProp<TextStyle> = { 
        flex: 1, 
        fontFamily: this.appTheme.primaryBoldFont, 
        color: this.appTheme.darkBlue, 
        fontSize: this.appTheme.pixelPerfectForFont(7) 
    }

    radioButtonDefaultText: StyleProp<TextStyle> = { 
        flex: 1, 
        fontFamily: this.appTheme.secondaryFont, 
        color: this.appTheme.textColor, 
        fontSize: this.appTheme.pixelPerfectForFont(7) 
    }

    constructor() {

    }
}