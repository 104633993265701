module.exports = {
    "data": {
        "grow": {
            "analytics_key": "PHILO",
            "body": {
                "fr": "Lili invite chaque enfant au questionnement en partant de situations vécues comme la colère, la moquerie ou le handicap, et permet d’éduquer au vivre-ensemble et à la citoyenneté. Des études ont en effet montré que les ateliers philo augmentent l’empathie et diminuent les conduites agressives des élèves grâce à un meilleur contrôle émotionnel."
            },
            "intro": {
                "pos0": {
                    "fr": "Des podcasts philo pour réfléchir, seul ou en famille, aux situations du quotidien."
                }
            },
            "status": "published",
            "tagText": {
                "pos0": {
                    "fr": "Découvre des clés pour penser, mieux vivre avec les autres,  et finalement... bien grandir !"
                }
            },
            "key": "grow",
            "last_update": 1705479899000,
            "appMenuBody": {
                "fr": "Podcasts philo"
            },
            "teacher": {
                "appMenuBody": {
                    "fr": "Ateliers philo et argumentation"
                }
            },
            "badges": [
                {
                    "messageBadgeInactive": {
                        "fr": "Pour gagner ce badge, tu dois faire au moins 3 activités \"Je Grandis\""
                    },
                    "levelToReach": 1,
                    "subTitle": {
                        "fr": "3 activités"
                    },
                    "messageBadgeActive": {
                        "fr": "Bravo, tu as gagné ce badge parce que tu as fait au moins 3 activités \"Je Grandis\""
                    },
                    "imgUrl": "je_grandis_badge_level_1"
                },
                {
                    "messageBadgeInactive": {
                        "fr": "Pour gagner ce badge, tu dois faire au moins 10 activités \"Je grandis\""
                    },
                    "levelToReach": 2,
                    "subTitle": {
                        "fr": "10 activités"
                    },
                    "messageBadgeActive": {
                        "fr": "Bravo, tu as gagné ce badge parce que tu as fait au moins 10 activités \"Je Grandis\""
                    },
                    "imgUrl": "je_grandis_badge_level_2"
                },
                {
                    "messageBadgeInactive": {
                        "fr": "Pour gagner ce badge, tu dois faire au moins 20 activités \"Je Grandis\""
                    },
                    "levelToReach": 3,
                    "subTitle": {
                        "fr": "20 activités"
                    },
                    "messageBadgeActive": {
                        "fr": "Bravo, tu as gagné ce badge parce que tu as fait au moins 20 activités \"Je Grandis\""
                    },
                    "imgUrl": "je_grandis_badge_level_3"
                }
            ],
            "switchValue": {
                "pos0": {
                    "fr": "Podcasts et lectures"
                }
            },
            "title": {
                "fr": "Je grandis"
            },
            "clr": {
                "main": "#42bc99",
                "action": "#42bc99",
                "txt": "#25364f"
            },
            "ord": 3,
            "switchKey": {
                "pos0": "philo"
            },
            "icon": "lili_grows"
        },
        "talk": {
            "analytics_key": "EXPRESSION",
            "body": {
                "fr": "Improvisation, virelangues ou dessins à dédicacer, Lili promeut la parole et l’expression artistique sous toutes ses formes. Des pratiques plébiscitées par les tenants de l'éducation nouvelle comme moyen d'épanouissement individuel et d'intégration, pour travailler sur la mixité, favoriser la socialisation, stimuler l’imagination et la prise d’initiative."
            },
            "intro": {
                "pos0": {
                    "fr": "Place au jeu, à l'improvisation et au mime, pour prendre confiance à l'oral !"
                }
            },
            "status": "published",
            "tagText": {
                "pos0": {
                    "fr": "Filtre parmi cette liste d’activités en sélectionnant un ou plusieurs thèmes."
                }
            },
            "key": "talk",
            "last_update": 1705479928000,
            "appMenuBody": {
                "fr": "Art et jeux d’impro"
            },
            "teacher": {
                "appMenuBody": {
                    "fr": "Art et langage oral"
                }
            },
            "badges": [
                {
                    "messageBadgeInactive": {
                        "fr": "Pour gagner ce badge, tu dois faire au moins 3 activités \"Je m'exprime\""
                    },
                    "levelToReach": 1,
                    "subTitle": {
                        "fr": "3 activités"
                    },
                    "messageBadgeActive": {
                        "fr": "Bravo, tu as gagné ce badge parce que tu as fait au moins 3 activités \"Je m'exprime\""
                    },
                    "imgUrl": "je_m_exprime_badge_level_1"
                },
                {
                    "messageBadgeInactive": {
                        "fr": "Pour gagner ce badge, tu dois faire au moins 10 activités \"Je m'exprime\""
                    },
                    "levelToReach": 2,
                    "subTitle": {
                        "fr": "10 activités"
                    },
                    "messageBadgeActive": {
                        "fr": "Bravo, tu as gagné ce badge parce que tu as fait au moins 10 activités \"Je m'exprime\""
                    },
                    "imgUrl": "je_m_exprime_badge_level_2"
                },
                {
                    "messageBadgeInactive": {
                        "fr": "Pour gagner ce badge, tu dois faire au moins 20 activités \"Je m'exprime\""
                    },
                    "levelToReach": 3,
                    "subTitle": {
                        "fr": "20 activités"
                    },
                    "messageBadgeActive": {
                        "fr": "Bravo, tu as gagné ce badge parce que tu as fait au moins 20 activités \"Je m'exprime\""
                    },
                    "imgUrl": "je_m_exprime_badge_level_3"
                }
            ],
            "switchValue": {
                "pos0": {
                    "fr": "À l'oral"
                }
            },
            "title": {
                "fr": "Je m'exprime"
            },
            "clr": {
                "main": "#ff855f",
                "action": "#ff855f",
                "txt": "#25364f"
            },
            "ord": 2,
            "switchKey": {
                "pos0": "oral"
            },
            "icon": "lili_talks"
        },
        "liliAtSchool": {
            "body": {
                "fr": "Des ateliers à faire en classe"
            },
            "intro": {
                "pos0": {
                    "fr": "Des supports pédagogiques conçus pour la classe."
                }
            },
            "status": "published",
            "metadata": {
                "body": {
                    "fr": {
                        "lae3": "24 guides de séance et fiches de liaison, une application accessible par ordinateur, et un programme de sensibilisation à la philosophie pour enfants.",
                        "lae2": "Des guides de séances conçus pour la classe et des fiches de liaison pour les parents.",
                        "lae4": "24 guides de séance et fiches de liaison, une application accessible par ordinateur, et un programme de sensibilisation à la philosophie pour enfants."
                    }
                },
                "title": {
                    "fr": {
                        "lae3": "Lili à l'école",
                        "lae2": "Lili à l'école",
                        "lae4": "Lili à l'école"
                    }
                }
            },
            "title": {
                "fr": "Ressources pour l’enseignant"
            },
            "appMenuBody": {
                "fr": "Matériel pour la classe Supports pédagogiques Modules de formation"
            },
            "key": "liliAtSchool",
            "last_update": 1647942626000,
            "clr": {
                "main": "#5B5778",
                "action": "#5B5778",
                "txt": "#ffffff"
            },
            "ord": 4,
            "minBuildNumber": 303,
            "icon": "ic_school"
        },
        "still": {
            "analytics_key": "CALM",
            "body": {
                "fr": "Relaxation, respirations, postures d'équilibre ou bulles sonores… ces activités qui demandent de la concentration aident à trouver le calme et à mieux vivre avec les autres. La psychothérapeute Isabelle Filliozat l'explique : \"la compréhension de ses propres émotions mène à la compréhension des émotions d’autrui, c’est la naissance de l’empathie\"."
            },
            "intro": {
                "pos0": {
                    "fr": "Prends un temps pour toi, trouve le calme, et développe ta concentration !"
                }
            },
            "status": "published",
            "tagText": {
                "pos0": {
                    "fr": "Filtre parmi cette liste d’activités en sélectionnant un ou plusieurs thèmes."
                }
            },
            "key": "still",
            "last_update": 1705479949000,
            "appMenuBody": {
                "fr": "Relaxation et sommeil"
            },
            "animator": {
                "appMenuBody": {
                    "fr": "Temps calmes"
                }
            },
            "teacher": {
                "appMenuBody": {
                    "fr": "Temps calmes et attention"
                }
            },
            "badges": [
                {
                    "messageBadgeInactive": {
                        "fr": "Pour gagner ce badge, tu dois faire au moins 3 activités \"Je me pose\""
                    },
                    "levelToReach": 1,
                    "subTitle": {
                        "fr": "3 activités"
                    },
                    "messageBadgeActive": {
                        "fr": "Bravo, tu as gagné ce badge parce que tu as fait au moins 3 activités \"Je me pose\""
                    },
                    "imgUrl": "je_me_pose_badge_level_1"
                },
                {
                    "messageBadgeInactive": {
                        "fr": "Pour gagner ce badge, tu dois faire au moins 10 activités \"Je me pose\""
                    },
                    "levelToReach": 2,
                    "subTitle": {
                        "fr": "10 activités"
                    },
                    "messageBadgeActive": {
                        "fr": "Bravo, tu as gagné ce badge parce que tu as fait au moins 10 activités \"Je me pose\""
                    },
                    "imgUrl": "je_me_pose_badge_level_2"
                },
                {
                    "messageBadgeInactive": {
                        "fr": "Pour gagner ce badge, tu dois faire au moins 20 activités \"Je me pose\""
                    },
                    "levelToReach": 3,
                    "subTitle": {
                        "fr": "20 activités"
                    },
                    "messageBadgeActive": {
                        "fr": "Bravo, tu as gagné ce badge parce que tu as fait au moins 20 activités \"Je me pose\""
                    },
                    "imgUrl": "je_me_pose_badge_level_3"
                }
            ],
            "switchValue": {
                "pos0": {
                    "fr": "Immobile"
                }
            },
            "title": {
                "fr": "Je me pose"
            },
            "clr": {
                "main": "#bdf3f9",
                "action": "#75DFEA",
                "txt": "#25364f"
            },
            "ord": 1,
            "switchKey": {
                "pos0": "motionless"
            },
            "icon": "lili_sleeps"
        },
        "liliAtHome": {
            "ord": 5,
            "last_update": 1644603613000,
            "key": "liliAtHome",
            "minBuildNumber": 370,
            "clr": {
                "main": "#F3D676",
                "action": "#F3D676",
                "txt": "#25364f"
            },
            "status": "published",
            "title": {
                "fr": "Lili à la maison"
            },
            "metadata": {},
            "intro": {
                "pos0": {
                    "fr": "Retrouvez le Manuel du coach de \nsuper-héros : des conseils, équipements,\net ateliers pour libérer les 7 \nsuper-pouvoirs de votre enfant."
                }
            },
            "body": {
                "fr": "Des ateliers à faire à la maison"
            },
            "icon": "ic_lili_at_home"
        },
        "onboardingSteps": {
            "ord": 6,
            "last_update": 1699552412000,
            "key": "onboardingSteps",
            "minBuildNumber": 537,
            "clr": {
                "main": "#F3D676",
                "action": "#F3D676",
                "txt": "#25364f"
            },
            "status": "published",
            "title": {
                "fr": "Les 7 étapes pour vous lancer"
            },
            "metadata": {},
            "intro": {
                "pos0": {
                    "fr": ""
                }
            },
            "body": {
                "fr": ""
            },
            "icon": "illSevenSteps"
        },
        "inspire": {
            "ord": 3,
            "last_update": 1644603613000,
            "key": "inspire",
            "minBuildNumber": 370,
            "clr": {
                "main": "#FFF085",
                "action": "#FFC94E",
                "txt": "#25364f"
            },
            "status": "published",
            "title": {
                "fr": "Je m'inspire"
            },
            "metadata": {},
            "intro": {
                "pos0": {
                    "fr": ""
                }
            },
            "appMenuBody": {
                "fr": "Histoires audio"
            },
            "teacher": {
                "appMenuBody": {
                    "fr": "Récits et compréhension"
                }
            },
            "body": {
                "fr": "Albert Bandura a mis en évidence l'importance de l'observation et du mimétisme dans le développement des comportements et des compétences. Les enfants renforcent la croyance en leur propre capacité à réussir en s'identifiant à celles et ceux qu'ils considèrent compétents ou dignes d'admiration. Avec ces récits de rôles modèles, ils s’inspirent de femmes et d’hommes ordinaires au parcours extraordinaire, ayant fait preuve d’audace, de persévérance, et de sens du collectif."
            },
            "icon": "ic_inspiration"
        },
        "actForThePlanet": {
            "ord": 4,
            "last_update": 1644603613000,
            "key": "actForThePlanet",
            "minBuildNumber": 370,
            "clr": {
                "main": "#354F84",
                "action": "#1D3361",
                "txt": "#ffffff"
            },
            "status": "published",
            "title": {
                "fr": "J'agis"
            },
            "metadata": {},
            "intro": {
                "pos0": {
                    "fr": "Retrouvez le Manuel du coach de \nsuper-héros : des conseils, équipements,\net ateliers pour libérer les 7 \nsuper-pouvoirs de votre enfant."
                }
            },
            "appMenuBody": {
                "fr": "Défis pour la planète"
            },
            "body": {
                "fr": "Défis pour la planète"
            },
            "icon": "ic_lili_at_home"
        },
        "superPowers": {
            "body": {
                "fr": "Développe tes 7 super-pouvoirs :\nvoici les équipements et des ateliers\npour ton entrainement de super-héros !"
            },
            "intro": {
                "pos0": {
                    "fr": "Prends un temps pour toi, trouve le calme, et développe ta concentration !"
                }
            },
            "status": "published",
            "tagText": {
                "pos0": {
                    "fr": "Filtre parmi cette liste d’activités en sélectionnant un ou plusieurs thèmes."
                }
            },
            "key": "mySuperPowers",
            "last_update": 1647936884000,
            "title": {
                "fr": "Mes super-programmes"
            },
            "appMenuBody": {
                "fr": "Active tes 7 super-pouvoirs"
            },
            "clr": {
                "main": "#364A7B",
                "action": "#1C3656",
                "txt": "#ffffff"
            },
            "ord": 1,
            "minBuildNumber": 370,
            "icon": "lili_masked"
        }
    },
    "ts": 1705480012000
};