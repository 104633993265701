import React from "react";

import {
    Image, ImageErrorEventData, NativeSyntheticEvent, Platform, View
} from "react-native";
import Images, { bundleImage } from "../../../specific/utils/Images";
import AppTheme from "../../utils/Theme";
import Activity, { getActivityAvalability } from "../../models/Activity";
import User from "../../data/user/User";
import Style from "../../utils/Style";
import SVGView from "../../utils/SvgView";

interface CategoryIconProps {
    isActivityAvailable?: boolean,
    localPath?: string,
    remotePath?: string,
    withShadow?: boolean,
    isSquare?: boolean,
    size?: number,
    color?: string
}

const CategoryIcon = (props: CategoryIconProps) => {

    const images: Images = new Images();
    const appTheme: AppTheme = new AppTheme();
    const user: User = User.getInstance();

    const { localPath = "", remotePath, isSquare = false, withShadow = false, size = appTheme.pixelPerfect(60), color } = props;

    const onError = (error: NativeSyntheticEvent<ImageErrorEventData>) => {
    }

    const activityIconSize = size;
    let imageOpacity = 1;
    let imageSource: bundleImage | number | undefined = undefined;
    if ((localPath !== undefined) && (localPath.length > 0)) {
        if (images[localPath] !== undefined) {
            imageSource = images[localPath];
        }
    }
    if (imageSource === undefined) {
        // On n'a pas d'imaage locale, donc on charge l'image distante
        if ((remotePath !== undefined) && (remotePath.length > 0)) {
            imageSource = { uri: remotePath };
        }
    }

    let imageView = <View />
    const borderRadius = isSquare === true ? appTheme.pixelPerfect(10) : activityIconSize / 2;
    if (imageSource !== undefined) {
        // Définir une image par défaut
        imageView = <Image onError={onError} source={imageSource} style={[{ width: activityIconSize, height: activityIconSize, borderRadius, opacity: imageOpacity, borderColor:color, borderWidth:appTheme.pixelPerfect(2) }]} resizeMode="cover" />;
    }

    const containerStyle = withShadow === true ? [Style.shadowed, {borderRadius}] : undefined;

    return (
        <View style={containerStyle}>
            {imageView}
        </View>
    )
}

export default CategoryIcon;