class DeepLink {
    private static instance: DeepLink;

    public path: string = "";
    public last_urls: string[] = [];
    public category_key: string = "";
    public activity_key: string = "";

    public static getInstance(): DeepLink {
        if (!DeepLink.instance) {
            DeepLink.instance = new DeepLink();
        }

        return DeepLink.instance;
    }

}


export default DeepLink;