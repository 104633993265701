// Utils
import { isUserATeacher } from "../utils/LocalStorage";

// Firebase
import * as Auth from "../../specific/services/Specific_Auth";
import { createUserWithData, createTeacherCode, getUserWithId } from "./Database";
import * as Constants from "../utils/Constants";

import * as LocalStorage from "../utils/LocalStorage";
import { trackEvent, analyticsEventParentProfile, analyticsEventTeacherProfile, analyticsEventLogIn, analyticsEventSignInCompleted, initUserIdForAnalytics } from "./Tracking/Analytics";
import User from "../data/user/User";
import { AppTarget, getAppTarget } from "../../specific/utils/Navigation/Host";
import { InstitutionType } from "../components/Authentication/Forms/SelectInstituionType";

let sessionId = "";

type AuthUser = {
    email?: string | null | undefined,
    uid?: string,
    ido?: string
};

export type userOptions = {
    optInNewsletter?: boolean,
    optInCoachNewsLetter?: boolean,
    firstname?: string,
    lastname?: string,
    school_id?: string,
    classes?: string[],
    tneEnable?: boolean,
    institutionType?: InstitutionType,
    institutionName?: string,
    institutionAddress?: string,
    institutionZipCode?: string,
    institutionCity?: string,
    institutionCountry?: string,
    level?: string
}

export const isConnnected = async () => {
    const user = await currentUser();
    if (user === null) {
        return null;
    }
    return { email: user.email, uid: user.uid };
}

export const currentUser = async () => {
    try {
        const currentUser = await Auth.dbCurrentUser();
        if (currentUser === null) {
            return null;
        }
        const appTarget: AppTarget = getAppTarget();
        const uid = ((appTarget === "public") || (appTarget === "lhq")) ? currentUser.uid : currentUser.$id;
        const ido = ((appTarget === "public") || (appTarget === "lhq")) ? undefined : currentUser.prefs.ido;
        const authUser: AuthUser = {
            email: currentUser?.email,
            uid,
            ido
        }
        return authUser;
    } catch (error) {
        return null;
    }

}

export async function createUserWithEmailAndPassword(credential: { context: string, email: string, password: string, options: userOptions }) {
    const { email, password, options = {}, context = Constants.CONTEXT.family } = credential;
    try {
        const authResult = await Auth.dbCreateUserWithEmailAndPassword({ email, password });
        initUserIdForAnalytics(authResult.user.uid);
        await createUserWithData({
            context,
            user_id: authResult.user.uid,
            email,
            options
        });
        trackEvent(analyticsEventSignInCompleted, {profile: context === "family" ? "parent" : context});
        if (authResult.user !== undefined) {
            const user: User = User.getInstance();
            user.setAuthUserData({ email: authResult.user.email, uid: authResult.user.uid });
            const dbUser = await getUserWithId({ user_id: authResult.user.uid });
            user.setDBUserData(dbUser);
            return user;
        } else {
            return undefined;
        }
    } catch (error) {
        throw error;
    }
}

export const signInWithEmailAndPassword = async (credential: { email: string, password: string }) => {
    const { email, password } = credential;
    try {
        const result = await Auth.dbSignInWithEmailAndPassword({ email, password });
        if (result.user !== undefined) {
            const user: User = User.getInstance();
            user.setAuthUserData({ email: result.user.email, uid: result.user.uid });
            initUserIdForAnalytics(result.user.uid);
            trackEvent(analyticsEventLogIn);
            const dbUser = await getUserWithId({ user_id: result.user.uid });
            user.setDBUserData(dbUser);
            if (user.userProfile() === "family") {
                trackEvent(analyticsEventParentProfile);
            } else if (user.userProfile() === "teacher") {
                trackEvent(analyticsEventTeacherProfile);
            }
            return user;
        } else {
            return undefined;
        }
    } catch (error) {
        throw error;
    }
}

// User reset password
export const revokePassword = async (credential: { email: string }) => {
    const { email } = credential;
    try {
        const revokeResult = await Auth.revokePassword({ email });
        return revokeResult;
    } catch (error) {
        throw error;
    }
}


export const signOut = async () => {
    Auth.dbSignOut();
}