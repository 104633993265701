import React, { useEffect, useRef, useState } from "react";
import { ScrollView, Touchable, TouchableOpacity, View } from "react-native";
import Pager from 'borodindk-react-js-pager';
import AppTheme from "../../../shared/utils/Theme";
import SVGView from "../../../shared/utils/SvgView";
import { default as IcLeftArrow } from "../../../shared/assets/svg/icons/solid/angle-left.svg";
import { default as IcRightArrow } from "../../../shared/assets/svg/icons/solid/angle-right.svg";

const ViewPager = (props: {
    showNavigationButton?: boolean,
    mainColor?: string,
    isHorizontal?: boolean,
    pageToShow: number,
    style: any,
    views: View[]
}) => {

    const appTheme: AppTheme = new AppTheme();
    const { views, pageToShow, style, isHorizontal = true, mainColor = appTheme.darkBlue, showNavigationButton = false } = props;
    const [viewDidAppear, setViewDidAppear] = useState(false);
    const [pagerViews, setPagerViews] = useState([]);
    const [pageToDisplay, setPageToDisplay] = useState(0);

    const pagerRef = useRef<Pager>(null);

    useEffect(() => {
        if (props.pageToShow !== pageToDisplay) {
            if (props.pageToShow > 0) {
                setPageToDisplay(props.pageToShow);
            } else {
                setPageToDisplay(0);
            }
        }
    }, [props]);

    useEffect(() => {
        pagerRef.current?.setPage(pageToDisplay);
    }, [pageToDisplay]);

    useEffect(() => {
        if (pagerViews.length > 0) {
            setViewDidAppear(true);
        }
    }, [pagerViews]);

    const onViewDidAppear = () => {
        if (viewDidAppear === false) {
            let newViews = [];
            for (const aViewIndex in views) {
                if (Object.prototype.hasOwnProperty.call(views, aViewIndex)) {
                    const aView = views[aViewIndex];
                    newViews.push(aView.view);
                }
            }
            setPagerViews(newViews);
        }
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View />
        }
        return <Pager
            ref={pagerRef}
            orientation={isHorizontal === true ? "horizontal" : "vertical"}
            animationStyle='scroll'
            initialPage={pageToDisplay}
            duration={600}
            touchGestures={true}
            wrapperStyle={style}>
            {pagerViews}
        </Pager>
    }

    const onGoForward = () => {
        setPageToDisplay(pageToDisplay + 1);
    }

    const onGoBackward = () => {
        setPageToDisplay(pageToDisplay - 1);
    }

    let leftNavigationButton = <View />;
    let rightNavigationButton = <View />;
    if (showNavigationButton === true) {
        leftNavigationButton = pageToDisplay > 0 ? <TouchableOpacity onPress={onGoBackward} style={{ left: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(25), justifyContent: "center", alignItems: "center", backgroundColor: mainColor, position: "absolute" }}>
            <SVGView Component={IcLeftArrow} size={appTheme.pixelPerfect(30)} color={appTheme.white} />
        </TouchableOpacity> : <View />;
        rightNavigationButton = pageToDisplay < (pagerViews.length - 1) ? <TouchableOpacity onPress={onGoForward} style={{ right: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(50), height: appTheme.pixelPerfect(50), borderRadius: appTheme.pixelPerfect(25), justifyContent: "center", alignItems: "center", backgroundColor: mainColor, position: "absolute" }}>
            <SVGView Component={IcRightArrow} size={appTheme.pixelPerfect(30)} color={appTheme.white} />
        </TouchableOpacity> : <View />;
    }


    return <View style={{ justifyContent: "center" }} onLayout={onViewDidAppear}>
        {getContent()}
        {leftNavigationButton}
        {rightNavigationButton}
    </View>
}

export default ViewPager;