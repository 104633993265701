import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import Main from "../../../specific/components/Main/Main";

import {
    View,
    Platform,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    Animated
} from 'react-native';

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// Assets
import Images from "../../../specific/utils/Images";
import Image from "../../designSystem/OTBImage";

// Services

import Button from "../../designSystem/Button";
import i18n from "../../utils/Localization/Localization";
import { setUserType } from "../../utils/LocalStorage";

// Views
import OnboardingParent from "./Onboarding/OnboardingParent";
import OnboardingTeacher from "./Onboarding/OnboardingTeacher";

import { useParams, useNavigate } from "react-router-dom";
import SVGView from "../../utils/SvgView";
import { default as icBack } from "../../assets/svg/icons/solid/angle-left.svg";
import { default as icNext } from "../../assets/svg/icons/solid/angle-right.svg";

const Onboarding = (props: any) => {

    let propsData = props;
    if (Platform.OS === "web") {
        propsData = useParams();
    }

    const [page, setPage] = useState(0);
    const scrollViewAbscissa = useRef(new Animated.Value(0)).current;
    const scrollViewRef = useRef<ScrollView>(null);



    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    // UI
    const { context } = propsData;
    const isTeacher = context === "teachers" ? true : false;
    const buttonBackgroundColor = isTeacher === true ? appTheme.schoolColor : appTheme.homeColor;
    const buttonTextColor = isTeacher === true ? appTheme.white : appTheme.nightSkyColor;
    const viewBackgroundColor = isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor;
    const [backgroundColor, setBackgroundColor] = useState(viewBackgroundColor);

    const { componentId = "" } = props;

    const positionIndicatorCircleStyle = {
        width: appTheme.pixelPerfect(10),
        height: appTheme.pixelPerfect(10),
        borderRadius: appTheme.pixelPerfect(8),
        borderWidth: appTheme.pixelPerfect(1),
        justifyContent: 'center',
        alignItems: 'center'
    };
    const positionIndicatorPointStyle = {
        height: appTheme.pixelPerfect(10),
        borderRadius: appTheme.pixelPerfect(8)
    };
    const onboardingNavigationButtonStyle = {
        marginHorizontal: appTheme.pixelPerfect(10),
        width: appTheme.pixelPerfect(40),
        height: appTheme.pixelPerfect(40),
        borderRadius: appTheme.pixelPerfect(20),
        justifyContent: "center",
        alignItems: "center"
    };

    // MainOptions
    const mainOptions: MainOptions = new MainOptions({
        key:"onboarding",
        componentId,
        showNavBar: false,
        showMenu: false,
        safeArea: false,
        backgroundColor,
        navigationItemColor: appTheme.white,
        navigationColor: backgroundColor,
        canGoBack: true
    });

    let onboardingPagerData = [
        <OnboardingTeacher key="teacherPageOne" page="teacherPageOne" />,
        <OnboardingTeacher key="teacherPageTwo" page="teacherPageTwo" />,
        <OnboardingTeacher key="teacherPageThree" page="teacherPageThree" />,
        <OnboardingTeacher key="teacherPageFour" page="teacherPageFour" />
    ]
    if (isTeacher === false) {
        onboardingPagerData = [
            <OnboardingParent key="parentPageOne" page="parentPageOne" />,
            <OnboardingParent key="parentPageTwo" page="parentPageTwo" />,
            <OnboardingParent key="parentPageThree" page="parentPageThree" />,
            <OnboardingParent key="parentPageFour" page="parentPageFour" />,
        ]
    }

    const getOnboardingViewsForViewPager = () => {
        let onboardingViews = [];
        for (const aDataIndex in onboardingPagerData) {
            if (Object.prototype.hasOwnProperty.call(onboardingPagerData, aDataIndex)) {
                const anOnboardingData = onboardingPagerData[aDataIndex];
                onboardingViews.push({
                    key: aDataIndex,
                    view: <ScrollView
                        key={aDataIndex}
                        style={{ width: appTheme.getFullAppWidth() }}>
                        {anOnboardingData}
                    </ScrollView>
                });
            }
        }
        return onboardingViews;
    }

    const getOnboardingViews = () => {

        let onboardingViews = [];
        for (const aDataIndex in onboardingPagerData) {
            if (Object.prototype.hasOwnProperty.call(onboardingPagerData, aDataIndex)) {
                const anOnboardingData = onboardingPagerData[aDataIndex];
                onboardingViews.push(<ScrollView
                    key={aDataIndex}
                    style={{ width: appTheme.getFullAppWidth(), flex:1 }}>
                    {anOnboardingData}
                </ScrollView>);
            }
        }
        return onboardingViews;
    }

    const getIndicatorPosition = () => {
        const screenWidth = appTheme.getFullAppWidth();
        return scrollViewAbscissa.interpolate({
            inputRange: [0, screenWidth / 2, screenWidth, screenWidth * 3 / 2, 2 * screenWidth, screenWidth * 5 / 2, 3 * screenWidth],
            outputRange: [0, 0, appTheme.pixelPerfect(20), appTheme.pixelPerfect(20), appTheme.pixelPerfect(40), appTheme.pixelPerfect(40), appTheme.pixelPerfect(60)],
            extrapolate: "clamp"
        });
    }

    const getIndicatorSize = () => {
        const screenWidth = appTheme.getFullAppWidth();
        return scrollViewAbscissa.interpolate({
            inputRange: [0, screenWidth / 2, screenWidth, screenWidth * 3 / 2, 2 * screenWidth, screenWidth * 5 / 2, 3 * screenWidth],
            outputRange: [appTheme.pixelPerfect(10), appTheme.pixelPerfect(30), appTheme.pixelPerfect(10), appTheme.pixelPerfect(30), appTheme.pixelPerfect(10), appTheme.pixelPerfect(30), appTheme.pixelPerfect(10)],
            extrapolate: "clamp"
        });
    }

    const getBackgroundColor = () => {
        if (isTeacher === true) {
            return appTheme.schoolBackgroundColor;
        } else {
            const screenWidth = appTheme.getFullAppWidth();
            return scrollViewAbscissa.interpolate({
                inputRange: [0, screenWidth, 2 * screenWidth, 3 * screenWidth],
                outputRange: [appTheme.daySkyColor, appTheme.nightSkyColor, appTheme.nightSkyColor, appTheme.nightSkyColor],
                extrapolate: "clamp"
            });
        }

    }

    const onAuthenticationRequest = async (data: { signUp: boolean }) => {
        const { signUp } = data;
        await setUserType({
            defineAsTeacher: isTeacher
        });
        const authenticationName = isTeacher === true ? Constants.ScreenTeacherAuthentication : Constants.ScreenAuthentication;
        const context = isTeacher === true ? Constants.CONTEXT.teacher : Constants.CONTEXT.family;
        const screen = signUp === true ? "signUp" : "signIn";
        Navigation.push({
            componentId,
            navigate,
            name: authenticationName,
            passProps: {
                context,
                screen
            }
        })
    }

    const navigateThroughOnboarding = (forward: boolean) => {
        let xToScrollTo = (page + 1) * appTheme.getFullAppWidth();
        if (forward === false) {
            xToScrollTo = (page - 1) * appTheme.getFullAppWidth();
        }
        scrollViewRef.current.scrollTo({ x: xToScrollTo, y: 0, animated: true });
    }

    // On va faire en sorte de ne pas coller les boutons du bas au bas de l'écran
    const buttonsMarginBottom = appTheme.bottomInsets === 0 ? appTheme.pixelPerfect(10) : appTheme.bottomInsets;

    const backgroundImage = isTeacher === true ? <Image source={images.bgOnboardingTeacher} style={{ position: "absolute", top: 0, width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight() }} resizeMode="cover" /> : <View />;

    // On va ajouter une marge en haut pour le web, afin d'y mettre les boutons de connexion
    const signInButtonMarginTop = Platform.OS === "web" ? 0 : appTheme.pixelPerfect(10);
    const signUpButton = <Button
        onPress={() => onAuthenticationRequest({ signUp: true })}
        title={i18n.t("onboarding.buttons.signUp")}
        textStyle={{ color: buttonTextColor, fontSize: appTheme.pixelPerfectForFont(10) }}
        style={{ backgroundColor: buttonBackgroundColor, width: appTheme.pixelPerfect(120) }} />;
    const signInButton = <Button
        onPress={() => onAuthenticationRequest({ signUp: false })}
        link={true}
        title={i18n.t("onboarding.buttons.signIn")}
        textStyle={{ color: buttonBackgroundColor, fontSize: appTheme.pixelPerfectForFont(10) }}
        style={{ backgroundColor: buttonBackgroundColor, marginTop: signInButtonMarginTop }} />;
    const authenticationActionTopView = Platform.OS === "web" ? <View style={{ marginBottom: buttonsMarginBottom, flexDirection: "row", position: "absolute", top: appTheme.pixelPerfect(10), right: appTheme.pixelPerfect(20), alignItems: "center", height: appTheme.pixelPerfect(60) }}>
        <View style={{ flex: 1 }}>
            <View style={[styles.authenticationButton, { flexDirection: appTheme.getFlexDirectionForSplitScreen() }]}>
                {signInButton}
                {signUpButton}
            </View>
        </View>
    </View> : <View />;
    const authenticationActionBottomView = Platform.OS === "web" ? <View /> : <View style={{ flex: 1 }}>
        <View style={[styles.authenticationButton, { flexDirection: appTheme.getFlexDirectionForSplitScreen() }]}>
            {signUpButton}
            {signInButton}
        </View>
    </View>;

    // Boutons de navigation dans l'onboarding
    let backArrowForOnboardingNavigation = <View />;
    let nextArrowForOnboardingNavigation = <View />;
    if (Platform.OS === "web") {
        backArrowForOnboardingNavigation = page === 0 ? <View style={{ width: appTheme.pixelPerfect(60) }} /> : <TouchableOpacity onPress={() => navigateThroughOnboarding(false)} style={[onboardingNavigationButtonStyle, { backgroundColor: buttonBackgroundColor }]}>
            <SVGView Component={icBack} size={appTheme.pixelPerfect(30)} color={appTheme.white} />
        </TouchableOpacity>;
        nextArrowForOnboardingNavigation = page === (onboardingPagerData.length - 1) ? <View style={{ width: appTheme.pixelPerfect(60) }} /> : <TouchableOpacity onPress={() => navigateThroughOnboarding(true)} style={[onboardingNavigationButtonStyle, { backgroundColor: buttonBackgroundColor }]}>
            <SVGView Component={icNext} size={appTheme.pixelPerfect(30)} color={appTheme.white} />
        </TouchableOpacity>;
    }
    return (
        <Main mainOptions={mainOptions} >
            {backgroundImage}
            <View style={[styles.main, { width: appTheme.getFullAppWidth() }]}>
                <Animated.ScrollView
                    ref={scrollViewRef}
                    onScroll={Animated.event(
                        [
                            {
                                nativeEvent: {
                                    contentOffset: { x: scrollViewAbscissa }
                                },
                            }
                        ],
                        {
                            useNativeDriver: false,
                            listener: event => {
                                const offsetx = event.nativeEvent.contentOffset.x
                                const newPage = Math.ceil(offsetx / appTheme.getFullAppWidth());
                                if (newPage !== page) {
                                    setPage(newPage);
                                }
                            },
                        }
                    )}
                    pagingEnabled={true}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    style={{ width: appTheme.getFullAppWidth(), marginTop: -appTheme.topInsets }}>
                    {getOnboardingViews()}
                </Animated.ScrollView>
                <View style={{ height: appTheme.pixelPerfect(100), marginBottom: buttonsMarginBottom, alignItems: "flex-end", flexDirection: "row", position: "absolute", bottom: 0, width: appTheme.getFullAppWidth() }}>
                    <View style={{ flex: 1, justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                        {backArrowForOnboardingNavigation}
                        <View style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: appTheme.pixelPerfect(70),
                            marginStart: appTheme.pixelPerfect(10),
                            marginVertical: appTheme.pixelPerfect(5)
                        }}>

                            <View style={[positionIndicatorCircleStyle, { borderColor: buttonBackgroundColor }]} />
                            <View style={[positionIndicatorCircleStyle, { borderColor: buttonBackgroundColor }]} />
                            <View style={[positionIndicatorCircleStyle, { borderColor: buttonBackgroundColor }]} />
                            <View style={[positionIndicatorCircleStyle, { borderColor: buttonBackgroundColor }]} />
                            <Animated.View style={[positionIndicatorPointStyle, { width: getIndicatorSize(), position: "absolute", left: getIndicatorPosition(), backgroundColor: buttonBackgroundColor }]} />
                        </View>
                        {nextArrowForOnboardingNavigation}
                    </View>
                    {authenticationActionBottomView}
                </View>
            </View>
            {authenticationActionTopView}
        </Main>
    )
}

export default Onboarding;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    authenticationButton: {
        alignItems: "center"
    }
});