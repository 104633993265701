import React, { useEffect, useState } from "react";

import {
    Text,
    View,
    Image,
    SafeAreaView,
    FlatList,
    TouchableOpacity,
    Platform,
    ActivityIndicator,
    Linking
} from "react-native";

import Main from "../../../specific/components/Main/Main";
import DynamicBackground from "../DynamicBackground/DynamicBackground";
import MainOptions from "../../models/MainOptions";
import Images from "../../../specific/utils/Images";

import AppTheme from "../../utils/Theme";
import Style from "../../utils/Style";
import Label from "../../utils/Localization/Label";

import i18n from "../../utils/Localization/Localization";

import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import * as Constants from "../../utils/Constants";
import * as DateUtils from "../../utils/DateUtils";

import SVGView from "../../utils/SvgView";
import { default as IcChevronRight } from "../../assets/svg/icons/solid/chevron-right.svg";
import OtbImage from "../../../specific/utils/OtbImage/OtbImage";
import Button from "../../designSystem/Button";
import { getLocalStorage, setLocalStorage } from "../../../specific/utils/LocalStorage";
import { EventRegister } from "../../utils/EventListeners";
import { consentActivation } from "../../services/Tracking/Analytics";
import { useNavigate } from "react-router-dom";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";

const ConcentNotification = (props: any) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const label: Label = new Label();

    const [isTeacher, setIsTeacher] = useState(props.isTeacher);
    const [consentDataChecked, setConsentDataChecked] = useState(false);
    const [consentData, setConsentData] = useState(null);

    // MainOptions
    const { componentId = "", context = "launch" } = props;
    const mainOptions: MainOptions = new MainOptions({
        key:"concent_notification",
        componentId,
        showNavBar: false,
        showMenu: false,
        canBeHidden: true,
        safeArea: false,
        canGoBack: false,
        backgroundColor: isTeacher === true ? appTheme.schoolBackgroundColor : appTheme.homeBackgroundColor,
        canBeDismissed: false
    });

    useEffect(() => {
        checkUserData();
    }, []);

    useEffect(() => {
        if (consentData !== null) {
            setConsentDataChecked(true);
        }
    }, [consentData]);

    const checkUserData = async () => {
        const userConsent = await getLocalStorage({ key: Constants.keyGdprConsent });
        if (userConsent !== null) {
            const userConsentData = JSON.parse(userConsent);
            setConsentData(userConsentData);
        } else {
            setConsentData({ ts: -1 });
        }
    }

    const showCookiesPolicy = () => {
        const appTarget = getAppTarget();
        if (appTarget === "gar-prod") {
            Linking.openURL("https://lili.cool/tne/Lili_politique_de_confidentialite_GAR.pdf");
        } else {
            Linking.openURL("https://lili.cool/cookies_policy.html");
        }
    }

    const recordConsent = async (consent: boolean) => {
        consentActivation(consent);
        await setLocalStorage({
            key: Constants.keyGdprConsent, value: JSON.stringify({
                ts: new Date().getTime(),
                consent
            })
        });
        setTimeout(() => {
            if (context === "launch") {
                EventRegister.emitEvent(Constants.EventGRPDConsentDone, {});
            }
            closeView();
        }, 500);
    }

    const closeView = () => {
        Navigation.dismissModalInView({
            componentId
        });
    }

    const notificationWidth = appTheme.getBlockWidth();

    const getContent = () => {
        if (consentDataChecked === false) {
            return <View style={{ width: notificationWidth, height: appTheme.pixelPerfect(100), justifyContent: "center", alignItems: "center" }}>
                <ActivityIndicator color={appTheme.talk} />
                <View style={{ width: notificationWidth, padding: appTheme.pixelPerfect(10), alignItems: "center" }}>
                    <Button onPress={closeView} title="Annuler" textStyle={{ color: appTheme.white }} style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.talk }} />
                </View>
            </View>;
        }
        let consentView = [];
        consentView.push(<View style={{ flex: 1, paddingTop: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.pixelPerfect(10), paddingBottom: appTheme.pixelPerfect(10), alignItems: "center" }}>
            <Text style={{ textAlign: "center", fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(16), paddingBottom: appTheme.pixelPerfect(5), color: appTheme.titleColor }}>Lili respecte votre vie privée</Text>
            <Text style={{ textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.textColor }}>Avec votre accord, nous utilisons ainsi que nos partenaires différentes technologies telles que les cookies pour améliorer l’expérience et les performances de l’application, et mesurer l’engagement des contenus et des audiences. En cliquant sur "Autoriser", vous acceptez l'utilisation de ces services détaillés dans notre</Text>
            <TouchableOpacity onPress={showCookiesPolicy}>
                <Text style={{ textAlign: "center", fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(16), color: appTheme.talk, textDecorationLine: "underline" }}>Politique de cookies</Text>
            </TouchableOpacity>
        </View>);
        if (consentData.ts === -1) {
            consentView.push(<View style={{ width: notificationWidth, padding: appTheme.pixelPerfect(10), alignItems: "center" }}>
                <Button onPress={() => recordConsent(false)} title="Refuser" textStyle={{ color: appTheme.talk }} style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.white, borderWidth: 1, borderColor: appTheme.talk, marginBottom: appTheme.pixelPerfect(10) }} />
                <Button onPress={() => recordConsent(true)} title="Autoriser" textStyle={{ color: appTheme.white }} style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.talk }} />
            </View>);
        } else {
            const userConsent = consentData.consent === true ? "Vous avez accepté l'utilisation de nos services le " : "Vous avez refusé l'utilisation de nos services le ";
            consentView.push(<View style={{ width: notificationWidth, padding: appTheme.pixelPerfect(10), alignItems: "center" }}>
                <Text style={{ color: appTheme.talk, textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), paddingBottom: appTheme.pixelPerfect(5) }}>{userConsent + DateUtils.getFullDateForTimestamp(consentData.ts)}</Text>
                <Button onPress={() => setConsentData({ ts: -1 })} title="Modifier" textStyle={{ color: appTheme.white }} style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.talk }} />
                <Button onPress={() => recordConsent(consentData.consent)} title="Garder mon choix" textStyle={{ color: appTheme.talk }} style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.white, borderWidth: 1, borderColor: appTheme.talk, marginTop: appTheme.pixelPerfect(10) }} />
            </View>);
        }

        return consentView;
    }

    return (
        <View style={{ height: appTheme.getFullScreenHeight(), alignItems: "center", backgroundColor: "#00000040", justifyContent: "flex-end" }}>
            <View style={{ position: "absolute", bottom: 0, backgroundColor: appTheme.white, width:notificationWidth, borderRadius: appTheme.pixelPerfect(20) }}>
                <View style={{ flexDirection: "row", width: notificationWidth, padding: appTheme.pixelPerfect(10), alignItems: "center", justifyContent: "space-between" }}>
                    <OtbImage source={images.icOwlYoda} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(60) }} />
                    <OtbImage source={images.icLiliCircle} style={{ width: appTheme.pixelPerfect(60), height: appTheme.pixelPerfect(60) }} />
                </View>
                {getContent()}
                <View style={{ marginTop: appTheme.pixelPerfect(20), paddingBottom: appTheme.bottomInsets + appTheme.pixelPerfect(20), backgroundColor: appTheme.talk }} />
            </View>
        </View>
    )
}

export default ConcentNotification;