import CasClient, { constant } from "../../utils/CasClient";
import { Account, Client, Databases, ID, Query } from 'appwrite';
import { build } from "../../../../package.json";
import { AppTarget, getAppTarget } from "../../utils/Navigation/Host";

const host = window.location.hostname;
let client = undefined;
let isTneApp = false;
let account = undefined;
let databases = undefined;
const tneAppApiUrl = "https://api.lili.cool/v1";
const appTarget = getAppTarget();
const tneAppProjectId = "6392ed8493c6811ab73f";
const tneAppDatabaseId = "63b2a188b1fd08721a8d";
const tneAppUsersCollectionId = "63b2a1aea2a53b245cbf";
if (appTarget !== "public") {
    isTneApp = true;
    client = new Client();
    client.setEndpoint(tneAppApiUrl).setProject(tneAppProjectId);
    account = new Account(client);
    databases = new Databases(client);
}

// Configuration CAS
const isProd = true;
const isGar = ((appTarget === "gar-prod") || (appTarget === "gar-preprod")) ? true : false;
const garCasEndpoint = appTarget === "gar-prod" ? "idp-auth.gar.education.fr" : "idp-auth.partenaire.test-gar.education.fr";
const garCasPath = "";
const casEndpoint = appTarget === "gar-prod" ? "www.reseau-canope.fr/" : "val.reseau-canope.fr/";
const casPath = "cas-usagers";
let casOptions = {
    version: constant.CAS_VERSION_3_0,
    path: isGar === true ? garCasPath : casPath,
    validation_proxy: true,
    validation_proxy_path: "/cas_proxy"
};

let casClient = isGar === true ? new CasClient(garCasEndpoint, casOptions) : new CasClient(casEndpoint, casOptions);

// TNE
export const checkCASConnexion = async () => {
    try {
        const authResult = await casClient.auth();
        return authResult;
    } catch (error) {
        throw error;
    };
}

export const redirectToCASLogin = () => {
    casClient.redirectToLoginUrl();
}

export function logoutFromCAS() {
    casClient.logout();
}

export const createAnonymousSession = async () => {
    try {
        const promise = await account.createAnonymousSession();
        return promise;
    } catch (error) {
        throw error;
    }
}

export const deleteCurrentSession = async () => {
    try {
        const promise = await account.deleteSession("current");
        return true;
    } catch (error) {
        return false;
    }
}

export const deleteSessionWithId = async (session_id:string) => {
    try {
        const promise = await account.deleteSession(session_id);
        return true;
    } catch (error) {
        return false;
    }
}

export const getCurrentSession = async () => {
    try {
        const promise = await account.getSession("current");
        return promise;
    } catch (error) {
        return null;
    }
}

export const deleteCurrentUser = async () => {
    try {
        const promise = await account.get();
        const deleteUserRequest = await fetch("https://api.lili.cool/v1/users/"+promise.$id, {
            method:"DELETE",
            headers: {
                "X-Appwrite-Project":"6392ed8493c6811ab73f",
                "Content-Type": "application/json"
            }
        });
        return true;
    } catch (error) {
        return false;
    }
}

export const getCurrentUser = async () => {
    try {
        const promise = await account.get();
        console.log("getCurrentUser", {promise});
        return promise;
    } catch (error) {
        console.log("getCurrentUser", {error});
        return null;
    }
}

export const getUser = async (id:string) => {
    try {
        let promise = await databases.getDocument(tneAppDatabaseId, tneAppUsersCollectionId, id);
        return promise;
    } catch (error) {
        return null;
    }
}

export const getUserWithGARIDO = async (gar_ido:string) => {
    try {
        let promise = await databases.listDocuments(tneAppDatabaseId, tneAppUsersCollectionId, [Query.equal('IDO', gar_ido)]);
        console.log("getUserWithGARIDO", {promise});
        if (promise.documents.length > 0) {
            return promise.documents[0];
        } else {
            return null;
        }
    } catch (error) {
        console.log("getUserWithGARIDO", {error});
        return null;
    }
}

export const getUserWithSessionId = async (current_session_id:string) => {
    try {
        let promise = await databases.listDocuments(tneAppDatabaseId, tneAppUsersCollectionId, [Query.equal('current_session_id', current_session_id)]);
        console.log("getUserWithSessionId", {promise});
        if (promise.documents.length > 0) {
            return promise.documents[0];
        } else {
            return null;
        }
    } catch (error) {
        console.log("getUserWithSessionId", {error});
        return null;
    }
}

export const updateUserPrefs = async (user_data: {ido:string, ticket:string, app:string}) => {
    try {
        const { ido, ticket, app } = user_data;
        const promise = await account.updatePrefs({ido, ticket, app});
        return true
    } catch (error) {
        throw error
    }
}

export const updateUserTicketPref = async (user_data: {ticket:string}) => {
    try {
        const { ticket } = user_data;
        const promise = await account.updatePrefs({ ticket });
        return true
    } catch (error) {
        throw error
    }
}

export const createUserWithGARData = async (user_data: {ido:string, pro:string, p_ms2:string[], uai:string, sessionId:string, ticket:string, authId:string, email:string, firstname:string, lastname:string, demo:boolean}) => {
    try {
        const { ido, pro, p_ms2, uai, sessionId, ticket, authId, email, firstname, lastname, demo } = user_data;
        await databases.createDocument(tneAppDatabaseId, tneAppUsersCollectionId, ID.unique(), {
            "build":build,
            "creation_build":build,
            "creation_version":"2.3",
            "last_update": new Date().getTime(),
            "version":"2.3",
            "email":email,
            "lastname":lastname,
            "firstname":firstname,
            "IDO": ido,
            "PRO": pro,
            "UAI": uai,
            "P_MS2": p_ms2,
            "current_session_id": sessionId,
            "gar_ticket": ticket,
            "current_auth_id": authId,
            "demo": demo
        });
        return true;
    } catch (error) {
        return null;
    }
}

export const updateSessionAndTicketForGarUser = async (user_data: {sessionId:string, ticket:string, userId:string, authId:string}) => {
    try {
        const { sessionId, ticket, userId, authId } = user_data;
        let promise = await databases.updateDocument(tneAppDatabaseId, tneAppUsersCollectionId, userId, {
            "last_update": new Date().getTime(),
            "current_session_id": sessionId,
            "gar_ticket": ticket,
            "current_auth_id": authId
        });
        return true;
    } catch (error) {
        return null;
    }
}

// Gestion de l'audio
const bucketIdForAudio = "641960bb6ecb47bdeb59";
export const getAudioUrlForMediaId = (data: {media_id:string}) => {
    const { media_id } = data;
    const urlToReturn = tneAppApiUrl+"/storage/buckets/"+bucketIdForAudio+"/files/"+media_id.toLowerCase()+"/view?project="+tneAppProjectId;
    return urlToReturn;
}

// Gestion des vidéos
const bucketIdForVideo = "641dbaacd6473dc2185a";
export const getVideoUrlForMediaId = (data: {media_id:string}) => {
    const { media_id } = data;
    const urlToReturn = tneAppApiUrl+"/storage/buckets/"+bucketIdForVideo+"/files/"+media_id.toLowerCase()+"/view?project="+tneAppProjectId;
    return urlToReturn;
}

// Gestion des documents
const bucketIdForDocument = "6438f2c4d03f27cb9ddf";
export const getDocumentUrlForMediaId = (data: {media_id:string}) => {
    const { media_id } = data;
    const urlToReturn = tneAppApiUrl+"/storage/buckets/"+bucketIdForDocument+"/files/"+media_id.toLowerCase()+"/view?project="+tneAppProjectId;
    return urlToReturn;
}

export const getDocumentUrlForActivityId = (data: {activity_id:string}) => {
    const { activity_id } = data;
    const urlToReturn = tneAppApiUrl+"/storage/buckets/"+bucketIdForDocument+"/files/"+activity_id.toLowerCase()+"/view?project="+tneAppProjectId;
    return urlToReturn;
}

export const getChapterAudioIdForActivityId = (data: {activity_id:string, index:string}) => {
    const { activity_id, index } = data;
    const urlToReturn = tneAppApiUrl+"/storage/buckets/"+bucketIdForAudio+"/files/"+activity_id.toLowerCase()+"_"+index+"/view?project="+tneAppProjectId;
    return urlToReturn;
}

export const getContextDocumentUrlForActivityId = (data: {activity_id:string}) => {
    const { activity_id } = data;
    const urlToReturn = tneAppApiUrl+"/storage/buckets/"+bucketIdForDocument+"/files/context_"+activity_id.toLowerCase()+"/view?project="+tneAppProjectId;
    return urlToReturn;
}