import React, { useEffect, useState } from 'react';
import { View, Animated, SafeAreaView, TouchableOpacity } from 'react-native';

import AppTheme from '../../utils/Theme';
import Image from "../../designSystem/OTBImage";

import Style from '../../utils/Style';

// SVG
import SVGView from "../../utils/SvgView";
import { default as IcTimes } from "../../assets/svg/icons/solid/times.svg";

/**
 * Player Audio
 */
import AudioPlayer from '../../../specific/utils/AudioPlayer/AudioPlayer';
import LiliAirPlayButton from '../../../specific/utils/AudioPlayer/AirPlayButton';

const ActivityPlayer = (props: any) => {

    const [stopRequest, setStopRequest] = useState(false);
    const [urls, setUrls] = useState(props.urls);

    const appTheme: AppTheme = new AppTheme();

    let imageUrl = props.image;
    if (imageUrl === undefined) {
        if (props.localImage !== undefined) {
            imageUrl = props.localImage;
        } else if (props.remoteImage !== undefined) {
            imageUrl = {uri:props.remoteImage};
        }
    }
    const { percentageForCompletion = 70 } = props;

    const actionColor = appTheme.darkBlue;
    let innerViewBgColor = appTheme.white;
    const fullPlayerWidth = appTheme.getBlockWidth() - appTheme.pixelPerfect(40);
    const viewWidth = appTheme.getFlexDirectionForSplitScreen() === "row" ? fullPlayerWidth / 2 : fullPlayerWidth;
    let activityHeaderHeight = viewWidth * 108 / 144;
    const playerHeight = appTheme.getFlexDirectionForSplitScreen() === "row" ? activityHeaderHeight : appTheme.pixelPerfect(200);
    const borderRadius = appTheme.pixelPerfect(20);

    let viewHeight = playerHeight;
    if (appTheme.getFlexDirectionForSplitScreen() === "column") {
        viewHeight = activityHeaderHeight + playerHeight;
        if (viewHeight > appTheme.getFullAppHeight()) {
            viewHeight = appTheme.getFullAppHeight();
            activityHeaderHeight = viewHeight - playerHeight;
        }
    }

    useEffect(() => {
        setUrls(props.urls);
    }, [props]);

    // Audio View
    const onPlayerEnd = () => {
        if (props.onPlayerEnd !== undefined) {
            props.onPlayerEnd();
        } else {
            askToCloseView();
        }
    }

    const onActivityCompletedForGamification = () => {
        if (props.onActivityCompleted !== undefined) {
            props.onActivityCompleted();
        }
    }

    const mainView = <View style={{ flexDirection: "row", alignItems: "center" }}>
        <View style={{ width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), marginEnd: appTheme.pixelPerfect(30) }} />
        <AudioPlayer
            size={140}
            onActivityCompleted={onActivityCompletedForGamification}
            percentageForCompletion={percentageForCompletion}
            onPlayerEnd={onPlayerEnd}
            stopRequest={stopRequest}
            autoLaunch={true}
            color={props.color}
            urls={urls}
            title={props.title !== undefined ? props.title : "Lili"}
            image={props.notificationImage !== undefined ? props.notificationImage : props.image} />
        <View style={{ marginStart: appTheme.pixelPerfect(30) }}>
            <LiliAirPlayButton activeTintColor={props.color} tintColor={props.color} />
        </View>
    </View>;



    const askToCloseView = () => {
        setStopRequest(true);
        closeView();
    }

    const closeView = () => {
        // Appel de la fonction close view dans la modal
        if (props.togglePlayer !== undefined) {
            props.togglePlayer();
        }
    }

    return (
        <SafeAreaView style={{ flex: 1, width:appTheme.getBlockWidth() }}>
            <Animated.View style={{ position: 'absolute', bottom: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth(), height: viewHeight, alignItems: "center" }}>
                <View style={[Style.fullViewShadowed, { flexDirection: appTheme.getFlexDirectionForSplitScreen(), width: fullPlayerWidth, height: viewHeight, borderRadius, alignItems: 'center', backgroundColor: innerViewBgColor }]}>
                    <Image source={imageUrl} style={{ width: viewWidth, height: activityHeaderHeight, borderRadius }} />
                    <View style={{ width: viewWidth, height: playerHeight, alignItems: "center", justifyContent: "center" }}>
                        {mainView}
                    </View>
                    <TouchableOpacity
                        style={{ position: 'absolute', right: appTheme.pixelPerfect(10), top: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), justifyContent: 'center', alignItems: 'center', backgroundColor: appTheme.white }}
                        onPress={askToCloseView} >
                        <SVGView Component={IcTimes} width={appTheme.pixelPerfect(24)} height={appTheme.pixelPerfect(24)} color={actionColor} />
                    </TouchableOpacity>
                </View>
            </Animated.View>
        </SafeAreaView >
    )
}

export default ActivityPlayer;
