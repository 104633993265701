import { ViewStyle, TextStyle } from "react-native";
import AppTheme from "../../utils/Theme";

export default class MenuStyle {

    appTheme: AppTheme = new AppTheme();

    menuWidth = this.appTheme.getBlockWidth() - this.appTheme.pixelPerfect(20);
    buttonWitdh = this.appTheme.getFlexDirectionForSplitScreen() === "row" ? this.appTheme.pixelPerfect(140) : this.menuWidth;
    maxButtonWidth = this.appTheme.getFlexDirectionForSplitScreen() === "row" ? this.appTheme.pixelPerfect(180) : this.appTheme.pixelPerfect(320);
    buttonHeight = this.appTheme.getFlexDirectionForSplitScreen() === "row" ? this.appTheme.pixelPerfect(220) : Math.ceil(this.buttonWitdh * 215 / 814);
    maxButtonHeight = this.appTheme.getFlexDirectionForSplitScreen() === "row" ? this.appTheme.pixelPerfect(240) : Math.ceil(this.maxButtonWidth * 215 / 814);

    headingButtonStyle:  ViewStyle = {
        borderRadius: this.appTheme.pixelPerfect(20),
        marginTop: this.appTheme.pixelPerfect(14),
        alignItems: "center",
        justifyContent: "center",
        paddingStart: this.appTheme.pixelPerfect(8),
        paddingEnd: this.appTheme.pixelPerfect(6),
        flexDirection:  this.appTheme.getFlexDirectionForSplitScreen() === "row" ? "column" : "row",
        width: this.buttonWitdh,
        height: this.buttonHeight
    };

    titleStyle: TextStyle = { 
        textAlign: "center", 
        fontSize: this.appTheme.pixelPerfectForFont(10),
        color: this.appTheme.white, 
        fontFamily: this.appTheme.primaryBoldFont 
    }

    constructor() {

    }
}