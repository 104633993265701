import React, { useState, useEffect } from "react";
import { View, Text, TextStyle, TouchableOpacity, Switch, Animated } from "react-native";
import AppTheme from "../../utils/Theme";

import Select from "react-select"

import Button from "../../designSystem/Button";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import Divider from "../../designSystem/Divider/Divider";

import { setDBCategory } from "../../../specific/services/Specific_Database";
import { getSessionsActivities, getSessionsCategories } from "../../../specific/utils/LocalStorage";

import Category from "../../models/Category";
import TextInput from "../../designSystem/TextInput/RoundCornerTextInput";
import GameBackground from "../Game/GameBackground";


interface Props {
  goBack?: () => void
}


const LHQCategoriesManagement = ({ goBack }: Props) => {


  //const { goBack } = props;

  // VALEURS PAR DÉFAUT POUR LES COULEURS DES CAT
  // btn : #1BA48A
  // header_bg : #FCECDF
  // nav : #1BA48A
  // txt : #FFFFFF

  const [categories, setCategories] = useState<any>(undefined);
  const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);

  const [newCategory, setNewCategory] = useState<boolean>(false);
  const [showNewCategoryForm, setShowNewCategoryForm] = useState<boolean>(false)


  const [section, setSection] = useState<{ value: string, name: string } | undefined>(undefined);
  const [sectionId, setSectionId] = useState<string>("");

  const [languageAndTitle, setLanguageAndTitle] = useState<{ language: LanguageOption | null, title: string; customLanguage?: string }[]>([]);
  const [selectedTitleLanguage, setSelectedTitleLanguage] = useState<LanguageOption | null>(null);


  const [languageAndDescription, setLanguageAndDescription] = useState<{ language: LanguageOption | null; descriptions: string[]; customDescriptionLanguage?: string }[]>([])
  const [selectedDescriptionLanguage, setSelectedDescriptionLanguage] = useState<LanguageOption | null>(null)

  const [analyticsKey, setAnalyticsKey] = useState<string>("");

  // NOM DE LA CATEGORIE
  const [categoryName, setCategoryName] = useState<string>("");
  const [tempCategoryKey, setTempCategoryKey] = useState("");
  const [categoryKeyError, setCategoryKeyError] = useState<string>("");
  const [categoryKey, setCategoryKey] = useState<string>("");

  const [tempCategoryOrder, setTempCategoryOrder] = useState<{ value: number, label: string } | undefined>(undefined);
  const [categoryOrder, setCategoryOrder] = useState<number | null>(null);

  const [status, setStatus] = useState<string>("Draft");
  const [isPublished, setIsPublished] = useState<boolean>(false);

  const [minBuildNumber, setMinBuildNumber] = useState<string>("");
  const [minBuildNumberError, setMinBuildNumberError] = useState<string>("");

  const [btnClr, setBtnClr] = useState<string>("");
  const [headerBgClr, setHeaderBgClr] = useState<string>("");
  const [navClr, setNavClr] = useState<string>("");
  const [txtClr, setTxtClr] = useState<string>("");

  const [category, setCategory] = useState<Category | undefined>(undefined);

  const appTheme: AppTheme = new AppTheme();

  const h1Style: TextStyle = {
    fontFamily: appTheme.primaryBoldFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(18)
  };

  const h2Style: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(16)
  };

  const h3Style: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(14)
  };

  const labelStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(10)
  };

  const mediaTitleStyle: TextStyle = {
    fontFamily: appTheme.primaryMediumFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(10)
  };

  const attributeTitleStyle: TextStyle = {
    fontFamily: appTheme.primaryBoldFont,
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(12),
    textDecorationLine: "underline"
  };

  const inputStyle: TextStyle = {
    height: appTheme.pixelPerfect(40),
    borderRadius: appTheme.pixelPerfect(10),
    borderWidth: 1,
    borderColor: appTheme.grow,
    padding: appTheme.pixelPerfect(4),
    color: appTheme.darkBlue,
    fontSize: appTheme.pixelPerfectForFont(6)
  };

  const pStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.textColor,
    fontSize: appTheme.pixelPerfectForFont(14)
  };

  const aStyle: TextStyle = {
    fontFamily: appTheme.primaryFont,
    color: appTheme.textColor,
    fontSize: appTheme.pixelPerfectForFont(14),
    textDecorationLine: "underline"
  };

  const onViewWillAppear = () => {
    getCategories();
  };

  const getCategories = async () => {
    const sessionCategories = await getSessionsCategories();
    setCategories(sessionCategories);
  };

  useEffect(() => {
    if (categories !== undefined) {
      setViewDidAppear(true);
    }
  }, [categories]);

  // PROVISOIRE JUSTE POUR SetViewDidAppear(true) 
  useEffect(() => {
    if (section === undefined) {
      //setViewDidAppear(true)
    }
  }, [section]);


  // ********** NEW CATEGORY TO ADD TO THE DATA BASE ****************************************************************************************************
  useEffect(() => {
    if (newCategory === true) {
      if (sectionId !== "" && languageAndTitle !== undefined && languageAndDescription !== undefined && categoryKey !== "" && analyticsKey !== "" && categoryOrder !== null && minBuildNumber !== "" && btnClr !== "" && headerBgClr !== "" && navClr !== "" && txtClr !== "" && status !== "" && categoryKeyError.length <= 0 && minBuildNumberError.length <= 0) {

        //Pour le titre de la catégorie
        let categoryTitle: LocalisedString = {};
        for (const aLocalizedIndex in languageAndTitle) {
          if (Object.prototype.hasOwnProperty.call(languageAndTitle, aLocalizedIndex)) {
            const aLocalizedTitle: { title: string, language: { value: string, label: string }, customLanguage?: string } = languageAndTitle[aLocalizedIndex];
            let languageKey: string = aLocalizedTitle.language.value;
            if (aLocalizedTitle.customLanguage !== undefined) {
              // Nouvelle langue
              languageKey = aLocalizedTitle.customLanguage;
            }
            categoryTitle[languageKey] = aLocalizedTitle.title;
          }
        };

        //Pour la description de la catégorie
        let categoryDescription: LocalisedStringArray = {};
        for (const aLocalizedIndex in languageAndDescription) {
          if (Object.prototype.hasOwnProperty.call(languageAndDescription, aLocalizedIndex)) {
            const aLocalizedDescription: { language: { value: string, label: string }, descriptions: [], customDescriptionLanguage?: string } = languageAndDescription[aLocalizedIndex];
            let languageKey: string = aLocalizedDescription.language.value;
            if (aLocalizedDescription.customDescriptionLanguage != undefined) {
              languageKey = aLocalizedDescription.customDescriptionLanguage;
            }
            categoryDescription[languageKey] = aLocalizedDescription.descriptions
            console.log("", categoryDescription[languageKey])
          }
        };

        let categoryToAdd: Category = {
          analytics_key: analyticsKey,
          body: categoryDescription,
          clr: {
            btn: btnClr,
            header_bg: headerBgClr,
            nav: navClr,
            txt: txtClr
          },
          key: categoryKey,
          last_update: new Date().getTime(),
          minBuildNumber: minBuildNumber,
          ord: categoryOrder,
          sectionId: sectionId,
          status: status,
          title: categoryTitle
        }
        setCategory(categoryToAdd)
      }
    }
  }, [newCategory]);

  useEffect(() => {
    if (category !== undefined) {
      dbCreateAndUpdateCategory();
      setSection(undefined)
      setSectionId("")
      setStatus("")
      setLanguageAndTitle([])
      setLanguageAndDescription([])
      setCategoryName("")
      setCategoryKey("")
      setTempCategoryKey("")
      setCategoryKeyError("")
      setAnalyticsKey("")
      setCategoryOrder(null)
      setTempCategoryOrder(null)
      setMinBuildNumber("")
      setMinBuildNumberError("")
      setBtnClr("")
      setHeaderBgClr("")
      setNavClr("")
      setTxtClr("")
      setCategory(undefined)
    }
  }, [category]);

  // ********** SETTER AUTOMAITQUE DU STATUS DE LA NOUVELLE CATÉGORIE EN CREATION EN FONCTION DE L'ETAT "IsPublished" **************************************************************
  useEffect(() => {
    if (isPublished === true) {
      setStatus("Published")
    } else {
      setStatus("Draft")
    }
  }, [isPublished]);

  const [animatedValue] = useState(new Animated.Value(0));

  // ********** GÉNÉRATION AUTOMATIQUE DE L'ANALYSE KEY EN FONCTION DE LA VALEUR DE LA CLÉ DE LA CATEGORIE **********
  useEffect(() => {
    if (tempCategoryKey !== undefined) {
      setAnalyticsKey(tempCategoryKey.replace(/([A-Z])/g, "_$1")
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
      )
      setCategoryKeyError("")
    }
  }, [tempCategoryKey]);

  // VÉRIFICATION AUTOMATIQUE SI LA CLÉ TEMPORAIRE DE LA CATÉGORIE EXISTE DÉJÀ DANS LA BASE DE DONNÉES
  // SI ELLE N'EXISTE PAS, ON SET LA VALEUR DE LA NOUVELLE CLÉ DE LA CATÉGORIE À PARTIR DE CLÉ TEMPORAIRE DE LA CATEGORIE
  // SINON ON PUSH UNE ERREUR POUR L'AFFICHER **********
  useEffect(() => {
    if (tempCategoryKey !== "" && tempCategoryKey !== undefined) {
      console.log({categories});
      if (categories.data[tempCategoryKey] === undefined) {
        setCategoryKey(tempCategoryKey)
      } else {
        setCategoryKeyError("Cette clé de catégorie existe déjà dans la base des categories. Veuillez saisir un autre nom pour cette nouvelle catégorie.")
      }
    }
  }, [tempCategoryKey]);
  
  const dbCreateAndUpdateCategory = async () => {
    let updatedCategory: Category = { ...category };
    await setDBCategory({ key: updatedCategory.key, value: updatedCategory })
  };

  // ********** FONCTION VALIDER LE FORMULAIRE**********
  const validateNewCategoryForm = () => {
    setNewCategory(true)
  };

  // ********** PARTIE POUR LA SELECTION DE LA SECTION **********
  const onSectionSelection = (e: { value: string }) => {
    setSection(e)
    setSectionId(e.value)
  };

  interface SectionInterface {
    value: string,
    name: string
  };

  const getSection = () => {
    const sectionArray: SectionInterface[] = [
      {
        value: "still",
        name: "Je me pose"
      },
      {
        value: "talk",
        name: "Je m'exprime"
      },
      {
        value: "grow",
        name: "Je grandis"
      },
      {
        value: "inspire",
        name: "Je m'inspire"
      },
      {
        value: "liliAtHome",
        name: "Lili à la maison"
      },
      {
        value: "liliAtSchool",
        name: "Lili à l'école"
      },
      {
        value: "onBoardingSteps",
        name: "Conseils d'utilisation de l'app"
      },
      {
        value: "superPowers",
        name: "Super pouvoir"
      },
      {
        value: "superPrograms",
        name: "Super programmes"
      }
    ];

    let sectionSelectionOptions: { value: string, label: string }[] = [];
    for (const aSection in sectionArray) {
      if (Object.prototype.hasOwnProperty.call(sectionArray, aSection)) {
        const element = sectionArray[aSection];
        sectionSelectionOptions.push({
          value: element.value,
          label: element.name + " - " + element.value
        })
      }
    };
    return (
      <Select
        value={section}
        onChange={(e) => onSectionSelection(e)}
        placeholder={"Sélectionnez la section à laquelle cette nouvelle categorie sera rattachée."}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: appTheme.pixelPerfect(30),
            borderRadius: appTheme.pixelPerfect(10),
            fontFamily: appTheme.primaryFont
            borderColor: appTheme.grow
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            fontFamily: appTheme.primaryFont
          })
        }} options={sectionSelectionOptions}
      />
    )
  };

  // console.log("*** SECTION", section)

  // ********** PARTIE POUR LE BOUTON SWITCH DU STATUS **********
  const toggleSwitch = () => {
    setIsPublished(!isPublished);

    // Animation du bouton
    Animated.timing(animatedValue, {
      toValue: isPublished ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  const getSwitchButtonForStatus = () => {
    const thumbPosition = animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [2, 33], // Les positions de départ et d'arrivée du bouton
    });

    return (
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: '#fff', marginBottom: appTheme.pixelPerfect(10) }}>
        <TouchableOpacity onPress={toggleSwitch}>
          <View style={{
            // Style pour le track
            width: appTheme.pixelPerfect(50),
            height: appTheme.pixelPerfect(25),
            borderRadius: appTheme.pixelPerfect(25),
            backgroundColor: isPublished === true ? '#117864' : '#d0d3d4',
            padding: 2,
            justifyContent: 'center',
          }}>
            <Animated.View
              style={{
                // Style pour le bouton rond (thumb)
                width: appTheme.pixelPerfect(20),
                height: appTheme.pixelPerfect(20),
                borderRadius: appTheme.pixelPerfect(10),
                backgroundColor: "#f4f3f4",
                transform: [{ translateX: thumbPosition }]
              }}
            />
          </View>
        </TouchableOpacity>
        <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20) }}>{isPublished === true ? "Published" : "Draft"}</Text>
      </View>
    )
  };


  // ********** PARTIE POUR LA SAISIE DU TITRE DE LA CATEGORY  ******************************************************************************************

  type LanguageOption = {
    value: string;
    label: string
  };

  const languageOptions: LanguageOption[] = [
    { value: 'fr', label: 'Français' },
    { value: 'en', label: 'Anglais' },
    { value: 'es', label: 'Espagnol' },
    { value: 'other', label: 'Autre' },
  ];

  const getCategoryTitlewithLanguage = () => {

    //////// PAS DE FONCTION DANS UNE FONCTION!!!!!!!!!!!! /////////
    const onPairLanguageAndTitleAdd = () => {
      // console.log("++++ SELECTED LANGUAGE ++++", selectedTitleLanguage.value)
      if (selectedTitleLanguage) {
        if (selectedTitleLanguage.value === 'other') {
          // Ajout d'un champ pour "Autre" (deux champs input)
          setLanguageAndTitle((prev) => [
            ...prev,
            { language: selectedTitleLanguage, title: '', customLanguage: '' },
          ]);
        } else {
          // Ajout de la langue sélectionnée avec un champ input associé

          setLanguageAndTitle((prev) => [
            ...prev,
            { language: selectedTitleLanguage, title: '' },
          ]);
        }
        setSelectedTitleLanguage(null); // Réinitialise la sélection
      }

    };

    const onTitleChange = (index: number, text: string) => {
      const newLanguageAndTitle = [...languageAndTitle];
      if (newLanguageAndTitle[index]) {
        newLanguageAndTitle[index].title = text;
      }
      setLanguageAndTitle(newLanguageAndTitle);
    };

    const onCustomLanguageChange = (index: number, text: string) => {
      const newLanguageAndTitle = [...languageAndTitle];
      if (newLanguageAndTitle[index] && newLanguageAndTitle[index].customLanguage !== undefined) {
        newLanguageAndTitle[index].customLanguage = text;
      }
      setLanguageAndTitle(newLanguageAndTitle)
    }

    // console.log("**** LANGUAGE AND TITLE ****", languageAndTitle);

    return (
      <View style={{ width: "100%", zIndex: 3 }}>
        {/* Sélecteur pour choisir la langue */}
        <View style={{ display: "flex", flexDirection: "row", width: "100%", zIndex: 3, marginBottom: appTheme.pixelPerfect(10) }}>
          <View style={{ width: "16%", zIndex: 4 }} >
            <Select
              value={selectedTitleLanguage}
              onChange={(selectedOption) => setSelectedTitleLanguage(selectedOption)}
              options={languageOptions}
              placeholder="Sélectionner la langue"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: appTheme.pixelPerfect(30),
                  borderRadius: appTheme.pixelPerfect(10),
                  fontFamily: appTheme.primaryFont,
                  width: "100%",
                  borderColor: appTheme.grow
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                  ...styles,
                  fontFamily: appTheme.primaryFont,
                })
              }}
            />
          </View>
          <View style={{ width: "20%", marginLeft: appTheme.pixelPerfect(10) }}>
            {/* Bouton Ajouter */}
            <Button
              onPress={() => onPairLanguageAndTitleAdd()}
              title={"Ajouter une langue"}
              style={{ height: appTheme.pixelPerfect(30) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }}
            />
          </View>
        </View>

        {/* Affichage dynamique selon la sélection */}
        {languageAndTitle.map((lang, index) => (
          <div key={index} style={{ display: "flex", flexDirection: "row", marginTop: '10px', width: "100%" }}>
            {lang.language?.value === 'other' ? (
              <View style={{ display: "flex", flexDirection: "row", width: "80%" }}>
                {/* Si "Autre" est sélectionné, deux champs input s'affichent */}
                <View style={{ width: "20%", marginRight: appTheme.pixelPerfect(10) }}>
                  <TextInput
                    onChangeText={(text) => onCustomLanguageChange(index, text)}
                    placeholder="Saisissez le code pays"
                    style={[inputStyle, { height: appTheme.pixelPerfect(30), width: "100%" }]}
                  />
                </View>
                <View style={{ width: "80%" }}>
                  <TextInput
                    onChangeText={(text) => onTitleChange(index, text)}
                    placeholder="Saisissez le titre correspondant au code pays"
                    style={[inputStyle, { height: appTheme.pixelPerfect(30), width: "100%" }]}
                  />
                </View>
              </View>
            ) : (
              <View style={{ display: "flex", flexDirection: "row", width: "80%" }}>
                {/* Sinon, le sélecteur de langue et un champ input */}
                <View style={{ width: "20%", marginRight: appTheme.pixelPerfect(10) }}>
                  <Select
                    value={lang.language}
                    options={languageOptions}
                    isDisabled={true} // Désactivé pour conserver la sélection
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: appTheme.pixelPerfect(30),
                        borderRadius: appTheme.pixelPerfect(10),
                        fontFamily: appTheme.primaryFont,
                        width: "100%",
                        borderColor: appTheme.grow,
                      }),
                      option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                        ...styles,
                        fontFamily: appTheme.primaryFont,
                      })
                    }}
                  />
                </View>
                <View style={{ width: "80%" }}>
                  <TextInput
                    value={lang.title}
                    onChangeText={(text) => onTitleChange(index, text)}
                    placeholder="Saisissez le titre dans la langue sélectionée"
                    style={[inputStyle, { height: appTheme.pixelPerfect(30), width: "100%" }]}
                  />
                </View>
              </View>
            )}
          </div>
        ))}
      </View>
    );
  };

  // ********** PARTIE POUR LA DESCRIPTION DE LA CATGORIE ****************************************************************************************************

  const getCategoryDescriptionWithLanguage = () => {

    const onPairLanguageAndDescriptionAdd = () => {
      if (selectedDescriptionLanguage) {
        if (selectedDescriptionLanguage.value === "other") {
          // Ajout d'un champ pour "Autre" (deux champs input)
          setLanguageAndDescription((prev) => [
            ...prev,
            { language: selectedDescriptionLanguage, descriptions: [""], customDescriptionLanguage: "" },
          ]);
        } else {
          // Ajout de la langue sélectionnée avec un champ input associé

          setLanguageAndDescription((prev) => [
            ...prev,
            { language: selectedDescriptionLanguage, descriptions: [""] },
          ]);
        }
        setSelectedDescriptionLanguage(null); // Réinitialise la sélection
      }
    };

    const onDescriptionChange = (langIndex: number, descriptionIndex: number, text: string) => {
      const newLanguageAndDescription = [...languageAndDescription];
      if (newLanguageAndDescription[langIndex]) {
        newLanguageAndDescription[langIndex].descriptions[descriptionIndex] = text
      }
      setLanguageAndDescription(newLanguageAndDescription)
    };

    const onCustomDescriptionLanguageChange = (langIndex: number, text: string) => {
      const newLanguageAndDescription = [...languageAndDescription];
      if (newLanguageAndDescription[langIndex] && newLanguageAndDescription[langIndex].customDescriptionLanguage !== undefined) {
        newLanguageAndDescription[langIndex].customDescriptionLanguage = text
      }
      setLanguageAndDescription(newLanguageAndDescription)
    };

    const onExtraDescriptionAdd = (index: number) => {
      const newLanguageAndDescription = [...languageAndDescription];
      newLanguageAndDescription[index].descriptions.push('') // Ajout d'un champ inpu supplémentaire pour la description
      setLanguageAndDescription(newLanguageAndDescription)
    };

    return (
      <View style={{ width: "100%", zIndex: 2 }}>
        {/* Sélecteur pour choisir la langue */}
        <View style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: appTheme.pixelPerfect(10), zIndex: 2 }}>
          <View style={{ width: "16%" }} >
            <Select
              value={selectedDescriptionLanguage}
              onChange={(selectedOption) => setSelectedDescriptionLanguage(selectedOption)}
              options={languageOptions}
              placeholder="Sélectionner la langue"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: appTheme.pixelPerfect(30),
                  borderRadius: appTheme.pixelPerfect(10),
                  fontFamily: appTheme.primaryFont,
                  width: "100%",
                  borderColor: appTheme.grow,
                }),
                option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                  ...styles,
                  fontFamily: appTheme.primaryFont,
                })
              }}
            />
          </View>
          <View style={{ width: "20%", marginLeft: appTheme.pixelPerfect(10) }}>
            {/* Bouton Ajouter */}
            <Button
              onPress={() => onPairLanguageAndDescriptionAdd()}
              title={"Ajouter une langue"}
              style={{ height: appTheme.pixelPerfect(30) }}
              textStyle={{ fontSize: appTheme.pixelPerfectForFont(6) }}
            />
          </View>
        </View>

        {/* Affichage dynamique selon la sélection */}
        {languageAndDescription.map((lang, langIndex) => (
          <div key={langIndex} style={{ display: "flex", flexDirection: "row", marginTop: '10px', width: "100%" }}>
            {lang.language?.value === 'other' ? (
              <View style={{ display: "flex", flexDirection: "row", width: "80%" }}>
                {/* Si "Autre" est sélectionné, deux champs input s'affichent */}
                <View style={{ width: "20%" }}>
                  <TextInput
                    onChangeText={(text) => onCustomDescriptionLanguageChange(langIndex, text)}
                    placeholder="Saisissez le code pays"
                    style={[inputStyle, { height: appTheme.pixelPerfect(30), width: "100%" }]}
                  />
                </View>
                <View style={{ width: "80%", paddingLeft: appTheme.pixelPerfect(10) }}>
                  {lang.descriptions.map((description, descriptionIndex) => (
                    <TextInput
                      key={descriptionIndex}
                      value={description}
                      onChangeText={(text) => onDescriptionChange(langIndex, descriptionIndex, text)}
                      multiline={true}
                      placeholder="Saisissez la description de la catégorie correspondant au code pays"
                      style={[inputStyle, { height: appTheme.pixelPerfect(60), width: "100%" }]}
                    />
                  ))}
                </View>
              </View>
            ) : (
              <View style={{ display: "flex", flexDirection: "row", width: "80%" }}>
                {/* Sinon, le sélecteur de langue et un champ input */}
                <View style={{ width: "20%" }}>
                  <Select
                    value={lang.language}
                    options={languageOptions}
                    isDisabled={true} // Désactivé pour conserver la sélection
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: appTheme.pixelPerfect(30),
                        borderRadius: appTheme.pixelPerfect(10),
                        fontFamily: appTheme.primaryFont,
                        width: "100%",
                        borderColor: appTheme.grow,
                      }),
                      option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
                        ...styles,
                        fontFamily: appTheme.primaryFont,
                      })
                    }}
                  />
                </View>
                <View style={{ width: "80%", paddingLeft: appTheme.pixelPerfect(10) }}>
                  {lang.descriptions.map((description, descriptionIndex) => (
                    <TextInput
                      key={descriptionIndex}
                      value={description}
                      onChangeText={(text) => onDescriptionChange(langIndex, descriptionIndex, text)}
                      placeholder="Saisissez la description de la categorie dans la langue sélectionnée"
                      multiline={true}
                      style={[inputStyle, { height: appTheme.pixelPerfect(60), width: "100%" }]}
                    />
                  ))}
                </View>
              </View>
            )}
            {/* Bouton pour ajouter une description supplémentaire */}
            <View style={{ display: "flex", alignItems: "center", width: "20%", height: appTheme.pixelPerfect(30) }}>
              <Button
                onPress={() => onExtraDescriptionAdd(langIndex)}
                //title={lang.language?.value === 'other' && lang.customDescriptionLanguage !== undefined ? `+ 1 desc. en "${lang.customDescriptionLanguage}"` : `+ 1 desc. en ${lang.language.label}` }
                title={lang.language?.value === 'other' ? `+1 description` : `+ 1 desc. en ${lang.language.label}`}
                // title={`+ 1 desc. en ${lang.language.label}`}
                style={{ height: appTheme.pixelPerfect(30), width: "80%" }}
                textStyle={{ fontSize: appTheme.pixelPerfectForFont(6), textTransform: "lowercase" }}
              />
            </View>
          </div>
        ))}
      </View>
    )
  };

  // console.log("**** LANGUAGE AND DESCRIPTION", languageAndDescription)

  // ********** PARTIE POUR LA SAISIE DE LA CLÉ (NOM DE LA CATEGORIE) PERMETTANT D'IDENTIFIÉ LA CATEGORIE (CELLE CI DÉTERMINERA AUTOMATIQUEMENT L'ANALYTICS KEY) **********


  const onChangeCategoryKey = (text: string) => {
    let transformedCategoryKey: string = text.toLowerCase()
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z]/g, " ")
      .split(' ')
      .map((word, index) => {
        if (index === 0) {
          return word
        } else {
          return (
            word.charAt(0).toUpperCase() + word.slice(1)
          )
        }
      })
      .join('')
    setTempCategoryKey(transformedCategoryKey)
    setCategoryName(text)
  };

  const createMyOwnCategoryKey = (text: string) => {
    setTempCategoryKey(text)
  };

  const getCategoryKey = () => {
    const categoryKeyErrorLabel = categoryKeyError.length > 0 ? <Text style={{ color: "#800000" }}>{categoryKeyError}</Text> : <View />
    return (
      <View>
        <View style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
          <View style={{ width: "45%" }}>
            <Text>Nom que vous souhaitez donner à cette nouvelle catégorie</Text>
            <TextInput
              value={categoryName}
              onChangeText={(text) => onChangeCategoryKey(text)}
              style={[inputStyle, { height: appTheme.pixelPerfect(30) }]}
              multiline={false}
              //  Ci dessous limiter le nombre de caractères à 40
              maxLength={40}
              placeholder="Saisissez le nom de la nouvelle catégorie"
            />
          </View>
          <View style={{ width: "45%" }}>
            <Text>Clé qui sera enregistrée pour cette nouvelle catégorie</Text>
            {tempCategoryKey !== "" && tempCategoryKey !== undefined ? <TextInput
              value={tempCategoryKey}
              style={[inputStyle, { height: appTheme.pixelPerfect(30) }]}
              multiline={false}
              placeholder={"Généré automatiquement mais éditable"}
              onChangeText={(text) => createMyOwnCategoryKey(text)}
            /> : <TextInput
              value={tempCategoryKey}
              style={[inputStyle, { height: appTheme.pixelPerfect(30) }]}
              onChangeText={(text) => createMyOwnCategoryKey(text)}
              multiline={false}
              placeholder={"Généré automatiquement mais éditable"}
            />}
          </View>
        </View>
        {categoryKeyErrorLabel}
      </View>
    )
  };

  // ********** PARTIE POUR LA SUGGESTION DE L'ANALYTICS KEY **********
  const onChangeCategoryAnalyticsKey = (text: string) => {
    setAnalyticsKey(text.toUpperCase()
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      .replace(/[^A-Z]/g, "_"))
  }

  const getNewAnalyticsKey = () => {
    return (
      <View>
        <TextInput
          value={categoryKeyError.length > 0 ? "" : analyticsKey}
          style={[inputStyle, { width: "100%", height: appTheme.pixelPerfect(30) }]}
          onChangeText={(text) => onChangeCategoryAnalyticsKey(text)}
          placeholder="Généré automatiquement"
        />

      </View>
    )
  };

  // ********** PARTIE POUR LA SAISIE DU ORDER "ORD" ******************************************************************************************
  interface Option {
    value: number;
    label: string
  }

  const onSelectCategoryOrder = (e: { value: number }) => {
    setTempCategoryOrder(e)
    setCategoryOrder(e.value)
  };

  const getOrder = () => {
    let categoryOrderOptions: { label: string, value: number }[] = [];
    //Générer les options de 0 à 100
    const options: Option[] = Array.from({ length: 101 }, (_, i) => ({
      value: i,
      label: i.toString()
    }));

    for (const anOption in options) {
      if (Object.prototype.hasOwnProperty.call(options, anOption)) {
        const element = options[anOption]
        categoryOrderOptions.push({
          value: element.value,
          label: element.label
        })
      }
    }
    return (
      <View style={{ zIndex: 3 }}>
        <Select
          value={tempCategoryOrder}
          onChange={(e) => onSelectCategoryOrder(e)}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              height: appTheme.pixelPerfect(30),
              borderRadius: appTheme.pixelPerfect(10),
              fontFamily: appTheme.primaryFont,
              borderColor: appTheme.grow
            }),
            option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
              ...styles,
              fontFamily: appTheme.primaryFont,
            })
          }}
          placeholder="Sélection un nombre"
          options={categoryOrderOptions}
        />
      </View>
    )
  };

  // ********** PARTIE POUR LA SAISIE DU MINIMUM BUILD NUMBER **********
  const onChangeCategoryMinBuildNumber = (text: string) => {
    //TO DO : Vérifier que text est composé de chiffres uniquement
    let isNumber: boolean = /^\d+$/.test(text);
    if ((isNumber === true) || (text.length === 0)) {
      setMinBuildNumber(parseInt(text));
      setMinBuildNumberError("");
    } else {
      setMinBuildNumberError("Le numéro de build doit être un nombre !")
    }
  }
  const getMinBuildNumber = () => {
    const errorLabelView: React.JSX.Element = minBuildNumberError.length > 0 ? <Text style={{ color: "#800000" }}>{minBuildNumberError}</Text> : <View />;
    const inputBorderColor: string = minBuildNumberError.length > 0 ? "#800000" : appTheme.grow;
    return (
      <View>
        <TextInput
          value={minBuildNumber}
          onChangeText={(text) => onChangeCategoryMinBuildNumber(text)}
          style={[inputStyle, { width: "100%", borderColor: inputBorderColor, height: appTheme.pixelPerfect(30) }]}
          placeholder="Saisissez un nombre"
        />
        {errorLabelView}

      </View>
    )
  };

  // ********** ORDER, KEY, MIN BUILD NUMBER ON THE SAIME LINE ******************************************************************************************
  const getOrderKeyMinBuildNumberSameLine = () => {
    return (
      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", zIndex: 1 }}>
        <View style={{ width: appTheme.pixelPerfect(200) }}>
          <Text style={labelStyle}>Analytics Key</Text>
          <Divider size={5} />
          {getNewAnalyticsKey()}
        </View>
        <View style={{ width: appTheme.pixelPerfect(200), zIndex: 2 }}>
          <Text style={labelStyle}>Ordre de la catégorie</Text>
          <Divider size={5} />
          {getOrder()}
        </View>
        <View style={{ width: appTheme.pixelPerfect(200) }}>
          <Text style={labelStyle}>Min. Build Number</Text>
          <Divider size={5} />
          {getMinBuildNumber()}
        </View>
      </View>
    )
  }

  // ********** PARTIE POUR LA DÉFINITION DE LA COULEUR **********

  const onChangeBtnColor = (text: string) => {
    setBtnClr(text)
  }
  const onChangeHeaderBgClr = (text: string) => {
    setHeaderBgClr(text)
  }

  const onChangeNavClr = (text: string) => {
    setNavClr(text)
  };

  const onChangeTxtClr = (text: string) => {
    setTxtClr(text)
  };

  const inspireBtnClr = "#FFC94E";
  const inspireHeaderBgClr = "#FFC94E";
  const inspireNavClr = "#FFF085";
  const inspireTxtClr = "#25364F";

  const getClrForCategoryElement = () => {

    return (
      <View style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <View>
          <Text>Couleur du btn"</Text>
          <TextInput
            value={sectionId === "inspire" ? inspireBtnClr : btnClr}
            onChangeText={(text) => onChangeBtnColor(text)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150), height: appTheme.pixelPerfect(30) }]}
            editable={sectionId === "inspire" ? false : true}
            placeholder="Couleur du bouton"
          />
        </View>
        <View>
          <Text>Couleur du header-bg</Text>
          <TextInput
            value={sectionId === "inspire" ? inspireHeaderBgClr : headerBgClr}
            onChangeText={(text) => onChangeHeaderBgClr(text)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150), height: appTheme.pixelPerfect(30) }]}
            editable={sectionId === "inspire" ? false : true}
            placeholder="Couleur de fond du header"
          />
        </View>
        <View>
          <Text>Couleur de la nav</Text>
          <TextInput
            value={sectionId === "inspire" ? inspireNavClr : navClr}
            onChangeText={(text) => onChangeNavClr(text)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150), height: appTheme.pixelPerfect(30) }]}
            editable={sectionId === "inspire" ? false : true}
            placeholder="Couleur de la navigation"
          />
        </View>
        <View>
          <Text>Couleur du txt</Text>
          <TextInput
            value={sectionId === "inspire" ? inspireTxtClr : txtClr}
            onChangeText={(text) => onChangeTxtClr(text)}
            style={[inputStyle, { width: appTheme.pixelPerfect(150), height: appTheme.pixelPerfect(30) }]}
            editable={sectionId === "inspire" ? false : true}
            placeholder="Couleur du texte"
          />
        </View>
      </View>
    )
  };

  // ********** BOUTON POUR FAIRE APPARAITRE LE FORMULAIRE DE CRÉATION D'UNE NOUVELLE CATEGORY ******************************************************************************************
  const createNewCategoryButton = () => {
    return (
      <Button disabled={(section === undefined)}
        style={{ width: appTheme.pixelPerfect(300) }}
        title={"Créer une nouvelle categorie"}
        onPress={() => setShowNewCategoryForm(true)} />
    )
  };

  const getContent = () => {
    if (viewDidAppear === false) {
      return <View onLayout={onViewWillAppear} style={{ width: "100%", flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator color={appTheme.grow} loading={true} />
      </View>
    };

    return (
      <View style={{ width: "100%" }}>
        <View style={{ flex: 1, alignItems: "flex-end" }}>
          {createNewCategoryButton()}
        </View>
        <Text style={labelStyle}>Choix de la section</Text>
        <Divider size={5} />
        {getSection()}

        {/* CE QUI SUIT N'APPARAIT PAS SI LA SECTION N'EST PAS DÉFINIE */}

        {showNewCategoryForm === true ? <View>

          <Divider size={20} />
          <Text style={labelStyle}>Status de la catégorie</Text>
          <Divider size={5} />
          {getSwitchButtonForStatus()}

          <Divider size={10} />
          <Text style={labelStyle}>Titre de la catégorie</Text>
          <Divider size={5} />
          {getCategoryTitlewithLanguage()}

          <Divider size={10} />
          <Text style={labelStyle}>Description de la catégorie (body)</Text>
          <Divider size={5} />
          {getCategoryDescriptionWithLanguage()}

          <Divider size={10} />
          <Text style={labelStyle}>Nom de la catégorie (clé)</Text>
          <Divider size={5} />
          {getCategoryKey()}

          <Divider size={10} />
          {getOrderKeyMinBuildNumberSameLine()}

          <Divider size={10} />
          <Text style={labelStyle}>Couleurs des éléments</Text>
          <Divider size={5} />
          {getClrForCategoryElement()}

          <Divider size={10} />
          <Button style={{ width: appTheme.pixelPerfect(300) }} title={showNewCategoryForm === true ? "Enregistrer" : "Mettre à jour"} onPress={() => validateNewCategoryForm()} />
        </View> : <View />}
        <Divider />
      </View>
    )
  };

  return (
    <View style={{ padding: appTheme.pixelPerfect(20) }}>
      <View style={{ flexDirection: "row", marginBottom: appTheme.pixelPerfect(10) }}>
        <TouchableOpacity onPress={() => goBack()}>
          <Text style={[aStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
            Dashboard
          </Text>
        </TouchableOpacity>
        <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
          {">"}
        </Text>
        <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
          Catégories
        </Text>
      </View>
      {getContent()}
    </View>
  )
};

export default LHQCategoriesManagement;