import React, { useEffect } from 'react';
import { Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';

interface MarkdownProps {
    onChange: (e) => void
}

const Markdown = () => {

    const [editorState, setEditorState] = React.useState(
        () => EditorState.createEmpty()
    );

    useEffect(() => {
        console.log(editorState);
    }, [editorState]);

    return (
        <Editor editorState={editorState} onChange={setEditorState} />
    );
}

export default Markdown;