import { ImageStyle, StyleProp, TextStyle, ViewStyle } from "react-native";
import AppTheme from "../../../shared/utils/Theme";


export default class SignInStyle {

    appTheme: AppTheme = new AppTheme();

    formContainer: StyleProp<ViewStyle> = { 
        flex: 1, 
        alignItems: "center", 
        justifyContent: "center", 
        backgroundColor: this.appTheme.white 
    }

    constructor() {

    }
}