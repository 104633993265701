import React, { useEffect, useRef, useState } from "react";

import {
    NativeSyntheticEvent,
    Platform,
    TextInputChangeEventData,
    View
} from "react-native";
import AppTheme from "../../../utils/Theme";
import Images from "../../../../specific/utils/Images";
import TextInput from "../../../designSystem/TextInput/RoundCornerTextInput";
import Divider from "../../../designSystem/Divider/Divider";
import RoundButton from "../../../designSystem/Buttons/RoundButton";
import LinkButton from "../../../designSystem/Buttons/LinkButton";
import i18n from "../../../utils/Localization/Localization";
import { validateEmail } from "../../../utils/StringUtils";
import { signInWithEmailAndPassword } from "../../../services/Auth";
import { relaunchApp } from "../../../../specific/utils/Navigation/Navigation";
import ComponentProps from "../../../../specific/utils/Interfaces/ComponentProps";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Alert from "../../Notification/Alert";

interface SignInProps extends ComponentProps {
    onChangePasswordRequest:() => void,
    userEmail?: string
}

const emptyErrors = {
    email:"",
    password:""
};

const SignIn = (props: SignInProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();

    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState(emptyErrors);

    const alertRef = useRef<Alert>(null);

    const { componentId = "", userEmail = "", onChangePasswordRequest } = props;
    const [email, setEmail] = useState("");

    let navigate: NavigateFunction | string = "";
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const connectUser = async () => {
        try {
            const signInResponse = await signInWithEmailAndPassword({email, password});
            setIsLoading(false);
            relaunchApp({
                componentId,
                navigate
            });
        } catch (error) {
            setIsLoading(false);
            let title = "Une erreur est survenue";
            let body = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
            if (error.toString().indexOf("auth/user-disabled") !== -1) {
                title = "Compte désactivé";
                body = "Votre compte a été désactivé.\nN'hésitez pas à nous contacter : support@lili.cool";
            } else if ((error.toString().indexOf("auth/invalid-credential") !== -1) || (error.toString().indexOf("auth/wrong-password") !== -1) || (error.toString().indexOf("auth/user-not-found") !== -1)) {
                title = "Adresse e-mail ou mot de passe erronée";
                body = "Veuillez vérifier votre adresse e-mail et votre mot de passe s'il vous plaît.\n\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\"";
            } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                title = "Erreur de connexion 📡";
                body = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît."
            }
            alertRef.current?.showAlert({
                title,
                body
            });
        }
    }

    const onSignInButtonPressed = () => {
        setIsLoading(true);
        let signInErrors = {email:"", password:""};
        if (email.length === 0) {
            signInErrors.email = "Votre e-mail ne peut être vide";
        } else {
            const isEmailValid = validateEmail(email);
            if (isEmailValid === false) {
                signInErrors.email = "La saisie de votre e-mail est incorrect";
            }
        }
        if (password.length === 0) {
            signInErrors.password = "Votre mot de passe ne peut être vide";
        } else if (password.length < 6) {
            signInErrors.password = "Votre mot de passe doit contenir 6 caractères minimum";
        }
        if ((signInErrors.email.length > 0) || (signInErrors.password.length > 0)) {
            setErrors(signInErrors);
            setIsLoading(false);
        } else {
            //Sign In
            connectUser();
        }
    }

    const onChangeText = (text:string) =>  {
        setEmail(text);
    }

    const onChange = (change:NativeSyntheticEvent<TextInputChangeEventData>) => {
    }

    return (<View style={{ justifyContent: "center", alignItems: "center", width:appTheme.getBlockWidth() }}>
        <TextInput value={email} onFocus={() => setErrors(emptyErrors)} error={errors.email} onChangeText={onChangeText} onChange={onChange} type="email" placeholder={i18n.t("authentication.signIn.form.emailPlaceholder")} />
        <TextInput onFocus={() => setErrors(emptyErrors)} error={errors.password} onChangeText={(text) => setPassword(text)} type="password" legend={i18n.t("authentication.signIn.form.passwordLegend")} placeholder={i18n.t("authentication.signIn.form.passwordPlaceholder")} />
        <Divider size={20} />
        <RoundButton isLoading={isLoading} onPress={onSignInButtonPressed} title={i18n.t("authentication.signIn.buttons.signIn")} />
        <Divider size={20} />
        <LinkButton onPress={onChangePasswordRequest} title={i18n.t("authentication.signIn.buttons.forgotPassword")} />
        <Alert ref={alertRef} />
    </View>)

}

export default SignIn;