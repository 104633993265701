import { ImageStyle, StyleProp, TextStyle, ViewStyle } from "react-native";
import AppTheme from "../../../shared/utils/Theme";


export default class WebSubStyle {

    appTheme: AppTheme = new AppTheme();

    formContainerWidth = this.appTheme.getBlockWidth() - this.appTheme.pixelPerfect(40);
    switchWidth = this.formContainerWidth - this.appTheme.pixelPerfect(80);

    container: StyleProp<ViewStyle> = {
        alignItems: "center",
        flex:1
    }

    headerLogo: StyleProp<ImageStyle> = {
        width: this.appTheme.pixelPerfect(80),
        height: this.appTheme.pixelPerfect(80),
        marginVertical: this.appTheme.pixelPerfect(20)
    }

    title: StyleProp<TextStyle> = {
        fontFamily: this.appTheme.primaryBoldFont,
        textTransform: "uppercase",
        fontSize: this.appTheme.pixelPerfectForFont(8),
        paddingBottom: this.appTheme.pixelPerfect(10)
    }

    subFormContainer: StyleProp<ViewStyle> = {
        overflow: "hidden",
        width: this.formContainerWidth,
        borderRadius: this.appTheme.pixelPerfect(20)
    }

    subDetailsContainer: StyleProp<ViewStyle> = {
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: this.appTheme.pixelPerfect(5)
    }

    subTitle: StyleProp<TextStyle> = {
        textTransform: "uppercase",
        fontFamily: this.appTheme.primaryMediumFont,
        fontSize: this.appTheme.pixelPerfectForFont(9)
    }

    subDetails: StyleProp<TextStyle> = {
        color: this.appTheme.white,
        textTransform: "uppercase",
        fontFamily: this.appTheme.primaryMediumFont,
        fontSize: this.appTheme.pixelPerfectForFont(8)
    }

    legalMentions: StyleProp<TextStyle> = {
        color: this.appTheme.white,
        fontFamily: this.appTheme.primaryFont,
        fontSize: this.appTheme.pixelPerfectForFont(7),
        opacity: 0.6
    }

    authFormContainer: StyleProp<ViewStyle> = {
        backgroundColor: this.appTheme.white,
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    }

    authSwitch: StyleProp<ViewStyle> = {
        overflow: "hidden",
        flexDirection: "row",
        width: this.switchWidth,
        height: this.appTheme.pixelPerfect(40),
        borderWidth: 1,
        borderRadius: this.appTheme.pixelPerfect(20),
        marginTop: this.appTheme.pixelPerfect(15)
    }

    switchButton: StyleProp<ViewStyle> = {
        borderRadius: this.appTheme.pixelPerfect(20), 
        flex: 1, 
        height: this.appTheme.pixelPerfect(40), 
        justifyContent: "center", 
        alignItems: "center" 
    }

    authForm: StyleProp<ViewStyle> = { 
        marginVertical: this.appTheme.pixelPerfect(20), 
        alignItems: "center"
    }

    authFormTitle: StyleProp<TextStyle> = { 
        fontFamily: this.appTheme.primarySemiBoldFont, 
        fontSize: this.appTheme.pixelPerfectForFont(8),
        textTransform: "uppercase" 
    }

    constructor() {

    }
}