import React, { useRef, useState } from "react";

import {
    View,
    Text,
    Image,
    TouchableOpacity
} from "react-native";
import Style from "../../utils/Style";
import AppTheme from "../../utils/Theme";
import Divider from "../../designSystem/Divider/Divider";
import TextInput from "../../designSystem/TextInput/RoundCornerTextInput";
import { signInWithEmailAndPassword, signOut } from "../../services/Auth";
import ActivityIndicator from "../../designSystem/ActivityIndicator/ActivityIndicator";
import Alert from "../Notification/Alert";

interface LHQAUthProps {
    onConnexionSucceed: () => void
}

const LHQAuth = (props: LHQAUthProps) => {

    const appTheme: AppTheme = new AppTheme();

    const alertRef = useRef<Alert>(null);

    const { onConnexionSucceed } = props;

    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    let width = appTheme.getFullAppWidth() - appTheme.pixelPerfect(40);
    if (width > appTheme.pixelPerfect(400)) {
        width = appTheme.pixelPerfect(400);
    }

    const connexionButton = () => {
        if (isLoading === true) {
            return <ActivityIndicator color={appTheme.grow} loading={true} />
        }
        return <Text style={{fontFamily:appTheme.primaryMediumFont, color:appTheme.grow, fontSize:appTheme.pixelPerfectForFont(14)}}>Connexion</Text>
    }

    const onConnectRequest = async () => {
        setIsLoading(true);
        let errorTitle: string = "";
        let errorBody: string = "";
        // On va connecter l'utilisateur, voir s'il a les droits d'accéder à LILIHQ, puis répondre en conséquence
        try {
            const signInResponse = await signInWithEmailAndPassword({email, password});
            if ((signInResponse.admin === undefined) || (signInResponse.admin === false)) {
                errorTitle = "Connexion refusée ☝️";
                errorBody = "Il semble que vous n'ayez pas les autorisations suffisantes pour accéder à LILI HQ !";
                await signOut();
                setEmail("");
                setPassword("");
            } else {
                onConnexionSucceed();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let errorTitle = "Une erreur est survenue";
            let errorBody = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
            if (error.toString().indexOf("auth/user-disabled") !== -1) {
                errorTitle = "Compte désactivé";
                errorBody = "Votre compte a été désactivé.\nN'hésitez pas à nous contacter : support@lili.cool";
            } else if ((error.toString().indexOf("auth/invalid-credential") !== -1) || (error.toString().indexOf("auth/wrong-password") !== -1) || (error.toString().indexOf("auth/user-not-found") !== -1) || (error.toString().indexOf("auth/invalid-email") !== -1)) {
                errorTitle = "Adresse e-mail ou mot de passe erronée";
                errorBody = "Veuillez vérifier votre adresse e-mail et votre mot de passe s'il vous plaît.\n\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\"";
            } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                errorTitle = "Erreur de connexion 📡";
                errorBody = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît.";
            }
        }
        if (errorTitle.length > 0) {
            alertRef.current?.showAlert({
                title:errorTitle,
                body:errorBody
            });
        }
    }

    return <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <View style={[Style.shadowed, { backgroundColor: appTheme.grow, width, paddingHorizontal: appTheme.pixelPerfect(30), paddingVertical: appTheme.pixelPerfect(20), borderRadius: appTheme.pixelPerfect(20), alignItems:"center" }]}>
            <Image source={require("../../assets/img/logo_central_perk_sd.png")} style={{width:appTheme.pixelPerfect(120), height:appTheme.pixelPerfect(120)}} />
            <Divider />
            <TextInput value={email} onChangeText={(text) => setEmail(text)} style={{backgroundColor:appTheme.white, width:width-appTheme.pixelPerfect(20)}} placeholder="Ton e-mail" />
            <Divider />
            <TextInput secureTextEntry={true} value={password} onChangeText={(text) => setPassword(text)} style={{backgroundColor:appTheme.white, width:width-appTheme.pixelPerfect(20)}} placeholder="Ton mot de passe" />
            <Divider />
            <TouchableOpacity disabled={isLoading} onPress={onConnectRequest} style={[Style.shadowed, {paddingHorizontal:appTheme.pixelPerfect(20), paddingVertical:appTheme.pixelPerfect(10), backgroundColor:appTheme.white, justifyContent:"center", alignItems:"center", borderRadius:appTheme.pixelPerfect(20)}]}>
                {connexionButton()}
            </TouchableOpacity>
            <Alert ref={alertRef} />
        </View>
    </View>


}

export default LHQAuth;