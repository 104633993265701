const CAS_VERSION_2_0 = '2.0';
const CAS_VERSION_3_0 = '3.0';

const CAS_VERSIONS = [CAS_VERSION_2_0, CAS_VERSION_3_0];

const CAS_STATUS_IN_PROCESS = 'in_process';

export {
  CAS_VERSION_2_0,
  CAS_VERSION_3_0,
  CAS_VERSIONS,
  CAS_STATUS_IN_PROCESS,
};

/**
 * Errors
 */
const CAS_ERROR_FETCH = 'FETCH_ERROR';
const CAS_ERROR_PARSE_RESPONSE = 'PARSE_RESPONSE_ERROR';
const CAS_ERROR_AUTH_ERROR = 'AUTH_ERROR';

export { CAS_ERROR_FETCH, CAS_ERROR_PARSE_RESPONSE, CAS_ERROR_AUTH_ERROR };
