import BundleSections from "../../shared/data/jsons/bundleSections.json";
import BundleActivities from "../../shared/data/jsons/bundleActivities.json";
import BundleActivitiesByCategory from "../../shared/data/jsons/bundleActivitiesByCategory.json";
import BundleActivitiesByTag from "../../shared/data/jsons/bundleActivitiesByTag.json";
import BundleCategories from "../../shared/data/jsons/bundleCategories.json";
import BundleActivitiesByTagAndCategory from "../../shared/data/jsons/bundleActivitiesByTagAndCategory.json";
import BundleActivitiesBySections from "../../shared/data/jsons/bundleActivitiesBySections.json";
import BundleQuotes from "../../shared/data/jsons/bundleQuotes.json";
import BundleLaunchSteps from "../../shared/data/jsons/bundleLaunchSteps.json";
import BunldeNews from "../../shared/data/jsons/bundleNews.json";
import { LaunchStep, News } from "../../shared/models/Models";

export const buildLocalDB = async (activities: any) => {

}

export const setLocalStorage = (data: { key: string, value: string }) => {
    const { key, value } = data;
    window.localStorage.setItem(key, value);
}

export const getLocalStorage = (data: { key: string }) => {
    const { key } = data;
    const value = window.localStorage.getItem(key);
    return value;
}

export const wipeLocalStorage = async () => {
    return window.localStorage.clear();
}

export const removeItemFromLocalStorage = (data: { key: string }) => {
    const { key } = data;
    return window.localStorage.removeItem(key);
}

export const getLocalActivities = async () => {
    return BundleActivities;
}

export const getLocalActivitiesByCategories = async () => {
    return BundleActivitiesByCategory;
}

export const getLocalActivitiesBySections = async () => {
    return BundleActivitiesBySections;
}

export const getLocalActivitiesByTagsAndCategories = async () => {
    return BundleActivitiesByTagAndCategory;
}

export const getLocalActivitiesByTags = async () => {
    return BundleActivitiesByTag;
}

export const getLocalCategories = async () => {
    return BundleCategories;
}

export const getLocalLaunchSteps = async () => {
    return BundleLaunchSteps;
}

export const getLocalNews = async () => {
    const sessionsNews = await getNews();
    console.log({sessionsNews});
    if (sessionsNews.ts > 0) {
        return sessionsNews;
    } else {
        const localNews = await recordLocalNews(BunldeNews);
        console.log({localNews});
        return localNews;
    }
}

export const recordLocalNews = async (newsToRecord: {ts:number, data:{[key:string]:News}}) => {
    await sessionStorage.setItem("news", JSON.stringify(newsToRecord));
    return newsToRecord;
}

export const recordLocalLaunchSteps = async (launchStepsToRecord: {ts:number, data:{[key:string]:LaunchStep}}) => {
    // A voir si on enregistre en session
}

export const getSessionsActivities = async () => {
    const sessionActivities = sessionStorage.getItem("activities");
    if (sessionActivities !== null) {
        return JSON.parse(sessionActivities);
    }
    return {data:{}, ts:new Date().getTime()};
}

export const recordSessionsActivities = async (data:any) => {
    try {
        await sessionStorage.setItem("activities", JSON.stringify({data, ts: new Date().getTime()}));
    } catch (error) {
    }
}

export const getSessionsCategories = async () => {
    const sessionCategories = sessionStorage.getItem("categories");
    if (sessionCategories !== null) {
        return JSON.parse(sessionCategories);
    }
    return {data:{}, ts:new Date().getTime()};
}

export const recordSessionsCategories = async (data:any) => {
    try {
        await sessionStorage.setItem("categories", JSON.stringify({data, ts: new Date().getTime()}));
    } catch (error) {
    }
}

export const getSessionsPremiumCards = async () => {
    const sessionPremiumCards = sessionStorage.getItem("premiumCards");
    if (sessionPremiumCards !== null) {
        return JSON.parse(sessionPremiumCards);
    }
    return {data:{}, ts:new Date().getTime()};
}


export const recordSessionsPremiumCards = async (data:any) => {
    try {
        await sessionStorage.setItem("premiumCards", JSON.stringify({data, ts: new Date().getTime()}));
    } catch (error) {
    }
}

export const getLocalQuotes = async () => {
    return BundleQuotes;
}

export const recordLocalActivities = async (activitiesToRecord:any) => {
    return true;
}

export const getDownloadedActivities = async () => {
    return [];
}

// NEWS
export const getNews = async () => {
    const sessionsNews = sessionStorage.getItem("news");
    if (sessionsNews !== null) {
        return JSON.parse(sessionsNews);
    }
    return {data:{}, ts:0};
}

export const recordNews = async (data:any) => {
    try {
        await sessionStorage.setItem("news", JSON.stringify({data, ts: new Date().getTime()}));
    } catch (error) {
    }
}