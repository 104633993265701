import React from "react";
import { useState, useEffect } from "react";

import {
    View,
    Text,
    Platform,
    StyleSheet,
    FlatList,
} from 'react-native';


import AppTheme from "../../utils/Theme";

// Assets
import Button from "../../designSystem/Button";
import { getUserWithId } from "../../../specific/services/Specific_Database";
import { useNavigate } from "react-router-dom";

type sub = {
    auto_renewing: boolean,
    creationDate: number,
    date: number,
    expires_date: number,
    origin: string,
    product_id: string,
    start_date: number
}

type giftUser = {
    liliMember: string,
    userName: string,
    userEmail: string,
    card_code: string,
    subCode: string,
    subDurationInMonth: number,
    subAtSchool: boolean,
    sub: sub,
    userKey: string,
    canReceiveGift: boolean,
    message: string,
    giftCreated?: boolean,
    giftId?: string,
    status?: string,
    created: number,
    updated: number
}

const subCaracteristics = {
    "LAUREAT_LAE1": {
        duration_in_monts: 1,
        product_id: "cool.lili.gift.laspremiumone",
        las: true
    },
    "LAUREAT_LAE3": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true
    },
    "LAUREAT_LAE12": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.laspremiumtwelve",
        las: true
    },
    "LAUREAT_LAM1": {
        duration_in_monts: 1,
        product_id: "cool.lili.gift.lahpremiumone",
        las: false
    },
    "LAUREAT_LAM3": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.lahpremiumthree",
        las: false
    },
    "LAUREAT_LAM12": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.lahpremiumtwelve",
        las: false
    },
    "PROF_COMM": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true
    },
    "PROF_RS": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true
    },
    "PROF_AMB": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.laspremiumtwelve",
        las: true
    },
    "PARENTALITE_RS": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.lahpremiumthree",
        las: false
    },
    "PARENTALITE_AMB": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.lahpremiumtwelve",
        las: false
    },
    "JOURNALISTE_LAM": {
        duration_in_monts: 1,
        product_id: "cool.lili.gift.lahpremiumone",
        las: false
    },
    "JOURNALISTE_LAE": {
        duration_in_monts: 1,
        product_id: "cool.lili.gift.laspremiumone",
        las: true
    },
    "JOURNALISTE_AMB": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.lahpremiumtwelve",
        las: false
    },
    "INSTITUTIONNEL": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true
    },
    "INSTITUTIONNEL_AMB": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.laspremiumtwelve",
        las: true
    },
    "PARTENAIRE": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true
    },
    "PARTENAIRE_AMB": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.laspremiumtwelve",
        las: true
    },
    "LILI_FAMILY": {
        duration_in_monts: 3,
        product_id: "cool.lili.gift.laspremiumthree",
        las: true
    },
    "LILI_TEAM": {
        duration_in_monts: 12,
        product_id: "cool.lili.gift.laspremiumtwelve",
        las: true
    },
    "PETITCHAMPION_LAE2": {
        duration_in_monts: 2,
        product_id: "cool.lili.gift.laspremiumtwo",
        las: true
    }
}

let giftCount = 0;

const AmplitudeTreatment = (props: any) => {

    // MainOptions
    const { componentId = "" } = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const { data } = props;

    const appTheme: AppTheme = new AppTheme();

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [userIds, setUserIds] = useState<string[]>([]);

    const onViewDidAppear = () => {

    }

    useEffect(() => {
        if (userIds.length > 0) {
            setViewDidAppear(true);
        }
    }, [userIds]);

    useEffect(() => {
        if (props.data.length > 0) {
            setViewDidAppear(false);
            treatUserList();
        }
    }, [props]);

    interface AmplitudeUser {
        user_id: string,
    }

    const treatUserList = async () => {
        let newUsers: string[] = [];
        const dataAsJSON = JSON.parse(props.data);
        for (const aKeyIndex in dataAsJSON) {
            if (Object.prototype.hasOwnProperty.call(dataAsJSON, aKeyIndex)) {
                const aUserDataArray = dataAsJSON[aKeyIndex];
                let userId: string = aUserDataArray["user_id"];
                newUsers.push(userId);
            }
        }
        setUserIds(newUsers);
    }

    const keyExtractor = (item, index) => {
        return (item.toString());
    }

    const aboutCell = ({ item, index }) => {
        return (
            <View style={{ width: appTheme.getFullAppWidth(), marginTop: appTheme.pixelPerfect(10), justifyContent: 'center', alignItems: 'flex-start' }}>
                <Text style={{ color: appTheme.darkBlue, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(8) }}>
                    {item.userId + " : " + item.profile}
                </Text>
            </View>
        )
    }

    const updateUsers = async () => {
        let userListUpdated = [];
        for (const aUserDataIndex in userIds) {
            if (Object.prototype.hasOwnProperty.call(userIds, aUserDataIndex)) {
                let aUserId = userIds[aUserDataIndex];
                // On va intéroger Firebase
                const fbUser = await getUserWithId({ user_id: aUserId });
                let profile = "parent";
                if (fbUser !== undefined) {
                    if (fbUser.profile !== undefined) {
                        profile = fbUser.profile;
                        if (profile === "family") {
                            profile = "parent";
                        }
                    } else {
                        if ((fbUser.code !== undefined) && (fbUser.code.code !== undefined)) {
                            profile = "teacher";
                        } else {
                            profile = "parent";
                        }
                    }

                    userListUpdated.push({
                        userId: aUserId,
                        profile
                    });
                    const options = {
                        method: 'POST',
                        headers: {
                            accept: 'application/json',
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify({
                            "api_key": "9605d8b096477137a6d9bdc4b67cb375",
                            "events": [
                                {
                                    "user_id": aUserId,
                                    "time": new Date().getTime(),
                                    "event_type": "user_properties",
                                    "user_properties": {
                                        "profile": profile
                                    }
                                }
                            ]
                        })
                    };
                    try {
                        const response = await fetch('https://api.eu.amplitude.com/2/httpapi', options);
                        setTimeout(() => {
                        }, 500);
                    } catch (error) {
                    }
                }

            }
        }
        if (props.onCreationCompleted !== undefined) {
            props.onCreationCompleted(userListUpdated);
        }
    }

    const getContent = () => {
        if (viewDidAppear === false) {
            return <View />
        }
        const actionButton = userIds.length > 0 ? <View style={{ marginVertical: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth() }}>
            <Button title={"Mettre à jour"} onPress={updateUsers} />
        </View> : <View />;
        return <View style={{ flex: 1, alignItems: "center", width: appTheme.getBlockWidth() }}>
            {actionButton}
            <FlatList
                data={userIds}
                renderItem={aboutCell}
                keyExtractor={keyExtractor}
                style={{ flex: 1 }} />
        </View >;
    }

    return (
        <View onLayout={onViewDidAppear} style={[styles.main]}>
            {getContent()}
        </View>
    )
}

export default AmplitudeTreatment;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#fff",
        marginTop: 20,
        borderRadius: 10
    },
    switchMenu: {
        position: "absolute",
        left: 0,
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "flex-start"
    }
});