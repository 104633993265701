/* LOCALIZATION */
import i18n from "./Localization/Localization";

export const monthDiff = (firstTs: number, secondTs: number) => {
    let months = 0;
    const firstDate = new Date(firstTs);
    const secondDate = new Date(secondTs);
    months = (secondDate.getFullYear() - firstDate.getFullYear()) * 12;
    months -= firstDate.getMonth();
    months += secondDate.getMonth();
    return Math.abs(months);
}

export const getEndOfTheSchoolYearDate = () => {
    const today = new Date();
    const month = today.getMonth();
    let endDate = new Date();
    if (month > 7) {
        // Il faut ajouter un an
        endDate = new Date(today.getFullYear()+1, 7, 31, 23, 59, 59);
    } else {
        // On est dans l'année scolaire
        endDate = new Date(today.getFullYear(), 7, 31, 23, 59, 59);
    }
    return endDate.getTime();
}

export function getDayAndMonthForBadge(dateAsString) {
    const monthAsString = dateAsString.substring(0,2);
    const dayAsString = dateAsString.substring(2, 4);
    return {"day": dayAsString, "month": getMonthForNumber(parseInt(monthAsString))};
}

export function getMinAndSecForCountdown(timeInSec) {
    const sec = parseInt(timeInSec, 10);
    let minutes = Math.floor(sec / 60);
    let seconds = sec - (minutes * 60);
    let minDigit = 0;
    let minUnit = 0;
    let secDigit = 0;
    let secUnit = 0;
    if (minutes < 10) {
        minDigit = 0;
        minUnit = minutes;
    } else {
        minDigit = Math.floor(minutes / 10);
        minUnit = minutes - (minDigit*10);
    }
    if (seconds < 10) {
        secDigit = 0;
        secUnit = seconds;
    } else {
        secDigit = Math.floor(seconds / 10);
        secUnit = seconds - (secDigit*10);
    }
    return {minDigit, minUnit, secDigit, secUnit};
}

export function convertHMS(value) {
    const sec = parseInt(value, 10);
    let hours   = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - (hours * 3600)) / 60);
    let seconds = sec - (hours * 3600) - (minutes * 60);
    if (hours === 0) {
        hours = "";
    } else {
        hours = hours+" h";
    }
    if (minutes === 0) {
        minutes = "";
    } else {
        minutes = minutes+" min";
    }
    if (seconds === 0) {
        seconds = "";
    } else {
        seconds = seconds+" sec";
    }
    return hours+' '+minutes+' '+seconds; // Return is HH : MM : SS
}

export function getBirthdayButtonTitle(date) {
    if (date !== undefined) {
        const aDateToTreat = new Date(date);
        //Jours
        var numericDay = aDateToTreat.getDate();
        if (numericDay < 10) numericDay = '0' + numericDay;

        //Mois
        const numericMonth = aDateToTreat.getMonth();
        const monthArray = ['jan.', 'fév.', 'mars', 'avr.', 'mai', 'juin', 'jui.', 'aoû.', 'sep.', 'oct.', 'nov.', 'déc.'];
        const aMonth = monthArray[numericMonth];

        //Année
        const numericYear = aDateToTreat.getFullYear();

        return numericDay + " | " + aMonth + " | " + numericYear;
    } else {
        return i18n.t('signup.birthdayDay') + " | " + i18n.t('signup.birthdayMonth') + " | " + i18n.t('signup.birthdayYear');
    }
}

export function getYear(aDate) {
    //Année
    const numericYear = aDate.getFullYear();

    return numericYear;
}

export function getWeekNumber(aDate) {
    const theDate = new Date(Date.UTC(aDate.getFullYear(), aDate.getMonth(), aDate.getDate()));
    theDate.setUTCDate(theDate.getUTCDate() + 4 - (theDate.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(theDate.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil((((theDate - yearStart) / 86400000) + 1) / 7);
    return weekNo;
}

export function getHourAndMinuteForTimestamp(aTimestamp) {
    const aDateToTreat = new Date(aTimestamp);
    //Heures
    var numericHours = aDateToTreat.getHours();
    if (numericHours < 10) numericHours = '0' + numericHours;

    //Minutes
    var numericMintues = aDateToTreat.getMinutes();
    if (numericMintues < 10) numericMintues = '0' + numericMintues;

    return numericHours + "H" + numericMintues;
}

export function getShortMonthForTimestamp(aTimestamp) {
    const aDateToTreat = new Date(aTimestamp);

    //Mois
    const numericMonth = aDateToTreat.getMonth();
    const monthArray = ['jan.', 'fév.', 'mars', 'avr.', 'mai', 'juin', 'jui.', 'aoû.', 'sep.', 'oct.', 'nov.', 'déc.'];
    const aMonth = monthArray[numericMonth];

    return aMonth;
}

export function getShortDateAndTimeForTimestamp(aTimestamp) {
    const aDateToTreat = new Date(aTimestamp);
    //Jours
    var numericDay = aDateToTreat.getDate();
    if (numericDay < 10) numericDay = '0' + numericDay;

    //Mois
    const numericMonth = aDateToTreat.getMonth();
    const monthArray = ['jan.', 'fév.', 'mars', 'avr.', 'mai', 'juin', 'jui.', 'aoû.', 'sep.', 'oct.', 'nov.', 'déc.'];
    const aMonth = monthArray[numericMonth];

    //Année
    const numericYear = aDateToTreat.getFullYear();

    return numericDay + " " + aMonth + " " + numericYear + " - " + getHourAndMinuteForTimestamp(aTimestamp);
}

export function getFullDateForTimestamp(aTimestamp) {
    const aDateToTreat = new Date(aTimestamp);
    //Jours
    var numericDay = aDateToTreat.getDate();
    if (numericDay < 10) numericDay = '0' + numericDay;

    //Mois
    const numericMonth = aDateToTreat.getMonth();
    const monthArray = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
    const aMonth = monthArray[numericMonth];

    //Année
    const numericYear = aDateToTreat.getFullYear();

    return numericDay + " " + aMonth + " " + numericYear;
}

export function getDayForTimestamp(aTimestamp) {
    const aDateToTreat = new Date(aTimestamp);
    //Jours
    var numericDay = aDateToTreat.getDate();
    if (numericDay < 10) numericDay = '0' + numericDay;

    return numericDay;
}

export function getMonthForTimestamp(aTimestamp) {
    const aDateToTreat = new Date(aTimestamp);

    //Mois
    const numericMonth = aDateToTreat.getMonth();
    const monthArray = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
    const aMonth = monthArray[numericMonth];

    return aMonth;
}

export function getYearForTimestamp(aTimestamp) {
    const aDateToTreat = new Date(aTimestamp);

    //Année
    const numericYear = aDateToTreat.getFullYear();

    return numericYear;
}

export function getNumericDateForAnalyticForTimestamp(aTimestamp:number) {
    const aDateToTreat = new Date(aTimestamp);
    //Jours
    const numericDay:number = aDateToTreat.getDate();
    let stringDay:string = numericDay.toString();
    if (numericDay < 10) {
        stringDay = '0' + stringDay;
    }

    //Mois
    const numericMonth:number = aDateToTreat.getMonth() + 1;
    let stringMonth:string = numericMonth.toString();
    if (numericMonth < 10) {
        stringMonth = '0' + stringMonth;
    }

    //Année
    const stringYear: string = aDateToTreat.getFullYear().toString();

    return stringYear+"-"+stringMonth+"-"+stringDay;
}

export function getNumericDateForTimestamp(aTimestamp) {
    const aDateToTreat = new Date(aTimestamp);
    //Jours
    var numericDay = aDateToTreat.getDate();
    if (numericDay < 10) numericDay = '0' + numericDay;

    //Mois
    var numericMonth = aDateToTreat.getMonth() + 1;
    if (numericMonth < 10) numericMonth = '0' + numericMonth;

    //Année
    const numericYear = aDateToTreat.getFullYear();

    return numericDay + "/" + numericMonth + "/" + numericYear;
}

export function getNumericDateAsIntForTimestamp(aTimestamp) {
    const aDateToTreat = new Date(aTimestamp);
    // Jours
    let numericDay = aDateToTreat.getDate();

    // Mois
    let numericMonth = aDateToTreat.getMonth() + 1;
    numericMonth = numericMonth*100;

    //Année
    let numericYear = aDateToTreat.getFullYear();
    numericYear = numericYear*10000;

    return parseInt(numericDay)+parseInt(numericMonth)+parseInt(numericYear);
}

export function getNumberOfDaysBetweenDates(aFirstTimestamp, aSecondTimestamp) {
    const numberOfMilliSeconds = aSecondTimestamp - aFirstTimestamp;
    return Math.round(numberOfMilliSeconds / 86400000);
}

export function getMonthForNumber(monthNumber) {
    const monthArray = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
    return monthArray[monthNumber-1];
}