import { ViewStyle, TextStyle } from "react-native";
import AppTheme from "../../utils/Theme";

export default class TeacherSignUpStyle {

    appTheme: AppTheme = new AppTheme();

    positionIndicatorCircleStyle: ViewStyle = {
        width: this.appTheme.pixelPerfect(20),
        height: this.appTheme.pixelPerfect(20),
        borderRadius: this.appTheme.pixelPerfect(10),
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center'
    };

    positionIndicatorTextStyle: TextStyle = {
        fontSize: this.appTheme.pixelPerfectForFont(10),
        fontWeight: "500"
    };

    titleStyle: TextStyle = {
        textAlign: "center",
        fontSize: this.appTheme.pixelPerfectForFont(9),
        fontFamily: this.appTheme.primaryMediumFont,
        letterSpacing: 0.8
    };

    constructor() {

    }
}