import React, { SVGProps } from "react";

import {
    Text,
    TouchableOpacity,
    TouchableOpacityProps
} from "react-native";

import AppTheme from "../../utils/Theme";
import LandingStyle from "../../styles/auth/LandingStyle";

interface AuthTabButtonProps extends TouchableOpacityProps {
    color?: string,
    title?: string,
    isSelected?: boolean,
    unselectedColor?: string 
}

const AuthTabButton = (props: AuthTabButtonProps) => {

    const appTheme: AppTheme = new AppTheme();
    const landingStyle: LandingStyle = new LandingStyle();

    const { color = appTheme.navigationColor, title="OK", isSelected=false, unselectedColor = appTheme.darkBlue, ...restProps } = props;

    const textColor = isSelected === true ? appTheme.darkBlue : unselectedColor;
    return <TouchableOpacity style={isSelected ? landingStyle.selectedTabButton : landingStyle.tabButton} {...restProps}>
        <Text style={[landingStyle.tabButtonTitle, { color:textColor }]}>{title}</Text>
    </TouchableOpacity>
}

export default AuthTabButton;