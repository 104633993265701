import React, { useEffect, useRef, useState } from "react";

import {
    Image,
    Linking,
    Modal,
    Platform,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import AppTheme from "../../../utils/Theme";
import * as Navigation from "../../../../specific/utils/Navigation/Navigation";
import TextInput from "../../../designSystem/TextInput/RoundCornerTextInput";
import Divider from "../../../designSystem/Divider/Divider";
import RoundButton from "../../../designSystem/Buttons/RoundButton";
import LinkButton from "../../../designSystem/Buttons/LinkButton";
import i18n from "../../../utils/Localization/Localization";
import AnimatorSignUpStyle from "../../../styles/components/AnimatorSignUpStyle";
import Alert from "../../Notification/Alert";
import LandingStyle from "../../../styles/auth/LandingStyle";
import SignUpForm from "../Forms/SignUpForm";
import ActionSheet from "../../ActionSheets/ActionSheet";
import { InstitutionType } from "../Forms/SelectInstituionType";
import AnimatorLevelsSelection from "../Forms/AnimatorLevelsSelection";
import { ScreenCookiesPolicy } from "../../../utils/Constants";
import TeacherSignUpStyle from "../../../styles/components/TeacherSignUpStyle";
import { createUserWithEmailAndPassword } from "../../../services/Auth";
import User from "../../../data/user/User";
import { setUserProperty } from "../../../services/Tracking/Analytics";
import { addContactToGeneralAudience } from "../../../services/Mailing";
import Images from "../../../../specific/utils/Images";
import { NavigateFunction, useNavigate } from "react-router-dom";

interface AnimatorSignUpProps {
    componentId: string,
    onCancelRequest: () => void,
    onSignUpConfirmed: () => void
}


const AnimatorSignUp = (props: AnimatorSignUpProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const landingStyle: LandingStyle = new LandingStyle();
    const teacherSignUpStyle: TeacherSignUpStyle = new TeacherSignUpStyle();

    const { onCancelRequest, componentId } = props;

    let navigate: NavigateFunction | string = "";
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    // Information du formulaire d'identité
    const [optInNewsletter, setOptInNewsLetter] = useState<boolean>(false);
    const [emailAddress, setEmailAddress] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [firstname, setFirstname] = useState<string>("");
    const [lastname, setLastname] = useState<string>("");

    // Information sur l'établissement
    const [institutionType, setInstitutionType] = useState<InstitutionType | undefined>(undefined);
    const [institutionName, setInstitutionName] = useState<string>("");
    const [institutionAddress, setInstitutionAddress] = useState<string>("");
    const [institutionZipCode, setInstitutionZipCode] = useState<string>("");
    const [institutionCity, setInstitutionCity] = useState<string>("");
    const [institutionCountry, setInstitutionCountry] = useState<string>("");
    const [animatorLevel, setAnimatorLevel] = useState<string | undefined>(undefined);
    const [step, setStep] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const animatorSignUpStyle: AnimatorSignUpStyle = new AnimatorSignUpStyle();

    const alertRef = useRef<Alert>(null);
    const signUpPagerRef = useRef<ScrollView>(null);
    const actionSheetRef = useRef<ActionSheet>(null);

    const onQuoteRequest = async () => {
        try {
            const animatorQuoteForm = "https://periscolaire.lili.cool";
            const canOpenUrl = await Linking.canOpenURL(animatorQuoteForm);
            if (canOpenUrl === true) {
                Linking.openURL(animatorQuoteForm);
            } else {
                alertRef.current?.showAlert({
                    title: "Une erreur est survenue lors de l'ouverture du lien",
                    body: "Vous pouvez accéder à cette page sur : \n https://periscolaire.lili.cool"
                });
            }
        } catch (error) {
            alertRef.current?.showAlert({
                title: "Une erreur est survenue lors de l'ouverture du lien",
                body: "Vous pouvez accéder à cette page sur : \n https://periscolaire.lili.cool"
            });
        }
    }

    const moveForward = (index: number) => {
        if (signUpPagerRef !== undefined) {
            signUpPagerRef.current?.scrollTo({ x: index * appTheme.getBlockWidth(), y: 0, animated: true });
        }
    }

    const registerNewUser = async () => {
        try {
            setIsLoading(true);
            const emailToRecord = emailAddress.toLowerCase().trim();
            const userSession: User | undefined = await createUserWithEmailAndPassword({
                context: "animator",
                email: emailToRecord,
                password,
                options: {
                    firstname,
                    lastname,
                    optInCoachNewsLetter: false,
                    optInNewsletter: optInNewsletter,
                    institutionType,
                    institutionAddress,
                    institutionCity,
                    institutionCountry,
                    institutionName,
                    institutionZipCode,
                    level: animatorLevel
                }
            });
            if (animatorLevel !== undefined) {
                setUserProperty({ key: "animator_function", value: animatorLevel });
            }
            setUserProperty({ key: "rare_emails_optin", value: optInNewsletter.toString(), once: true });
            if (institutionType !== undefined) {
                if (institutionType?.id.length > 0) {
                    if (institutionType.id === "other") {
                        setUserProperty({ key: "institution_type", value: institutionType.label })
                    } else {
                        setUserProperty({ key: "institution_type", value: institutionType.id })
                    }
                }
            }
            if (institutionName.length > 0) {
                setUserProperty({ key: "institution_name", value: institutionName })
            }
            if (institutionAddress.length > 0) {
                setUserProperty({ key: "institution_address", value: institutionAddress })
            }
            if (institutionZipCode.length > 0) {
                let zipNumber = "";
                if (institutionZipCode.length === 4) {
                    zipNumber = institutionZipCode.substring(0, 1);
                } else if (institutionZipCode.length === 5) {
                    zipNumber = institutionZipCode.substring(0, 2);
                    if (zipNumber === "97") {
                        zipNumber = institutionZipCode.substring(0, 3);
                    }
                }
                setUserProperty({ key: "institution_department", value: zipNumber })
            }
            if (institutionCountry.length > 0) {
                setUserProperty({ key: "institution_country", value: institutionCountry })
            }
            // Si l'utilisateur a accepté de recevoir des e-mails, on l'ajoute à la base
            if (Platform.OS !== "web") {
                addContactToGeneralAudience({
                    email: emailToRecord,
                    userType: "animator",
                    optIn: optInNewsletter,
                    optInCoach: false,
                    tneEnable: false
                });
            }
            if ((userSession !== undefined) && (userSession.uid !== undefined)) {
                setStep(4);
            } else {
                setIsLoading(false);
                alertRef.current?.showAlert({
                    title: "Une erreur est survenue",
                    body: "Veuillez réessayer plus tard s'il vous plaît."
                });
            }
        } catch (error) {
            setIsLoading(false);
            let title = "Une erreur est survenue";
            let body = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
            if (error.toString().indexOf("auth/email-already-in-use") !== -1) {
                title = "Utilisateur existant";
                body = "Votre adresse e-mail est déjà reliée à un compte de LILI.\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\" dans la page \"DÉJÀ INSCRIT(E)\"";
            } else if (error.toString().indexOf("auth/invalid-email") !== -1) {
                title = "Adresse e-mail erronée";
                body = "Veuillez vérifier votre adresse e-mail s'il vous plaît.";
            } else if (error.toString().indexOf("auth/weak-password") !== -1) {
                title = "Mot de passe trop faible";
                body = "Veuillez saisir au moins 6 caractères pour votre mot de passe.";
            } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                title = "Erreur de connexion 📡";
                body = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît."
            }
            alertRef.current?.showAlert({
                title,
                body,
                onPressPrimaryButton: () => setStep(0)
            });
        }
    }

    useEffect(() => {
        if (animatorLevel !== undefined) {
            registerNewUser();
        }
    }, [animatorLevel]);

    useEffect(() => {
        moveForward(step);
        if (step === 4) {
            if (props.onSignUpConfirmed !== undefined) {
                props.onSignUpConfirmed();
            }
            setIsLoading(false);
        }
    }, [step]);

    const onSignUpRequest = () => {
        setStep(1);
    }

    const recordFirstStepData = (data: { email: string, password: string, firstname: string, lastname: string, optIn: boolean }) => {
        setEmailAddress(data.email);
        setPassword(data.password);
        setFirstname(data.firstname);
        setLastname(data.lastname);
        setOptInNewsLetter(data.optIn);
        setStep(2);
    }

    const onInstitutionRecord = () => {
        if ((institutionName.length > 0) && (institutionAddress.length > 0) && (institutionCity.length > 0) && (institutionZipCode.length > 0) && (institutionCountry.length > 0) && (institutionType !== undefined)) {
            setStep(3);
        } else {
            alertRef.current?.showAlert({
                title: "Informations manquantes",
                body: "Veuillez vérifier que tous les champs sont correctement renseignés s'il vous plait."
            });
        }
    }

    const onInstitutionTypeRequest = () => {
        actionSheetRef.current.showAlert({
            actionSheetType: "INSTITUTION_TYPE",
            data: {

            }
        });
    }

    const getInstitutionTypeButton = () => {
        const textToDisplay: string = institutionType === undefined ? i18n.t("authentication.signUp.form.institutionTypePlaceholder") : institutionType.label;
        return <TouchableOpacity
            onPress={onInstitutionTypeRequest}
            style={{
                width: appTheme.getBlockWidth() * 0.8,
                borderRadius: appTheme.pixelPerfect(10),
                borderWidth: 1,
                borderColor: appTheme.darkBlue + "6D",
                height: appTheme.pixelPerfect(40),
                justifyContent: "center"
            }}>
            <Text style={{
                paddingHorizontal: 10,
                fontSize: appTheme.pixelPerfectForFont(8),
                fontFamily: appTheme.secondaryFont,
                color: appTheme.darkBlue
            }}>
                {textToDisplay}
            </Text>
        </TouchableOpacity>
    }

    const getSignUpStepThree = () => {
        return (<View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1, alignItems: "center", width: appTheme.getBlockWidth() }}>
            <AnimatorLevelsSelection isLoading={isLoading} width={appTheme.getBlockWidth()} onSuccess={(level) => setAnimatorLevel(level)} />
        </View>);
    }

    const onFinalStepValidation = () => {
        Navigation.relaunchApp({
            componentId,
            navigate
        });
    }

    const getFinalStep = () => {
        return <View style={{ marginBottom: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth(), alignItems: "center" }}>
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont, color: appTheme.schoolColor, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", textTransform: "uppercase" }}>
                {"Bienvenue parmi les\nsuper-animateurs !"}
            </Text>
            <Divider />
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center" }}>
                {"Découvrez"}
            </Text>
            <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", textTransform: "uppercase" }}>
                {"nos conseils pour vous lancer"}
            </Text>
            <View style={{ height: appTheme.pixelPerfect(180) }}>
                <Image source={images.illAnimatorSignUpFinal} style={{ height: appTheme.pixelPerfect(180), width: appTheme.pixelPerfect(230) }} resizeMode="contain" />
            </View>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
                <View style={{ flex: 1 }}>
                    <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center" }}>
                        {"Rendez-vous dans"}
                    </Text>
                    <Text style={{ paddingHorizontal: appTheme.pixelPerfect(10), fontFamily: appTheme.primaryBoldFont, color: appTheme.darkBlue, fontSize: appTheme.pixelPerfectForFont(10), textAlign: "center", textTransform: "uppercase" }}>
                        {"Le coin des\nSuper-animateurs"}
                    </Text>
                </View>
                <View style={{ width: appTheme.pixelPerfect(120), height: appTheme.pixelPerfect(145) }}>
                    <Image source={images.illAnimatorSignUpFinalBis} style={{ height: appTheme.pixelPerfect(145), width: appTheme.pixelPerfect(100) }} resizeMode="contain" />
                </View>
            </View>
            <Divider size={20} />
            <RoundButton textColor={appTheme.white} style={{ backgroundColor: appTheme.schoolColor }} isLoading={isLoading} onPress={onFinalStepValidation} title={"Découvrir Lili"} />
        </View>
    }

    const getSignUpStepTwo = () => {
        return (<View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1, alignItems: "center", width: appTheme.getBlockWidth() }}>
            <Text style={[animatorSignUpStyle.titleStyle, { textAlign: "left", color: appTheme.schoolColor }]}>
                {i18n.t("authentication.signUp.form.schoolFormTitle").toLocaleUpperCase()}
            </Text>
            <Divider />
            {getInstitutionTypeButton()}
            <Divider />
            <TextInput
                key="institutionName"
                placeholder={i18n.t("authentication.signUp.form.institutionNamePlaceholder")}
                onChangeText={(text) => setInstitutionName(text)}
                value={institutionName}
                keyboardType="default"
                autoCapitalize="words" />
            <TextInput
                key="institutionAddress"
                placeholder={i18n.t("authentication.signUp.form.institutionAddressPlaceholder")}
                onChangeText={(text) => setInstitutionAddress(text)}
                value={institutionAddress}
                keyboardType="default"
                autoCapitalize="words" />
            <TextInput
                key="institutionZipCode"
                placeholder={i18n.t("authentication.signUp.form.institutionZipCodePlaceholder")}
                onChangeText={(text) => setInstitutionZipCode(text)}
                value={institutionZipCode}
                keyboardType="default"
                autoCapitalize="words" />
            <TextInput
                key="institutionCity"
                placeholder={i18n.t("authentication.signUp.form.institutionCityPlaceholder")}
                onChangeText={(text) => setInstitutionCity(text)}
                value={institutionCity}
                keyboardType="default"
                autoCapitalize="words" />
            <TextInput
                key="institutionCountry"
                placeholder={i18n.t("authentication.signUp.form.institutionCountryPlaceholder")}
                onChangeText={(text) => setInstitutionCountry(text)}
                value={institutionCountry}
                keyboardType="default"
                autoCapitalize="words" />
            <RoundButton
                onPress={onInstitutionRecord}
                title={i18n.t("authentication.signUp.buttons.steps")} />
            <Alert ref={alertRef} />
        </View>);
    }

    const openGeneralConditions = () => {
        if (Platform.OS === "web") {
            let uri = "https://www.lili.cool/fr/mentions-legales/";
            Linking.openURL(uri);
        } else {
            Navigation.showModalInView({
                componentId,
                name: ScreenCookiesPolicy,
                passProps: {
                    uri: "https://www.lili.cool/fr/mentions-legales/"
                },
                options: {
                    modalPresentationStyle: 'overCurrentContext',
                    layout: {
                        componentBackgroundColor: 'transparent'
                    }
                }
            });
        }
    }

    const getSignUpStepOne = () => {
        return <View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1, width: appTheme.getBlockWidth(), alignItems: "center" }}>
            <SignUpForm type="laa" onSuccess={recordFirstStepData} openGeneralConditions={openGeneralConditions} />
        </View>
    }

    const getIntro = () => {
        return <View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1, width: appTheme.getBlockWidth(), alignItems: "center" }}>
            <Text style={[animatorSignUpStyle.titleStyle, { paddingHorizontal: appTheme.pixelPerfect(10) }]}>
                {i18n.t("authentication.animator.introduction.title")}
            </Text>
            <Divider />
            <Text style={[animatorSignUpStyle.bodyStyle, { paddingHorizontal: appTheme.pixelPerfect(10) }]}>
                {i18n.t("authentication.animator.introduction.body")}
            </Text>
            <Divider size={10} />
            <RoundButton onPress={onQuoteRequest} title={i18n.t("authentication.animator.introduction.buttons.main")} style={{ backgroundColor: appTheme.schoolColor }} />
            <Divider size={5} />
            <Text style={[animatorSignUpStyle.bodyStyle, { paddingHorizontal: appTheme.pixelPerfect(10) }]}>
                {i18n.t("authentication.animator.introduction.or")}
            </Text>
            <Divider size={5} />
            <RoundButton onPress={onSignUpRequest} title={i18n.t("authentication.animator.introduction.buttons.second")} style={{ backgroundColor: appTheme.schoolColor }} />
            <Divider size={20} />
            <LinkButton onPress={onCancelRequest} title="Annuler" />
        </View>
    }

    const getStepIndicatorByStep = () => {
        if ((step === 4) || (step === 0)) {
            return <View />;
        }
        let stepsBackgroundColors = [appTheme.schoolColor, appTheme.white, appTheme.white];
        let stepsColors = [appTheme.white, appTheme.schoolColor, appTheme.schoolColor];
        if (step === 1) {
            stepsBackgroundColors = [appTheme.schoolColor, appTheme.white, appTheme.white];
            stepsColors = [appTheme.white, appTheme.schoolColor, appTheme.schoolColor];
        } else if (step === 2) {
            stepsBackgroundColors = [appTheme.schoolColor, appTheme.schoolColor, appTheme.white];
            stepsColors = [appTheme.white, appTheme.white, appTheme.schoolColor];
        } else if (step === 3) {
            stepsBackgroundColors = [appTheme.schoolColor, appTheme.schoolColor, appTheme.schoolColor];
            stepsColors = [appTheme.white, appTheme.white, appTheme.white];
        }
        return <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <View style={[teacherSignUpStyle.positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[0], borderColor: appTheme.schoolColor }]}>
                <Text style={[teacherSignUpStyle.positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[0] }]}>1</Text>
            </View>
            <View style={{ width: appTheme.pixelPerfect(20), height: 1, backgroundColor: appTheme.schoolColor }} />
            <View style={[teacherSignUpStyle.positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[1], borderColor: appTheme.schoolColor }]}>
                <Text style={[teacherSignUpStyle.positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[1] }]}>2</Text>
            </View>
            <View style={{ width: appTheme.pixelPerfect(20), height: 1, backgroundColor: appTheme.schoolColor }} />
            <View style={[teacherSignUpStyle.positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[2], borderColor: appTheme.schoolColor }]}>
                <Text style={[teacherSignUpStyle.positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[2] }]}>3</Text>
            </View>
        </View>
    }

    return <View style={{ justifyContent: "flex-start", alignItems: "center", width: appTheme.getBlockWidth() }}>
        {getStepIndicatorByStep()}
        <Divider />
        <ScrollView scrollEnabled={false} pagingEnabled={true} ref={signUpPagerRef} horizontal={true} style={landingStyle.contentContainer}>
            {getIntro()}
            {getSignUpStepOne()}
            {getSignUpStepTwo()}
            {getSignUpStepThree()}
            {getFinalStep()}
        </ScrollView>
        <Alert ref={alertRef} />
        <ActionSheet componentId={props.componentId} ref={actionSheetRef} onChange={(insitutionType: InstitutionType) => setInstitutionType(insitutionType)} />
    </View>

}

export default AnimatorSignUp;