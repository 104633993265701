import React, { useState } from "react";

import {
    Text,
    View,
    TouchableOpacity,
    Platform,
    ScrollView,
} from "react-native";

import Images from "../../../specific/utils/Images";
import Image from "../../designSystem/OTBImage";

import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";

import i18n from "../../utils/Localization/Localization";

import * as Navigation from "../../../specific/utils/Navigation/Navigation";

import { useNavigate } from "react-router-dom";
import { EventRegister } from "../../utils/EventListeners";
import AsyncStorage from "@react-native-async-storage/async-storage";

let isMoodSelected = false;

const Mood = (props: any) => {
    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const [moodSelectedKey, setMoodSelectedKey] = useState("");

    let navigate = null;
    if (Platform.OS === "web") {
        navigate = useNavigate()
    }

    // MainOptions
    const { componentId = "", context = "splash" } = props;

    const moodSelected = async (mood: string) => {
        if (isMoodSelected === false) {
            isMoodSelected = true;
            await AsyncStorage.setItem(Constants.keyMood, mood);
            setMoodSelectedKey(mood);
            setTimeout(() => {
                isMoodSelected = false;
                if (context !== "splash") {
                    EventRegister.emitEvent(Constants.EventMoodFromProfileSelected, {});
                }

                Navigation.dismissModalInView({
                    componentId
                });
            }, 1000);
        }
    }

    const cardWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
    let imageSize = (appTheme.getBlockWidth() - appTheme.pixelPerfect(160)) / 3;
    let buttonSize = (appTheme.getBlockWidth() - appTheme.pixelPerfect(80)) / 3;
    const justifyContent = Platform.OS === "web" ? "flex-start" : "flex-end";

    if (imageSize > appTheme.pixelPerfect(50)) {
        imageSize = appTheme.pixelPerfect(50);
    }
    if (buttonSize > appTheme.pixelPerfect(100)) {
        buttonSize = appTheme.pixelPerfect(100);
    }

    const moodButtonStyle = {
        borderRadius: appTheme.pixelPerfect(40),
        justifyContent: "center",
        alignItems: "center"
    };

    const moodTitleStyle = {
        textTransform: "uppercase",
        paddingTop: appTheme.pixelPerfect(10),
        fontSize: appTheme.pixelPerfectForFont(8)
    };

    return (
        <View style={{ alignItems: "center", justifyContent, backgroundColor: "#00000040", height: appTheme.getFullScreenHeight(), width: appTheme.getFullScreenWidth() }}>
            <ScrollView style={{ flex: 1 }}>
                <View style={{ alignItems: "center", width: appTheme.getFullAppWidth(), marginTop: appTheme.topInsets, height: appTheme.getFullAppHeight() }}>
                    <View style={{ backgroundColor: appTheme.white, width: cardWidth, borderTopRightRadius: appTheme.pixelPerfect(20), borderTopLeftRadius: appTheme.pixelPerfect(20), overflow: "hidden", position:"absolute", bottom:0 }}>
                        <View style={{ paddingTop: appTheme.pixelPerfect(40), paddingHorizontal: appTheme.pixelPerfect(20), paddingBottom: appTheme.pixelPerfect(10) }}>
                            <Text style={{ textAlign: "center", fontFamily: appTheme.primaryMediumFont, fontSize: appTheme.pixelPerfectForFont(16), color: appTheme.darkBlue, textTransform: "uppercase" }}>{i18n.t("mood.title")}</Text>
                            <Text style={{ textAlign: "center", fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(12), color: appTheme.darkBlue }}>{i18n.t("mood.subtitle")}</Text>
                        </View>
                        <View style={{ marginTop: appTheme.pixelPerfect(20), paddingBottom: appTheme.bottomInsets + appTheme.pixelPerfect(20), backgroundColor: appTheme.white }}>
                            <View style={{ flexDirection: "row", width: cardWidth, justifyContent: "space-evenly", marginBottom: appTheme.pixelPerfect(20) }}>
                                <TouchableOpacity onPress={() => moodSelected("happy")} style={{ alignItems: "center" }}>
                                    <View style={[moodButtonStyle, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, opacity: ((moodSelectedKey === "happy") || (moodSelectedKey === "")) ? 1 : 0.6, backgroundColor: appTheme.daySkyColor }]}>
                                        <Image source={images.icMoodHappy} style={{ width: imageSize, height: imageSize }} resizeMode="contain" />
                                    </View>
                                    <Text style={[moodTitleStyle, { fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }]}>{i18n.t("mood.happy")}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => moodSelected("peaceful")} style={{ alignItems: "center" }}>
                                    <View style={[moodButtonStyle, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, opacity: ((moodSelectedKey === "peaceful") || (moodSelectedKey === "")) ? 1 : 0.6, backgroundColor: appTheme.daySkyColor }]}>
                                        <Image source={images.icMoodCalme} style={{ width: imageSize, height: imageSize }} resizeMode="contain" />
                                    </View>
                                    <Text style={[moodTitleStyle, { fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }]}>{i18n.t("mood.peaceful")}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{ flexDirection: "row", width: cardWidth, justifyContent: "space-evenly", marginBottom: appTheme.pixelPerfect(20) }}>
                                <TouchableOpacity onPress={() => moodSelected("sad")} style={{ alignItems: "center" }}>
                                    <View style={[moodButtonStyle, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, opacity: ((moodSelectedKey === "sad") || (moodSelectedKey === "")) ? 1 : 0.6, backgroundColor: appTheme.daySkyColor }]}>
                                        <Image source={images.icMoodSad} style={{ width: imageSize, height: imageSize }} resizeMode="contain" />
                                    </View>
                                    <Text style={[moodTitleStyle, { fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }]}>{i18n.t("mood.sad")}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => moodSelected("annoyed")} style={{ alignItems: "center" }}>
                                    <View style={[moodButtonStyle, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, opacity: ((moodSelectedKey === "annoyed") || (moodSelectedKey === "")) ? 1 : 0.6, backgroundColor: appTheme.daySkyColor }]}>
                                        <Image source={images.icMoodAnnoyed} style={{ width: imageSize, height: imageSize }} resizeMode="contain" />
                                    </View>
                                    <Text style={[moodTitleStyle, { fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }]}>{i18n.t("mood.annoyed")}</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{ flexDirection: "row", width: cardWidth, justifyContent: "space-evenly", marginBottom: appTheme.pixelPerfect(20) }}>
                                <TouchableOpacity onPress={() => moodSelected("stressed")} style={{ alignItems: "center" }}>
                                    <View style={[moodButtonStyle, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, opacity: ((moodSelectedKey === "stressed") || (moodSelectedKey === "")) ? 1 : 0.6, backgroundColor: appTheme.daySkyColor }]}>
                                        <Image source={images.icMoodStressed} style={{ width: imageSize, height: imageSize }} resizeMode="contain" />
                                    </View>
                                    <Text style={[moodTitleStyle, { fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }]}>{i18n.t("mood.stressed")}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => moodSelected("angry")} style={{ alignItems: "center" }}>
                                    <View style={[moodButtonStyle, { width: buttonSize, height: buttonSize, borderRadius: buttonSize / 2, opacity: ((moodSelectedKey === "angry") || (moodSelectedKey === "")) ? 1 : 0.6, backgroundColor: appTheme.daySkyColor }]}>
                                        <Image source={images.icMoodAngry} style={{ width: imageSize, height: imageSize }} resizeMode="contain" />
                                    </View>
                                    <Text style={[moodTitleStyle, { fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }]}>{i18n.t("mood.angry")}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

export default Mood;