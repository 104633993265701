import { ViewStyle } from "react-native";
import AppTheme from "../../utils/Theme";

export default class MainStyle {

    appTheme: AppTheme = new AppTheme();

    shadowed: ViewStyle = {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 4
    }

    constructor() {

    }
}