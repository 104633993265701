import React from "react";

import {
    View,
    Text
} from "react-native";

interface LHQTeamLeaderDashboardProps {

}

const LHQTeamLeaderDashboard = (props: LHQTeamLeaderDashboardProps) => {


    return <View>
        <Text>
            LHQ Team Leader Dashboard
        </Text>
    </View>

}

export default LHQTeamLeaderDashboard;