import React, { useEffect, useState } from "react";

import {
    Text,
    View
} from "react-native";
import ComponentProps from "../../../../specific/utils/Interfaces/ComponentProps";
import AppTheme from "../../../utils/Theme";
import AuthButton from "../../../designSystem/Buttons/RoundButton";
import DynamicBackground from "../../DynamicBackground/DynamicBackground";
import { UserType } from "./Landing";
import { Image } from "react-native";
import Images, { bundleImage } from "../../../../specific/utils/Images";
import AuthHeaderStyle from "../../../styles/auth/AuthHeaderStyle";
import Superfont from "../../../utils/Superfont/Superfont";

interface AuthHeaderProps {
    type?: UserType
}

type IconDef = {
    source: bundleImage,
    width: number,
    height: number
}

type Argument = {
    id: string,
    icon: IconDef,
    title: string,
    subtitle: string
}

const AuthFooter = (props: AuthHeaderProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const authHeaderStyle: AuthHeaderStyle = new AuthHeaderStyle();

    const [titleElements, setTitleElements] = useState<string[]>(["Faire", "grandir la confiance", "des 3-12 ans"]);
    const [titleColor, setTitleColor] = useState<string>(appTheme.darkBlue);
    const [userTypeIcon, setUserTypeIcon] = useState(images.icLiliCircle);

    // Déclaration des cellules pour l'argumentaire de profs
    const teacherArguments: Argument[] = [
        {
            id: "t_arg_01",
            icon: {
                source: images.icTeacherProfs,
                width: 50,
                height: 40
            },
            title: "6000 Super-profs",
            subtitle: "qui apprennent la confiance\net le vivre ensemble avec Lili"
        },
        {
            id: "t_arg_02",
            icon: {
                source: images.icTeachersWorkshops,
                width: 90,
                height: 40
            },
            title: "300 ateliers clé-en-main",
            subtitle: "à diffuser sur mobile, tablette,\nordinateur ou TNI"
        },
        {
            id: "t_arg_03",
            icon: {
                source: images.icTeacherTogether,
                width: 110,
                height: 35
            },
            title: "De courtes activités collectives",
            subtitle: "Temps calme, jeux d'art et impro,\npodcasts philo."
        },
        {
            id: "t_arg_04",
            icon: {
                source: images.icTeacherSuperProgrammes,
                width: 90,
                height: 50
            },
            title: "7 super-programmes",
            subtitle: "adaptés aux besoins\nparticuliers des enfants"
        },
        {
            id: "t_arg_05",
            icon: {
                source: images.illTeacherDocs,
                width: 61,
                height: 66
            },
            title: "1 espace dédié",
            subtitle: "au développement professionnel\ndes enseignants (formation & ressources)"
        }
    ]

    const periscolarArguments: Argument[] = [
        {
            id: "t_arg_02",
            icon: {
                source: images.icTeachersWorkshops,
                width: 90,
                height: 40
            },
            title: "300 ateliers clé-en-main",
            subtitle: "à diffuser sur mobile, tablette\nou ordinateur"
        },
        {
            id: "t_arg_03",
            icon: {
                source: images.icTeacherTogether,
                width: 110,
                height: 35
            },
            title: "De courtes activités collectives",
            subtitle: "Temps calmes, art & jeux\nd'impro, podcasts philo"
        },
        {
            id: "t_arg_04",
            icon: {
                source: images.icTeacherSuperProgrammes,
                width: 90,
                height: 50
            },
            title: "7 super-programmes",
            subtitle: "pour apprendre la confiance\net le vivre ensemble dès 3 ans"
        },
        {
            id: "t_arg_05",
            icon: {
                source: images.icPeriscolaire,
                width: 65,
                height: 60
            },
            title: "1 espace dédié",
            subtitle: "au développement professionnel\ndes super-animateurs"
        },
        {
            id: "t_arg_06",
            icon: {
                source: images.icMinistereWhite,
                width: 75,
                height: 60
            },
            title: "Ressource éducation nationale",
            subtitle: "Les compétences psychosociales\nau service du climat scolaire"
        }
    ]

    const familyArguments: Argument[] = [
        {
            id: "t_arg_02",
            icon: {
                source: images.icTeachersWorkshops,
                width: 90,
                height: 40
            },
            title: "300 activités sans écran",
            subtitle: "à diffuser sur mobile, tablette,\net ordinateur"
        },
        {
            id: "t_arg_03",
            icon: {
                source: images.icTeacherTogether,
                width: 110,
                height: 35
            },
            title: "Des ateliers audio et papier",
            subtitle: "Temps calmes, art & jeux\nd'impro, podcasts philo"
        },
        {
            id: "t_arg_04",
            icon: {
                source: images.illSupercatStanding,
                width: 90,
                height: 50
            },
            title: "7 super-programmes",
            subtitle: "adaptés aux besoins\nparticuliers de votre enfant"
        },
        {
            id: "t_arg_05",
            icon: {
                source: images.icOwlMaster,
                width: 50,
                height: 40
            },
            title: "1 espace dédié",
            subtitle: "pour les super-parents avec des conseils\nd'experts en pédagogie pour enfant"
        },
        {
            id: "t_arg_06",
            icon: {
                source: images.icMinistereWhite,
                width: 75,
                height: 60
            },
            title: "Ressource éducation nationale",
            subtitle: "Les compétences psychosociales\nau service du climat scolaire"
        }
    ]

    const getContent = () => {
        let argumentsToDisplay: Argument[] = [];
        let textColor = appTheme.darkBlue;
        if (props.type === "family") {
            textColor = appTheme.white;
            argumentsToDisplay = familyArguments;
        } else if (props.type === "animator") {
            argumentsToDisplay = periscolarArguments;
        } else if (props.type === "teacher") {
            argumentsToDisplay = teacherArguments;
        } else {
            return (<Image source={images.illSupercat} style={{ width: appTheme.pixelPerfect(120), height: appTheme.pixelPerfect(125), marginTop: appTheme.pixelPerfect(10) }} resizeMode="contain" />);
        }
        let argumentView = [];
        for (const anArgumentKey in argumentsToDisplay) {
            if (Object.prototype.hasOwnProperty.call(argumentsToDisplay, anArgumentKey)) {
                const anArgument: Argument = argumentsToDisplay[anArgumentKey];
                argumentView.push(<View key={anArgument.id} style={{ justifyContent: "center", alignItems: "center", marginTop: appTheme.pixelPerfect(50) }}>
                    <Image source={anArgument.icon.source} style={{ marginBottom:appTheme.pixelPerfect(5), width: appTheme.pixelPerfect(anArgument.icon.width), height: appTheme.pixelPerfect(anArgument.icon.height) }} resizeMode="contain" />
                    <Text style={{ fontFamily: appTheme.primaryBoldFont, textAlign: "center", textTransform: "uppercase", fontSize: appTheme.pixelPerfectForFont(10), color: textColor }}>{anArgument.title}</Text>
                    <Text style={{ fontFamily: appTheme.secondaryFont, textAlign: "center", fontSize: appTheme.pixelPerfectForFont(8), color: textColor }}>{anArgument.subtitle}</Text>
                </View>)
            }
        }
        return argumentView;
    }

    return getContent();

}

export default AuthFooter;