import { TextStyle } from "react-native";

import AppTheme from "../../utils/Theme";

export default class TextInputStyle {

    appTheme: AppTheme = new AppTheme();

    input: TextStyle = {
        outline: "none",
        width: this.appTheme.getBlockWidth()*0.9,
        height: this.appTheme.pixelPerfect(40),
        padding: 10,
        borderBottomWidth:1,
        borderColor: this.appTheme.textColor+'40',
        fontSize: this.appTheme.pixelPerfectForFont(8),
        marginBottom: this.appTheme.pixelPerfect(10),
        fontFamily: this.appTheme.primaryMediumFont,
        color: this.appTheme.darkBlue
    };

    legend: TextStyle = {
        fontFamily: this.appTheme.secondaryFont,
        fontSize: this.appTheme.pixelPerfectForFont(6),
        color: this.appTheme.textColor
    }

    focusedInput: TextStyle = {
        ...(this.input as object),
        borderColor:this.appTheme.textColor
    }

    inputMultiline: TextStyle = {
        ...(this.input as object),
        height: 120
    }

    focusedInputMultiline: TextStyle = {
        ...(this.inputMultiline as object),
        borderColor:this.appTheme.textColor
    }

    constructor() {

    }
}