import React from "react";

import {
    View,
    Text,
    TextStyle,
    TouchableOpacity,
    Linking
} from "react-native";
import AppTheme from "../../utils/Theme";
import Markdown from "../../../specific/components/Markdown/Markdown";

interface LHQDocumentationProps {
    goBack?: () => void
}

const LHQDocumentation = (props: LHQDocumentationProps) => {

    const appTheme: AppTheme = new AppTheme;

    const { goBack } = props;

    const h1Style: TextStyle = {
        fontFamily: appTheme.primaryBoldFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(18)
    }

    const h2Style: TextStyle = {
        fontFamily: appTheme.primaryMediumFont,
        color: appTheme.darkBlue,
        fontSize: appTheme.pixelPerfectForFont(16)
    }

    const pStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.textColor,
        fontSize: appTheme.pixelPerfectForFont(14)
    }

    const aStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        color: appTheme.textColor,
        fontSize: appTheme.pixelPerfectForFont(14),
        textDecorationLine: "underline"
    }

    const openLink = (link: string) => {
        Linking.openURL(link);
    }

    return <View>
        <View style={{ flexDirection: "row" }}>
            <TouchableOpacity onPress={() => goBack()}>
                <Text style={[aStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                    Dashboard
                </Text>
            </TouchableOpacity>
            <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                {">"}
            </Text>
            <Text style={[pStyle, { fontSize: appTheme.pixelPerfectForFont(8), paddingEnd: appTheme.pixelPerfect(5) }]}>
                Documentation
            </Text>
        </View>
        <Text style={h1Style}>
            Documentation
        </Text>
        <View style={{ width: 300, height: 300 }}>
            <Markdown />
        </View>
        <View>
            <Text style={h2Style}>
                Outils
            </Text>
            <View style={{ flexDirection: "row" }}>
                <Text style={[pStyle, { paddingEnd: appTheme.pixelPerfect(10) }]}>
                    Amplitude (Analytics)
                </Text>
                <TouchableOpacity onPress={() => openLink("https://app.eu.amplitude.com/analytics/lili/home")}>
                    <Text style={aStyle}>
                        https://app.eu.amplitude.com/analytics/lili/home
                    </Text>
                </TouchableOpacity>
            </View>

        </View>
    </View>

}

export default LHQDocumentation;