
import React, { useEffect, useState } from 'react';
import { Dimensions, Modal, StyleSheet, Text, TouchableOpacity, ScrollView, Alert } from 'react-native';

import Main from "../../../specific/components/Main/Main";

import { SafeAreaView, View } from "react-native";

import TextInput from "../../designSystem/OTBTextInput";

import i18n from '../../utils/Localization/Localization';

// SVG
import SVGView from "../../utils/SvgView";
import MainOptions from '../../models/MainOptions';
import AppTheme from '../../utils/Theme';
import Activity from '../../models/Activity';

import Button from '../../designSystem/Button';
import { updateAnActivity } from '../../../specific/services/Specific_Database';

import { default as IcSelected } from "../../assets/svg/icons/regular/check-circle.svg";

const defaultColor = "#ABABAB";

const ActivityForm = (props: any) => {

    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [showCategorySelection, setShowCategorySelection] = useState<boolean>(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
    const [showForm, setShowForm] = useState<boolean>(false);
    const [editActivity, setEditActivity] = useState<Activity>({ ...props.activity });
    const [selectedFilter, setSelectedFilter] = useState<string[]>(props.activity.cycles !== undefined ? props.activity.cycles : []);

    const { width, height } = Dimensions.get("window");

    const { componentId, activity = {} } = props;
    const appTheme: AppTheme = new AppTheme();

    const onViewDidAppear = async () => {
        if (viewDidAppear === false) {
            setViewDidAppear(true);
        }
    }

    const onCategorySelected = (data: { id: string }) => {
        const { id } = data;
        setSelectedCategoryId(id);
        setShowCategorySelection(false);
    }

    const getModalContent = () => {
        return <View />;
        let categoriesView = [];
        for (const aCategoryIndex in categories) {
            if (Object.prototype.hasOwnProperty.call(categories, aCategoryIndex)) {
                const aCategory = categories[aCategoryIndex];
                categoriesView.push(<TouchableOpacity key={aCategoryIndex} onPress={() => onCategorySelected({ id: aCategory.id })} style={{ flexDirection: "row", paddingHorizontal: 20, width, height: 50, justifyContent: "flex-start", alignItems: "center" }}>
                    <SVGView Component={aCategory.icon} size={20} color={"#909090"} />
                    <Text style={{ paddingStart: 10, color: "#909090", fontSize: 14 }}>{i18n.t("category." + aCategory.name_id)}</Text>
                </TouchableOpacity>);

            }
        }
        let modalView = <View style={[styles.shadowed, { backgroundColor: "#FFFFFF", justifyContent: "flex-end", paddingTop: 20, paddingBottom: 40, borderRadius: 20 }]}>
            <View style={{ width, height: 40, justifyContent: "center", alignItems: "center" }}>
                <Text style={{ fontSize: 16, color: "#707070" }}>Choisissez votre catégorie</Text>
            </View>
            {categoriesView}
        </View>
        return modalView;
    }

    const formTitle = activity.title.fr + " - " + activity.key;

    const mainOptions: MainOptions = new MainOptions({
        key:"activity_form",
        componentId,
        backgroundColor: appTheme.white,
        navigationColor: appTheme.talk,
        navigationItemColor: appTheme.white,
        showNavBar: true,
        showMenu: false,
        safeArea: true,
        canGoBack: false,
        canBeDismissed: true,
        title: props.activity.title.fr
    });

    const setNewValue = (data: { key: string, value: string, localized: boolean }) => {
        const { key, value, localized } = data;
        let newActivityData = { ...editActivity };
        if (localized === true) {
            newActivityData[key].fr = value;
        } else {
            newActivityData[key] = value;
        }
        setEditActivity(newActivityData);
    }

    const setChildrenValue = (data: { key: string, value: string, localized: boolean }) => {
        const { key, value, localized } = data;
        let newActivityData = { ...editActivity };
        if (localized === true) {
            newActivityData.children[key].fr = value;
        } else {
            newActivityData.children[key] = value;
        }
        setEditActivity(newActivityData);
    }

    const updateActivity = async () => {
        try {
            let newActivityData = { ...editActivity };
            newActivityData.cycles = selectedFilter;
            await updateAnActivity({ activity: newActivityData });
            Alert.alert("Activitée mise à jour");
        } catch (error) {
            Alert.alert("Erreur à la mise à jour", JSON.stringify(error));
        }
    }

    const treatClassification = (id:string) => {
        let newSelection = [...selectedFilter];
        if (newSelection.indexOf(id) !== -1) {
            const indexOfId = newSelection.indexOf(id);
            newSelection.splice(indexOfId, 1);
        } else {
            newSelection.push(id);
        }
        setSelectedFilter(newSelection);
    }

    const childrenDataForm = editActivity.children !== undefined ? <View>
        <Text style={{ fontSize: appTheme.pixelPerfectForFont(11), color: "#808080", marginStart: appTheme.pixelPerfect(5), marginTop: appTheme.pixelPerfect(10) }}>{"Nom de l'activité (enfant)"}</Text>
        <Text style={{ color: "#808080", marginStart: appTheme.pixelPerfect(5) }}>{"Valeur actuelle : " + activity.title.fr}</Text>
        <TextInput value={editActivity.title.fr} onChangeText={(text) => setChildrenValue({ key: "title", value: text, localized: true })} inputWidth={width - appTheme.pixelPerfect(20)} placeholder={"Titre"} />

        <Text style={{ fontSize: appTheme.pixelPerfectForFont(11), color: "#808080", marginStart: appTheme.pixelPerfect(5), marginTop: appTheme.pixelPerfect(10) }}>{"Description de l'activité (enfant)"}</Text>
        <Text style={{ color: "#808080", marginStart: appTheme.pixelPerfect(5) }}>{"Valeur actuelle : " + activity.body.fr}</Text>
        <TextInput multiline={true} value={editActivity.body.fr} onChangeText={(text) => setChildrenValue({ key: "body", value: text, localized: true })} inputWidth={width - appTheme.pixelPerfect(20)} placeholder={"Titre"} />
    </View> : <View />;

    const mainFilterColor = appTheme.white;
    const answerWidth = appTheme.getBlockWidth();

    return (
        <Main mainOptions={mainOptions}>
            <SafeAreaView onLayout={onViewDidAppear} style={styles.container}>
                <ScrollView style={{ flex: 1, width: appTheme.getFullAppWidth() }}>
                    <View style={styles.center}>
                        <View style={styles.header}>
                            <Text style={styles.title}>
                                {formTitle}
                            </Text>
                        </View>
                        <View style={styles.mainSection}>
                            <View style={{ marginVertical: appTheme.pixelPerfect(20) }}>
                                <TouchableOpacity onPress={() => treatClassification("cycle1")} style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: appTheme.talk, minHeight: appTheme.pixelPerfect(40), width: answerWidth, borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(10) }}>
                                    <View style={{ position: "absolute", left: appTheme.pixelPerfect(10), opacity: selectedFilter.indexOf("cycle1") !== -1 ? 1 : 0 }}>
                                        <SVGView Component={IcSelected} size={36} color={mainFilterColor} />
                                    </View>
                                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), color: mainFilterColor, textAlign: "center" }}>{"Maternelle"}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => treatClassification("cycle2")} style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: appTheme.talk, minHeight: appTheme.pixelPerfect(40), width: answerWidth, borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(10) }}>
                                    <View style={{ position: "absolute", left: appTheme.pixelPerfect(10), opacity: selectedFilter.indexOf("cycle2") !== -1 ? 1 : 0 }}>
                                        <SVGView Component={IcSelected} size={36} color={mainFilterColor} />
                                    </View>
                                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), color: mainFilterColor, textAlign: "center" }}>{"Élémentaire"}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => treatClassification("cycle3")} style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", backgroundColor: appTheme.talk, minHeight: appTheme.pixelPerfect(40), width: answerWidth, borderRadius: appTheme.pixelPerfect(20), marginBottom: appTheme.pixelPerfect(10) }}>
                                    <View style={{ position: "absolute", left: appTheme.pixelPerfect(10), opacity: selectedFilter.indexOf("cycle3") !== -1 ? 1 : 0 }}>
                                        <SVGView Component={IcSelected} size={36} color={mainFilterColor} />
                                    </View>
                                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), color: mainFilterColor, textAlign: "center" }}>{"Collège"}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </ScrollView>
                <View style={styles.footer}>
                    <Text style={styles.title}>
                        <Button onPress={updateActivity} title={"Mettre à jour"} />
                    </Text>
                </View>
                <Modal animationType="slide" transparent={true} visible={showCategorySelection}>
                    <View style={{ width, height, justifyContent: "flex-end" }}>
                        {getModalContent()}
                    </View>
                </Modal>

            </SafeAreaView>
        </Main>
    );
};

export default ActivityForm;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff"
    },
    center: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: 10
    },
    title: {
        fontWeight: "900",
        fontSize: 18,
        color: defaultColor
    },
    header: {
        height: 80,
        justifyContent: "center",
        alignItems: "center"

    },
    mainSection: {
        flex: 1,
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    footer: {
        height: 80,
        justifyContent: "center",
        alignItems: "center"
    },
    shadowed: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 2
    }
});