import React, { useRef, useState } from "react";

import {
    Text,
    View,
    Animated,
    LayoutChangeEvent,
    Platform,
    TouchableOpacity,
    StyleSheet
} from "react-native";
import { getAppTarget } from "../../../specific/utils/Navigation/Host";
import Button from "../../designSystem/Button";
import Style from "../../utils/Style";
import AppTheme from "../../utils/Theme";
interface ContactBottomSheetProps {
    onCloseRequest?: () => void,
    onChoiceSelected?: (choice:string) => void
}

const ContactBottomSheet = (props: ContactBottomSheetProps) => {

    const appTheme: AppTheme = new AppTheme();
    const [viewDidAppear, setViewDidAppear] = useState<boolean>(false);
    const [viewHeight, setViewHeight] = useState<number>(0);
    const animatedViewPosition = useRef(new Animated.Value(0)).current;
    const animatedViewOpacity = useRef(new Animated.Value(0)).current;

    const { onCloseRequest = () => { }, onChoiceSelected = () => { } } = props;

    const paddingBottom = appTheme.bottomInsets + appTheme.pixelPerfect(20);

    const onViewDidAppear = (event: LayoutChangeEvent) => {
        if (viewDidAppear === false) {
            const layoutHeight = event.nativeEvent.layout.height;
            Animated.sequence([
                Animated.timing(animatedViewPosition, {
                    toValue: -layoutHeight,
                    duration: 100,
                    useNativeDriver: false
                }),
                Animated.timing(animatedViewOpacity, {
                    toValue: 1,
                    duration: 100,
                    useNativeDriver: false
                }),
                Animated.timing(animatedViewPosition, {
                    toValue: 0,
                    duration: 500,
                    useNativeDriver: false
                }),
            ]).start(() => {
                setViewDidAppear(true);
                setViewHeight(layoutHeight);
            });
        }
    }

    const onCancelRequest = () => {
        Animated.sequence([
            Animated.timing(animatedViewPosition, {
                toValue: -viewHeight,
                duration: 500,
                useNativeDriver: false
            }),
            Animated.timing(animatedViewOpacity, {
                toValue: 0,
                duration: 100,
                useNativeDriver: false
            })
        ]).start(() => {
            onCloseRequest();
        });
    }

    const getChoices = () => {
        let choices = [
            "Demande d'information",
            "Support client",
            "Écoles et professeurs",
            "Comités d'entreprise",
            "Partenariats",
            "Communication",
            "Récupération d'informations personnelles",
            "Suppression du compte",
            "Autres"
        ];
        const appTarget = getAppTarget();
        if (appTarget === "gar-prod") {
            choices = [
                "Demande d'information",
                "Support",
                "Récupération d'informations personnelles",
                "Suppression du compte",
                "Autres"
            ];
        }
        let choicesView = [];
        for (const aChoiceIndex in choices) {
            if (Object.prototype.hasOwnProperty.call(choices, aChoiceIndex)) {
                const aChoice = choices[aChoiceIndex];
                choicesView.push(<TouchableOpacity key={aChoiceIndex} onPress={() => selectChoice(aChoice)} style={[styles.choiceCell, { height: appTheme.pixelPerfect(40) }]} >
                    <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(11) }}>{aChoice}</Text>
                </TouchableOpacity>);
            }
        }
        return choicesView;
    }

    const selectChoice = (choice: string) => {
        onChoiceSelected(choice);
        onCancelRequest();
    }

    const bottomSheetWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();

    return <View style={[{ flex: 1, justifyContent: "flex-end", alignItems: "center", backgroundColor: appTheme.white + "80" }]}>
        <Animated.View onLayout={onViewDidAppear} style={[Style.shadowed, { width: bottomSheetWidth, opacity: animatedViewOpacity, position: "absolute", bottom: animatedViewPosition, backgroundColor: appTheme.white, paddingBottom, paddingTop: appTheme.pixelPerfect(20), borderTopEndRadius: appTheme.pixelPerfect(20), borderTopStartRadius: appTheme.pixelPerfect(20), alignItems: "center" }]}>
            <Text style={{ fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue }}>Object du message</Text>
            <View style={{ alignItems: "flex-start", width: bottomSheetWidth, padding: appTheme.pixelPerfect(20) }}>
                {getChoices()}
            </View>
            <Button title="Annuler" style={{ width: appTheme.pixelPerfect(100), backgroundColor: appTheme.talk }} onPress={onCancelRequest} />
        </Animated.View>
    </View>

}

export default ContactBottomSheet;

const styles = StyleSheet.create({
    choiceCell: {
        borderBottomWidth: 1,
        borderBottomColor: "#DEDEDE",
        width: "100%",
        justifyContent: "center",
    }
});