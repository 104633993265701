import { useKeyboard } from "@react-native-community/hooks";
import React, { Ref, useEffect, useState } from "react";
import { LegacyRef } from "react";

import {
    Animated,
    KeyboardType,
    NativeSyntheticEvent,
    ReturnKeyType,
    TextInput,
    TextInputFocusEventData,
    Platform,
    View,
    TextStyle,
    Text,
    TouchableOpacity,
    Keyboard
} from 'react-native';


import SVGView from "../utils/SvgView";
import AppTheme from "../utils/Theme";


const DefaultTextInput = (props: {
    editable: boolean,
    autoCapitalize?: "none" | "sentences" | "words" | "characters" | undefined,
    label?: string,
    style?: TextStyle,
    password?: boolean,
    value?: string,
    keyboardType?: KeyboardType,
    onFocus?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void),
    onBlur?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void),
    onChangeText?: ((text: string) => void) | undefined,
    onSubmitEditing?: void,
    textInputRef?: LegacyRef<TextInput>,
    blurOnSubmit?: boolean,
    returnKeyType?: ReturnKeyType,
    multiline?: boolean,
    titleColor?: string,
    placeholderColor?: string,
    textColor?: string,
    backgroundColor?: string,
    legend?: string
}) => {

    const appTheme: AppTheme = new AppTheme();

    const {
        editable = true,
        autoCapitalize = "none",
        label = "",
        style = {},
        password = false,
        keyboardType = "default",
        onFocus,
        onBlur,
        onChangeText,
        onSubmitEditing,
        textInputRef,
        blurOnSubmit = false,
        returnKeyType = "default",
        multiline = false,
        titleColor = "#000000",
        placeholderColor = "#909090",
        textColor = "#000000",
        backgroundColor = appTheme.inputBackgroundColor,
        legend = ""
    } = props;

    const [isFocused, setIsFocused] = useState(false);
    const [animatedIsFocused, setAnimatedIsFocused] = useState(new Animated.Value(0));
    const [stateValue, setStateValue] = useState(props.value);
    const [hidePassword, setHidePassword] = useState(password);

    const handleSubmitEditing = () => {
        if (onSubmitEditing !== undefined) {
            onSubmitEditing();
        }
        Keyboard.dismiss();
    }

    // ComponentDidMount
    useEffect(() => {

    }, []);

    const onChange = (text: string) => {
        setStateValue(text);
        if (onChangeText !== undefined) {
            onChangeText(text);
        }
    }

    let inputViewHeight = multiline === false ? Platform.OS === "web" ? appTheme.pixelPerfect(50) : Platform.OS === 'ios' ? appTheme.pixelPerfect(40) : appTheme.pixelPerfect(50) : appTheme.pixelPerfect(120);
    inputViewHeight = legend.length > 0 ? Platform.OS === "web" ? inputViewHeight + appTheme.pixelPerfect(40) : inputViewHeight + appTheme.pixelPerfect(20) : inputViewHeight;
    const inputHeight = multiline === false ? Platform.OS === "web" ? appTheme.pixelPerfect(20) : Platform.OS === 'ios' ? appTheme.pixelPerfect(30) : appTheme.pixelPerfect(40) : appTheme.pixelPerfect(100);
    let width = ((style !== undefined) && (style.width !== undefined)) ? style.width : appTheme.pixelPerfect(220);
    let rowWidth = password === true ? width - appTheme.pixelPerfect(20) : width;
    const labelStyle = {
        fontSize: appTheme.pixelPerfectForFont(6),
        color: appTheme.textColor,
        paddingVertical: appTheme.pixelPerfect(2),
        width,
        fontFamily: appTheme.primaryMediumFont
    };

    const textInputStyle: TextStyle = {
        fontFamily: appTheme.primaryFont,
        marginTop: appTheme.pixelPerfect(30),
        fontSize: appTheme.pixelPerfectForFont(10),
        minHeight: inputHeight,
        paddingRight: appTheme.pixelPerfect(5),
        paddingLeft: appTheme.pixelPerfect(0),
        width:rowWidth,
        color: textColor
    }

    const webInputStyle: TextStyle = Platform.select({
        web: {
            width:rowWidth,
            marginTop: appTheme.pixelPerfect(10),
        },
    });

    const labelView = <Text numberOfLines={1} style={labelStyle}>{label.toLocaleUpperCase()}</Text>;
    const legendView = legend.length > 0 ? <Text style={[{ textAlign: "left", fontSize: appTheme.pixelPerfectForFont(6) }, { fontFamily: appTheme.secondaryFont, color: appTheme.darkBlue }]}>{legend}</Text> : <View />;
    const passwordIcon = hidePassword === true ? "🙈" : "🐵";
    const togglePasswordView = password === true ? <TouchableOpacity style={{ paddingTop: appTheme.pixelPerfect(28), }} onPress={() => setHidePassword(!hidePassword)}>
        <Text style={{ fontSize: appTheme.pixelPerfectForFont(16) }}>{passwordIcon}</Text>
    </TouchableOpacity> : <View />;
    return <View style={{ flexDirection: "row", alignItems: "flex-start", marginBottom: appTheme.pixelPerfect(5)}}>
        <View style={[style, { width, height: inputViewHeight, backgroundColor, flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-end'}]}>
            <View style={{ flexDirection: "row", alignItems:"flex-end", justifyContent:"space-between", width }}>
                <TextInput
                    editable={editable !== undefined ? editable : true}
                    underlineColorAndroid='transparent'
                    ref={textInputRef}
                    style={[textInputStyle, webInputStyle, style]}
                    autoCapitalize={autoCapitalize}
                    autoCorrect={false}
                    clearButtonMode="while-editing"
                    secureTextEntry={hidePassword}
                    onChangeText={(text) => onChange(text)}
                    value={stateValue}
                    keyboardType={keyboardType}
                    blurOnSubmit={blurOnSubmit}
                    onSubmitEditing={() => handleSubmitEditing()}
                    returnKeyType={returnKeyType}
                    multiline={multiline}
                    placeholder={label}
                    placeholderTextColor={placeholderColor}
                />
                {togglePasswordView}
            </View>
            <View style={{ width, height: 1, backgroundColor: titleColor + "30" }} />
            {labelView}
            {legendView}
        </View>
    </View>
}

export default DefaultTextInput;