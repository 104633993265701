import React from "react";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import {
    ActivityIndicator,
    Image,
    Platform,
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    SafeAreaView,
    Dimensions,
    Keyboard,
    Modal as RNModal,
    ScrollView
} from 'react-native';
import { ScreenActivitySheet, ScreenPDFViewer, ScreenSubscription, ScreenSuperRoom } from "../../../shared/utils/Constants";
import AppTheme from "../../../shared/utils/Theme";
import SuperRoom from "../../../shared/components/Rooms/SuperRoom";
import ActionSheet from "../../../shared/components/ActionSheets/ActionSheet";
import ActivitySheet from "../../../shared/components/Activities/ActivitySheet";
import PDFViewer from "../PDF/PDFViewer";
import Subscription from "../../../shared/components/Subscription/Subscription";

const InViewModal = forwardRef((props: any, ref: any) => {

    const [isVisible, setIsVisible] = useState(false);

    const appTheme: AppTheme = new AppTheme();

    const hideTheAlert = () => {
        setAlertContent({
            name: "",
            passProps: {},
            isVisible: false
        });
    }

    const [alertContent, setAlertContent] = useState({
        name: "",
        passProps: {},
        isVisible: false
    });

    // ComponentDidMount
    useEffect(() => {
    }, []);

    useImperativeHandle(ref, () => ({
        showAlert(data: { name: string, passProps?: any }) {
            const { name, passProps = {} } = data;
            setAlertContent({
                name,
                passProps,
                isVisible: true
            });
        },

        hideAlert() {
            hideTheAlert();
        }
    }));

    const getModalComponent = () => {
        if (alertContent.name === ScreenSuperRoom) {
            return <SuperRoom onCloseRequest={hideTheAlert} {...alertContent.passProps} />
        } else if (alertContent.name === ScreenActivitySheet) {
            return <ScrollView style={{ flex: 1, paddingVertical: appTheme.pixelPerfect(20) }}>
                <ActivitySheet inPager={true} onCloseRequest={hideTheAlert} {...alertContent.passProps} />
            </ScrollView>
        } else if (alertContent.name === ScreenPDFViewer) {
            return <PDFViewer closeTheDocument={hideTheAlert} {...alertContent.passProps} />
        } else if (alertContent.name === ScreenSubscription) {
            return <Subscription onCloseRequest={hideTheAlert} {...alertContent.passProps} />
        }
        /*
        if (alertContent.name === ScreenAbout) {
            return <About {...props} />
        } else if (modalOptions.name === Constants.ScreenUserProfile) {
            return <UserProfile {...props} />
        } else if (modalOptions.name === Constants.ScreenConsentNotification) {
            return <ConsentNotification {...props} />
        } else if (modalOptions.name === Constants.ScreenMood) {
            return <Mood {...props} />
        } else if (modalOptions.name === Constants.ScreenActivityForm) {
            return <ActivityForm {...props} />
        } else if (modalOptions.name === Constants.ScreenSubscription) {
            return <Subscription {...props} />
        } else if (modalOptions.name === Constants.ScreenLegalMentions) {
            return <LegalMentions {...props} />
        } else if (modalOptions.name === Constants.ScreenSuperRoom) {
            return <SuperRoom {...props} />
        }
        */
        return <View />;
    }

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return <RNModal
        visible={alertContent.isVisible}
        style={customStyles}
        animationType="fade">
        <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), alignItems: "center" }}>
            <View style={{ position: "absolute", backgroundColor: appTheme.darkBlue, width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight() }} />
            <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), alignItems: "center" }}>
                {getModalComponent()}
            </View>
        </View>
    </RNModal>

    return (
        <Modal
            ariaHideApp={false}
            transparent={true}
            isOpen={alertContent.isVisible}
            style={customStyles}>
            <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), alignItems: "center" }}>
                <View style={{ position: "absolute", backgroundColor: appTheme.darkBlue, width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight() }} />
                <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), alignItems: "center" }}>
                    {getModalComponent()}
                </View>
            </View>
        </Modal>
    )
});

export default InViewModal;