import React, { useState } from "react";

import {
    View,
    Text,
    StyleSheet
} from "react-native";

import AppTheme from "../../../shared/utils/Theme";


const About = () => {

    const appTheme: AppTheme = new AppTheme();

    const [count, setCount] = useState(0);

    const onViewDidAppear = () => {
        startCount();
    }

    const startCount = () => {
        setTimeout(() => {
            setCount(count+1);
        }, 100);
    }

    return (
        <View onLayout={onViewDidAppear} style={[{ width: appTheme.getFullScreenWidth(), backgroundColor:appTheme.talk}]}>
            <Text>{count.toString()}</Text>
        </View>
    )
};

export default About;