import React, { useState } from "react";

import {
    View,
    Text,
    TouchableOpacity,
    Image,
    ViewStyle,
    TextStyle,
    Platform
} from "react-native";
import Style from "../../utils/Style";
import SVGView from "../../utils/SvgView";
import AppTheme from "../../utils/Theme";
import * as Constants from "../../utils/Constants";

import { default as IcSelected } from "../../assets/svg/icons/solid/check.svg";
import Images from "../../../specific/utils/Images";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { EventRegister } from "../../utils/EventListeners";
import { addFilterToUser } from "../../../specific/services/Specific_Database";
import User from "../../data/user/User";

interface ActivityFilterByLevelProps {
    toggleFilters: () => void;
    sectionKey?: string;
    backgroundColor?: string;
    selected?: string[]
}

const ActivityFilterByLevel = (props: ActivityFilterByLevelProps) => {

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const user: User = User.getInstance();

    const { toggleFilters, backgroundColor = appTheme.daySkyColor, selected = ["cycle1", "cycle2", "cycle3"], sectionKey = "liliAtSchool" } = props;

    const [selectedFilters, setSelectedFilters] = useState<string[]>(selected);

    const filterToggled = (id_filter: string) => {
        const indexOfSelectedFilter = selectedFilters.indexOf(id_filter);
        let newFilterSelection = [...selectedFilters];
        if (indexOfSelectedFilter !== -1) {
            newFilterSelection.splice(indexOfSelectedFilter, 1);
        } else {
            newFilterSelection.push(id_filter);
        }
        if (newFilterSelection.length === 0) {
            newFilterSelection = ["cycle1", "cycle2", "cycle3"];
        }
        setSelectedFilters(newFilterSelection);
    }

    const viewWidth = Platform.OS === "web" ? appTheme.getBlockWidth() : appTheme.getFullAppWidth();
    const answerWidth = viewWidth - appTheme.pixelPerfect(20);
    const selectionButtonWidth = (answerWidth - appTheme.pixelPerfect(5)) / 2;
    const userTypeColor:string = user.profile === "family"  ? appTheme.homeColor : appTheme.schoolColor;
    const userTypeTextColor:string = user.profile === "family"  ? appTheme.darkBlue : appTheme.white;
    const mainBackgroundColor = appTheme.white;
    const titleTextColor = backgroundColor;
    const buttonBackgroundColor = mainBackgroundColor;
    const buttonTextColor = titleTextColor;

    const buttonStyle: ViewStyle = {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: buttonBackgroundColor,
        borderWidth: appTheme.pixelPerfect(0.5),
        borderColor: userTypeColor,
        minHeight: appTheme.pixelPerfect(40),
        width: answerWidth,
        borderRadius: appTheme.pixelPerfect(20),
        marginBottom: appTheme.pixelPerfect(10)
    }

    const buttonTextStyle: TextStyle = {
        fontFamily: appTheme.primarySemiBoldFont,
        fontSize: appTheme.pixelPerfectForFont(10),
        color: user.profile === "family"  ? appTheme.darkBlue : userTypeColor,
        textAlign: "center"
    }

    const validationButtonStyle: ViewStyle = {
        ...buttonStyle,
        borderWidth: 0,
        width: selectionButtonWidth
    }

    const reversedValidationButtonStyle: ViewStyle = {
        ...validationButtonStyle,
        backgroundColor: userTypeColor
    }

    const reversedButtonTextStyle: TextStyle = {
        ...buttonTextStyle,
        color: userTypeTextColor
    }

    const onValidateFilters = async () => {
        const user: User = User.getInstance();
        // On va les enregistrer dans la base de données
        await addFilterToUser({ user_id: user.uid, filters: selectedFilters });
        user.filter_by_level = selectedFilters;
        EventRegister.emitEvent(Constants.EventFiltersByLevelChanged, {});
        toggleFilters();
    }

    const getFilters = () => {
        let filtersChoices = [];
        for (const aFilterKey in Constants.filtres) {
            if (Object.prototype.hasOwnProperty.call(Constants.filtres, aFilterKey)) {
                const aFilterName = Constants.filtres[aFilterKey];
                const imageForFilter = aFilterKey === "cycle1" ? images.illStepFilterForAge46 : aFilterKey === "cycle2" ? images.illStepFilterForAge69 : images.illStepFilterForAge912;
                const backgroundColorForButton = selectedFilters.indexOf(aFilterKey) !== -1 ? userTypeColor : appTheme.white;
                const textColorForButton = user.profile === "family"  ? appTheme.darkBlue : selectedFilters.indexOf(aFilterKey) === -1 ? userTypeColor : appTheme.white;
                filtersChoices.push(<TouchableOpacity key={aFilterKey} onPress={() => filterToggled(aFilterKey)} style={[buttonStyle, { paddingHorizontal:appTheme.pixelPerfect(2), justifyContent:"flex-start", flexDirection:"row", backgroundColor: backgroundColorForButton }]}>
                    <Image source={imageForFilter} style={{width:appTheme.pixelPerfect(34), height:appTheme.pixelPerfect(34), borderRadius:appTheme.pixelPerfect(18)}} />
                    <Text style={[buttonTextStyle, {flex:1, paddingEnd:appTheme.pixelPerfect(20), color:textColorForButton}]}>{aFilterName}</Text>
                    <View style={{ position: "absolute", right: appTheme.pixelPerfect(10), opacity: selectedFilters.indexOf(aFilterKey) !== -1 ? 1 : 0 }}>
                        <SVGView Component={IcSelected} size={18} color={textColorForButton} />
                    </View>
                </TouchableOpacity>);
            }
        }
        return filtersChoices;
    }

    const justifyContent = Platform.OS === "web" ? "center" : "flex-end";
    return (<View style={{ flex: 1, justifyContent, alignItems: "center", backgroundColor: backgroundColor + "40" }}>
        <View style={[{ paddingTop: appTheme.pixelPerfect(40) }]}>
            <View style={[Style.shadowed, { backgroundColor: mainBackgroundColor, width: viewWidth, minHeight: appTheme.pixelPerfect(100), borderRadius: appTheme.pixelPerfect(10), alignItems: "center", paddingBottom:appTheme.bottomInsets }]}>
                <View style={[{ marginTop: -appTheme.pixelPerfect(40), marginBottom: appTheme.pixelPerfect(20), paddingHorizontal: appTheme.pixelPerfect(10), alignItems: "center", justifyContent: "center" }]}>
                    <View style={{ width: appTheme.pixelPerfect(80), height: appTheme.pixelPerfect(80), backgroundColor: appTheme.white, overflow:"hidden", borderWidth: appTheme.pixelPerfect(2), borderColor: appTheme.white, justifyContent: "center", alignItems: "center", borderRadius: appTheme.pixelPerfect(40) }}>
                        <Image source={images.illStepFilterByAge} style={{ width: appTheme.pixelPerfect(72), height: appTheme.pixelPerfect(72), borderRadius:appTheme.pixelPerfect(36) }} resizeMode="cover" />
                    </View>
                </View>
                <Text style={{ paddingHorizontal: appTheme.pixelPerfect(20), textAlign: "center", fontFamily: appTheme.primaryBoldFont, fontSize: appTheme.pixelPerfectForFont(10), color: titleTextColor, textTransform:"uppercase" }}>
                    {"Filtrez les activités par âge"}
                </Text>
                <View style={{ marginVertical: appTheme.pixelPerfect(20) }}>
                    {getFilters()}
                </View>
                <View style={{ marginVertical: appTheme.pixelPerfect(20), flexDirection: "row", justifyContent: "space-between", width: answerWidth }}>
                    <TouchableOpacity onPress={() => toggleFilters()} style={[Style.shadowed, validationButtonStyle]}>
                        <Text style={buttonTextStyle}>{"Annuler"}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => onValidateFilters()} style={[Style.shadowed, reversedValidationButtonStyle]}>
                        <Text style={reversedButtonTextStyle}>{"Valider"}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    </View>)
}

export default ActivityFilterByLevel;