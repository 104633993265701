import React from "react";

import CountDown from "../../sound/countdown.mp3";
import BipOne from "../../sound/bip_one.m4a";
import BipTwo from "../../sound/bip_two.m4a";
import GameOver from "../../sound/gameover.mp3";
import LiliBienvenue from "../../sound/lili_bienvenue.mp3";
import LiliJeMexprime from "../../sound/lili_je_m_exprime.mp3";
import LiliJingle from "../../sound/lili_jingle.mp3";
import LiliMeteo from "../../sound/lili_meteo.mp3";
import PacMan from "../../sound/pacman.mp3";
import Tac from "../../sound/tac.mp3";
import Tic from "../../sound/tic.mp3";
import TicTac from "../../sound/tictac.mp3";
import Time from "../../sound/time.mp3";

const soundArray = {
    "countdown.mp3": CountDown,
    "bip_one.m4a": BipOne,
    "bip_two.m4a": BipTwo,
    "gameover.mp3": GameOver,
    "lili_bienvenue.mp3": LiliBienvenue,
    "lili_jingle.mp3": LiliJingle,
    "lili_je_m_exprime.mp3": LiliJeMexprime,
    "lili_meteo.mp3": LiliMeteo,
    "pacman.mp3": PacMan,
    "tac.mp3": Tac,
    "tic.mp3": Tic,
    "tictac.mp3": TicTac,
    "time.mp3": Time
}

export const playSound = async (source:any, origin:string) => {
    let audioSource = source;
    if (origin === "app") {
        audioSource = soundArray[source];
    }
    const audio = new Audio(audioSource);
    audio.play();
}