import React from "react";
import { useState, useEffect, useRef } from "react";
import * as Navigation from "../../../specific/utils/Navigation/Navigation";
import Main from "../../../specific/components/Main/Main";
import i18n from "../../utils/Localization/Localization";

import Image from "../../designSystem/OTBImage";

import {
    View,
    Text,
    Platform,
    StyleSheet,
    ScrollView,
    LayoutChangeEvent
} from 'react-native';

import AppTheme, { Theme } from "../../utils/Theme";
import * as Constants from "../../utils/Constants";
import MainOptions from "../../models/MainOptions";

// UI
import Button from "../../designSystem/Button";
import TextInput from "../../designSystem/TextInput";

// Assets
import Images from "../../../specific/utils/Images";

// Services
import { revokePassword } from "../../services/Auth";
import Alert from "../Notification/Alert";

import { useNavigate, useParams } from "react-router-dom";
import DynamicBackground from "../DynamicBackground/DynamicBackground";

const ForgotPassword = (props: any) => {

    let propsData = props;
    let navigate: any = null;
    if (Platform.OS === "web") {
        propsData = useParams();
        navigate = useNavigate();
    }

    const { context } = propsData;

    const [isLoading, setIsLoading] = useState(true);
    const [isTeacher, setIsTeacher] = useState(false);
    const [emailAddress, setEmailAddress] = useState(propsData.emailAddress);
    const [password, setPassword] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertAttributes, setAlertAttributes] = useState({ title: "", body: "" });

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const alertRef = useRef<Alert>(null);
    const [screenHeight, setScreenHeight] = useState(appTheme.getFullScreenHeight);

    const { componentId } = props;
    

    // ComponentDidMount
    useEffect(() => {

    }, []);

    const goBack = () => {
        Navigation.pop({
            componentId,
            navigate
        });
    }

    const resetPassword = () => {
        setIsLoading(true);
        if (emailAddress.length > 0) {
            revokePassword({
                email: emailAddress
            }).then(() => {
                setIsLoading(false);
                let title = "Modification de mot de passe";
                let body = "Un e-mail vient de vous être envoyé pour réinitialiser votre mot de passe";
                alertRef.current?.showAlert({
                    title,
                    body,
                    onPressPrimaryButton: goBack
                });
            }).catch((error) => {
                setIsLoading(false);
                let title = "Une erreur est survenue";
                let body = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
                if (error.toString().indexOf("auth/user-disabled") !== -1) {
                    title = "Compte désactivé";
                    body = "Votre compte a été désactivé.\nN'hésitez pas à nous contacter : support@lili.cool";
                } else if ((error.toString().indexOf("auth/invalid-email") !== -1) || (error.toString().indexOf("auth/wrong-password") !== -1) || (error.toString().indexOf("auth/user-not-found") !== -1)) {
                    title = "Adresse e-mail ou mot de passe erronée";
                    body = "Veuillez vérifier votre adresse e-mail et votre mot de passe s'il vous plaît.\n\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\"";
                } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                    title = "Erreur de connexion 📡";
                    body = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît."
                }
                alertRef.current?.showAlert({
                    title,
                    body
                });
            });
        } else {
            setIsLoading(false);
            alertRef.current?.showAlert({
                title: "Erreur sur votre saisie",
                body: "L'e-mail ne peut pas être vide."
            });
        }
        
    }

    const onChangeEmailAddress = (emailAddress: string) => {
        setEmailAddress(emailAddress);
    }

    const getHeadeImage = () => {
        if (context === Constants.CONTEXT.teacher) {
            return images.icLiliAtSchoolCircle;
        }
        return images.icLiliAtHomeCircle;
    }

    const getNavigationColor = () => {
        if (context === Constants.CONTEXT.teacher) {
            return appTheme.schoolBackgroundColor;
        }
        return appTheme.homeBackgroundColor;
    }

    const getTextColor = () => {
        if (context === Constants.CONTEXT.teacher) {
            return appTheme.darkBlue;
        }
        return appTheme.getParentTextColor();
    }

    const calibrateHeight = (layoutEvent: LayoutChangeEvent) => {
        setScreenHeight(layoutEvent.nativeEvent.layout.height);
    }

    const mainOptions: MainOptions = new MainOptions({
        key:"forgot_password",
        componentId,
        showNavBar: false,
        showMenu: false,
        safeArea: true,
        canGoBack: true,
        navigationColor: getNavigationColor()
    });

    return (
        <Main mainOptions={mainOptions}>
            <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), position: "absolute" }}>
                <DynamicBackground
                    isTeacher={context === Constants.CONTEXT.teacher}
                    onDesignSet={(data: { backgroundColor: string, itemsColor: string }) => {
                    }} />
            </View>
            <ScrollView
                showsVerticalScrollIndicator={false}
                onLayout={calibrateHeight}
                style={{ flex: 1 }}>
                <View style={{ width: appTheme.getFullAppWidth(), height: screenHeight }}>
                    <View style={[styles.main]}>
                        <View style={{ width: appTheme.getFullAppWidth(), flex: 1 }}>
                            <View style={{ alignItems: "center", justifyContent: "center", paddingHorizontal: appTheme.pixelPerfect(20), paddingTop: appTheme.pixelPerfect(20), flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                                <Image source={getHeadeImage()} style={{width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(70)}} resizeMode="contain" />
                                <Text style={{ textAlign: "center", fontSize: appTheme.pixelPerfectForFont(11), fontFamily: appTheme.primaryFont, fontWeight: "600", color: getTextColor(), marginBottom: appTheme.pixelPerfect(10), paddingTop: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.pixelPerfect(20) }}>
                                    {i18n.t("authentication.forgotPassword.title").toLocaleUpperCase()}
                                </Text>
                            </View>
                            <View style={{ flex: 1 }}>
                                <ScrollView
                                    showsVerticalScrollIndicator={false}
                                    style={{ flex: 1 }}>
                                    <View style={{ flex: 1, alignItems: "center", justifyContent: "center", marginTop: appTheme.pixelPerfect(10) }}>
                                        <View style={{ width: appTheme.getBlockWidth(), alignItems: "center", justifyContent: "center" }}>
                                            <View style={{
                                                borderRadius: appTheme.pixelPerfect(20),
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginTop: appTheme.pixelPerfect(20),
                                                width: appTheme.getBlockWidth(),
                                                backgroundColor: appTheme.white,
                                                paddingVertical: appTheme.pixelPerfect(20)
                                            }}>
                                                <View style={{ marginBottom: appTheme.pixelPerfect(10) }}>
                                                    <TextInput
                                                        label={i18n.t("authentication.signIn.form.emailPlaceholder")}
                                                        style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color:appTheme.darkBlue }]}
                                                        onChangeText={onChangeEmailAddress}
                                                        value={emailAddress}
                                                        keyboardType="email-address"
                                                        autoCapitalize="none"
                                                    />
                                                </View>
                                                <View style={{ marginTop: appTheme.pixelPerfect(20) }}>
                                                    <Button
                                                        onPress={resetPassword}
                                                        style={{ width: appTheme.pixelPerfect(140), backgroundColor: context === Constants.CONTEXT.teacher ? appTheme.schoolColor : appTheme.homeColor }}
                                                        title={i18n.t("authentication.forgotPassword.buttons.send")} />
                                                </View>
                                            </View>
                                            <Image source={images.icBird} style={{ position: "absolute", top: -appTheme.pixelPerfect(8), right: 0, width: appTheme.pixelPerfect(75), height: appTheme.pixelPerfect(72) }} resizeMode="contain" />
                                        </View>
                                    </View>
                                </ScrollView>
                            </View>
                        </View>
                        <Alert ref={alertRef} />
                    </View>
                </View>
            </ScrollView>
        </Main>
    )
}

export default ForgotPassword;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});