import React from "react";
import { useState, useEffect } from "react";
import {
    View,
    StyleSheet,
    Modal,
    Text,
    TouchableOpacity,
    Platform
} from 'react-native';
import OtbImage from "../../../specific/utils/OtbImage/OtbImage";

import Style from "../../utils/Style";
import SVGView from "../../utils/SvgView";
import { default as IcEye } from "../../assets/svg/icons/solid/eye.svg";
import { default as IcEyeSlash } from "../../assets/svg/icons/solid/eye-slash.svg";
import AppTheme from "../../utils/Theme";
import { checkLocalUrl } from "../../utils/LocalStorage";
import { EventRegister } from "../../utils/EventListeners";

const TutorialLargeImageView = (props: any) => {

    const [showLegend, setShowLegend] = useState(true);

    useEffect(() => {
        EventRegister.addEventListener("showLegend", (data:{displayLegend:boolean}) => {
            setShowLegend(data.displayLegend);
        });
        return () => {
            EventRegister.removeEventListener("showLegend");
        };
        
    }, []);

    const toogleTextView = () => {
        EventRegister.emitEvent("showLegend", {displayLegend:!showLegend});
    }

    const appTheme: AppTheme = new AppTheme();
    const { largeImageKey, uri, height, width, color, legend } = props;

    const fullScreenWidth = Platform.OS === "web" ? appTheme.getFullScreenWidth() : appTheme.getFullAppHeight();
    const legendView = showLegend === true ? <Text style={{ color: appTheme.white, textAlign: 'center', fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(14), flex: 1, paddingHorizontal: appTheme.pixelPerfect(10) }}>{legend}</Text> : <View style={{ flex: 1 }} />;
    const legendBackgroundColor = showLegend === true ? appTheme.darkBlue : 'transparent';
    const legendToogleIcon = showLegend === true ? IcEyeSlash : IcEye;
    return <View style={{ width: fullScreenWidth, alignItems: "center" }} key={largeImageKey}>
        <OtbImage resizeMode='contain' source={{ uri: checkLocalUrl(uri) }} style={{ height, width }} />
        <View style={{ position: 'absolute', left: 0, top: 0, width, height, justifyContent: 'flex-end', paddingBottom: appTheme.pixelPerfect(10), marginLeft: appTheme.pixelPerfect(40) }}>
            <View style={{ flexDirection: 'row', backgroundColor: legendBackgroundColor, width: width - appTheme.pixelPerfect(80), alignItems: 'center', borderRadius: appTheme.pixelPerfect(40) }}>
                {legendView}
                <TouchableOpacity onPress={toogleTextView} style={[Style.shadowed, { justifyContent: 'center', alignItems: 'center', width: appTheme.pixelPerfect(40), height: appTheme.pixelPerfect(40), borderRadius: appTheme.pixelPerfect(20), backgroundColor: appTheme.white }]}>
                    <SVGView Component={legendToogleIcon} width={appTheme.pixelPerfect(32)} height={appTheme.pixelPerfect(32)} color={color} />
                </TouchableOpacity>
            </View>
        </View>
    </View>
}

export default TutorialLargeImageView;