import md5 from "md5";
import base64 from "base-64";

export const btoa = (text:string) => {
    const encodedData = base64.encode(text);
    return encodedData;
}

export const stringToMd5 = (text:string) => {
    md5(text);
}