import React from "react";
import { useState, useEffect, useRef } from "react";
import * as Navigation from "../../../../specific/utils/Navigation/Navigation";

import {
    View,
    Text,
    ActivityIndicator,
    Platform,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    SafeAreaView,
    FlatList,
    Modal,
    Linking
} from 'react-native';

import AppTheme from "../../../utils/Theme";
import * as Constants from "../../../utils/Constants";

// Assets
import Images from "../../../../specific/utils/Images";

// Services
import { createUserWithEmailAndPassword } from "../../../services/Auth";
import { getSchoolId, addDataToUser } from "../../../services/Database";


import i18n from "../../../utils/Localization/Localization";

// UI
import Button from "../../../designSystem/Button";
import RadioButton from "../../../designSystem/RadioButton";
import TextInput from "../../../designSystem/TextInput";
import Image from "../../../designSystem/OTBImage";

import * as Mailing from "../../../services/Mailing";

// SVG
import SVGView from "../../../utils/SvgView";
import { default as IcSearch } from "../../../assets/svg/icons/solid/search.svg";
import { default as IcAngleRight } from "../../../assets/svg/icons/solid/angle-right.svg";
import Alert from "../../Notification/Alert";

import { useNavigate, useParams } from "react-router-dom";
import User from "../../../data/user/User";
import { analyticsEventSignInCompleted, propertiesEventTeacherDepartment, propertiesEventTeacherGradeLevel, trackEvent } from "../../../services/Tracking/Analytics";

const TeacherSignUp = (props: any) => {

    let propsData = props;
    if (Platform.OS === "web") {
        propsData = useParams();
    }
    let navigate: any = null;
    if (Platform.OS === "web") {
        navigate = useNavigate();
    }

    const { componentId } = propsData;

    const [isLoading, setIsLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [newsletter, setNewsletter] = useState(false);
    const [schoolsList, setSchoolsList] = useState([]);
    const [showSchoolsList, setShowSchoolsList] = useState(false);
    const [schoolName, setSchoolName] = useState("");
    const [schoolAddress, setSchoolAddress] = useState("");
    const [schoolZipCode, setSchoolZipCode] = useState("");
    const [schoolCity, setSchoolCity] = useState("");
    const [schoolCountry, setSchoolCountry] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [classes, setClasses] = useState<String[]>([]);
    const [step, setStep] = useState(0);
    const [userId, setUserId] = useState("");

    const appTheme: AppTheme = new AppTheme();
    const images: Images = new Images();
    const alertRef = useRef<Alert>(null);

    const titleStyle = {
        textAlign: "center",
        fontSize: appTheme.pixelPerfectForFont(11),
        fontWeight: "700",
        letterSpacing: 0.8
    };

    let blockWidthMax = appTheme.pixelPerfect(480);
    let blockWidth = appTheme.getFullAppWidth() - appTheme.pixelPerfect(40);
    if (blockWidth > blockWidthMax) {
        blockWidth = blockWidthMax;
    }

    let fontSizefactor = (appTheme.getFullAppWidth() - appTheme.pixelPerfect(40)) / blockWidth;
    if (fontSizefactor < 1) {
        fontSizefactor = 1;
    } else if (fontSizefactor > 1.5) {
        fontSizefactor = 1.5;
    }

    const pixelPerfectForOnBoarding = (size: number) => {
        return appTheme.pixelPerfect(size) * fontSizefactor;
    }

    useEffect(() => {
        if (props.onStepChange !== undefined) {
            props.onStepChange(step);
        }
    }, [step]);

    // MainOptions
    const onRadioButtonPress = (data: { value: boolean }) => {
        setNewsletter(data.value);
    }

    const onSignUpRequest = async () => {
        let canGoForward: boolean = false;
        let errrorTitle = "";
        let errorBody = "";
        if (step === 0) {
            if ((emailAddress.length > 4) && (password.length > 5) && (firstname.length > 0) && (lastname.length > 0)) {
                canGoForward = true;
            } else {
                errrorTitle = "Veuillez vérifier les champs suivants :";
                if (firstname.length === 0) {
                    errorBody = "‣ votre prénom"
                }
                if (lastname.length === 0) {
                    if (errorBody.length > 0) {
                        errorBody = errorBody + '\n';
                    }
                    errorBody = errorBody + "‣ votre nom"
                }
                if (emailAddress.length < 5) {
                    if (errorBody.length > 0) {
                        errorBody = errorBody + '\n';
                    }
                    errorBody = errorBody + "‣ votre e-mail"
                }
                if (password.length < 8) {
                    if (errorBody.length > 0) {
                        errorBody = errorBody + '\n';
                    }
                    errorBody = errorBody + "‣ votre mot de passe"
                }
            }
            if (canGoForward === true) {
                try {
                    setIsLoading(true);
                    const emailToRecord = emailAddress.toLowerCase().trim();
                    const userSession: User = await createUserWithEmailAndPassword({
                        context: Constants.CONTEXT.teacher,
                        email: emailToRecord,
                        password,
                        options: {
                            firstname,
                            lastname,
                            optInCoachNewsLetter: false,
                            optInNewsletter: newsletter
                        }
                    });
                    // Si l'utilisateur a accepté de recevoir des e-mails, on l'ajoute à la base
                    Mailing.addContactToGeneralAudience({
                        email: emailToRecord,
                        context: Constants.CONTEXT.teacher,
                        optIn: newsletter,
                        optInCoach: false
                    });
                    if ((userSession !== undefined) && (userSession.uid !== undefined)) {
                        setIsLoading(false);
                        setUserId(userSession.uid);
                        setStep(1);
                    } else {
                        setIsLoading(false);
                        alertRef.current?.showAlert({
                            title: "Une erreur est survenue",
                            body: "Veuillez réessayer plus tard s'il vous plaît."
                        });
                    }
                } catch (error) {
                    setIsLoading(false);
                    let title = "Une erreur est survenue";
                    let body = "Veuillez réessayer plus tard s'il vous plaît.\nSi l'erreur persiste, n'hésitez pas à nous contacter : support@lili.cool";
                    if (error.toString().indexOf("auth/email-already-in-use") !== -1) {
                        title = "Utilisateur existant";
                        body = "Votre adresse e-mail est déjà reliée à un compte de LILI.\nSi vous avez oublié votre mot de passe, vous pouvez le retrouver via le lien \"Mot de passe oublié\" dans la page \"DÉJÀ INSCRIT(E)\"";
                    } else if (error.toString().indexOf("auth/invalid-email") !== -1) {
                        title = "Adresse e-mail erronée";
                        body = "Veuillez vérifier votre adresse e-mail s'il vous plaît.";
                    } else if (error.toString().indexOf("auth/weak-password") !== -1) {
                        title = "Mot de passe trop faible";
                        body = "Veuillez saisir au moins 6 caractères pour votre mot de passe.";
                    } else if (error.toString().indexOf("auth/network-request-failed") !== -1) {
                        title = "Erreur de connexion 📡";
                        body = "Vérifiez votre connexion internet pour vous connecter à votre compte s'il vous plaît."
                    }
                    alertRef.current?.showAlert({
                        title,
                        body
                    });
                }
            } else {
                setIsLoading(false);
                alertRef.current?.showAlert({
                    title: errrorTitle,
                    body: errorBody
                });
            }
        } else if (step === 1) {
            setStep(2);
        } else if (step === 2) {
            if ((schoolAddress.length > 0) && (schoolCity.length > 0) && (schoolCountry.length > 0) && (schoolName.length > 0) && (schoolZipCode.length > 0)) {
                canGoForward = true;
            } else {
                errrorTitle = "Veuillez vérifier les informations suivantes sur votre établissement :";
                if (schoolName.length === 0) {
                    errorBody = "‣ son nom"
                }
                if (schoolAddress.length === 0) {
                    if (errorBody.length > 0) {
                        errorBody = errorBody + '\n';
                    }
                    errorBody = errorBody + "‣ son adresse"
                }
                if (schoolZipCode.length === 0) {
                    if (errorBody.length > 0) {
                        errorBody = errorBody + '\n';
                    }
                    errorBody = errorBody + "‣ son code postal"
                }
                if (schoolCity.length === 0) {
                    if (errorBody.length > 0) {
                        errorBody = errorBody + '\n';
                    }
                    errorBody = errorBody + "‣ sa ville"
                }
                if (schoolCountry.length === 0) {
                    if (errorBody.length > 0) {
                        errorBody = errorBody + '\n';
                    }
                    errorBody = errorBody + "‣ son pays"
                }
            }
            if (canGoForward === true) {
                // On va enregistrer l'école ou récupérer son ID si elle existe déjà
                setIsLoading(true);
                const schoolId = await getSchoolId({
                    name: schoolName,
                    address: schoolAddress,
                    zipcode: schoolZipCode,
                    city: schoolCity,
                    country: schoolCountry
                });
                await addDataToUser({
                    key: "school_id",
                    value: schoolId,
                    user_id: userId
                });
                setIsLoading(false);
                setStep(3);
            } else {
                setIsLoading(false);
                alertRef.current?.showAlert({
                    title: errrorTitle,
                    body: errorBody
                });
            }
        } else if (step === 3) {
            if (classes.length > 0) {
                canGoForward = true;
            } else {
                errrorTitle = "Veuillez indiquer dans quelle(s) classe(s) vous enseignez s'il vous plaît ?";
            }
            if (canGoForward === true) {
                setIsLoading(true);
                // Analytics - Récupération du département
                const departmentNumber = schoolZipCode.length === 4 ? schoolZipCode.substring(0, 1) : schoolZipCode.substring(0, 2);
                trackEvent(analyticsEventSignInCompleted, { profile:"teacher", [propertiesEventTeacherGradeLevel]: JSON.stringify(classes), [propertiesEventTeacherDepartment]: departmentNumber })
                await addDataToUser({
                    key: "classes",
                    value: classes,
                    user_id: userId
                });
                // Ajouter un tag si l'utilisateur est directeur
                const isDirector = classes.indexOf("direction") !== -1 ? true : false;
                setIsLoading(false);
                setStep(4);
            } else {
                setIsLoading(false);
                alertRef.current?.showAlert({
                    title: errrorTitle,
                    body: errorBody
                });
            }
        } else if (step === 4) {
            setIsLoading(false);
            Navigation.relaunchApp({
                componentId,
                navigate
            });
        }

    }

    const openGeneralConditions = () => {
        if (Platform.OS === "web") {
            let uri = "https://www.lili.cool/fr/mentions-legales/";
            Linking.openURL(uri);
        } else {
            Navigation.showModalInView({
                componentId,
                name: Constants.ScreenCookiesPolicy,
                passProps: {
                    uri: "https://www.lili.cool/fr/mentions-legales/"
                },
                options: {
                    modalPresentationStyle: 'overCurrentContext',
                    layout: {
                        componentBackgroundColor: 'transparent'
                    }
                }
            });
        }

    }

    const showSignInScreen = () => {
        if (props.onFlipRequest !== undefined) {
            props.onFlipRequest();
        }
    }

    const onSchoolSearchRequest = async () => {
        setSearchIsLoading(true);
        const url = "https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-annuaire-education&q=" + searchTerm + "&rows=50&facet=identifiant_de_l_etablissement&facet=nom_etablissement&facet=type_etablissement&facet=statut_public_prive&facet=code_postal&facet=code_commune&facet=nom_commune&facet=code_departement&facet=code_academie&facet=code_region&facet=ecole_maternelle&facet=ecole_elementaire&facet=voie_generale&facet=voie_technologique&facet=voie_professionnelle&facet=restauration&facet=hebergement&facet=ulis&facet=apprentissage&facet=segpa&facet=section_arts&facet=section_cinema&facet=section_theatre&facet=section_sport&facet=section_internationale&facet=section_europeenne&facet=lycee_agricole&facet=lycee_militaire&facet=lycee_des_metiers&facet=post_bac&facet=appartenance_education_prioritaire&facet=greta&facet=type_contrat_prive&facet=libelle_departement&facet=libelle_academie&facet=libelle_region&facet=nom_circonscription&facet=precision_localisation&facet=etat&facet=ministere_tutelle&facet=multi_uai&facet=rpi_concentre&facet=rpi_disperse&facet=code_nature&facet=libelle_nature&facet=code_type_contrat_prive&facet=pial";
        try {
            const listResult = await fetch(url, {
                method: "GET",
                headers: {
                    "Accept": "application/json"
                }
            });
            let jsonResults = await listResult.json();
            if (jsonResults.records !== undefined) {
                jsonResults = jsonResults.records;
            } else {
                jsonResults = [];
            }
            setSchoolsList(jsonResults);
            setShowSchoolsList(true);
            setSearchIsLoading(false);
        } catch (error) {
            //throw error;
        }
    }

    const toggleClasses = (value: string) => {
        let teacherClasses = classes;
        const valueIndex = teacherClasses.indexOf(value);
        if (valueIndex !== -1) {
            teacherClasses.splice(valueIndex, 1);
        } else {
            teacherClasses.push(value);
        }
        setClasses(teacherClasses);
    }



    const getContentByStep = () => {
        if (step === 0) {
            const formInputsViewStyle = Platform.OS === "web" ? { flex: 1, paddingHorizontal: appTheme.pixelPerfect(10), justifyContent: "flex-start", alignItems: "flex-start" } : { paddingHorizontal: appTheme.pixelPerfect(10), justifyContent: "flex-start", alignItems: "flex-start" };
            const inputWidth = appTheme.getBlockWidth() - appTheme.pixelPerfect(20);
            return (<View style={{ marginBottom: appTheme.pixelPerfect(10), paddingTop: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth(), alignItems: "center" }}>
                <View style={{ flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                    <View style={formInputsViewStyle}>
                        <TextInput
                            key="firstname"
                            label={i18n.t("authentication.signUp.form.firstnamePlaceholder")}
                            style={[{ borderColor: appTheme.secondaryColor, width: inputWidth, color: appTheme.darkBlue }]}
                            onChangeText={(text) => setFirstname(text)}
                            value={firstname}
                            keyboardType="default"
                            autoCapitalize="words"
                        />
                        <TextInput
                            key="lastname"
                            label={i18n.t("authentication.signUp.form.lastnamePlaceholder")}
                            style={[{ borderColor: appTheme.secondaryColor, width: inputWidth, color: appTheme.darkBlue }]}
                            onChangeText={(text) => setLastname(text)}
                            value={lastname}
                            keyboardType="default"
                            autoCapitalize="words"
                        />
                    </View>
                    <View style={formInputsViewStyle}>
                        <TextInput
                            key="email"
                            label={i18n.t("authentication.signUp.form.emailPlaceholder")}
                            style={[{ borderColor: appTheme.secondaryColor, width: inputWidth, color: appTheme.darkBlue }]}
                            onChangeText={(text) => setEmailAddress(text)}
                            value={emailAddress}
                            keyboardType="email-address"
                            autoCapitalize="none"
                        />
                        <TextInput
                            key="password"
                            label={i18n.t("authentication.signUp.form.passwordPlaceholder")}
                            password={true}
                            style={[{ borderColor: appTheme.secondaryColor, width: inputWidth, color: appTheme.darkBlue }]}
                            onChangeText={(text) => setPassword(text)}
                            value={password}
                            secureTextEntry={true}
                            autoCapitalize="none"
                            legend={i18n.t("authentication.signUp.form.passwordLegend")}
                        />
                    </View>
                </View>
                <View style={{ width: appTheme.getBlockWidth(), alignItems: "center", marginTop: appTheme.pixelPerfect(10) }}>
                    <RadioButton
                        value={newsletter}
                        onPress={onRadioButtonPress}
                        style={{ width: appTheme.pixelPerfect(200) }}>
                        <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue }}>
                            {i18n.t("authentication.signUp.form.radioButtons.newsletter")}
                        </Text>
                    </RadioButton>
                </View>
            </View>)
        } else if (step === 1) {
            return (<View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1, paddingTop: appTheme.pixelPerfect(10) }}>
                <Text style={[titleStyle, { textAlign: "left", color: appTheme.schoolColor, fontFamily: appTheme.primaryFont, paddingTop: appTheme.pixelPerfect(10) }]}>
                    {i18n.t("authentication.signUp.form.schoolSearchEngineTitle").toLocaleUpperCase()}
                </Text>
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <TextInput
                        key="schoolNameSearch"
                        label={i18n.t("authentication.signUp.form.schoolByZipCodePlaceholder")}
                        style={{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(180), color: appTheme.darkBlue }}
                        onChangeText={(text) => setSearchTerm(text)}
                        value={searchTerm}
                        keyboardType="default"
                        autoCapitalize="words" />
                    <TouchableOpacity onPress={onSchoolSearchRequest} style={{ marginLeft: appTheme.pixelPerfect(10), width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), justifyContent: "center", alignItems: "center", backgroundColor: appTheme.schoolColor, borderRadius: appTheme.pixelPerfect(20) }}>
                        <SVGView Component={IcSearch} width={appTheme.pixelPerfect(16)} height={appTheme.pixelPerfect(16)} color={appTheme.white} />
                    </TouchableOpacity>
                </View>
            </View>);
        } else if (step === 2) {
            return (<View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1 }}>
                <Text style={[titleStyle, { textAlign: "left", color: appTheme.schoolColor, fontFamily: appTheme.primaryFont, paddingTop: appTheme.pixelPerfect(10) }]}>
                    {i18n.t("authentication.signUp.form.schoolFormTitle").toLocaleUpperCase()}
                </Text>
                <TextInput
                    key="schoolName"
                    label={i18n.t("authentication.signUp.form.schoolNamePlaceholder")}
                    style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                    onChangeText={(text) => setSchoolName(text)}
                    value={schoolName}
                    keyboardType="default"
                    autoCapitalize="words" />
                <TextInput
                    label={i18n.t("authentication.signUp.form.schoolAddressPlaceholder")}
                    style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                    onChangeText={(text) => setSchoolAddress(text)}
                    value={schoolAddress}
                    keyboardType="default"
                    autoCapitalize="words" />
                <TextInput
                    label={i18n.t("authentication.signUp.form.schoolZipCodePlaceholder")}
                    style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                    onChangeText={(text) => setSchoolZipCode(text)}
                    value={schoolZipCode}
                    keyboardType="default"
                    autoCapitalize="words" />
                <TextInput
                    label={i18n.t("authentication.signUp.form.schoolCityPlaceholder")}
                    style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                    onChangeText={(text) => setSchoolCity(text)}
                    value={schoolCity}
                    keyboardType="default"
                    autoCapitalize="words" />
                <TextInput
                    label={i18n.t("authentication.signUp.form.schoolCountryPlaceholder")}
                    style={[{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }]}
                    onChangeText={(text) => setSchoolCountry(text)}
                    value={schoolCountry}
                    keyboardType="default"
                    autoCapitalize="words" />
            </View>);
        } else if (step === 3) {
            let choicesForm = [];
            let choices = [
                {
                    key: "kindergarten",
                    value: i18n.t("authentication.signUp.form.radioButtons.classes.kindergarten")
                },
                {
                    key: "elementary",
                    value: i18n.t("authentication.signUp.form.radioButtons.classes.elementary")
                },
                {
                    key: "middleschool",
                    value: i18n.t("authentication.signUp.form.radioButtons.classes.middleschool")
                },
                {
                    key: "highschool",
                    value: i18n.t("authentication.signUp.form.radioButtons.classes.highschool")
                },
                {
                    key: "direction",
                    value: i18n.t("authentication.signUp.form.radioButtons.classes.direction")
                }
            ];
            for (const aChoiceIndex in choices) {
                if (Object.prototype.hasOwnProperty.call(choices, aChoiceIndex)) {
                    const aChoice = choices[aChoiceIndex];
                    choicesForm.push(<RadioButton
                        onPress={() => toggleClasses(aChoice.key)}
                        style={{ width: appTheme.pixelPerfect(220), marginBottom: appTheme.pixelPerfect(10) }}>
                        <Text style={{ flex: 1, fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(10), color: appTheme.darkBlue }}>
                            {aChoice.value}
                        </Text>
                    </RadioButton>);
                }
            }
            return (<View style={{ marginBottom: appTheme.pixelPerfect(10), flex: 1 }}>
                <Text style={[titleStyle, { textAlign: "left", color: appTheme.schoolColor, fontFamily: appTheme.primaryFont, paddingVertical: appTheme.pixelPerfect(10) }]}>
                    {i18n.t("authentication.signUp.form.classesFormTitle").toLocaleUpperCase()}
                </Text>
                {choicesForm}
            </View>);
        }
    }

    // Texte à afficher dans le bouton d'action du formulaire
    const getButtonContentByStep = () => {
        let buttonTitle = i18n.t("authentication.signUp.buttons.steps");
        if (step === 1) {
            buttonTitle = i18n.t("authentication.signUp.buttons.thisWay");
        } else if (step === 3) {
            buttonTitle = i18n.t("authentication.signUp.buttons.signUp");
        } else if (step === 4) {
            buttonTitle = i18n.t("authentication.signUp.buttons.ok");
        }
        if (searchIsLoading === true) {
            return <ActivityIndicator color={appTheme.schoolColor} />
        }
        return <Button
            isLoading={isLoading}
            disabled={searchIsLoading}
            onPress={onSignUpRequest}
            style={{ width: appTheme.pixelPerfect(200), backgroundColor: appTheme.schoolColor }}
            title={buttonTitle} />
    }

    // Condition pour afficher les mentions légales
    const getLegalMentionByStep = () => {
        if (step === 0) {
            return <TouchableOpacity onPress={openGeneralConditions} style={{ marginTop: appTheme.pixelPerfect(10) }}>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "center", color: appTheme.darkBlue }}>
                    {i18n.t("authentication.signUp.buttons.cgu.firstPart")}
                </Text>
                <Text style={{ fontFamily: appTheme.secondaryFont, fontSize: appTheme.pixelPerfectForFont(9), textAlign: "center", textDecorationLine: "underline", color: appTheme.darkBlue }}>
                    {i18n.t("authentication.signUp.buttons.cgu.secondPart")}
                </Text>
            </TouchableOpacity>;
        }
        return <View />
    }

    // Condition pour afficher le bouton permettant de basculer vers la connexion
    const signInButton = <Button
        onPress={showSignInScreen}
        textStyle={{ color: appTheme.darkBlue }}
        link={true}
        title={i18n.t("authentication.signUp.buttons.signIn")} />;
    const signInUpButton = step === 0 ? <View style={{ width: appTheme.getFullAppWidth(), alignItems: "flex-end", height: appTheme.pixelPerfect(40), justifyContent: "center", paddingEnd: appTheme.pixelPerfect(10) }}>{signInButton}</View> : <View />;

    // Condition pour afficher le bouton permettant de basculer vers la connexion
    const positionIndicatorCircleStyle = {
        width: appTheme.pixelPerfect(20),
        height: appTheme.pixelPerfect(20),
        borderRadius: appTheme.pixelPerfect(10),
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center'
    };
    const positionIndicatorTextStyle = {
        fontSize: appTheme.pixelPerfectForFont(10),
        fontWeight: "500"
    };
    const getStepIndicatorByStep = () => {
        if (step === 4) {
            return <View />;
        }
        let stepsBackgroundColors = [appTheme.schoolColor, appTheme.schoolBackgroundColor, appTheme.schoolBackgroundColor];
        let stepsColors = [appTheme.schoolBackgroundColor, appTheme.schoolColor, appTheme.schoolColor];
        if ((step === 1) || (step === 2)) {
            stepsBackgroundColors = [appTheme.schoolColor, appTheme.schoolColor, appTheme.schoolBackgroundColor];
            stepsColors = [appTheme.schoolBackgroundColor, appTheme.schoolBackgroundColor, appTheme.schoolColor];
        } else if (step === 3) {
            stepsBackgroundColors = [appTheme.schoolColor, appTheme.schoolColor, appTheme.schoolColor];
            stepsColors = [appTheme.schoolBackgroundColor, appTheme.schoolBackgroundColor, appTheme.schoolBackgroundColor];
        }
        return <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <View style={[positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[0], borderColor: appTheme.schoolColor }]}>
                <Text style={[positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[0] }]}>1</Text>
            </View>
            <View style={{ width: appTheme.pixelPerfect(20), height: 1, backgroundColor: appTheme.schoolColor }} />
            <View style={[positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[1], borderColor: appTheme.schoolColor }]}>
                <Text style={[positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[1] }]}>2</Text>
            </View>
            <View style={{ width: appTheme.pixelPerfect(20), height: 1, backgroundColor: appTheme.schoolColor }} />
            <View style={[positionIndicatorCircleStyle, { backgroundColor: stepsBackgroundColors[2], borderColor: appTheme.schoolColor }]}>
                <Text style={[positionIndicatorTextStyle, { fontFamily: appTheme.primaryFont, color: stepsColors[2] }]}>3</Text>
            </View>
        </View>
    }

    const selectSchool = (item) => {
        setSchoolName(item.fields.nom_etablissement);
        setSchoolAddress(item.fields.adresse_1);
        setSchoolZipCode(item.fields.code_postal);
        setSchoolCity(item.fields.nom_commune);
        setSchoolCountry("France");
        setStep(2);
        setShowSchoolsList(false);
    }

    const enterSchool = () => {
        setSchoolName("");
        setSchoolAddress("");
        setSchoolZipCode("");
        setSchoolCity("");
        setStep(2);
        setShowSchoolsList(false);
    }

    const getLoadingView = () => {
        if (searchIsLoading === true) {
            return <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullScreenHeight(), justifyContent: "center", alignItems: "center", backgroundColor: appTheme.white + "99", position: "absolute" }}>
                <ActivityIndicator color={appTheme.schoolColor} />
            </View>
        }
        return <View />;
    }

    const getSignUpHeader = () => {
        if (step === 4) {
            return <View />;
        }
        let signUpHeader = [];
        signUpHeader.push(<Image source={images.icLiliAtSchoolCircle} style={{ width: appTheme.pixelPerfect(70), height: appTheme.pixelPerfect(70) }} resizeMode="contain" />);
        signUpHeader.push(<View style={{ justifyContent: "space-evenly", alignItems: "center" }}>
            <Text style={[titleStyle, { fontFamily: appTheme.primaryFont, marginTop: appTheme.pixelPerfect(10), color: appTheme.schoolColor }]}>{i18n.t("authentication.signUp.title").toLocaleUpperCase()}</Text>
        </View>);
        return signUpHeader;
    }

    const getPageContent = () => {
        if (step === 4) {
            return <View style={{ alignItems: "center", justifyContent: "center" }}>
                <Text style={[titleStyle, { textAlign: "center", color: appTheme.schoolColor, fontFamily: appTheme.primaryFont, paddingVertical: appTheme.pixelPerfect(10) }]}>
                    {i18n.t("authentication.signUp.form.welcome").toLocaleUpperCase()}
                </Text>
                <Image source={images.illTeacherDocsWithLogo} style={{ width: appTheme.pixelPerfect(130), height: appTheme.pixelPerfect(190), marginTop: appTheme.pixelPerfect(30) }} resizeMode="contain" />
                <Text style={[titleStyle, { textAlign: "center", color: appTheme.schoolColor, fontFamily: appTheme.primaryFont, paddingTop: appTheme.pixelPerfect(20), paddingBottom: appTheme.pixelPerfect(10), paddingHorizontal: appTheme.pixelPerfect(40) }]}>
                    {i18n.t("authentication.signUp.form.toStart.title")}
                </Text>
                <View style={{ alignItems: "flex-start" }}>
                    <Text style={[titleStyle, { textAlign: "left", color: appTheme.schoolColor, fontFamily: appTheme.secondaryFont, paddingHorizontal: appTheme.pixelPerfect(40) }]}>
                        {i18n.t("authentication.signUp.form.toStart.body.tuto")}
                    </Text>
                    <Text style={[titleStyle, { textAlign: "left", color: appTheme.schoolColor, fontFamily: appTheme.secondaryFont, paddingHorizontal: appTheme.pixelPerfect(40) }]}>
                        {i18n.t("authentication.signUp.form.toStart.body.advice")}
                    </Text>
                    <Text style={[titleStyle, { textAlign: "left", color: appTheme.schoolColor, fontFamily: appTheme.secondaryFont, paddingHorizontal: appTheme.pixelPerfect(40) }]}>
                        {i18n.t("authentication.signUp.form.toStart.body.webinar")}
                    </Text>
                </View>
                <View style={{ alignItems: "center", marginTop: appTheme.pixelPerfect(20) }}>
                    {getButtonContentByStep()}
                </View>
            </View>;
        }
        let buttonPrefix = <View />;
        if (step === 1) {
            const paddingTop = appTheme.pixelPerfect(40);
            const paddingBottom = appTheme.pixelPerfect(20);
            buttonPrefix = <Text style={[titleStyle, { textAlign: "center", color: appTheme.schoolColor, fontFamily: appTheme.primaryFont, paddingTop, paddingBottom, paddingHorizontal: appTheme.pixelPerfect(20) }]}>
                {i18n.t("authentication.signUp.form.schoolInOtherTerritoryTitle").toLocaleUpperCase()}
            </Text>;
        }
        const birdPosition = (appTheme.getFullAppWidth() - blockWidth) / 2;
        return <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center", justifyContent: "center" }}>
            <View style={{
                borderRadius: appTheme.pixelPerfect(20),
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: appTheme.pixelPerfect(20),
                width: appTheme.getBlockWidth(),
                backgroundColor: appTheme.white,
                paddingBottom: appTheme.pixelPerfect(10),
                paddingHorizontal: appTheme.pixelPerfect(10)
            }}>
                {getContentByStep()}
                <View style={{ alignItems: "center" }}>
                    <View style={{ alignItems: "center" }}>
                        {buttonPrefix}
                        {getButtonContentByStep()}
                    </View>
                    {getLegalMentionByStep()}
                </View>
            </View>
            <Image source={images.icBird} style={{ position: "absolute", top: -appTheme.pixelPerfect(8), right: birdPosition, width: appTheme.pixelPerfect(75), height: appTheme.pixelPerfect(72) }} resizeMode="contain" />
        </View>
    }

    return (
        <View style={{ flex: 1 }}>
            {signInUpButton}
            <SafeAreaView style={[styles.main, { width: appTheme.getFullAppWidth() }]}>
                <Image source={images.bgOnboardingTeacher} style={{ position: "absolute", top: 0, width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight() }} resizeMode="cover" />
                <View style={[styles.main, { width: appTheme.getFullAppWidth(), margin: appTheme.pixelPerfect(10), height: appTheme.getFullAppHeight() }]}>
                    {getSignUpHeader()}
                    <View style={{ flex: 1 }}>
                        <ScrollView style={{ flex: 1 }}>
                            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", marginTop: appTheme.pixelPerfect(10), width: appTheme.getBlockWidth() }}>
                                {getPageContent()}
                            </View>
                        </ScrollView>
                    </View>
                    <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.pixelPerfect(40), alignItems: "center", justifyContent: "center", marginBottom: appTheme.pixelPerfect(10) }}>
                        {getStepIndicatorByStep()}
                    </View>
                </View>
                <Modal
                    transparent={true}
                    animationType="slide"
                    visible={showSchoolsList}>
                    <SafeAreaView style={{ width: appTheme.getFullScreenWidth(), alignItems: "center" }}>
                        <View style={{ width: appTheme.getFullAppWidth(), height: appTheme.getFullAppHeight(), backgroundColor: appTheme.white }}>
                            <View style={{ width: appTheme.getFullAppWidth(), alignItems: "flex-end", padding: appTheme.pixelPerfect(10) }}>
                                <TouchableOpacity onPress={() => setShowSchoolsList(false)}>
                                    <Text style={{ color: appTheme.darkBlue }}>Fermer</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={{ flexDirection: appTheme.getFlexDirectionForSplitScreen() }}>
                                <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center" }}>
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                        <TextInput
                                            key="schoolNameSearch"
                                            label={i18n.t("authentication.signUp.form.schoolByZipCodePlaceholder")}
                                            style={{ borderColor: appTheme.secondaryColor, width: appTheme.pixelPerfect(220), color: appTheme.darkBlue }}
                                            onChangeText={(text) => setSearchTerm(text)}
                                            value={searchTerm}
                                            keyboardType="default"
                                            autoCapitalize="words" />
                                        <TouchableOpacity onPress={onSchoolSearchRequest} style={{ width: appTheme.pixelPerfect(30), height: appTheme.pixelPerfect(30), justifyContent: "center", alignItems: "center", backgroundColor: appTheme.schoolColor, borderRadius: appTheme.pixelPerfect(20) }}>
                                            <SVGView Component={IcSearch} width={appTheme.pixelPerfect(16)} height={appTheme.pixelPerfect(16)} color={appTheme.white} />
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                            <View style={{ width: appTheme.getFullAppWidth(), flex: 1, backgroundColor: appTheme.white }}>
                                <Text style={{ textAlign: "center", color: appTheme.secondaryColor, fontFamily: appTheme.primaryFont, fontWeight: "700", fontSize: appTheme.pixelPerfectForFont(12), padding: appTheme.pixelPerfect(10) }}>{schoolsList.length + " résultat(s)"}</Text>

                                <FlatList
                                    style={{ width: appTheme.getFullAppWidth(), flex: 1, backgroundColor: appTheme.white }}
                                    data={schoolsList}
                                    keyExtractor={(item, index) => index.toString()}
                                    renderItem={({ item, index }) => {
                                        return (
                                            <TouchableOpacity onPress={() => selectSchool(item)} style={{ width: appTheme.getFullAppWidth() - appTheme.pixelPerfect(20), paddingVertical: appTheme.pixelPerfect(10), borderBottomColor: appTheme.schoolColor, borderBottomWidth: 1, marginHorizontal: appTheme.pixelPerfect(10), flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                <View style={{ width: appTheme.getFullAppWidth() - appTheme.pixelPerfect(40) }}>
                                                    <Text style={{ color: appTheme.secondaryColor, fontFamily: appTheme.primaryFont, fontWeight: "700", fontSize: appTheme.pixelPerfectForFont(12) }}>{item.fields.nom_etablissement}</Text>
                                                    <Text style={{ color: appTheme.secondaryColor }}>{item.fields.type_etablissement + " " + item.fields.statut_public_prive}</Text>
                                                    <Text style={{ color: appTheme.secondaryColor }}>{item.fields.adresse_1}</Text>
                                                    <Text style={{ color: appTheme.secondaryColor }}>{item.fields.code_postal + " " + item.fields.nom_commune}</Text>
                                                </View>
                                                <SVGView Component={IcAngleRight} width={appTheme.pixelPerfect(16)} height={appTheme.pixelPerfect(16)} color={appTheme.schoolColor} />
                                            </TouchableOpacity>
                                        )
                                    }} />
                            </View>
                            <View style={{ width: appTheme.getFullAppWidth(), alignItems: "center", justifyContent: "center", marginBottom: appTheme.pixelPerfect(10) }}>
                                <Text style={{ textAlign: "center", color: appTheme.secondaryColor, fontFamily: appTheme.primaryFont, fontWeight: "700", fontSize: appTheme.pixelPerfectForFont(12), padding: appTheme.pixelPerfect(10) }}>{"Si vous ne trouvez pas votre école cliquez sur le bouton ci-dessous."}</Text>
                                <Button
                                    onPress={enterSchool}
                                    title="Mon école n'est pas listée" />
                            </View>
                        </View>
                    </SafeAreaView>
                    {getLoadingView()}
                </Modal>
            </SafeAreaView>
            <Alert ref={alertRef} />
        </View>
    )
}

export default TeacherSignUp;

const styles = StyleSheet.create({
    main: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }
});